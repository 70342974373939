import { useEffect, useRef } from "react";

/**
 * some mobile the css unit vh, kind of doing weird thing cause of some browser navbar
 * this hook will take the innerHeight * 0.01 then set the css variable --vh to that computed value
 * https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ that tips dont work on this case
 * im using this hack from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export default function useSetVhRef() {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const setVh = () => {
      if (elementRef.current) {
        // some weird bug shit with vh on mobile
        // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ that tips dont work on this case
        // im using this hack from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        const vh = window.innerHeight * 0.01;
        elementRef.current?.style.setProperty("--vh", `${vh}px`);
      }
    };
    setVh()
    window.addEventListener("resize", setVh);
    return () => {
      window.removeEventListener('resize', setVh)
    }
  }, []);

  return {elementRef}
}