import { DevTool } from "@hookform/devtools";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactNode, forwardRef, useEffect, useImperativeHandle } from "react";
import {
  DeepPartial,
  FormProvider,
  Resolver,
  UnpackNestedValue,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import useMortgageFormContext from "shared/components/MortgageForm/hooks/useMortgageFormContext";
import { MortgageFormData } from "shared/types/postMortgageData";
import logger from "utils/logger";

type Props = {
  resolver?: Resolver<any>;
  title: string;
  defaultValues: UnpackNestedValue<DeepPartial<MortgageFormData>>;
  onNext: (data?: MortgageFormData) => void;
  onBack?: (data?: MortgageFormData) => void;
  isLogin?: boolean;
  children:
    | ReactNode
    | ((props: UseFormReturn<MortgageFormData, any>) => ReactNode);
};

export type StepLayoutRef = {
  formMethod: UseFormReturn<MortgageFormData, any>;
};
const StepLayout = forwardRef<StepLayoutRef, Props>(
  (
    {
      defaultValues,
      resolver,
      children,
      title,
      onNext,
      onBack,
      isLogin: shouldShowTermAndCondt,
    },
    ref,
  ) => {
    const formMethod = useForm<MortgageFormData>({
      mode: "onBlur",
      defaultValues,
      resolver,
      criteriaMode: "firstError",
    });
    const {
      mortgageFormState,
      isLastStep,
      mortgageFormDispatch,
      onStepClick,
      mortgageFormData,
      setMortgageFormData,
      setMortgageFormState,
    } = useMortgageFormContext();
    const { isTermAccepted, selectedStepView } = mortgageFormState;
    const { formState, reset, getValues } = formMethod;
    const { isDirty, isValid, errors } = formState;

    useImperativeHandle(ref, () => {
      return {
        formMethod,
      };
    });

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues]);

    const handleNext = (form: MortgageFormData) => {
      const newForm = Object.keys(form).length ? form : getValues();
      if (selectedStepView) {
        mortgageFormDispatch({
          type: "SET_SELECTED_STEPVIEW",
          payload: undefined,
        });
        const newFormData = { ...mortgageFormData, ...newForm };
        setMortgageFormState({
          ...mortgageFormState,
          mortgageFormData: newFormData,
          selectedStepView: undefined,
        });
        return;
      }
      onNext(newForm);
    };

    const handleBack = () => {
      if (selectedStepView) {
        mortgageFormDispatch({
          type: "SET_SELECTED_STEPVIEW",
          payload: undefined,
        });
        return;
      }
      onBack?.(formMethod.getValues());
    };

    let btnDisabled = shouldShowTermAndCondt
      ? !(isValid && isTermAccepted)
      : !isValid;
    return (
      <FormProvider {...formMethod}>
        <DevTool control={formMethod.control} />
        <form
          onSubmit={formMethod.handleSubmit(handleNext, (e) => {
            logger.info(e);
          })}
          style={{ height: "100%" }}
        >
          <Stack
            //this marginTop is to cancelout the main-layout margintop ヘ(◕。◕ヘ)
            // marginTop="-2rem"
            height="inherit"
            // position={"relative"}
          >
            <Typography
              variant="h5"
              textAlign="center"
              sx={{
                backgroundColor: isLastStep ? "transparent" : "#D8D7F540",
                padding: "2rem 1rem 1rem",
              }}
            >
              {title}
            </Typography>
            <Box
              id="inputs-section"
              sx={{
                overflowY: "auto",
                flex: 1,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "stretch",
                flexDirection: "column",
              }}
            >
              {/* {children} */}
              {typeof children === "function"
                ? children({ ...formMethod })
                : children}
            </Box>
            <Stack
              id="wizard-bottom-nav"
              direction="row"
              gap={1}
              justifyContent={
                isLastStep || selectedStepView ? "space-around" : "center"
              }
              padding={2}
              alignItems="flex-end"
              {...((!isLastStep || !selectedStepView) && {
                sx: {
                  width: "100%",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  "&>*": {
                    flex: 1,
                  },
                },
              })}
            >
              {onBack && (
                <Button
                  variant="contained"
                  onClick={handleBack}
                  sx={{
                    backgroundColor: "#AAADAD80",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "gray",
                    },
                  }}
                >
                  {selectedStepView ? "Regresar" : "Atrás"}
                </Button>
              )}
              <Stack>
                <Button
                  // disabled={btnDisabled}
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#E5F287",
                    color: "black",
                    "&:disabled": {
                      backgroundColor: "#E5F28780",
                    },
                    "&:hover": {
                      backgroundColor: "#E5F28790",
                    },
                  }}
                >
                  {selectedStepView ? "Guardar" : "Siguiente"}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    );
  },
);

export default StepLayout;
