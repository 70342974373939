import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";
import { TopBar, LeftBarMenu, Loader, BottomNav } from "../../components";

import subject, { TOGGLE_LOADER } from "../../../utils/subjects";
import { useProfile, useSetVhRef } from "shared/hooks";
import { InfoDialog } from "pages/RealState/Components";
import { RealStateProfile } from "shared/types/Profile";

const { REACT_APP_SPECIAL_AGENT }: any = process.env;

// Get email from localstorage
const email = localStorage.getItem("email")
  ? localStorage.getItem("email")
  : "";

export interface IMainRealEstateLayoutProps {
  children: JSX.Element;
}

const navOptions = [
  {
    label: "Registrar contacto",
    path: "/agent/contact",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Registrar%20contacto%20en%20Colibid%20-%20bottom%20menu.png",
  },
  {
    label: "Consultar Prescoring",
    path: "/agent/scoring",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Prescoring-bottom%20menu.png",
  },
  {
    label: "Home",
    path: "/agent",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/homeBottoMenu.png",
  },
  {
    label: "Solicitud de hipoteca",
    path: "/agent/apply",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Solicitarhipotecabottommenu.png",
  },
  {
    label: "Mis clientes",
    path: "/agent/clients",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/MisClientesbottommenu.png",
  },
];

if (email === REACT_APP_SPECIAL_AGENT) {
  navOptions.pop(); // Remove Mis clientes section
}

const MainRealEstateLayout: FunctionComponent<IMainRealEstateLayoutProps> = ({
  children,
}): ReactElement<any> => {
  const { elementRef: mainSectionRef } = useSetVhRef();
  const [loading, setLoading] = useState(false);
  const profile = useProfile<RealStateProfile>().profile;
  const imageCompany =
    profile?.tags === "casafari"
      ? "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcasafari.svg"
      : undefined;

  useEffect(() => {
    const toggleLoader = subject
      .listen(TOGGLE_LOADER)
      .subscribe(async ({ value }) => {
        setLoading(value);
      });

    return () => {
      if (toggleLoader) {
        toggleLoader.unsubscribe();
      }
    };
  }, []);

  // Get image from backend

  return (
    <>
      {loading && <Loader />}

      <TopBar
        linkColibid="/agent"
        allowLeftBarMenu={true}
        showAuthControls={true}
        imageCompany={imageCompany}
      />
      <LeftBarMenu role="realstate" />
      <InfoDialog />
      <section id="content" className="main-layout" ref={mainSectionRef}>
        {children}
      </section>

      <BottomNav navBtnOptions={navOptions} />
    </>
  );
};

export default MainRealEstateLayout;
