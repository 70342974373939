import {
  List,
  ListItemText,
  Alert,
  ListItemButton,
  ListItemIcon,
  type ListItemTextProps,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { useDialog } from "shared/hooks";
import ImageButton from ".";
import CustomDialog from "../CustomDialog";
import { ReactNode, useState } from "react";

interface Props {
  shouldOpenDialog?: boolean;
  imgBtnOnClick: () => void;
  btnTitle: string;
  listTitle: string;
  alertMsg: string;
  imgSrc: string;
  children: ReactNode;
}

export default function ImageBtnWithDialog({
  imgBtnOnClick,
  shouldOpenDialog,
  btnTitle,
  listTitle,
  alertMsg,
  imgSrc,
  children,
}: Props) {
  const { closeDialog, isShowing, openDialog } = useDialog();
  return (
    <>
      <ImageButton
        title={btnTitle}
        src={imgSrc}
        onClick={() => {
          if (shouldOpenDialog) {
            openDialog();
            return;
          }
          imgBtnOnClick();
        }}
      />
      {shouldOpenDialog && (
        <CustomDialog
          open={isShowing}
          onClose={() => {
            closeDialog();
          }}
          maxWidth="xs"
        >
          <List>
            <ListItemText>{listTitle}</ListItemText>
          </List>
          {children}
          <Alert severity="info">{alertMsg}</Alert>
        </CustomDialog>
      )}
    </>
  );
}

export interface CustomListItemBtnProps extends ListItemTextProps {
  completed: boolean;
  onClick: () => void;
}

export const CustomListItemBtn = ({
  completed,
  onClick,
  ...ListItemTextProps
}: CustomListItemBtnProps) => {
  const [btnDisabled, setbtnDisabled] = useState(false);
  return (
    <ListItemButton
      disabled={btnDisabled || completed}
      onClick={() => {
        setbtnDisabled(true);
        onClick();
      }}
    >
      <ListItemIcon>
        {completed ? (
          // <CheckIcon color="primary" />
          <RadioButtonCheckedIcon color="primary" />
        ) : (
          <RadioButtonUncheckedIcon color="primary" />
        )}
      </ListItemIcon>
      <ListItemText {...ListItemTextProps}></ListItemText>
      {completed && <CheckIcon color="primary" />}
    </ListItemButton>
  );
};
