import { ComponentType } from "react";
import { MortgageStepBaseDirector, IMFormStepBuilder, StepView } from ".";
import {
  CurrentMortgageDataForm,
  CustomerAppSituationForm,
  DataPeriodForm,
  HouseSituationForm,
  ProjectApproved,
  PropertyAreaForm,
  PropertyConstructionTypeForm,
  PropertyExistenceForm,
  PropertyHasEndormentForm,
  PropertyLocationForm,
  PropertyTypeForm,
  PropertyValueForm,
} from "../components/formSteps";
import { MortgageFormData } from "shared/types/postMortgageData";
import ExtendedMortgageDataForm from "../components/formSteps/ExtendedMortgageDataForm";

export class MortgageDataBuilder implements IMFormStepBuilder {
  private initialStepView: Pick<
    StepView<MortgageFormData>,
    "group" | "subGroup"
  > = {
    group: "form",
    subGroup: "mortgageData",
  };
  _stepViews: StepView<MortgageFormData>[] = [];
  addStep(
    Component: ComponentType<any>,
    accesor: StepView<MortgageFormData>["accesor"]
  ) {
    this._stepViews.push({
      ...this.initialStepView,
      component: Component,
      accesor,
    });
    return this;
  }
  addPropertyType() {
    this.addStep(PropertyTypeForm, {
      label: "Tipo de propiedad",
      path: "property.type",
    });
    return this;
  }

  addCurrentMortgageData() {
    this.addStep(CurrentMortgageDataForm, [
      {
        path: "property.value",
        label: "Valor de la propiedad",
      },
      {
        path: "data.pending_amount",
        label: "Importe pendiente de la hipoteca",
      },
      {
        path: "data.interest_type_remortgage",
        label: "Tipo de Interés Existente",
      },
      {
        path: "data.interest_rate",
        label: "Tasa de interés existente",
      },
      {
        path: "data.remortgage_laps_years",
        label: "Plazo pendiente de hipoteca",
      },
      {
        path: "data.curr_mortgage_init_date",
        label: "Mes y año de firma de hipoteca",
      },
      {
        path: "current_bank",
        label: "Banco actual",
      },
    ]);
    return this;
  }

  addExtendedMortgageData() {
    this.addStep(ExtendedMortgageDataForm, [
      {
        path: "data.pending_amount",
        label: "Importe pendiente de la hipoteca",
      },
      {
        path: "data.interest_type_remortgage",
        label: "Tipo de Interés Existente",
      },
      {
        path: "data.interest_rate",
        label: "Tasa de interés existente",
      },
      {
        path: "data.remortgage_laps_years",
        label: "Plazo pendiente de hipoteca",
      },
      {
        path: "data.curr_mortgage_init_date",
        label: "Mes y año de firma de hipoteca",
      },
      {
        path: "current_bank",
        label: "Banco actual",
      },
    ]);
    return this;
  }

  addPropertyValue() {
    this.addStep(PropertyValueForm, [
      {
        path: "property.value",
        label: "Valor de la propiedad",
      },
      {
        path: "mortgage_initial",
        label: "Fondos Iniciales disponibles",
      },
      {
        path: "data.amount",
        label: "Importe de la hipoteca",
      },
      {
        path: "data.mortgage_rate",
        label: "Porcentaje de la hipoteca",
      },
    ]);
    return this;
  }
  addDataPeriod() {
    this.addStep(DataPeriodForm, {
      path: "data.period",
      label: "Plazo de la hipoteca",
    });
    return this;
  }
  addPropertyExistence() {
    this.addStep(PropertyExistenceForm, {
      path: "property_existence",
      label: "Tipo de vivienda",
    });
    return this;
  }
  addHouseSituation() {
    this.addStep(HouseSituationForm, {
      label: "Vivienda habitual",
      path: "property.house_situation",
    });
    return this;
  }
  addPropertyLocation() {
    this.addStep(PropertyLocationForm, [
      {
        path: "property.community",
        label: "Comunidad",
      },
      {
        path: "property.province",
        label: "Provincia",
      },
      {
        path: "property.city",
        label: "Ciudad",
      },
      {
        path: "property.zipcode",
        label: "Código postal",
      },
      {
        path: "property.address",
        label: "Dirección",
      },
    ]);
    return this;
  }
  addPropertyArea() {
    this.addStep(PropertyAreaForm, {
      label: "Area de la Propiedad",
      path: "property.area",
    });
    return this;
  }
  addPropertyConstructionType() {
    this.addStep(PropertyConstructionTypeForm, {
      label: "Tipo de Contrucción",
      path: "property.constructionType",
    });
    return this;
  }
  addPropertyHasEndorsment() {
    this.addStep(PropertyHasEndormentForm, {
      path: "property.hasEndorsement",
      label: "Aval",
    });
    return this;
  }
  addCustomerAppSituation() {
    this.addStep(CustomerAppSituationForm, [
      {
        path: "customer_application_situation.other_active_applications",
        label: "Solicitud de hipoteca anteriores",
      },
      {
        path: "customer_application_situation.financials_entities",
        label: "Hipoteca ya solicitada con",
      },
      {
        path: "customer_application_situation.types_entities",
        label: "Instituciones aplicados",
      },
    ]);
    return this;
  }

  addProjectApproved() {
    this.addStep(ProjectApproved, [
      {
        path: "property.project_approved.value",
        label: "¿Tienes tu proyecto aprobado?",
      },
    ]);
    return this;
  }

  build(): StepView<MortgageFormData>[] {
    return this._stepViews;
  }
}

export class MortgageDataDirector implements MortgageStepBaseDirector {
  private builder: MortgageDataBuilder;

  constructor(builder: MortgageDataBuilder) {
    this.builder = builder;
  }

  buildNewMortgage() {
    this.builder.addPropertyType();
    this.builder.addPropertyValue();
    this.builder.addDataPeriod();
    this.builder.addPropertyExistence();
    this.builder.addHouseSituation();
    this.builder.addPropertyLocation();
    // this.builder.addPropertyArea();
    this.builder.addPropertyConstructionType();
    this.builder.addPropertyHasEndorsment();
    this.builder.addCustomerAppSituation();
  }

  buildImproveMortgage() {
    this.builder.addPropertyType();
    this.builder.addCurrentMortgageData();
    this.builder.addDataPeriod();
    this.builder.addPropertyLocation();
    // this.builder.addPropertyArea();
    this.builder.addPropertyConstructionType();
    this.builder.addCustomerAppSituation();
  }

  buildRaiseCapital() {
    this.builder.addPropertyType();
    this.builder.addCurrentMortgageData();
    this.builder.addExtendedMortgageData();
    this.builder.addDataPeriod();
    this.builder.addPropertyLocation();
    this.builder.addPropertyArea();
    this.builder.addPropertyConstructionType();
    this.builder.addCustomerAppSituation();
  }
  buildAutopromotor() {
    this.builder.addPropertyType();
    this.builder.addPropertyValue();
    this.builder.addDataPeriod();
    this.builder.addProjectApproved();
    this.builder.addPropertyLocation();
    this.builder.addPropertyArea();
    this.builder.addPropertyConstructionType();
    this.builder.addCustomerAppSituation();
  }
}
