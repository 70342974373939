import { InfoOutlined } from "@mui/icons-material";
import {
  Tooltip,
  TooltipProps,
  Stack,
  IconButton,
  IconButtonProps,
  DialogContent,
  Dialog,
} from "@mui/material";
import { FlexboxProps } from "@mui/system";
import { ReactNode } from "react";
import { useDialog } from "shared/hooks";

export default function WithDialog({
  children,
  position = "left",
  justifyContent = "flex-end",
  icon,
  label,
  ...restIconBtnPros
}: {
  position?: "left" | "right";
  justifyContent?: FlexboxProps["justifyContent"];
  icon?: IconButtonProps["children"];
  children: ReactNode | ((props: { closeDialog: () => void }) => ReactNode);
  label?: ReactNode;
} & IconButtonProps) {
  const { isShowing, openDialog, closeDialog } = useDialog();
  const handleClick: IconButtonProps["onClick"] = (e) => {
    openDialog();
    if (restIconBtnPros.onClick) {
      restIconBtnPros.onClick(e);
    }
  };
  return (
    <>
      <Stack
        id="with-dialog-stack"
        alignItems="center"
        justifyContent={justifyContent}
        spacing={1}
        direction={position === "left" ? "row" : "row-reverse"}
      >
        <IconButton
          {...restIconBtnPros}
          onClick={handleClick}
          sx={{ ...restIconBtnPros.sx, fontSize: "medium" }}
        >
          {icon ? (
            icon
          ) : (
            <InfoOutlined color="primary" sx={{ width: 24, height: 24 }} />
          )}
        </IconButton>
        {label}
      </Stack>
      <Dialog open={isShowing} onClose={closeDialog}>
        <DialogContent>
          {typeof children === "function"
            ? children({ closeDialog })
            : children}
        </DialogContent>
      </Dialog>
    </>
  );
}
