import axios from "../../utils/axios";

export interface Bank {
  id: number;
  logo: any;
  name: string;
}

const getBanks = () => {
  return axios.get<Bank[]>(`${process.env.REACT_APP_API_URL}/banks`);
};

export { getBanks };
