import { useReducer } from "react";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";

export interface BidReducerState {
  mortgage: MortgageRes;
  bids: Bid[];
  selectedBid?: Array<Bid>;
  bidSubmitted: boolean;
  isFinanceFeeAccepted: boolean;
}

export type BidAction =
  | {
      type: "ADD";
      payload: Bid;
    }
  | { type: "SET_INITIAL_STATE"; payload: Bid[] }
  | { type: "SELECT_BID"; payload: Bid["id"] }
  | { type: "TOGGLE_FINANCE_FEE" }
  | { type: "BID_SUBMIT" };

function reducer(state: BidReducerState, action: BidAction): BidReducerState {
  // const isSameCol =
  //   "payload" in action ? state.sortCol === action.payload : false;
  switch (action.type) {
    case "ADD":
      return { ...state, bids: [...state.bids, action.payload] };
    case "SET_INITIAL_STATE":
      return { ...state, bids: action.payload };
    case "SELECT_BID":
      const bid = state.bids.find((b) => b.id === action.payload);

      // If bid is not found, we just return the state as is
      if (!bid) return state;

      // If selectedBid is not initialized (i.e., undefined), we initialize it as an empty array
      if (!state.selectedBid) state.selectedBid = [];

      const isBidSelected = state.selectedBid.some(
        (selectedBid) => selectedBid.id === bid.id
      );

      // If the bid is already selected, we filter it out of the selectedBid array
      if (isBidSelected) {
        return {
          ...state,
          selectedBid: state.selectedBid.filter(
            (selectedBid) => selectedBid.id !== bid.id
          ),
        };
      }

      // Otherwise, we add the bid to the selectedBid array
      return {
        ...state,
        selectedBid: [...state.selectedBid, bid],
      };
    case "BID_SUBMIT":
      return { ...state, bidSubmitted: true };
    case "TOGGLE_FINANCE_FEE":
      return { ...state, isFinanceFeeAccepted: !state.isFinanceFeeAccepted };
    default:
      return state;
  }
}

function useBidReducer({ mortgage }: { mortgage: MortgageRes }) {
  const bids = mortgage.bids;

  const initialState: BidReducerState = {
    mortgage,
    bids,
    bidSubmitted: false,
    isFinanceFeeAccepted: false,
  };
  if (bids.length > 0) {
    const bidAccepted = bids.find((b) => b.is_accepted);
    if (bidAccepted) {
      initialState.selectedBid = [bidAccepted];
      initialState.bidSubmitted = true;
      // initialState.isFinanceFeeAccepted = bidAccepted.accepted.some(
      //   (a) => a.accept_financed_commission
      // );
    }
  }

  return useReducer(reducer, initialState);
}
export default useBidReducer;
