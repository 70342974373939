import { Typography, TypographyProps } from "@mui/material";

type Props = {};
export default function HighlightText(props: TypographyProps) {
  return (
    <Typography
      {...props}
      component="span"
      sx={{ backgroundColor: "#D9F6EF", ...props.sx }}
    >
      {props.children}
    </Typography>
  );
}
