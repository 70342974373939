import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

export const subject = new Subject();
const props = {
  send: (topic: string, value: any) =>
    subject.next({
      topic,
      value,
    }),
  listen: (topic: string) =>
    subject.asObservable().pipe(filter((d: any) => d.topic === topic)),
};

// Topics
export const OPEN_LEFT_BAR = "open_left_bar";
export const TOGGLE_LOADER = "toggle_loader";
export const USER_LOGGED = "user_logged";

//mortgageForm
export const HAS_FORM = 'has_form';

export default props;
