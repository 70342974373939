import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { sendTagEvent } from "utils/analytics";
import DialogWarning from "shared/components/DialogWarning";
import { useDialog, useProfile } from "shared/hooks";
import { ClientProfile } from "shared/types/Profile";

export default function MortgageCalculatorDialog() {
  const navigate = useNavigate();
  const { profile } = useProfile<ClientProfile>(false);
  const { isShowing, toggle } = useDialog(true);

  const handledCLickCloseDialog = () => {
    sendTagEvent({
      event: "app_formulario_redirecto_to_calculator",
      method: "email",
    });
    window.open(
      "https://colibid.com/calculadora-cuanto-podria-llegar-solicitar/",
      "_blank"
    );
    if (profile?.firstname) {
      navigate("/client/home");
    } else {
      navigate("/sign-in");
    }
  };

  return (
    <DialogWarning
      title="¿Tienes escogida tu próxima vivienda?"
      buttonDisagree="Ir a Calculadoras"
      buttonAgree="SÍ TENGO VIVIENDA"
      ShowDialog={isShowing}
      handledClickAgree={toggle}
      handledClickDisagree={handledCLickCloseDialog}
    >
      <>
        <Typography component={"span"} variant="body2" gutterBottom>
          Para que bancos y asesores puedan hacerte <b>ofertas reales</b> de
          financiación es necesario que tengas los datos concretos del inmueble.
          Si todavía estás buscando vivienda y necesitas conocer cuánto podrías
          solicitar o cuál sería la cuota mensual de tu hipoteca, puedes
          consultarlo en nuestras calculadoras.
        </Typography>
      </>
    </DialogWarning>
  );
}
