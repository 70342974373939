import {
  useState,
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Card,
  CardContent,
  Collapse,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  IconButtonProps,
  Stack,
  CardHeader,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDialog } from "shared/hooks";
import { getTruthyValuesLength } from "./utils";
import { NotResults } from "..";

const InfoCardContext = createContext<{
  editField: boolean;
  toggleEdit: () => void;
} | null>(null);

function useInfoCardContext() {
  const context = useContext(InfoCardContext);
  if (!context) {
    throw new Error(
      `tiene que ser renderizado dentro de un InfoCard (╯°□°）╯︵ ┻━┻`,
    );
  }
  return context;
}

function InfoCard({
  title,
  editable = false,
  background = "white",
  children,
  open,
}: {
  title: ReactNode;
  background?: React.CSSProperties["background"];
  editable?: boolean;
  children: ReactNode;
  open?: boolean;
}) {
  const [editField, setEditField] = useState(false);
  const { isShowing, toggle } = useDialog(open);
  const toggleEdit = useCallback(() => setEditField((oldEdit) => !oldEdit), []);

  const providerValue = useMemo(() => ({ editField, toggleEdit }), [editField]);

  return (
    <InfoCardContext.Provider value={providerValue}>
      <Card sx={{ background }}>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {title}
              {editable && (
                <IconButton onClick={toggleEdit}>
                  <ModeEditIcon />
                </IconButton>
              )}
            </Stack>
          }
          action={
            <ExpandMore expand={isShowing} onClick={toggle}>
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={isShowing}>
          <CardContent>{children}</CardContent>
        </Collapse>
      </Card>
    </InfoCardContext.Provider>
  );
}

/* 
/ solo right recibe una fc con cb para tomar cualquir fc interna que tiene este componente
/ el tipado parece confuso pero no lo es, typescript te ayuda a AUTOCOMENTAR el codigo plus autocompletar y atrapar errores antes de build, 
/ rightBtnCb recibe una fc () => {}, y esa fc recibe un argumento (arg) => {}, 
/ el argumento es un objeto, con 2 campos, closeModalCb para pasarle la fc local y un kind of enum llamado template literal
/ porq argumento en obj y no 2 argumento por cada campo de ese obje?, la razon de por q me gusta asi es q a la hora de invocar
/ la fc es mas facil pasarle un obj y no importar el orden de los campos a escribir, aparte se puede extender un objeto a mas campo facilmente
/ que agregarle mas arg y recordar su orden.
 */
export interface InfoCardModalsProps {
  title?: ReactNode;
  children: (closeModal: () => void) => ReactNode;
  rightBtnDisabled?: boolean;
  rightBtnLabel?: string;
  rightBtnCb?: (arg: {
    closeModalCb: () => void;
    reason?: "backdropClick" | "escapeKeyDown";
  }) => void;
  leftBtnDisabled?: boolean;
  leftBtnLabel?: string;
  leftBtnCb?: () => void;
}

export function Modals({
  title,
  children,
  rightBtnDisabled = false,
  rightBtnLabel,
  rightBtnCb,
  leftBtnDisabled,
  leftBtnLabel,
  leftBtnCb,
}: InfoCardModalsProps) {
  const { toggleEdit, editField } = useInfoCardContext();

  const handleClose = async (e?: {}, r?: "backdropClick" | "escapeKeyDown") => {
    /*
    / lo bueno de este patron es que se puede usar este modal y agregar mas cosas q quieres
    / hacer antes de llamar toggleEdit, si no hay callback suministrado solo se cierra el modal
     */
    if (rightBtnCb) {
      rightBtnCb({ closeModalCb: toggleEdit, reason: r });
      return;
    }
    toggleEdit();
  };

  return (
    <Dialog onClose={handleClose} open={editField}>
      <DialogTitle>
        {title || "Seleccione el campo que desea  editar"}
      </DialogTitle>

      <DialogContent dividers>{children(toggleEdit)}</DialogContent>

      {leftBtnCb ||
        (rightBtnCb && (
          <DialogActions>
            {leftBtnCb && (
              <Button onClick={leftBtnCb} disabled={leftBtnDisabled}>
                {leftBtnLabel}
              </Button>
            )}

            <Button onClick={handleClose} disabled={rightBtnDisabled}>
              {rightBtnLabel || "Cerrar"}
            </Button>
          </DialogActions>
        ))}
    </Dialog>
  );
}

type TableRosDataValue = {
  value: string | number | undefined | null | ReactNode;
  error: boolean;
  censure?: boolean;
  icon?: ReactNode;
};
export interface TableRowsData {
  [key: string]:
    | string
    | number
    | undefined
    | null
    | boolean
    | TableRosDataValue
    | ReactNode;
}

export function setTableRowsData(
  value?: string | ReactNode,
  errorMsg?: string,
  censure?: boolean,
  icon?: ReactNode,
) {
  return {
    value,
    error: Boolean(errorMsg),
    censure,
    icon,
  };
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: any; expand: boolean }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function TableRows<D extends TableRowsData, K extends keyof D>({
  data,
  title,
  bolds,
  collapseAble,
  classNameTable,
}: {
  data: D;
  title?: string;
  bolds?: K[];
  collapseAble?: boolean;
  classNameTable?: string;
}) {
  const [expanded, setExpanded] = useState(false);
  const truthyValsLen = getTruthyValuesLength(data);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  if (!truthyValsLen) {
    return (
      <NotResults
        title="Sin Resultados"
        caption="Sin información disponible que mostrar"
      />
    );
  }

  return (
    <>
      {title && (
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          {collapseAble && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
        </Stack>
      )}
      {collapseAble ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {renderTable({ data, bolds, classNameTable })}
        </Collapse>
      ) : (
        renderTable({ data, bolds, classNameTable })
      )}
    </>
  );
}

const renderTable = <D extends TableRowsData, K extends keyof D>({
  data,
  bolds,
  classNameTable,
}: {
  data: D;
  bolds?: K[];
  classNameTable?: string;
}) => {
  const entries = Object.entries(data);
  const getStyle = ({ arr = [], key }: { arr?: K[]; key: string | K }) => ({
    fontWeight: arr.includes(key as K) ? "bold" : "",
  });

  return (
    <Table size="small" className={classNameTable}>
      <TableBody>
        {entries
          .filter(([key, val]) => {
            let value: TableRosDataValue | ReactNode = val;
            if ((val && typeof val === "string") || typeof val === "number") {
              value = val;
            }
            if (val && typeof val === "object" && "value" in val) {
              value = val.value;
            }
            return key && value;
          })
          .map(([key, val]) => {
            let value = val;
            let error;
            let censure;
            let icon;
            if ((val && typeof val === "string") || typeof val === "number") {
              value = val;
            }
            if (val && typeof val === "object" && "value" in val) {
              value = val?.value;
              error = val?.error;
              censure = val?.censure;
              icon = val?.icon;
            }

            return (
              <TableRow
                key={key}
                sx={{
                  // borderBottom: error ? "red solid" : "",
                  backgroundColor: error ? "pink" : "",
                }}
              >
                <TableCell align="left" sx={getStyle({ arr: bolds, key })}>
                  {key}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    ...getStyle({ arr: bolds, key }),
                    overflowWrap: "anywhere",
                  }}
                  className={`${censure ? "censure" : ""}`}
                >
                  {icon && icon} {value}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

InfoCard.TableRows = TableRows;
InfoCard.Modals = Modals;
export default InfoCard;

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

function entriesWithType<T>(obj: T): Entries<T> {
  return Object.entries(obj as any) as any;
}
