import { FunctionComponent, ReactComponentElement } from "react";
import { useNavigate } from "react-router-dom";
import { MortgageForm } from "shared/components";
import { MainRealEstateLayout } from "shared/layouts";
import Swal from "sweetalert2";
import { sendDataToScore } from "pages/RealState/Prescoring/Scoring";
import { MortagageRealStateAgencies } from "shared/types/BaseApiResponse";
import { agentAplyMortgage } from "services/api/agent.service";
import { toast } from "react-toastify";
import { getInitialFormData } from "shared/components/MortgageForm/utils";
import dataStorageService from "services/api/dataStorage.service";
import { MORTGAGE_FORM_STATE } from "shared/constants/localStorage";

const FormMortgage: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();

  const applyMortgage = (data: MortagageRealStateAgencies) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¿El cliente ${data.first_name}  ${data.last_name}  quiere solicitar la subasta?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        agentAplyMortgage(data.id, data.mortgage)
          .then(() => {
            toast.success("Solicitud registrada correctamente");
            dataStorageService.delData({ key: MORTGAGE_FORM_STATE });
            navigate("/agent");
          })
          .catch((e: any) => {
            toast.error(
              "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
            );
            console.log(e);
          });
      }
    });
  };

  return (
    <MainRealEstateLayout>
      <MortgageForm
        onSubmitForm={async (data) => {
          let res = await sendDataToScore(data);
          if (res !== null) {
            applyMortgage(res);
          }
        }}
      />
    </MainRealEstateLayout>
  );
};

export default FormMortgage;
