import React, {
  FunctionComponent,
  useState,
  ReactComponentElement,
  useEffect,
} from "react";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Dialog,
  Tabs,
  Tab,
  Button,
  Divider,
  Collapse,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import {
  TabPanel,
  ConfirmAccept,
  NotResults,
  BidFiles,
  HelperText,
  MortgageStepTimeline,
  ToggleFinanceFee,
} from "../../components";

import {
  getConsultingType,
  getConsultingMessageType,
  validateConditions,
  formatCurrency,
  censureChars,
  splitName,
  getInterestType,
  getMonthlyComission,
} from "../../../utils/helpers";
import styles from "./styles.module.scss";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { parseISO, format } from "date-fns";
import { Widget } from "@typeform/embed-react";
import { useLocalStorage } from "../../hooks";
import { getComissionValue } from "utils/bids";
// const { REACT_APP_TYPEFORM_ID }: any = process.env;

interface Interest {
  data: Array<any>;
  cellClassTitle?: string;
  cellClassContent?: string;
  hideInterest: boolean;
}
export const MixTypeInterest = (props: Interest) => {
  const [openRow, setOpenRow] = useState(false);
  const totalYears =
    props.data !== undefined
      ? props.data.length > 0
        ? props.data.filter((interest: any) => parseInt(interest.duration))
            .length > 0
          ? props.data
              .map((interest: any) => parseInt(interest.duration))
              .reduce((partialSum, a) => partialSum + a, 0)
          : 0
        : 0
      : 0;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          align="center"
          className={props.cellClassTitle ? props.cellClassTitle : styles.Title}
        >
          Tasa de interés
        </TableCell>
        <TableCell align="center" className={styles.Content}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenRow(!openRow)}
          >
            {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openRow} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table aria-label="caption table" size="small">
                <caption
                  className={
                    props.cellClassContent
                      ? props.cellClassContent
                      : styles.Content
                  }
                  style={{ paddingLeft: 10, textAlign: "center" }}
                >
                  * Plazo total de hipoteca: {totalYears} años
                </caption>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={
                        props.cellClassTitle
                          ? props.cellClassTitle
                          : styles.Title
                      }
                    >
                      Período
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        props.cellClassTitle
                          ? props.cellClassTitle
                          : styles.Title
                      }
                    >
                      Tipo
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        props.cellClassTitle
                          ? props.cellClassTitle
                          : styles.Title
                      }
                    >
                      Interés
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        props.cellClassTitle
                          ? props.cellClassTitle
                          : styles.Title
                      }
                    >
                      Tiempo
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((interest: any) => (
                    <TableRow
                      key={interest.mode}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={
                          props.cellClassContent
                            ? props.cellClassContent
                            : styles.Content
                        }
                        align="center"
                      >
                        {getInterestType(interest.name)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={
                          props.cellClassContent
                            ? props.cellClassContent
                            : styles.Content
                        }
                        align="center"
                      >
                        {getInterestType(interest.mode)}
                      </TableCell>
                      <TableCell
                        className={`
                          ${
                            props.cellClassContent
                              ? props.cellClassContent
                              : styles.Content
                          }

                          ${props.hideInterest ? "censure" : ""}
                        `}
                        align="center"
                      >
                        {interest.rate}%
                      </TableCell>
                      <TableCell
                        className={
                          props.cellClassContent
                            ? props.cellClassContent
                            : styles.Content
                        }
                        align="center"
                      >
                        {interest?.duration} años
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export interface IBidDetailsProps {
  onClose: () => void;
  open: boolean;
  accept: boolean;
  details: any;
  onAccept: (bid: any) => void;
  addComissionCheckbox?: boolean;
}

const BidDetails: FunctionComponent<IBidDetailsProps> = ({
  onClose,
  open,
  details,
  accept,
  onAccept,
  addComissionCheckbox,
}): ReactComponentElement<any> => {
  const [tab, setTab] = useState(0);
  const [role] = useLocalStorage("role");
  const [email] = useLocalStorage("email");
  const [hideForBrokerAndBank, setHideForBrokerAndBank] = useState(false);
  const [financedAcepted, setFinancedAcepted] = useState(false);
  const [dataBid, setDataBid] = useState(details);

  const handleChangeTab = (value: any) => {
    setTab(value);
  };

  useEffect(() => {
    if (role !== "client" && email !== details?.bidder?.user) {
      setHideForBrokerAndBank(true);
    } else {
      setHideForBrokerAndBank(false);
    }
  }, [role, email, details]);

  return (
    <Dialog onClose={() => onClose()} open={open}>
      <Box className={styles.DialogDetails}>
        <Tabs value={tab} onChange={(e, value) => handleChangeTab(value)}>
          <Tab label="Generales" sx={{ width: "50%", fontSize: ".8rem" }} />
          <Tab
            label="Bonificaciones"
            sx={{ width: "50%", fontSize: ".8rem" }}
          />
        </Tabs>

        <TabPanel value={tab} index={0}>
          <Box>
            <Table size="small" sx={{ marginTop: "1rem" }}>
              <TableBody>
                {role === "client" && details.bidder?.bidder_type !== "bank" ? (
                  <TableRow>
                    <TableCell align="center" className={styles.Title}>
                      Asesor financiero
                    </TableCell>
                    <TableCell align="center">
                      Asesor{/* {splitName(details.bidder.name)} */}
                    </TableCell>
                  </TableRow>
                ) : null}
                {/* Brokers */}
                {details.bid_for?.hidden === false && role !== "client" ? (
                  <TableRow>
                    <TableCell align="center" className={styles.Title}>
                      Banco
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        role === "broker" && email !== details?.bidder?.user
                          ? `${styles.Content} censure`
                          : ""
                      } //experiment
                    >
                      {details?.bid_for?.name}
                    </TableCell>
                  </TableRow>
                ) : null}
                {details.interest_data?.mode === "mixed" ||
                details.interest_data?.mode === "mixto" ? (
                  <MixTypeInterest
                    data={details.interest_data.interest}
                    hideInterest={hideForBrokerAndBank}
                  />
                ) : (
                  <TableRow>
                    <TableCell align="center" className={styles.Title}>
                      Tasa de interés
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        hideForBrokerAndBank ? `${styles.Content} censure` : ""
                      } //experiment
                    >
                      {details?.interest_rate}%<br></br>
                    </TableCell>
                  </TableRow>
                )}
                {details.interest_data?.mode === "fijo" &&
                  role === "client" && (
                    <TableRow>
                      <TableCell align="center" className={styles.Title}>
                        Cuota Mensual (estimada)
                      </TableCell>
                      <TableCell align="center" className={styles.Content}>
                        {details?.calculated?.monthly_repr}€<br></br>
                      </TableCell>
                    </TableRow>
                  )}
                {details.bidder?.bidder_type !== "bank" && (
                  <TableRow>
                    <TableCell align="center" className={styles.Title}>
                      Honorarios totales *
                    </TableCell>
                    <TableCell align="center" className={styles.Content}>
                      {formatCurrency(getComissionValue(details.general_fees))}
                    </TableCell>
                  </TableRow>
                )}
                {/* {role === "broker" &&
                  details.bidder.accept_financed_commission && (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                        className={styles.Title}
                      >
                        Honorarios Netos con Financiación
                      </TableCell>
                      <TableCell align="center" className={styles.Content}>
                        {details.general_fees.net_fees_financed}€
                      </TableCell>
                    </TableRow>
                  )} */}
                {details.bidder?.bidder_type !== "bank" &&
                  dataBid.accept_financed_commission &&
                  financedAcepted && (
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                        className={styles.Title}
                      >
                        Cuota mensual de la financiación
                      </TableCell>
                      <TableCell align="center" className={styles.Content}>
                        {getMonthlyComission(
                          details.general_fees.consulting_fees,
                          6
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                {details.bidder?.bidder_type !== "bank" && (
                  <TableRow>
                    <TableCell align="center" className={styles.Title}>
                      Tipo de honorarios
                    </TableCell>
                    <TableCell align="center" className={styles.Content}>
                      {getConsultingType(details.general_fees.consulting_type)}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="center" className={styles.Title}>
                    Fecha de publicación
                  </TableCell>
                  <TableCell align="center" className={styles.Content}>
                    {format(parseISO(details.created), "dd-MM-yyyy / HH:mm:ss")}
                  </TableCell>
                </TableRow>
                {details.bidder?.bidder_type !== "broker" && (
                  <TableRow>
                    <TableCell align="center" className={styles.Title}>
                      Detalle de la oferta
                    </TableCell>
                    <TableCell align="center" className={styles.Content}>
                      {details.additional_detail}
                    </TableCell>
                  </TableRow>
                )}

                {/* <TableRow>
                  <TableCell align="center" className={styles.Title}>
                    Ahorro Estimado
                  </TableCell>
                  <TableCell align="center" className={styles.Content}>
                    26,000€
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>

            {role === "client" && details.bidder?.bidder_type !== "bank" ? (
              <>
                {details.general_fees.finance_fees && !accept && (
                  <ToggleFinanceFee
                    checked={financedAcepted}
                    onChange={() => {
                      setFinancedAcepted((prevState) => {
                        dataBid.accept_financed_commission = !prevState;

                        return !prevState;
                      });

                      setDataBid({ ...dataBid });
                    }}
                  />
                )}

                <Box className={styles.DialogDetails}>
                  <Alert severity="info" className={styles.AlertStyle}>
                    {getConsultingMessageType(
                      details.general_fees.consulting_type
                    )}
                    {/* Los honorarios del asesor, sin compromiso de pago hasta que
                    no se formalice la hipoteca ante notario. */}
                  </Alert>
                </Box>
              </>
            ) : null}
          </Box>
          {(role === "broker" || role === "client") && (
            <span className={styles.Title}>
              * ( honorarios colibid + honorarios broker )
            </span>
          )}
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <Box>
            {validateConditions(details.conditions_details) ? (
              <Box>
                <Table size="small" sx={{ marginTop: "1rem" }}>
                  <TableBody>
                    {details.conditions_details
                      ?.filter((bonus: any) => {
                        return bonus.allow === true;
                      })
                      .map((c: any, i: number) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="left" className={styles.Title}>
                              {c.bonificationName}
                            </TableCell>
                            <TableCell align="left" className={styles.Content}>
                              {c.interest_rate}%
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>

                {role === "client" ? (
                  <Box className={styles.DialogDetails}>
                    <Typography variant="subtitle2" align="center">
                      Información
                    </Typography>
                    <Typography variant="caption">
                      <ul>
                        <li>
                          Recibirá el tipo de interés indicado, si también
                          contrata estos productos adicionales que ofrece el
                          asesor.
                        </li>
                        <li>
                          Por lo general, puede seleccionar las bonificaciones
                          que desea tomar. Sin embargo en algunos casos debe
                          tomar todas las bonificaciones ofrecidas.
                        </li>
                        <li>
                          El precio de las bonificaciones se calcula por
                          separado y le será comunicado por nuestro socio.
                        </li>
                      </ul>
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            ) : (
              <NotResults
                title="Sin bonificaciones"
                caption="No se agregaron bonificaciones a esta puja"
                resize={true}
              />
            )}
          </Box>
        </TabPanel>

        <Box sx={{ textAlign: "right", marginTop: "1rem" }}>
          {!accept && role === "client" && (
            <Button
              variant="contained"
              onClick={() => onAccept(dataBid)}
              sx={{ marginRight: "1.3rem" }}
            >
              ELEGIR OFERTA
            </Button>
          )}

          <Button variant="outlined" onClick={() => onClose()}>
            CERRAR
          </Button>

          {/* {role === "client" && details.bidder?.bidder_type !== "bank" ? (
            <div>
              <Divider component="br" />
              <Widget
                id={REACT_APP_TYPEFORM_ID}
                hideHeaders={true}
                autoResize={true}
                className="my-form"
                hideFooter={true}
              />
            </div>
          ) : null} */}
        </Box>
      </Box>
    </Dialog>
  );
};

export default BidDetails;
