import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import { PaymentList, PaymentMethod } from "shared/types/Payments";
import axios from "../../utils/axios";

const getPaymentMethod = () => {
  return axios.get<BaseApiSuccessRes<PaymentMethod>>(`${process.env.REACT_APP_API_URL}/payments/methods`);
};

const putPaymentMethod = (data: object | undefined) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/payments/methods`, data);
};

const getPaymentsList = (ipp: number, page: number) => {
  return axios.get<BaseApiSuccessRes<PaymentList[]>>(`${process.env.REACT_APP_API_URL}/payments/list?ipp=${ipp}&page=${page}`);
}

export { getPaymentMethod, putPaymentMethod, getPaymentsList };
