import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import { sendTagEvent } from "utils/analytics";
import { DinamycLayout, MainClientLayout } from "../../../shared/layouts";
import { Breadcrumbs } from "../../../shared/components";
import { useAuthenticated, useLocalStorage, useProfile } from "../../../shared/hooks";
import { changePassword, validateTrue } from "../../../services/api";

import styles from "./styles.module.scss";
import { DIALOGS } from "shared/constants/localStorage";

const ChangePassword: FunctionComponent<{}> = (
  props
): ReactComponentElement<any> => {
  const location = useLocation();
  const navigate = useNavigate();
  const [role] = useLocalStorage("role");
  const [newpassword, setNewPassword] = useState("");
  const [repeatnewpassword, setrepeatNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, sethasError] = useState(false); // User to display error message
  const [errors, setErrors] = useState({
    newpassword: false,
    repeatnewpassword: false,
  });

  const passwordValidate = (value: any) => {
    if (!value || value.length < 6) return true;
    return false;
  };

  const repeatValidate = (val1: any, val2: any) =>
    !val1 || val2 !== val1 ? true : false;

  const validate = () => {
    errors.newpassword = passwordValidate(newpassword);
    errors.repeatnewpassword = repeatValidate(newpassword, repeatnewpassword);

    let err = validateTrue(errors);
    sethasError(err);
    setErrors({ ...errors });
    if (!err) {
      change();
    }
  };

  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["client", "realstate"]
  );

  const change = () => {
    changePassword({ newpassword })
      .then(() => {
        window.localStorage.setItem(DIALOGS.CHANGE_PASSWORD, 'true')
        toast.success("Se ha cambiado la contraseña correctamente");
        navigate("/client/home");

      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔, CODE: ECP"
        );
        console.log(e);
      });
  };

  useEffect(() => {
    sendTagEvent({
      event: "page_view",
      page_category: location.search,
      page_location: location.pathname, // URL
      user_type: role,
      user_registration_date: format(new Date(), "MM/dd/yyyy"),
      user_login_status: "active",
    });
  }, []);

  const navigateToProfile = () => {
    if (role === "client") {
      navigate("/client/profile");
    } else {
      navigate("/agent/profile");
    }
  };

  return (
    role && (
      <DinamycLayout type={role}>
        <Container className={styles.LoginWrapper}>
          <Grid container spacing={2} marginTop={0}>
            <Grid container spacing={2} marginTop={0}>
              <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
                <Breadcrumbs
                  parents={[
                    {
                      href:
                        role === "client"
                          ? "/client/profile"
                          : "/agent/profile",
                      title: "Mi Perfil",
                      onClick: navigateToProfile,
                    },
                  ]}
                >
                  <Typography color="text.gray">Cambiar contraseña</Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12} sm={12}></Grid>
            <Grid item md={4} xs={12} sm={12}>
              <Box className={styles.LoginImage}>
                <img src="/assets/img/sources/lock.svg" alt="" width="70%" />
              </Box>

              {hasError ? (
                <Box>
                  <Alert severity="error">
                    Por favor valida la información ingresada
                  </Alert>
                  <Divider component="br" />
                </Box>
              ) : null}

              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="new-password"
                    label="Nueva contraseña"
                    fullWidth
                    error={errors.newpassword}
                    helperText={
                      errors.newpassword
                        ? "La contraseña debe tener más de 6 caracteres"
                        : false
                    }
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    onChange={({ target }) => {
                      setNewPassword(target.value);
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    id="repeat-new-password"
                    label="Repetir nueva contraseña"
                    fullWidth
                    error={errors.repeatnewpassword}
                    helperText={
                      errors.repeatnewpassword
                        ? "Las contraseñas no coinciden"
                        : false
                    }
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    onChange={({ target }) => {
                      setrepeatNewPassword(target.value);
                    }}
                    required
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => validate()}
                >
                  Cambiar contraseña
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </DinamycLayout>
    )
  );
};

export default ChangePassword;
