import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { CURRENT_HOUSE_SITUATION_OPTIONS } from "../../utils";
import HandleSolicitorView from "../HandleSolicitorView";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { useFormContext } from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import { userSchema } from "../../validation/user";

const yupSchema = yup.object({
  user: userSchema.pick(["current_house_situation"]),
  user_2: isConjuntaValidate(userSchema.pick(["current_house_situation"])),
});

function CurrentHouseSituationForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es su situación de vivienda actual?"
      resolver={yupResolver(yupSchema)}
    >
      <Form />
    </StepLayout>
  );
}
function Form() {
  const { onNext } = useMortgageFormContext();
  return (
    <HandleSolicitorView
      RenderComponent={FormFields.ListSelector}
      individualProps={{
        name: "user.current_house_situation",
        options: CURRENT_HOUSE_SITUATION_OPTIONS,
        onClick: onNext,
      }}
      conjuntaProps={{ name: "user_2.current_house_situation" }}
    />
  );
}
export default CurrentHouseSituationForm;
