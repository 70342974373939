import { MortgageFormData } from "shared/types/postMortgageData";

export interface TestContextExtended {
  from: {
    schema: any;
    value: MortgageFormData;
  }[];
}

export enum EmploymentSituations {
  UNDEFINED_MORE_THAN_ONE_YEAR = 1,
  UNDEFINED_LESS_THAN_SIX_MONTH,
  UNEMPLOYED,
  FUNCTIONARY_LABORAL,
  TEMPORAL_CONTRACT,
  PENSIONER,
  AUTONOMO_ENTREPRENEUR,
  UNDEFINED_BETWEEN_SIX_MONTH_AND_ONE_YEAR,
  OTHER,
  FUNCTIONARY_INTERINO,
}

export enum FundUsedFor {
  UPGRADE_ACTUAL_HOUSE = 1,
  BUY_OTHER_HOUSE,
  DEBT_REUNIFICACION,
  OTHER,
}

export enum MortgageMode {
  NEW_MORTGAGE = 1,
  /** Se conoce como amplicación de capital */
  REMORTGAGE,
  CAPITAL_RAISE,
  AUTOPROMOTOR,
}

export const { NEW_MORTGAGE, REMORTGAGE, CAPITAL_RAISE, AUTOPROMOTOR } =
  MortgageMode;
