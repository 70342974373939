import { COUNTRIES } from "shared/constants/countries";
import { NumericString } from "./misc";
import { MortgageFormState } from "shared/components/MortgageForm/hooks/useMortgageFormReducer";

export interface PostMortgageData {
  mortgage_data: MortgageFormData;
  map_feature?: MortgageFormState["map"]["selectedFeature"];
}

export interface MortgageData extends MortgageFormData {
  solicitor_type: number;
  mortgage_initial: number;
  property_existence: number;
  customer_application_situation: CustomerApplicationSituation & {
    other_active_applications: number;
  };
  property: Property<number>;
  user: User;
  user_2: User;
  revenue: Revenue & {
    user_1: RevenueUser;
    user_2: RevenueUser;
    general: RevenueUser;
    monthly: number;
    incomes_list: Array<
      IncomesList & {
        monthly_income: number;
        solicitor: number;
      }
    >;
    expenses_list: Array<
      ExpensesList & {
        solicitor: number;
        debt_monthly: number;
        debt_total: number;
        debt_duration: string;
      }
    >;
  };
  data: Data;
  temp_uploads: TempUploadItem[];
}

export interface MortgageFormData {
  user: UserForm;
  user_2: UserForm;
  mortgage: MortgageMode;
  revenue: Revenue;
  data: DataForm;
  property: Property<NumericString>;
  temp_uploads: TempUploadItem[];
  customer_application_situation: CustomerApplicationSituation;
  solicitor_type: number | string;
  property_existence: number | string;
  current_bank: string;
  mortgage_initial: number | string;
  expenses_toggle?: number;
  form_init_date: string;
  acceptedBid?: any;
}

export enum EntitiesType {
  BANKS = 1,
  BROKERS,
}
export interface CustomerApplicationSituation {
  other_active_applications: number | string;
  types_entities: string[];
  financials_entities: string[];
}

export interface FileWithPreview extends File {
  urlBlob?: string;
}

export interface UserForm {
  name: string;
  surname: string;
  birthdate: Date | string | null;
  dni: string;
  type_dni: string;
  residence: string;
  residence_permit: NumericString;
  work: number | string;
  work_date?: string | null;
  email: string;
  phone: string;
  dni_file_front: string;
  dni_file_front_file?: FileWithPreview;
  dni_file_back: string;
  dni_file_back_file?: FileWithPreview;
  salary: number | string;
  profession: string;
  work_init_month?: string;
  work_init_year?: string | number;
  marital_status: string | number;
  children: string | number;
  current_house_situation: string | number;
  has_last_year_tax: NumericString;
}

export interface User extends UserForm {
  work: number;
  salary: number;
  work_init_year: number;
  work_init_month: string;
  marital_status: number;
  children: number;
  current_house_situation: number;
  residence: string;
  work_date: string;
  profession: string;
  residence_permit: string;
}

export interface MortgageMode {
  mode: number;
}

export interface Revenue {
  incomes_list: IncomesList[];
  expenses_list: ExpensesList[];
  coli_last_3_file: string;
  coli_emp_contract: string;
  coli_account_state: string;
  monthly: number | string;
}

export interface RevenueUser {
  total_expenses: number;
  monthly_expenses: number;
}

export interface IncomesList {
  monthly_income: number | string;
  name: string;
  solicitor: number | string;
}

export interface ExpensesList {
  debt_monthly: number | string;
  debt_total: number | string;
  debt_duration: string | Date;
  name: string;
  solicitor: NumericString;
}

export interface DataForm {
  interest_type: InterestType;
  interest_type_remortgage: Extract<InterestType, "fijo" | "variable"> | "";
  amount: string | number;
  period: string | number;
  interest_rate: string | number | null;
  pending_amount: string | number;
  remortgage_laps_years: string | number;
  tax_and_expenses: string | number;
  mortgage_rate: string | number; // se manda float
  fund_used_for: number | string;
  debtRatio: number;
  curr_mortgage_init_date: string;
  curr_original_amount: string;
}

export interface Data extends DataForm {
  amount: number;
  period: number;
  interest_rate: string | number | null;
  pending_amount: number;
  remortgage_laps_years: number;
  tax_and_expenses: number;
  mortgage_rate: number;
  debtRatio: number;
}
export interface UploadPropertyFile<T extends NumericString> {
  value: T;
  file_url: string;
}

interface ProjectApproved<T extends NumericString> {
  /**yesno option*/
  value: T;
  /**project approved doc type selected option*/
  selected_doc_type: T;
  /**url file*/
  project_obra_visado: string;
  /**url file*/
  nota_simple_parcela: string;
}



  /** 
   * Services requested by the client like
   * solar panel, water and other
   */
interface ServiceRequest {
  solar_panel: {
    req: boolean
  };
}

export interface Property<T extends NumericString> {
  hasEndorsement: T;
  constructionType: T;
  /**se usa para autopromotor only value of yesNoOptions*/
  project_approved: ProjectApproved<T>;
  area: T;
  type: T;
  province: string;
  community: string;
  city: string;
  zipcode: string;
  address: string;
  value: T;
  house_situation: T;
  /**url file */
  notasimple?: string;
  /**url file */
  arras?: string;
  /**url file */
  reserva?: string;
  /**url file */
  appraisal: string;
  service_request: ServiceRequest
}

export type Residence = (typeof COUNTRIES)[number];

export interface TempUploadItem {
  slug: string;
  url: string;
}

export type InterestTypeMixto = "mixto" | "mixed";

export type InterestType =
  | "fijo"
  | "variable"
  | InterestTypeMixto
  | "todos"
  | "euribor"
  | "";

/** Generic Type to manipulate data ranges */
type Ranges = {
  MIN: number;
  MAX: number;
};

/** With this type we abstract an concept that manage ranges per kind of mortgage modes (Examples: Property value, Data amount, etc.) */
export type MortgageModeRanges = {
  NEW_MORTGAGE: Ranges;
  REMORTGAGE: Ranges;
  CAPITAL_RAISE: Ranges;
  AUTOPROMOTOR: Ranges;
  GLOBAL: Ranges;
};
