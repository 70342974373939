import { ClientProfile, BrokerProfile, RealStateProfile, Profile } from "shared/types/Profile";

export function isClientProfile(p: Profile): p is ClientProfile {
  return (p as ClientProfile).can_create_mortgage !== undefined;
}

export function isBrokerProfile(p: Profile): p is BrokerProfile {
  return (p as BrokerProfile).bidder_type === "broker";
}

export function isAgencyProfile(p: Profile): p is RealStateProfile {
  return "tags" in p;
}