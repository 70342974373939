import {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { MainClientLayout } from "../../../shared/layouts";
import {
  PageTitle,
  MortgageCard,
  TabPanel,
  NotResults,
  SearchBar,
} from "../../../shared/components";
import {
  getMortgages,
  deleteMortgage,
  addMortgage,
  updateMortgage,
} from "../../../services/api";
import {
  useAuthenticated,
  useDialog,
  useLocalStorage,
  useProfile,
} from "../../../shared/hooks";
import { getApplicationType } from "../../../utils/helpers";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { sendTagEvent } from "utils/analytics";
import styles from "./styles.module.scss";
import {
  MortgageData,
  MortgageFormData,
  PostMortgageData,
} from "shared/types/postMortgageData";
import {
  MortgageRes,
  PaginationResWithResult,
} from "shared/types/BaseApiResponse";
import { AxiosResponse } from "axios";
import { mortgageDataToForm, postProcessData } from "utils/mortagageData";
import { RetryDialog } from "./components/RetryDialog";
import Swal from "sweetalert2";
import { IMortgageCardProps } from "shared/components/MortgageCard/types";
import { prepareTempUploads } from "shared/components/MortgageForm/utils";
import { PP_ACTIVE_STEP } from "shared/constants/queryParams";

const Mortgages: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const [role] = useLocalStorage("role");
  const profile = useProfile().profile;
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(3);
  const [mortgages, setMortgages] = useState<MortgageRes[]>([]);
  const [mortgageStatus, setMortgagesStatus] = useState("");
  const [defaultFilter, setDefaultFilter] = useState(0);
  const [initialData, setInitialData] = useState<MortgageFormData>();
  const { isShowing, toggle } = useDialog();
  const [disabled, setDisabled] =
    useState<IMortgageCardProps["disabledButton"]>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [mortgageId, setMortgageId] = useState("");

  useAuthenticated((allow) => {
    if (!allow) navigate("/sign-in");
  }, "client");

  const handleChangeTab = (value: any) => {
    setMortgages([]);
    if (value === 0) {
      getAll({ mode: "active" }, 1, 100);
    }

    if (value === 1) {
      getAll({ mode: "closed_successfully" }, 1, 100);
    }

    if (value === 2) {
      getAll({ mode: "closed" }, 1, 100);
    }

    if (value === 3) {
      getAll({ mode: "default" }, 1, 100);
    }
    setTab(value);
  };

  useEffect(() => {
    if (defaultFilter === 0) {
      getAll({ mode: "default" }, 1, 100);
      setDefaultFilter(1);
    }
  }, [mortgageStatus]);

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  useEffect(() => {
    if (mortgages.length > 0) {
      setDisabled(
        mortgages.filter(
          (item) =>
            (item.status === "active" ||
              item.status === "in_verification_accepted" ||
              item.status === "closed_successfully") &&
            item.mortgage_data.mortgage.mode !== 3 &&
            item.mortgage_data.mortgage.mode !== 4
        ).length > 0
          ? "button_retry"
          : undefined
      );
    }
  }, [mortgages]);

  const getAll = (filter: any, page: number, limit: number) => {
    setIsFetching(true);
    getMortgages(filter, page, limit)
      .then(
        (response: AxiosResponse<PaginationResWithResult<MortgageRes[]>>) => {
          setMortgages(response.data.results);

          // response.data.results.length > 0
          if (response.data.results.length > 0) {
            setMortgagesStatus(response.data.results[0].status);
          }
        }
      )
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      })
      .finally(() => setIsFetching(false));
  };

  const retryMortgage = (mortgageData: MortgageData) => {
    let newMortage = mortgageDataToForm(mortgageData);
    setInitialData(newMortage);
    toggle();
  };

  const onactionDeleteMortage = (mortgageId: string) => {
    deleteMortgage(mortgageId)
      .then(() => {
        // const mortgage = mortgages.find(m => m.id === mortgageId)
        // if(mortgage){
        //   retryMortgage(mortgage.mortgage_data)
        //   return
        // }
        navigate("/client/mortgage/create?step=0");
        // handleChangeTab(3);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al cancelar la hipoteca, por favor inténtalo más tarde 😔"
        );
      });
  };

  const onRetryMortgage = (mortgage: MortgageRes) => {
    if (mortgage.status !== "draft") {
      Swal.fire({
        title: "Estás a punto de reintentar la hipoteca",
        text: "Se tomarán los datos de esta hipoteca y puedes modificarlo si deseas para obtener mejores resultados.",
        icon: "warning",
        customClass: {
          title: `title_modal_cancel_mortagage`,
        },
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result: any) => {
        if (result.isConfirmed) {
          retryMortgage(mortgage.mortgage_data);
        }
      });
    } else {
      setMortgageId(mortgage.id);
      retryMortgage(mortgage.mortgage_data);
    }
  };
  const onConfirmRetryMortage = () => {
    if (initialData && mortgageId === "") {
      const data = { ...initialData };
      var postdata: PostMortgageData = postProcessData(data);
      postdata.mortgage_data.form_init_date = new Date().toISOString();

      postdata.mortgage_data.temp_uploads = prepareTempUploads(postdata);

      addMortgage(postdata)
        .then((response) => {
          toast.success("Solicitud registrada correctamente");
          toggle();

          if (mortgageId) deleteMortgage(mortgageId);

          setMortgages([...mortgages, response.data]);
        })
        .catch((e: any) => {
          if (e.response && e.response.status === 409) {
            toast.error(
              "No es posible crear nuevas subastas cuando ya tienes un proceso de hipoteca abierto."
            );
          } else {
            toast.error(
              "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
            );
          }
          toggle();
        });
    } else {
      onConfirmUpdateMortage();
    }
  };

  const onConfirmUpdateMortage = () => {
    if (initialData) {
      const data = { ...initialData };
      var postdata: PostMortgageData = postProcessData(data);
      postdata.mortgage_data.form_init_date = new Date().toISOString();

      postdata.mortgage_data.temp_uploads = prepareTempUploads(postdata);

      updateMortgage(postdata, mortgageId)
        .then((response) => {
          toast.success("Solicitud registrada correctamente");
          toggle();

          getAll({ mode: "" }, 1, 100);
          navigate("/");
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );

          toggle();
        });
    }
  };

  return (
    <MainClientLayout>
      <>
        <Container>
          <Grid container spacing={2} marginTop={0}>
            <Grid item md={12} sm={12} xs={12}>
              <PageTitle title="Mis solicitudes" />
              <Typography variant="subtitle1" style={{ marginTop: "1rem" }}>
                Subastas
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Grid item md={12} sm={12} xs={12}>
                <SearchBar
                  onChange={function (searchText: string): void {
                    throw new Error("Function not implemented.");
                  }}
                >
                  <>
                    <FormControl sx={{ width: "12rem" }} variant="standard">
                      <InputLabel id="mortgage-status">
                        Estado de hipoteca
                      </InputLabel>
                      <Select
                        className={styles.Select}
                        id="mortgage-status"
                        value={tab}
                        onChange={({ target }) => {
                          handleChangeTab(target.value);
                        }}
                      >
                        <MenuItem value={3}>Todas</MenuItem>
                        <MenuItem value={0}>En subasta</MenuItem>
                        <MenuItem value={2}>Subasta finalizada</MenuItem>
                        <MenuItem value={1}>Completadas</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                </SearchBar>
              </Grid>

              <TabPanel value={tab} index={tab}>
                <Grid container spacing={2} marginTop={1}>
                  {isFetching && mortgages !== undefined ? (
                    Array.apply(null, Array(3)).map((e, i) => (
                      <Grid item md={4} sm={12} xs={12} key={i}>
                        <MortgageCard.Skeleton />
                      </Grid>
                    ))
                  ) : (
                    <>
                      {mortgages.length === 0 && (
                        <Grid item md={12} sm={12} xs={12}>
                          <NotResults
                            title="Sin Resultados"
                            caption="No se han encontrado registros"
                          />
                        </Grid>
                      )}

                      {mortgages.map((m, i: number) => {
                        return (
                          <Grid item md={4} sm={12} xs={12} key={i}>
                            <MortgageCard
                              mortagage={m}
                              documentStatus={m.document_status}
                              status={m.status}
                              date={format(parseISO(m.created), "dd-MM-yyyy")}
                              end={m.ends}
                              id={m.id}
                              title={getApplicationType(
                                m?.mortgage_data.mortgage.mode
                              )}
                              onViewClick={() => {
                                // si tiene el btn view lo mas problable es q es una mortgage activa
                                if (m.status === "paid") {
                                  navigate(`/client/mortgage/${m.id}`);
                                } else {
                                  //active step 2 is oferta aceptada
                                  navigate(`/client/home?${PP_ACTIVE_STEP}=2`);
                                }
                              }}
                              onDeleteCard={() => {
                                onactionDeleteMortage(m.id);
                              }}
                              onRetryCard={() => {
                                onRetryMortgage(m);
                              }}
                              bids={m.bids_count}
                              disabledButton={disabled}
                            />
                          </Grid>
                        );
                      })}
                    </>
                  )}
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
          {initialData && (
            <RetryDialog
              data={initialData}
              showModal={isShowing}
              onClose={toggle}
              onEdit={(m) => {
                setInitialData({ ...m });
              }}
              onSubmit={() => {
                onConfirmRetryMortage();
              }}
            />
          )}
        </Container>
      </>
    </MainClientLayout>
  );
};

export default Mortgages;
