import {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  memo,
  CSSProperties,
} from "react";

import { Box, IconButton, Link, Chip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import { es } from "date-fns/locale";
import { format } from "date-fns";
import { dateFormat, isStrADate } from "utils";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import {
  CellClassParams,
  ColDef,
  Column,
  ColumnMovedEvent,
  EditableCallbackParams,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  ICellRendererParams,
  RowNode,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueSetterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import type {
  CRMTable,
  BidderEvaluationOption,
  CrmFields,
  CRMFlashyCells,
  CRMTableActionsFields,
  CrmFunnel,
} from "shared/types/Crm";
import NotResults from "../../../NotResults";
import { useNavigate } from "react-router-dom";
import { useLocalStorages } from "shared/hooks";
import { COL_STATE, LAST_ROW_TOUCHED } from "shared/constants/localStorage";
import CustomTooltip from "./components/CustomTooltip";
import EditableCellRenderer from "./components/EditableCellRenderer";
import DatePickerEditor from "./components/DatePickerEditor";
import SelectEditor from "./components/SelectEditor";
import CustomLoadingOverlay from "./components/CustomLoadingOverlay";

import "./styles.css";
import { getApplicationType } from "utils/helpers";

type Props = {
  flashyCellsData?: CRMFlashyCells;
  data: CRMTable[] | undefined;
  handleData: (data: CRMTable[], params: ValueSetterParams) => void;
  optionsValue?: BidderEvaluationOption[];
  suppressClickEdit?: boolean;
  headerFields?: CrmFields[];
  closeSuccessFullies: string[];
  fileCols: (string | number)[];
  dateCols: (string | number)[];
  funnels?: CrmFunnel;
  headerNames: string[];
  shouldShowLoadingOverlay: boolean;
};

export default function DataGridClient({
  flashyCellsData,
  data,
  handleData,
  shouldShowLoadingOverlay,
  closeSuccessFullies,
  fileCols,
  dateCols,
  funnels,
  headerFields,
  headerNames,
  optionsValue,
  suppressClickEdit = false,
}: Props) {
  const [colState, setColState] = useLocalStorages(COL_STATE, undefined);
  const [lastRowTouchState, setLastRowTouchState] = useLocalStorages(
    LAST_ROW_TOUCHED,
    undefined
  );
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact<CRMTable>>(null);
  const containerStyle = useMemo<CSSProperties>(
    () => ({ width: "100%", marginTop: "5px", height: "500px" }),
    []
  );
  const gridStyle = useMemo<CSSProperties>(
    () => ({ height: "100%", width: "100%" }),
    []
  );

  const valueSetter = useCallback(
    (params: ValueSetterParams) => {
      const valueChange = params.newValue !== params.oldValue.value;
      setLastRowTouchState(params.data.id);
      if (params.newValue) {
        // este isDate y el control flow parece estar de mas tal vez directo pasar handle Data, probar un refactor y test 🤷‍♂
        const isDate = isStrADate(params.newValue);
        if (isDate) {
          if (valueChange) {
            handleData(data || [], params);
          }
        } else {
          handleData(data || [], params);
        }
      }
      return valueChange;
    },
    [data]
  );

  const cellStyle = (params: CellClassParams<any, any>) => {
    const { value, is_frozen } = params.value;
    if (is_frozen) {
      return {
        color: "#333",
        backgroundColor: "#f1c40f",
      };
    }
    if (isStrADate(value)) {
      return null;
    }
    if (value && optionsValue && optionsValue.length > 0) {
      const idx = optionsValue.map((e) => e.code).indexOf(value);
      if (idx === -1) {
        return null;
      }
      return {
        color: "black",
        backgroundColor: optionsValue[idx].color,
      };
    }
    return null;
  };

  const valueFormatter = (params: ValueFormatterParams) => {
    try {
      if (isStrADate(params.value) || isStrADate(params.value?.value)) {
        return `${format(
          new Date(params.value?.value || params.value),
          dateFormat,
          {
            locale: es,
          }
        )}`;
      }
      // value should not be empty string or it will break, will default to a space
      const value = params.value?.value || " ";
      if (headerNames.includes(params.colDef.field || "")) {
        if (value && optionsValue) {
          const options = optionsValue.find((e) => e.code === value);
          if (options) {
            return `${options.emojis} ${options.label}`;
          }
          return value;
        }
      }
      return value;
    } catch (error) {
      console.log({
        error,
        params,
        column: params.colDef.field,
        value: params.value,
      });
      return " ";
    }
  };

  const cellEditorParams = {
    optionsValue,
    closeSuccessFullies,
    fileCols,
    dateCols,
  };

  const columnDefs = useMemo<ColDef[]>(() => {
    // this column work this way, the first 2 element and the last is default,
    // in the middle of those is dinamic column coming from the back
    const defaultColumn: ColDef[] = [
      {
        field: "Cliente",
        headerTooltip: "cliente",
        editable: false,
        minWidth: 180,
        cellRenderer: memo(
          (params: ICellRendererParams<CRMTable, CRMTableActionsFields>) => {
            const mortgageUrl = `/broker/clients/${params.data?.bid}`;
            const cellValue = params.valueFormatted
              ? params.valueFormatted
              : params.value;
            const hasSeen = params.data?.seen;
            const bidStatus = params.data?.bid_status;

            return (
              <Box>
                <Link
                  href={mortgageUrl}
                  underline="hover"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  sx={{ color: "black", fontWeight: "bolder" }}
                >
                  {cellValue}
                </Link>
                <IconButton onClick={() => navigate(mortgageUrl)}>
                  <LaunchIcon />
                </IconButton>
                {!hasSeen && bidStatus === "accepted" && (
                  <Chip label="Nuevo" color="error" />
                )}
                {bidStatus &&
                  (bidStatus === "collapsed_system" ||
                    bidStatus === "rejected") && (
                    <Chip label="Cliente retirado" color="warning" />
                  )}
              </Box>
            );
          }
        ),
      },
      {
        field: "mortgage_data.mortgage.mode",
        headerName: "Tipo de Hipoteca",
        headerTooltip: "Tipo de Hipoteca",
        editable: false,
        minWidth: 100,
        valueFormatter: (params) => {
          const value = getApplicationType(
            params.data.mortgage_data.mortgage.mode
          );

          return value;
        },

        comparator: (
          valA: CRMTableActionsFields,
          valB: CRMTableActionsFields
        ) => {
          const valueA = getApplicationType(valA);
          const valueB = getApplicationType(valB);
          return valueA.localeCompare(valueB);
        },
      },
      {
        field: "created",
        headerName: "Fecha de adquisición del cliente",
        headerTooltip: "Fecha de adquisición del cliente",
        editable: false,
        valueFormatter,
        minWidth: 136,
      },
    ];

    //add the col from back
    headerFields?.forEach((h) => {
      const cellEditor =
        h.accepted_type === "date" ? DatePickerEditor : SelectEditor;
      const newCol: ColDef = {
        field: h.name,
        headerName: h.label,
        headerTooltip: h.label,
        cellEditorPopup: true,
        cellEditorParams,
        cellEditor,
        cellStyle,
        valueSetter,
        valueFormatter,
        cellRenderer: (
          params: ICellRendererParams<CRMTable, CRMTableActionsFields>
        ) => {
          const cellValue = params.valueFormatted
            ? params.valueFormatted
            : params.value;
          return cellValue;
        },
        comparator: (
          valA: CRMTableActionsFields,
          valB: CRMTableActionsFields
        ) => {
          const valueA = valA.value;
          const valueB = valB.value;
          if (typeof valueA === "string" && typeof valueB === "string") {
            return valueA.localeCompare(valueB);
          }
          if (typeof valueA === "number" && typeof valueB === "number") {
            return valueA - valueB;
          }
          return 0;
        },
        editable: (params: EditableCallbackParams<CRMTable>) => {
          const col = params.colDef.field;
          if (col && params.data) {
            const isEditable = params.data[col].is_editable;
            return isEditable;
          }
          return true;
        },
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            `   <span class='CrmHeaderBadge'>${funnels?.[h.name]}</span>` +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      };
      if (h.accepted_type === "file") {
        newCol.cellRenderer = EditableCellRenderer(h.name, closeSuccessFullies);
      }

      defaultColumn.push(newCol);
    });

    defaultColumn.push({
      field: "diasDesdeAdquisicionCliente",
      headerName: "Días desde adquisición del cliente",
      headerTooltip: "Días desde adquisición del cliente",
      editable: false,
    });

    return defaultColumn;
  }, [data, optionsValue, headerFields, funnels]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      minWidth: 138,
      editable: true,
      resizable: true,
      sortable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      tooltipComponent: CustomTooltip,
      tooltipValueGetter: (params: any) => {
        const colId = params.colDef.field;
        if (!headerNames.includes(colId)) return undefined;

        const paramsVal = params.value;
        const isObject = typeof paramsVal === "object";
        const value = isObject ? paramsVal.value : paramsVal;
        if (!value) return undefined;
        return params.value;
      },
    }),
    [headerNames]
  );

  const loadingOverlayComponent = useMemo<any>(() => {
    return CustomLoadingOverlay;
  }, []);

  const noRowsOverlayComponent = useMemo<any>(() => {
    return NotResults;
  }, []);

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent) => {
      if (gridRef.current && data && data?.length > 0) {
        if (colState) {
          gridRef.current!.columnApi.applyColumnState({
            state: colState,
            applyOrder: true,
          });
        }
        gridRef.current?.columnApi.autoSizeAllColumns(true);
        if (lastRowTouchState) {
          gridRef.current.api.ensureNodeVisible((n: RowNode) => {
            return n.data.id === lastRowTouchState;
          }, "top");
        }
      }
    },
    [data, colState]
  );

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<CRMTable>) => params.data.id.toString();
  }, []);

  const onColumnResized = useCallback((e: ColumnMovedEvent) => {
    setColState(gridRef.current!.columnApi.getColumnState());
  }, []);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay();
      if (shouldShowLoadingOverlay) {
        gridRef.current.api.showLoadingOverlay();
        return;
      }
      if (!shouldShowLoadingOverlay && data?.length === 0) {
        gridRef.current!.api.showNoRowsOverlay();
        return;
      }
      if (!shouldShowLoadingOverlay && data && data.length > 0) {
        gridRef.current.api.hideOverlay();
        return;
      }
    }
  }, [data, shouldShowLoadingOverlay]);

  const flashCellMF = ({
    rowNodes,
    columns,
  }: {
    rowNodes?: RowNode<CRMTable>[];
    columns?: Column[];
  }) => {
    gridRef.current?.api.flashCells({
      rowNodes,
      columns,
      flashDelay: 100,
      fadeDelay: 500,
    });
  };

  const intervalIdRef = useRef<number>();
  useEffect(() => {
    if (flashyCellsData) {
      if (intervalIdRef.current) {
        window.clearInterval(intervalIdRef.current);
      }
      intervalIdRef.current = window.setInterval(() => {
        //loop flashcell data keys
        (Object.keys(flashyCellsData) as Array<keyof CRMFlashyCells>).forEach(
          (k: any) => {
            flashyCellsData[k].forEach((id: number) => {
              const node = gridRef.current?.api.getRowNode(String(id));
              if (node) {
                flashCellMF({ rowNodes: [node], columns: [k] });
              }
            });
          }
        );
      }, 1000);

      return () => {
        window.clearInterval(intervalIdRef.current);
      };
    }
  }, [flashyCellsData]);

  return (
    <div style={containerStyle}>
      <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
        <AgGridReact<CRMTable>
          ref={gridRef}
          animateRows={true}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={data}
          singleClickEdit={true}
          tooltipShowDelay={100}
          tooltipMouseTrack
          getRowId={getRowId}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={{
            title: "Sin Resultados",
            caption: "No se han registrado datos",
          }}
          loadingOverlayComponent={loadingOverlayComponent}
          // enableBrowserTooltips
          suppressClickEdit={suppressClickEdit}
          suppressDragLeaveHidesColumns={true}
          alwaysShowHorizontalScroll={true}
          onFirstDataRendered={onFirstDataRendered}
          onColumnResized={onColumnResized}
          // rowBuffer={25}
        />
      </div>
    </div>
  );
}
