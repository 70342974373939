import {
  useState,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { Grid, Tab, Tabs, TextField, Typography } from "@mui/material";

import DataGridClient from "./components/DataGridClient";
import DataGridClientSkeleton from "./DataGridClientSkeleton";
import { postCrmDataValue } from "services/api";

import {
  CRMTable,
  BidderEvaluationOption,
  CrmFields,
  CrmTabState,
  CRMFlashyCells,
  CRMTableActions,
  CrmFunnel,
} from "shared/types/Crm";

import { useDialog } from "shared/hooks";
import { ValueSetterParams } from "ag-grid-community";
import { differenceInDays, parseISO } from "date-fns";
import { toast } from "react-toastify";

import CustomDialog from "../CustomDialog";
import { isStrADate } from "utils";
import UploadDialog from "./components/UploadDialog";

export type UploadableCol = keyof Pick<
  CRMTable,
  "FEIN" | "tasadoraRealizada" | "notaEncargoFirmada"
>;

type Props = {
  funnels?: CrmFunnel;
  headerFields?: CrmFields[];
  optionsValue?: BidderEvaluationOption[];
  flashyCellsDataState: [
    CRMFlashyCells | undefined,
    Dispatch<SetStateAction<CRMFlashyCells | undefined>>
  ];
  collapsedDataState: [
    CRMTable[] | undefined,
    Dispatch<SetStateAction<CRMTable[] | undefined>>
  ];
  notCollapseDataState: [
    CRMTable[] | undefined,
    Dispatch<SetStateAction<CRMTable[] | undefined>>
  ];
  clientPaidDataState: [
    CRMTable[] | undefined,
    Dispatch<SetStateAction<CRMTable[] | undefined>>
  ];
  tabValue: number;
  changeTabsValue: (e: any, v: any) => void;
  isFetchingData: boolean;
  onSave: () => void;
};

export const COMMNET_MAX_CHAR = 180;

export default function DataGridTabs({
  isFetchingData,
  funnels,
  headerFields,
  optionsValue,
  flashyCellsDataState,
  collapsedDataState,
  notCollapseDataState,
  clientPaidDataState,
  tabValue,
  changeTabsValue,
  onSave,
}: Props) {
  const {
    isShowing: isUploadDialogShowing,
    closeDialog: closeUploadDialog,
    openDialog: openUploadDialog,
  } = useDialog();
  const {
    isShowing: isCommentDialogShowing,
    openDialog: openCommentDialog,
    closeDialog: closeCommentDialog,
  } = useDialog();

  const [collapsedData, setCollapsedData] = collapsedDataState;
  const [notCollapsedData, setNotCollapsedData] = notCollapseDataState;
  const [clientPaidData, setClientPaidData] = clientPaidDataState;
  const [flashyCellsData, setFlashyCellsData] = flashyCellsDataState;

  const optionsCodeVal = useMemo<string[]>(
    () => optionsValue?.map((e) => e.code) || [],
    [optionsValue]
  );

  const headerNames = useMemo<string[]>(
    () => headerFields?.map((e) => e.name) || [],
    [headerFields]
  );

  const closeSuccessFullies = useMemo<string[]>(
    () =>
      optionsValue?.filter((e) => e.closes_successfully).map((e) => e.code) ||
      [],
    [optionsValue]
  );

  const dateCols = useMemo<(keyof CRMTableActions)[]>(
    () =>
      headerFields
        ?.filter((e) => e.accepted_type === "date")
        .map((e) => e.name) || [],
    [headerFields]
  );

  const fileCols = useMemo<(keyof CRMTableActions)[]>(
    () =>
      headerFields
        ?.filter((e) => e.accepted_type === "file")
        .map((e) => e.name) || [],
    [headerFields]
  );

  const paidCols = useMemo<(keyof CRMTableActions)[]>(
    () =>
      headerFields
        ?.filter((e) => e.state_change_if_closed_successfully === "paid")
        .map((e) => e.name) || [],
    [headerFields]
  );

  const handleDataRef = useRef<{
    newData: any[];
    params: ValueSetterParams;
  } | null>(null);

  const handleData = (
    data: CRMTable[],
    params: ValueSetterParams
    // utilInfo: PostCrmInfoFields
  ) => {
    const colId = params.column.getColId() as keyof CRMTable;
    const rowIdx = Number(params.node?.getRowIndexString());
    const newData: any[] = data.slice();

    newData[rowIdx][colId] = params.newValue;
    // save this reference for future use on modal success
    handleDataRef.current = { newData, params };
    if (
      fileCols.includes(colId) &&
      closeSuccessFullies.includes(params.newValue)
    ) {
      openUploadDialog();
      return;
    }
    if (dateCols.includes(colId)) {
      const daysDifference = differenceInDays(
        parseISO(params.newValue),
        parseISO(params.data.created)
      );
      newData[rowIdx].diasDesdeAdquisicionCliente = daysDifference;
      handleDataRef.current = { newData, params };
    }
    openCommentDialog();
    // postCrmValues(); los modales commemnt y upload llamaran postCrmvalues
  };

  const setTablesData = (resetToPreviousData?: boolean) => {
    if (handleDataRef.current) {
      const { params, newData } = handleDataRef.current;
      const colId = params.colDef.field as keyof CRMTableActions;
      if (resetToPreviousData) {
        const rowIdx = Number(params.node?.getRowIndexString());
        newData[rowIdx][colId] = params.oldValue;
        setNotCollapsedData(newData);
        return;
      }
      const isColADate = dateCols.includes(colId);
      const isColAPaid = paidCols.includes(colId);
      const isCollapsed = !closeSuccessFullies.includes(params.newValue);
      if ((isColAPaid || isCollapsed) && !isColADate) {
        setNotCollapsedData((oldDatas) =>
          oldDatas?.filter((e) => e.id !== params.data.id)
        );
      }
      setFlashyCellsData((oldFlashyDatas) => {
        if (oldFlashyDatas && colId) {
          return {
            ...oldFlashyDatas,
            [colId]: oldFlashyDatas[colId].filter((e) => e !== params.data.id),
          };
        }
      });
      handleDataRef.current = null;
    }
  };

  const postCrmValues = (
    {
      successMsg,
      rejectMsg,
      comment,
      fileUrl,
      fileType,
      payload,
    }: {
      successMsg?: string;
      rejectMsg?: string;
      comment?: string;
      fileUrl?: string;
      fileType?: string;
      payload?: any;
    } = {
      successMsg: "Actualización registrada correctamente",
      rejectMsg:
        "Ha ocurrido un error inesperado en la actualización, por favor intente más tarde 😔",
    }
  ) => {
    if (handleDataRef.current) {
      const { params } = handleDataRef.current;
      if (
        !isStrADate(params.newValue) &&
        !optionsCodeVal.includes(params.newValue)
      ) {
        toast.warning(
          "Ha ocurrido un error inesperado en la actualización, se refrescará la página en 5 segundos, por favor inténtalo de nuevo."
        );
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return;
      }
      const colId = params.column.getColId() as UploadableCol;
      postCrmDataValue({
        field: colId,
        value: params.newValue,
        id: params.data.id,
        comment,
        fileUrl,
        fileType,
        payload,
      })
        .then((data: any) => {
          toast.success(successMsg);
          onSave();
          // setTablesData();
        })
        .catch((err: any) => {
          setTablesData(true);
          if (
            err.response &&
            err.response.status === 418 &&
            err.response.data.result === "error" &&
            err.response.data.internal === "fuck_you_error"
          ) {
            toast.warning(
              err.response.data.message || "Hubo un problema con el servidor"
            );
            return;
          }
          toast.error(rejectMsg);
          console.log("error", err);
        });
    }
  };

  const getDataByTabValue = useCallback(
    (value: CrmTabState) => {
      if (value === CrmTabState.NO_COLLAPSE) return notCollapsedData;
      if (value === CrmTabState.COLLAPSE) return collapsedData;
      if (value === CrmTabState.PAID) return clientPaidData;
    },
    [notCollapsedData, collapsedData, clientPaidData]
  );

  const isFileCol = fileCols.includes(
    handleDataRef.current?.params.colDef.field || ""
  );

  //comment dialog
  const [textAreaValue, setTextAreaValue] = useState("");
  const onClickCommentCancel = () => {
    setTablesData(true);
    setTextAreaValue("");
    closeCommentDialog();
  };

  const onClickCommentAccept: any = async () => {
    postCrmValues({ comment: textAreaValue });
    setTextAreaValue("");
    closeCommentDialog();
  };

  return (
    <>
      <Tabs value={tabValue} onChange={changeTabsValue}>
        {["Clientes en trámite", "Dados de baja", "Clientes Cerrados"].map(
          (l) => (
            <Tab key={l} label={l} disabled={isFetchingData} />
          )
        )}
      </Tabs>
      {!headerFields && !optionsValue ? (
        <DataGridClientSkeleton />
      ) : (
        <DataGridClient
          flashyCellsData={flashyCellsData}
          data={getDataByTabValue(tabValue)}
          handleData={handleData}
          funnels={funnels}
          headerNames={headerNames}
          closeSuccessFullies={closeSuccessFullies}
          fileCols={fileCols}
          dateCols={dateCols}
          headerFields={headerFields}
          optionsValue={optionsValue}
          suppressClickEdit={Boolean(tabValue)}
          shouldShowLoadingOverlay={isFetchingData}
        />
      )}

      {isFileCol && (
        <UploadDialog
          open={isUploadDialogShowing}
          handleDataRef={handleDataRef}
          postCrmValues={postCrmValues}
          openUploadDialog={openUploadDialog}
          closeUploadDialog={closeUploadDialog}
          setTablesData={setTablesData}
        />
      )}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={isCommentDialogShowing}
        btnList={[
          { onClick: onClickCommentCancel, title: "Cancelar" },
          {
            onClick: onClickCommentAccept,
            variant: "contained",
            disabled:
              Boolean(handleDataRef.current?.params.newValue === "otro") &&
              textAreaValue.length === 0,
            title: "Aceptar",
          },
        ]}
      >
        <Grid container justifyContent="center" spacing={4}>
          <Grid container item justifyContent="center">
            <img
              src="/assets/img/icons/exclamation_warning.svg"
              alt=""
              width="50%"
            />
          </Grid>
          {!closeSuccessFullies.includes(
            handleDataRef.current?.params.newValue
          ) && (
            <Grid item>
              <Typography variant="h5">¿Estás seguro?</Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1">
              {closeSuccessFullies.includes(
                handleDataRef.current?.params.newValue
              ) ||
              dateCols.includes(
                handleDataRef.current?.params.colDef.field || ""
              )
                ? "Puedes ingresar un comentario (Opcional)."
                : "Está acción no se puede deshacer, este cliente será categorizado como una venta no exitosa."}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              label="Comentario"
              placeholder={`Por favor explica brevemente una razón${
                handleDataRef.current?.params.newValue === "otro" ||
                dateCols.includes(
                  handleDataRef.current?.params.colDef.field || ""
                )
                  ? ", opcional..."
                  : "."
              }`}
              inputProps={{
                maxLength: COMMNET_MAX_CHAR,
              }}
              value={textAreaValue}
              helperText={`${textAreaValue.length}/${COMMNET_MAX_CHAR}`}
              onChange={(e) => {
                setTextAreaValue(e.target.value);
              }}
              required={Boolean(
                handleDataRef.current?.params.newValue === "otro"
              )}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
}
