import { yupResolver } from "@hookform/resolvers/yup";
import CreateIcon from "@mui/icons-material/Create";
import {
  Box,
  InputAdornment,
  Stack,
  TextFieldProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import FormFields from "shared/components/FormFields";
import useMortgageFormContext from "shared/components/MortgageForm/hooks/useMortgageFormContext";
import {
  MortgageMode,
  TestContextExtended,
} from "shared/components/MortgageForm/types";
import { NumericString } from "shared/types/misc";
import { MortgageFormData } from "shared/types/postMortgageData";
import { formatCurrency, formatLocalCurrency } from "utils/helpers";
import { removeNonNumeric } from "utils/string";
import { lessThan, requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";
import {
  calcDataAmount,
  getAllowedMortgageRate,
  getMortgageInitialRange,
  getMortgageRate,
  getTaxAndExpenses,
} from "../../../utils";
import StepLayout from "../../StepLayout";
import { Amount, MortgageDataRow, SubMortgageRow } from "../../MortgageDataRow";
import { amountLTPropertyValueTest } from "../../StepTwo/validation.schema";
import {
  MORTGAGE_AMOUNT_RANGES,
  PROPERTY_VALUE_RANGES,
} from "shared/constants/mortgageForm";
import { getObjectRanges } from "utils/mortagageData";

function PropertyValueForm() {
  const { mortgageFormData, onStepClick } = useMortgageFormContext();
  const mortgageMode = mortgageFormData.mortgage.mode;
  const title =
    mortgageMode === MortgageMode.AUTOPROMOTOR
      ? "¿Cuál es el presupuesto total del proyecto?"
      : "¿Cuál es el valor de la propiedad?";
  const minDataAmount = getObjectRanges(
    mortgageMode,
    MORTGAGE_AMOUNT_RANGES
  ).MIN;
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            value: requiredStringSchema(
              "Ingrese el valor actual de la propiedad"
            ).test(lessThan(PROPERTY_VALUE_RANGES.GLOBAL.MIN)),
          }),
          data: yup.object().shape({
            amount: requiredStringSchema("Ingrese un valor")
              .test(
                lessThan(minDataAmount, () => {
                  return `Lamentablemente, los bancos no suelen financiar hipotecas por montos inferiores a ${formatLocalCurrency(
                    PROPERTY_VALUE_RANGES.GLOBAL.MIN
                  )}`;
                })
              )
              .test(amountLTPropertyValueTest),
            // interest_rate: requiredStringSchema().test(aboveZeroTest),
            mortgage_rate: requiredStringSchema().test(
              "allowed",
              "El importe supera el porcentaje máximo permitido",
              function (val) {
                const { from } = this as yup.TestContext & TestContextExtended;
                const { value } = from[1];
                return Number(val) <= getAllowedMortgageRate(value);
              }
            ),
          }),
        })
      )}
      onNext={onStepClick()}
      onBack={onStepClick(false)}
      title={title}
    >
      <PropertyValueFormHandle />
    </StepLayout>
  );
}

function PropertyValueFormHandle() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const methods = useFormContext<MortgageFormData>();
  const { control, getValues, setValue } = methods;

  const mortgageMode = getValues("mortgage.mode");

  const [
    propertyValue,
    mortgageInitial,
    dataAmount,
    dataTaxAndExpenses,
    dataMortgageRate,
  ] = useWatch({
    control,
    name: [
      "property.value",
      "mortgage_initial",
      "data.amount",
      "data.tax_and_expenses",
      "data.mortgage_rate",
    ],
  });

  const setMortgageRate = ({
    newDataAmount,
    newMortgageInitial,
    newPropertyValue,
  }: {
    newDataAmount: NumericString;
    newMortgageInitial: NumericString;
    newPropertyValue?: NumericString;
  }) => {
    const newMortgageRate = getMortgageRate({
      mortgageMode,
      numerator: newDataAmount,
      propertyValue: newPropertyValue || propertyValue,
      mortgageInitial: newMortgageInitial,
    });
    setValue("data.mortgage_rate", newMortgageRate, {
      shouldValidate: Boolean(newMortgageInitial),
    });
  };

  const setDataAmount = (
    props:
      | {
          newMortgageInitial: NumericString;
          newPropertyValue?: NumericString;
        }
      | {
          newMortgageRate: NumericString;
        }
  ) => {
    let newDataAmount: number = 0;
    if ("newMortgageRate" in props) {
      newDataAmount =
        Number(propertyValue) * (Number(props.newMortgageRate) / 100);
    } else {
      newDataAmount = calcDataAmount({
        propertyValue: props.newPropertyValue || propertyValue,
        mortgageInitial: props.newMortgageInitial,
      });
    }

    setValue("data.amount", newDataAmount, {
      shouldValidate: true,
    });
    return newDataAmount;
  };

  const setTax = (newPropertyVal?: NumericString) => {
    const fees = getTaxAndExpenses(
      newPropertyVal || propertyValue,
      mortgageMode
    );
    setValue("data.tax_and_expenses", Math.ceil(fees).toFixed(2), {
      shouldValidate: true,
    });
  };

  const handleMortgageRateChange: TextFieldProps["onBlur"] = (e) => {
    setTax();
    const newMortgageRate = removeNonNumeric(e.target.value);
    const newDataAmount = setDataAmount({ newMortgageRate });
    const newMortgageInitial = Number(propertyValue) - Number(newDataAmount);
    setValue("mortgage_initial", newMortgageInitial, { shouldValidate: true });
  };

  const handleDataAmountChange: TextFieldProps["onBlur"] = (e) => {
    setTax();
    const newDataAmount = removeNonNumeric(e.target.value);
    const newMortgageInitial = Number(propertyValue) - Number(newDataAmount);
    setValue("mortgage_initial", newMortgageInitial, { shouldValidate: true });
    setMortgageRate({
      newDataAmount,
      newMortgageInitial,
    });
  };

  const handlerMortgageInitialChange: TextFieldProps["onBlur"] = (e) => {
    setTax();
    const newMortgageInitial = removeNonNumeric(e.target.value);
    let newDataAmount: number = 0;
    if ([1, 4].includes(mortgageMode)) {
      newDataAmount = setDataAmount({ newMortgageInitial });
    }
    setMortgageRate({
      newMortgageInitial,
      newDataAmount,
    });
  };

  const onPropertyValueChange = (newPropertyValue: NumericString) => {
    setTax(newPropertyValue);
    const { minAmount, maxAmount } = getMortgageInitialRange(
      getValues(),
      newPropertyValue
    );
    setValue("mortgage_initial", minAmount);

    const newDataAmount = setDataAmount({
      newPropertyValue,
      newMortgageInitial: minAmount,
    });
    setMortgageRate({
      newDataAmount,
      newMortgageInitial: minAmount,
      newPropertyValue,
    });
  };

  const handlerPropertyValueChange: TextFieldProps["onBlur"] = (e) => {
    const newPropertyValue = removeNonNumeric(e.target.value);
    onPropertyValueChange(newPropertyValue);
  };

  return (
    <Stack
      marginTop="1rem"
      justifyContent="flex-start"
      alignItems="center"
      height="100%"
      gap="1rem"
    >
      <Box>
        <FormFields.CircularSlider
          width={150}
          name="property.value"
          label="Valor de la propiedad"
          min={0}
          max={PROPERTY_VALUE_RANGES.GLOBAL.MAX}
          dataIndex={Number(propertyValue)}
          onChange={(val: number) => {
            if (val !== Number(propertyValue)) {
              const roundVal = Math.round(val / 1000) * 1000;
              onPropertyValueChange(roundVal);
              // it need to set value separely so circular slider and editablelabel work separetly
              setValue("property.value", roundVal, { shouldValidate: true });
            }
          }}
          renderLabelValue={
            <FormFields.CircularSlider.EditableLabel
              valueFontSize="23px"
              label="Valor de la propiedad"
              value={formatCurrency(propertyValue)}
              name="property.value"
              maxInput={PROPERTY_VALUE_RANGES.GLOBAL.MAX}
              textFieldProps={{
                onBlur: handlerPropertyValueChange,
                autoFocus: true,
              }}
              hideLabel
            />
          }
          labelColor="#005a58"
          labelBottom={true}
          knobColor="#9f9c49"
          knobSize={32}
          progressColorFrom="#f2ee87"
          progressColorTo="#f2ee87"
          progressSize={16}
          trackColor="#eeeeee"
          trackSize={14}
        />
      </Box>
      <Typography variant="body2">
        Mueve la rueda o haz clic en el valor de propiedad para comenzar. En
        base a ese valor, te presentamos los montos mínimos. ¿No quieres el
        mínimo? Pues haz clic en el <CreateIcon fontSize="small" /> e ingresa
        tus valores deseados.
      </Typography>
      {isMobile ? (
        <Stack id="property-val-data-mobile" width="100%">
          <MortgageDataRow
            label="Ahorro a aportar"
            tooltipText="Será la cantidad con la que deberás contar para optar por la hipoteca solicitada."
            value={formatLocalCurrency(
              Number(mortgageInitial) + Number(dataTaxAndExpenses)
            )}
          >
            <SubMortgageRow
              label="Valor de entrada a la hipoteca"
              value={formatLocalCurrency(mortgageInitial)}
              name="mortgage_initial"
              textFieldProps={{
                onBlur: handlerMortgageInitialChange,
                autoFocus: true,
              }}
              editable={Boolean(propertyValue)}
            />
            <Stack
              direction="row"
              justifyContent="flex-end"
              paddingX={3}
              marginY="-1.2rem"
            >
              <Amount value={"+"} label="plus-sign" />
            </Stack>
            <SubMortgageRow
              label="Gastos e impuestos"
              value={formatLocalCurrency(dataTaxAndExpenses)}
            />
          </MortgageDataRow>
          <MortgageDataRow
            label="Importe de la hipoteca"
            tooltipText="Es la cantidad que se le solicitará al banco para tu financiación. En este importe no se incluyen los gastos derivados de la operación."
            value={formatLocalCurrency(dataAmount)}
            name="data.amount"
            textFieldProps={{ onBlur: handleDataAmountChange, autoFocus: true }}
            editable={Boolean(propertyValue)}
          />
          <MortgageDataRow
            label="Porcentaje de la hipoteca"
            tooltipText="Aunque las entidades bancarias estudian cada caso de forma independiente, generalmente el importe máximo a financiar para una vivienda habitual es entre el 80 al 90%"
            value={(dataMortgageRate || 0) + "%"}
            name="data.mortgage_rate"
            textFieldProps={{
              onBlur: handleMortgageRateChange,
              autoFocus: true,
              InputProps: {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              },
            }}
            editable={Boolean(propertyValue)}
          />
        </Stack>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "repeat(3, min-content)",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Stack
            id="valor + fee tax"
            sx={{
              gridColumn: "1",
              gridRow: "1",
            }}
          >
            <MortgageDataRow
              label="Valor de entrada a la hipoteca"
              value={formatLocalCurrency(mortgageInitial)}
              name="mortgage_initial"
              textFieldProps={{
                onBlur: handlerMortgageInitialChange,
                autoFocus: true,
              }}
              editable={Boolean(propertyValue)}
            />
            <Stack
              direction="row"
              justifyContent="flex-end"
              paddingX={3}
              marginY="-0.5rem"
            >
              <Amount value={"+"} label="plus-sign" />
            </Stack>
            <MortgageDataRow
              label="Gastos e impuestos"
              value={formatLocalCurrency(dataTaxAndExpenses)}
            />
          </Stack>

          <Box id="ahorro-a-aportar">
            <MortgageDataRow
              label="Ahorro a aportar"
              // value={mortgageInitial + dataTaxAndExpenses}
              value={formatLocalCurrency(
                Number(mortgageInitial) + Number(dataTaxAndExpenses)
              )}
              iconSx={{ transform: "scaleY(4)" }}
              // textFieldProps={{ onBlur: handleAhorroAportarChange,autoFocus: true }}
              // name="mortgage_initial"
            />
          </Box>
          <Box sx={{ gridColumn: "3", gridRow: "1" }}>
            <Typography variant="body2" color="GrayText">
              Será la cantidad con la que deberás contar para optar por la
              hipoteca solicitada.
            </Typography>
          </Box>
          <Box
            id="importe-hipoteca"
            sx={{
              gridColumn: "2",
              gridRow: "2",
            }}
          >
            <MortgageDataRow
              label="Importe de la hipoteca"
              value={formatLocalCurrency(dataAmount)}
              name="data.amount"
              textFieldProps={{
                onBlur: handleDataAmountChange,
                autoFocus: true,
              }}
              editable={Boolean(propertyValue)}
            />
          </Box>
          <Box sx={{ gridColumn: "3", gridRow: "2" }}>
            <Typography variant="body2" color="GrayText">
              Cantidad solicitada como préstamo.
            </Typography>
          </Box>
          <Box id="porcentaje-hipoteca" sx={{ gridColumn: "2", gridRow: "3" }}>
            <MortgageDataRow
              label="Porcentaje de la hipoteca"
              value={(dataMortgageRate || 0) + "%"}
              name="data.mortgage_rate"
              textFieldProps={{
                onBlur: handleMortgageRateChange,
                autoFocus: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                },
              }}
              editable={Boolean(propertyValue)}
            />
          </Box>
          <Box sx={{ gridColumn: "3", gridRow: "3" }}>
            <Typography variant="body2" color="GrayText">
              Porcentaje del total de la vivienda que estás solicitando
              financiar. El máximo recomendado está entre el 80 y 95%, dependerá
              de la entidad.
            </Typography>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

export default PropertyValueForm;
