import axios, { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/react";

import { setTimeout } from "timers";
import subject, { TOGGLE_LOADER } from "./subjects";

let ignoreLoader: any = false;

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "X-Version-App": process.env.REACT_APP_MIN_VERSION_FRONT_APP || "",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(reqInterceptor, reqErrorInterceptor);

instance.interceptors.response.use(
  function (config) {
    setTimeout(() => {
      subject.send(TOGGLE_LOADER, false);
    }, 2000);

    return config;
  },
  function (error) {
    setTimeout(() => {
      subject.send(TOGGLE_LOADER, false);
    }, 2000);

    // Set sentry log
    Sentry.withScope((scope) => {
      const username = localStorage.getItem("username") || "";
      const email = localStorage.getItem("email") || "";
      const role = localStorage.getItem("role") || "";
      if (role) scope.setTag("role", role);
      scope.setUser({ username, email });
      Sentry.captureException(error, true as any);
    });

    // Return to login
    if (
      (error.response && error.response?.status === 401) ||
      error.response?.status === 403
    ) {
      localStorage.removeItem("access_token");
      localStorage.clear();
      console.log("location", window.location);
      // window.location.href = "/sign-in";
      return;
    }
    if (error.response && error.response?.status === 417) {
      localStorage.removeItem("access_token");
      localStorage.clear();
      window.location.href = `/sign-in?t=${new Date().getUTCMilliseconds()}`;
      return;
    }

    return Promise.reject(error);
  }
);

const cleanAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "X-Version-App": process.env.REACT_APP_MIN_VERSION_FRONT_APP || "",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
// cleanAxios.interceptors.request.use(reqInterceptor, reqErrorInterceptor)
export const axiosWithoutResInterceptor = cleanAxios;

export const setTokenSSID = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  axiosWithoutResInterceptor.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const delTokenSSID = () => {
  delete instance.defaults.headers.common.Authorization;
  delete axiosWithoutResInterceptor.defaults.headers.common.Authorization;
};

export const setIgnoreLoader = (value: string) => {
  ignoreLoader = value;
};

export function reqInterceptor(config: AxiosRequestConfig) {
  if (!ignoreLoader) {
    subject.send(TOGGLE_LOADER, true);
  }
  return config;
}

export function reqErrorInterceptor(error: any) {
  subject.send(TOGGLE_LOADER, false);
  return Promise.reject(error);
}
export default instance;
