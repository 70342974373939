import React, { FunctionComponent, ReactComponentElement } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// https://github.com/alexplumb/material-ui-phone-number/issues/111
import MuiPhoneNumber from "material-ui-phone-number-2";

import { MainRealEstateLayout, NotAuthLayout } from "../../../shared/layouts";
import { useLocalStorage } from "../../../shared/hooks";

import styles from "./styles.module.scss";

import { NumberBox } from "../Components";
import dataStorageService from "services/api/dataStorage.service";
import { MORTGAGE_FORM_VALUE } from "shared/constants/localStorage";

const { REACT_APP_SPECIAL_AGENT }: any = process.env;

const ApplyMortage: FunctionComponent<{}> = (
  props
): ReactComponentElement<any> => {
  const [email] = useLocalStorage("email");
  const navigate = useNavigate();
  return (
    <MainRealEstateLayout>
      <Container className={styles.Wrapper}>
        <Grid container className={styles.fullHeight}>
          <Grid item md={12} xs={12} sm={12}>
            <Typography
              color="primary"
              variant="h5"
              display="block"
              marginBottom="1rem"
              textAlign="center"
              className={styles.title}
            >
              ¿Tu cliente ya tiene la vivienda escogida? <br></br>¡A por la
              hipoteca!
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} sm={12} container>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src="https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcolibid_thum_up.gif"
                alt=""
                width="70px"
                height="70px"
              />
              <Typography variant="h5" display="block">
                En 3 días podrá elegir una oferta
              </Typography>
            </Grid>
          </Grid>
          {email !== REACT_APP_SPECIAL_AGENT ? (
            <Grid item md={12} xs={12} sm={12} container spacing={2}>
              <Grid item xs={6}>
                <Button
                  size="large"
                  onClick={() => {
                    navigate("/agent/clients");
                  }}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, height: "56px" }}
                >
                  CLIENTE YA INGRESADO
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="large"
                  onClick={() => {
                    dataStorageService
                      .delData({ key: MORTGAGE_FORM_VALUE })
                      .then(() => {
                        localStorage.removeItem("isEditingForm");
                        navigate("/agent/apply/form/");
                      });
                  }}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, height: "56px" }}
                >
                  Cliente Nuevo
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                size="large"
                onClick={() => {
                  dataStorageService
                    .delData({ key: MORTGAGE_FORM_VALUE })
                    .then(() => {
                      navigate("/agent/apply/form/");
                    });
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, height: "56px" }}
              >
                Cliente Nuevo
              </Button>
            </Grid>
          )}
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            container
            padding="1rem"
            textAlign="center"
            spacing={2}
          >
            <Typography
              variant="body1"
              display="block"
              className={styles.paragrahp}
            >
              Como son ofertas reales, para aplicar a la subasta debes tener los
              datos concretos del inmueble. Si aún no los tienes, puedes validar
              con el prescoring primero.
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} sm={12} container>
            {email !== REACT_APP_SPECIAL_AGENT ? (
              <>
                <Grid item xs={6}>
                  <NumberBox
                    number="01"
                    colorNumber="#D8D8F6"
                    title="Completa
                el formulario"
                    paragraph="Deberás completarlo junto con el cliente. ¡Sólo os llevará 4 minutos!"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberBox
                    number="02"
                    colorNumber="#01807A"
                    title="Síguelo en 
                tu panel"
                    paragraph={`Quedará registrado en tu panel de "mis clientes" para que puedas hacer su seguimiento.`}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <NumberBox
                  number="01"
                  colorNumber="#D8D8F6"
                  title="Completa
                el formulario"
                  paragraph="Deberás completarlo junto con el cliente. ¡Sólo os llevará 4 minutos!"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainRealEstateLayout>
  );
};

export default ApplyMortage;
