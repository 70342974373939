import { Box, Tabs, Tab, Divider } from "@mui/material";
import { useState } from "react";
import { DROPZONE_ACCEPTED_FILE } from "shared/constants/general";
import BidFiles, { IBidFilesProps } from "../BidFiles";
import TabPanel from "../TabPanel";
import UploadManager, { IUploadManagerProps } from "../UploadManager";

export type BidFileSectionProps = {
  bidFilesProps: IBidFilesProps;
  uploadManagerProps: Omit<
    IUploadManagerProps,
    "denyFiles" | "onlyAllow" | "acceptedFiles"
  >;
}

export default function BidFileSection({
  bidFilesProps,
  uploadManagerProps,
}: BidFileSectionProps ) {
  const isDocumentMode = uploadManagerProps.mode === "documents";
  const [denyFiles, setDenyFiles] = useState<string[]>(
    isDocumentMode ? ["coli_certificate"] : []
  );
  const [acceptedFiles, setAcceptedFiles] = useState<any>(
    isDocumentMode ? DROPZONE_ACCEPTED_FILE : {}
  );
  const [allowFiles, setAllowFiles] = useState<string[]>(
    !isDocumentMode ? ["coli_certificate"] : []
  );
  const [tab, setTab] = useState(0);

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tab}
          onChange={(e: any, value) => {
            setTab(value);
          }}
        >
          <Tab label="Documentos" />
          <Tab label="Subir archivos" />
        </Tabs>
      </Box>

      <TabPanel value={tab} index={0}>
        <>
          <Divider component="br" />
          <BidFiles {...bidFilesProps} />
        </>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <>
          <UploadManager
            {...uploadManagerProps}
            acceptedFiles={acceptedFiles}
            onlyAllow={allowFiles}
            denyFiles={denyFiles}
            onFinish={() => {
              setTab(0);
              uploadManagerProps.onFinish();
            }}
          />
        </>
      </TabPanel>
    </>
  );
}