import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import React from "react";

export default function BidResumeSkeleton() {
  return (
    <Card>
      <CardContent>
        <Stack spacing={1} sx={{ margin: 1 }}>
          <Stack direction="row" height={80} spacing={1}>
            <Skeleton variant="rectangular" height="100%" width="100%" />
            <Skeleton variant="rectangular" height="100%" width="100%" />
          </Stack>
          <Stack
            direction="row"
            height={25}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Skeleton variant="rectangular" height={20} width={100} />
            <Skeleton variant="rectangular" height={20} width={100} />
            <Skeleton variant="rectangular" height={20} width={100} />
            <Skeleton variant="rectangular" height={20} width={100} />
          </Stack>
          <Stack direction="row" height={80} spacing={1}>
            <Skeleton variant="rectangular" height="100%" width="100%" />
            <Skeleton variant="rectangular" height="100%" width="100%" />
          </Stack>
          {Array.apply(null, Array(9)).map((e) => (
            <Skeleton variant="rectangular" height={30} width="100%" />
          ))}
          {Array.apply(null, Array(2)).map((e) => (
            <Skeleton variant="rectangular" height={50} width="100%" />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
