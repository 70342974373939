import React, { FunctionComponent, ReactElement } from "react";
import {
  Box,
  Dialog,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";

import styles from "./styles.module.scss";

export interface ICertificateDisclaimerModalProps {
  show: boolean;
  onClose: () => void;
  onContinue: () => void;
}
export const CertificateDisclaimerModal: FunctionComponent<
  ICertificateDisclaimerModalProps
> = (props): ReactElement<any> => {
  const [checked, setChecked] = React.useState(false);

  const handleAccept = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Dialog
      open={props.show}
      onClose={() => props.onClose()}
      maxWidth="md"
      fullWidth={true}
    >
      <Box className={styles.DialogDetails}>
        <Box className={styles.DialogContent}>
          <CertificateDisclaimer />
        </Box>
        <Box className={styles.DialogFooter}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleAccept} />}
              label="Acepto los términos y condiciones de intermediación"
            />
          </FormGroup>
          <Divider component="br" />
          <Button
            variant="contained"
            disabled={!checked}
            fullWidth
            onClick={() => {
              props.onContinue();
            }}
          >
            CONTINUAR CON LA CARGA DEL CERTIFICADO
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const CertificateDisclaimer: FunctionComponent = (): ReactElement<any> => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<b>Términos y Condiciones de intermediación</b>

    <p class="msonormal" style="line-height: 115%; margin-bottom: 0in">
      <span lang="es-ES">CAPACIDAD. ACEPTACIÓN. CONSENTIMIENTO.</span>
      <span lang="es-ES">
        AL CREAR UNA CUENTA Y PARTICIPAR EN LOS SERVICIOS DE SUBASTA Y
        POSTERIOR OFERTA Y CONTRATACIÓN DE UNA HIPOTECA, TANTO EL
        COLABORADOR COMO EL USUARIO DECLARAN QUE TIENEN LA CAPACIDAD
        SUFICIENTE PARA ELLO Y ACEPTAN, COMPRENDEN Y SE OBLIGAN A CUMPLIR LAS
        SIGUIENTES CONDICIONES, AS&Iacute; COMO CUALQUIER OTRO T&Eacute;RMINO
        AQU&Iacute; INCLUIDO, YA SEA EXPL&Iacute;CITAMENTE O POR REFERENCIA.
        SIN LIMITACI&Oacute;N DE LO ANTERIOR, EL ACUERDO COMPLETO ENTRE LAS
        PARTES (
      </span>
      <span lang="es-ES">
        <i>USUARIO-COLIBID-COLABORADOR</i>
      </span>
      <span lang="es-ES">
        ) CONSISTE EN: (I) LOS SIGUIENTES T&Eacute;RMINOS DE
        INTERMEDIACI&Oacute;N; (II) EL ACUERDO DE LICENCIA DE USUARIO FINAL;
        (III) LA POL&Iacute;TICA DE PRIVACIDAD (IV) LA POL&Iacute;TICA DE
        COOKIES (V) AS&Iacute; COMO CUALQUIER ANEXO O DOCUMENTO INCORPORADO
        POR REFERENCIA O ADJUNTO.
      </span>
    </p>
    <p class="msonormal" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msonormal" style="line-height: 115%; margin-bottom: 0in">
      <span lang="es-ES">
        <b>GENERAL.</b>
      </span>
      <span lang="es-ES">
        Con&nbsp;Colibid,&nbsp;son&nbsp;los&nbsp;bancos&nbsp;los&nbsp;que&nbsp;compiten&nbsp;para&nbsp;ofrecer&nbsp;la&nbsp;mejor&nbsp;hipoteca.
        Por ello, estos t&eacute;rminos de intermediaci&oacute;n establecen
        las condiciones en las que Colibid desempe&ntilde;a su papel de
        intermediario entre los Usuarios (o Usuario) y las entidades bancarias
        y/o 
      </span>
      <span lang="es-ES">
        <i>broker</i>
      </span>
      <span lang="es-ES">
        (en lo sucesivo, los &ldquo;Colaboradores&rdquo;). Los Usuarios a
        trav&eacute;s de la plataforma automatizada de Colibid, proporcionan
        la informaci&oacute;n necesaria y entre ella, su certificado digital
        para que Colibid lo procese en la misma de manera que los
        Colaboradores puedan estudiar su perfil para la oferta y posterior
        concesi&oacute;n de la hipoteca a la que los Usuarios quieran optar
        tras el fin de la subasta.
      </span>
    </p>
    <p class="msonormal" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msonormal"
      style="line-height: 115%; margin-top: 0.08in; margin-bottom: 0in"
    >
      En este sentido, los presentes t&eacute;rminos y condiciones (el &ldquo;
      <i>acuerdo&rdquo;</i>) establecen (i) la relaci&oacute;n de Colibid con
      los Usuarios y los Colaboradores en t&eacute;rminos de desempe&ntilde;ar
      un papel de intermediario entre ellos; y (iii) la autorizaci&oacute;n de
      uso del DNI as&iacute; como del del certificado electr&oacute;nico de
      los Usuarios en favor de Colibid.
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0.27in">
      <b>1.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      <span lang="es-ES">
        <b>EXCLUSIVIDAD</b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>1.1.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        Colibid no representa ni act&uacute;a en nombre ni de los Usuarios ni
        de los Colaboradores.
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      Tanto los Usuarios como los Colaboradores designan a Colibid como
      intermediario para llevar a cabo la oferta y contrataci&oacute;n de
      hipotecas a trav&eacute;s del procedimiento previsto en su Plataforma.
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>1.2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        Colibid acepta su nombramiento como intermediario de los servicios.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>1.3.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        La designaci&oacute;n aqu&iacute; realizada debe considerarse como no
        exclusiva; pudiendo las partes acordar y ejecutar cualquier
        operaci&oacute;n por su cuenta, fuera de la plataforma Colibid.
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>FUNCIONAMIENTO DE LA PLATAFORMA COLIBID </b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>2.1.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        Colibid pone a disposici&oacute;n de los Usuarios una plataforma
        automatizada de subasta de hipotecas a trav&eacute;s de la cual los
        Colaboradores pujar&aacute;n durante tres (3) d&iacute;as laborables
        para tener a dichos Usuarios como clientes.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>2.2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        Una vez la puja haya finalizado, el Usuario ser&aacute; quien elija la
        oferta del Colaborador con quien quiere contratar la hipoteca.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>2.3.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        La comunicaci&oacute;n al Colaborador ganador se llevar&aacute; a cabo
        por Colibid gracias a la automatizaci&oacute;n de su plataforma.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>2.4.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        Posteriormente, el Usuario y el Colaborador proceder&aacute;n a
        formalizar la hipoteca mediante los mecanismos y la
        documentaci&oacute;n legal prevista para ello.
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>3.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>OBLIGACIONES </b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>3.1.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Intermediaci&oacute;n </b>
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      Colibid tomar&aacute; todas las medidas razonables que se consideren
      necesarias para promover y garantizar el procedimiento de subasta y
      posteriormente, procurar la contrataci&oacute;n de la hipoteca entre las
      partes implicadas: Usuarios y Colaboradores. Para ello, Colibid pone a
      disposici&oacute;n de ambas partes, una plataforma automatizada de
      desarrollo propio.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      No obstante, Colibid no podr&aacute; asegurar que los perfiles de los
      Usuarios, as&iacute; como las ofertas y condiciones de los Colaboradores
      se ajusten a lo pretendido por cada una de las Partes y con ello, la
      satisfacci&oacute;n de un contrato de concesi&oacute;n y/o mejora de
      hipoteca.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>3.2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Formulario y DNI del Usuario</b>
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      Para acceder a la plataforma de Colibid, en primer lugar, los Usuarios
      deber&aacute;n registrarse rellenarse el formulario de contacto
      facilitado a tal efecto en el apartado &ldquo;acceso&rdquo; de la
      p&aacute;gina web de Colibid (app.colibid.com/sign-up). Asimismo, y por
      razones de seguridad, es imprescindible aportar el Documento Nacional de
      Identidad (DNI) u otro documento nacional legal y en vigencia que
      acredite debidamente la identidad del Usuario.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      La no aportaci&oacute;n de dicho documento supondr&aacute; la
      imposibilidad del Usuario de participar en las subastas promovidas en la
      plataforma.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>3.3.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>
          Certificado digital del Usuario e informaci&oacute;n derivada del
          mismo
        </b>
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      <span lang="es-ES">
        El Usuario deber&aacute; aportar su certificado digital (solo se
        aceptar&aacute;n certificado cualificado de firma electr&oacute;nica,
        emitidos por prestadores cualificados de servicios de confianza) para
        que los Colaboradores que han pujado para ofertar a aquel la mejora o
        concesi&oacute;n de la hipoteca, puedan consultar su perfil a fin de
        valorar y concluir si se tratar de un/a &ldquo;candidato/a
        id&oacute;neo&rdquo; con base en sus pol&iacute;ticas y procedimientos
        internos para proceder a formalizar el contrato. Dichos proveedores de
        confianza, ser&aacute;n en todo caso, los que se encuentren dentro de
        las 
      </span>
      <span lang="es-ES">
        <i>
          <a href="https://esignature.ec.europa.eu/efda/tl-browser/#/screen/home" target="n_blank">
          European Union Trusted Listed
          </a>
        </i>.
      </span>
      <span lang="es-ES">
        Para el caso de los Usuarios con documento de identidad
        espa&ntilde;ol, se recomendar&aacute; que el certificado
        electr&oacute;nico haya sido proporcionado por 
        <a href="https://www.sede.fnmt.gob.es/certificados/persona-fisica" target="n_blank">
          CERES-FNMT
        </a>
        o el contenido en el 
        <a href="https://www.sede.fnmt.gob.es/certificados/persona-fisica/obtener-certificado-con-dnie" target="n_blank">
          DNIe
        </a>
        , sin perjuicio de cualesquiera otros proveedores recomendados en la
        siguiente 
        <a href="https://esignature.ec.europa.eu/efda/tl-browser/#/screen/tl/ES" target="n_blank">
          lista
        </a>
        .
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      El procedimiento de oferta-estudio del perfil- aceptaci&oacute;n (o
      rechazo, en su caso) se llevar&aacute; a cabo transcurridos tres (3)
      d&iacute;as, que marcar&aacute;n el final de la subasta.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="margin-left: 0.99in; margin-bottom: 0in"
    >
      <span lang="es-ES">
        <b>3.3.1.</b>
      </span>
      <span style="font-variant: normal">
      <span lang="es-ES">
        <b>Cesi&oacute;n.</b>
      </span> 
      <span lang="es-ES">
        El Usuario autoriza a Colibid el uso y el almacenamiento de su
        certificado digital en la Plataforma para proceder, en su nombre, a
        solicitar la informaci&oacute;n y documentaci&oacute;n de las
        administraciones p&uacute;blicas y otros organismos que los
        Colaboradores encuentren necesarias para llevar a cabo el mencionado
        an&aacute;lisis de idoneidad. A t&iacute;tulo enunciativo, pero no
        limitativo la informaci&oacute;n a solicitar de las administraciones
        p&uacute;blicas ser&aacute;:
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.49in; margin-bottom: 0in"
    >
      <span lang="es-ES">-</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        Datos identificativos y de contacto (nombre, apellidos, domicilio,
        etc.)
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.49in; margin-bottom: 0in"
    >
      <span lang="es-ES">-</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        Datos acerca de circunstancias sociales y personales (estado civil,
        sexo, nacionalidad, etc.)
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.49in; margin-bottom: 0in"
    >
      <span lang="es-ES">-</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        Datos acad&eacute;micos y profesionales (estudios, vida laboral) 
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.49in; margin-bottom: 0in"
    >
      <span lang="es-ES">-</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        Datos econ&oacute;micos (comprobante de ingresos: n&oacute;minas,
        recibos trimestrales, pensiones, ayudas, pr&eacute;stamos concedidos,
        bienes patrimoniales, etc.)
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.49in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      La incorporaci&oacute;n del certificado digital del Usuario en la
      Plataforma de Colibid se podr&aacute; llevar a cabo de dos diferentes
      formas:
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.23in; margin-bottom: 0in"
    >
      <span lang="es-ES">(a)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        <u>Manualmente</u>
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.23in; margin-bottom: 0in"
    >
      <a name="_anchor_1"></a>
      <span lang="es-ES">
        Colibid proceder&aacute; a realizar el almacenamiento del certificado
        electr&oacute;nico (as&iacute; como su clave privada) de los Usuarios
        y su posterior exportaci&oacute;n como un archivo .pk12.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.23in; margin-bottom: 0in"
    >
      <span lang="es-ES">
        Tanto el certificado como la clave privada del Usuario ser&aacute;n
        almacenados en en servidores privados gestionados por Colibid y
        alojados en nuestro proveedor de Cloud Computing, separados de
        aquellos en los que almacenamos datos o el c&oacute;digo a partir del
        cual se ejecuta la plataforma, el cual se encuentra [cifrado/protegido
        mediante t&eacute;cnicas de encriptaci&oacute;n avanzadas y al que
        solo puede accederse a trav&eacute;s de solicitudes que nuestra
        plataforma realice a esa base de datos o manualmente en caso de
        incidencias o brechas de seguridad].
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.23in; margin-bottom: 0in"
    >
      <a name="_anchor_2"></a>
      <span lang="es-ES">(b)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        <u>De manera automatizada</u>
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.23in; margin-top: 0.08in; margin-bottom: 0in"
    >
      En caso de que el procesamiento, almacenamiento y uso del certificado
      digital de los Usuarios se llevar&aacute; a cabo de manera automatizada,
      Colibid contratar&aacute; proveedores de firma y certificados digitales
      cualificados que cumplan con la legislaci&oacute;n aplicable en ese
      momento y cualquier otra de obligado cumplimiento, as&iacute; como con
      las medidas oportunas y adecuadas para el tratamiento de
      informaci&oacute;n y seguridad.
    </p>
    <p class="msonormal" style="margin-top: 0.08in; margin-bottom: 0.27in">
      <span lang="es-ES">
        <b>3.2.2. Precio. </b>
      </span>
      <span lang="es-ES">
        La utilizaci&oacute;n de la plataforma y el acceso a sus servicios de
        subasta y a las ofertas de los Colaboradores es totalmente gratuita
        para el Usuario. La contraprestaci&oacute;n de Colibid por sus labores
        de intermediaci&oacute;n ser&aacute; abonada por los Colaboradores, en
        los t&eacute;rminos y condiciones que de manera particular dichas
        partes acuerden.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0.27in">
      <span lang="es-ES">
        <b>4.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>INDEMNIZACI&Oacute;N. GASTOS.</b>
      </span>
      <span lang="es-ES">
        Los Usuarios y los Colaboradores defender&aacute;n e
        indemnizar&aacute;n a Colibid contra las reclamaciones, acciones,
        procedimientos, p&eacute;rdidas, da&ntilde;os, gastos y costes
        (incluyendo, sin limitaci&oacute;n, las costas judiciales y los
        honorarios razonables de los abogados) presentados por un tercero que
        surjan de o en relaci&oacute;n con los presentes t&eacute;rminos y
        condiciones, y en consecuencia su papel de intermediaci&oacute;n. En
        este sentido, los Usuarios y Colaboradores -a t&iacute;tulo
        enunciativo, pero no limitativo- deber&aacute;n:
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">(a)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        notificar a Colibid cualquier reclamaci&oacute;n de este tipo;
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">(b)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        proporcionar una cooperaci&oacute;n razonable a Colibid en la defensa
        y resoluci&oacute;n de dicha reclamaci&oacute;n.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>5.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>VIGENCIA. TERMINACI&Oacute;N.</b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>5.1.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Vigencia y terminaci&oacute;n del contrato. </b>
      </span>
      <span lang="es-ES">
        El presente Contrato comenzar&aacute; en la fecha en la que se acepten
        los presentes t&eacute;rminos y condiciones 
      </span>
      y permanecer&aacute; en vigor hasta el momento en que el Usuario
      formalice los tr&aacute;mites de concesi&oacute;n o mejora de la
      hipoteca contratada con el Colaborador escogido posteriormente a la
      superaci&oacute;n del examen de idoneidad y a la subasta. A partir de
      ese momento, los presentes t&eacute;rminos y condiciones quedar&aacute;n
      sin efecto salvo que el Usuario vuelva a optar, mediante el
      procedimiento establecido, por una nueva hipoteca, la mejora de la que
      ya ha adquirido u otra a la que se encuentre sujeto.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      No obstante, tras la terminaci&oacute;n del presente acuerdo,
      &eacute;ste continuar&aacute; en vigor respecto a aquellas obligaciones
      que se deban en virtud del mismo a cualquiera de las partes y que
      estuvieran en curso en el momento de su terminaci&oacute;n, y ello hasta
      que dichas obligaciones hayan sido totalmente satisfechas.
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>5.2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Fuerza mayor. </b>
      </span>
      <span lang="es-ES">
        Si cualquiera de las Partes se retrasa o ve impedido en el
        cumplimiento de cualquiera de sus obligaciones en virtud del presente
        Acuerdo, por un evento, circunstancia o causa m&aacute;s all&aacute;
        de su control razonable que, por su naturaleza, no podr&iacute;a haber
        sido previsto o, si era previsible, era inevitable (evento de fuerza
        mayor), entonces, sin perjuicio de lo anterior, la parte afectada no
        ser&aacute; responsable ante la otra Parte ni incurrir&aacute; en
        incumplimiento por la demora o impedimento en el cumplimiento de
        cualquiera de sus obligaciones en virtud del presente acuerdo y el
        plazo para el cumplimiento de la obligaci&oacute;n afectada se
        prorrogar&aacute; por el per&iacute;odo que sea razonable para
        permitir a la parte, haciendo todos los esfuerzos razonables, cumplir
        con esa obligaci&oacute;n.
      </span>
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      En caso de fuerza mayor, la parte afectada deber&aacute;:
    </p>
    <p class="msolistparagraph" style="line-height: 115%; margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        tomar todas las medidas necesarias para prevenir y evitar el caso de
        fuerza mayor;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        llevar a cabo sus obligaciones al mejor nivel razonablemente
        alcanzable en las circunstancias del caso de fuerza mayor;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        tomar todas las medidas necesarias para superar y mitigar los efectos
        del evento de Fuerza Mayor tan pronto como sea razonablemente posible,
        incluyendo la gesti&oacute;n activa de cualquier problema causado o
        contribuido por terceros y el enlace con ellos;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        en cuanto tenga conocimiento del acontecimiento de fuerza mayor,
        notificar&aacute; sin demora a la otra parte que ha sucedido algo que
        constituye un acontecimiento de fuerza mayor, dando detalles del
        acontecimiento de fuerza mayor, junto con una estimaci&oacute;n
        razonable del per&iacute;odo durante el cual continuar&aacute; el
        acontecimiento de fuerza mayor; e
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        informar a la parte no afecta cuando haya cesado la circunstancia de
        fuerza mayor.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>6.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>RESPONSABILIDAD Y EXENCIONES.</b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph">
      <b>6.1.1.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      La Plataforma y su contenido y servicios se proporcionan &quot;tal
      cual&quot; y no ofrecemos ninguna garant&iacute;a ni
      representaci&oacute;n con respecto a ellos
    </p>
    <p class="msolistparagraph">
      <b>6.1.2.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      En la medida en que lo permita la ley aplicable, Colibid no
      asumir&aacute; ninguna responsabilidad con respecto al uso de la
      Plataforma. No aceptamos ninguna responsabilidad por cualquier
      p&eacute;rdida directa, indirecta, incidental, emergente o
      econ&oacute;mica, o cualquier otra p&eacute;rdida, sea cual sea su
      causa, que surja de o en relaci&oacute;n con este Acuerdo.&nbsp;
    </p>
    <p class="msolistparagraph">
      <b>6.1.3.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      Aunque tomamos precauciones razonables para evitar la existencia de
      virus inform&aacute;ticos y/u otros programas maliciosos en la
      Plataforma y nuestros servidores, no aceptamos ninguna responsabilidad
      por ellos.
    </p>
    <p class="msolistparagraph">
      <b>6.1.4.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      No declaramos ni garantizamos que el funcionamiento de esta Plataforma
      sea oportuno, seguro, ininterrumpido o libre de errores y declinamos
      toda responsabilidad al respecto en la medida permitida por la ley.
    </p>
    <p class="msolistparagraph">
      <b>6.1.5.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      Nos reservamos el derecho de suspender el uso y acceso a la Plataforma y
      todos sus servicios en cualquier momento por razones operativas,
      reglamentarias, legales o de otro tipo.
    </p>
    <p class="msolistparagraph">
      <b>6.1.6.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      Colibid no controla la informaci&oacute;n ni las ofertas proporcionada
      ni por los Usuarios ni por los Colaboradores y, por lo tanto, no
      garantizamos su veracidad, integridad o calidad.
    </p>
    <p class="msolistparagraph">
      <b>6.1.7.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      Colibid, por tanto, no es responsable en caso de que de los documentos
      proporcionados por el Usuario (DNI, certificado digital, etc.) se derive
      una falsedad documental u otro il&iacute;cito o delito en
      relaci&oacute;n con la tenencia indebida de documentaci&oacute;n e
      informaci&oacute;n, as&iacute; como de la veracidad de la misma.
    </p>
    <p class="msolistparagraph">
      <b>6.1.8.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      Colibid &uacute;nicamente ser&aacute; facilitador de la
      informaci&oacute;n, datos y documentaci&oacute;n provista por las bases
      de datos de las administraciones y organismos p&uacute;blicos,
      solicitados por los Colaboradores a trav&eacute;s de la
      utilizaci&oacute;n autorizada del DNI y el certificado digital del
      Usuario. En este sentido, Colibid se compromete a proporcionar dicha
      informaci&oacute;n &ldquo;tal cual&rdquo; sin que la misma sea alterada
      o modificada.
    </p>
    <p class="msolistparagraph">
      <b>6.1.9.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      En la medida en que lo permita la ley aplicable, Colibid no
      asumir&aacute; ninguna responsabilidad con respecto al uso del DNI y el
      certificado electr&oacute;nico en nombre del Usuario.
    </p>
    <p class="msolistparagraph">
      <b>6.1.10.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Colibid en su calidad de intermediador, no proporciona ning&uacute;n
      tipo de recomendaci&oacute;n ni asesoramiento jur&iacute;dico, laboral,
      fiscal o de otro tipo a ninguna de las partes. Antes de decidir si
      contratar o no las ofertas de los Colaboradores, posteriormente al
      proceso de subasta, el Usuario deber&aacute; obtener un asesoramiento
      profesional adecuado y espec&iacute;fico. Cualesquiera decisiones,
      acciones de contrataci&oacute;n o de otro tipo que sean adoptadas por
      las Partes, ser&aacute;n de su exclusiva cuenta y riesgo.
    </p>
    <p class="msolistparagraph">
      <b>6.1.11.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Los Colaboradores son totalmente libres para pujar o no, por un Usuario
      u otro para atraerlo como cliente a trav&eacute;s de sus ofertas de
      concesi&oacute;n o mejora de hipoteca. Por ello, tambi&eacute;n
      ser&aacute;n totalmente libres a la hora de negociar y proponer las
      condiciones y de contrataci&oacute;n de las mismas, as&iacute; como de
      retirar la oferta en cualquier momento.
    </p>
    <p class="msolistparagraph">
      <b>6.1.12.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Los Usuarios son libres de aceptar o rechazar en cualquier momento las
      ofertas proporcionadas por los Colaboradores que han pujado por sus
      perfiles.
    </p>
    <p class="msolistparagraph">
      <b>6.1.13.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Colibid ni ninguno de sus socios o colaboradores ser&aacute;n
      responsables de posibles perjuicios, insatisfacci&oacute;n, etc. que
      pudieran derivarse para el Usuario como consecuencia de la
      formalizaci&oacute;n de un contrato de hipoteca con uno u otro
      Colaborador, as&iacute; como con los organismos legales intervinientes
      en dicha formalizaci&oacute;n. De la misma manera, no asumir&aacute;
      responsabilidad alguna frente a los perjuicios que el Colaborador pueda
      sufrir como consecuencia de dicha formalizaci&oacute;n.
    </p>
    <p class="msolistparagraph">
      <b>6.1.14.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Respecto de los puntos anteriores, Colibid tampoco es responsable de la
      actuaci&oacute;n de los Colaboradores y, por tanto, del desempe&ntilde;o
      y calidad de su trabajo, condiciones y servicio; ni de sus trabajadores
      o directivos.
    </p>
    <p class="msolistparagraph">
      <b>6.1.15.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      El registro y acceso a la Plataforma de Colibid no garantiza que, a
      trav&eacute;s de la Plataforma se consiga alcanzar un acuerdo de
      concesi&oacute;n (o mejora) de hipoteca con alguno de las Partes que
      interact&uacute;an en la misma.
    </p>
    <p class="msolistparagraph">
      <b>6.1.16.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Colibid tampoco se hace responsable de los posibles conflictos que
      pudieran surgir entre Usuarios y los Colaboradores, ni tampoco
      tendr&aacute; la obligaci&oacute;n de intervenir como mediador o
      intermediario en la resoluci&oacute;n de la disputa.
    </p>
    <p class="msolistparagraph">
      <b>6.1.17.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      En caso de conflicto con los Colaboradores ya sean con motivo de sus
      servicios u otros, el Usuario no podr&aacute; exigir a Colibid o a
      cualquiera de sus colaboradores o socios responsabilidades y/o la
      resoluci&oacute;n de los acuerdos formalizados. Colibid podr&aacute; ser
      informado acerca de la situaci&oacute;n, pero en caso de reclamar
      responsabilidades, deber&aacute; acudir a la v&iacute;a de
      resoluci&oacute;n de conflictos competente en la materia. Colibid se
      reserva el derecho, pero no estar&aacute; obligado a investigar y dar
      seguimiento al asunto.
    </p>
    <p class="msolistparagraph">
      <b>6.1.18.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      En definitiva y bajo la aceptaci&oacute;n de las presentes condiciones,
      las Partes reconocen la intervenci&oacute;n de Colibid &uacute;nicamente
      como intermediador de los servicios gracias a la puesta a
      disposici&oacute;n de su Plataforma y la interacci&oacute;n en la misma
      de los Colaboradores.
    </p>
    <p class="msolistparagraph">
      <b>6.1.19.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;</b>
      </span>
      Por tanto, de los derechos y obligaciones pactadas entre el Usuario y el
      Colaborador elegido, as&iacute; como de la normativa de
      aplicaci&oacute;n y de los &oacute;rganos de resoluci&oacute;n de
      conflictos competentes ya sea en v&iacute;a judicial o extrajudicial,
      s&oacute;lo son responsables dichas partes.
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      <span lang="es-ES">
        <b>6.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>CONFIDENCIALIDAD. PRIVACIDAD. SEGURIDAD</b>
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>6.1.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Confidencialidad. </b>
      </span>
      <span lang="es-ES">Cada parte deber&aacute;:</span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      &nbsp;
      <span lang="es-ES">
        no revelar ninguna Informaci&oacute;n Confidencial, excepto cuando sea
        necesario conocerla por abogados, consultores o asesores financieros u
        otros terceros-proveedores para la correcta consecuci&oacute;n de la
        finalidad prevista, a condici&oacute;n de que est&eacute;n sujetos a
        obligaciones de confidencialidad no menos onerosas que las de esta
        secci&oacute;n;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        no utilizar la Informaci&oacute;n Confidencial de la otra Parte
        &uacute;nicamente para el cumplimiento de sus obligaciones en virtud
        del presente Acuerdo, lo que incluye no copiar, reproducir o reducir a
        escritura ninguna parte material de la Informaci&oacute;n
        Confidencial, salvo en la medida en que sea razonablemente necesario
        en virtud del presente Acuerdo;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        cuando se le solicite, devolver&aacute; o destruir&aacute; la
        Informaci&oacute;n Confidencial de la otra parte, a menos que lo
        impida la legislaci&oacute;n aplicable;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-top: 0.08in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        informar a la otra parte inmediatamente si se ha producido cualquier
        incumplimiento de estas disposiciones (accidental o no).
      </span>
    </p>
    <p
      class="msonormal"
      style="line-height: 115%; margin-top: 0.08in; margin-bottom: 0.27in"
    >
      Las partes ser&aacute;n responsables de la divulgaci&oacute;n de
      cualquier Informaci&oacute;n Confidencial efectuada por parte de sus
      colaboradores u otros agentes. Nada de lo dispuesto en el presente
      acuerdo impedir&aacute; a una Parte revelar Informaci&oacute;n
      Confidencial salvo:
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0.27in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        en la medida en que dicha Parte est&eacute; obligada a hacerlo a
        cualquier juzgado, tribunal, &aacute;rbitro o autoridad gubernamental
        o reguladora con jurisdicci&oacute;n competente a la que cualquiera de
        las Partes est&eacute; sujeto. Cuando esto ocurra, la parte en
        cuesti&oacute;n notificar&aacute; inmediatamente a la otra el
        requerimiento (cuando lo permita la Legislaci&oacute;n Aplicable); y
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        que dicha informaci&oacute;n se convierta de dominio p&uacute;blico,
        de otra manera que no sea por el incumplimiento de este Acuerdo por
        parte de la Parte que recibe la Informaci&oacute;n Confidencial.
      </span>
    </p>
    <p class="msolistparagraph">&nbsp;</p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>6.2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Privacidad. </b>
      </span>
      <span lang="es-ES">
        En esta cl&aacute;usula, &quot;Legislaci&oacute;n/Ley en materia de
        protecci&oacute;n de datos&quot; significa, seg&uacute;n sea aplicable
        y vinculante para cada parte (i) el Reglamento General de
        Protecci&oacute;n de Datos de la UE,&nbsp; (ii) el Reglamento (UE)
        2016/679 (RGDP), (iii) Ley Org&aacute;nica 3/2018, de 5 de diciembre,
        de Protecci&oacute;n de Datos Personales y garant&iacute;a de los
        derechos digitales (LOPDGDD) (iv) la Directiva 2002/58/CE de la UE
        (Directiva sobre privacidad y comunicaciones electr&oacute;nicas), (v)
        y/o cualquier ley o reglamento nacional correspondiente o equivalente;
        y (vi) cualquier ley aplicable que sustituya, modifique,
        ampl&iacute;e, vuelva a promulgar, consolide o implemente cualquiera
        de las Leyes de Protecci&oacute;n de Datos anteriores de vez en cuando
        (ya sea antes o despu&eacute;s de la fecha de este acuerdo). Los
        t&eacute;rminos &quot;datos personales&quot;, &quot;violaci&oacute;n
        de datos personales&quot;, &quot;tratamiento&quot;, &quot;responsable
        del tratamiento&quot;, &quot;encargado del tratamiento&quot;,
        &quot;corresponsable del tratamiento&quot;, &quot;interesado&quot; y
        &quot;autoridad de control&quot; tendr&aacute;n el significado
        establecido en las Leyes de Protecci&oacute;n de Datos.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <span lang="es-ES">6.2.1.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Las partes reconocen que, con respecto de los datos personales que
        puedan ser procesados y compartidos entre los actores implicados
        (Usuarios, Colaboradores, Colibid y administraciones/organismos
        p&uacute;blicos) en virtud del acuerdo, cada parte determina de forma
        independiente sus propios fines y medios para el procesamiento de
        dichos datos personales y, a menos que las partes acuerden lo
        contrario, cada parte es un controlador independiente de los datos
        personales. Es decir, responsable del tratamiento.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <span lang="es-ES">6.2.2.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Colibid se asegurar&aacute; de cumplir en todo momento con todas las
        obligaciones impuestas a un responsable de tratamiento en virtud de
        las leyes de protecci&oacute;n de datos en relaci&oacute;n con el
        tratamiento de datos personales en virtud del acuerdo. 
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      6.2.3.
      <span style="font-variant: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      En el caso de que la prestaci&oacute;n de los servicios de la Plataforma
      Colibid suponga la necesidad de acceder a datos de car&aacute;cter
      personal, tratarlos y procesarlos como ser&iacute;a, por ejemplo, cuando
      se hace uso del certificado digital del Usuario y del DNI del Usuario,
      as&iacute; como de la documentaci&oacute;n e informaci&oacute;n que se
      recopile de las bases de datos de las administraciones y otros
      organismos p&uacute;blicos a mediante dichos documentos identificativos,
      Colibid, como encargado de tratamiento, queda obligado al cumplimiento
      de las estipulaciones contenidas en el&nbsp; RGPD, la la LOPDGDD y
      dem&aacute;s normativa aplicable. En este sentido, Colibid se
      compromete, entre otras cuestiones exigidas por las leyes, as&iacute;
      como la naturaleza del acuerdo, a:
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.5in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        Utilizar la informaci&oacute;n y documentos, y en definitiva,
        cualquier dato de car&aacute;cter personal a los que tenga acceso,
        &uacute;nica y exclusivamente para cumplir con sus obligaciones
        contractuales de intermediaci&oacute;n;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.5in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        Observar y adoptar cuantas medidas de seguridad sean necesarias para
        asegurar la confidencialidad, secreto e integridad de los datos de
        car&aacute;cter personal a los que tenga acceso, as&iacute; como
        adoptar en el futuro cuantas medidas sean exigidas por las leyes y
        reglamentos destinadas a preservar el secreto, confidencialidad e
        integridad en el tratamiento automatizado de datos personales;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.5in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        A no ceder en ning&uacute;n caso a terceras personas (a
        excepci&oacute;n de las partes implicadas en este acuerdo y otros
        proveedores necesarios para la consecuci&oacute;n del fin de aquel)
        los datos de car&aacute;cter personal a los que tenga acceso, ni tan
        siquiera a efectos de su conservaci&oacute;n;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.5in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        Informar en todo momento sobre la ubicaci&oacute;n o
        localizaci&oacute;n en la que se va a encontrar la informaci&oacute;n
        y los datos personales, contenidos en cualquier soporte o formato.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.5in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        Notificar sin dilaci&oacute;n indebida, y en cualquier caso antes del
        plazo m&aacute;ximo de 72 horas desde que se haya tenido conocimiento
        del hecho, las violaciones de la seguridad, junto con toda la
        informaci&oacute;n relevante para la documentaci&oacute;n y
        comunicaci&oacute;n de la misma.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1.5in; margin-bottom: 0in"
    >
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        Las anteriores obligaciones se extienden a toda persona que pudiera
        intervenir en cualquier fase del tratamiento por cuenta de Colibid y
        subsistir&aacute;n despu&eacute;s de terminados los tratamientos
        efectuados en el marco del presente acuerdo e incluso despu&eacute;s
        de la terminaci&oacute;n de este &uacute;ltimo. 
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <span lang="es-ES">6.2.4.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Sin perjuicio de la generalidad de la cl&aacute;usula anterior, cada
        parte deber&aacute;: 
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1.24in">
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        ser responsable de proporcionar a los individuos las notificaciones
        apropiadas y la transparencia con respecto al procesamiento de sus
        datos personales seg&uacute;n lo requerido por la legislaci&oacute;n
        en materia de Protecci&oacute;n de Datos;
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1.24in">
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        garantizar que dispone de todos los avisos y consentimientos
        necesarios para permitir la transferencia legal de datos personales a
        la otra parte si se requiere en relaci&oacute;n con el acuerdo; y
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1.24in">
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        aplicar las medidas t&eacute;cnicas y organizativas adecuadas para
        proteger dichos datos personales contra el tratamiento no autorizado e
        il&iacute;cito y contra la p&eacute;rdida, destrucci&oacute;n,
        divulgaci&oacute;n, da&ntilde;o o alteraci&oacute;n accidentales,
        incluida la aplicaci&oacute;n de procesos de seguridad de la
        informaci&oacute;n adecuados que se ajusten a las normas del sector
        reconocidas internacionalmente.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <span lang="es-ES">6.2.5.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      &nbsp;
      <span lang="es-ES">
        Salvo que las partes acuerden lo contrario por escrito, cada una de
        ellas, en la medida en que se trate de datos personales compartidos y
        tratados en virtud del presente acuerdo, cuando las partes
        act&uacute;en como responsables del tratamiento independientes:
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1.24in">
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        notificar&aacute; inmediatamente a la otra por escrito proporcionando
        detalles razonables de cualquier queja, auditor&iacute;a,
        investigaci&oacute;n o consulta (ya sea por parte de una autoridad de
        supervisi&oacute;n, un sujeto de datos o de otra manera) que
        establezca, alegue o pregunte sobre el posible incumplimiento de las
        leyes de protecci&oacute;n de datos, y las partes cooperar&aacute;n
        razonablemente entre s&iacute; al respecto; 
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1.24in">
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        notificar&aacute; a la otra parte, sin demora indebida, el
        conocimiento de cualquier violaci&oacute;n real o presunta de datos
        personales que afecte a los datos personales recibidos de la otra
        parte, y proporcionar una descripci&oacute;n razonablemente detallada
        de la violaci&oacute;n. Si la parte que sufre la violaci&oacute;n
        est&aacute; obligada, en virtud de las leyes de protecci&oacute;n de
        datos, a proporcionar (i) una notificaci&oacute;n a las autoridades
        p&uacute;blicas, a los individuos o a otras personas, o (ii) a
        emprender otras medidas correctoras (incluyendo, sin
        limitaci&oacute;n, la notificaci&oacute;n, los servicios de control de
        cr&eacute;dito o el establecimiento de un centro de llamadas para
        responder a las consultas (cada una de las anteriores, una
        &quot;Acci&oacute;n correctora&quot;), no incluir&aacute; en dicha
        Acci&oacute;n correctora ninguna menci&oacute;n a la otra parte sin su
        aprobaci&oacute;n previa por escrito, excepto cuando la ley aplicable
        lo exija.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1.24in">
      <font face="Symbol">
        <span lang="es-ES">&middot;</span>
      </font>
      <span style="font-variant: normal">
        <font face="Symbol">
          <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </font>
      </span>
      <span lang="es-ES">
        Ninguna de las partes transferir&aacute; fuera del EEE los datos
        personales que le haya proporcionado la otra parte, a menos que
        &eacute;sta lo autorice. Cualquier transferencia de datos de este tipo
        estar&aacute; sujeta a un mecanismo de transferencia adecuado (como
        las cl&aacute;usulas contractuales est&aacute;ndar de la UE,
        seg&uacute;n corresponda) seg&uacute;n lo acordado por las partes.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <span lang="es-ES">6.2.6.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Uso de los datos por parte de Colibid, de los Usuarios y de las
        relaciones llevadas a cabo y formalizadas con los Colaboradores. Una
        vez formalizado el contrato de hipoteca entre un Usuario y un
        Colaborador, se haya desistido en la contrataci&oacute;n de la misma,
        el Usuario haya eliminado su cuenta, el Colaborador haya dejado de
        prestar servicio en la Plataforma o cualquier otra causa que motivara
        la resoluci&oacute;n de dicho acuerdo, la informaci&oacute;n derivada
        del mismo as&iacute; como del uso de la Plataforma por dichas Partes,
        ser&aacute; bloqueada durante el período m&aacute;ximo establecido por
        Ley. Posteriormente se proceder&aacute; a su eliminaci&oacute;n de
        manera definitiva. No obstante, dicha informaci&oacute;n podr&aacute;
        ser utilizada de forma totalmente anonimizada para fines
        estad&iacute;sticos que sirvan a Colibid de manera interna a medir el
        &eacute;xito de la Plataforma y la satisfacci&oacute;n de los
        intervinientes: cu&aacute;ntos Usuarios han finalizado un
        procedimiento de contrataci&oacute;n de hipoteca, cu&aacute;ntos
        Usuarios han eliminado su cuenta, etc.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <span lang="es-ES">6.2.7.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Los titulares de los datos personales recabados por medio del presente
        contrato podr&aacute;n ejercitar sus derechos de acceso,
        rectificaci&oacute;n, oposici&oacute;n, limitaci&oacute;n,
        supresi&oacute;n, portabilidad y a no ser objeto de decisiones
        automatizadas dirigiendo un correo electr&oacute;nico a
        info@colibid.com.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 1in">
      <a name="_anchor_3"></a>
      <span lang="es-ES">6.2.8.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Para m&aacute;s informaci&oacute;n acerca del tratamiento de los datos
        que lleva a cabo Colibid puede consultar la Pol&iacute;tica de
        Privacidad y la Pol&iacute;tica de Cookies 
        &nbsp;configuradas a tal efecto.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>6.3.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Seguridad. </b>
      </span>
      <span lang="es-ES">
        Las partes se comprometen a adoptar medidas t&eacute;cnicas y
        organizativas razonables para evitar la p&eacute;rdida, el uso
        indebido, la alteraci&oacute;n o la divulgaci&oacute;n il&iacute;cita
        de la informaci&oacute;n procesada. 
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 1in; margin-bottom: 0in">
      <span lang="es-ES">6.3.1.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        Cualquier tercero, agente o representante de las partes que necesite
        acceder a la informaci&oacute;n tambi&eacute;n estar&aacute; obligado
        a introducir medidas t&eacute;cnicas y organizativas razonables para
        la protecci&oacute;n de su informaci&oacute;n.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 1in; margin-bottom: 0in">
      <span lang="es-ES">6.3.2.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        En caso de que se produzca una violaci&oacute;n de la seguridad o se
        sospeche de ella, las partes se notificar&aacute;n mutuamente de forma
        inmediata para tomar las medidas oportunas.
      </span>
    </p>
    <p class="msolistparagraph">&nbsp;</p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      <span lang="es-ES">
        <b>7.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>PROPIEDAD INTELECTUAL </b>
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      <span lang="es-ES">
        <b>7.1.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Derechos de propiedad intelectual e industrial de Colibid</b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph">
      8.1.2.
      <span style="font-variant: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      Todos los contenidos que se muestran en la Plataforma y en especial,
      videos, dise&ntilde;os, dibujos, textos, gr&aacute;ficos, logos, iconos,
      botones, software, nombres comerciales, marcas o cualesquiera otros
      signos susceptibles de generar derechos de propiedad intelectual,
      industrial, a la propia imagen o cualesquiera otros sobre activos
      intangibles, son titularidad de Colibid, o de terceros con los que se ha
      llegado a un acuerdo para su leg&iacute;tima explotaci&oacute;n. En
      concreto, Colibid ha recibido cuantas cesiones de derechos y/o licencias
      de uso necesarias para la explotaci&oacute;n de la Plataforma.
    </p>
    <p class="msolistparagraph">
      8.1.3.
      <span style="font-variant: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      En ning&uacute;n caso se entender&aacute; que el acceso,
      navegaci&oacute;n y utilizaci&oacute;n de la Plataforma y los servicios
      por parte de los Usuarios y Colaboradores implica una renuncia,
      transmisi&oacute;n, licencia o cesi&oacute;n total o parcial de dichos
      derechos por parte de Colibid. El Usuario dispone de una licencia de uso
      de la Plataforma, y en concreto, para estrictamente, los servicios
      ofertados.
    </p>
    <p class="msolistparagraph">
      8.1.4.
      <span style="font-variant: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      Toda referencia a marcas o nombres comerciales registrados, u otros
      signos distintivos, ya sean titularidad de Colibid o de terceras
      empresas, en cuyo caso se encuentren licenciadas a Colibid para su
      utilizaci&oacute;n y/o explotaci&oacute;n, lleva impl&iacute;cita la
      prohibici&oacute;n de su uso sin el consentimiento de Colibid o de sus
      leg&iacute;timos titulares o licenciatarios. En ning&uacute;n momento,
      salvo manifestaci&oacute;n previa y expresa en contrario de Colibid y de
      sus leg&iacute;timos titulares o licenciatarios, el acceso,
      navegaci&oacute;n o utilizaci&oacute;n de la Plataforma y/o de sus
      servicios, confiere al Usuario derecho alguno sobre signos distintivos
      en &eacute;l incluidos.
    </p>
    <p class="msolistparagraph">
      8.1.5.
      <span style="font-variant: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      Quedan reservados a Colibid todos los derechos de propiedad intelectual
      e industrial y cualesquiera otros que puedan recaer sobre los
      contenidos, activos intangibles y/o servicios de la Plataforma y, en
      particular, queda prohibido modificar, copiar, reproducir, comunicar
      p&uacute;blicamente, poner a disposici&oacute;n, transformar o
      distribuir, por cualquier medio y bajo cualquier forma, la Plataforma
      y/o la totalidad o parte de los contenidos incluidos en la Plataforma,
      entre los que se incluyen, de manera enunciativa y no taxativa, textos,
      im&aacute;genes, marcas, gr&aacute;ficos, logotipos, botones, archivos
      de software, combinaciones de colores, as&iacute; como la estructura,
      selecci&oacute;n, ordenaci&oacute;n y presentaci&oacute;n de sus
      contenidos, sin perjuicio de los derechos que terceras personas pudiesen
      tener sobre cualquiera de los elementos que integran el contenido. Todo
      ello, sin perjuicio de contar con la autorizaci&oacute;n previa, expresa
      y por escrito de Colibid o, en su caso, del titular de los derechos
      correspondientes. Asimismo, y por razones de seguridad, no est&aacute;
      permitido utilizar &ldquo;frames&rdquo; o mecanismos que alteren o
      var&iacute;en el dise&ntilde;o, configuraci&oacute;n original o
      contenidos de la Plataforma.
    </p>
    <p class="msolistparagraph">
      8.1.6.
      <span style="font-variant: normal">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      Ninguno de los contenidos alojados en la Plataforma puede ser
      descargado, reproducido o utilizado, en ning&uacute;n otro dispositivo o
      lugar diferente a la Plataforma, salvo que se haya habilitado por
      Colibid el medio para ello.
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 1in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="margin-left: 0.63in; margin-bottom: 0in"
    >
      <span lang="es-ES">8.2.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        <b>Notificaciones por contenido irregular. </b>
      </span>
      <span lang="es-ES">
        Si el Usuario o Colaborador tuviera conocimiento de la existencia de
        alg&uacute;n contenido il&iacute;cito, ilegal, contrario a las leyes,
        brecha de seguridad o que pudiera suponer una infracci&oacute;n de
        derechos de propiedad intelectual, industrial o de cualquier otra
        &iacute;ndole, deber&aacute; notificarlo inmediatamente a Colibid
        escribiendo a info@colibid.com para que Colibid pueda tomar
        conocimiento efectivo y adoptar las medidas oportunas, con la mayor
        brevedad posible.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.63in; margin-bottom: 0in"
    >
      <a name="_anchor_4"></a>
      <span lang="es-ES">8.3.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        Para m&aacute;s informaci&oacute;n acerca de nuestros derechos de
        propiedad intelectual e industrial puede consultar la siguiente
        Pol&iacute;tica
      </span>
      <span lang="es-ES">.</span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      <span lang="es-ES">
        <b>9.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>INTERPRETACI&Oacute;N</b>
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="margin-left: 0.63in; margin-bottom: 0in"
    >
      <span lang="es-ES">9.1.</span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="es-ES">
        Los encabezamientos de las cl&aacute;usulas, los p&aacute;rrafos y
        anexos no afectar&aacute;n a la interpretaci&oacute;n de este acuerdo.
        Salvo que el contexto de lo plasmado exija lo contrario:
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <span lang="es-ES">(a)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        las palabras en singular incluir&aacute;n el plural y, las palabras en
        plural, incluir&aacute;n el singular;
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <span lang="es-ES">(b)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        la expresi&oacute;n &ldquo;las Partes&rdquo; har&aacute; referencia a
        las todas las partes implicadas en este acuerdo (Colaboradores,
        Usuarios y Colibid).
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <span lang="es-ES">(c)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        las referencias a una ley o a una disposici&oacute;n legal se refiere
        a la que actualmente se encuentre de aplicaci&oacute;n y en vigor.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <span lang="es-ES">(d)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        una referencia a un g&eacute;nero incluir&aacute; una referencia a los
        otros g&eacute;neros; y 
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      <span lang="es-ES">(e)</span>
      <span style="font-variant: normal">
        <span lang="es-ES">&nbsp;&nbsp;&nbsp;</span>
      </span>
      <span lang="es-ES">
        cualquier palabra, expresi&oacute;n, etc. que se indique de modo
        ejemplificativo se interpretar&aacute; como ilustrativa y no
        limitar&aacute; el sentido de las palabras, la descripci&oacute;n, la
        definici&oacute;n, la frase o el t&eacute;rmino que preceden a dichos
        t&eacute;rminos.
      </span>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.99in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      9.2.
      <span style="font-variant: normal">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      En caso de conflicto o ambig&uuml;edad entre cualquier
      disposici&oacute;n contenida en el cuerpo de este acuerdo y cualquier
      disposici&oacute;n contenida en los anexos, tendr&aacute; prioridad la
      disposici&oacute;n de este acuerdo.
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      9.3.
      <span style="font-variant: normal">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Las partes mencionadas en el presente acuerdo incluyen personas
      f&iacute;sicas, empresas, o entidades no constituidas en sociedad (tenga
      o no personalidad jur&iacute;dica propia) y a sus representantes
      personales, sucesores y cesionarios autorizados.
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      <b>10.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      <b>MISCEL&Aacute;NEA</b>
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      <b>10.1.</b>
      <span style="font-variant: normal">
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
      </span>
      <b>Modificaci&oacute;n</b>
      <span lang="es-ES">
        <b>.</b>
      </span>
      <span lang="es-ES">
        Colibid se reserva el derecho de modificar o reemplazar cualquier
        parte de este acuerdo. Es responsabilidad del Usuario revisarlos
        peri&oacute;dicamente para identificar cambios. Colibid
        comunicar&aacute; a los Usuarios la modificaci&oacute;n del presente
        Acuerdo con, al menos, 15 d&iacute;as de antelaci&oacute;n a la fecha
        en la que sea efectiva su modificaci&oacute;n. La utilizaci&oacute;n
        de la Plataforma y los servicios luego de la publicaci&oacute;n de los
        cambios supondr&aacute; la aceptaci&oacute;n de los mismos. 
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      <span lang="es-ES">
        <b>10.2.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        Adem&aacute;s, Colibid podr&iacute;a incluir nuevos servicios o
        funciones en el futuro. De ser el caso, tales servicios o funciones se
        ver&aacute;n sujetos a los t&eacute;rminos y condiciones especificados
        en este Acuerdo.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      <span lang="es-ES">
        <b>10.3.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Cesi&oacute;n.</b>
      </span>
      <span lang="es-ES">
        Ninguna de las partes podr&aacute; transferir y ceder sus derechos y
        obligaciones en virtud del presente acuerdo sin el previo
        consentimiento por escrito de la otra parte. No obstante lo anterior,
        Colibid podr&aacute; transferir y ceder sus derechos en virtud del
        presente Acuerdo sin el consentimiento de la otra parte en
        relaci&oacute;n con un cambio de control, adquisici&oacute;n o venta
        de todos o casi todos sus activos.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      <span lang="es-ES">
        <b>10.4.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Renuncia.</b>
      </span> 
      <span lang="es-ES">
        El hecho de que Colibid no ejerza o no haga cumplir un derecho o una
        disposici&oacute;n de este acuerdo no constituir&aacute; una renuncia
        a tal derecho o disposici&oacute;n.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      <span lang="es-ES">
        <b>10.5.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>Nulidad parcial.</b>
      </span>
      <span lang="es-ES">
        Si una o varias estipulaciones del presente acuerdo se consideran no
        v&aacute;lidas o declaradas como tal en aplicaci&oacute;n de una ley,
        de un reglamento o de una decisi&oacute;n definitiva de una
        jurisdicci&oacute;n competente, las otras estipulaciones
        conservar&aacute;n toda su fuerza y todo su alcance.
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p
      class="msolistparagraph"
      style="line-height: 115%; margin-left: 0.38in; margin-bottom: 0in"
    >
      <span lang="es-ES">
        <b>11.</b>
      </span>
      <span style="font-variant: normal">
        <span lang="es-ES">
          <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
        </span>
      </span>
      <span lang="es-ES">
        <b>LEY APLICABLE Y JURISDICCION</b>
      </span>
    </p>
    <p class="msolistparagraph" style="margin-bottom: 0in">
      &nbsp;
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      11.1.
      <span style="font-variant: normal">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Toda controversia derivada de este Acuerdo o que guarden relaci&oacute;n
      con &eacute;l &ndash; incluida cualquier cuesti&oacute;n relativa a su
      existencia, validez, terminaci&oacute;n, interpretaci&oacute;n o
      ejecuci&oacute;n- se someter&aacute; a la jurisdicci&oacute;n de los
      Juzgados y Tribunales de Valencia para la resoluci&oacute;n del
      conflicto o cuesti&oacute;n.
    </p>
    <p class="msolistparagraph" style="margin-left: 0.63in">
      11.2.
      <span style="font-variant: normal">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      Sin perjuicio de lo anterior, las partes se comprometen a realizar sus
      mejores esfuerzos para resolver de forma amistosa y de buena fe dichas
      controversias, antes de acudir los &oacute;rganos jurisdiccionales
      competentes.
    </p>`,
      }}
    ></div>
  );
};

export default CertificateDisclaimer;
