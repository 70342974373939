import * as yup from "yup";
export const MAX_CHAR = 200;
const emailPattern =
  /\b[A-Za-z0-9._%+-]+(?:\s*@|\s*@+\s*)\s*?[A-Za-z0-9.-]+\s?(?:\.+\s*?[A-Z|a-z]{2,}|\s*[A-Z|a-z]{2,})\b/;
// const spainPhonePattern = /\b(?:\+34\s*?|0034\s*?|34\s*?)?[6789](?:\s*\d){8}\b/;
export const bidValidationSchema = yup.object({
  additional_detail: yup
    .string()
    .nullable()
    .max(
      MAX_CHAR,
      `No puedes ingresar más de ${MAX_CHAR} caracteres como detalle de la oferta`,
    )
    .test(
      "no-leak-email",
      "No está permitido añadir correo electrónico.",
      (value) => {
        if (value) {
          const hasEmail = emailPattern.test(value);

          return !hasEmail;
        }
        return true;
      },
    ),
  // .test(
  //   "no-leak-phone",
  //   "No está permitido añadir un número de teléfono.",
  //   (value) => {
  //     if (value) {
  //       const hasPhone = spainPhonePattern.test(value);
  //
  //       return !hasPhone;
  //     }
  //     return true;
  //   },
  // ),
});
