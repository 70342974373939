import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";

import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  CardHeader,
  Avatar,
  Chip,
  IconButton,
} from "@mui/material";

import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { toast } from "react-toastify";

import IMortgageSchema from "../../../shared/schemas/mortgage.schema";
import { BidModal } from "../";

import { NotResults } from "../../../shared/components";
import { censureChars, getInterestType } from "../../../utils/helpers";
import Countdown from "react-countdown";
import { parseISO } from "date-fns";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import useLocalStorage from "shared/hooks/useLocalStorage";
import styles from "./styles.module.scss";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";

export interface IBidsHistoryProps {
  buttonTitle: string;
  data: Partial<IMortgageSchema> | IMortgageSchema | MortgageRes;
  onClick: (bid: any) => void;
  onUpdate: () => void;
  onConfirm: (bid: any) => void;
}
const BidsHistory: FunctionComponent<IBidsHistoryProps> = ({
  buttonTitle,
  data,
  onClick,
  onUpdate,
  onConfirm,
}): ReactElement<any> => {
  const [role] = useLocalStorage("role");
  const [email] = useLocalStorage("email");
  const [showModalAddBid, setShowModalAddBid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [haveAcceptedBid, setHaveAcceptedBid] = useState(false);

  useEffect(() => {
    if (data.status !== "active") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (data.bids?.length !== 0) {
      getAcceptedBid(data.bids || []);
    }
  }, [data]);

  const getAcceptedBid = (bids: any[]) => {
    let acceptedBid = bids.some((b: Bid) => b.is_accepted);

    /* 
    bids.forEach((b: any, i: number) => {
      if (
        b?.accepted[0]?.solicitor_accepted &&
        (!b?.accepted[0]?.bidder_accepted ||
          b?.accepted[0]?.bidder_accepted === "")
      ) {
        acceptedBid = true;
      }
    }); */

    return setHaveAcceptedBid(acceptedBid);
  };
  const classCensure = (b: any) => {
    return role !== "client" && email !== b?.bidder?.user
      ? `${styles.Content} censure  ${styles.ChipAuto}`
      : "";
  };
  return (
    <>
      <Card>
        <CardContent>
          {data.bids?.length === 0 ? (
            <NotResults
              title="No hay pujas todavía"
              caption="¿Listo para despegar?"
              url="/assets/img/sources/ready_to_launch.svg"
              resize={true}
            />
          ) : (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Entidad financiera</TableCell>
                  <TableCell align="center">Tipo de Interés</TableCell>
                  <TableCell align="center">Tasa de interés</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.bids?.map((b: any, i) => {
                  return (
                    <TableRow hover key={i} sx={{ cursor: "pointer" }}>
                      <TableCell
                        scope="row"
                        align="left"
                        onClick={() => onClick(b)}
                      >
                        <CardHeader
                          avatar={
                            <Avatar
                              sx={{ width: 30, height: 30 }}
                              className={styles.TableLogo}
                            />
                          }
                          title={
                            <div className={styles.WrapperChip}>
                              {role !== "client" &&
                              email !== b?.bidder?.user ? (
                                <span className={`${classCensure(b)}`}>
                                  {censureChars(b.bid_for.name)}
                                </span>
                              ) : (
                                <span>{b.bid_for.name}</span>
                              )}

                              {b.is_auto === true &&
                                email === b?.bidder?.user && (
                                  <Chip
                                    label="Auto"
                                    size="small"
                                    color="info"
                                    variant="outlined"
                                    className={`${classCensure(b)} ${
                                      styles.ChipAuto
                                    }`}
                                  />
                                )}

                              {b.is_auto === false &&
                                email === b?.bidder?.user && (
                                  <Chip
                                    label="Manual"
                                    size="small"
                                    color="warning"
                                    variant="outlined"
                                    className={`${classCensure(b)} ${
                                      styles.ChipAuto
                                    }`}
                                  />
                                )}

                              {b.general_fees.finance_fees === true && (
                                <Chip
                                  sx={{
                                    marginTop: ".2rem",
                                    height: "100%",
                                    borderRadius: "6px",
                                  }}
                                  label="Financiada"
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  className={`${classCensure(b)} ${
                                    styles.ChipAuto
                                  }`}
                                />
                              )}
                            </div>
                          }
                          style={{ padding: ".5rem" }}
                        />
                      </TableCell>

                      <TableCell align="center">
                        {getInterestType(b.interest_data.mode)}
                      </TableCell>

                      {b.interest_data?.mode === "mixed" ||
                      b.interest_data?.mode === "mixto" ? (
                        <TableCell align="center" onClick={() => onClick(b)}>
                          <ContentPasteSearchIcon
                            color="primary"
                            fontSize="small"
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          align="center"
                          className={
                            role !== "client" && email !== b?.bidder?.user
                              ? `${styles.Content} censure`
                              : ""
                          } //experiment
                          onClick={() => onClick(b)}
                        >
                          {b.interest_rate}%{" "}
                        </TableCell>
                      )}

                      {/* <TableCell align="center">
                        {b?.accepted[0]?.solicitor_accepted &&
                          (!b?.accepted[0]?.bidder_accepted ||
                            b?.accepted[0]?.bidder_accepted === "") && (
                            <IconButton
                              onClick={() => onConfirm(b)}
                              title="Aceptar"
                            >
                              <LibraryAddCheckIcon color="primary" />
                            </IconButton>
                          )}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
                <br />
                <TableRow>
                  <TableCell align="left" sx={{ border: "none" }}>
                    Pujas totales
                  </TableCell>
                  <TableCell align="right" sx={{ border: "none" }}>
                    {data.bids?.length}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell align="left" sx={{ border: "none" }}>
                    Cantidad de visualizaciones
                  </TableCell>
                  <TableCell align="right" sx={{ border: "none" }}>
                    {data.seen || 0}
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell align="left" sx={{ border: "none" }}>
                    Tiempo restante
                  </TableCell>
                  <TableCell align="right" sx={{ border: "none" }}>
                    <Countdown
                      date={parseISO(data?.ends!)}
                      renderer={({ days, hours, minutes }) => (
                        <div className={styles.Countdown}>
                          {days}d : {hours}h : {minutes}m
                        </div>
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
      <Box className={styles.BidButtonWrapper}>
        {!haveAcceptedBid && (
          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowModalAddBid(true)}
            disabled={disabled}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>

      {showModalAddBid && (
        <BidModal
          mode="manual"
          defaultValue={null}
          readOnly={false}
          mortgage={data}
          onClose={() => {
            setShowModalAddBid(false);
          }}
          onSave={() => {
            setShowModalAddBid(false);
            onUpdate();
          }}
        />
      )}
    </>
  );
};

export default BidsHistory;
