import { DevTool } from "@hookform/devtools";
import {
  Box,
  Button, Stack,
  Typography
} from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { StepViewLayoutProps } from "shared/components/MortgageForm/stepBuilder";
import logger from "utils/logger";

type Props<T extends Record<any, any>> = PropsWithChildren<
  StepViewLayoutProps<T>
>;

export default function WizardFormPage<T extends Record<any, any>>({
  defaultValues,
  resolver,
  children,
  title,
  onNext,
  onBack,
}: Props<T>) {
  const formMethod = useForm({
    mode: "onBlur",
    defaultValues,
    resolver,
  });
  const { formState, reset } = formMethod;
  const { isDirty, isValid, errors } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleNext = (data: T) => {
    onNext(data);
  };

  const handleBack = () => {
    onBack?.();
  };

  let btnDisabled = !isValid;
  return (
    <FormProvider {...formMethod}>
      <DevTool control={formMethod.control} />
      <form
        onSubmit={formMethod.handleSubmit(handleNext, (e) => {
          logger.info(e);
        })}
        style={{ height: "100%" }}
      >
        <Stack
          height="inherit"
        >
          <Typography
            variant="h5"
            textAlign="center"
            sx={{
              backgroundColor:  "#D8D7F540",
              padding: "2rem 1rem 1rem",
            }}
          >
            {title}
          </Typography>
          <Box
            id="inputs-section"
            paddingX={2}
            sx={{
              overflowY: "auto",
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "stretch",
              flexDirection: "column",
            }}
          >
            {children}
          </Box>
          <Stack
            id="wizard-bottom-nav"
            direction="row"
            gap={1}
            justifyContent={ "center"}
            padding={2}
            alignItems="flex-end"
          >
            {onBack && (
              <Button
                variant="contained"
                onClick={handleBack}
                sx={{
                  backgroundColor: "#AAADAD80",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                }}
              >
                Atrás
              </Button>
            )}
              <Button
                disabled={btnDisabled}
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#E5F287",
                  color: "black",
                  "&:disabled": {
                    backgroundColor: "#E5F28780",
                  },
                  "&:hover": {
                    backgroundColor: "#E5F28790",
                  },
                }}
              >
                "siguiente"
              </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}