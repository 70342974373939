import { differenceInDays, parseISO } from "date-fns";
import { PaginationRes } from "shared/types/BaseApiResponse";
import {
  CRMTable,
  CRMTableActions,
  CRMFlashyCells,
  CRMTableActionsFields,
  CrmFields,
  CrmTabState,
} from "shared/types/Crm";

export const getValueActions = (obj: any) => {
  return typeof obj === "object" ? obj.value : obj;
};

export const isRefDayExceeded = ({
  date,
  action,
  tableActionExceedableDay,
}: {
  date: string;
  action: keyof Omit<CRMTableActions, "cerrado">;
  tableActionExceedableDay: {
    [x in keyof Omit<CRMTableActions, "cerrado">]: number;
  };
}): boolean => {
  const daysDiff = differenceInDays(new Date(), parseISO(date));
  return daysDiff >= tableActionExceedableDay[action];
};

export const getAlertDataCells = ({
  data,
  shouldGetFlashyData,
  headerFields,
}: {
  data: CRMTable[];
  headerFields: CrmFields[];
  shouldGetFlashyData?: boolean;
}) => {
  const flashyCells: CRMFlashyCells = {};
  const tableActionExceedableDay: {
    [x in keyof Omit<CRMTableActions, "cerrado">]: number;
  } = {};
  headerFields.forEach((h) => {
    tableActionExceedableDay[h.name] = h.max_days;
    flashyCells[h.name] = [];
  });

  if (shouldGetFlashyData) {
    data.forEach((d) => {
      // take advantage of this loop to get the flashy one ✨
      // loop to the array order if find a falsy or empty string is going to be a flashy item 💫
      for (let i = 0; i < headerFields.length; i++) {
        const action = headerFields[i].name;
        // falsy or empty string, check the date and is it greater then go to flashy 💫
        if (!d[action].value) {
          if (i === 0) {
            const isDayExceeded = isRefDayExceeded({
              date: d.created,
              action,
              tableActionExceedableDay,
            });
            if (isDayExceeded) {
              flashyCells[action].push(d.id);
            }
            break;
          }
          const prevAction = headerFields[i - 1].name;
          const prevField = d[prevAction] as CRMTableActionsFields;
          const isDayExceeded = isRefDayExceeded({
            date: prevField ? prevField.created : new Date().toISOString(),
            action,
            tableActionExceedableDay,
          });
          if (isDayExceeded) {
            flashyCells[action].push(d.id);
          }
          break;
        }
      }
    });
  }
  return flashyCells;
};

export const PAGINATION_INITIAL_VALUE = {
  page: 1,
  pages: 0,
  count: 0,
  limit: 25,
};

export const crmPaginationFactory = (pag?: PaginationRes) => {
  if (pag) {
    return {
      page: pag.page,
      pages: pag.pages,
      limit: pag.items_per_page,
      count: pag.count,
    };
  }
  return PAGINATION_INITIAL_VALUE;
};

export const sortDateByMortgagesCreated =
  (order: "asc" | "desc" = "asc") =>
  (a: any, b: any) => {
    if (order === "asc") {
      return (
        new Date(b.created as string).getTime() -
        new Date(a.created as string).getTime()
      );
    }
    return (
      new Date(a.created as string).getTime() -
      new Date(b.created as string).getTime()
    );
  };

export const getModeByTab = (tabState: CrmTabState) => {
  const tab = Number(tabState);
  if (tab === CrmTabState.NO_COLLAPSE || !tab) return undefined;
  if (tab === CrmTabState.COLLAPSE) return "dead";
  if (tab === CrmTabState.PAID) return "paid";
};
