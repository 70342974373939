import { TextFieldProps, Stack, TextField, Button } from "@mui/material";
import { useState } from "react";
import useMortgageFormContext from "../hooks/useMortgageFormContext";
import logger from "utils/logger";

function MortgageDevTool() {
  const isDevelopment = process.env.REACT_APP_ENV === "development";
  const {
    setStep,
    stepViews,
    clearMortgageFormState,
    mortgageFormData,
    mortgageFormState,
  } = useMortgageFormContext();
  const [val, setVal] = useState("");
  if (!isDevelopment) return null;

  const handleChage: TextFieldProps["onChange"] = (e) => {
    setVal(e.target.value);
  };
  const handleClick = () => {
    const value = Math.floor(Number(val));
    if (value > stepViews.length - 1) {
      setStep(stepViews.length - 1);
      return;
    }
    setStep(value);
  };
  return (
    <Stack
      sx={{
        position: "absolute",
        top: 10,
        right: 1,
        background: "#faebd759",
      }}
      direction="row"
    >
      <TextField
        type="number"
        size="small"
        value={val}
        label="ir a paso"
        onChange={handleChage}
        helperText="este input solo aparece en desarrollo"
      ></TextField>
      <Button onClick={handleClick} size="small">
        go
      </Button>
      <Button onClick={clearMortgageFormState} size="small">
        clearmortgage
      </Button>
      <Button
        onClick={() => {
          logger.info({ mortgageFormState, stepViews });
        }}
        size="small"
      >
        log state
      </Button>
    </Stack>
  );
}

export default MortgageDevTool;
