import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { Grid, Box, Typography, Divider, Fab } from "@mui/material";
import styles from "./styles.module.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YoutubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import HomeIcon from "@mui/icons-material/Home";

const Maintenance: FunctionComponent<{}> = (): ReactElement<any> => {
  return (
    <Grid
      container
      spacing={2}
      style={{ textAlign: "center" }}
      className={styles.Container}
    >
      <Grid item xs={12} md={12}>
        <img
          src="/assets/img/logo.svg"
          alt="colibid logo"
          style={{ marginBottom: "12px" }}
          className={styles.logo}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Box className={styles.containerImage}>
          <img
            src="/assets/img/sources/maintenance.svg"
            alt="colibid maintenance"
            width="70%"
          />
        </Box>

        <Divider style={{ margin: "1rem" }} light />
        <Box style={{ padding: "10px" }}>
          <Typography variant="subtitle1" gutterBottom align="center">
            <h5 className={styles.titleMaintain}>En mantenimiento...</h5>
            Nuestro equipo actualmente se encuentra trabajando para ti, te
            pedimos disculpas por los inconvenientes. Por favor inténtalo más
            tarde 🤗
          </Typography>
          <Box className={styles.ShareBox} style={{ marginTop: "10px" }}>
            <Fab
              size="medium"
              className={styles.FacebookButton}
              target="_blank"
              component="a"
              href={`https://www.facebook.com/colibid/`}
            >
              <FacebookIcon />
            </Fab>

            <Fab
              size="medium"
              className={styles.TwitterButton}
              target="_blank"
              component="a"
              href={`https://twitter.com/colibid`}
            >
              <TwitterIcon />
            </Fab>

            <Fab
              size="medium"
              className={styles.LinkedinButton}
              target="_blank"
              component="a"
              href={`https://www.linkedin.com/company/colibid/`}
            >
              <LinkedInIcon />
            </Fab>

            <Fab
              size="medium"
              className={styles.YoutubeButton}
              target="_blank"
              component="a"
              href={`https://www.youtube.com/channel/UCZc7j2eUDddHXpJtMnDF8dA`}
            >
              <YoutubeIcon />
            </Fab>

            <Fab
              size="medium"
              className={styles.InstagramButton}
              target="_blank"
              component="a"
              href={`https://www.instagram.com/colibid_spain/`}
            >
              <InstagramIcon />
            </Fab>

            <Fab
              size="medium"
              className={styles.HomeButton}
              target="_blank"
              component="a"
              href="https://colibid.com/"
            >
              <HomeIcon />
            </Fab>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Maintenance;
