import React from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import styles from "../styles.module.scss";
import { formatToRate } from "../../../../utils/helpers";
type Props = {
  conditions_details: any;
  onChangeInput: (bid: any, n: number) => void;
  onChangeCheckBox?: (bid: any, n: number) => void;
};
export default function Bindings({ conditions_details, onChangeInput }: Props) {
  const handledDataInput = (data: any, n: number) => {
    onChangeInput(data, n);
  };

  return (
    <Grid item md={6} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
      <Typography variant="h6" className={styles.Title}>
        Lista de Bonificaciones
      </Typography>

      <Box className={styles.BonusList}>
        {conditions_details.map((s: any, i: number) => {
          return (
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <ReactScrollWheelHandler preventScroll={true}>
                <InputLabel htmlFor="standard-adornment-amount">
                  {s.bonificationName}
                </InputLabel>
                <Input
                  type="text"
                  value={s.interest_rate}
                  disabled={!s.allow}
                  error={s.interest_rate === "" && s.allow ? true : false}
                  onChange={({ target }) => {
                    s.interest_rate = formatToRate(target.value);
                    handledDataInput(s, i);
                    // schema.conditions_details[i] = s;
                    // setSchema({ ...schema });
                  }}
                  inputProps={{
                    step: "0.01",
                    min: 0,
                  }}
                  onBlur={({ target }) => {
                    if (parseFloat(target.value) === 0) {
                      s.interest_rate = "";
                      handledDataInput(s, i);
                    }
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Checkbox
                        checked={s.allow}
                        className={styles.CheckBox}
                        onChange={() => {
                          s.allow = !s.allow;
                          handledDataInput(s, i);

                          //   schema.conditions_details[i] = s;
                          //   setSchema({ ...schema });
                        }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                />
              </ReactScrollWheelHandler>
            </FormControl>
          );
        })}
      </Box>
    </Grid>
  );
}
