import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { ReactChildren, ReactNode, useState } from "react";
import { useDialog } from "shared/hooks";

interface AlertOfferts {
  imgUrl: string;
  content: ReactNode;
  closeButtonText: string;
  acceptButtonText: string;
  showModal: boolean;
  sendEventAccept?: () => void;
  closeEventAccept?: () => void;
}

const AlertOfferts = ({
  imgUrl,
  content,
  closeButtonText,
  acceptButtonText,
  showModal,
  sendEventAccept,
  closeEventAccept,
}: AlertOfferts) => {
  const { isShowing, closeDialog, openDialog } = useDialog(showModal);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handledAcceptModal = () => {
    closeDialog();
    if (sendEventAccept) {
      sendEventAccept();
    }
  };

  const handleClose = () => {
    closeDialog();
    if (closeEventAccept) {
      closeEventAccept();
    }
  };
  return (
    <div>
      <Dialog
        open={isShowing}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img src={imgUrl} width={40} height={40}></img>
        </DialogTitle>

        <DialogContent sx={{ fontFamily: "Roboto", lineHeight: "1.5rem" }}>
          {content}
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Stack alignItems="center" flexDirection="row" gap={2}>
            {closeButtonText && (
              <Button variant="contained" color="primary" onClick={handleClose}>
                {closeButtonText}
              </Button>
            )}

            {acceptButtonText && (
              <Button
                variant="contained"
                color="primary"
                onClick={handledAcceptModal}
                autoFocus
              >
                {acceptButtonText}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertOfferts;
