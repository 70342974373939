import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Alert,
  Box,
  Typography,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Countdown from "react-countdown";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { MainBrokerLayout } from "../../../shared/layouts";
import {
  PageTitle,
  MortgageChart,
  BidsHistory,
  ConfirmAccept,
} from "../../../shared/components";
import { useAuthenticated } from "../../../shared/hooks";
import IMortgageSchema from "../../../shared/schemas/mortgage.schema";

import { getMortgage, brokerAcceptBid } from "../../../services/api";
import { useLocalStorage, useProfile } from "../../../shared/hooks";

import styles from "./styles.module.scss";
import { parseISO, format } from "date-fns";
import { sendTagEvent } from "utils/analytics";
import { formatCurrency } from "../../../utils/helpers";

const BidDetails: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [role] = useLocalStorage("role");
  const [mortgage, setMortgage] = useState<Partial<IMortgageSchema>>({});
  const [bid, setBid] = useState<any | null>(null);
  const [isMax, setIsMax] = useState(false);
  const [username] = useLocalStorage("username");
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const profile = useProfile().profile;

  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["broker", "bank"]
  );

  const getDetails = (id: string) => {
    getMortgage(id)
      .then((response: any) => {
        setMortgage(response.data);

        const last = response.data.bids[response.data.bids.length - 1];
        setBid(last);

        if (last.bid_for.user === username) {
          setIsMax(true);
        }
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  useEffect(() => {
    getDetails(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  const confirmAction = (bid: any) => {
    setShowDialogConfirm(true);
    setBid(bid);
  };

  const accept = (b: any) => {
    brokerAcceptBid(mortgage.id, b)
      .then(() => {
        toast.success(
          "Puja confirmada correctamente y enhorabuena por tu nuevo cliente 🥳, te recomendamos ponerte en contacto lo antes posible para finalizar los últimos detalles 😄"
        );
        getDetails(id!);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  return (
    <MainBrokerLayout>
      <Container className={styles.BidDetailsWrapper}>
        <Grid container spacing={4}>
          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Puja Actual" />
          </Grid>

          <Grid item md={8} sm={12} xs={12}>
            {isMax && (
              <Alert severity="info" sx={{ marginBottom: "1rem" }}>
                Eres el máximo pujador y actualmente vas en cabeza{" "}
                {mortgage?.ends}
              </Alert>
            )}
            <Box className={styles.CountdownWrapper}>
              <AccessAlarmIcon className={styles.CountdownIcon} />
              <Countdown
                date={parseISO(mortgage?.ends!)}
                renderer={({ days, hours, minutes, seconds }) => (
                  <div className={styles.Countdown}>
                    {days}d : {hours}h : {minutes}m : {seconds}s
                  </div>
                )}
              />
            </Box>
            <Card>
              <CardContent>
                <MortgageChart
                  bids={mortgage?.bids!}
                  onClick={(selected) => setBid(selected)}
                />
              </CardContent>
            </Card>
            <br />
            {bid && (
              <Card>
                <CardContent>
                  <PageTitle title="Detalles de la puja" />
                  <Divider component="hr" sx={{ margin: "1rem 0" }} />

                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography variant="body1" align="center">
                        Datos Generales
                      </Typography>
                      <br />
                      <Table size="small" className={styles.TableDetails}>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" className={styles.Title}>
                              Banco
                            </TableCell>
                            <TableCell align="center">
                              {bid.bid_for.name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" className={styles.Title}>
                              Tasa de Interés
                            </TableCell>
                            <TableCell
                              align="center"
                              className={styles.Content}
                            >
                              {bid.interest_rate}%
                            </TableCell>
                          </TableRow>
                          {/* <TableRow>
                            <TableCell align="left" className={styles.Title}>
                              Número de Pujas
                            </TableCell>
                            <TableCell
                              align="center"
                              className={styles.Content}
                            >
                              1
                            </TableCell>
                          </TableRow> */}
                          <TableRow>
                            <TableCell align="left" className={styles.Title}>
                              Honorarios de Asesoría
                            </TableCell>
                            <TableCell
                              align="center"
                              className={styles.Content}
                            >
                              {bid.general_fees.total_fees
                                ? formatCurrency(bid.general_fees.total_fees)
                                : formatCurrency(
                                    bid.general_fees.consulting_fees +
                                      bid.general_fees.colibid_fees
                                  )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left" className={styles.Title}>
                              Fecha de puja
                            </TableCell>
                            <TableCell
                              align="center"
                              className={styles.Content}
                            >
                              {format(
                                parseISO(bid.created),
                                "dd-MM-yyyy / HH:mm:ss"
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography variant="body1" align="center">
                        Lista de Bonificaciones
                      </Typography>

                      <br />
                      <Table size="small" className={styles.TableDetails}>
                        <TableBody>
                          {bid.conditions_details
                            ?.filter((bonus: any) => {
                              return bonus.allow === true;
                            })
                            .map((c: any, i: number) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell
                                    align="left"
                                    className={styles.Title}
                                  >
                                    {c.bonificationName}
                                  </TableCell>
                                  <TableCell align="center">
                                    {c.interest_rate}%
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <PageTitle title="Historial de Pujas" />
            <br />
            <BidsHistory
              buttonTitle="Pujar de nuevo"
              data={mortgage}
              onClick={(selected) => {
                setBid(selected);
              }}
              onUpdate={() => getDetails(id!)}
              onConfirm={(b) => confirmAction(b)}
            />

            {showDialogConfirm && (
              <ConfirmAccept
                bid={bid.id}
                mortgageData={mortgage}
                title="¡Enhorabuena tu oferta tiene buena pinta!"
                caption="Al aceptar se dará por finalizada la subasta con la siguiente oferta
          para el cliente"
                bidData={bid}
                onConfirm={(b) => {
                  accept(b);
                  setShowDialogConfirm(false);
                }}
                onClose={() => setShowDialogConfirm(false)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </MainBrokerLayout>
  );
};

export default BidDetails;
