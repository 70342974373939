import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import axios from "utils/axios";

export interface TruelayerAccount {
  account_id: string;
  account_type: string;
  display_name: string;
  currency: string;
  provider: TruelayerProvider;
}

export interface TruelayerProvider {
  display_name: string;
  provider_id: string;
  logo_uri: string;
}

export interface GetTruelayerAccountsProps {
  mid: string;
  solicitor: string;
  id?: string;
}

const getTruelayerAccounts = ({
  mid,
  solicitor,
}: GetTruelayerAccountsProps) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/services/truelayer/accounts`,
    {
      params: {
        mid,
        solicitor,
      },
    }
  );
};

const getTruelayerAccountById = ({
  mid,
  solicitor,
  id,
}: GetTruelayerAccountsProps) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/services/truelayer/gen_report/${id}`,
    {
      params: {
        mid,
        solicitor,
      },
    }
  );
};

interface GenReportByAccountIdProps {
  accountId: string;
  mid: string;
  solicitor: string;
}

const genReportByAccountId = ({
  accountId,
  mid,
  solicitor,
}: GenReportByAccountIdProps) => {
  return axios.get<BaseApiSuccessRes<{ file_url: string }>>(
    `${process.env.REACT_APP_API_URL}/services/truelayer/gen_report/${accountId}`,
    {
      params: {
        mid,
        solicitor,
      },
    }
  );
};

export { getTruelayerAccounts, getTruelayerAccountById, genReportByAccountId };
