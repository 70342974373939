import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
  ReactElement,
} from "react";
import {
  Container,
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Box,
  Chip,
  Divider,
  Button,
  Alert,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useLocalStorages,
  useAuthenticated,
  useDialog,
  useLoading,
  useRole,
} from "shared/hooks";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";

import { MainBrokerLayout } from "../../../shared/layouts";
import {
  PageTitle,
  TabPanel,
  NotResults,
  HelperTextFieldWrapper,
  ApplicationsFilterModal,
  BidModal,
  PaginationBar,
  TableSkeleton,
  MetricPlans,
} from "../../../shared/components";

import {
  getBiddings,
  addBidding,
  updateBidding,
  deleteBidding,
} from "../../../services/api";

import { toast } from "react-toastify";
import IBiddingSchema, {
  IBiddingAddSchema,
} from "../../../shared/schemas/bidding.schema";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GavelIcon from "@mui/icons-material/Gavel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { isMobile } from "react-device-detect";

import {
  getFormatedFiltersForAPI,
  defaultFilters,
  decodeFiltersAPI,
} from "../../../shared/components/ApplicationsFilterModal/helpers";
import styles from "./styles.module.scss";
import { BidderProfile } from "shared/types/Profile";
import useProfile from "shared/hooks/useProfile";
import { formatToRate } from "utils/helpers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UserDefaultAutoBidDialog from "./components/UserDefaultDialog";
import { isBrokerProfile } from "utils/profile";

const AutoBidding: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const { profile, setValueProfile } = useProfile<BidderProfile>();
  const [biddings, setBiddings] = useState<IBiddingSchema[] | []>([]);
  const [bidding, setBidding] = useState<IBiddingAddSchema>({
    title: "",
    filters: {},
    conditions: {},
    max_bids: 5,
    max_users: 5,
    is_active: true,
    for_new: true,
    for_old: false,
    run_mon: true,
    run_tue: true,
    run_wed: true,
    run_thu: true,
    run_fri: true,
    run_sat: true,
    run_sun: true,
  });
  const [mode, setMode] = useState<string>("list");
  const { isShowing, openDialog, closeDialog } = useDialog(false);
  const [applicationsPaging, setApplicationsPaging] = useLocalStorages(
    "autoBiding_paging",
    {
      page: 1,
      pages: 0,
      count: 0,
      limit: 25,
    }
  );
  const [tabValue, setTabValue] = useLocalStorages("bidTab", "0");
  const isLoading = useLoading();
  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["broker", "bank"]
  );

  useEffect(() => {
    handleChangeTab(0);
  }, []);

  useEffect(() => {
    getAll("?only=active");
  }, [applicationsPaging]);

  const getAll = (filters: string) => {
    getBiddings(filters, applicationsPaging.page, applicationsPaging.limit)
      .then((response: any) => {
        isLoading.setValue(false);
        applicationsPaging.page = response.data.pagination.page;
        applicationsPaging.pages = response.data.pagination.pages;
        applicationsPaging.limit = response.data.pagination.items_per_page;
        setBiddings(response.data.results);
      })
      .catch((e: any) => {
        isLoading.setValue(false);
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  };

  const create = (schema: IBiddingAddSchema) => {
    addBidding(schema)
      .then(() => {
        setMode("list");
        toast.success("Automatización creada correctamente");
        handleChangeTab(0);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  };

  const update = (schema: any) => {
    updateBidding(schema)
      .then(() => {
        setMode("list");
        toast.success("Automatización actualizada correctamente");
        handleChangeTab(0);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  };

  const delBidding = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Está acción es irreversible la automatización será removida completamente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        deleteBidding(id)
          .then(() => {
            Swal.fire({
              title: "Solicitud procesada",
              text: "La automatización se ha removido correctamente",
              icon: "success",
            });

            handleChangeTab(0);
          })
          .catch((e: any) => {
            toast.error(
              "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
            );
          });
      }
    });
  };

  const handleChangeTab = (value: any) => {
    // setBiddings([]);

    if (value === 0) {
      openDialog();
      getAll("?only=active");
    }

    if (value === 1) {
      closeDialog();
      getAll("?only=delete");
    }

    setTabValue(value);
  };

  const duplicateBidding = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Estás a punto de duplicar una puja",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        const matchingBid = biddings.find((bidding) => bidding.id === id);
        if (matchingBid) {
          matchingBid.title = "Copia " + matchingBid.title;
          matchingBid.is_active = false;
          // damn no time to fix this
          create(matchingBid as unknown as IBiddingAddSchema);
        }
      }
    });
  };

  const setBidderComissionValues = (data: BidderProfile) => {
    if (profile) {
      profile.autobidding_min_margin = data.autobidding_min_margin;
      profile.autobidding_percentage = data.autobidding_percentage;
      setValueProfile({ ...profile });
    }
  };

  const changeMode = () => {
    if (
      profile &&
      !profile.autobidding_min_margin &&
      !profile.autobidding_percentage &&
      isBrokerProfile(profile)
    ) {
      Swal.fire({
        text: "Antes de crear una automatización debes cargar la configuración de tus honorarios en la pestaña “Configuraciones”",
        icon: "warning",
      });
      return;
    }
    setMode("add");
  };

  const changePage = (p: any) => {
    applicationsPaging.page = p.page;
    applicationsPaging.limit = p.limit;

    setApplicationsPaging({ ...applicationsPaging });
  };

  return (
    <MainBrokerLayout>
      <Container className={styles.BidsWrapper}>
        {profile && isBrokerProfile(profile) && (
          <UserDefaultAutoBidDialog
            profile={profile}
            hasAutoBiddings={biddings.length > 0}
            onSubmit={() => {
              getAll("?only=active");
            }}
          />
        )}
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Auto Pujas" />
          </Grid>
          {/* <Grid item md={12} sm={12} xs={12}>
            <MetricPlans />
          </Grid> */}
          <Grid item md={12} sm={12} xs={12}>
            {mode === "list" && profile?.enable_auto_bid && (
              <Alert severity="info" className={styles.MainCaption}>
                El automatizador le permite pujar en distintas hipotecas de
                acuerdo a los parámetros y condiciones seleccionadas al momento
                de crear la automatización{" "}
              </Alert>
            )}
            {mode === "list" && !profile?.enable_auto_bid && (
              <Alert severity="warning">
                Tienes desactivado el servicio de autopujas en tu cuenta, esto impedirá que se creen nuevas ofertas automáticas o poder agregar nuevas autopujas para segmentar perfiles de clientes. Por favor ponte en contacto con nosotros.
              </Alert>
            )}

          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {profile && profile?.enable_auto_bid ? (
              <Grid item md={12} sm={12} xs={12}>
                <Box sx={{ textAlign: "right" }}>
                  {mode === "list" && biddings.length < 40 && isShowing && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      size="small"
                      onClick={() => changeMode()}
                    >
                      Crear automatización
                    </Button>
                  )}
                </Box>

                {mode === "list" && profile && (
                  <TableListResume
                    isLoading={isLoading}
                    profile={profile}
                    biddings={biddings}
                    applicationsPaging={applicationsPaging}
                    onTabClick={(v) => handleChangeTab(v)}
                    onEditClick={(b) => {
                      setBidding(b);
                      setMode("edit");
                    }}
                    onDeleteClick={(id) => {
                      delBidding(id);
                    }}
                    onDuplicateBidding={(id) => {
                      duplicateBidding(id);
                    }}
                    onSaveSettings={(data) => {
                      const hasZero = !data.autobidding_min_margin || !data.autobidding_percentage
                      if (hasZero) {
                        // este alert se usa tambien en otro lado es igual!!! centralizarlo o no ?
                        Swal.fire({
                          title: "¿Estás seguro?",
                          text: "Los honorarios ingresados son 0.",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Aceptar",
                          cancelButtonText: "Cancelar",
                        }).then((result: any) => {
                          if (result.isConfirmed) {
                            setBidderComissionValues(data);
                          }
                        });
                        return
                      }
                      setBidderComissionValues(data);
                    }}
                    onChangePage={(v) => {
                      changePage(v);
                    }}
                    tabValue={tabValue}
                  />
                )}

                {(mode === "add" || mode === "edit") && profile && (
                  <CreateSetup
                    defaultValue={mode === "edit" ? bidding : null}
                    profile={profile}
                    onSave={(data) => {
                      if (mode === "add") {
                        create(data);
                      }

                      if (mode === "edit") {
                        update(data);
                      }
                    }}
                    onCancel={() => {
                      setMode("list");
                    }}
                  />
                )}
              </Grid>
            ) : (
              <NotResults
                title="Servicio no disponible"
                caption="Contacta con nosotros para disfrutar de las autopujas"
                resize={true}
                url="/assets/img/sources/service_not_available.svg"
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </MainBrokerLayout>
  );
};

export interface ITableListResumeProps {
  profile: BidderProfile;
  biddings: IBiddingSchema[];
  onTabClick: (v: any) => void;
  onEditClick: (b: any) => void;
  onDeleteClick: (b: string) => void;
  onDuplicateBidding: (b: string) => void;
  onSaveSettings: (b: BidderProfile) => void;
  onChangePage: (v: any) => void;
  applicationsPaging: any;
  tabValue: string;
  isLoading: any;
}
interface PreferencesState {
  autobidding_min_margin: number | string;
  autobidding_percentage: number | string;
}

const TableListResume: FunctionComponent<ITableListResumeProps> = ({
  profile,
  biddings,
  onTabClick,
  onEditClick,
  onDeleteClick,
  onDuplicateBidding,
  onSaveSettings,
  onChangePage,
  applicationsPaging,
  tabValue,
  isLoading,
}): ReactElement<any> => {
  const [preferencesValues, setPreferenceValues] = useState<PreferencesState>({
    autobidding_min_margin: 0,
    autobidding_percentage: 0,
  });
  const [showSetting, setShowSettings] = useState(false);
  const { isUserBank } = useRole();

  useEffect(() => {
    if (profile) {
      preferencesValues.autobidding_min_margin = profile.autobidding_min_margin;
      preferencesValues.autobidding_percentage = profile.autobidding_percentage;

      setPreferenceValues({ ...preferencesValues });
      setShowSettings(isBrokerProfile(profile));
    }
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={parseInt(tabValue)}
            onChange={(e, value) => {
              onTabClick(value);
            }}
          >
            <Tab label="Automatizaciones" />
            {showSetting && <Tab label="Configuraciones" />}
          </Tabs>
        </Box>
      </Box>
      <Divider component="br" />
      <TabPanel value={parseInt(tabValue)} index={0}>
        {(() => {
          if (biddings.length === 0 && !isLoading.loading) {
            return (
              <NotResults
                title="Sin Resultados"
                caption="No se han encontrado registros"
              />
            );
          } else if (biddings.length > 0 && !isLoading.loading) {
            return (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  <TableContainer sx={{ maxHeight: 650 }} component={Paper}>
                    <Table stickyHeader sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Título</TableCell>
                          <TableCell align="center">
                            Límite pujas diarias
                          </TableCell>
                          <TableCell align="center">
                            Total de pujas del día
                          </TableCell>
                          {/* <TableCell align="center">
                            Reintento de pujas
                          </TableCell> */}
                          <TableCell align="center">Fecha creación</TableCell>
                          <TableCell align="center">
                            Ultima actualización
                          </TableCell>
                          <TableCell align="center">Estado</TableCell>
                          <TableCell align="center">Opciones</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {biddings.map((bidding: IBiddingSchema, i: number) => {
                          return (
                            <TableRow hover key={i || 0}>
                              <TableCell align="center">
                                {bidding.title}
                              </TableCell>
                              <TableCell align="center">
                                {bidding.max_bids}
                              </TableCell>
                              <TableCell align="center">
                                {bidding.progress.counter_new}
                              </TableCell>
                              {/* <TableCell align="center">
                                {bidding.progress.counter_old}
                              </TableCell> */}
                              <TableCell align="center">
                                {(bidding.created_at || "") !== "" && (
                                  <>
                                    {format(
                                      parseISO(bidding.created_at),
                                      "dd/MM/yyyy HH:mm:ss"
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {(bidding.updated_at || "") !== "" && (
                                  <>
                                    {format(
                                      parseISO(bidding.updated_at),
                                      "dd/MM/yyyy HH:mm:ss"
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {bidding.is_active === true && (
                                  <Chip
                                    label="Activo"
                                    color="success"
                                    variant="outlined"
                                    className={styles.ChipAuto}
                                  />
                                )}

                                {bidding.is_active !== true && (
                                  <Chip
                                    label="Inactivo"
                                    color="error"
                                    variant="outlined"
                                    className={styles.ChipAuto}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Stack direction="row">
                                  <IconButton
                                    aria-label="Editar"
                                    onClick={() => {
                                      onEditClick(bidding);
                                    }}
                                  >
                                    <EditIcon color="primary" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="Eliminar"
                                    onClick={() => onDeleteClick(bidding.id)}
                                  >
                                    <DeleteForeverIcon color="error" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="Clonar"
                                    onClick={() =>
                                      onDuplicateBidding(bidding.id)
                                    }
                                  >
                                    <ContentCopyIcon color="primary" />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                {biddings.length > 0 && applicationsPaging.pages > 0 && (
                  <Grid item md={12} sm={12} xs={12} mt="1rem">
                    <PaginationBar
                      count={applicationsPaging.count}
                      rowsPerPage={applicationsPaging.limit}
                      page={applicationsPaging.page}
                      pages={applicationsPaging.pages}
                      onChange={(p) => {
                        applicationsPaging.page = p;

                        onChangePage(applicationsPaging);
                      }}
                      onChangeLimit={(l) => {
                        applicationsPaging.page = 1;
                        applicationsPaging.limit = l;
                        onChangePage(applicationsPaging);
                      }}
                    />
                  </Grid>
                )}
              </>
            );
          }
        })()}
        {isLoading.loading && (
          <TableSkeleton headColumns={4} bodyRows={6} bodyColumns={4} />
        )}
      </TabPanel>
      <TabPanel value={parseInt(tabValue)} index={1}>
        <Grid container spacing={2}>
          <Grid item md={10} className={styles.info}>
            <Typography variant="body2">
              Aquí podrás guardar las configuraciones que serán aplicadas a
              todos tus autopujas.
              <span className={styles.infoHiglight}>
                {" "}
                Estos son los importes que tú recibirás.
              </span>
            </Typography>
            <Typography variant="body2">
              Al modificar la configuración sólo afectará a las pujas que se
              envíen luego del cambio. No será retroactivo.
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            {!isMobile && (
              <Button
                onClick={() => {
                  profile.autobidding_min_margin = Number(
                    preferencesValues.autobidding_min_margin
                  );
                  profile.autobidding_percentage = Number(
                    preferencesValues.autobidding_percentage
                  );
                  onSaveSettings(profile);
                }}
                // disabled={
                //   !preferencesValues.autobidding_min_margin ||
                //   !preferencesValues.autobidding_percentage
                // }
                variant="contained"
                color="primary"
                fullWidth
              >
                Guardar
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            {profile && (
              <React.Fragment>
                <Grid
                  spacing={2}
                  mt="2rem"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    className={styles.Title}
                    width="40%"
                  >
                    Margen sobre el importe de la hipoteca
                  </Typography>
                  <TextField
                    size="small"
                    variant="filled"
                    type="text"
                    inputProps={{
                      step: "0.01",
                      min: 0,
                    }}
                    // error={
                    //   !parseFloat(
                    //     preferencesValues.autobidding_percentage.toString()
                    //   )
                    // }
                    // helperText={
                    //   !parseFloat(
                    //     preferencesValues.autobidding_percentage.toString()
                    //   )
                    //     ? "Valor incorrecto"
                    //     : null
                    // }
                    value={preferencesValues.autobidding_percentage}
                    onChange={(value) => {
                      preferencesValues.autobidding_percentage = formatToRate(
                        value.target.value
                      );

                      setPreferenceValues({ ...preferencesValues });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip
                    title="Este es el porcentaje que deseas ganar."
                  >
                    <InfoOutlinedIcon className={styles.toolTip} />
                  </Tooltip>
                </Grid>
                <Grid
                  spacing={2}
                  mt="2rem"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    className={styles.Title}
                    width="40%"
                  >
                    Honorarios mínimos a percibir
                  </Typography>
                  <TextField
                    size="small"
                    variant="filled"
                    inputProps={{
                      step: "0.01",
                      min: 0,
                    }}
                    // error={
                    //   !parseFloat(
                    //     preferencesValues.autobidding_min_margin.toString()
                    //   )
                    // }
                    // helperText={
                    //   !parseFloat(
                    //     preferencesValues.autobidding_min_margin.toString()
                    //   )
                    //     ? "Valor incorrecto"
                    //     : null
                    // }
                    onChange={(value) => {
                      preferencesValues.autobidding_min_margin = value.target
                        .value
                        ? parseFloat(value.target.value)
                        : "";

                      setPreferenceValues({ ...preferencesValues });
                    }}
                    value={preferencesValues.autobidding_min_margin}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip
                    title="Este es el valor mínimo que quieres ganar. De no alcanzar el mínimo con el porcentaje, se aplicará este mínimo."
                  >
                    <InfoOutlinedIcon className={styles.toolTip} />
                  </Tooltip>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Typography mt="1rem" variant="body2">
                Ejemplo 1 (El % no alcanza el mínimo, por lo que se cobra al
                cliente los honorarios mínimos requeridos):
              </Typography>
              <Grid item>
                <List>
                  <ListItem>
                    <ListItemText primary="Valor de hipoteca = €80.000" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Porcentaje de ganancia deseado = 0,4%" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Ganancia mínima requerida = €1.000" />
                  </ListItem>
                </List>
              </Grid>
              <Divider
                orientation="vertical"
                className={styles.hide}
                flexItem
              ></Divider>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    margin: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ul className={styles.boxListed}>
                    <li>Si fuera con el % deseado = €320</li>
                    <li>Si es con la ganancia mínima = €1.000</li>
                    <li>Honorarios que recibe el cliente = €1.000</li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Typography mt="1rem" variant="body2">
                Ejemplo 2 (El % está por encima del mínimo, por lo que se cobra
                al cliente el porcentaje deseado):
              </Typography>
              <Grid item>
                <List>
                  <ListItem>
                    <ListItemText primary="Valor de hipoteca = €350.000" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Porcentaje de ganancia deseado = 0,4%" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Ganancia mínima requerida = €1.000" />
                  </ListItem>
                </List>
              </Grid>
              <Divider
                orientation="vertical"
                className={styles.hide}
                flexItem
              ></Divider>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    margin: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ul className={styles.boxListed}>
                    <li>Si fuera con el % deseado = €1400</li>
                    <li>Si es con la ganancia mínima = €1.000</li>
                    <li>Honorarios que recibe el cliente = €1.400</li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          {isMobile && (
            <Grid item xs={12} md={12} textAlign="center">
              <Button
                onClick={() => {
                  profile.autobidding_min_margin = Number(
                    preferencesValues.autobidding_min_margin
                  );
                  profile.autobidding_percentage = Number(
                    preferencesValues.autobidding_percentage
                  );
                  onSaveSettings(profile);
                }}
                // disabled={
                //   !preferencesValues.autobidding_min_margin ||
                //   !preferencesValues.autobidding_percentage
                // }
                variant="contained"
                color="primary"
                fullWidth
              >
                Guardar
              </Button>
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </>
  );
};

export interface ICreateSetupProps {
  onSave: (data: IBiddingAddSchema) => void;
  onCancel: () => void;
  defaultValue: any;
  profile: BidderProfile;
}
const CreateSetup: FunctionComponent<ICreateSetupProps> = ({
  onSave,
  onCancel,
  defaultValue,
  profile,
}): ReactElement<any> => {
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showModalAddBid, setShowModalAddBid] = useState<boolean>(false);
  const isBroker = isBrokerProfile(profile);
  const isRequiredPurchase = isBroker && profile.require_purchase_package;

  const [bidding, setBidding] = useState<IBiddingAddSchema>({
    title: "",
    filters: {},
    conditions: {},
    max_bids: 5,
    max_users: 5,
    is_active: true,
    for_new: true,
    for_old: false,
    run_mon: true,
    run_tue: true,
    run_wed: true,
    run_thu: true,
    run_fri: true,
    run_sat: true,
    run_sun: true,
  });

  const [fieldsValidation, setFieldsValidation] = useState({
    title: {
      error: false,
      description: "",
    },
    max_users: {
      error: false,
      description: "",
    },
    max_bids: {
      error: false,
      description: "",
    },
    filters: {
      error: false,
      description: "",
    },
    conditions: {
      error: false,
      description: "",
    },
  });

  const validate = () => {
    let error = false;

    // Check title
    if (bidding.title === "" || bidding.title === null) {
      fieldsValidation.title.error = true;
      fieldsValidation.title.description = "Este campo es requerido";

      error = true;
    }

    // Check max bids
    if (
      bidding.max_users === null ||
      Number(bidding.max_users) === 0 ||
      Number(bidding.max_users) < 0
    ) {
      fieldsValidation.max_users.error = true;
      fieldsValidation.max_users.description = "El valor debe ser mayor a 0";

      error = true;
    }
    // Check max user
    if (
      bidding.max_users === null ||
      Number(bidding.max_users) === 0 ||
      Number(bidding.max_users) < 0
    ) {
      fieldsValidation.max_users.error = true;
      fieldsValidation.max_users.description = "El valor debe ser mayor a 0";

      error = true;
    }

    // Check filters
    if (Object.keys(bidding.filters).length === 0) {
      fieldsValidation.filters.error = true;
      fieldsValidation.filters.description =
        "Los filtros no pueden estar vacios. Verifique e intente nuevamente";

      error = true;
    }

    // Check conditions
    if (Object.keys(bidding.conditions).length === 0) {
      fieldsValidation.conditions.error = true;
      fieldsValidation.conditions.description =
        "Asegurese de ingresar correctamente los detalles de la Puja";

      error = true;
    }

    setFieldsValidation({ ...fieldsValidation });

    if (!error) {
      onSave(bidding);
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (defaultValue !== null) {
      const b = defaultValue;

      if (typeof b.filters === "string" || b.filters instanceof String) {
        b.filters = JSON.parse(defaultValue.filters.replaceAll("'", '"'));
      }

      setBidding({ ...b });
    }
  }, [defaultValue]);

  const handleChange = (
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  ) => {
    const bidLimitsDaily = 3;
    return target.value.length <= bidLimitsDaily;
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 600,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" component="p">
              {defaultValue !== null ? "Editar" : "Crear"} automatización
            </Typography>
          </Grid>

          {fieldsValidation.filters.error === true && (
            <Grid item xs={12}>
              <Alert severity="warning" className={styles.AlertStyle}>
                {fieldsValidation.filters.description}
              </Alert>
            </Grid>
          )}

          {fieldsValidation.filters.error === false &&
            fieldsValidation.conditions.error === true && (
              <Grid item xs={12}>
                <Alert severity="warning" className={styles.AlertStyle}>
                  {fieldsValidation.conditions.description}
                </Alert>
              </Grid>
            )}

          <Grid item xs={12}>
            <Typography gutterBottom variant="caption" component="p">
              Parametros generales
            </Typography>

            <Divider />
          </Grid>

          <Grid item xs={12}>
            <HelperTextFieldWrapper text="Ingrese una descripción breve que le permita identificar está automatización de las demás que podrá crear">
              <TextField
                label="Título"
                variant="outlined"
                size="small"
                name="title"
                error={fieldsValidation.title.error}
                helperText={fieldsValidation.title.description}
                value={bidding.title}
                onChange={({ target }) => {
                  bidding.title = target.value;

                  fieldsValidation.title.error = false;
                  fieldsValidation.title.description = "";

                  setFieldsValidation({ ...fieldsValidation });
                  setBidding({ ...bidding });
                }}
                fullWidth
              />
            </HelperTextFieldWrapper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <HelperTextFieldWrapper text="Corresponde al número de pujas máximo que debera realizar el automatizador diariamente">
              <TextField
                label={
                  isBroker && isRequiredPurchase
                    ? "Límite usuarios diarios"
                    : "Límite pujas diarias"
                }
                required
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                // TODO this should be changing to max_user in the future
                // inputMode="numeric"
                error={fieldsValidation.max_bids.error}
                helperText={fieldsValidation.max_bids.description}
                value={
                  isBroker && isRequiredPurchase
                    ? bidding.max_users
                    : bidding.max_bids
                }
                onChange={({ target }) => {
                  if (handleChange(target)) {
                    let newBidding = { ...bidding };
                    let newFieldsValidation = { ...fieldsValidation };

                    const newValue = parseInt(target.value);
                    if (isBroker && isRequiredPurchase) {
                      newBidding.max_users = newValue;
                      newFieldsValidation.max_users.error = false;
                      newFieldsValidation.max_users.description = "";
                    } else {
                      newBidding.max_bids = newValue;
                      newFieldsValidation.max_bids.error = false;
                      newFieldsValidation.max_bids.description = "";
                    }

                    setFieldsValidation(newFieldsValidation);
                    setBidding(newBidding);
                  }
                }}
              />
            </HelperTextFieldWrapper>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={bidding.is_active}
                  onChange={(event, checked) => {
                    bidding.is_active = Boolean(checked);
                    setBidding({ ...bidding });
                  }}
                />
              }
              label={
                <>
                  <Typography
                    gutterBottom
                    variant="caption"
                    component="p"
                    sx={{ marginBottom: "0px", color: "#9a9a9a" }}
                  >
                    Estado de la automatización
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="p"
                    sx={{ marginTop: "-5px" }}
                  >
                    Activo
                  </Typography>
                </>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant="caption" component="p">
              Días de operatividad
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ paddingTop: "0px !important" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_mon}
                    onChange={(event, checked) => {
                      bidding.run_mon = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Lunes"
              />

              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_tue}
                    onChange={(event, checked) => {
                      bidding.run_tue = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Martes"
              />

              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_wed}
                    onChange={(event, checked) => {
                      bidding.run_wed = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Miércoles"
              />

              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_thu}
                    onChange={(event, checked) => {
                      bidding.run_thu = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Jueves"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_fri}
                    onChange={(event, checked) => {
                      bidding.run_fri = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Viernes"
              />

              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_sat}
                    onChange={(event, checked) => {
                      bidding.run_sat = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Sábado"
              />

              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={bidding.run_sun}
                    onChange={(event, checked) => {
                      bidding.run_sun = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label="Domingo"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* By default this parameters now are declare true form the API side */}
          {/* <Grid item xs={12}>
            <Typography variant="caption" component="p">
              Habilitar para
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bidding.for_new}
                    onChange={(event, checked) => {
                      bidding.for_new = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label={
                  <>
                    <Typography variant="subtitle1" component="p">
                      Hipotecas que no he pujado
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bidding.for_old}
                    onChange={(event, checked) => {
                      bidding.for_old = Boolean(checked);
                      setBidding({ ...bidding });
                    }}
                  />
                }
                label={
                  <Typography variant="subtitle1" component="p">
                    Hipotecas que ya he pujado
                  </Typography>
                }
              />
            </FormGroup>
          </Grid> */}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" component="p">
              Filtros y detalles de la puja
            </Typography>

            <Alert severity="info" className={styles.MainCaption}>
              <b>Antes de configurar</b> los detalles de la Puja debe definir al
              menos los filtros: <br />
              <ul>
                <li>Tipo de hipoteca</li>
                <li>Duración de Hipoteca</li>
              </ul>
              Estos valores son necesarios para poder registrar los{" "}
              <b>Detalles de la Puja</b>
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                size="small"
                startIcon={<FilterAltIcon />}
                onClick={() => setShowFilterModal(true)}
              >
                Filtros
              </Button>

              <Button
                variant="outlined"
                disabled={
                  Object.keys(bidding.filters).length === 0 ||
                  typeof bidding.filters["mortgage.mode__in"] === "undefined" ||
                  typeof bidding.filters["data.period__lte"] === "undefined"
                }
                size="small"
                color="warning"
                startIcon={<GavelIcon />}
                onClick={() => setShowModalAddBid(true)}
              >
                Detalles de la puja
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider component="br" />
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1} direction="row-reverse">
              <Button
                variant="contained"
                color="error"
                size="small"
                startIcon={<ClearIcon />}
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => {
                  validate();
                }}
                disabled={
                  Object.keys(bidding.filters).length === 0 ||
                  typeof bidding.filters["mortgage.mode__in"] === "undefined" ||
                  typeof bidding.filters["data.period__lte"] === "undefined" ||
                  Object.keys(bidding.conditions).length === 0
                }
              >
                Guardar
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>

      <ApplicationsFilterModal
        readOnly={true}
        defaultFilters={
          Object.keys(bidding.filters).length !== 0
            ? decodeFiltersAPI(bidding.filters)
            : defaultFilters()
        }
        show={showFilterModal}
        onSubmit={(data) => {
          const filters = getFormatedFiltersForAPI(data);

          filters["data.interest_type__in"] = ["todos"];

          bidding.filters = filters;
          /*   if (
            filters["data.interest_type"] !==
            bidding.conditions.interest_data?.mode
          ) {
            bidding.conditions = {};
          } */

          fieldsValidation.filters.error = false;
          fieldsValidation.filters.description = "";

          setFieldsValidation({ ...fieldsValidation });
          setBidding({ ...bidding });
          setShowFilterModal(false);
        }}
        onClose={() => {
          setShowFilterModal(false);
        }}
        onFiltering={(status) => { }}
        onClearFilter={() => {
          bidding.filters = {};

          setBidding({ ...bidding });
        }}
      />
      {showModalAddBid === true && (
        <BidModal
          mode="auto"
          defaultValue={
            Object.keys(bidding.conditions).length === 0
              ? null
              : bidding.conditions
          }
          readOnly={true}
          mortgage={{
            mortgage_data: {
              mortgage: {
                mode: bidding.filters["mortgage.mode"],
              },
              data: {
                interest_type: bidding.filters["data.interest_type__in"],
                period: bidding.filters["data.period__lte"],
              },
            },
          }}
          onClose={() => {
            setShowModalAddBid(false);
          }}
          onSave={(data) => {
            bidding.conditions = data;

            fieldsValidation.conditions.error = false;
            fieldsValidation.conditions.description = "";

            if (bidding.conditions?.interest_data?.mode) {
              let item = [];

              let value = bidding.conditions.interest_data.mode;
              item = ["todos", value];

              bidding.filters["data.interest_type__in"] = item;
            }

            setFieldsValidation({ ...fieldsValidation });

            setBidding({ ...bidding });
            setShowModalAddBid(false);
          }}
        />
      )}
    </>
  );
};

export default AutoBidding;
