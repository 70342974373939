import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import FormFields from "shared/components/FormFields";
import {
  spainComunitiesAndProvices,
  SpainComunitiesAndProvices,
} from "../../data/spain_cominities_provinces";
import { propertyLocationSchema } from "../../validation/property";
import { useDebounce } from "shared/hooks";
import { getMapGeocoding } from "services/api";
import { useFormContext } from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { useEffect } from "react";

function PropertyLocationForm() {
  const { mortgageFormData, onStepClick } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onStepClick()}
      onBack={onStepClick(false)}
      title="¿Dónde se encuentra la vivienda?"
      resolver={yupResolver(propertyLocationSchema)}
    >
      <Form />
    </StepLayout>
  );
}

function Form() {
  const { setValue, watch } = useFormContext<MortgageFormData>();

  const [property] = watch(["property"]);
  const comunidades = Object.keys(spainComunitiesAndProvices);

  const provincias =
    spainComunitiesAndProvices[
      property.community as keyof SpainComunitiesAndProvices
    ];

  return (
    <Stack
      id="property-location-form-inputs"
      height="inherit"
      spacing={3}
      justifyContent="center"
      alignItems="stretch"
      margin="auto"
      maxWidth="370px"
      width="100%"
    >
      <FormFields.AutoCompleteInput
        options={comunidades.map((community) => community)}
        onChange={(e, v) => {
          setValue("property.province", "");
          // setValue("property.address", "");
        }}
        fullWidth
        name="property.community"
        disablePortal
        id="community-box"
        label="Comunidad"
      />
      <FormFields.AutoCompleteInput
        noOptionsText="Por favor selecciona una comunidad"
        options={
          provincias ? provincias.map((province: string) => province) : []
        }
        name="property.province"
        disablePortal
        id="province-box"
        label="Provincia"
      />
      <MapBoxAutocomplete />
      <PropertyView />
    </Stack>
  );
}

function PropertyView() {
  const { setValue, watch, getFieldState } = useFormContext<MortgageFormData>();
  const [property] = watch(["property"]);
  const { address, zipcode, city } = property;

  if (!address) return null;

  const zipcodeError = getFieldState("property.zipcode").error;
  const cityError = getFieldState("property.city").error;

  return (
    <Stack paddingX={2} spacing={2}>
      <Typography>
        Dirección:{" "}
        <Typography color="primary" component="span">
          {address}
        </Typography>
      </Typography>
      {Boolean(zipcodeError) ? (
        <FormFields.TextFieldInput
          id="propertyZipcode"
          autoComplete="no-autofill"
          label="Código postal"
          name="property.zipcode"
          inputProps={{
            maxLength: 5,
          }}
        />
      ) : (
        <Typography>
          Código postal:{" "}
          <Typography color="primary" component="span">
            {zipcode}
          </Typography>
        </Typography>
      )}
      {Boolean(cityError) ? (
        <FormFields.TextFieldInput
          id="propertyCity"
          autoComplete="no-autofill"
          label="Ciudad"
          name="property.city"
        />
      ) : (
        <Typography>
          Ciudad:{" "}
          <Typography color="primary" component="span">
            {city}
          </Typography>
        </Typography>
      )}
    </Stack>
  );
}
function MapBoxAutocomplete() {
  const { mortgageFormDispatch, mortgageFormState } = useMortgageFormContext();
  const {
    map: { features },
  } = mortgageFormState;
  const { watch, setValue, setError } = useFormContext<MortgageFormData>();
  const [propertyCommunity, propertyProvince] = watch([
    "property.community",
    "property.province",
  ]);
  const debounce = useDebounce(300);
  const handlePropertyAddress = (value: string) => {
    debounce(() => {
      getMapGeocoding({
        location: `${propertyCommunity} ${propertyProvince} ${value}`,
      })
        .then((res) => {
          mortgageFormDispatch({
            type: "SET_FEATURES",
            payload: res.data.features,
          });
        })
        .catch((e) => {
          console.error({ e });
        });
    });
  };

  useEffect(() => {
    setValue("property.address", "");
    handlePropertyAddress("");
  }, [propertyCommunity, propertyProvince]);

  return (
    <FormFields.AutoCompleteInput
      filterOptions={(x) => {
        return x;
      }}
      noOptionsText="Escribe la dirección"
      options={features.map((a) => a.place_name_es)}
      id="adress-box"
      label="Dirección"
      name="property.address"
      onChange={(e, v) => {
        const selectedAddress = features.find((f) => f.place_name_es === v);
        // set geocontext
        if (selectedAddress) {
          mortgageFormDispatch({
            type: "SET_FEATURE",
            payload: selectedAddress,
          });
          // extract the context to find code and place if exist
          const { context } = selectedAddress;
          const postcode = context.find((c) => c.id.includes("postcode"));
          if (postcode) {
            setValue("property.zipcode", postcode.text_es, {
              shouldValidate: true,
            });
          } else {
            setValue("property.zipcode", "");
            setError("property.zipcode", {
              message:
                "No se encontro el código postal, por favor introduzca manualmente",
            });
          }
          const place = context.find((c) => c.id.includes("place"));
          if (place) {
            setValue("property.city", place.text_es, { shouldValidate: true });
          } else {
            setValue("property.city", "");
            setError("property.city", {
              message:
                "No se encontro la ciudad, por favor introduzca manualmente",
            });
          }
        }
      }}
      onInputChange={(e, value) => {
        handlePropertyAddress(value);
      }}
    />
  );
}
export default PropertyLocationForm;
