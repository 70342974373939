import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  StepLabel,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  ReactComponentElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { TabPanel, WithTooltip, NotResults } from "shared/components";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";
import { CustomStepIcon, PPStepperData } from "../PPSteppers";
import styles from "./styles.module.scss";

import { useNavigate } from "react-router-dom";
import { cancelAceptedBids } from "services/api";
import AlertOfferts from "../AlertOfferts";
import BidProgress from "./Components/BidProgress";
import SkeletonCard from "./Components/SkeletonCard";
import CardOffert from "./Components/CardOfert";

interface bidOfertCardComponent {
  id: string;
  onClickOffert: (id: string) => void;
  bid: Bid;
  onViewOffert: (bid: Bid) => void;
  mortgage?: MortgageRes;
  disabled: boolean;
}

interface DatBid {
  nameEntity: string;
  interestInformation: string | ReactNode;
  bidStepIcon: string | any;
  bidStepLabel: string | any;
  progress: number;
}

interface bidOfertCard {
  bid: Bid;
}

interface PPOffersInterface {
  mortagage: MortgageRes;
  mortgageOfferts: Array<Bid>;
  fetching: boolean;
  changeTabOffers: (data: any, isFetching: boolean) => void;
}

const PPOffers = ({
  mortagage,
  mortgageOfferts,
  changeTabOffers,
  fetching,
}: PPOffersInterface): ReactComponentElement<any> => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [offerts, setOfferts] = useState<Array<Bid>>([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [bidsDisabled, setBidsDisabled] = useState<Array<string>>([]);
  const [modalToShow, setModalToShow] = useState("");

  useEffect(() => {
    setOfferts(mortgageOfferts);
  }, [mortgageOfferts]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    changeTabOffers(
      selectedTab === 0 ? "collapsed_system,rejected" : "accepted",
      true
    );
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const cancelOfferts = (modal: string) => {
    setModalToShow("cancelModal");
  };

  const acceptCancelOffert = () => {
    setModalToShow("");
    let cancelOfferts = bidsDisabled.map((bid) => {
      return {
        id: bid,
      };
    });
    cancelAceptedBids(mortagage.id, cancelOfferts).then((res) => {
      changeTabOffers(selectedTab === 0 ? "accepted" : "rejected", true);
      setDisabledButton(true);
    });
  };

  const SkeletonLoading = () => (
    <Container>
      <Grid item xs={12} mt="1rem">
        <SkeletonCard />
      </Grid>

      <Grid item xs={12} mt="1rem">
        <SkeletonCard />
      </Grid>

      <Grid item xs={12} mt="1rem">
        <SkeletonCard />
      </Grid>
    </Container>
  );

  const DeleteActionButton = () => {
    const commonProps = {
      disabled: disabledButton,
      onClick: () => cancelOfferts("cancelOffer"),
    };

    return isMobile ? (
      <IconButton aria-label="delete" color="primary" {...commonProps}>
        <DeleteIcon />
      </IconButton>
    ) : (
      <Button
        variant="contained"
        size="medium"
        startIcon={<DeleteIcon />}
        {...commonProps}
      >
        Cancelar ofertas
      </Button>
    );
  };

  const ImportantNote = () => (
    <Typography className={styles.typografy} alignItems="center">
      <span className={styles.highlightTitle}>*¡IMPORTANTE! </span>
      los documentos que cargues para una oferta, serán
      <span className={styles.highlightParagrah}> válidos para todas.</span>
      Por lo que puedes actualizarlo desde cualquier oferta individual o desde
      la sección “Mis Documentos” en el menú principal.
    </Typography>
  );

  return (
    <div>
      <Typography variant="caption" display="block" gutterBottom>
        <p>
          Aquí encontrarás el resumen de las ofertas elegidas. Podrás ver de
          cada una de ellas: información principal, paso del proceso en que se
          encuentra y grado de avance. Verás que puede pasar que algunas ofertas
          avancen más rápido en el proceso que otras. Esto es porque cada
          entidad tiene sus tiempos. Pero no te preocupes, al ingresar a cada
          una te contaremos qué necesitamos de ti y qué debes esperar de esa
          parte del proceso.
        </p>
      </Typography>

      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab label="Mis ofertas elegidas activas" />
        <Tab label="Mis ofertas elegidas canceladas" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <Container>
          <Grid container spacing={2}>
            {offerts.length > 0 && !fetching && (
              <React.Fragment>
                <Grid item xs={9} md={10}></Grid>
                <Grid item xs={3} md={2} textAlign="end">
                  <WithTooltip
                    title="Si no deseas seguir adelante con alguna de las ofertas, puedes darla de baja sin problema. Sólo ten en cuenta que luego no podrás reactivarla."
                    position="left"
                  >
                    <DeleteActionButton />
                  </WithTooltip>
                </Grid>
                <Grid item xs={12}>
                  {fetching ? (
                    <SkeletonLoading />
                  ) : (
                    <OffertsList
                      mortagage={mortagage}
                      offerts={offerts}
                      disabled={false}
                      onCheck={(bidsDisabled) => {
                        setBidsDisabled(bidsDisabled);
                        setDisabledButton(bidsDisabled.length === 0);
                      }}
                    />
                  )}
                  <Grid item xs={11} mt="1rem">
                    {mortagage.status === "in_verification_accepted" && (
                      <ImportantNote />
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {fetching && (
              <Grid item xs={11} padding="2rem">
                <SkeletonLoading />
              </Grid>
            )}
          </Grid>
        </Container>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <Container>
          <Grid item xs={11}>
            {fetching ? (
              <SkeletonLoading />
            ) : (
              <OffertsList
                mortagage={mortagage}
                offerts={offerts}
                disabled={true}
              />
            )}
          </Grid>
        </Container>
      </TabPanel>

      {modalToShow === "cancelModal" && (
        <AlertOfferts
          imgUrl="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/warning.png"
          content={
            <div>
              <Typography variant="body1" textAlign={"center"}>
                ¿Estás seguro que deseas eliminar estas ofertas? No hay
                problema, sólo ten en cuenta que luego no podrás re-activarlas.
              </Typography>

              <Typography variant="body1" textAlign={"center"}>
                Al eliminarlas, desapaecerán de este panel pero podrás verlas en
                “Mis ofertas elegidas canceladas”{" "}
              </Typography>
            </div>
          }
          closeButtonText="volver"
          acceptButtonText="Eliminar ofertas"
          showModal={true}
          sendEventAccept={() => {
            acceptCancelOffert();
          }}
          closeEventAccept={() => {
            setModalToShow("");
          }}
        />
      )}

      <AlertOfferts
        imgUrl="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/target.png"
        content="¡Has cancelado las ofertas correctamente!
        Ahora puedes seguir enfocado en las que realmente te interesan ;)"
        closeButtonText=""
        acceptButtonText="ok"
        showModal={false}
      />

      <AlertOfferts
        imgUrl="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/target.png"
        content={
          <div>
            <Typography variant="h6" textAlign={"center"} color="#01807A">
              ¡Parece que tenemos un ganador!
            </Typography>
            <Typography mt="1rem" variant="body1" textAlign={"center"}>
              La entidad XXX nos informa que está listao para concederte la
              hipoteca. ¿qué significa? Pues que cerrarás el acuerdo con ellos,
              y que el resto de las ofertas con que hayas avanzado, se
              cancelarán. Si estás de acuerdo con esto, danos el OK.
            </Typography>

            <Typography variant="body1" textAlign={"center"}>
              Si aún necesitas pensarlo, puedes cancelar tú mismo las ofertas
              que quieras cancelar.
            </Typography>
          </div>
        }
        closeButtonText="mas tarde"
        acceptButtonText="Avanzar con"
        showModal={false}
      />
      <AlertOfferts
        imgUrl="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/warning.png"
        content={
          <div>
            <Typography variant="h6" textAlign={"center"} color="#01807A">
              ¡Parece que tenemos un ganador!
            </Typography>
            <Typography mt="1rem" variant="body1" textAlign={"center"}>
              Sólo queremos confirmar que estés seguro :) Si lo tienes claro,
              ¡pues vamos!
            </Typography>
          </div>
        }
        closeButtonText="Atrás"
        acceptButtonText="¡Estoy seguro!"
        showModal={false}
      />
    </div>
  );
};

const OffertsList = ({
  disabled,
  offerts,
  mortagage,
  onCheck,
}: {
  disabled: boolean;
  offerts: Array<Bid>;
  mortagage: MortgageRes;
  onCheck?: (bidDisable: Array<string>) => void;
}) => {
  const [bidsDisabled, setBidsDisabled] = useState<Array<string>>([]);
  const navigate = useNavigate();

  const handleOnClickOffert = (id: string) => {
    const newBidsDisabled = bidsDisabled.includes(id)
      ? bidsDisabled.filter((bidId) => bidId !== id)
      : [...bidsDisabled, id];

    // []
    // [ "sd232", "2323"]
    // ["3432"]
    // [...newBidsDisabled]
    setBidsDisabled(newBidsDisabled);

    onCheck?.(newBidsDisabled);
  };
  return (
    <>
      {offerts.map((bid) => (
        <CardOffert
          checked={bidsDisabled.includes(bid.id)}
          mortgageCard={mortagage}
          disabled={disabled}
          key={bid.id}
          id={bid.id}
          bid={bid}
          onClickOffert={handleOnClickOffert}
          onViewOffert={(offertToSee) =>
            navigate(`/client/bid/${offertToSee.id}`)
          }
        />
      ))}

      {offerts.length === 0 && (
        <NotResults
          title="Sin Resultados"
          caption="No se han encontrado resultados"
          url="/assets/img/sources/not_result_banks.svg"
          resize={true}
        />
      )}
    </>
  );
};

export default PPOffers;
