import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { setDefaultUserBiddings } from "services/api/biddings.service";
import dataStorageService from "services/api/dataStorage.service";
import FormFields from "shared/components/FormFields";
import { DEFAULT_USER_BIDDING_MODAL } from "shared/constants/general";
import { useDialog } from "shared/hooks";
import { BrokerProfile } from "shared/types/Profile";
import { getImgBaseUrl } from "utils/links";

type FormData = Parameters<typeof setDefaultUserBiddings>[0]["params"];

type Props = {
  profile: BrokerProfile;
  onSubmit?: () => void;
  hasAutoBiddings: boolean;
};
/**This components is use to check is the bidder has set the default max_user */
function UserDefaultDialog(props: Props) {
  const { profile, hasAutoBiddings } = props;
  const formMethods = useForm<FormData>({ defaultValues: { max_users: 5 } });
  const { isShowing, closeDialog, openDialog } = useDialog();

  const setUserHasSeenDialog = () => {
    dataStorageService
      .setData({
        key: DEFAULT_USER_BIDDING_MODAL,
        payload: true,
        days_expire: 365,
      })
      .then(() => {
        closeDialog();
        props.onSubmit?.();
      });
  };
  useEffect(() => {
    if (profile.require_purchase_package) {
      if (!hasAutoBiddings) {
        setUserHasSeenDialog();
        return;
      }
      // in theory is success then do nothing
      dataStorageService
        .getData<boolean>(DEFAULT_USER_BIDDING_MODAL)
        .catch((err) => {
          openDialog();
        });
    }
  }, [profile]);

  const onSubmit = (data: FormData) => {
    setDefaultUserBiddings({ action: "set_max_users_bulk", params: data }).then(
      () => {
        setUserHasSeenDialog();
      }
    );
  };
  return (
    <Dialog open={isShowing}>
      <DialogContent>
        <Stack alignItems="center" gap={2}>
          <img src={getImgBaseUrl("warning-alert.png")} height={80} />
          <Typography variant="h6" color="primary">
            ¡Tus auto-pujas configuradas tendrán un cambio!
          </Typography>
          <Typography>
            Para que puedas tener control sobre los usuarios impactados, cada
            auto-puja tendrá un límite diario que desees alcanzar. Configuramos
            este límite por default en 5 usuarios alcanzados por día. Pondrás
            cambiar este liímite en cada configuración cuando lo desees, o
            puedes decirnos el valor default que quieras aquí:
          </Typography>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Stack gap={2} alignItems="center">
                <FormFields.CounterInput
                  name="max_users"
                  label="Usuarios diarios"
                  min={0}
                />
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ maxWidth: "260px" }}
                >
                  Confirmar límite diario de usuarios alcanzados
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default UserDefaultDialog;
