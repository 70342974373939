import { AxiosResponse } from "axios";
import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import {
  BidderEvaluationOption,
  BidderMetrics,
  CrmFields,
  CrmFunnel,
  CRMTable,
  PostCrmInfoFields,
} from "shared/types/Crm";
import axios from "utils/axios";

interface GetCrmDataParams {
  cols?: Array<keyof CRMTable>;
  mode?: "" | "dead" | "paid";
  p?: number; //pages
  ipp?: number; // item per pages
  q?: string;
}

const getCrmData = ({
  cols = [],
  mode = "",
  p,
  ipp,
  q,
}: GetCrmDataParams = {}) => {
  const stringify = JSON.stringify(cols);
  const params: GetCrmDataParams = {};
  if (cols && cols.length > 0) {
    params.cols = cols;
  }
  if (mode) {
    params.mode = mode;
  }
  if (q) {
    params.q = q;
  } else {
    if (p) {
      params.p = p;
    }
    if (ipp) {
      params.ipp = ipp;
    }
  }

  return axios.get<BaseApiSuccessRes<CRMTable[]>>(
    `${process.env.REACT_APP_API_URL}/bidders/crm`,
    {
      params,
    }
  );
};

const postCrmDataValue = ({
  id,
  field,
  value,
  comment,
  fileType,
  fileUrl,
  payload,
}: PostCrmInfoFields & {
  comment?: string;
  fileUrl?: string;
  fileType?: string;
  payload?: unknown;
}) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("step", String(field));
  formData.append("value", value);
  formData.append("comment", comment || "");
  formData.append("step_file_url", fileUrl || "");
  formData.append("step_file_type", fileType || "");
  if (typeof payload === "object") {
    // for (let k in payload){
    //   formData.append('payload', payload[k])
    // }
    formData.append("payload", JSON.stringify(payload));
  } 
  if (typeof payload === 'string'){
    formData.append("payload", payload || "");
  }
  return axios.post(
    `${process.env.REACT_APP_API_URL}/bidders/crm/eval`,
    formData
  );
};

const getCrmOptionsStates = () => {
  return axios.get<BaseApiSuccessRes<BidderEvaluationOption[]>>(
    `${process.env.REACT_APP_API_URL}/bidders/crm/states`
  );
};

const getCrmHeaderFields = () => {
  return axios.get<BaseApiSuccessRes<CrmFields[]>>(
    `${process.env.REACT_APP_API_URL}/bidders/crm/fields`
  );
};

const getCrmMetrics = () => {
  return axios.get<BaseApiSuccessRes<BidderMetrics>>(
    `${process.env.REACT_APP_API_URL}/bidders/crm/metrics`
  );
};

const getCrmFunnels = (mode?: GetCrmDataParams["mode"]) => {
  const params = mode ? { mode } : {};
  return axios.get<BaseApiSuccessRes<CrmFunnel>>(
    `${process.env.REACT_APP_API_URL}/bidders/crm/funnel`,
    { params }
  );
};

export {
  getCrmData,
  getCrmOptionsStates,
  postCrmDataValue,
  getCrmHeaderFields,
  getCrmMetrics,
  getCrmFunnels,
};
