import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * A custom React hook for parsing query parameters from the current URL.
 *
 * @returns {URLSearchParams} - An instance of URLSearchParams containing the parsed query parameters.
 * @example
 * // Usage in a functional component:
 * if url is localhost:3000/?abc=123&nm=23
 * const MyComponent = () => {
 *   const queryParams = useQuery();
 *
 *   // Access individual query parameters
 *   const abcParam = queryParams.get('abc'); // "123"
 *   const nmParam = queryParams.get('nm');   // "23"
 * };
 */
export default function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
