import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getMortgage } from "services/api";
import { MortgageRes } from "shared/types/BaseApiResponse";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { Bid } from "shared/types/Bid";

const useMortgage = (id: string | undefined) => {
  const [mortgage, setMortgage] = useState<IMortgageSchema | MortgageRes>();
  const [acceptedBid, setAcceptedBid] = useState<Bid>();
  const [fetching, setFetching] = useState(true);

  const fetchMortgage = (id: string) => {
    setFetching(true);
    getMortgage(id)
      .then((res) => {
        console.log("Res", res);
        const mrtg = res.data.results;

        setMortgage(mrtg);
        // Get accepted bid
        const bids = [...mrtg.bids, ...mrtg.bids_bank, ...mrtg.bids_broker];
        const ab = bids.find((b) =>
          b?.accepted?.some((a) => a.solicitor_accepted)
        );
        setAcceptedBid(ab);
      })
      .catch((e) => {
        console.error({ e });
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      })
      .finally(() => setFetching(false));
  };

  useEffect(() => {
    if (id) {
      fetchMortgage(id);
    }
  }, [id]);

  const getBids = (bidType?: "banks" | "brokers") => {
    if (!mortgage) return [];
    if (bidType === "banks") {
      return mortgage.bids_bank;
    }
    if (bidType === "brokers") {
      return mortgage.bids_broker;
    }
    const allBids = [
      ...mortgage.bids,
      ...mortgage.bids_bank,
      ...mortgage.bids_broker,
    ];
    return allBids;
  };

  const getUser = (user?: 1 | 2) => {
    if (!user || user === 1) return mortgage?.mortgage_data.user;
    return mortgage?.mortgage_data.user_2;
  };

  return {
    mortgage,
    getBids,
    getUser,
    fetching,
    fetchMortgage,
    acceptedBid,
  };
};

export default useMortgage;
