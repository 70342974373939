import {
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { CgFileDocument } from "react-icons/cg";
import { IconBaseProps } from "react-icons/lib";
import { Link as RouterLink } from "react-router-dom";
import { DIALOGS } from "shared/constants/localStorage";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { DocType } from "shared/types/DocType";
import { PrincipalPanelState } from "../usePPReducer";
import { PPStepperData } from "./PPSteppers";
import { OMITS_STEPS } from "shared/constants/general";
import TasadoraComponent from "./TasadoraComponent";
import { NumberBox } from "pages/RealState/Components";
import styles from "../components/TasadoraComponent/styles.module.scss";
import PPBidFileSection from "./PPBidFileSection";
import { OnBidSelectedDialog } from "../";
import { useParams } from "react-router-dom";
/**
 * This component only care for the active step ,completed step and steps, with that it will show render base on that info
 *
 * mortage.evaltion = bid.step.
 */
function EvalSection({
  activeStep,
  steps,
  completedStep,
  mortgage,
  id,
}: {
  activeStep: PrincipalPanelState["activeStep"];
  steps: PrincipalPanelState["steps"];
  completedStep: PrincipalPanelState["completedStep"];
  mortgage: MortgageRes;
  id?: string;
}) {
  if (!steps) return null;

  //todo what happen when crm esta hasta viable pero no a subido notaencargo se debe mostrar la pantalla de documento
  const activeStepDetails = steps[activeStep];

  if (!activeStepDetails.step) return null;
  const isStepCompleted = completedStep[activeStep];
  let evalData: (typeof evalSectionData)[string];

  const isBidDetails = id && mortgage.bids.some((b) => b.id === id);
  const steppp = mortgage?.crm_last_bidder_evaluation?.step;

  if (
    (!mortgage?.crm_last_bidder_evaluation?.step ||
      OMITS_STEPS.includes(steppp || "")) &&
    !isBidDetails
  ) {
    //Note: this will now show if the stepper is set to default stepper length without substracting 1, in the reducer
    evalData = evalSectionData["isContactadoOrViable"];
  } else {
    evalData = evalSectionData[activeStepDetails.step.name];
  }

  const urlFile = mortgage.files.find(
    (f) => f.file_descriptor === evalData.docTypeCode
  )?.file_url;

  if (activeStepDetails.step.name === "notaEncargoFirmada" && isBidDetails) {
    let bidID = mortgage.bids.filter((b) => b.id === id)[0];
    evalData.docTypeCode = bidID.bidder.CIF_ICI_company
      ? bidID.bidder.order_note_code
      : "Doc_nota_encargo_firmada";
  }

  const evalDataText = isStepCompleted ? evalData.fullfilled : evalData.pending;
  const TitleIcon = PPStepperData[activeStepDetails.step.name]?.icon;

  return (
    <Stack
      component="section"
      id={`eval-section-${activeStepDetails.step.label}`}
      alignItems="center"
      gap={2}
    >
      <Stack
        direction="row"
        id="title"
        alignSelf="flex-start"
        alignItems="center"
        gap={2}
      >
        <TitleIcon size={40} />
        <Typography fontSize={25}>{activeStepDetails.step.label}</Typography>
      </Stack>
      <Stack
        id="desc-pending-or-fullfilled"
        sx={{
          ...(evalData?.backgroundColor && {
            backgroundColor: evalData.backgroundColor,
          }),
          padding: "5rem",
        }}
        gap={3}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          {typeof evalDataText === "function"
            ? evalDataText(urlFile, mortgage)
            : evalDataText}
        </Stack>
        {evalData?.footer}
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        {activeStepDetails.step.name !== "tasadoraRealizada" && (
          <>
            <img
              src="https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcolibid_logo_bird.svg"
              width={40}
              height={40}
            />
            <Typography>
              ¡Ten activas las notificaciones así no te pierdes los avances!
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
}

type EvalStatusComponent =
  | ReactNode
  | ((url?: string, mortgage?: MortgageRes) => ReactNode);

const evalSectionData: Record<
  string,
  {
    pending: EvalStatusComponent;
    fullfilled: EvalStatusComponent;
    footer?: ReactNode;
    backgroundColor?: string;
    docTypeCode?: DocType["code"];
  }
> = {
  /**
  this field doesnt exist on the mortgage.crm_last_bidder_step, is just here to show the transition 
  from contactado, viable, to nota de encargo
   */
  Documentos: {
    pending: (
      <EvalWithHighligthText
        head="El bróker está preparando tu Nota de Encargo.  La tendrá lista en los próximos días y te la enviará vía email. ¡No olvides verificar tu casilla!"
        mid="Nota de Encargo!"
        end="Revisa tu correo electronico para firmar"
        showIcon
      />
    ),
    fullfilled: null,
  },
  isContactadoOrViable: {
    pending: (
      <EvalWithHighligthText
        head="El bróker está preparando tu Nota de Encargo.  La tendrá lista en los próximos días y te la enviará vía email. ¡No olvides verificar tu casilla!"
        mid="Nota de Encargo!"
        end="Revisa tu correo electronico para firmar"
        showIcon
      />
    ),
    fullfilled: null,
  },
  notaEncargoFirmada: {
    backgroundColor: "#F5F5F5B2",
    docTypeCode: "Doc_nota_encargo_firmada",
    pending: (
      <EvalWithHighligthText
        head="¡Parece que el bróker te ha dejado lista para firmar la"
        mid="Nota de Encargo!"
        end="Revisa tu correo electronico para firmar"
        showIcon
      />
    ),
    fullfilled: (url) => (
      <EvalWithHighligthText
        head="Puedes consultar"
        mid={<Link href={url}>aquí la Nota de Encargo</Link>}
        end="firmada por ambas partes"
        showIcon
        iconProps={{ color: "#D8D7F5" }}
      />
    ),
    footer: (
      <EvalWithHighligthText
        head="La"
        mid="Nota de Encargo"
        end="es el documento donde el bróker se compromete contigo a negociar tu
        hipoteca y tú a pagar sus honorarios (si hubiera) contra firma de FEIN.
        De no cerrar la hipoteca y firmar el FEIN, y de no aclararse en esta
        Nota de Encargo, no deberás pagar los honorarios del bróker."
      />
    ),
  },
  aprobacionBancaria: {
    pending: (
      <EvalWithHighligthText
        head="¡El banco está analizando el 
        caso en profundidad! Validará el"
        mid="perfil financiero y los datos de la vivienda"
        end="para poder avanzar con la tasación. Será rápido y te avisaremos este paso se haya completado. No necesitamos más información ni documentación para este paso 😉"
      />
    ),
    fullfilled: (
      <EvalWithHighligthText
        head="El banco ya ha validado el"
        mid="perfil financiero y los datos de la vivienda."
      />
    ),
  },
  tasadoraRealizada: {
    docTypeCode: "Doc_tasadora",
    pending: (url, mortgage) => (
      <Container>
        <TasadoraComponent mortgage={mortgage} step={1} variant="" />
      </Container>
    ),
    fullfilled: (url, mortgage) => (
      <Container>
        <TasadoraComponent mortgage={mortgage} step={6} url={url} variant="" />
      </Container>
    ),
  },
  operacionRiesgos: {
    pending: (
      <EvalWithHighligthText
        head="El banco está evaluando toda tu información para la confirmación definitiva.
    ¡Te avisaremos cuando esté resuelto!"
      />
    ),
    fullfilled: (
      <EvalWithHighligthText
        head="¡El banco ha confirmado tu hipoteca!"
        nextText={
          <Typography>
            Esto significa que el departamento de riesgos ha dado su visto
            bueno.
          </Typography>
        }
      />
    ),
  },
  FEIN: {
    docTypeCode: "Doc_fein",
    pending: (
      <EvalWithHighligthText
        head=""
        mid=""
        end={
          <Typography style={{ fontFamily: "Roboto", fontSize: "1rem" }}>
            La{" "}
            <span style={{ color: "#01807A", fontWeight: "bold" }}>FEIN</span>{" "}
            es un documento que no tiene caracter vinculante para tí, pero sí
            para el banco.
            <br />
            <br />
            Una vez el banco haya emitido este documento, lo deberás{" "}
            <span style={{ fontWeight: "bold" }}>
              firmar digitalmente aquí.
            </span>
            <br />
            <br />
            Por favor ten en cuenta que al firmar la FEIN de una de las ofertas,
            si tienes otras ofertas activas se cancelarán automáticamente. Esto
            es porque es el último paso antes de cerrar la hipoteca.
          </Typography>
        }
        showIcon
        iconProps={{ color: "#D9F6EF" }}
      />
    ),
    fullfilled: (url) => (
      <EvalWithHighligthText
        head="El"
        mid="FEIN"
        end={
          <>
            es un documento que no tiene caracter vinculante para tí, pero sí
            para el banco. Una vez el banco haya emitido este documento,{" "}
            <Link href={url}>lo veras aquí.</Link>
          </>
        }
        showIcon
        iconProps={{ color: "#D9F6EF" }}
      />
    ),
  },
  altaSistema: {
    pending: (
      <EvalWithHighligthText
        head="¡El banco está analizando el caso en profundidad! 
    Validará el"
        mid="perfil financiero y los datos de la vivienda"
        end="para poder avanzar con la tasación. 
    Será rápido y te avisaremos cuando este paso se haya completado. 
    No necesitamos más información ni documentación para este paso 😉"
      />
    ),
    fullfilled: (
      <EvalWithHighligthText
        head="¡El banco ya ha validado el"
        mid="perfil financiero y los datos de la vivienda"
        end="!"
      />
    ),
  },
  fechaNotaria: {
    pending: (
      <EvalWithHighligthText
        head="¡Es la última etapa del proceso!
    Debes"
        mid="acudir físicamente"
        end="a tu notaría para la lectura de la FEIN y su firma.
    La casa de tus sueños ya es casi tuya 🙂"
      />
    ),
    fullfilled: (
      <EvalWithHighligthText
        head="Ahora sí, ¡Tu casa es tuya! ¡Y tu hipoteca, también!"
        mid="Felicidades."
        end="Desde Colibid queremos agradecerte que hayas confiado en nosotros para ayudarte a conseguir las mejores condiciones. Pero no hemos acabado aún, queremos seguir haciendo cosas por tí. Te regalamos 100€ por recomendarnos a quién tú quieras, solo tienes que entrar a tu panel de referidos."
      />
    ),
  },
  cerrado: {
    pending: (
      <EvalWithHighligthText
        head=""
        mid="¡ENHORABUENA!"
        end="un ultimo paso mas a esprerar que el banco cierre este proceso"
      />
    ),
    fullfilled: (
      <Stack gap={1}>
        <Typography variant="h6" color="primary">
          ¡Enhorabuena!
        </Typography>
        <Typography>
          Puedes a{" "}
          <Link
            component={RouterLink}
            to="/client/mortgages"
            onClick={() => {
              localStorage.setItem(DIALOGS.BID_PAID_DIALOG_SEEN, "true");
            }}
          >
            &nbsp;ir a mi solictud&nbsp;
          </Link>{" "}
          y revisar tu hipoteca!
        </Typography>
      </Stack>
    ),
  },
};

/**
 * This is the way, the Dry way
 */
export function EvalWithHighligthText({
  head,
  mid,
  end,
  nextText,
  showIcon,
  iconProps,
}: {
  /**the initial text before highlight*/
  head: ReactNode;
  /**the highligth text*/
  mid?: ReactNode;
  /**the end text after highlight*/
  end?: ReactNode;
  nextText?: ReactNode;
  showIcon?: boolean;
  iconProps?: IconBaseProps;
}) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Stack>
        <Typography textAlign="center">
          {head}
          {mid && (
            <Typography component="span" color="primary">
              &nbsp;{mid}&nbsp;
            </Typography>
          )}
          {end}
        </Typography>
        {nextText}
      </Stack>
      {showIcon && (
        <CgFileDocument
          size="40px"
          style={{ minWidth: "fit-content" }}
          {...iconProps}
        />
      )}
    </Stack>
  );
}

export default EvalSection;
