import { WSAPI } from "shared/constants/links";
import styles from "./styles.module.scss";

const WaNumber = process.env.REACT_APP_WHATSAPP_NUMBER_CUSTOMERS;
const MAIL = process.env.REACT_APP_COLIBID_EMAIL;

type Props = {whatsapp?:boolean, email?:boolean}
export default function LinkText({whatsapp, email}: Props) {
  if(whatsapp && email){
    throw new Error('can only use one, is either whatsapp props or email') 
  }
  const href = whatsapp ? `${WSAPI}${WaNumber}` : `mailto:${MAIL}`
  const text = whatsapp ? "Whatsapp" : MAIL 
  return (
    <a
      className={styles.links}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
}
