import { Container, Stack } from "@mui/material";
import { format } from "date-fns";
import {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MortgageDetailCards, PageTitle } from "shared/components";
import {
  useAuthenticated,
  useLocalStorage,
  useProfile,
} from "shared/hooks";
import useMortgage from "shared/hooks/useMortgage";
import { MainClientLayout } from "shared/layouts";
import { sendTagEvent } from "../../../utils/analytics";
import MDSkeleton from "./components/MDSkeleton";
import styles from "./styles.module.scss";
import { navItems } from "shared/constants/navigations";


const MortgageDetails: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [role] = useLocalStorage("role");
    const profile = useProfile().profile;

    const { mortgage, fetching, acceptedBid, getBids } = useMortgage(id);

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "client");

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    const titleInfo = navItems.myMortgage 
    return (
      <MainClientLayout>
        <>
          <Container className={styles.MortgageDetailsWrapper}>
            {fetching && <MDSkeleton />}
            <Stack gap={2}>
              {titleInfo && (
                <PageTitle.WithIcon iconUrl={titleInfo.iconUrl} label={titleInfo.label}/>
              )}
              <MortgageDetailCards
                mortgage={mortgage}
                acceptedBid={acceptedBid}
              />
            </Stack>
          </Container>
        </>
      </MainClientLayout>
    );
  };

export default MortgageDetails;
