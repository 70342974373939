import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";
import { TabPanel } from "../";

import {
  Box,
  CardContent,
  Card,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import GavelIcon from "@mui/icons-material/Gavel";
import { parseISO, format } from "date-fns";
import { getMortgageAcceptedBid, getBroker } from "../../../services/api";
import { getMonthlyComission } from "../../../utils/helpers";
import styles from "./styles.module.scss";
import { NotResults, MixTypeInterest } from "../../../shared/components";
import { formatCurrency } from "../../../utils/helpers";
import { useLocalStorage, useMortgageBidAccepted } from "../../../shared/hooks";
import { toast } from "react-toastify";
import { TableRows } from "../InfoCard";
import {
  InformationGeneralInfoCard,
  HipotecaInfoCard,
  PropertyInfoCard,
  FamilyEconomyInfoCard,
} from "../InfoCard/mortgageCards";
import { getComissionValue, getNetFees } from "utils/bids";
import { object } from "yup";
import BidResumeSkeleton from "./BidResumeSkeleton";
import { MortgageRes } from "shared/types/BaseApiResponse";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { Bid } from "shared/types/Bid";

export interface IBidResumeProps {
  data: MortgageRes | IMortgageSchema;
  mode: string;
  option: string;
  bid?: Bid;
}
const BidResume: FunctionComponent<IBidResumeProps> = (
  props
): ReactElement<any> => {
  const [tab, setTab] = useState(0);
  const [details, setDetails] = useState<any>(null);
  const [option, setOption] = useState(props.option);
  const [mode, setMode] = useState(props.mode);
  const [accepted, setAccepted] = useState<Bid>();
  const [broker, setBroker] = useState<any>([]);
  const [role] = useLocalStorage("role");

  const handleChangeTab = (value: any) => {
    if (value === 0) {
      setOption(props.option);
    } else {
      setOption("general_data");
    }

    setTab(value);
  };

  useEffect(() => {
    setDetails(props.data);
    setMode(props.mode);
    setOption(props.option);

    if (props.bid) {      
      setAccepted(props.bid);
      getBrokerData(props.bid.bidder.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const ifIsActiveGetVariant = (
    element: string
  ): "filled" | "outlined" | undefined => {
    if (element === option) {
      return "filled";
    }
    return "outlined";
  };

  const ifIsActiveGetColor = (element: string): "primary" | undefined => {
    if (element === option) {
      return "primary";
    }

    return undefined;
  };

  /*   const getAcceptedBid = (id: string) => {
    getMortgageAcceptedBid(id)
      .then((response: any) => {
        setAccepted(response.data);
        getBrokerData(response.data.bidder.user);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  }; */

  const getBrokerData = (username: string) => {
    getBroker(username)
      .then((response: any) => {
        setBroker(response.data);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  };

  // no tenemos la fecha de aceptacion de la oferta  del cliente

  return (
    <Card sx={{ marginTop: ".7rem" }}>
      {accepted === null ? (
        <BidResumeSkeleton />
      ) : (
        <CardContent>
          {accepted?.general_fees?.finance_fees === true && (
            <Chip
              sx={{ marginTop: ".2rem", float: "right" }}
              label="Financiada"
              size="small"
              color="primary"
              variant="outlined"
            />
          )}
          <Tabs value={tab} onChange={(e, value) => handleChangeTab(value)}>
            <Tab
              icon={<InfoIcon />}
              label="Datos Generales"
              sx={{
                width: `${100 / 2}%`,
                maxWidth: `${100 / 2}%`,
                fontSize: ".8rem",
              }}
            />
            <Tab
              icon={<GavelIcon />}
              label="Detalles de la oferta"
              sx={{
                width: `${100 / 2}%`,
                maxWidth: `${100 / 2}%`,
                fontSize: ".8rem",
              }}
            />
          </Tabs>

          <TabPanel value={tab} index={0}>
            <>
              <Box className={styles.ChipBox}>
                {mode === "client" && (
                  <Chip
                    label="Entidad Financiera"
                    variant={ifIsActiveGetVariant("financial_entity")}
                    color={ifIsActiveGetColor("financial_entity")}
                    className={styles.Chip}
                    onClick={() => {
                      setOption("financial_entity");
                    }}
                  />
                )}

                {mode === "broker" && (
                  <Chip
                    label="Información del Cliente"
                    variant={ifIsActiveGetVariant("customer_information")}
                    color={ifIsActiveGetColor("customer_information")}
                    className={styles.Chip}
                    onClick={() => {
                      setOption("customer_information");
                    }}
                  />
                )}

                <Chip
                  label="Hipoteca"
                  variant={ifIsActiveGetVariant("mortgage")}
                  color={ifIsActiveGetColor("mortgage")}
                  className={styles.Chip}
                  onClick={() => {
                    setOption("mortgage");
                  }}
                />

                <Chip
                  label="Propiedad"
                  variant={ifIsActiveGetVariant("property")}
                  color={ifIsActiveGetColor("property")}
                  className={styles.Chip}
                  onClick={() => {
                    setOption("property");
                  }}
                />

                <Chip
                  label="Economía Familiar"
                  variant={ifIsActiveGetVariant("family_economy")}
                  color={ifIsActiveGetColor("family_economy")}
                  className={styles.Chip}
                  onClick={() => {
                    setOption("family_economy");
                  }}
                />
              </Box>

              {option === "financial_entity" && (
                <TableRows
                  data={{
                    "Entidad financiera": broker?.company_name,
                    Ciudad: broker?.company_city,
                    Dirección: broker?.company_address,
                    "Código postal": broker?.company_postcode,
                    Teléfono: broker?.company_phone,
                    Email: broker?.company_email,
                  }}
                />
              )}

              {option === "customer_information" && (
                <InformationGeneralInfoCard
                  data={details?.mortgage_data}
                  hasTitle={false}
                />
              )}

              {option === "mortgage" && (
                <HipotecaInfoCard
                  data={details?.mortgage_data}
                  acceptedBid={accepted}
                  hasTitle={false}
                />
              )}

              {option === "property" && (
                <PropertyInfoCard
                  data={details?.mortgage_data}
                  hasTitle={false}
                />
              )}

              {option === "family_economy" && (
                <FamilyEconomyInfoCard
                  data={details?.mortgage_data}
                  hasTitle={false}
                />
              )}
            </>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <>
              <Box className={styles.ChipBox}>
                <Chip
                  label="Información general"
                  variant={ifIsActiveGetVariant("general_data")}
                  color={ifIsActiveGetColor("general_data")}
                  className={styles.Chip}
                  onClick={() => {
                    setOption("general_data");
                  }}
                />

                <Chip
                  label="Lista de Bonificaciones"
                  variant={ifIsActiveGetVariant("bonus_list")}
                  color={ifIsActiveGetColor("bonus_list")}
                  className={styles.Chip}
                  onClick={() => {
                    setOption("bonus_list");
                  }}
                />
              </Box>

              {option === "general_data" && accepted && (
                <Table
                  size="small"
                  sx={{ marginTop: "1rem" }}
                  className={styles.TableWrapper}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" className={styles.Title}>
                        {accepted.bidder?.bidder_type === "bank"
                          ? "Banco"
                          : "Asesor"}
                      </TableCell>
                      <TableCell align="center" className={styles.Content}>
                        {accepted.bidder?.bidder_type === "bank"
                          ? accepted.bid_for?.name
                          : accepted.bidder?.company_name}
                      </TableCell>
                    </TableRow>

                    {(accepted?.interest_data?.mode === "mixed" ||
                      accepted?.interest_data?.mode === "mixto") &&
                    accepted.interest_data.interest ? (
                      <MixTypeInterest
                        data={accepted.interest_data.interest}
                        cellClassTitle={styles.Title}
                        cellClassContent={styles.Content}
                        hideInterest={false}
                      />
                    ) : (
                      <TableRow>
                        <TableCell align="center" className={styles.Title}>
                          Tasa de Interés
                        </TableCell>
                        <TableCell align="center" className={styles.Content}>
                          {accepted?.interest_rate}%
                        </TableCell>
                      </TableRow>
                    )}
                    {accepted?.bidder?.bidder_type !== "bank" && (
                      <React.Fragment>
                        <TableRow>
                          <TableCell align="center" className={styles.Title}>
                            Honorarios totales
                          </TableCell>
                          <TableCell align="center" className={styles.Content}>
                            {formatCurrency(
                              getComissionValue(accepted?.general_fees)
                            )}
                          </TableCell>
                        </TableRow>
                        {accepted?.bidder?.bidder_type === "broker" &&
                          role === "broker" && (
                            <React.Fragment>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={styles.Title}
                                >
                                  Comisiones Colibid
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={styles.Content}
                                >
                                  {accepted?.general_fees?.values?.colibid_fees > 0
                                    ? `- ${formatCurrency(
                                        accepted?.general_fees?.values?.colibid_fees
                                      )}`
                                    : formatCurrency(0)}
                                </TableCell>
                              </TableRow>
                              {accepted?.general_fees?.finance_fees === true ? (
                                <React.Fragment>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      className={styles.Title}
                                    >
                                      Descuento por financiación
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={styles.Content}
                                    >
                                      -
                                      {accepted?.general_fees
                                        ?.financing_discount
                                        ? accepted?.general_fees
                                            ?.financing_discount
                                        : accepted?.general_fees
                                            ?.consulting_fees * 0.15}
                                      €
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      className={styles.Title}
                                    >
                                      Honorarios Netos
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        color: "#018075",
                                      }}
                                      className={styles.Content}
                                    >
                                      {formatCurrency(
                                        parseFloat(
                                          accepted?.general_fees?.net_fees.toString()
                                        ).toFixed(0)
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ) : (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    className={styles.Title}
                                  >
                                    Honorarios netos
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#018075",
                                    }}
                                    className={styles.Content}
                                  >
                                    {formatCurrency(
                                      getNetFees(accepted?.general_fees)
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </React.Fragment>
                          )}
                        {role === "client" &&
                          accepted?.general_fees?.finance_fees === true && (
                            <TableRow hover>
                              <TableCell
                                align="center"
                                className={styles.Title}
                              >
                                Cuota mensual de la financiación
                              </TableCell>
                              <TableCell
                                align="center"
                                className={styles.Content}
                              >
                                {getMonthlyComission(
                                  accepted?.general_fees?.consulting_fees,
                                  6
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                      </React.Fragment>
                    )}

                    <TableRow>
                      <TableCell align="center" className={styles.Title}>
                        Publicación de la oferta
                      </TableCell>
                      <TableCell align="center" className={styles.Content}>
                        {format(
                          parseISO(accepted.created.toString()),
                          "dd-MM-yyyy / HH:mm:ss"
                        )}
                      </TableCell>
                    </TableRow>

                    {accepted.is_accepted && (
                      <React.Fragment>
                        <TableRow>
                          <TableCell align="center" className={styles.Title}>
                            Fecha de aceptación por cliente
                          </TableCell>
                          <TableCell align="center" className={styles.Content}>
                            {accepted.agreement.solicitor_accepted !== null
                              ? format(
                                  parseISO(
                                    accepted.agreement.solicitor_accepted.toString()
                                  ),
                                  "dd-MM-yyyy / HH:mm:ss"
                                )
                              : ""}
                          </TableCell>
                        </TableRow>
                        {accepted.agreement.bidder_accepted !== null && (
                          <TableRow>
                            <TableCell align="center" className={styles.Title}>
                              Fecha de aceptación por ofertante
                            </TableCell>
                            <TableCell
                              align="center"
                              className={styles.Content}
                            >
                              {accepted.agreement.bidder_accepted !== null
                                ? format(
                                    parseISO(accepted.created.toString()),
                                    "dd-MM-yyyy / HH:mm:ss"
                                  )
                                : ""}
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table>
              )}

              {option === "bonus_list" &&
                accepted &&
                accepted.conditions_details.length > 0 && (
                  <Table
                    size="small"
                    sx={{ marginTop: "1rem" }}
                    className={styles.TableWrapper}
                  >
                    <TableBody>
                      {accepted.conditions_details
                        ?.filter((bonus: any) => {
                          return bonus.allow === true;
                        })
                        .map((c: any, i: number) => {
                          return (
                            <TableRow>
                              <TableCell align="left" className={styles.Title}>
                                {c.bonificationName}
                              </TableCell>
                              <TableCell align="center">
                                {c.interest_rate}%
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                )}

              {option === "bonus_list" &&
                accepted &&
                (accepted.conditions_details?.filter((bonus: any) => {
                  return bonus.allow === true;
                }).length <= 0 ||
                  !accepted?.conditions_details) && (
                  <NotResults
                    title="Sin Bonificaciones"
                    caption="No se han registrado bonificaciones"
                  />
                )}
            </>
          </TabPanel>
        </CardContent>
      )}
    </Card>
  );
};

export default BidResume;
