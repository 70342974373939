export const TOTAL_INCOME_PERCENTAGE_ALLOWED = 0.35;

export const DROPZONE_ACCEPTED_FILE = {
  "image/*": [".heic", ".heif"],
  "application/pdf": [],
};

export const INITIAL_STEP = "step";
export const OMITS_STEPS = ["contactado", "viable"];

export const REQUIRE_BIDDINGS_ACTIVATION = "require_biddings_activation";

export const CLOSED_EXPIRATION_DAYS = 3;

export const AGENCY_FIRST_INITAL_SESSION = "agency_accept_modal";
export const DEFAULT_USER_BIDDING_MODAL = "max_user_default_modal";

export const GENERAL_ERROR_RES =
  "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔";

