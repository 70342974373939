import { useEffect, useState } from "react";

import {
  ButtonProps,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getBiddings } from "services/api";
import { reactivateBidding } from "services/api/biddings.service";
import dataStorageService from "services/api/dataStorage.service";
import { REQUIRE_BIDDINGS_ACTIVATION } from "shared/constants/general";
import { useDialog } from "shared/hooks";
import { Biddings, ReactivateBidding } from "shared/types/Bid";
import { RequireBiddingsActivation } from "shared/types/Enums";
import CustomDialog from "../CustomDialog";
import { format, parseISO } from "date-fns";
import { isStrADate } from "utils";
import { toast } from "react-toastify";

type Props = {};

export default function AutoBiddingDialog({}: Props) {
  const { isShowing, closeDialog, openDialog } = useDialog();
  const [biddings, setBiddings] = useState<Biddings[]>();

  useEffect(() => {
    if (!biddings) {
      dataStorageService
        .getData(REQUIRE_BIDDINGS_ACTIVATION)
        .then((res) => {
          const isRequired =
            res.data.results.payload === RequireBiddingsActivation.REQUIRED;
          if (isRequired) {
            getBiddings().then((bidRes) => {
              if (bidRes && bidRes.data.results.length > 0) {
                setBiddings(bidRes.data.results);
                openDialog();
              }
            });
          }
        })
        .catch((err) => {
          console.error({ err });
        });
    }
  }, []);

  const btnList: ButtonProps[] = [
    {
      title: "Lo haré manualmente",
      onClick: () => {
        dataStorageService
          .setData({
            key: REQUIRE_BIDDINGS_ACTIVATION,
            payload: RequireBiddingsActivation.POSTPONED,
          })
          .then((res) => {
            if (res.status === 200) {
              closeDialog();
            }
          })
          .catch((err) => console.log({ err }));
      },
    },
    {
      title: "Confirmar activación",
      onClick: () => {
        if (biddings) {
          const bidsArr: ReactivateBidding[] = biddings?.map((b) => ({
            bidding_id: b.id,
            is_active: b.is_active,
          }));
          reactivateBidding({ biddings: bidsArr })
            .then((res) => {
              toast.success('Estados de pujas automaticas actualizada 👏')
              closeDialog()
            })
            .catch((err) => console.log({ err }));
        }
      },
    },
  ];
  return (
    <CustomDialog
      open={isShowing}
      // onClose={(e: SyntheticEventData) => {
      //   closeDialog();
      // }}
      btnList={btnList}
      title='Active o desactive sus pujas automáticas'
    >
      {biddings && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Título</TableCell>
                <TableCell align="center">Límite diario</TableCell>
                <TableCell align="center">Fecha creación</TableCell>
                <TableCell align="center">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {biddings.map((b, idx, bids) => {
                return (
                  <TableRow key={b.id}>
                    <TableCell align="center">{b.title}</TableCell>
                    <TableCell align="center">{b.max_bids}</TableCell>
                    <TableCell align="center">
                      {isStrADate(b.created_at) &&
                        format(parseISO(b.created_at), "dd/MM/yyyy HH:mm:ss")}
                    </TableCell>
                    {/* <TableCell align="center">
                      <Chip
                        sx={{minWidth:'80px'}}
                        label={b.is_active ? "Activo" : "Inactivo"}
                        color={b.is_active ? "success" : "error"}
                        variant="outlined"
                      />
                    </TableCell> */}
                    <TableCell>
                      <Switch
                        checked={bids[idx].is_active}
                        onChange={(e) => {
                          const { checked } = e.target;
                          setBiddings((prev) =>
                            prev?.map((e, i, pArr) => {
                              if (i === idx) {
                                const newBid = { ...e };
                                newBid.is_active = checked;
                                return newBid;
                              }
                              return e;
                            })
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </CustomDialog>
  );
}
