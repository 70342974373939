import {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Container,
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Box,
  Chip,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { MainBrokerLayout } from "../../../shared/layouts";
import {
  PageTitle,
  Timer,
  PaginationBar,
  TabPanel,
  NotResults,
  ConfirmAccept,
  BidDetails,
  TableSkeleton,
  MetricPlans,
} from "../../../shared/components";

import { getBids, brokerAcceptBid } from "../../../services/api";
import { getApplicationType, formatCurrency } from "../../../utils/helpers";
import { toast } from "react-toastify";
import {
  useAuthenticated,
  useLocalStorage,
  useLoading,
  useProfile,
  useLocalStorages,
} from "../../../shared/hooks";
import IMortgageSchema from "../../../shared/schemas/mortgage.schema";
import { format } from "date-fns";
import { sendTagEvent } from "utils/analytics";
import styles from "./styles.module.scss";
import { getNetFees } from "utils/bids";
import { Bid } from "shared/types/Bid";
import { isBrokerProfile } from "utils/profile";

const Bids: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role] = useLocalStorage("role");
  const [email] = useLocalStorage("email");
  const [tabValue, setTabValue] = useLocalStorages("bidTab", "0");
  const [bids, setBids] = useState([]);
  const [bid, setBid] = useState<any | null>(null);
  const [mortgage, setMortgage] = useState<Partial<IMortgageSchema>>({});
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [readyAccept, setReadyAccept] = useState(false);
  const [acceptedCount, setAcceptedCount] = useState<number>(0);
  const [bidPaging, setBidPaging] = useLocalStorages(
    "bid_application_pagination",
    {
      page: 1,
      pages: 25,
      count: 1,
      limit: 25,
    }
  );
  const isLoading = useLoading();
  const profile = useProfile().profile;

  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["broker", "bank"]
  );

  useEffect(() => {
    handleChangeTab(parseInt(tabValue));
  }, []);

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  const getAll = (filters: string, page: number, limit: number) => {
    getBids(filters, page, limit)
      .then((response: any) => {
        isLoading.setValue(false);
        setBids(response.data.results);
        bidPaging.page = parseInt(response.data.page);
        bidPaging.pages = parseInt(response.data.pages);
        bidPaging.limit = parseInt(response.data.items_per_page);
        bidPaging.count = parseInt(response.data.mortgages);

        setBidPaging({ ...bidPaging });
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const handleChangeTab = (value: any) => {
    isLoading.setValue(true);
    setBids([]);
    setTabValue(value);

    if (value === 0) {
      getAll("mode=bid", bidPaging.page, bidPaging.limit);
    }

    if (value === 1) {
      getAll("mode=closed", bidPaging.page, bidPaging.limit);
    }
  };

  const confirmAction = (bid: any) => {
    setShowDialogConfirm(true);
    setBid(bid);
  };

  const getAcceptedBid = (bids: any) => {
    let acceptedBid;

    bids.forEach((b: any, i: number) => {
      if (
        b?.accepted[0]?.solicitor_accepted &&
        (!b?.accepted[0]?.bidder_accepted ||
          b?.accepted[0]?.bidder_accepted === "")
      ) {
        acceptedBid = b;
      }
    });

    return acceptedBid;
  };

  const accept = (b: any) => {
    brokerAcceptBid(mortgage.id, b)
      .then(() => {
        toast.success(
          "Puja confirmada correctamente y enhorabuena por tu nuevo cliente 🥳, te recomendamos ponerte en contacto lo antes posible para finalizar los últimos detalles 😄"
        );
        getAll("&mode=accepted", bidPaging.page, bidPaging.limit);
        navigate(`/broker/clients/${mortgage.id}`);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const netValue = (m: IMortgageSchema) => {
    const filteredBids = m.bids.filter((b: any) => b.bidder.user === email);
    const isAuto = filteredBids.some((b: any) => b.is_auto === true);

    const minNetFees = Math.min(
      ...filteredBids
        .filter((b: any) => b.is_auto === isAuto)
        .map((obj: Bid) => getNetFees(obj.general_fees))
    );

    return `${formatCurrency(minNetFees)}`;
  };

  return (
    <MainBrokerLayout>
      <Container className={styles.BidsWrapper}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Mis Pujas" />
            <Typography variant="subtitle1" style={{ marginTop: "1rem" }}>
              Subastas
            </Typography>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <MetricPlans />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <Grid item md={12} sm={12} xs={12}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={parseInt(tabValue)}
                    onChange={(e, value) => handleChangeTab(value)}
                  >
                    <Tab label="Activas" />

                    <Tab label="Finalizadas" />
                  </Tabs>
                </Box>
              </Box>

              <TabPanel value={parseInt(tabValue)} index={1}>
                {(() => {
                  if (bids.length > 0 && !isLoading.loading) {
                    return (
                      <TableContainer sx={{ maxHeight: 650 }} component={Paper}>
                        <Table stickyHeader sx={{ width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                Tipo de hipoteca
                              </TableCell>
                              <TableCell align="center">
                                Vivienda habitual
                              </TableCell>
                              <TableCell align="center">
                                Valor de propiedad
                              </TableCell>
                              <TableCell align="center">
                                Importe de hipoteca
                              </TableCell>
                              {profile && isBrokerProfile(profile) && (
                                <TableCell align="center">
                                  Honorarios Netos(broker)
                                </TableCell>
                              )}

                              <TableCell align="center">
                                Fin de la subasta
                              </TableCell>
                              <TableCell align="center">
                                Pujas recibidas
                              </TableCell>
                              <TableCell align="center">
                                Mis Pujas
                                <Chip
                                  label="Manuales"
                                  size="small"
                                  color="warning"
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell align="center">
                                Mis Pujas{" "}
                                <Chip
                                  label="Automáticas"
                                  size="small"
                                  color="info"
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bids.map((m: IMortgageSchema, i: number) => {
                              return (
                                <TableRow
                                  hover
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      `/broker/applications/${m.id}`,
                                      "_blank"
                                    );
                                  }}
                                  key={i || 0}
                                >
                                  <TableCell align="center">
                                    {getApplicationType(
                                      m.mortgage_data.mortgage.mode
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {Number(m?.mortgage_data?.property.type) ===
                                    1
                                      ? "Sí"
                                      : "No"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatCurrency(
                                      m.mortgage_data.property.value
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatCurrency(
                                      m.mortgage_data.data.amount
                                    )}
                                  </TableCell>

                                  {profile && isBrokerProfile(profile) && (
                                    <TableCell align="center">
                                      {netValue(m)}
                                    </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <Timer date={m?.ends} />
                                  </TableCell>
                                  <TableCell align="center">
                                    {m.bids_count}
                                  </TableCell>
                                  <TableCell align="center">
                                    {
                                      m.bids.filter(
                                        (b: any) =>
                                          b.bidder.user === email &&
                                          b.is_auto === false
                                      ).length
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    {
                                      m.bids.filter(
                                        (b: any) =>
                                          b.bidder.user === email &&
                                          b.is_auto === true
                                      ).length
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    );
                  } else if (bids.length === 0 && !isLoading.loading) {
                    return (
                      <NotResults
                        title="Sin Resultados"
                        caption="No se han encontrado registros"
                      />
                    );
                  }
                })()}

                {isLoading.loading && (
                  <TableSkeleton headColumns={4} bodyRows={4} bodyColumns={4} />
                )}
              </TabPanel>

              <TabPanel value={parseInt(tabValue)} index={0}>
                {(() => {
                  if (bids.length > 0 && !isLoading.loading) {
                    return (
                      <TableContainer sx={{ maxHeight: 650 }} component={Paper}>
                        <Table stickyHeader sx={{ width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                Tipo de hipoteca
                              </TableCell>
                              <TableCell align="center">
                                Vivienda habitual
                              </TableCell>
                              <TableCell align="center">
                                Valor de propiedad
                              </TableCell>
                              <TableCell align="center">
                                Importe de hipoteca
                              </TableCell>
                              {profile && isBrokerProfile(profile) && (
                                <TableCell align="center">
                                  Honorarios Netos(broker)
                                </TableCell>
                              )}
                              <TableCell align="center">
                                Fin de la subasta
                              </TableCell>
                              <TableCell align="center">
                                Pujas recibidas
                              </TableCell>
                              <TableCell align="center">
                                Mis Pujas
                                <Chip
                                  label="Manuales"
                                  size="small"
                                  color="warning"
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell align="center">
                                Mis Pujas
                                <Chip
                                  label="Automáticas"
                                  size="small"
                                  color="info"
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bids.map((m: IMortgageSchema, i: number) => {
                              return (
                                <TableRow
                                  hover
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      `/broker/applications/${m.id}`,
                                      "_blank"
                                    );
                                  }}
                                  key={i || 0}
                                >
                                  <TableCell align="center">
                                    {getApplicationType(
                                      m.mortgage_data.mortgage.mode
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {Number(m?.mortgage_data?.property.type) ===
                                    1
                                      ? "Sí"
                                      : "No"}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatCurrency(
                                      m.mortgage_data.property.value
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {formatCurrency(
                                      m.mortgage_data.data.amount
                                    )}
                                  </TableCell>

                                  {profile && isBrokerProfile(profile) && (
                                    <TableCell align="center">
                                      {netValue(m)}
                                    </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <Timer date={m?.ends} />
                                  </TableCell>
                                  <TableCell align="center">
                                    {m.bids_count}
                                  </TableCell>
                                  <TableCell align="center">
                                    {
                                      m.bids.filter(
                                        (b: any) =>
                                          b.bidder.user === email &&
                                          b.is_auto === false
                                      ).length
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    {
                                      m.bids.filter(
                                        (b: any) =>
                                          b.bidder.user === email &&
                                          b.is_auto === true
                                      ).length
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    );
                  } else if (bids.length === 0 && !isLoading.loading) {
                    return (
                      <NotResults
                        title="Sin Resultados"
                        caption="No se han encontrado registros"
                      />
                    );
                  }
                })()}
                {isLoading.loading && (
                  <TableSkeleton headColumns={4} bodyRows={4} bodyColumns={4} />
                )}
              </TabPanel>
            </Grid>
          </Grid>

          {showDialogConfirm && (
            <ConfirmAccept
              bid={bid.id}
              title="¡Enhorabuena tu oferta tiene buena pinta!"
              caption="Al aceptar se dará por finalizada la subasta con la siguiente oferta
            para el cliente"
              bidData={bid}
              mortgageData={mortgage}
              onConfirm={(b) => {
                accept(b);
                setShowDialogConfirm(false);
                setBid(null);
              }}
              onClose={() => {
                setBid(null);
                setShowDialogConfirm(false);
              }}
            />
          )}

          {showDialog && (
            <BidDetails
              details={bid}
              open={showDialog}
              onClose={() => setShowDialog(false)}
              accept={readyAccept}
              onAccept={(b) => {
                confirmAction(b);
              }}
            />
          )}

          {bids.length !== 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <PaginationBar
                count={bidPaging.count}
                rowsPerPage={bidPaging.limit}
                page={bidPaging.page}
                pages={bidPaging.pages}
                onChange={(p) => {
                  bidPaging.page = p;

                  setBidPaging({ ...bidPaging });
                  handleChangeTab(tabValue);
                }}
                onChangeLimit={(l) => {
                  bidPaging.page = 1;
                  bidPaging.limit = parseInt(l);

                  setBidPaging({ ...bidPaging });
                  handleChangeTab(tabValue);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </MainBrokerLayout>
  );
};

export default Bids;
