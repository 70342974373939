import { Box, Skeleton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";

export default (props: ICellRendererParams & { loadingMessage: string }) => {
  return (
    <Box
      // className="ag-overlay-loading-center"
      style={{ width: "50%", position: "absolute", top: "50%", left: "25%" }}
    >
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
};
