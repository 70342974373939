import React, {
  FunctionComponent,
  ReactElement,
  cloneElement,
  useState,
  ReactNode,
} from "react";

import { Tooltip, Box, Typography, TooltipProps } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoRounded";

import styles from "./styles.module.scss";

interface HelperTextProps extends Omit<TooltipProps, 'title'> {
  text: string;
}

const HelperText: FunctionComponent<HelperTextProps> = (props): ReactElement<any> => {
  if (!props.text) {
    return <div>{props.children}</div>;
  }
  return (
    <Tooltip
      {...props}
      arrow
      enterTouchDelay={0}
      title={<Typography fontSize={14}>{props.text}</Typography>}
    >
      {(() => {
        if (!props.children) {
          return <InfoIcon className={styles.Icon} />;
        } else {
          return props.children;
        }
      })()}
    </Tooltip>
  );
};

export const HelperTextGridWrapper: FunctionComponent<any> = (
  props
): ReactElement<any> => {
  return (
    <Box arrow className={styles.GridWrapper} {...props}>
      {props.children}
    </Box>
  );
};

export const HelperTextFieldWrapper: FunctionComponent<any> = (
  props
): ReactElement<any> => {
  const [showBubble, setShowBubble] = useState(false);

  return (
    <Box className={styles.FieldWrapper}>
      <>
        {cloneElement(props.children, {
          onFocus: () => {
            setShowBubble(true);
          },
          onBlur: () => {
            setShowBubble(false);
          },
        })}
        {showBubble && <Box className={styles.FieldBubble}>{props.text}</Box>}
      </>
    </Box>
  );
};

export default HelperText;
