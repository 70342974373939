import posthog from "posthog-js";
import TagManager from "react-gtm-module";

export interface eventTag {
  event?: string;
  method?: string;
  step_name?: string;
  page_category?: string;
  page_location?: string; // URL
  user_id?: string;
  user_type?: string;
  user_registration_date?: any;
  user_login_status?: string;
  r?: string;
}

export function sendTagEvent({
  event,
  method,
  page_category,
  page_location,
  user_id,
  user_type,
  user_registration_date,
  user_login_status,
}: eventTag) {
  let eventObj = {
    event,
    method,
    page_category,
    page_location,
    user_id,
    user_type,
    user_registration_date,
    user_login_status,
  };
  let eveObj: any = {};
  for (const [key, value] of Object.entries(eventObj)) {
    if (value !== undefined) {
      eveObj[key] = value;
    }
  }
  TagManager.dataLayer({
    dataLayer: eveObj,
  });
}

interface User {
  username: string;
  email: string;
  name: string;
  role: string;
}
/**

This is an exported object named posthogUtils that contains utility functions related to user tracking using the PostHog analytics library.
**/
export const posthogUtils = {
  resetUser: () => {
    try {
      posthog.reset();
    } catch (error) {
      console.error("PostHog reset failed:", error);
    }
  },

  identifyUser: (user: User) => {
    try {
      const { username, email, name, role } = user;

      posthog.identify(email, {
        email,
        name,
        role,
      });
    } catch (error) {
      console.error("PostHog identification failed:", error);
    }
  },

  captureEvent: (eventName: string, properties: object) => {
    try {
      posthog.capture(eventName, properties);
    } catch (error) {
      console.error("PostHog event capture failed:", error);
    }
  },
};
