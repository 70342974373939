import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userProfile } from "services/api";
import { getProfileWithOutInterceptor } from "services/api/auth.service";
import { userPostProfile } from "services/api";
import { BidderRole, ClientProfile, Profile } from "shared/types/Profile";

export interface RealStateAgency {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}
export interface ProfileHook extends ClientProfile {
  dni: string | null;
  id: string;
  bidder_type: BidderRole;
  user: string;
  name: string;
  bank_name?: any;
  company_name: string;
  company_logo: string;
  percentage: number;
  ICI_number: string;
  ICI_company: string;
  CIF_ICI_company: string;
  BOE_code_ICI: string;
  company_address: string;
  company_city: string;
  company_postcode: string;
  company_phone: string;
  company_email: string;
  days: number;
  accept_financed_commission: boolean;
  accapt_financed_commission_signed: Date | boolean;
  financed_commission_percentage: number;
  remaining_bids: number;
  tags: null | string;
  real_estate_agency: null | RealStateAgency;
  signed_contract: boolean;
  enable_auto_bid: boolean;
  autobidding_percentage: number;
  autobidding_min_margin: number;
}

export default function useProfile<P extends Profile>(handleError = true) {
  const [profile, setProfile] = useState<P>();
  const [isFetching, setIsFetching] = useState(false);
  const controller = new AbortController();

  useEffect(() => {
    const setFinally = () => {
      setIsFetching(false);
    };
    const errorHandler = (e: any) => {
      if (e?.message !== "canceled") {
        console.log(e);
        if (e.response && e?.response?.status === 401) {
          console.log(e.response);
        } else {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔, error(upuf)",
          );
        }
      }
    };
    setIsFetching(true);
    if (handleError) {
      userProfile(controller.signal)
        .then((response: any) => {
          setProfile(response.data);
        })
        .catch(errorHandler)
        .finally(setFinally);
    } else {
      getProfileWithOutInterceptor(controller.signal)
        .then((res) => {
          setProfile(res.data);
        })
        .catch(errorHandler)
        .finally(setFinally);
    }
    return () => {
      controller.abort();
    };
  }, []);

  const setValueProfile = (value: P) => {
    userPostProfile(value)
      .then((response: any) => {
        toast.success("Solicitud registrada correctamente");
        setProfile(response.data);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔, error(upsvpupp)",
        );
        console.log(e);
      });
    setProfile(value);
  };

  return { profile, isFetching, setValueProfile };
}
