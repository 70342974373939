import { useContext } from "react";
import { MortgageFormContext } from "../context/MortgageFormProvider";

function useMortgageFormContext() {
  const context = useContext(MortgageFormContext);
  if (context === undefined) {
    throw new Error(
      "useMortgageFormContext debe ser usado en un MortgageFormContext"
    );
  }
  return context;
}

export default useMortgageFormContext