import React, { FunctionComponent, ReactComponentElement } from "react";
import { useLocalStorage } from "../../../shared/hooks";
import { Container, Grid } from "@mui/material";

import {
  MainClientLayout,
  MainRealEstateLayout,
} from "../../../shared/layouts";

import styles from "./styles.module.scss";
import { ImageWithTitle } from "../Components";

const { REACT_APP_SPECIAL_AGENT }: any = process.env;

const Home: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const [email] = useLocalStorage("email");
  const images = [
    {
      title: "Registrar contacto en Colibid",
      imageUrl:
        "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/RegistrarcontactoColibidhome.png",
      targetPath: "/agent/contact",
      color: "#D8D8F6",
    },
    {
      title: "Consultar Prescoring",
      imageUrl:
        "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Prescoringhome.png",
      targetPath: "/agent/scoring",
      color: "#E5F287",
    },
    {
      title: "Realizar solicitud de hipoteca",
      imageUrl:
        "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Solicitarhipotecahome.png",
      targetPath: "/agent/apply",
      color: "#33CCCC",
    },
    {
      title: "Mis Clientes",
      imageUrl:
        "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/misClientesHome.png",
      targetPath: "/agent/clients",
      color: "#01807A",
    },
  ];

  if (email === REACT_APP_SPECIAL_AGENT) {
    images.pop(); // Remove Mis clientes section
  }

  return (
    <MainRealEstateLayout>
      <Container className={styles.HomeWrapper}>
        {email !== REACT_APP_SPECIAL_AGENT ? (
          <Grid container spacing={2} className={styles.fullHeight} marginTop={0}>
            {images.map((image, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <ImageWithTitle
                  title={image.title}
                  imageUrl={image.imageUrl}
                  targetPath={image.targetPath}
                  boxColor={image.color}
                  customStyle={{
                    width: "4rem",
                  }}
                  customStyleTitle={{
                    textAlign: "center",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2} className={styles.fullHeight} marginTop={0}>
            {images.map((image, index) => (
              <Grid key={index} item xs={12} sm={4}>
                <ImageWithTitle
                  title={image.title}
                  imageUrl={image.imageUrl}
                  targetPath={image.targetPath}
                  boxColor={image.color}
                  customStyle={{
                    width: "4rem",
                  }}
                  customStyleTitle={{
                    textAlign: "center",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </MainRealEstateLayout>
  );
};

export default Home;
