import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { MainClientLayout } from "../../../shared/layouts";
import {
  PageTitle,
  MortgageCard,
  NotResults,
} from "../../../shared/components";
import { getMortgages } from "../../../services/api";

import { format, parseISO } from "date-fns";
import { getApplicationType } from "../../../utils/helpers";

import styles from "./styles.module.scss";
import { MortgageRes } from "shared/types/BaseApiResponse";

const ActivesMortgages: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const navigate = useNavigate();
    const [mortgages, setMortgages] = useState([]);

    useEffect(() => {
      getAll({}, 1, 100);
    }, []);

    const getAll = (filter: any, page: number, limit: number) => {
      getMortgages(filter, page, limit)
        .then((response: any) => {
          setMortgages(response.data.results);
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    };

    return (
      <MainClientLayout>
        <>
          <Container className={styles.ActivesMortgagesWrapper}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <PageTitle title="Subastas activas" />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  {mortgages.length === 0 && (
                    <Grid item md={12} sm={12} xs={12}>
                      <NotResults
                        title="Sin Resultados"
                        caption="No se han encontrado registros"
                      />
                    </Grid>
                  )}

                  {mortgages.map((m: MortgageRes, i: number) => {
                    return (
                      <Grid item md={4} sm={12} xs={12} key={i}>
                        {" "}
                        <MortgageCard
                          mortagage={m}
                          documentStatus={m.document_status}
                          status={m.status}
                          date={format(parseISO(m.created), "dd-MM-yyyy")}
                          end={m.ends}
                          id={m.id}
                          title={getApplicationType(
                            m?.mortgage_data.mortgage.mode
                          )}
                          onViewClick={() => {
                            // si tiene el btn view lo mas problable es q es una mortgage activa
                            if (m.status === "paid") {
                              navigate(`/client/mortgage/${m.id}`);
                            } else {
                              navigate(`/client/home`);
                            }
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      </MainClientLayout>
    );
  };

export default ActivesMortgages;
