import { ReactNode } from "react";
import { BodyTextLink } from "./types";

export const imgByStatus: Record<string, string> = {
  active: "settings",
  created: "settings_pending",
  waiting_for_response: "waiting",
  closed_successfully: "completed",
  paid: "completed",
  in_verification: "verification",
  in_verification_accepted: "in_verification_accepted",
  expired: "expired",
  collapsed: "collapsed",
  draft: "statusDraft",
};

export const getClosedImg = (bidLen: number) => {
  if (bidLen === 0) return "closed_no_bids";
  return "closed";
};

export const bodyTextLinkBy: Record<string, BodyTextLink> = {
  in_verification_accepted: {
    text: "¡Enhorabuena! ya es encontrado una oferta prometedora, ahora solo hace falta que verifiquemos tus documentos para continuar el proceso, por favor carga tu documentación.",
  },
  expired: {
    text: "Esta solicitud ha expirado. Si has aceptado una oferta y tienes alguna consulta acerca de la documentación o quieres hablar con uno de nuestros agentes puedes hacerlo",
    link: "https://link.colibid.com/contacto",
    endEmoji: "",
  },
  collapsed: {
    text: "Sentimos mucho que no haya sido tu match perfecto 😔; sin embargo siempre hay segundas oportunidades, puedes reintentar la subasta para buscar tu siguiente match.",
  },
};

export const getClosedBodyTextLink = (bidLen?: number): ReactNode | string => {
  if (bidLen === 0)
    return "Sentimos mucho que nos hayas recibido ofertas 😔, sin embargo siempre hay segundas oportunidades, puedes reintentar tu solicitud y modificarla para obtener mejores resultados.";
  return (
    <>
      La subasta ha terminado y aún puedes elegir una oferta pero ten en cuenta
      que las condiciones no se pueden mantener por mucho tiempo,{" "}
      <b>se expirarán en 3 días</b>.
    </>
  );
};

const documentUploadStatus = [
  "created",
  "active",
  "closed",
  "in_verification_accepted",
  "expired",
];
export const shouldUploadDocuments = ({
  status,
  documentStatus,
}: {
  status?: string;
  documentStatus?: string;
}) => {
  if (!status || !documentStatus) return false;
  return documentUploadStatus.includes(status) && documentStatus === "pending";
};
