import { yupResolver } from "@hookform/resolvers/yup";
import { useFormContext } from "react-hook-form";
import FormFields from "shared/components/FormFields";
import { MortgageFormData } from "shared/types/postMortgageData";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import { EMPLOYMENT_SITUATIONS } from "../../utils";
import HandleSolicitorView from "../HandleSolicitorView";
import StepLayout from "../StepLayout";
import { userSchema } from "../../validation/user";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";

const yupSchema = yup.object({
  user: userSchema.pick(["work"]),
  user_2: isConjuntaValidate(userSchema.pick(["work"])),
});

function UserWorkForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es su situación laboral?"
      resolver={yupResolver(yupSchema)}
    >
      <Form />
    </StepLayout>
  );
}

function Form() {
  const { onNext } = useMortgageFormContext();
  return ( 
    <HandleSolicitorView
      RenderComponent={FormFields.ListSelector}
      individualProps={{
        initialOptionsToShow: 7,
        name: "user.work",
        options: EMPLOYMENT_SITUATIONS.filter((e) =>
          //hide those options 
          ![5, 3, 9].includes(Number(e.id))
        ).map((e) => ({
          key: e.id,
          value: e.id,
          label: e.description,
        })),
        onClick: onNext,
      }}
      conjuntaProps={{
        name: "user_2.work",
        options: EMPLOYMENT_SITUATIONS.map((e) => ({
          key: e.id,
          value: e.id,
          label: e.description,
        })),
      }}
    />
   );
}
export default UserWorkForm;
