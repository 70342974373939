import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
  ReactNode,
} from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  AlertTitle,
  Divider,
  Stack,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/InfoRounded";
import { defaultSteps, StepTimeline, road } from "./utils";
import { HelperText } from "../";
import styles from "./styles.module.scss";

export interface IMortgageStepTimelineProps {
  status: string;
  infoIcon?: boolean;
  steps?: StepTimeline[];
  statusAlert?: boolean;
}
export const MortgageStepTimeline: FunctionComponent<
  IMortgageStepTimelineProps
> = ({ status, infoIcon, steps, statusAlert }): ReactElement<any> => {
  const [currrentStep, setCurrentStep] = useState<number>(-1);
  const [defaultRoad, setDefaultRoad] = useState<any>(() => {
    const dataRoad =
      steps !== undefined
        ? steps.map((step: any) => {
            return step.label;
          })
        : road;

    return dataRoad;
  });
  const [stepsLine, setStepsLine] = useState(() => {
    return steps !== undefined ? steps : defaultSteps;
  });
  const [expandedAccordionStep, setExpandedAccordionStep] = useState(false);

  useEffect(() => {
    const step = defaultRoad.indexOf(status);
    const less = steps ? 0 : 1;
    setCurrentStep(step - less);
  }, [status]);

  const titleStatus = (dataSteps: any) => {
    let stringData = dataSteps.filter((item: any) => item.label === status)[0]
      .alertTitle;
    return <>{stringData}</>;
  };

  return (
    <Box sx={{ width: "100%" }}>
      {statusAlert && steps && status !== "" && (
        <Alert
          color="success"
          sx={{
            margin: "0rem 2rem 2rem 2rem",
            backgroundColor: "#c5f9e4",
          }}
        >
          <AlertTitle
            sx={{ fontSize: { xs: ".70rem", sm: ".70rem", md: "1rem" } }}
          >
            {status ? titleStatus(steps) : ""}{" "}
          </AlertTitle>
        </Alert>
      )}

      {statusAlert && steps && status === "" && (
        <Alert
          color="success"
          sx={{ margin: "0rem 2rem 2rem 2rem", backgroundColor: "#c5f9e4" }}
        >
          <AlertTitle>
            ¡Enhorabuena! Por favor está atento ya que el banco o asesor se
            estará poniendo en contacto contigo pronto.{" "}
          </AlertTitle>
        </Alert>
      )}
      {!isMobile && (
        <>
          <Typography variant="h6" align="center" sx={{ fontWeight: "400" }}>
            Estado del proceso{" "}
          </Typography>
          <Divider component="br" />
          <Divider component="br" />
          <Stepper
            activeStep={currrentStep}
            alternativeLabel={isMobile ? false : true}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {stepsLine.map((e: any, i: number) => (
              <Step key={i}>
                <HelperText text={e.caption}>
                  <StepLabel className={styles.stepLabels}>
                    {infoIcon && !isMobile && (
                      <div className={styles.containerIcon}>
                        <InfoIcon className={styles.infoIcon} />{" "}
                      </div>
                    )}
                    <div className={styles.StepTitle}>
                      <Typography variant="caption">
                        <div
                          className={`${
                            isMobile ? styles.labelMobile : styles.label
                          }`}
                        >
                          {e.label}{" "}
                          {isMobile && infoIcon && <span>{e.caption}</span>}
                        </div>
                      </Typography>
                    </div>
                  </StepLabel>
                </HelperText>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      {isMobile && (
        <Accordion
          key="step_mortgage"
          expanded={expandedAccordionStep === true}
          onChange={() => setExpandedAccordionStep(!expandedAccordionStep)}
          className={styles.AccordionWrapper}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={styles.AccordionTitle}>
              <ArrowRightIcon className={styles.AccordionIconTitle} />
              Estado del proceso
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stepper
              activeStep={currrentStep}
              alternativeLabel={isMobile ? false : true}
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              {stepsLine.map((e: any, i: number) => (
                <Step key={i}>
                  <HelperText text={e.caption}>
                    <StepLabel className={styles.stepLabels}>
                      {infoIcon && !isMobile && (
                        <div className={styles.containerIcon}>
                          <InfoIcon className={styles.infoIcon} />{" "}
                        </div>
                      )}
                      <div className={styles.StepTitle}>
                        <Typography variant="caption">
                          <div
                            className={`${
                              isMobile ? styles.labelMobile : styles.label
                            }`}
                          >
                            {e.label}{" "}
                            {isMobile && infoIcon && <span>{e.caption}</span>}
                          </div>
                        </Typography>
                      </div>
                    </StepLabel>
                  </HelperText>
                </Step>
              ))}
            </Stepper>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export interface IMortgageFullTimelineProps {
  status: string;
}
export const MortgageFullTimeline: FunctionComponent<
  IMortgageFullTimelineProps
> = ({ status }): ReactElement<any> => {
  const [currrentStep, setCurrentStep] = useState<number>(-1);

  useEffect(() => {
    const step = road.indexOf(status);
    setCurrentStep(step - 1);
  }, [status]);

  return (
    <Box sx={{ width: "100%" }}>
      <Timeline position="alternate">
        {defaultSteps.map((e, i: number) => (
          <TimelineItem key={i}>
            <TimelineSeparator>
              {i > 0 && <TimelineConnector />}
              <TimelineDot
                color="primary"
                variant={currrentStep === i ? "filled" : "outlined"}
              >
                {e.icon}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography
                variant="h6"
                component="span"
                className={styles.StepTimelineTitle}
              >
                {e.label}
              </Typography>
              <Typography className={styles.StepTimelineCaption}>
                {e.caption}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Stack alignItems="center">
        <Typography
          variant="h6"
          paddingX={2}
          sx={{ backgroundColor: "#E2E2F8" }}
        >
          ¡Y voilà!
        </Typography>
      </Stack>
    </Box>
  );
};
