import {
  Stack,
  StackProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
} from "@mui/material";
import {
  forwardRef,
  ReactNode, useImperativeHandle, useState
} from "react";
import TabPanel from "../TabPanel";
import "animate.css";
interface TabsOption {
  renderInTabPanel: ReactNode;
  muiTabProps: TabProps;
}

type Props = TabsProps & {
  stackProps?: StackProps;
  tabs: TabsOption[];
  children?: ReactNode;
  initialTabVal?: number;
};

export type TabSectionRef = {
  tabVal: number;
  setTabVal: React.Dispatch<React.SetStateAction<number>>;
};
export const TabSection = forwardRef<TabSectionRef, Props>(function TabSection(
  {
    tabs,
    children,
    stackProps,
    initialTabVal,
    ...tabsProps
  },
  ref,
) {
  const [value, setValue] = useState(initialTabVal ?? 0);
  useImperativeHandle(ref, () => {
    return {
      tabVal: value,
      setTabVal: setValue,
    };
  });
  const handleChange: TabsProps["onChange"] = (e, v) => {
    setValue(v);
  };
  return (
    <Stack id="tab-section" spacing={2} {...stackProps} sx={{
      height:'100%', 
      overflowY: 'hidden'
    }}>
      <Tabs
        {...tabsProps}
        value={value}
        onChange={handleChange}
      >
        {tabs.map((t, idx) => {
          return <Tab {...t.muiTabProps} key={t.muiTabProps.label as string} />;
        })}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel
          key={t.muiTabProps.label as string}
          value={value}
          index={idx}
          style={{
            display: value === idx ? "flex" : "none",
            height: "100%",
            overflowY: "auto",
            marginTop: 0,
          }}
          className={"animate__animated animate__backInUp"}
        >
          {t.renderInTabPanel}
        </TabPanel>
      ))}
    </Stack>
  );
});
