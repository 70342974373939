import {
  Button,
  Card, Skeleton,
  Stack,
  Typography
} from "@mui/material";
import { NumericString } from "shared/types/misc";
import { useProfile } from "shared/hooks";
import { BrokerProfile } from "shared/types/Profile";
import { useNavigate } from "react-router-dom";
import {RStack} from 'shared/components'

export default function MetricPlans() {
  const navigate = useNavigate();
  const { profile } = useProfile<BrokerProfile>();
  if (!profile) {
    return <MetricPlansSkeleton />;
  }
  if (!profile.require_purchase_package) {
    return null;
  }
  const {
    remaining_credits,
    users_reached,
    users_reached_trial,
    allow_trial,
    trial_initiated,
  } = profile;
  const isTrial = allow_trial && trial_initiated;
  const pkgComsuption = isTrial ? users_reached_trial : users_reached;
  const availableCredits = isTrial ? "--" : remaining_credits;
  // const handleClick = () => {
  //   navigate("/broker/planes");
  // };
  return (
    <Stack gap={1}>
      <RStack sx={{ "&>*": { flex: 3 } }} gap={2}>
        <PlanCounter label="Créditos disponibles" val={availableCredits} />
        <PlanCounter label="Consumo créditos" val={`${pkgComsuption}`} />
        {/* <Button variant="contained" onClick={handleClick}>
          Comprar más
        </Button> */}
      </RStack>
      {isTrial && (
        <Typography>
          ¡Aún no tienes avances de consumo para ver! Una vez adquieras
          créditos, verás en estas tarjetas las métricas.
        </Typography>
      )}
    </Stack>
  );
}

function PlanCounter({ label, val }: { label: string; val: NumericString }) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography textAlign="center">{label}</Typography>
      <Typography
        color="primary"
        component={Card}
        padding="0.8rem 1.5rem"
        minWidth={90}
        bgcolor={"#00000025"}
        textAlign="center"
      >
        {val}
      </Typography>
    </Stack>
  );
}

function MetricPlansSkeleton() {
  return (
    <RStack id="skeleton" justifyContent="space-around">
      <Stack direction="row" alignItems="center" gap={1}>
        <Skeleton variant="text" width={100} />
        <Skeleton height={50} width={80} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Skeleton variant="text" width={100} />
        <Skeleton height={50} width={80} />
      </Stack>
      {/* <Skeleton height={80} width={250} /> */}
    </RStack>
  );
}
