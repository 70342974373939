import {
  Grid,
  Stack,
  Skeleton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

import CustomSkeleton from "shared/components/CustomSkeleton";

const MDSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {/* TODO: Pending fix Skeleton layout */}
      {/* <Grid item xs={12}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          height={31}
          width={152}
        >
          <Skeleton height={24} width={24} variant="circular" />
          <Skeleton height={30} width={120} />
        </Stack>
      </Grid>
      
      <Grid item md={6} sm={6} xs={6}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          <Skeleton width={150} />
        </Typography>
      </Grid>

      <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
        <ToggleButtonGroup color="primary" value={0} exclusive disabled>
          <ToggleButton value="banks">Bancos</ToggleButton>
          <ToggleButton value="brokers">Asesores</ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: ".5rem" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          <Skeleton width={150} />
        </Typography>

        <Divider component="br"></Divider>

        <Grid item md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ToggleButtonGroup
              value={0}
              exclusive
              aria-label="text alignment"
              disabled={true}
            >
              <ToggleButton value="list" aria-label="left aligned">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="card" aria-label="centered">
                <ViewModuleIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>

        <Skeleton height={250} />
        <Grid container spacing={2}>
            <Skeleton height={250}  />
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default MDSkeleton