import axios from "../../utils/axios";

export interface BankFlipApiBody {
  person_type: BankFlipPersonType;
  mortgage_id: string;
}
export type BankFlipPersonType = "main_user" | "partner";

const createBankflipSession = (data: BankFlipApiBody) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/services/bankflip/session`,
    data
  );
};

export { createBankflipSession };
