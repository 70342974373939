import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { HAS_CHILDREN_OPTIONS } from "../../utils";
import HandleSolicitorView from "../HandleSolicitorView";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { userSchema } from "../../validation/user";

const yupSchema = yup.object({
  user: userSchema.pick(["children"]),
  user_2: isConjuntaValidate(userSchema.pick(["children"])),
});

function ChildrenForm() {
  const { mortgageFormData, onNext, onBack } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuántos hijos o personas tienes a cargo?"
      resolver={yupResolver(yupSchema)}
    >
      <Form />
    </StepLayout>
  );
}

function Form() {
  const { onNext } = useMortgageFormContext();
  return (
    <HandleSolicitorView
      RenderComponent={FormFields.ListSelector}
      individualProps={{
        name: "user.children",
        options: HAS_CHILDREN_OPTIONS,
        onClick: onNext,
      }}
      conjuntaProps={{
        name: "user_2.children",
        options: HAS_CHILDREN_OPTIONS,
      }}
    />
  );
}
export default ChildrenForm;
