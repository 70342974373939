import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import HandleSolicitorView from "../HandleSolicitorView";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";
import * as yup from "yup";
import { getUserProfessions } from "utils/helpers";
import { InputOption } from "shared/types/misc";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { useFormContext } from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import { userSchema } from "../../validation/user";

const yupSchema = yup.object({
  user: userSchema.pick(["profession"]),
  user_2: isConjuntaValidate(userSchema.pick(["profession"])),
});

function UserProfessionForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es su profesión?"
      resolver={yupResolver(yupSchema)}
    >
      <Form />
    </StepLayout>
  );
}

function Form() {
  const { onNext } = useMortgageFormContext();
  const options: InputOption[] = getUserProfessions().map((e) => ({
    value: e.id,
    label: e.description,
  }));
  return (
    <HandleSolicitorView
      RenderComponent={FormFields.ListSelector}
      individualProps={{
        getValueFromOptions: (o: any | null) => {
          if (!o) return "";
          if (typeof o === "string") return o;
          return o.value;
        },
        loadMoreLabel: "Ver más profesiones",
        loadMoreAsAutocomplete: true,
        maxLen: 9,
        name: "user.profession",
        options,
        onClick: onNext,
      }}
      conjuntaProps={{
        name: "user_2.profession",
        options,
      }}
    />
  );
}

export default UserProfessionForm;
