import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import Add from "@mui/icons-material/Add";
import {
  FieldValues,
  FormProvider,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  useWatch,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DialogWarning from "shared/components/DialogWarning";
import { useDialog, useInterestRate } from "shared/hooks";
import { UseDialogReturn } from "shared/hooks/useDialog";
import { MortgageFormData } from "shared/types/postMortgageData";
import { sendTagEvent } from "utils/analytics";
import styles from "../../styles.module.scss";
import { stepFourValidationSchema } from "./validation.schema";
import ExpenseCard from "./ExpenseCard";
import IncomeCard from "./IncomeCard";
import CountersDisplayCard, {
  CounterDisplayProps,
} from "shared/components/CounterDisplayCard";
import { formatCurrency } from "utils/helpers";

interface IStepFourProps {
  onNext?: (formData: any) => void;
  onBack?: (formData?: any) => void;
  defaultValues: MortgageFormData;
}
export const StepFour: FunctionComponent<IStepFourProps> = ({
  onNext,
  onBack,
  defaultValues,
}) => {
  const {
    isShowing: isCalculatorDialogShowing,
    toggle: calculatorDialogToggle,
  } = useDialog(false);

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    resolver: yupResolver(stepFourValidationSchema),
  });

  const { control, setValue, getValues, handleSubmit } = methods;
  const incomesFieldArrayMethods = useFieldArray({
    control,
    name: "revenue.incomes_list",
  });

  const expensesFieldArrayMethods = useFieldArray({
    control,
    name: "revenue.expenses_list",
  });

  const [userSalary, user2Salary, revenueMonthly] = useWatch({
    control,
    name: ["user.salary", "user_2.salary", "revenue.monthly"],
  });
  // const userSalary = mortgageFormValue?.user.salary;
  // const user2Salary = mortgageFormValue?.user_2.salary;
  const solicitorType = defaultValues.solicitor_type;
  const dataInterestType = defaultValues.data.interest_type;

  const { mortgageAllowedCalculator } = useInterestRate();

  // calculate total salary
  useEffect(() => {
    if (solicitorType === "1") {
      setValue("revenue.monthly", Number(userSalary));
    }
    if (solicitorType === "2") {
      let monthly = Number(userSalary) + Number(user2Salary);
      setValue("revenue.monthly", monthly);
    }
  }, [solicitorType, userSalary, user2Salary]);

  const addIncomeCard = (solicitor: 1 | 2 = 1) => {
    incomesFieldArrayMethods.append({
      monthly_income: "",
      name: "",
      solicitor: solicitor.toString(),
    });
  };

  const checkIncomeCard = () => {
    if (incomesFieldArrayMethods.fields.length === 0) {
      addIncomeCard();
      if (solicitorType === "2") {
        addIncomeCard(2);
      }
      return;
    }
    const incomesList = getValues("revenue.incomes_list");
    if (incomesList.length > 0) {
      if (solicitorType === "2") {
        const hasSolicitorTwo = incomesList.some((f: any) => {
          return Number(f.solicitor) === 2;
        });
        if (!hasSolicitorTwo) {
          addIncomeCard(2);
          return;
        }
      }
      const hasSolicitorOne = incomesList.some((f: any) => {
        return Number(f.solicitor) === 1;
      });
      if (!hasSolicitorOne) {
        addIncomeCard();
        return;
      }
    }
    return true;
  };

  const isMortgageAllowed = () => {
    const [dataPeriod, expensesList, revenueMonthly, dataAmount] = getValues([
      "data.period",
      "revenue.expenses_list",
      "revenue.monthly",
      "data.amount",
    ]);

    return mortgageAllowedCalculator({
      dataPeriod: Number(dataPeriod),
      expensesList,
      revenueMonthly: Number(revenueMonthly),
      dataAmount: Number(dataAmount),
      interestType: dataInterestType,
    });
  };

  const handleOnNext = (data: any) => {
    const shouldReturn = checkIncomeCard();
    if (!shouldReturn) {
      return;
    }
    const { debtRatio, isAllowed } = isMortgageAllowed();
    if (isAllowed) {
      setValue("data.debtRatio", debtRatio);
      onNext?.(data);
      return;
    }
    calculatorDialogToggle();
  };

  const handleOnBack = () => {
    const form = getValues();
    onBack?.(form);
  };

  const counterDisplayListProps: CounterDisplayProps[] = [
    {
      count: formatCurrency(userSalary),
      helperText: "Ingresos totales del solicitante.",
      label: "Ingresos netos mensuales del solicitante",
    },
  ];
  if (solicitorType === "2") {
    counterDisplayListProps.push({
      count: formatCurrency(user2Salary),
      helperText: "Ingresos totales del acompañante.",
      label: "Ingresos netos mensuales del acompañante",
    });
    counterDisplayListProps.push({
      count: formatCurrency(revenueMonthly),
      helperText: "Total de Ingresos familiares.",
      label: "Total de Ingresos familiares",
    });
  }

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(handleOnNext)}>
        <Grid container spacing={2}>
          <StepFourDialogCalculator
            isShowing={isCalculatorDialogShowing}
            toggle={calculatorDialogToggle}
          />
          <Grid item xs={12}>
            <Typography variant="h5">Registro</Typography>
            <Divider component="br" />
            <Typography variant="body1">Información financiera</Typography>
            <br />
          </Grid>

          <Grid item xs={12} md={12}>
            <CountersDisplayCard
              counterDisplayListProps={counterDisplayListProps}
            />
          </Grid>

          <IncomesSection incomesFieldArrayMethods={incomesFieldArrayMethods} />
          <ExpensesSection
            expensesFieldArrayMethods={expensesFieldArrayMethods}
          />

          <Grid item xs={12} md={12}>
            <Box className={styles.ActionButtons}>
              {onBack && (
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIosNew />}
                  // talvez pasar el getValues desde aqui en vez desde mortgagt form change step
                  onClick={handleOnBack}
                >
                  Regresar
                </Button>
              )}
              {onNext && (
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Siguiente
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
      <DevTool control={methods.control} placement="top-right" />
    </FormProvider>
  );
};

export const getStepFourFormTriggerArr = ({
  expensesFields,
  incomesFields,
  solicitorType,
}: {
  expensesFields: UseFieldArrayReturn<
    FieldValues,
    "revenue.expenses_list",
    "id"
  >["fields"];
  incomesFields: UseFieldArrayReturn<
    FieldValues,
    "revenue.incomes_list",
    "id"
  >["fields"];
  solicitorType: number | string;
}) => {
  const triggerArr: any[] = ["user.salary"];
  if (solicitorType === "2") {
    triggerArr.push("user_2.salary");
  }
  if (incomesFields.length !== 0) {
    for (let i = 0; i < incomesFields.length; i++) {
      triggerArr.push(
        `revenue.incomes_list[${i}].name`,
        `revenue.incomes_list[${i}].solicitor`,
        `revenue.incomes_list[${i}].monthly_income`
      );
    }
  }
  if (expensesFields.length !== 0) {
    for (let i = 0; i < expensesFields.length; i++) {
      triggerArr.push(
        `revenue.expenses_list[${i}].name`,
        `revenue.expenses_list[${i}].solicitor`,
        `revenue.expenses_list[${i}].debt_monthly`,
        `revenue.expenses_list[${i}].debt_total`,
        `revenue.expenses_list[${i}].debt_duration`
      );
    }
  }
  return triggerArr;
};

const IncomesSection = ({
  incomesFieldArrayMethods,
}: {
  incomesFieldArrayMethods: UseFieldArrayReturn<
    MortgageFormData,
    "revenue.incomes_list",
    "id"
  >;
}) => {
  const { fields, append, remove } = incomesFieldArrayMethods;
  const [hasError, setHasError] = useState(false);
  return (
    <>
      <Grid item xs={12} md={12}>
        <Divider component="br" />
        <Typography variant="body1">Ingresos existentes</Typography>
        <Typography variant="subtitle2">
          Por favor añade tus ingresos aquí, como el salario, renta y otros.
        </Typography>
        {hasError && (
          <Box>
            <Divider component="br" />
            <Alert severity="error">Todos los campos deben estar llenos</Alert>
          </Box>
        )}
      </Grid>

      {fields.map((field: any, index: any) => {
        return (
          <IncomeCard
            key={index}
            index={index}
            fieldId={field.id}
            remove={remove}
          />
        );
      })}

      <Grid item xs={12} md={12}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={() => {
            append({
              monthly_income: "",
              name: "",
              solicitor: "",
            });
            // formUpdate({ item: "add", value: true });
          }}
        >
          Añadir ingreso existente
        </Button>
      </Grid>
    </>
  );
};

const ExpensesSection = ({
  expensesFieldArrayMethods,
}: {
  expensesFieldArrayMethods: UseFieldArrayReturn<
    MortgageFormData,
    "revenue.expenses_list",
    "id"
  >;
}) => {
  const { fields, append, remove } = expensesFieldArrayMethods;
  const [hasError, setHasError] = useState(false);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Divider component="br" />
        <Typography variant="body1">Deudas existentes</Typography>
        <Typography variant="subtitle2">
          Por favor añade tus gastos aquí, como la letra del coche, otra
          hipoteca u otros.
        </Typography>
        {hasError && (
          <Box>
            <Divider component="br" />
            <Alert severity="error">Todos los campos deben estar llenos</Alert>
          </Box>
        )}
      </Grid>

      {fields.map((field: any, index: any) => {
        return (
          <ExpenseCard
            key={index}
            index={index}
            fieldId={field.id}
            remove={remove}
          />
        );
      })}

      <Grid item xs={12} md={12}>
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={() => {
            append({
              debt_monthly: "",
              debt_total: "",
              debt_duration: new Date(),
              name: "",
              solicitor: "",
            });
            // formUpdate({ item: "add", value: true });
          }}
        >
          Añadir deuda existente
        </Button>
      </Grid>
    </>
  );
};

const calculatorUrl = "https://colibid.com/calculadora-de-hipoteca/";
const aspireCalculatorUrl =
  "https://colibid.com/calculadora-cuanto-podria-llegar-solicitar/";

const StepFourDialogCalculator = ({
  isShowing,
  toggle,
}: Pick<UseDialogReturn, "isShowing" | "toggle">) => {
  const navigate = useNavigate();

  const handledCLickCalculatorDialog = () => {
    sendTagEvent({
      event: "app_formulario_redirecto_to_calculator",
      method: "email",
    });
    window.open(calculatorUrl, "_blank");
    navigate("/client/home");
  };
  const handledCLickAspireCalculatorDialog = () => {
    sendTagEvent({
      event: "app_formulario_redirecto_to_calculator",
      method: "email",
    });
    window.open(aspireCalculatorUrl, "_blank");
    navigate("/client/home");
  };
  return (
    <DialogWarning
      buttonDisagree="Ir a calcular coste mensual"
      buttonAgree="Ir a calcular hipoteca máxima"
      ShowDialog={isShowing}
      handledClickAgree={handledCLickAspireCalculatorDialog}
      handledClickDisagree={handledCLickCalculatorDialog}
      closeOnOutside={(event, reason) => {
        toggle();
      }}
      closeIcon={true}
      title="¿Tús ingresos están correctos?"
    >
      <>
        <Typography variant="body2" gutterBottom>
          En España, la Ley establece que los bancos no pueden conceder
          hipotecas cuando el importe de tus deudas (incluyendo la nueva
          hipoteca) supera del 30% al 40% de tus ingresos netos disponibles (es
          decir, del 30% al 40% de tu salario neto menos la deuda mensual).
        </Typography>
        <Typography variant="body2" gutterBottom>
          Lamentablemente, estimamos que no superarás este umbral y, por tanto,
          no podemos activar tu solicitud.
        </Typography>
        <Typography variant="body2" gutterBottom>
          ¡Ey! Pero no queda todo aquí, todavía puedes explorar más a fondo tus
          posibilidades mediante nuestras caluladoras.
        </Typography>
      </>
    </DialogWarning>
  );
};
