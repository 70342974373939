import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonProps,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  CustomDialog,
  HelperText,
  TableSkeleton,
  UploadManager,
  WithTooltip,
} from "../";
import { getDoctypes } from "../../../services/api";
import { useDialog, useLocalStorage } from "../../../shared/hooks";

import { DROPZONE_ACCEPTED_FILE } from "shared/constants/general";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { DocType, BidFile } from "shared/types/DocType";
import { DocAsstBtn } from "../DocAsssitant";
import { EmploymentSituations } from "../MortgageForm/types";
import { getMortgageFilesById } from "services/api/mortgages.service";

export interface IBidFile {
  description: string;
  url: string;
  uploaded: boolean;
  code: DocType["code"];
  downloadable: boolean;
  required: boolean;
  canAutomaticUpload: boolean;
}
export interface IBidFilesProps {
  autoExpand?: boolean;
  allowDownload?: boolean;
  mortgage: IMortgageSchema | MortgageRes;
  /**
   * now bidfiles will handle the update of files and refresh the list with getFileList enpoint onfinish,
   *  but is need to do something more you can pass it here
   */
  onFinish?: () => void;
}

const BidFiles: FunctionComponent<IBidFilesProps> = ({
  autoExpand,
  allowDownload,
  mortgage,
  onFinish,
}) => {
  const { id: mid } = mortgage;
  const [expanded, setExpandedFiles] = useState(autoExpand);
  const [bidFiles, setBidFiles] = useState<BidFile[]>();
  // const [docFiles, setDocFiles] = useState<DocType[]>([]);
  const [role] = useLocalStorage("role");
  const [access_token] = useLocalStorage("access_token");
  const [donwloading, setDonwloading] = useState(false);

  const getFileList = (id: string) => {
    // it is undefined to trigger skeleton one state, 2 use, nice!!! 🙃
    setBidFiles(undefined);
    getMortgageFilesById(id, "importants_files")
      .then((res) => {
        setBidFiles(res.data.results);
      })
      .catch((e) => {
        console.log({ e });
      });
  };

  const downloadFiles = (url: string) => {
    setDonwloading(true);
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        fileDownload(res.data, `mortgage_files-${mid}.zip`);
        setDonwloading(false);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al descargar los archivos, por favor inténtalo más tarde 😔"
        );
      });
  };

  useEffect(() => {
    getFileList(mid);
  }, []);

  return (
    <>
      <Accordion
        sx={{ marginTop: "1rem" }}
        onChange={(e, a) => setExpandedFiles(a)}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={
            !expanded ? (
              <AddCircleOutlineIcon color="primary" />
            ) : (
              <RemoveCircleOutlineIcon color="primary" />
            )
          }
        >
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
            paddingRight={2}
            // justifyContent="space-between"
          >
            <Stack direction="row">
              <ContentPasteIcon
                color="primary"
                style={{ marginRight: ".7rem" }}
              />
              <Typography>Documentos aportados</Typography>
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              {((allowDownload && ["bank", "broker"].indexOf(role) !== -1) ||
                ["client"].indexOf(role) !== -1) && (
                <LoadingButton
                  loading={donwloading}
                  loadingPosition="start"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFiles(
                      `${process.env.REACT_APP_API_URL}/mortgages/${mid}/download_files`
                    );
                  }}
                  disabled={mortgage?.files?.length === 0}
                  variant="contained"
                  startIcon={<DownloadIcon />}
                >
                  Descargar Todos
                </LoadingButton>
              )}
            </Box>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <DocsTable
            files={bidFiles}
            role={role}
            mortgage={mortgage}
            onFinish={() => {
              getFileList(mid);
              if (onFinish) {
                onFinish();
              }
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BidFiles;

function BidFileStatusIcon({ f, role }: { f: BidFile; role: string }) {
  if (f.uploaded?.downloadable)
    return (
      <HelperText text="Documento cargado correctamente, descarga permitida">
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => {
            if (f.uploaded && f.uploaded.downloadable) {
              window.open(f.uploaded.file_url, "_blank");
            }
          }}
        >
          <DownloadIcon color={f.is_uploaded ? "primary" : "disabled"} />
        </IconButton>
      </HelperText>
    );
  return (
    <>
      {f.is_uploaded && (
        <HelperText text="Documento cargado correctamente">
          <CloudDoneIcon color="primary" />
        </HelperText>
      )}

      {!f.is_uploaded && role === "client" && (
        <HelperText text="Documento pendiente, debe cargarlo desde la opción Subir Archivos">
          <CloudIcon color="disabled" />
        </HelperText>
      )}

      {!f.uploaded && role !== "client" && <CloudIcon color="disabled" />}
    </>
  );
}

function DocsTable({
  files,
  mortgage,
  role,
  onFinish,
}: {
  files?: BidFile[];
  mortgage: IBidFilesProps["mortgage"];
  role: string;
  /**refrest mortgage mostly after uploaded file*/
  onFinish: () => void;
}) {
  // TODO agregar row para poder agregar any file
  // TODO click in the manual icon open upload file
  const { isShowing, openDialog, closeDialog } = useDialog();
  const [bidFileSelected, setBidFileSelected] = useState<BidFile>();
  if (!files) {
    return <TableSkeleton headColumns={3} bodyRows={6} bodyColumns={4} />;
  }
  const handleManualUpload =
    (bidFile?: BidFile) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setBidFileSelected(bidFile);
      openDialog();
    };
  const isClient = role === "client";
  return (
    <Table
      size="small"
      sx={(theme) => ({
        [`@media(max-width:${theme.breakpoints.values.sm}px)`]: {
          display: "block",
          overflowX: "auto",
        },
        "& tr": {
          height: "50px",
        },
      })}
    >
      {isClient && (
        <TableHead>
          <TableRow>
            <TableCell colSpan={1}></TableCell>
            <TableCell align="center">
              <WithTooltip
                title="Aquí podrás cargar tu documentación de forma manual haciendo clic en cada nube de carga"
                position="right"
              >
                <Typography>Manual</Typography>
              </WithTooltip>
            </TableCell>
            <TableCell align="center" sx={{ paddingBottom: 2 }}>
              <WithTooltip
                title="Al hacer clic en el asistente, podremos ayudarte a cargar algunos documentos de forma automática"
                position="right"
              >
                <DocAsstBtn
                  mortgage={mortgage}
                  variant="contained"
                  sx={{ backgroundColor: "#33CCCC", color: "white" }}
                >
                  Automatico
                </DocAsstBtn>
              </WithTooltip>
            </TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {files.map((f: BidFile, i, arr) => {
          return (
            <TableRow
              key={f.code}
              sx={(theme) => {
                const isEven = arr.length % 2 === 0;
                return {
                  [`&:nth-of-type(${isEven ? "even" : "odd"})`]: {
                    backgroundColor: theme.palette.action.hover,
                  },
                };
              }}
            >
              <TableCell>
                <Stack gap={2} direction="row" alignItems="center">
                  <BidFileStatusIcon f={f} role={role} />
                  {f.label}
                </Stack>
              </TableCell>
              {isClient && (
                <>
                  <TableCell align="center">
                    {f.user_upload && (
                      <IconButton onClick={handleManualUpload(f)}>
                        <CloudUploadOutlinedIcon sx={{ color: "black" }} />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {f.automatic_upload ? (
                      <DocAsstBtn mortgage={mortgage}>
                        <CloudUploadOutlinedIcon sx={{ color: "#33CCCC" }} />
                      </DocAsstBtn>
                    ) : null}
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
        <ExtraDocTR
          mortgage={mortgage}
          onClick={handleManualUpload()}
          isClient={isClient}
        />
        <CustomDialog open={isShowing} onClose={closeDialog} fullWidth>
          {onFinish && (
            <UploadManager
              mortgage={mortgage as IMortgageSchema}
              mortgageId={mortgage.id}
              mode=""
              denyFiles={[]}
              acceptedFiles={DROPZONE_ACCEPTED_FILE}
              onlyAllow={[]}
              onFinish={() => {
                closeDialog();
                if (onFinish) {
                  onFinish();
                }
              }}
              defaultDocTypeCode={bidFileSelected?.code}
              qty_file_required={bidFileSelected?.qty_file_required}
            />
          )}
        </CustomDialog>
      </TableBody>
    </Table>
  );
}

function ExtraDocTR({
  mortgage,
  onClick,
  isClient,
}: {
  onClick: ButtonProps["onClick"];
  mortgage: IBidFilesProps["mortgage"];
  /**to gettit comprare role is it client */
  isClient: boolean;
}) {
  return (
    <TableRow>
      <TableCell>
        <Stack gap={2} direction="row" alignItems="center">
          <HelperText text="Documento opcionales, que puede ayudar a mejorar la hipoteca">
            <CloudIcon color="disabled" />
          </HelperText>
          Documentos extra que desees añadir (no obligatorio)
        </Stack>
      </TableCell>
      {isClient && (
        <>
          <TableCell align="center">
            <IconButton onClick={onClick}>
              <CloudUploadOutlinedIcon sx={{ color: "black" }} />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <DocAsstBtn mortgage={mortgage}>
              <CloudUploadOutlinedIcon sx={{ color: "#33CCCC" }} />
            </DocAsstBtn>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
