import React, { ReactElement, FunctionComponent } from "react";
import styles from "./loader.module.scss";

const Loader: FunctionComponent = (): ReactElement<any> => {
  return (
    <div className={styles.Container}>
      <div className={styles.Spinner}>
        <div
          className={"spinner-border " + styles.SpinnerEl}
          role="status"
        ></div>
      </div>
    </div>
  );
};

export default Loader;
