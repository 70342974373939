// ImageWithTitle.tsx
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

interface ImageWithTitleProps {
  title: string;
  imageUrl: string;
  targetPath: string;
  boxColor: string;
  customStyle?: React.CSSProperties;
  customStyleTitle?: React.CSSProperties;
}

const ImageWithTitle: React.FC<ImageWithTitleProps> = ({
  title,
  imageUrl,
  targetPath,
  boxColor,
  customStyle,
  customStyleTitle,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(targetPath);
  };

  return (
    <Box
      sx={{
        backgroundColor: `${boxColor}`,
      }}
      className={styles.boxImage}
      onClick={handleClick}
    >
      <Typography
        style={{ ...customStyleTitle }}
        variant="h5"
        component="div"
        gutterBottom
      >
        {title}
      </Typography>
      <img
        src={imageUrl}
        alt={title}
        style={{
          display: "block",
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "cover",
          ...customStyle,
        }}
      />
    </Box>
  );
};

export default ImageWithTitle;
