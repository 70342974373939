import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactChild | React.ReactChild[];
};

const ScrollToTop = ({ children }: Props) => {
  const location = useLocation();
  useEffect(() => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  }, [location]);
  return <>{children}</>;
};

export default ScrollToTop;
