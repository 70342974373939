import { AxiosResponse } from "axios";
import axios from "utils/axios";

const exportCrm = ({ mode }: { mode?: "" | "dead" | "paid" }) => {
  const params: Record<string, string> = { type: "csv" };
  if (mode) {
    params.mode = mode;
  }
  return axios
    .get(`${process.env.REACT_APP_API_URL}/bidders/crm/export`, {
      responseType: "blob",
      params,
    })
    .then((res) => {
      const filename = getFilenameFromContentDisposition(res)
      // Create a URL object from the response data
      const url = window.URL.createObjectURL(new Blob([res.data]));
      // Create a link element to initiate the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link and revoke the URL
      link.remove();
      window.URL.revokeObjectURL(url);
    });
};

export { exportCrm };

const getFilenameFromContentDisposition = (res: AxiosResponse<any, any>) => {
  const contentDisposition = res.headers["content-disposition"];
  if(!contentDisposition) {
    return 'mortgages.xlsx'
  }
  const [, filename] = contentDisposition
    .split(";")
    .map((item) => item.trim().split("=")[1]);
  return filename || "mortgages.csv"
};
