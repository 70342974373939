import { Skeleton, Stack } from "@mui/material";
import {HStack} from 'shared/components'

type Props = {
  width?: number,
  height?: number
  direction?: 'row' | 'column'
}
export default function ProfileAvatarSkeleton({width = 70, height = 70, direction='row'}: Props) {
  return (
    <HStack gap={1} flexDirection={direction}>
      <Skeleton variant="circular" width={width} height={height} />
      <Stack>
        <Skeleton variant="text" width={150} height={32} />
        <Skeleton variant="text" width={150} height={20} />
      </Stack>
    </HStack>
  );
}
