import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useTable,
  Column,
  CellProps,
  Row,
  Cell,
  ColumnInstance,
} from "react-table";
import { toast } from "react-toastify";
import { getListClient } from "services/api/agent.service";
import { MainRealEstateLayout } from "shared/layouts";
import styles from "./styles.module.scss";
import {
  ColumApplyMortgage,
  scoringColumn,
  simpleColumns,
  stepColumn,
} from "../../Clients/utils";

interface TransposedTableProps {
  titulos: DataColumn[];
  data: { [key: string]: string }[];
  onApplyMortagage?: (data: any) => void; // arrow function
  onColumClick: (
    data: Row<{
      [key: string]: string;
    }>
  ) => void;
}

type DataColumn = Column<{
  [key: string]: string;
}>;

const BasicTable = ({
  data,
  titulos,
  onApplyMortagage,
  onColumClick,
}: TransposedTableProps) => {
  const datas = React.useMemo<TransposedTableProps["data"]>(
    () => [...data],
    [data]
  );

  const columns = React.useMemo<TransposedTableProps["titulos"]>(
    () => [...titulos],
    [titulos]
  );
  const {
    getTableProps,
    headers,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: columns,
    data: datas,
  });

  const renderInput = (
    r: Row<{
      [key: string]: string;
    }>,
    c: ColumnInstance<{
      [key: string]: string;
    }>
  ) => {
    let value =
      c.id === "Prescoring" ? (
        scoringColumn(r, c)
      ) : c.id === "AplicaraHipoteca" ? (
        <ColumApplyMortgage
          r={r}
          c={c}
          onApplyMortagage={(data) => {
            if (onApplyMortagage) {
              onApplyMortagage(data);
            }
          }}
        />
      ) : c.id === "cliente" ||
        c.id == "ValorVivienda" ||
        c.id === "UbicacionVivienda" ||
        c.id === "email" ? (
        r.values[c.id]
      ) : (
        stepColumn(r, c)
      );

    return value;
  };

  const handleRowClick = (
    r: Row<{
      [key: string]: string;
    }>
  ) => {
    onColumClick(r);
  };

  return (
    <TableContainer style={{ overflowX: "auto" }}>
      <Table
        className={styles.customTable}
        {...getTableProps()}
        sx={{ minWidth: 650 }}
      >
        <TableBody {...getTableBodyProps()}>
          {headers.map((h) => {
            return (
              <TableRow {...h.getHeaderProps()}>
                <TableCell
                  component="th"
                  className={`${styles.cellHiglights} ${styles.stickyCell}`}
                >
                  {h.id !== "id" && h.render("Header")}
                </TableCell>
                {rows.map((r, i) => {
                  prepareRow(r);
                  return (
                    <TableCell
                      component="td"
                      {...r.getRowProps()}
                      className={
                        r.original.AplicaraHipoteca === "draft"
                          ? styles.columnEditable
                          : ""
                      }
                      onClick={() => {
                        if (r.original.AplicaraHipoteca === "draft") {
                          handleRowClick(r);
                        }
                      }}
                    >
                      {renderInput(r, h)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
