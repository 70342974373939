import { ComponentType } from "react";
import { MFormGroupKey, MFormSubGroupKey } from "..";
import { PersonalDataBuilder, PersonalDataDirector } from "./personalData";
import { MortgageDataBuilder, MortgageDataDirector } from "./mortgageData";
import { WorkInfoDataBuilder, WorkInfoDataDirector } from "./workInfoData";
import {
  CodeVerification,
  RevenueForm,
  VerificationForm,
} from "../components/formSteps";
import {
  DeepPartial,
  FieldValues,
  Path,
  Resolver,
  UnpackNestedValue,
} from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import { Role } from "shared/types/Profile";

export type StepViewLayoutProps<T extends FieldValues> = {
  resolver?: Resolver<any>;
  title: string;
  defaultValues: UnpackNestedValue<DeepPartial<T>>;
  onNext: (data?: T) => void;
  onBack?: () => void;
};

export type AccesorPathLabel<T> = {
  path: Path<T>;
  label: string;
  extractor?: (a: any) => any;
};

export type StepView<T extends FieldValues> = {
  group?: MFormGroupKey;
  subGroup?: MFormSubGroupKey;
  component: ComponentType;
  accesor?: AccesorPathLabel<T> | AccesorPathLabel<T>[];
};

export interface IMFormStepBuilder {
  _stepViews: StepView<MortgageFormData>[];
  addStep(
    Component: ComponentType,
    // accesor?: Extract<StepView<MortgageFormData>, { accesor: any }>["accesor"]
    accesor?: StepView<MortgageFormData>["accesor"]
  ): IMFormStepBuilder;
}

export interface MortgageStepBaseDirector {
  buildNewMortgage(): void;
  buildImproveMortgage(): void;
  buildRaiseCapital(): void;
  buildAutopromotor(): void;
}

interface ConstructorProps {
  mortgageMode: number;
  initialStep: StepView<MortgageFormData>;
  isUserLogin: boolean;
  role: Role;
  isUserVerified?: boolean;
}
export class MortgageFormStepBuilder implements IMFormStepBuilder {
  private mortgageMode: number;
  private initialStep: StepView<MortgageFormData>;
  private isUserLogin: boolean;
  private isUserVerified?: boolean;
  private role: Role;
  _stepViews: StepView<MortgageFormData>[] = [];
  // // maybe all component use some global state like react context api
  constructor({
    mortgageMode,
    initialStep,
    isUserLogin,
    isUserVerified,
    role,
  }: ConstructorProps) {
    this.mortgageMode = mortgageMode;
    this.initialStep = initialStep;
    this._stepViews.push(initialStep);
    this.role = role;
    this.isUserLogin = isUserLogin;
    this.isUserVerified = isUserVerified;
  }

  addStep(
    Component: ComponentType,
    accesor?: Extract<StepView<MortgageFormData>, { accesor: any }>["accesor"]
  ): IMFormStepBuilder {
    this._stepViews.push({ component: Component, accesor });
    return this;
  }

  reset() {
    this._stepViews = [this.initialStep];
  }

  addPersonalDataSteps() {
    const builder = new PersonalDataBuilder();
    const director = new PersonalDataDirector(builder);

    this.mortgageMode === 1 && director.buildNewMortgage();
    this.mortgageMode === 2 && director.buildImproveMortgage();
    this.mortgageMode === 3 && director.buildRaiseCapital();
    this.mortgageMode === 4 && director.buildAutopromotor();

    const personalDataSteps = builder.build();
    this._stepViews.push(...personalDataSteps);
  }

  addMortgageDataSteps() {
    const builder = new MortgageDataBuilder();
    const director = new MortgageDataDirector(builder);

    this.mortgageMode === 1 && director.buildNewMortgage();
    this.mortgageMode === 2 && director.buildImproveMortgage();
    this.mortgageMode === 3 && director.buildRaiseCapital();
    this.mortgageMode === 4 && director.buildAutopromotor();

    const mortgageDataSteps = builder.build();
    this._stepViews.push(...mortgageDataSteps);
  }
  addWorkInfoDataSteps() {
    const builder = new WorkInfoDataBuilder();
    const director = new WorkInfoDataDirector(builder);

    this.mortgageMode === 1 && director.buildNewMortgage();
    this.mortgageMode === 2 && director.buildImproveMortgage();
    this.mortgageMode === 3 && director.buildRaiseCapital();
    this.mortgageMode === 4 && director.buildAutopromotor();

    const mortgageDataSteps = builder.build();
    this._stepViews.push(...mortgageDataSteps);
  }
  addRevenueSteps() {
    this._stepViews.push({
      group: "form",
      subGroup: "revenue",
      component: RevenueForm,
      accesor: { path: "revenue", label: "Ingresos y gastos" },
    });
  }
  addVerificationStep() {
    this._stepViews.push({
      group: "check",
      // layout: FormFields.WizardFormPage,
      component: VerificationForm,
    });
  }
  addCodeVerificationStep() {
    if (
      (!this.isUserLogin || !this.isUserVerified) &&
      this.role !== "realstate"
    ) {
      this._stepViews.push({
        group: "form",
        subGroup: "movilVerification",
        component: CodeVerification,
      });
    }
  }
  build() {
    if (this.mortgageMode > 0) {
      this.addPersonalDataSteps();
      this.addMortgageDataSteps();
      this.addRevenueSteps();
      this.addWorkInfoDataSteps();
      // this.addCodeVerificationStep();
      this.addVerificationStep();
    }
    const result = this._stepViews;
    this.reset();
    return result;
  }
}
