import { InterestType } from "shared/types/postMortgageData";
import axios from "utils/axios";

export type InterestTypeValue = {
  [x in InterestType]: { value: number };
};
const getInterestTypeService = () => {
  return axios.get<InterestTypeValue>(
    `${process.env.REACT_APP_API_URL}/index/general`
  );
};

export { getInterestTypeService };
