import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import { Bid, Biddings, ReactivateBidding } from "shared/types/Bid";
import axios from "../../utils/axios";
import IBiddingSchema, {
  IBiddingAddSchema,
} from "shared/schemas/bidding.schema";

const getBiddings = (filters: string = "", page?: number, limit?: number) => {
  // return axios.get<BaseApiSuccessRes<Biddings[]>>(`${process.env.REACT_APP_API_URL}/biddings${filters}`);
  return axios.get<BaseApiSuccessRes<Biddings[]>>(
    `${process.env.REACT_APP_API_URL}/biddings`,
    {
      params: {
        format: "json",
        ipp: limit,
        p: page,
        filters: filters,
        wrap: 1,
      },
    }
  );
};

const addBidding = (schema: IBiddingAddSchema) => {
  return axios.post<BaseApiSuccessRes<IBiddingSchema>>(
    `${process.env.REACT_APP_API_URL}/biddings/create`,
    schema
  );
};

const updateBidding = (schema: any) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/biddings/${schema.id}/update`,
    schema
  );
};

const deleteBidding = (id: any) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/biddings/${id}/delete`);
};

const reactivateBidding = (biddings: { biddings: ReactivateBidding[] }) => {
  return axios.post<BaseApiSuccessRes<Biddings[]>>(
    `${process.env.REACT_APP_API_URL}/biddings/reactivate`,
    biddings
  );
};

const cancelAceptedBids = (mortgageId: string, bids: Array<{ id: string }>) => {
  return axios.post<BaseApiSuccessRes<Biddings[]>>(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bids/reject`,
    { bids }
  );
};

const getListAcceptedBids = (mortgageId: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bids/accepted`
  );
};

const setDefaultUserBiddings = (payload: {
  action: string;
  params: { max_users: number };
}) => {
  return axios.post<BaseApiSuccessRes<any>>(
    `${process.env.REACT_APP_API_URL}/biddings/operations`,
    payload
  );
};

export {
  getBiddings,
  addBidding,
  updateBidding,
  deleteBidding,
  reactivateBidding,
  getListAcceptedBids,
  cancelAceptedBids,
  setDefaultUserBiddings,
};
