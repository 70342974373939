import React, { FunctionComponent, ReactElement } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import styles from "./styles.module.scss";

interface Banner {
  img: string;
  children: React.ReactNode | string;
}
const BannerInfo: FunctionComponent<any> = (
  props: Banner
): ReactElement<any> => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "#F2F2F2",
        borderRadius: "2rem",
        margin: "1rem 0",
        padding: "1rem",
        width: "100%",
      }}
    >
      <Grid item md={4} sm={12} xs={12}>
        <div style={{ textAlign: "center" }}>
          <img src={props.img} alt="Compartir" style={{ width: "40%" }} />
        </div>
      </Grid>
      <Grid
        item
        md={8}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textAlign: {
              sm: "center",
              md: "left",
            },
            fontSize: {
              sm: "0.875rem;",
              md: "1rem",
            },
          }}
        >
          {props.children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BannerInfo;
