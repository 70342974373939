import { Stack, Container, Button, Typography } from "@mui/material";
import {
  ClientInfoPanel,
  MortgageInfoPanel,
  GeoInfoPanel,
  OffersInfoPanel,
  ConvertionInfoPanel,
} from "./MediaDescPanel";
import { ColibidTypography, HStack } from "shared/components";
import { getImgBaseUrl } from "utils/links";
import { COLIBID_DATA_BOOKING_LINK } from "shared/constants/links";
import { subscribeToDemo } from "services/api/subscription.services";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function Welcome() {
  let timeoutId: NodeJS.Timeout | undefined;
  const BookADemo = () => {
    subscribeToDemo()
      .then(() => {
        window.open(COLIBID_DATA_BOOKING_LINK, "_blank");
        timeoutId = setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((e) => {
        toast.error(
          "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔, pbi_booking "
        );
        console.log(e);
      });
  };
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Stack gap={2}>
      <Container id="colibid-data" maxWidth="md">
        <Stack alignItems="center" paddingTop={2} gap={3}>
          <Title />
          <AdvantageList />
          <Info />
          <Button variant="contained" onClick={BookADemo}>
            Me interesa
          </Button>
          <WhatWeOfferText />
          <Stack gap={3}>
            <ClientInfoPanel />
            <MortgageInfoPanel />
            <GeoInfoPanel />
            <OffersInfoPanel />
            <ConvertionInfoPanel />
          </Stack>
          <InsightsText />
        </Stack>
      </Container>
      <Stack gap={3}>
        <FooterCta onClick={BookADemo} />
      </Stack>
    </Stack>
  );
}

function Title() {
  return (
    <Stack>
      <Typography color="primary" variant="h5" textAlign="center">
        ¿Los beneficios de Colibid Data? ¡Infinitos!
      </Typography>
      <Typography variant="h6" textAlign="center">
        Descubre la única data intelligence hipotecario de España
      </Typography>
    </Stack>
  );
}

function Info() {
  return (
    <Typography variant="h6" textAlign="center" maxWidth={600}>
      Actualmente Colibid Data se encuentra en mantenimiento, si deseas el servicio <strong className="colibid-color">por favor contacta con nosotros</strong>
    </Typography>
    //  <Typography variant="h6" textAlign="center" maxWidth={600}>
    //         Estas son algunas de las tantas preguntas que te responderemos con el{" "}
    //   <strong className="colibid-color">data intelligence de Colibid</strong>
    // </Typography>
  );
}

function WhatWeOfferText() {
  return (
    <>
      <Typography variant="body2">
        Solo entendiendo el mercado conseguirás competir en él de la forma más
        eficiente. Colibid Data cuenta con 5 niveles de métricas para que puedas
        lograrlo. Compruébalo tú mismo en esta muestra de nuestras secciones:
      </Typography>

      <Typography variant="body2" component="span" display="inline-block">
        <strong>Importante:</strong> Los datos mostrados en esta simulación son
        ficticios y no tienen por qué reflejar la realidad de las auténticas
        métricas extraídas del Colibid Data.
      </Typography>
    </>
  );
}

function InsightsText() {
  return (
    <Typography textAlign="center">
      Recopilamos y analizamos datos de más de <strong>30k usuarios</strong> con
      todos sus datos financieros en tiempo real para brindarte insights
      profundos sobre oportunidades y posicionamiento. Toma{" "}
      <strong>decisiones con confianza</strong> respaldada por datos precisos y
      actuales.
      <Typography component="span" display="inline-block">
        Tu ventaja competitiva comienza aquí.
      </Typography>
    </Typography>
  );
}

function FooterCta({ onClick }: { onClick: () => void }) {
  return (
    <Stack
      alignItems="center"
      gap={5}
      sx={{ background: "#F2F2F2" }}
      padding={4}
    >
      <ColibidTypography>
        ¿Qué esperas para comenzar a disfrutar de Colibid Data y potenciar tu
        alcance?
      </ColibidTypography>
      <Button variant="contained" onClick={onClick}>
        Quiero reservar una demo con un agente comercial
      </Button>
    </Stack>
  );
}
function AdvantageList() {
  return (
    <HStack flexWrap="wrap" justifyContent="center" alignItems="center" gap={2}>
      <img src={getImgBaseUrl("undraw_data_report.svg")} width={260} />
      <Stack component="ul">
        {[
          <>
            Encuentra <strong>nichos</strong> de mercado.
          </>,
          <>
            Qué <strong>tipo de condiciones</strong> ofrece el mercado.
          </>,
          <>
            Descubre que <strong>perfiles</strong> son los más solicitados
          </>,
          <>
            En qué <strong>comunidades</strong> tendrás más éxito.
          </>,
          <>
            Quiénes son tus <strong>principales competidores</strong> y qué peso
            tienes en el mercado.
          </>,
          <>
            <strong>Mejora tus ofertas</strong> y conviértete en el mejor
            postor.
          </>,
          <>
            Actualizado a <strong>tiempo real</strong> gracias a un banco de
            datos con más de 200.000 solicitudes hipotecarias.
          </>,
          <>
            Aumenta hasta un 42% la <strong>adquisición</strong> de clientes.
          </>,
          <>
            <strong>¡Y mucho más!</strong>
          </>,
        ].map((a, i) => {
          return (
            <Typography key={i} component="li">
              {a}
            </Typography>
          );
        })}
      </Stack>
    </HStack>
  );
}
