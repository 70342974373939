import React, { useEffect, useRef } from "react";
import { MortgageFormData } from "shared/types/postMortgageData";
import { TransitionProps } from "@mui/material/transitions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slide,
} from "@mui/material";
import { MortgageForm } from "shared/components";

interface RetryDialogProps {
  data: MortgageFormData;
  showModal: boolean;
  onClose: () => void;
  onEdit: (data: any) => void;
  onSubmit: () => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const RetryDialog = ({
  data,
  showModal,
  onClose,
  onEdit,
  onSubmit,
}: RetryDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (showModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showModal]);
  return (
    <div>
      <Dialog
        fullScreen
        open={showModal}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{ backgroundColor: "#01857A", color: "#fff" }}
          id="scroll-dialog-title"
        >
          Hipoteca
        </DialogTitle>
        <DialogContent dividers={true} style={{ padding: "2rem" }}>
          <MortgageForm initialStep={1} initialFormValue={data} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={onSubmit}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
