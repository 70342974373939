import { Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

export default function ColibidTypography({
  children,
  accentColor,
  inline,
  ...typographyPros
}: {
  children: ReactNode;
  accentColor?: boolean;
  inline?: boolean
} & TypographyProps) {
  return (
    <Typography
      className={accentColor ? "colibid-accent-color" : "colibid-color"}
      {...(inline && {component: 'span', display: 'inline-block'})}
      {...typographyPros}
    >
      {children}
    </Typography>
  );
}
