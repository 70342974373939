import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Dialog,
  Typography,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  Stack,
  Alert,
  Link,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { MixTypeInterest } from "../BidDetails/index";

import { NotResults, ToggleFinanceFee } from "../../../shared/components";
import {
  validateConditions,
  splitName,
  getInterestType,
  getMonthlyComission,
} from "../../../utils/helpers";

import styles from "./styles.module.scss";
import { useLocalStorage } from "../../hooks";
import { useNavigate } from "react-router-dom";

export interface IConfirmAcceptProps {
  bid: string;
  title: any;
  caption?: string;
  htmlCaption?: any;
  onConfirmText?: string;
  bidData: any;
  mortgageData: any;
  onConfirm: (bid: any) => void;
  onClose?: () => void;
  hideWarning?: boolean;
  onTopConfirm?: boolean;
  hideCancelButton?: boolean;
  accept?: boolean;
}

const ConfirmAccept: FunctionComponent<IConfirmAcceptProps> = ({
  bid,
  title,
  caption,
  htmlCaption,
  bidData,
  onClose,
  onConfirm,
  mortgageData,
  onConfirmText,
  hideWarning,
  hideCancelButton,
  onTopConfirm,
  accept,
}): ReactElement<any> => {
  const navigate = useNavigate();
  const [mortgage, setMortgage] = useState<any>([]);
  const [role] = useLocalStorage("role");
  const [financedAcepted, setFinancedAcepted] = useState(false);
  const [dataBid, setDataBid] = useState(bidData);

  useEffect(() => {
    setMortgage(mortgageData);
  }, [mortgageData]);

  return (
    <Dialog open={true} maxWidth="xs" fullWidth={true} onClose={onClose}>
      {mortgage.document_status !== "ready" && role !== "client" && (
        <PendingDocuments />
      )}

      {(mortgage.document_status === "ready" || role === "client") && (
        <Box className={styles.DialogDetails}>
          <Typography
            variant="h5"
            align="center"
            color="primary"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          <Divider component="br" />

          {htmlCaption ? (
            <Typography
              variant="caption"
              dangerouslySetInnerHTML={{ __html: htmlCaption }}
            ></Typography>
          ) : (
            <Typography variant="caption">{caption}</Typography>
          )}
          <Divider component="br" />
          <span>{onTopConfirm}</span>

          {onTopConfirm && (
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {!hideCancelButton && (
                <Button
                  variant="outlined"
                  // sx={{ marginRight: "1rem" }}
                  onClick={() => {
                    return onClose ? onClose() : "";
                  }}
                >
                  Cancelar y Volver
                </Button>
              )}

              <Button variant="contained" onClick={() => onConfirm(dataBid)}>
                {onConfirmText}
              </Button>
            </Box>
          )}
          <Typography
            align="center"
            className={styles.DialogDetails}
            sx={{ fontWeight: "bold" }}
          >
            Detalles de la oferta
          </Typography>
          {bidData.bidder.bidder_type === "bank" && (
            <Table size="small">
              <TableBody>
                <TableRow hover>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Banco
                  </TableCell>
                  {role === "bank" ? (
                    <TableCell align="center">
                      {bidData.bid_for?.name}
                    </TableCell>
                  ) : (
                    <TableCell align="center" className="censure">
                      {bidData.bid_for?.name}
                    </TableCell>
                  )}
                </TableRow>
                {bidData.interest_data?.mode === "fijo" &&
                  role === "client" && (
                    <TableRow>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Cuota Mensual (estimada)
                      </TableCell>
                      <TableCell align="center">
                        {bidData.calculated?.monthly_repr}€
                      </TableCell>
                    </TableRow>
                  )}

                {bidData.interest_data?.mode === "mixed" ||
                bidData.interest_data?.mode === "mixto" ? (
                  <MixTypeInterest
                    data={bidData.interest_data.interest}
                    hideInterest={false}
                  />
                ) : (
                  <TableRow hover>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Interés
                    </TableCell>
                    <TableCell align="center">
                      {bidData.calculated?.monthly_repr}€
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}

          {bidData.bidder.bidder_type === "broker" && (
            <React.Fragment>
              <Table size="small">
                <TableBody>
                  {role === "client" && (
                    <TableRow hover>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Asesor Financiero
                      </TableCell>
                      <TableCell align="center">
                        Asesor{/* {splitName(bidData.bidder.name)} */}
                      </TableCell>
                    </TableRow>
                  )}

                  {bidData.interest_data?.mode === "mixed" ||
                  bidData?.interest_data?.mode === "mixto" ? (
                    <MixTypeInterest
                      data={bidData.interest_data.interest}
                      hideInterest={false}
                    />
                  ) : (
                    <TableRow hover>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Interés
                      </TableCell>
                      <TableCell align="center">
                        {bidData.interest_rate}%
                      </TableCell>
                    </TableRow>
                  )}

                  {bidData.interest_data?.mode === "fijo" &&
                    role === "client" && (
                      <TableRow>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>
                          Cuota Mensual (estimada)
                        </TableCell>
                        <TableCell align="center">
                          {bidData.calculated?.monthly_repr}€
                        </TableCell>
                      </TableRow>
                    )}
                  <TableRow hover>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Honorarios
                    </TableCell>
                    <TableCell align="center">
                      {bidData.general_fees.consulting_fees}€
                    </TableCell>
                  </TableRow>
                  {dataBid.accept_financed_commission && financedAcepted && (
                    <TableRow hover>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Cuota mensual de la financiación
                      </TableCell>
                      <TableCell align="center">
                        {getMonthlyComission(
                          bidData.general_fees.consulting_fees,
                          6
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {bidData.general_fees.finance_fees && !accept && (
                <ToggleFinanceFee
                  checked={financedAcepted}
                  onChange={() => {
                    setFinancedAcepted((prevState) => {
                      dataBid.accept_financed_commission = !prevState;

                      return !prevState;
                    });

                    setDataBid({ ...dataBid });
                  }}
                />
              )}
            </React.Fragment>
          )}

          <Divider component="br" />

          {validateConditions(bidData.conditions_details) ? (
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Lista de Bonificaciones
              </Typography>
              <Divider component="br" />
              <Table size="small">
                <TableBody>
                  {bidData.conditions_details
                    ?.filter((bonus: any) => {
                      return bonus.allow === true;
                    })
                    .map((c: any, i: number) => {
                      return (
                        <TableRow hover key={i}>
                          <TableCell align="left" className={styles.Title}>
                            {c.bonificationName}
                          </TableCell>
                          <TableCell align="center" className={styles.Content}>
                            {c.interest_rate}%
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <Divider component="br" />
              <Divider component="br" />
            </Box>
          ) : (
            <NotResults
              title="Sin bonificaciones"
              caption="No se agregaron bonificaciones a esta puja"
              resize={true}
            />
          )}

          {mortgage.document_status !== "ready" && !hideWarning && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="warning" className={styles.AlertInfo}>
                Estás apunto de aceptar esta oferta tu documentación será
                verificada, por favor asegurate de cargar todos los documentos
                necesarios, puedes ver los documentos que necesitas cargar{" "}
                <Link
                  className={styles.links}
                  underline="none"
                  onClick={() => navigate("/client/profile/documents")}
                >
                  aquí
                </Link>
                &nbsp;🤗.
              </Alert>
            </Stack>
          )}
        </Box>
      )}

      <Box className={styles.DialogDetails}>
        {onClose && !onTopConfirm && (
          <Button
            variant="outlined"
            sx={{ marginRight: "1rem" }}
            onClick={() => {
              return onClose ? onClose() : "";
            }}
          >
            Cerrar
          </Button>
        )}

        {(mortgage.document_status === "ready" || role === "client") &&
          !onTopConfirm && (
            <Button variant="contained" onClick={() => onConfirm(dataBid)}>
              {onConfirmText}
            </Button>
          )}
      </Box>
    </Dialog>
  );
};
ConfirmAccept.defaultProps = {
  onConfirmText: "Finalizar",
  hideWarning: false,
};

const PendingDocuments = () => {
  return (
    <Box className={styles.DialogDetails}>
      <Typography
        variant="h5"
        align="center"
        color="primary"
        sx={{ marginBottom: "2rem" }}
      >
        Aún faltan documentos
      </Typography>

      <img
        src="/assets/img/sources/in_verification_accepted.svg"
        alt=""
        width="60%"
        style={{
          margin: "auto",
          display: "block",
        }}
      />
      <Divider component="br" />
      <Typography variant="caption" align="center">
        El usuario no ha completado el proceso de carga de documentos, en cuanto
        estén validados todos los documentos podrás <b>Aprobar</b> la oferta
      </Typography>
    </Box>
  );
};

export default ConfirmAccept;
