import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Typography, Container } from "@mui/material";
import { format } from "date-fns";
import {
  useAuthenticated,
  useLocalStorage,
  useProfile,
} from "../../../shared/hooks";
// import { parseISO, format } from "date-fns";
import { useParams } from "react-router-dom";
import { sendTagEvent } from "utils/analytics";
import { MainClientLayout } from "../../../shared/layouts";
import {
  PageTitle,
  Breadcrumbs,
  BidResume,
  BidFiles,
  MortgageStepTimeline,
} from "../../../shared/components";

import styles from "./styles.module.scss";
import {
  StepTimeline,
  getStepLines,
} from "shared/components/MortgageStepTimeline/utils";
import useMortgage from "shared/hooks/useMortgage";
import useBidById from "shared/hooks/useBidById";
import { FloatingWap } from "shared/components/FabGroup";

/**deprecated */
const MortgageCompleted: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const location = useLocation();
    const navigate = useNavigate();
    const profile = useProfile().profile;
    const [role] = useLocalStorage("role");
    const { id } = useParams<{ id?: string }>();
    const [steps, setSteps] = useState<StepTimeline[]>([]);
    const [stepStatus, setStatusStep] = useState<any>("");
    const { bid, mortgage } = useBidById(id);

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "client");

    useEffect(() => {
      if (mortgage) {
        if (mortgage.evaluation) {
          let result = getStepLines(mortgage.evaluation);
          const data = result.resSteps;
          setStatusStep(result.lastStepResult);
          setSteps(data);
        }
      }
    }, [mortgage]);

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    return (
      <MainClientLayout>
        <>
          {mortgage && (
            <React.Fragment>
              <Container className={styles.MortgageCompletedWrapper}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ marginBottom: "1rem" }}
                  >
                    <Breadcrumbs
                      parents={[
                        {
                          href: "/client/mortgages",
                          title: "Mis solicitudes",
                        },
                      ]}
                    >
                      <Typography color="text.gray">
                        Detalle de subasta
                      </Typography>
                    </Breadcrumbs>
                  </Grid>

                  {/* TODO: Is not working correctly with the API */}
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ marginBottom: "2rem", marginTop: "2rem" }}
                  >
                    {steps.length > 0 && (
                      <MortgageStepTimeline
                        infoIcon={true}
                        status={stepStatus}
                        steps={steps}
                        statusAlert={true}
                      />
                    )}
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <PageTitle title="Resumen" />
                    <BidResume
                      bid={bid}
                      data={mortgage}
                      mode="client"
                      option="financial_entity"
                    />
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <PageTitle title="Documentación" />
                    <BidFiles mortgage={mortgage} />
                  </Grid>
                </Grid>
              </Container>
              {mortgage.status === "closed_successfully" && <FloatingWap />}
            </React.Fragment>
          )}
        </>
      </MainClientLayout>
    );
  };

export default MortgageCompleted;
