import { FunctionComponent, ReactComponentElement, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { NotAuthLayout } from "../../shared/layouts";
import { useLocalStorage } from "../../shared/hooks";
import { userHomeURL } from "../../utils/helpers";
import { MortgageForm } from "../../shared/components";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import { M_MODE } from "shared/constants/localStorage";

const SignUp: FunctionComponent<{}> = (props): ReactComponentElement<any> => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [bToken] = useLocalStorage("access_token");
  const [role] = useLocalStorage("role");

  useEffect(() => {
    if (bToken !== "") {
      navigate(userHomeURL(role));
    }
  }, [bToken, navigate, role]);

  useEffect(() => {
    // debugger;
    const paramsMortgageMode = searchParams.get(M_MODE);
    if (paramsMortgageMode) {
      localStorage.setItem(M_MODE, paramsMortgageMode);
    }
    if (location.search.indexOf("r") !== -1) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params?.r) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
          r: params.r,
        });
      }

      if (params?.error) {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado 😞, intenta iniciar sesión nuevamente y en caso de que sigas teniendo problemas, por favor ponte en contacto con nosotros. error(Supe)",
        );
      }
    } else {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, []);

  return (
    <NotAuthLayout>
      <MortgageForm initialStep={0} />
    </NotAuthLayout>
  );
};

export default SignUp;
