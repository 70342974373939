import { useState, useEffect } from "react";

const useAuthenticated = (
  cb: (f: any) => void,
  requiredRole: [] | string | any
) => {
  const [role] = useState<string | null | any>(() =>
    localStorage.getItem("role")
  );
  const [bearer] = useState<string | null | any>(() =>
    localStorage.getItem("access_token")
  );

  useEffect(() => {
    const ac = new AbortController();

    if (
      (role === requiredRole || requiredRole.indexOf(role) !== -1) &&
      bearer != null
    ) {
      cb(true);
    } else {
      cb(false);
    }

    return () => ac.abort();
  }, [role, bearer, requiredRole, cb]);
};

export default useAuthenticated;
