import * as yup from "yup";
import { requiredStringSchema } from "utils/yupValidation";
import { userSchema } from "../../validation/user";

export const stepOneValidationSchema = yup.object().shape({
  solicitor_type: requiredStringSchema("Por favor seleccione una opción"),
  user: userSchema,
  user_2: yup.object().when("solicitor_type", {
    is: (val: any) => {
      return Number(val) === 2;
    },
    then(schema) {
      return userSchema;
    },
    otherwise(schema) {
      return schema.nullable();
    },
  }),
});
