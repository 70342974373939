import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  Divider,
  TextField,
  Slider,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
} from "@mui/material";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import styles from "../styles.module.scss";
import { formatToRate } from "../../../../utils/helpers";

type Props = {
  schema: any;
  timeMortgage: number;
  selectValueOne: string;
  selectValueTwo: string;
  onChangeMixedInput: (bid: any, value: any) => void;
  onChangeSelectInput: (bid: any, value: any) => void;
  onChangeSlideInput: (bid: any) => void;
  isAutoBidding?: boolean;
};

export default function MixedInterest({
  schema,
  onChangeMixedInput,
  timeMortgage,
  selectValueOne,
  selectValueTwo,
  onChangeSelectInput,
  onChangeSlideInput,
  isAutoBidding,
}: Props) {
  const handledDataInput = (data: any, value: string) => {
    onChangeMixedInput(data, value);
  };

  const handledSelectInput = (data: any, value: string) => {
    onChangeSelectInput(data, value);
  };

  return (
    <>
      <Divider />
      <Divider component="br" />
      <Typography variant="h6" className={styles.Title}>
        Tasa de Interés
        <Typography
          mt={1}
          color="text.secondary"
          display="block"
          variant="caption"
        >
          Primer Período
        </Typography>
      </Typography>

      <Box>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            height: "100%",
          }}
        >
          <Grid item xs={12} md={8}>
            <ReactScrollWheelHandler preventScroll={true}>
              <FormControl fullWidth size="small">
                <TextField
                  data-cy="interest_value_period_1"
                  label="Interés"
                  type="text"
                  variant="outlined"
                  value={schema.interest_data.interest[0].rate}
                  error={
                    schema.interest_data.interest[0].rate === "" ||
                    isNaN(schema.interest_data.interest[0].rate) ||
                    parseFloat(schema.interest_data.interest[0].rate) >= 10
                      ? true
                      : false
                  }
                  onChange={({ target }) => {
                    //   setMixInterest(
                    //     formatToRate(target.value),
                    //     "initial_interest"
                    //   );
                    //   setSchema({ ...schema });
                    handledDataInput(
                      formatToRate(target.value),
                      "initial_interest"
                    );
                  }}
                  onBlur={({ target }) => {
                    if (parseFloat(target.value) === 0) {
                      target.value = "";
                      handledDataInput(
                        formatToRate(target.value),
                        "initial_interest"
                      );
                    }
                  }}
                  inputProps={{
                    step: "0.01",
                    min: 1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {""}
                        <TrendingUpIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {(schema.interest_data.interest[0].rate === "" ||
                  parseFloat(schema.interest_data.interest[0].rate) >= 10) && (
                  <FormHelperText error={true}>
                    {schema.interest_data.interest[0].rate === "" &&
                      "Debe ingresar el valor del interés"}
                    {parseFloat(schema.interest_data.interest[0].rate) >= 10 &&
                      "El valor máximo para el tipo de interés es 10%"}
                  </FormHelperText>
                )}
              </FormControl>
            </ReactScrollWheelHandler>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  data-cy="interest_type_period_1"
                  id="demo-simple-select"
                  label="Age"
                  value={selectValueOne}
                  onChange={(event) => {
                    handledSelectInput(event, "initial_step_interest");
                    // setMixInterest(event, "initial_step_interest");
                  }}
                >
                  <MenuItem value="fijo">Fijo</MenuItem>
                  <MenuItem value="variable">Variable</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              color="text.secondary"
              display="block"
              variant="caption"
            >
              Segundo Período
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box>
              <ReactScrollWheelHandler preventScroll={true}>
                <FormControl fullWidth size="small">
                  <TextField
                    label="Interés"
                    type="text"
                    variant="outlined"
                    value={schema.interest_data.interest[1].rate}
                    data-cy="interest_value_period_2"
                    error={
                      schema.interest_data.interest[1].rate === "" ||
                      isNaN(schema.interest_data.interest[1].rate) ||
                      parseFloat(schema.interest_data.interest[1].rate) >= 10
                        ? true
                        : false
                    }
                    onChange={({ target }) => {
                      onChangeMixedInput(
                        formatToRate(target.value),
                        "final_interest"
                      );
                    }}
                    onBlur={({ target }) => {
                      if (parseFloat(target.value) === 0) {
                        target.value = "";

                        onChangeMixedInput(
                          formatToRate(target.value),
                          "final_interest"
                        );
                      }
                    }}
                    inputProps={{
                      step: "0.01",
                      min: 0,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {""}
                          <TrendingUpIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {(schema.interest_data.interest[1].rate === "" ||
                    parseFloat(schema.interest_data.interest[1].rate) >=
                      10) && (
                    <FormHelperText error={true}>
                      {schema.interest_data.interest[1].rate === "" &&
                        "Debe ingresar el valor del interés"}
                      {parseFloat(schema.interest_data.interest[1].rate) >=
                        10 && "El valor máximo para el tipo de interés es 10%"}
                    </FormHelperText>
                  )}
                </FormControl>
              </ReactScrollWheelHandler>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  data-cy="interest_type_period_2"
                  inputProps={{ readOnly: true }}
                  value={selectValueTwo}
                  onChange={(event) => {
                    handledSelectInput(event, "final_step");
                    // setMixInterest(event, "final_step");
                  }}
                  renderValue={(value) => `${value}`}
                />
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography
                color="text.secondary"
                display="block"
                variant="caption"
              >
                Tiempo de interés (años)
              </Typography>
              <Slider
                size="small"
                min={timeMortgage - timeMortgage}
                max={timeMortgage}
                data-cy="interest_term"
                aria-label="Small"
                // marks={marks}
                valueLabelDisplay="auto"
                value={schema.interest_data.interest[0].to_year}
                onChange={(event: Event, newValue: number | number[]) => {
                  schema.interest_data.interest[0].to_year = newValue;

                  schema.interest_data.interest[0].duration = newValue;

                  schema.interest_data.interest[1].from_year = newValue;

                  schema.interest_data.interest[1].to_year = timeMortgage;

                  schema.interest_data.interest[1].duration =
                    timeMortgage - schema.interest_data.interest[1].from_year;
                  onChangeSlideInput(schema);
                }}
              />
              <LabeledValue
                label="Primer tramo"
                value={`${schema.interest_data.interest[0].to_year} años`}
              />
              {!isAutoBidding && (
                <>
                  <LabeledValue
                    label="Segundo período"
                    value={`${
                      timeMortgage - schema.interest_data.interest[0].to_year
                    } años`}
                  />
                  <LabeledValue
                    label="Duración total de la hipoteca"
                    value={`${timeMortgage} años`}
                  />
                </>
              )}
              {isAutoBidding && (
                <Alert severity="info">
                  El segundo tramo o período se calculará en base a la duración de la
                  hipoteca.
                </Alert>
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider component="br" />
      </Box>

      <Divider />

      <Divider component="br" />
    </>
  );
}

function LabeledValue({ label, value }: { label: string; value: string }) {
  return (
    <Typography
      color="text.secondary"
      display="block"
      variant="caption"
      fontStyle={{ fontWeight: "bold" }}
    >
      {label}:{" "}
      <Typography
        className="label-time-interest"
        color="text.secondary"
        display="inline"
        variant="caption"
      >
        {value}
      </Typography>
    </Typography>
  );
}
