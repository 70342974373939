import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import {
  Button,
  ButtonProps,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { differenceInMonths, format } from "date-fns";
import { InfoOutlined } from "@mui/icons-material";
import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Path, useFieldArray, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DialogWarning from "shared/components/DialogWarning";
import FormFields from "shared/components/FormFields";
import WithTooltip from "shared/components/WithTooltip";
import { useInterestRate } from "shared/hooks";
import useDialog from "shared/hooks/useDialog";
import { IncomesList, MortgageFormData } from "shared/types/postMortgageData";
import { sendTagEvent } from "utils/analytics";
import { formatLocalCurrency } from "utils/helpers";
import { aboveZeroTest, requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";
import HandleSolicitorView from "../HandleSolicitorView";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { incomeCardOptions, expensiveCardOptions } from "../StepFour/utils";
import CustomDialog from "shared/components/CustomDialog";
import { validarArregloDeudas } from "../../utils";

function RevenueForm() {
  const { mortgageFormData, onStepClick } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onStepClick()}
      onBack={onStepClick(false)}
      title="¿Cuáles son tus ingresos y gastos mensuales?"
      resolver={yupResolver(
        yup.object({
          data: yup.object({
            debtRatio: yup
              .number()
              .positive("No cumple con el ratio de endeudamiento"),
          }),
          revenue: yup.object().shape({
            incomes_list: yup.array().of(
              yup.object().shape({
                monthly_income: requiredStringSchema().test(aboveZeroTest),
                name: requiredStringSchema(),
                solicitor: requiredStringSchema(),
              })
            ),
            expenses_list: yup.array().of(
              yup.object().shape({
                debt_monthly: requiredStringSchema().test(aboveZeroTest),
                // debt_total: requiredStringSchema().test(aboveZeroTest),
                debt_duration: requiredStringSchema(),
                name: requiredStringSchema(),
                solicitor: requiredStringSchema(),
              })
            ),
            monthly: requiredStringSchema(),
          }),
        })
      )}
    >
      <Stack
        spacing={3}
        height="100%"
        paddingTop={1}
        alignItems="center"
        width="100%"
      >
        <RevenueInfo />
        <HandleSolicitorView
          RenderComponent={RevenueFormHandler}
          individualProps={{ solicitor: "1" }}
          conjuntaProps={{ solicitor: "2" }}
        />
      </Stack>
    </StepLayout>
  );
}
function RevenueFormHandler({
  solicitor,
}: {
  /**this is not the form solicitor_type value, is the value of the tabs in views at that moment */
  solicitor: "1" | "2";
}) {
  return (
    <Stack
      id="revenu-cards"
      direction="row"
      flexWrap="wrap"
      justifyContent="center"
      gap={2}
      width="100%"
      paddingTop={2}
      sx={{
        transformOrigin: "center top",
        transform: "scale(0.8)",
        "&>*": {
          width: "300px",
        },
      }}
    >
      <IncomeSection
        solicitor={solicitor}
        stackProps={{ sx: { flexGrow: 1, maxWidth: "450px" } }}
      />
      <ExpenseSection
        solicitor={solicitor}
        stackProps={{ sx: { flexGrow: 1, maxWidth: "450px" } }}
      />
    </Stack>
  );
}

function RevenueInfo() {
  const revenueDialogRef = useRef<{ openDialog: () => void }>();
  const { mortgageAllowedCalculator } = useInterestRate();
  const { watch, getValues, setValue } = useFormContext<MortgageFormData>();
  const [dataAmount, dataPeriod, interestType] = getValues([
    "data.amount",
    "data.period",
    "data.interest_type",
  ]);
  const [
    incomesList,
    expensesList,
  ] = watch([
    "revenue.incomes_list",
    "revenue.expenses_list",
  ]);

  const totalExpense = expensesList.reduce((acc, val) => {
    return acc + Number(val.debt_monthly);
  }, 0);
  const revenueMonthly = incomesList.reduce((acc, val) => {
    if (val.solicitor) {
      return acc + Number(val.monthly_income);
    }    

    return acc;
  }, 0);
  useEffect(() => {
    setValue("revenue.monthly", revenueMonthly);
  }, [revenueMonthly]);

  const { debtRatio, isAllowed } = mortgageAllowedCalculator({
    dataAmount,
    dataPeriod,
    expensesList,
    interestType,
    revenueMonthly,
  });
  useEffect(() => {
    if (isAllowed) {
      setValue("data.debtRatio", debtRatio);
    } else {
      setValue("data.debtRatio", -1);
    }
  }, [debtRatio, isAllowed]);
  return (
    <>
      <RevenueDialogCalculator
        ref={revenueDialogRef}
        debtRatio={debtRatio}
        isAllowed={isAllowed}
      />
      <Stack
        // direction={matches ? "column" : "row"}
        direction="row"
        divider={
          <Divider
            // orientation={matches ? "horizontal" : "vertical"}
            orientation="vertical"
            variant="middle"
            flexItem
          />
        }
        justifyContent="center"
        gap={2}
        sx={(theme) => ({
          // zoom: `calc(min(1, 100vw / ${theme.breakpoints.values.sm} * 0.7))`,
        })}
      >
        <Stack alignItems="center">
          <Typography variant="h6" color="primary" textAlign="center">
            {formatLocalCurrency(revenueMonthly)}
          </Typography>
          <AutoFontSizeLabel>Ingresos totales</AutoFontSizeLabel>
        </Stack>
        <Stack alignItems="center">
          <Typography variant="h6" color="primary" textAlign="center">
            {formatLocalCurrency(totalExpense)}
          </Typography>
          <AutoFontSizeLabel>Gastos totales</AutoFontSizeLabel>
        </Stack>
        <Stack alignItems="center">
          <Typography
            variant="h6"
            color={debtRatio === 0 || isAllowed ? "primary" : "error"}
            textAlign="center"
          >
            {debtRatio}%
          </Typography>
          <WithTooltip
            title="El Ratio de Endeudamiento es la proporción de tus ingresos que ocupan tus gastos. 
Por ejemplo: si tu salario neto es de 1.000€ y tus deudas totales son de 200€, tu ratio de endeudamiento será de un 20% (200€ / 1.000€). Por Ley tu ratio de endeudamiento no puede ser mayor a 35%
Para hacer este cálculo, adicionamos un valor de cuota de hipoteca referencia según tus datos ingresdos."
            position="left"
          >
            <AutoFontSizeLabel>Ratio de Endeudamiento</AutoFontSizeLabel>
          </WithTooltip>
        </Stack>
      </Stack>
    </>
  );
}

function IncomeSection({
  solicitor,
  stackProps,
}: {
  solicitor: "1" | "2";
  stackProps?: StackProps;
}) {
  const { control, watch } = useFormContext<MortgageFormData>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "revenue.incomes_list",
  });
  const solicitorType = watch("solicitor_type");

  const addIncomeCard = (solicitorView: "1" | "2" = "1") => {
    append({
      monthly_income: "",
      name: "",
      solicitor: solicitorView,
    });
  };
  useEffect(() => {
    if (fields.length === 0) {
      const initialIncomes = [
        {
          monthly_income: "",
          name: "",
          solicitor: "1",
        },
      ];
      if (Number(solicitorType) === 2) {
        initialIncomes.push({
          monthly_income: "",
          name: "",
          solicitor: "2",
        });
      }
      replace(initialIncomes);
    }
  }, [fields]);
  return (
    <Stack id="income-cards" {...stackProps} gap={1}>
      <Typography>Concepto del ingreso</Typography>
      <Stack gap={2} divider={<Divider variant="middle" />}>
        {fields.map((field, index) => {
          if (Number(field.solicitor) !== Number(solicitor)) return null;
          return (
            <IncomeCard
              key={field.id}
              index={index}
              onRemove={() => remove(index)}
            />
          );
        })}
      </Stack>
      <Button
        variant="text"
        startIcon={<Add />}
        onClick={() => {
          addIncomeCard(solicitor);
        }}
        sx={{ justifyContent: "flex-start" }}
      >
        Añadir otro ingreso existente
      </Button>
    </Stack>
  );
}

interface RevenueCardProps {
  index: number;
  onRemove: () => void;
}

function IncomeCard({ index, onRemove }: RevenueCardProps) {
  const MONTHLY_INCOME_STRING = `revenue.incomes_list[${index}].monthly_income`;
  const { setValue, getValues, watch } = useFormContext<MortgageFormData>();
  const monthlyIncome = watch(MONTHLY_INCOME_STRING as Path<MortgageFormData>);

  const sumMonthlyincome = (acc: number, val: IncomesList) => {
    return acc + Number(val.monthly_income);
  };
  const setUserSalary = (solicitor: "1" | "2") => {
    const incomesList = getValues("revenue.incomes_list");
    const fieldName = solicitor === "1" ? "user.salary" : "user_2.salary";
    const userSalary = incomesList
      .filter((i) => i.solicitor === solicitor)
      .reduce(sumMonthlyincome, 0);
    setValue(fieldName, userSalary);
  };
  const setUserSalaries = () => {
    setUserSalary("1");
    setUserSalary("2");
  };
  useEffect(() => {
    setUserSalaries();
  }, [monthlyIncome]);

  const removeHandler = () => {
    onRemove();
    setUserSalaries();
  };

  return (
    <Stack gap={2} padding={1}>
      <WithInfoDialog
        dialogContent={
          <Stack spacing={2}>
            <Typography color="primary" variant="h6" textAlign="center">
              ¿Necesitas ayuda para saber cuál es tu salario neto?
              <br /> ¡Colibid al rescate!
            </Typography>
            <Typography textAlign="center">
              Los ingresos netos son aquellos que recibes efectivmente cada mes
              luego de descontar los impuestos y deducciones.Si tienes 12 pagas,
              muy sencillo: es la cantidad que ingresas cada mes. Y si tienes
              una o varias pagas extra: indica el resulta de sumar todas y
              dividirlas entre 12
            </Typography>
          </Stack>
        }
      >
        <FormFields.AutoCompleteInput
          options={incomeCardOptions}
          name={`revenue.incomes_list[${index}].name`}
          label="Nombre del Ingreso Mensual "
          placeholder="Salario, Negocio, Renta..."
        />
      </WithInfoDialog>
      <WithInfoDialog>
        <FormFields.CurrencyFieldWithWrapperText
          name={MONTHLY_INCOME_STRING}
          label="Ingreso Mensual"
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            autoComplete: "off",
          }}
        />
      </WithInfoDialog>
      {!!index && (
        <DelBtn onClick={removeHandler}>eliminar este ingreso</DelBtn>
      )}
    </Stack>
  );
}

function ExpenseSection({
  solicitor,
  stackProps,
}: {
  solicitor: "1" | "2";
  stackProps?: StackProps;
}) {
  const { control } = useFormContext<MortgageFormData>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "revenue.expenses_list",
  });

  useEffect(() => {
    const fieldClear = fields.filter((f) => validarArregloDeudas(f));

    if (fieldClear.length < fields.length) {
      replace(fieldClear);
    }
  }, []);

  const addExpense = (solicitor: "1" | "2" = "1") => {
    append({
      debt_monthly: "",
      debt_total: "",
      debt_duration: "",
      name: "",
      solicitor,
    });
  };

  return (
    <Stack id="expense-cards" {...stackProps} gap={1}>
      <Typography>Concepto del gasto</Typography>
      <Stack gap={2} divider={<Divider variant="middle" />}>
        {fields.map((field, index) => {
          if (Number(field.solicitor) !== Number(solicitor)) return null;
          return (
            <ExpenseCard
              key={field.id}
              index={index}
              onRemove={() => remove(index)}
            />
          );
        })}
      </Stack>
      <Button
        variant="text"
        startIcon={<Add />}
        sx={{ justifyContent: "flex-start" }}
        onClick={() => {
          addExpense(solicitor);
        }}
      >
        Añadir otro gasto existente
      </Button>
    </Stack>
  );
}

function ExpenseCard({ index, onRemove }: RevenueCardProps) {
  const MONTHLY_DEBT_STRING = useMemo(
    () =>
      `revenue.expenses_list[${index}].debt_monthly` as unknown as Path<MortgageFormData>,
    []
  );
  const DEBT_DURATION_STRING = useMemo(
    () =>
      `revenue.expenses_list[${index}].debt_duration` as unknown as Path<MortgageFormData>,
    []
  );
  const DEBT_TOTAL_STRING = useMemo(
    () =>
      `revenue.expenses_list[${index}].debt_total` as unknown as Path<MortgageFormData>,
    []
  );
  const { setValue, getValues, watch } = useFormContext<MortgageFormData>();
  const [debtMonthly, debtDuration, debtTotal] = watch([
    MONTHLY_DEBT_STRING,
    DEBT_DURATION_STRING,
    DEBT_TOTAL_STRING,
  ]);

  useEffect(() => {
    if (Number(debtMonthly) > 0 && debtDuration) {
      const now = new Date();
      const endDate = new Date(debtDuration);
      const initDate = new Date(
        `${format(now, "yyyy-MM")}-${format(endDate, "dd")}`
      );

      const laps = differenceInMonths(endDate, initDate);
      const debt_total = Number(laps || 1) * Number(debtMonthly);
      if (debtTotal !== debt_total) {
        setValue(DEBT_TOTAL_STRING, debt_total);
      }
    }
  }, [debtMonthly, debtDuration]);

  return (
    <Stack gap={2} padding={1}>
      <WithInfoDialog>
        <FormFields.AutoCompleteInput
          options={expensiveCardOptions}
          name={`revenue.expenses_list[${index}].name`}
          label="Nombre de la deuda"
          placeholder="Coche, préstamo, vacaciones..."
        />
      </WithInfoDialog>
      <WithInfoDialog>
        <FormFields.DatePicker
          label=" Fecha prevista de liquidación de la deuda *"
          name={`revenue.expenses_list[${index}].debt_duration`}
          disablePast={true}
        />
      </WithInfoDialog>
      <WithInfoDialog>
        <FormFields.CurrencyFieldWithWrapperText
          name={`revenue.expenses_list[${index}].debt_monthly`}
          label="Cuota de pago mensual"
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            autoComplete: "off",
          }}
        />
      </WithInfoDialog>
      <DelBtn onClick={onRemove}>eliminar este gasto</DelBtn>
    </Stack>
  );
}

function DelBtn({
  onClick,
  children,
}: Pick<ButtonProps, "onClick" | "children">) {
  return (
    <Button
      variant="text"
      color="error"
      onClick={onClick}
      startIcon={<Delete />}
    >
      {children || "eliminar"}
    </Button>
  );
}

const calculatorUrl = "https://colibid.com/calculadora-de-hipoteca/";
const aspireCalculatorUrl =
  "https://colibid.com/calculadora-cuanto-podria-llegar-solicitar/";

const RevenueDialogCalculator = forwardRef(
  (
    { isAllowed, debtRatio }: { isAllowed: boolean; debtRatio: number },
    ref
  ) => {
    const { isShowing, toggle, openDialog, closeDialog } = useDialog();
    const navigate = useNavigate();

    useEffect(() => {
      if (debtRatio > 0 && !isAllowed) {
        openDialog();
      }
    }, [isAllowed, debtRatio]);
    useImperativeHandle(ref, () => ({
      openDialog: () => {
        openDialog();
      },
      closeDialog: () => {
        closeDialog();
      },
    }));

    const { stepViews, setStep, mortgageFormDispatch } =
      useMortgageFormContext();
    const handledCLickCalculatorDialog = () => {
      // const propertyAccesorSteps = ["data.amount", "property.value"];
      // const propertyStep = stepViews.find((v) => {
      //   const { accesor } = v;
      //   if (!accesor) return;
      //   if (Array.isArray(accesor)) {
      //     return accesor.some((a) => propertyAccesorSteps.includes(a.path));
      //   }
      //   return propertyAccesorSteps.includes(accesor.path);
      // });
      // if (propertyStep) {
      // this caused a infinite loop on root of REvenue form, cause it will take the selected view and try to render it
      // it was fix adding a guard that check the comp name is not RevenueForm but it sometime also cause a loop, by this time we'll disabled this whole code
      //   mortgageFormDispatch({
      //     type: "SET_SELECTED_STEPVIEW",
      //     payload: propertyStep,
      //   });
      // }
      closeDialog();
    };
    const handledCLickAspireCalculatorDialog = () => {
      sendTagEvent({
        event: "app_formulario_redirecto_to_calculator",
        method: "email",
      });
      window.open(aspireCalculatorUrl, "_blank");
      navigate("/client/home");
    };
    return (
      <DialogWarning
        buttonDisagree="Ir a calcular hipoteca máxima"
        handledClickDisagree={handledCLickAspireCalculatorDialog}
        buttonAgree="Revisar datos de hipoteca"
        handledClickAgree={handledCLickCalculatorDialog}
        ShowDialog={isShowing}
        closeOnOutside={(event, reason) => {
          toggle();
        }}
        closeIcon={true}
        title="¿Tús ingresos están correctos?"
      >
        <>
          <Typography variant="body2" gutterBottom>
            Según los datos que has ingresado, el importe de tus deudas
            (incluida la hipoteca que estás solicitando aquí) supera el 40% de
            tus ingresos netos disponibles.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Para el valor de propiedad que has ingresado, debes aportar más
            ahorros y así reducir el importe de la hipoteca.
          </Typography>
          <Typography variant="body2" gutterBottom>
            ¿Otra opción? Puedes ver si hay una propiedad que se ajuste mejor.
          </Typography>
        </>
      </DialogWarning>
    );
  }
);

function WithInfoDialog({
  dialogContent,
  children,
  position,
}: {
  dialogContent?: ReactNode;
  children: ReactNode;
  position?: "left" | "right";
}) {
  const { isShowing, openDialog, closeDialog } = useDialog();
  return (
    <Stack direction={position === "left" ? "row" : "row-reverse"}>
      <IconButton
        onClick={openDialog}
        sx={{ visibility: dialogContent ? "visible" : "hidden" }}
      >
        <InfoOutlined color="primary" sx={{ width: 24, height: 24 }} />
      </IconButton>
      {dialogContent && (
        <CustomDialog open={isShowing} onClose={closeDialog}>
          {dialogContent}
        </CustomDialog>
      )}
      {children}
    </Stack>
  );
}

function AutoFontSizeLabel(props: TypographyProps) {
  return (
    <Typography
      {...props}
      color="GrayText"
      textAlign="center"
      fontSize="calc(11px + (14 - 11) * ((100vw - 320px) / (1600 - 320)))"
    >
      {props.children}
    </Typography>
  );
}
export default RevenueForm;
