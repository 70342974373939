import {
  memo,
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";

import { Box, TextField, InputAdornment } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ICellEditorParams } from "ag-grid-community";
import { es } from "date-fns/locale";
import { isStrADate } from "utils";

export default memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const [value, setValue] = useState<Date | string>(props.value.value);
    const refInput = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
      };
    });

    const onChangeListener = useCallback((value) => {
      if(value && isStrADate(value)){
        setValue(value.toISOString());
      } else {
        setValue(value)
      } 
    }, []);

    useEffect(() => {
      if (refInput.current?.focus) {
        refInput.current.focus();
      }
    }, []);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <MobileDatePicker
          disablePast
          inputRef={refInput}
          reduceAnimations={true}
          showToolbar={false}
          value={value}
          onChange={onChangeListener}
          onAccept={(v) => {
            props.api.stopEditing();
          }}
          onClose={() => {
            props.api.stopEditing();
          }}
          renderInput={(params) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: 150,
              }}
            >
              <TextField
                {...params}
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        />
      </LocalizationProvider>
    );
  })
);
