import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { yesNoOption } from "utils/valueLabelOptions";
import { requiredStringSchema } from "utils/yupValidation";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";

function PropertyHasEndormentForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
    title="¿Tienes o podrías conseguir aval?"
    resolver={yupResolver(
      yup.object({
        property: yup.object({
          hasEndorsement: requiredStringSchema(
            "Por favor seleccione una opción"
          ),
        }),
      })
    )}
  >
    <FormFields.ListSelector
      name="property.hasEndorsement"
      options={yesNoOption}
      onClick={onNext}
    />
  </StepLayout>

  );
}
export default PropertyHasEndormentForm;
