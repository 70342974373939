import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";

import Countdown from "react-countdown";
import { parseISO } from "date-fns";

import styles from "./styles.module.scss";

export interface ITimerProps {
  date: string;
}
const Timer: FunctionComponent<ITimerProps> = (props): ReactElement<any> => {
  const [datetime, setDatetime] = useState(props.date);

  useEffect(() => {
    setDatetime(props.date);
  }, [props.date]);

  return (
    <Countdown
      date={parseISO(datetime)}
      renderer={({ days, hours, minutes }) => (
        <div className={styles.Countdown}>
          {days}d : {hours}h : {minutes}m
        </div>
      )}
    />
  );
};

export default Timer;
