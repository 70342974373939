import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";

import {
  Box,
  Dialog,
  Grid,
  Container,
  FormControl,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Tooltip,
} from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import InfoIcon from "@mui/icons-material/InfoRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GavelIcon from "@mui/icons-material/Gavel";
import Select, { SelectProps } from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import {
  formatCurrency,
  getUserProfessions,
  getRequestType,
} from "../../../utils/helpers";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { comunidades } from "../MortgageForm/data/spanish_comunities.json";
import {
  spainComunitiesAndProvices,
  SpainComunitiesAndProvices,
} from "../../components/MortgageForm/data/spain_cominities_provinces";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import styles from "./styles.module.scss";
import { defaultFiltersForTypes, valoresEnRango } from "./helpers";
import { useBanks, useDocuments } from "shared/hooks";
import { useLocalStorage } from "shared/hooks";
import { ROLE } from "shared/constants/localStorage";
import { EMPLOYMENT_SITUATIONS } from "../MortgageForm/utils";
import { DefaultFilters, allowFilterValuesKeys } from "./utils";
import {
  ConstructionTypeFilters,
  ExpandMore,
  FilterRangeSelected,
  HipotecaFilter,
  InterestFilter,
  MortgageTypeFilter,
  PropertyExistenceFilters,
} from "./components";
import SelectFilter from "./components/SelectFilter";
import {
  MORTGAGE_AMOUNT_RANGES,
  PROPERTY_VALUE_RANGES,
  USER_AGE_RANGE,
} from "shared/constants/mortgageForm";

export interface IApplicationsFilterModalProps {
  defaultFilters: any;
  show: boolean;
  readOnly: boolean;

  onSubmit: (data: any) => void;
  onClose: () => void;
  onFiltering: (status: boolean) => void;
  onClearFilter: () => void;
}

const ApplicationsFilterModal: FunctionComponent<
  IApplicationsFilterModalProps
> = (props): ReactElement<any> => {
  const [isClean, setIsClean] = useState<boolean>(false);
  const [expandFilterEconomy, setExpandFilterEconomy] = useState(false);
  const [expandFilterPersonalInformation, setExpandFilterPersonalInformation] =
    useState(false);
  const [expandFiltersLocation, setExpandFiltersLocation] = useState(false);
  const [expandFilterAuction, setExpandFilterAuction] = useState(false);
  const [expandDocumentationFilter, setExpandDocumentationFilter] =
    useState(false);
  const [expandFinancialsEntitiesFilter, setexpandFinancialsEntitiesFilter] =
    useState(false);
  const [disabledButton, setButtonDisabled] = useState(false);

  const [filters, setFilters] = useState<DefaultFilters>(props.defaultFilters);
  const [provinces, setProvinces] = useState<any>([]);
  const { banks, addBroker, removeBroker } = useBanks();
  const [role] = useLocalStorage(ROLE);
  const { documents } = useDocuments();
  const clear = () => {
    props.onClearFilter();
    setIsClean(true);
    // If not solo reading
    if (!props.readOnly) {
      localStorage.removeItem("applications_filters");
    }

    props.onFiltering(false);
  };

  const formaterFilter = ({
    condition,
    key,
    data,
    id,
  }: {
    condition: boolean;
    key: string;
    data: Array<any>;
    id: string;
  }) => {
    if (condition) {
      props.defaultFilters[key] = data.filter((d: any) =>
        props.defaultFilters[key].includes(d[id])
      );
    }
  };

  useEffect(() => {
    if (props.defaultFilters && props.readOnly) {
      if (props.defaultFilters.financialsEntities.length > 0) {
        props.defaultFilters.financialsEntities = banks.filter((bank: any) =>
          props.defaultFilters.financialsEntities.includes(bank.name)
        );
      }

      if (
        props.defaultFilters.mortgageFiles.length > 0 &&
        documents.length > 0
      ) {
        props.defaultFilters.mortgageFiles = documents.filter((file: any) =>
          props.defaultFilters.mortgageFiles.includes(file.code)
        );
      }

      if (props.defaultFilters.userWork.length > 0) {
        props.defaultFilters.userWork = EMPLOYMENT_SITUATIONS.filter(
          (file: any) => {
            return props.defaultFilters.userWork.includes(parseInt(file.id));
          }
        );
      }

      if (props.defaultFilters.user2Work.length > 0) {
        props.defaultFilters.user2Work = EMPLOYMENT_SITUATIONS.filter(
          (file: any) => {
            return props.defaultFilters.user2Work.includes(parseInt(file.id));
          }
        );
      }
    }
    setFilters(props.defaultFilters);
  }, [props.defaultFilters]);

  useEffect(() => {
    if (banks.length > 0 && role === "broker") {
      addBroker();
    }
  }, [banks]);

  useEffect(() => {
    if ([2, 3].some((r) => filters.mortgageType.includes(r))) {
      changeValue("propertyExistence", []);
    }
  }, [filters.mortgageType]);

  useEffect(() => {
    const userAgeRange = [USER_AGE_RANGE.min, USER_AGE_RANGE.max];
    const disabled =
      valoresEnRango(
        [MORTGAGE_AMOUNT_RANGES.GLOBAL.MIN, MORTGAGE_AMOUNT_RANGES.GLOBAL.MAX],
        filters.mortgageValue
      ) &&
      valoresEnRango(
        [PROPERTY_VALUE_RANGES.GLOBAL.MIN, PROPERTY_VALUE_RANGES.GLOBAL.MAX],
        filters.homeValue
      ) &&
      valoresEnRango([0, 40], filters.debtRatio) &&
      valoresEnRango([0, 45000], filters.monthlyIncome) &&
      valoresEnRango([0, 15000], filters.monthlyExpenses) &&
      valoresEnRango(userAgeRange, filters.userAge) &&
      valoresEnRango(userAgeRange, filters.user2_Age);
    setButtonDisabled(disabled);

    // Validar importe de hipoteca
  }, [filters]);

  const submit = () => {
    if (!isClean) {
      // localStorage.setItem("applications_filters", JSON.stringify(filters));

      props.onFiltering(true);
    }

    props.onSubmit(filters);
  };

  const changeValue = (key: string, value: any, active?: boolean) => {
    setFilters((oldFilters) => {
      if (key in allowFilterValuesKeys && active !== undefined) {
        const filterKey =
          allowFilterValuesKeys[key as keyof typeof allowFilterValuesKeys];
        const updatedFilters = { ...oldFilters, [filterKey]: active };
        return { ...updatedFilters, [key]: value };
      } else {
        return { ...oldFilters, [key]: value };
      }
    });

    setIsClean(false);
  };

  const getAllProvinces = (comunity: Array<string>) => {
    if (comunity.length > 0) {
      let provin: Array<string> = [];
      let comunities = comunity.map((item) => {
        let values =
          spainComunitiesAndProvices[item as keyof SpainComunitiesAndProvices];
        provin.push(...values);
        setProvinces(provin);
      });
    } else {
      setProvinces([]);
      changeValue("mortgagesProvinces", []);
    }
  };

  const onDeleteProvinces = (provinces: Array<string>) => {
    let comunities = filters.mortgagesCommunities;
    let filterComunities: any[] = [];
    if (comunities.length > 0) {
      comunities.forEach((element: any) => {
        provinces.every((province: string) => {
          if (
            spainComunitiesAndProvices[
              element as keyof SpainComunitiesAndProvices
            ].includes(province)
          ) {
            return true;
          } else {
            filterComunities.push(element);

            return false;
          }
        });
      });
    }

    if (filterComunities.length > 0) {
      comunities = comunities.filter(
        (com: any) => !filterComunities.includes(com)
      );

      changeValue("mortgagesCommunities", comunities);
    }

    if (provinces.length === 0) {
      setProvinces([]);
      changeValue("mortgagesCommunities", []);
    }
  };

  const onDeleteComunities = (data: any) => {
    let provinces =
      spainComunitiesAndProvices[data as keyof SpainComunitiesAndProvices];

    let prov = filters.mortgagesProvinces.filter((item: any) => {
      return !provinces.includes(item);
    });

    if (prov.length > 0) {
      changeValue("mortgagesProvinces", prov);
    }
  };
  return (
    <Dialog
      open={props.show}
      onClose={() => props.onClose()}
      maxWidth="lg"
      fullWidth={true}
    >
      <Box className={styles.DialogDetails}>
        <Container>
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
                Filtros
              </Typography>

              <IconButton
                aria-label="close"
                onClick={() => props.onClose()}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 30,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              <Divider />
            </Grid>
            <Grid item md={4} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
              <HipotecaFilter filters={filters} changeValue={changeValue} />

              <MortgageTypeFilter
                filters={filters}
                readOnly={props.readOnly}
                changeValue={changeValue}
              />

              {!props.readOnly && (
                <>
                  <Divider component="br" />
                  <InterestFilter
                    filters={filters}
                    readOnly={props.readOnly}
                    changeValue={changeValue}
                  />
                </>
              )}

              <Divider component="br" />
              {![2, 3].some((r) => filters.mortgageType.includes(r)) && (
                <PropertyExistenceFilters
                  filters={filters}
                  readOnly={props.readOnly}
                  changeValue={changeValue}
                />
              )}
            </Grid>

            <Grid item md={4} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
              {/* this filter wait for rafa if he considered indispensable */}
              <ConstructionTypeFilters
                filters={filters}
                readOnly={props.readOnly}
                changeValue={changeValue}
              />
              <Divider component="br" />
              <Typography
                variant="h6"
                sx={{ marginBottom: "1rem" }}
                className={styles.tittleFilter}
              >
                <AccountBalanceWalletIcon
                  sx={{
                    color: "#656565",
                    marginRight: "1rem",
                  }}
                />
                Economía familiar
                <ExpandMore
                  expand={expandFilterEconomy}
                  onClick={() => setExpandFilterEconomy(!expandFilterEconomy)}
                  aria-expanded={expandFilterEconomy}
                  aria-label="show more"
                  style={{
                    float: "right",
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Typography>

              <Collapse in={expandFilterEconomy} timeout="auto" unmountOnExit>
                <FilterRangeSelected
                  title="Ratio de endeudamiento"
                  name="debtRatio"
                  minFilterSelected={0}
                  maxFilterSelected={40}
                  stepFilterSelected={1}
                  sliderValue={filters.debtRatio}
                  inputOneValue={filters.debtRatio[0]}
                  inputTwoValue={filters.debtRatio[1]}
                  filtersSelected={filters}
                  labelFilterSelected={(value) => {
                    return `${value}%`;
                  }}
                  onChangeFilter={(data, active) => {
                    changeValue("debtRatio", data, active);
                  }}
                  onChangeInputOne={(data, active) => {
                    let value = [data, filters.debtRatio[1]];
                    changeValue("debtRatio", value, active);
                  }}
                  onChangeInputTwo={(data, active) => {
                    let value = [filters.debtRatio[0], data];
                    changeValue("debtRatio", value, active);
                  }}
                />
                <Divider component="br" />
                <FilterRangeSelected
                  title="Ingresos Mensuales"
                  name="monthlyIncome"
                  minFilterSelected={0}
                  maxFilterSelected={45000}
                  stepFilterSelected={100}
                  sliderValue={filters.monthlyIncome}
                  inputOneValue={filters.monthlyIncome[0]}
                  inputTwoValue={filters.monthlyIncome[1]}
                  filtersSelected={filters}
                  labelFilterSelected={(value) => {
                    return formatCurrency(value);
                  }}
                  onChangeFilter={(data, active) => {
                    changeValue("monthlyIncome", data, active);
                  }}
                  onChangeInputOne={(data, active) => {
                    let value = [data, filters.monthlyIncome[1]];
                    changeValue("monthlyIncome", value, active);
                  }}
                  onChangeInputTwo={(data, active) => {
                    let value = [filters.monthlyIncome[0], data];
                    changeValue("monthlyIncome", value, active);
                  }}
                />
                <Divider component="br" />

                <FilterRangeSelected
                  title="Deudas Mensuales"
                  name="monthlyExpenses"
                  minFilterSelected={0}
                  maxFilterSelected={15000}
                  stepFilterSelected={100}
                  sliderValue={filters.monthlyExpenses}
                  inputOneValue={filters.monthlyExpenses[0]}
                  inputTwoValue={filters.monthlyExpenses[1]}
                  filtersSelected={filters}
                  labelFilterSelected={(value) => {
                    return formatCurrency(value);
                  }}
                  onChangeFilter={(data, active) => {
                    changeValue("monthlyExpenses", data, active);
                  }}
                  onChangeInputOne={(data, active) => {
                    let value = [data, filters.monthlyExpenses[1]];
                    changeValue("monthlyExpenses", value, active);
                  }}
                  onChangeInputTwo={(data, active) => {
                    let value = [filters.monthlyExpenses[0], data];
                    changeValue("monthlyExpenses", value, active);
                  }}
                />
                <Divider component="br" />
              </Collapse>

              <Typography
                variant="h6"
                className={styles.tittleFilter}
                sx={{ marginBottom: "1rem" }}
              >
                <PersonIcon
                  sx={{
                    color: "#656565",
                    marginRight: "1rem",
                  }}
                />
                Información personal
                <Tooltip
                  title="Puedes filtrar por la información del solicitante principal o el acompañante."
                  placement="right-end"
                  className={styles.filterTooltip}
                >
                  <InfoIcon
                    sx={{
                      color: "#656565",
                      width: "1em",
                      height: "1em",
                      fontSize: "0.9rem",
                      margin: "0 .1rem",
                    }}
                  />
                </Tooltip>
                <ExpandMore
                  expand={expandFilterPersonalInformation}
                  onClick={() =>
                    setExpandFilterPersonalInformation(
                      !expandFilterPersonalInformation
                    )
                  }
                  aria-expanded={expandFilterPersonalInformation}
                  aria-label="show more"
                  style={{
                    float: "right",
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Typography>

              <Collapse
                in={expandFilterPersonalInformation}
                timeout="auto"
                unmountOnExit
              >
                {/* TODO aval y declaracion renta */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(filters["property.hasEndorsement"])}
                      onChange={(e) => {
                        if (e.target.checked) {
                          changeValue("property.hasEndorsement", 1);
                        } else {
                          changeValue("property.hasEndorsement", 0);
                        }
                      }}
                    />
                  }
                  label="Solicitante con aval"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(filters["user.has_last_year_tax"])}
                      onChange={(e) => {
                        if (e.target.checked) {
                          changeValue("user.has_last_year_tax", 1);
                        } else {
                          changeValue("user.has_last_year_tax", 0);
                        }
                      }}
                    />
                  }
                  label="Solicitante con declaración de renta"
                />
                <FormControl sx={{ width: "100%", mb: 2, mt: 2 }} size="small">
                  <InputLabel>Tipo Solicitud</InputLabel>
                  <Select
                    value={filters.requestType || ""}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      const Partner = value === 2;

                      if (!Partner) {
                        changeValue("user2Profession", "");
                        changeValue("user2Work", []);
                        changeValue("user2_Age", [18, 65], false);

                        changeValue("typeDocUser2", "");
                      }

                      changeValue("requestType", value);
                    }}
                    input={<OutlinedInput label="Tipo Solicitud" />}
                  >
                    {getRequestType().map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="subtitle1">
                  Solicitante Principal
                </Typography>
                <Divider component="br" />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(filters["user.residence_permit"])}
                      onChange={(e) => {
                        if (e.target.checked) {
                          changeValue("user.residence_permit", 1);
                        } else {
                          changeValue("user.residence_permit", 0);
                        }
                      }}
                    />
                  }
                  label="Residencia permanente"
                />
                <FormControl sx={{ width: "100%", mb: 2 }} size="small">
                  <InputLabel>Tipo documento del Solicitante</InputLabel>
                  <Select
                    value={filters.typeDocUser1 || ""}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      changeValue("typeDocUser1", value);
                    }}
                    input={
                      <OutlinedInput label="Tipo documento del Solicitante" />
                    }
                  >
                    <MenuItem key={0} value="DNI">
                      DNI
                    </MenuItem>
                    <MenuItem key={1} value="NIE">
                      NIE
                    </MenuItem>
                  </Select>
                </FormControl>
                <UserResidenceFilter
                  value={filters["user.residence"] || ""}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    changeValue("user.residence", value);
                  }}
                />
                <FormControl sx={{ width: "100%", mb: 2 }} size="small">
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={EMPLOYMENT_SITUATIONS}
                    getOptionLabel={(option: any) =>
                      option ? option.description : ""
                    }
                    value={filters.userWork || ""}
                    onChange={(data: any, value: any) => {
                      changeValue("userWork", value);
                    }}
                    filterSelectedOptions
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.description}
                        </li>
                      );
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Situación Laboral" />
                    )}
                  />
                </FormControl>

                <FormControl sx={{ width: "100%", gap: 1 }} size="small">
                  <InputLabel>Profesión</InputLabel>
                  <Select
                    value={filters.userProfession || ""}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      changeValue("userProfession", value);
                    }}
                    input={<OutlinedInput label="Profesión" />}
                  >
                    {getUserProfessions().map((item) => (
                      <MenuItem key={item.id} value={item.description}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                  <FilterRangeSelected
                    name="userAge"
                    title="Edad"
                    filtersSelected={filters}
                    showInputs
                    minFilterSelected={USER_AGE_RANGE.min}
                    maxFilterSelected={USER_AGE_RANGE.max}
                    sliderValue={filters.userAge}
                    inputOneValue={filters.userAge[0]}
                    inputTwoValue={filters.userAge[1]}
                    labelFilterSelected={(value) => {
                      return `${value} años`;
                    }}
                    onChangeFilter={(data, active) => {
                      changeValue("userAge", data, active);
                    }}
                    onChangeInputOne={(data, active) => {
                      let value = [data, filters.userAge[1]];
                      changeValue("userAge", value, active);
                    }}
                    onChangeInputTwo={(data, active) => {
                      let value = [filters.userAge[0], data];
                      changeValue("userAge", value, active);
                    }}
                  />
                </FormControl>
                <Divider component="br" />
                {filters.requestType === 2 && (
                  <React.Fragment>
                    <Typography variant="subtitle1">Acompañante</Typography>
                    <Divider component="br" />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(filters["user_2.residence_permit"])}
                          onChange={(e) => {
                            if (e.target.checked) {
                              changeValue("user_2.residence_permit", 1);
                            } else {
                              changeValue("user_2.residence_permit", 0);
                            }
                          }}
                        />
                      }
                      label="Residencia permanente"
                    />
                    <FormControl
                      sx={{ width: "100%", mb: 2, mt: 2 }}
                      size="small"
                    >
                      <InputLabel>Tipo documento del Acompañante</InputLabel>
                      <Select
                        value={filters.typeDocUser2 || ""}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;

                          changeValue("typeDocUser2", value);
                        }}
                        input={
                          <OutlinedInput label="Tipo documento del Acompañate" />
                        }
                      >
                        <MenuItem key={0} value="DNI">
                          DNI
                        </MenuItem>
                        <MenuItem key={1} value="NIE">
                          NIE
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <UserResidenceFilter
                      value={filters["user_2.residence"] || ""}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        changeValue("user_2.residence", value);
                      }}
                    />
                    <FormControl sx={{ width: "100%", mb: 2 }} size="small">
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={EMPLOYMENT_SITUATIONS}
                        getOptionLabel={(option: any) =>
                          option ? option.description : ""
                        }
                        value={filters.user2Work || ""}
                        onChange={(data: any, value: any) => {
                          changeValue("user2Work", value);
                        }}
                        filterSelectedOptions
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.description}
                            </li>
                          );
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Situación Laboral del Acompañante"
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl sx={{ width: "100%", mb: 1 }} size="small">
                      <InputLabel>Profesión</InputLabel>
                      <Select
                        value={filters.user2Profession || ""}
                        onChange={(event) => {
                          const {
                            target: { value },
                          } = event;

                          changeValue("user2Profession", value);
                        }}
                        input={<OutlinedInput label="Situación Laboral" />}
                      >
                        {getUserProfessions().map((item) => (
                          <MenuItem key={item.id} value={item.description}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <Divider component="br" />
                    </FormControl>
                    <FilterRangeSelected
                      title="Edad del Acompañante"
                      name="user2_Age"
                      showInputs
                      minFilterSelected={18}
                      maxFilterSelected={65}
                      stepFilterSelected={2}
                      sliderValue={filters.user2_Age}
                      inputOneValue={filters.user2_Age[0]}
                      inputTwoValue={filters.user2_Age[1]}
                      filtersSelected={filters}
                      labelFilterSelected={(value) => {
                        return `${value} años`;
                      }}
                      onChangeFilter={(data, active) => {
                        changeValue("user2_Age", data, active);
                      }}
                      onChangeInputOne={(data, active) => {
                        let value = [data, filters.user2_Age[1]];
                        changeValue("user2_Age", value, active);
                      }}
                      onChangeInputTwo={(data, active) => {
                        let value = [filters.user2_Age[0], data];
                        changeValue("user2_Age", value, active);
                      }}
                    />
                  </React.Fragment>
                )}

                <Divider component="br" />
              </Collapse>

              <Typography
                variant="h6"
                sx={{ marginBottom: "1rem" }}
                className={styles.tittleFilter}
              >
                <LocationOnIcon
                  sx={{
                    color: "#656565",
                    marginRight: "1rem",
                  }}
                />
                Ubicación
                <ExpandMore
                  expand={expandFiltersLocation}
                  onClick={() =>
                    setExpandFiltersLocation(!expandFiltersLocation)
                  }
                  aria-expanded={expandFiltersLocation}
                  aria-label="show more"
                  style={{
                    float: "right",
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Typography>
              <Collapse in={expandFiltersLocation} timeout="auto" unmountOnExit>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={comunidades.map((community) => community.nombre)}
                  getOptionLabel={(option: any) => option}
                  value={filters.mortgagesCommunities}
                  onChange={(data: any, value: any, reason: string) => {
                    if (reason === "removeOption") {
                      onDeleteComunities(
                        data.currentTarget.parentElement.innerText
                      );
                    }

                    changeValue("mortgagesCommunities", value);

                    getAllProvinces(value);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Comunidades"
                      placeholder="Comunidades"
                    />
                  )}
                />
                <Divider component="br" />
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={provinces.map((province: any) => province)}
                  getOptionLabel={(option: any) => {
                    return option;
                  }}
                  value={filters.mortgagesProvinces}
                  onChange={(data: any, value: any, reason: string) => {
                    if (reason === "removeOption") {
                      onDeleteProvinces(value);
                    }

                    changeValue("mortgagesProvinces", value);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Provincias"
                      placeholder="Provincias"
                    />
                  )}
                />
              </Collapse>

              {/* <FundUsedForFilters
                filters={filters}
                readOnly={props.readOnly}
                changeValue={changeValue}
              /> */}
            </Grid>

            <Grid item md={4} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
              <Tooltip
                title="Filtra por la cantidad de pujas que ha recibido una oferta o por el tiempo restante"
                placement="right-end"
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "1rem" }}
                  className={styles.tittleFilter}
                >
                  <GavelIcon
                    sx={{
                      color: "#656565",
                      marginRight: "1rem",
                    }}
                  />
                  Subasta
                  <InfoIcon
                    sx={{
                      color: "#656565",
                      width: "1em",
                      height: "1em",
                      fontSize: "0.9rem",
                      margin: "0 .1rem",
                    }}
                    className={styles.filterTooltip}
                  />
                  <ExpandMore
                    expand={expandFilterAuction}
                    onClick={() => setExpandFilterAuction(!expandFilterAuction)}
                    aria-expanded={expandFilterAuction}
                    aria-label="show more"
                    style={{
                      float: "right",
                    }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Typography>
              </Tooltip>

              <Collapse in={expandFilterAuction} timeout="auto" unmountOnExit>
                <FilterRangeSelected
                  title="Número de pujas"
                  name="mortgageBidsAmount"
                  showInputs={false}
                  minFilterSelected={0}
                  maxFilterSelected={15}
                  stepFilterSelected={1}
                  sliderValue={filters.mortgageBidsAmount}
                  inputOneValue={filters.mortgageBidsAmount[0]}
                  inputTwoValue={filters.mortgageBidsAmount[1]}
                  filtersSelected={filters}
                  onChangeFilter={(data, active) => {
                    changeValue("mortgageBidsAmount", data, active);
                  }}
                  onChangeInputOne={(data, active) => {
                    let value = [data, filters.mortgageBidsAmount[1]];
                    changeValue("mortgageBidsAmount", value, active);
                  }}
                  onChangeInputTwo={(data, active) => {
                    let value = [filters.mortgageBidsAmount[0], data];
                    changeValue("mortgageBidsAmount", value, active);
                  }}
                />
                <Divider component="br" />

                <FilterRangeSelected
                  title="Tiempo restante (días)"
                  name="mortgageRemaining"
                  showInputs={false}
                  minFilterSelected={0}
                  maxFilterSelected={10}
                  stepFilterSelected={1}
                  sliderValue={filters.mortgageRemaining}
                  inputOneValue={filters.mortgageRemaining[0]}
                  inputTwoValue={filters.mortgageRemaining[1]}
                  filtersSelected={filters}
                  onChangeFilter={(data, active) => {
                    changeValue("mortgageRemaining", data, active);
                  }}
                  onChangeInputOne={(data, active) => {
                    let value = [data, filters.mortgageRemaining[1]];
                    changeValue("mortgageRemaining", value, active);
                  }}
                  onChangeInputTwo={(data, active) => {
                    let value = [filters.mortgageRemaining[0], data];
                    changeValue("mortgageRemaining", value, active);
                  }}
                />

                {/*  <Typography>Tiempo restante (días)</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  size="small"
                  sx={{ marginBottom: "1rem" }}
                  step={1}
                  min={0}
                  max={10}
                  value={filters.mortgageRemaining}
                  valueLabelFormat={(c) => {
                    return `${c} días`;
                  }}
                  onChange={(event, newValue) => {
                    changeValue("mortgageRemaining", newValue);
                  }}
                /> */}
                <Divider component="br" />
              </Collapse>

              <Typography
                variant="h6"
                sx={{ marginBottom: "1rem" }}
                className={styles.tittleFilter}
              >
                <TaskIcon
                  sx={{
                    color: "#656565",
                    marginRight: "1rem",
                  }}
                />
                Documentación aportada
                <Tooltip
                  title="Si te interesa conocer un documento en específico puedes agregarlo a la lista y se te mostrarán todas las hipotecas que tengan al menos uno de estos documentos."
                  placement="right-end"
                  className={styles.filterTooltip}
                >
                  <InfoIcon
                    sx={{
                      color: "#656565",
                      width: "1em",
                      height: "1em",
                      fontSize: "0.9rem",
                      margin: "0 .1rem",
                    }}
                  />
                </Tooltip>
                <ExpandMore
                  expand={expandDocumentationFilter}
                  onClick={() =>
                    setExpandDocumentationFilter(!expandDocumentationFilter)
                  }
                  aria-expanded={expandDocumentationFilter}
                  aria-label="show more"
                  style={{
                    float: "right",
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Typography>
              <Collapse in={expandDocumentationFilter}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={documents}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  value={filters.mortgageFiles}
                  onChange={(data: any, value: any) => {
                    changeValue("mortgageFiles", value);
                  }}
                  filterSelectedOptions
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Documentos"
                      placeholder="Documentos"
                    />
                  )}
                />
              </Collapse>

              <Typography
                variant="h6"
                sx={{ marginBottom: "1rem" }}
                className={styles.tittleFilter}
              >
                <DeleteForeverIcon
                  sx={{
                    color: "#656565",
                    fontSize: "28px",
                    marginRight: "1rem",
                  }}
                />
                Excluir entidades
                <Tooltip
                  title="Sino te interesa trabajar con una entidad financiera en específico puedes agregarla a la lista y la búsqueda descartará aquella entidad de los resultados"
                  placement="right-end"
                  className={styles.filterTooltip}
                >
                  <InfoIcon
                    sx={{
                      color: "#656565",
                      width: "1em",
                      height: "1em",
                      fontSize: "0.9rem",
                      margin: "0 .1rem",
                    }}
                  />
                </Tooltip>
                <ExpandMore
                  expand={expandFinancialsEntitiesFilter}
                  onClick={() =>
                    setexpandFinancialsEntitiesFilter(
                      !expandFinancialsEntitiesFilter
                    )
                  }
                  aria-expanded={expandFinancialsEntitiesFilter}
                  aria-label="show more"
                  style={{
                    float: "right",
                  }}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Typography>
              <Collapse in={expandFinancialsEntitiesFilter}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  // options={(() => [OTHER_BROKER, ...banks])()}
                  options={banks}
                  value={filters.financialsEntities}
                  onChange={(data: any, value: any) => {
                    changeValue("financialsEntities", value);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  getOptionLabel={(option: any) => (option ? option.name : "")}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entidades financieras"
                      placeholder="BBVA, Abanca, Otros brókers, etc."
                    />
                  )}
                />
              </Collapse>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ marginBottom: "1rem", textAlign: "right" }}
          >
            <Divider /> <Divider component="br" />
            <Button
              variant="text"
              sx={{ marginRight: "1rem" }}
              onClick={() => clear()}
            >
              Limpiar Filtros
            </Button>
            <Button
              variant="contained"
              disabled={!disabledButton}
              onClick={() => submit()}
            >
              Aplicar Filtros
            </Button>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
};

export interface titleFilterBadge {
  title?: string;
}

function UserResidenceFilter({
  value,
  onChange,
}: Pick<SelectProps, "value" | "onChange">) {
  return (
    <SelectFilter title="Residencia" value={value} onChange={onChange}>
      <MenuItem key={0} value="España">
        Residente
      </MenuItem>
      <MenuItem key={1} value="exclude España">
        No Residente
      </MenuItem>
    </SelectFilter>
  );
}
export default ApplicationsFilterModal;
