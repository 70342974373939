import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = { mortgageId?: string } & ButtonProps;

export default function GoToManageDocumentsById({
  mortgageId,
  ...rest
}: Props) {
  const navigate = useNavigate();
  return (
    <Button
      {...rest}
      variant="outlined"
      onClick={() => {
        navigate(`/client/profile/documents?mid=${mortgageId}`);
      }}
      sx={{ mr: "4px" }}
    >
      Subir Documentos
    </Button>
  );
}
