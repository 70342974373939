import { ReactNode, createContext } from "react";
import { MortgageFormData } from "shared/types/postMortgageData";
import { ProgressGroupNode } from "../components/ProgressCard";
import { StepView } from "../stepBuilder";
import useMortgageForm from "../hooks/useMortgageForm";

export interface MortgageFormContextProps extends ReturnType<typeof useMortgageForm>{
  mortgageFormData: MortgageFormData;
  progressGroupNodes: ProgressGroupNode[];
  activeStep: number;
  isLastStep: boolean;
  isUserLogin: boolean;
  stepViews: StepView<MortgageFormData>[];
  onSubmit: () => void;
  onStepClick: (next?: boolean) => (form?: MortgageFormData) => void;
  onNext: (form?: MortgageFormData) => void;
  onBack: (form?: MortgageFormData) => void;
} 

export const MortgageFormContext = createContext<
  MortgageFormContextProps | undefined
>(undefined);

//because we want to use this in the form children level,
// the state is at the parent level so it need to pass it a value
function MortgageFormProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: MortgageFormContextProps;
}) {
  return (
    <MortgageFormContext.Provider value={value}>
      {children}
    </MortgageFormContext.Provider>
  );
}

export default MortgageFormProvider;
