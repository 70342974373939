import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  StepLabel,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  ReactComponentElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { MortgageRes, TimeLineStep } from "shared/types/BaseApiResponse";
import { Bid, BidTimeLine } from "shared/types/Bid";
import styles from "../../styles.module.scss";
import BidProgress from "../BidProgress";
import { CustomStepIcon, PPStepperData } from "../../../PPSteppers";
import { censureChars, formatLocalCurrency } from "utils/helpers";
import { isFileAlreadyUpdated } from "utils";
import usePPReducer from "../../../../usePPReducer";
import { getComissionValue } from "utils/bids";

interface bidOfertCardComponent {
  id: string;
  onClickOffert: (id: string) => void;
  bid: Bid;
  onViewOffert: (bid: Bid) => void;
  mortgageCard: MortgageRes;
  disabled: boolean;
  checked: boolean;
}

interface DatBid {
  nameEntity: string;
  interestInformation: string | ReactNode;
  bidStepIcon: string | any;
  bidStepLabel: string | any;
  progress: number;
  status: string;
}

const CardOffert = ({
  id,
  onClickOffert,
  bid,
  onViewOffert,
  disabled,
  mortgageCard,
  checked,
}: bidOfertCardComponent) => {
  const [checkedN, setChecked] = useState(checked);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [dataBid, setDataBid] = useState<DatBid>({
    nameEntity: "",
    interestInformation: "",
    bidStepLabel: "",
    bidStepIcon: "",
    progress: 0,
    status: "",
  });

  const [ppState, ppDispatch] = usePPReducer();
  const { mortgage, activeStep, currStep, completedStep, steps, bids, step } =
    ppState;

  useEffect(() => {
    ppDispatch({ type: "SET_MORTGAGE", payload: mortgageCard });
    ppDispatch({ type: "SET_BIDS", payload: mortgageCard.bids });
  }, []);

  useEffect(() => {
    if (mortgage && bids) {
      const bid = bids.filter((b) => b.id == id);
      const bidStepTimeline = bid[0].timeline.phases;

      const bidSteps = bidStepTimeline.map((stp: TimeLineStep) => {
        let stepp = {
          step: {
            description: stp.description ? stp.description : "",
            description2: stp.description ? stp.description : "",
            label: stp.label,
            name: stp.action,
          },
        };
        return stepp;
      });

      ppDispatch({
        type: "PUSH_STEPS",
        payload: bidSteps,
      });

      ppDispatch({
        type: "SET_STATE_STEPS",
        payload: {
          steps: bidStepTimeline,
          mortgage: mortgage, // use 'mortgage' instead of 'mortage'
          id: id,
        },
      });
    }
  }, [mortgage]);

  useEffect(() => {
    if (step) {
      setData();
    }
  }, [step]);

  const setData = () => {
    const validStates = ["contactado", "viable"];
    const stepCard = getStepTimeLine(bid.timeline);

    const updatedDataBid = {
      ...dataBid,
      nameEntity:
        bid.bidder.bidder_type === "broker"
          ? bid.bidder.name
          : bid.bid_for.name,
      interestInformation: getInterestTypeString(bid),
      bidStepIcon: step.action,
      bidStepLabel: step.label,
      progress: bid.timeline.progress.completed,
      status: step.status,
    };
    setDataBid(updatedDataBid);
  };

  const getStepTimeLine = (timeline: BidTimeLine) => {
    const current =
      timeline.current.status === "completed"
        ? timeline.phases.findIndex(
            (timeL) => timeL.action === timeline.current.action
          )
        : timeline.phases.findIndex(
            (timeL) => timeL.action === timeline.current.action
          );

    const actual = current !== -1 ? current : 0;

    // tasadora
    const isTasadoraUpdated = isFileAlreadyUpdated(
      mortgageCard,
      "Doc_tasadora"
    );
    const idxTasa = timeline.phases.findIndex(
      (timeL) => timeL.action === "tasadoraRealizada"
    );
    const lastStepTasadoraIsCompleted =
      timeline.phases[idxTasa - 1].status === "completed";
    const stepTasadora = timeline.phases[idxTasa].status === "completed";
    // esto es por q el actual timeline phases no esta sincronizado cuando se sube la tasadora
    if (isTasadoraUpdated && lastStepTasadoraIsCompleted && !stepTasadora) {
      return lastStepTasadoraIsCompleted
        ? timeline.phases[idxTasa + 1]
        : timeline.phases[actual];
    }

    // is step to omit
    const validStates = ["documents", "contactado", "viable"];

    const omitStep = validStates.includes(bid.timeline.current.action);

    if (omitStep) {
      const findDocumentsIndex = bid.timeline.phases.findIndex(
        (item) => item.action === "documents"
      );

      const isCompletedLastSteps =
        bid.timeline.current.action !== "contacto" &&
        bid.timeline.current.action !== "viable";

      return timeline.phases[findDocumentsIndex].status === "completed" &&
        !isCompletedLastSteps
        ? timeline.phases[findDocumentsIndex + 1]
        : timeline.phases[findDocumentsIndex];
    }

    return timeline.phases[actual].status === "completed"
      ? timeline.phases[actual + 1]
      : timeline.phases[actual];
  };

  const TitleIcon = dataBid.bidStepIcon
    ? PPStepperData[`${dataBid.bidStepIcon}`]?.icon
    : PPStepperData["documentos"]?.icon;

  const getInterestTypeString = (data: Bid) => {
    const isFixedOrVariable = ["fijo", "variable"].includes(
      data.interest_data.mode
    );
    const interest = isFixedOrVariable
      ? `${data.interest_data.rate}%  ${data.interest_data.mode}`
      : data.interest_data.interest
      ? `Mixto ${data.interest_data.interest[0].mode} ${data.interest_data?.interest[0].rate} ${data.interest_data?.interest[1].mode} ${data.interest_data?.interest[1].rate}`
      : "Mixto";

    const isVariable = data.interest_data.mode === "variable";

    const bonificationsCount = data.conditions_details.filter(
      (data) => data.allow === true
    ).length;
    const bonifications =
      bonificationsCount > 0
        ? `${bonificationsCount} bonificaciones`
        : `Sin bonificaciones`;
    const comission =
      getComissionValue(data.general_fees) > 0
        ? `Comisión ${formatLocalCurrency(
            getComissionValue(data.general_fees)
          )}`
        : "Sin comisiones";

    return (
      <>
        <p>
          {interest}
          {isVariable && (
            <Typography variant="subtitle1" color="darkgray">
              +Euribor
            </Typography>
          )}
        </p>
        <p>{bonifications}</p>
        <p>{comission}</p>
      </>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked: isCheckboxChecked } = event.target;
    setChecked(isCheckboxChecked);
    onClickOffert(id);
  };

  return (
    <Stack direction="row" spacing={2} mt="1rem">
      <Card
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={disabled ? styles.cardDisabled : ""}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          flexWrap="wrap"
          alignItems="center"
          spacing={2}
          padding="1rem"
          width="100%"
          display="grid"
          gridTemplateColumns={{ xs: "1fr ", sm: "1fr 1.5fr 1fr" }}
        >
          <Stack direction="row" gap={2} justifyContent="space-around">
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              className={`${styles.cardOfertTitle} ${styles.alignText}`}
            >
              {mortgageCard && mortgageCard.status !== "closed_successfully" ? (
                <span className="censure">
                  {censureChars(dataBid.nameEntity)}
                </span>
              ) : (
                <span className=""> {dataBid.nameEntity}</span>
              )}
            </Typography>

            <Typography
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              className={`${styles.cardOfertDescription} ${styles.alignText}`}
            >
              {dataBid.interestInformation}
            </Typography>
          </Stack>
          {isMobile && <Divider />}

          <Stack
            direction="row"
            gap={2}
            justifyContent="space-around"
            alignItems="center"
          >
            <Box width={{ xs: "auto", sm: "40%" }} className={styles.stepCard}>
              <StepLabel
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
                StepIconComponent={(props) =>
                  CustomStepIcon(
                    true,
                    TitleIcon
                  )({
                    ...props,
                    completed: Boolean(dataBid.status === "completed"),
                  })
                }
              >
                {dataBid.bidStepLabel}
              </StepLabel>
            </Box>

            <Box width={{ xs: "auto", sm: "100%" }}>
              <BidProgress value={dataBid.progress} />
            </Box>
          </Stack>

          <Stack
            direction="row"
            gap={2}
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            <Button
              variant="contained"
              size="small"
              disabled={disabled}
              onClick={() => {
                onViewOffert(bid);
              }}
            >
              ir a la oferta
            </Button>
          </Stack>
        </Stack>
      </Card>

      {!disabled && (
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Checkbox
            checked={checkedN}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
      )}
    </Stack>
  );
};

export default CardOffert;
