import {
  Typography,
  Collapse,
  Tooltip,
  Badge,
  TextField, TextFieldProps
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/InfoRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formatCurrency } from "utils/helpers";
import { useEffect, useState } from "react";
import ExpandMore from "./ExpandMore";

import styles from "../styles.module.scss";
import FilterRangeSelected from "./FilterRangeSelected";
import FilterRadio from "./FilterRadio";
import {
  MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
  MIN_DATA_PERIOD_YEARS,
  MORTGAGE_AMOUNT_RANGES,
  PROPERTY_VALUE_RANGES,
} from "shared/constants/mortgageForm";
import AllowSwitchFilter from "./AllowSwitchFilter";

type HipotecaFilterProps = {
  filters: any;
  changeValue: (key: string, value: any, active?: boolean) => void;
};

export default function HipotecaFilter({
  filters,
  changeValue,
}: HipotecaFilterProps) {
  const [expandFilterMortage, setExpandFilterMortage] = useState(false);

  const handleExpandClick = () => {
    setExpandFilterMortage(!expandFilterMortage);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{ marginBottom: "1rem" }}
        className={styles.tittleFilter}
      >
        <TitleFilter>
          <HomeIcon
            sx={{
              color: "#656565",
            }}
          />
        </TitleFilter>
        Hipoteca
        <Tooltip
          title="Toda la información relacionada con la hipoteca, tipo de solicitud, valor de propiedad, etc."
          placement="right-end"
          className={styles.filterTooltip}
        >
          <InfoIcon
            sx={{
              color: "#656565",
              width: "1em",
              height: "1em",
              fontSize: "0.9rem",
              margin: "0 .1rem",
            }}
          />
        </Tooltip>
        <ExpandMore
          expand={expandFilterMortage}
          onClick={handleExpandClick}
          aria-expanded={expandFilterMortage}
          aria-label="show more"
          style={{
            float: "right",
          }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      <Collapse in={expandFilterMortage} timeout="auto" unmountOnExit>
          <FilterRangeSelected
            title="Importe de la hipoteca"
            name="mortgageValue"
            minFilterSelected={MORTGAGE_AMOUNT_RANGES.GLOBAL.MIN}
            maxFilterSelected={MORTGAGE_AMOUNT_RANGES.GLOBAL.MAX}
            stepFilterSelected={10000}
            sliderValue={filters.mortgageValue}
            inputOneValue={filters.mortgageValue[0]}
            inputTwoValue={filters.mortgageValue[1]}
            filtersSelected={filters}
            onChangeFilter={(data, active) => {
              changeValue("mortgageValue", data, active);
            }}
            labelFilterSelected={(value) => {
              return formatCurrency(value);
            }}
            onChangeInputOne={(data, active) => {
              let value = [data, filters.mortgageValue[1]];
              changeValue("mortgageValue", value, active);
            }}
            onChangeInputTwo={(data, active) => {
              let value = [filters.mortgageValue[0], data];
              changeValue("mortgageValue", value, active);
            }}
          />

          <FilterRangeSelected
            title="Valor de la Propiedad"
            name="homeValue"
            minFilterSelected={PROPERTY_VALUE_RANGES.GLOBAL.MIN}
            maxFilterSelected={PROPERTY_VALUE_RANGES.GLOBAL.MAX}
            stepFilterSelected={10000}
            sliderValue={filters.homeValue}
            inputOneValue={filters.homeValue[0]}
            inputTwoValue={filters.homeValue[1]}
            labelFilterSelected={(value) => {
              return formatCurrency(value);
            }}
            filtersSelected={filters}
            onChangeFilter={(data, active) => {
              changeValue("homeValue", data, active);
            }}
            onChangeInputOne={(data, active) => {
              let value = [data, filters.homeValue[1]];
              changeValue("homeValue", value, active);
            }}
            onChangeInputTwo={(data, active) => {
              let value = [filters.homeValue[0], data];
              changeValue("homeValue", value, active);
            }}
          />
          <FilterRangeSelected
            showInputs={false}
            title="Duración de Hipoteca (años)"
            name="mortgagePeriod"
            minFilterSelected={MIN_DATA_PERIOD_YEARS}
            maxFilterSelected={MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO}
            stepFilterSelected={1}
            sliderValue={filters.mortgagePeriod}
            inputOneValue={filters.mortgagePeriod[0]}
            inputTwoValue={filters.mortgagePeriod[1]}
            filtersSelected={filters}
            labelFilterSelected={(value) => {
              return `${value} años`;
            }}
            onChangeFilter={(data, active) => {
              changeValue("mortgagePeriod", data, active);
            }}
            onChangeInputOne={(data, active) => {
              let value = [data, filters.mortgagePeriod[1]];
              changeValue("mortgagePeriod", value, active);
            }}
            onChangeInputTwo={(data, active) => {
              let value = [filters.mortgagePeriod[0], data];
              changeValue("mortgagePeriod", value, active);
            }}
          />
          <FilterRangeSelected
            title="Porcentaje de Hipoteca"
            name="mortgageRate"
            minFilterSelected={0}
            maxFilterSelected={100}
            stepFilterSelected={1}
            sliderValue={filters.mortgageRate}
            inputOneValue={filters.mortgageRate[0]}
            inputTwoValue={filters.mortgageRate[1]}
            filtersSelected={filters}
            showInputs={false}
            labelFilterSelected={(value) => {
              return `${value}%`;
            }}
            onChangeFilter={(data, active) => {
              changeValue("mortgageRate", data, active);
            }}
            onChangeInputOne={(data, active) => {
              let value = [data, filters.mortgageRate[1]];
              changeValue("mortgageRate", value, active);
            }}
            onChangeInputTwo={(data, active) => {
              let value = [filters.mortgageRate[0], data];
              changeValue("mortgageRate", value, active);
            }}
          />

          <FilterRadio
            title="Vivienda Habitual"
            name="propertyType"
            filters={filters}
            filterValue={filters.propertyType}
            onChangeValue={(data, active) => {
              changeValue("propertyType", data, active);
            }}
          />

          <CurrMortgageFilter filters={filters} changeValue={changeValue} />
      </Collapse>
    </>
  );
}

function CurrMortgageFilter({ filters, changeValue }: HipotecaFilterProps) {
  const mortgageType: number[] = filters.mortgageType || [];
  const shouldShowFilter =
    mortgageType.length > 0 && mortgageType.every((e) => e === 2);
  const filterOff = mortgageType.length === 0 || mortgageType.includes(1);
  useEffect(() => {
    if (filterOff) {
      changeValue("allowCurrMortgageInitDate", false);
    }
  }, [filterOff]);
  if (!shouldShowFilter) {
    // Desactivado para permitir la aplicación de este filtro en todos los modos.
    // return null; 
  }
  const handleTextFieldChange: TextFieldProps["onChange"] = (e) => {
        const newVal =  e.target.value.replace(/\D/g, "")
        changeValue(e.target.name, newVal, true);
  } 
  return (
    <AllowSwitchFilter
      title="Fecha de antigüedad de la firma de hipoteca"
      tooltipText="Ajusta la búsqueda por años, el valor que ingreses será el valor mínimo de antigüedad de las hipotecas."
      name="data.curr_mortgage_init_date__lte"
      filters={filters}
      onChange={changeValue}
    >
      <TextField 
        name="data.curr_mortgage_init_date__lte"
        label="Antigüedad mínima"
        value={filters["data.curr_mortgage_init_date__lte"]}
        onChange={handleTextFieldChange}
        inputProps={{maxLength: 2}}
        inputMode="numeric"
      />
    </AllowSwitchFilter>
  );
}

export interface titleFilterBadge {
  title?: string;
}

function TitleFilter(props: React.PropsWithChildren<titleFilterBadge>) {
  const [invisible, setInvisible] = useState(true);

  return (
    <Badge
      color="primary"
      variant="dot"
      invisible={invisible}
      sx={{
        marginRight: "1rem",
      }}
    >
      {props.children}
    </Badge>
  );
}
