import { useEffect } from "react";
import dataStorageService from "services/api/dataStorage.service";
import { MORTGAGE_FORM_STATE } from "shared/constants/localStorage";
import { MortgageFormData } from "shared/types/postMortgageData";
import { getInitialFormData, getM_ModeParams } from "../utils";
import useMortgageFormReducer, {
  MortgageFormState,
} from "./useMortgageFormReducer";
import { useProfile } from "shared/hooks";
import { ClientProfile } from "shared/types/Profile";

export default function useMortgageForm(
  initialStep?: number,
  defaultValue?: MortgageFormData
) {
  // the profile here apart from clientProfile there is RealStateProfile
  // need to refactor the dependency with client Profile to add RealStateProfile
  const { profile } = useProfile<ClientProfile>(false);
  const isUserLogin = Boolean(profile?.firstname || profile?.user);
  const isUserVerified = profile?.verified;
  const m_mode = getM_ModeParams();
  const paramsMortgageMode = Number(m_mode);

  const controller = new AbortController();

  const mortgageFormInitialData =
    defaultValue ||
    getInitialFormData({
      name: profile?.firstname,
      lastname: profile?.lastname,
      email: profile?.user,
      mortgageMode: paramsMortgageMode,
      phone: profile?.phone,
    });

  const initialState: MortgageFormState = {
    mortgageFormData: mortgageFormInitialData,
    mortgageFormStep: initialStep
      ? initialStep
      : defaultValue && defaultValue.mortgage.mode > 0
      ? 1
      : paramsMortgageMode
      ? 1
      : 0,
    formInitDate: new Date().toISOString(),
    map: { features: [] },
    isTermAccepted: false,
  };

  const [mortgageFormState, dispatch] = useMortgageFormReducer(initialState);

  useEffect(() => {
    //if defaultValue true, maybe form is coming from somewhere
    if (defaultValue) return;
    // this check is profile is a ClientProfile and not other type of Profile
    if (profile && 'firstname' in profile) {
      const newForm = { ...mortgageFormState.mortgageFormData };
      // newForm.user.name = profile?.firstname || "";
      // newForm.user.surname = profile?.lastname || "";
      // newForm.user.email = profile?.user || "";
      // newForm.user.phone = profile?.phone || "";
      paramsMortgageMode && (newForm.mortgage.mode = paramsMortgageMode);
      dispatch({ type: "SET_MORTGAGE_FORM_DATA", payload: newForm });
      dispatch({ type: "SET_IS_TERM_ACCEPTED", payload: profile.opt_in });
    }
  }, [profile]);

  useEffect(() => {
    dataStorageService
      .getData<MortgageFormState>(MORTGAGE_FORM_STATE, controller.signal)
      .then((res) => {
        const cachedForm = res.data.results.payload;
        dispatch({ type: "SET_MORTGAGE_STATE", payload: cachedForm });
      })
      .catch((err) => {
        console.log("Form not found", { err });
      });

    return () => {
      controller.abort();
    };
  }, []);

  const setMortgageFormState = (payload: MortgageFormState) => {
    dispatch({ type: "SET_MORTGAGE_STATE", payload });
    delete payload.selectedStepView 
    dataStorageService.setData({ key: MORTGAGE_FORM_STATE, payload });
  };

  /** will also set the cache if dont want to set the cache, use dispatch instead*/
  const setMortgageFormData = (form: MortgageFormData) => {
    // dispatch({type:'SET_MORTGAGE_FORM_DATA', payload: form})
    const newForm: MortgageFormState = {
      ...mortgageFormState,
      mortgageFormData: form,
    };
    setMortgageFormState(newForm);
  };

  /** will also set the cache if dont want to set the cache, use dispatch instead*/
  const setStep = (step: number) => {
    // dispatch({type:'SET_MORTGAGE_STEP', payload: step})
    const newForm: MortgageFormState = {
      ...mortgageFormState,
      mortgageFormStep: step,
    };
    setMortgageFormState(newForm);
  };

  const clearMortgageFormState = () => {
    dispatch({
      type: "SET_MORTGAGE_STATE",
      payload: {
        ...initialState,
        mortgageFormStep: 0,
        formInitDate: new Date().toISOString(),
      },
    });
    dataStorageService.delData({ key: MORTGAGE_FORM_STATE });
  };

  return {
    profile,
    isUserLogin,
    isUserVerified,
    mortgageFormState,
    setMortgageFormState,
    setMortgageFormData,
    setStep,
    clearMortgageFormState,
    mortgageFormDispatch: dispatch,
  };
}
