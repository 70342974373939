export const MORTGAGE_FORM_STATE = "mortgage_form_state";
export const MORTGAGE_FORM_VALUE = "mortgage_form_state"; // soon deprecated
export const MORTGAGE_FORM_STEP = "mortgage_form_step";
export const CRM_NOT_COLLAPSE_PAGINATION = "crm_not_collapse_pagination";
export const CRM_COLLAPSE_PAGINATION = "crm_collapse_pagination";
export const CRM_CLIENT_PAID_PAGINATION = "crm_client_paid_pagination";
export const CRM_TAB_STATE = "crm_tab_state";

export const COL_STATE = "col_state";
export const LAST_ROW_TOUCHED = "last_rowj_touched";
export const ROLE = "role";

export const NAME = 'name';

export const MID = "mid";
export const BROWSER_VER_ALERT = 'Browser_Ver_Alert'

export const BIDDERS_FILTERS = 'Bidder_filters'
export const MORTGAGE_FORM_SERVICE_FEEDBACK = "mortgage_form_id";

/**this will show on the url search or query params */
export const M_MODE='m_mode'

//Dialog state: has seen or not seen 🌸
export const DIALOGS = {
CHANGE_PASSWORD:'change_pw',
BID_PAID_DIALOG_SEEN : 'bid_paid_dialog_seen',
CLIENT_SELECT_BID_CONGRATS : 'client_select_bid_congrats'
}
