import {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";

import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  Alert,
  Stack,
} from "@mui/material";

import { MainClientLayout } from "../../../shared/layouts";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useAuthenticated, useProfile } from "../../../shared/hooks";
import {
  Breadcrumbs,
  FastFAQs,
  DialogWarning,
  BidFileSection, AlertConfetti
} from "shared/components";
import { getApplicationType } from "../../../utils/helpers";
import { getMortgages } from "../../../services/api";
import { sendTagEvent } from "utils/analytics";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { useLocalStorage } from "../../../shared/hooks";
import Lottie from "react-lottie";

import Unlock from "../../../shared/assets/icons/lottie-json/failed-attempt.json";

import styles from "./styles.module.scss";
import { isValidMortgageId } from "utils";
import { AxiosResponse } from "axios";
import { PaginationResWithResult } from "shared/types/BaseApiResponse";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { onTrueLayerSuccess } from "pages/callback/TrueLayer";
import DocListFaq from "./components/DocListFaq";

const ManageDocuments: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const navigate = useNavigate();
    const location = useLocation();
    const [role] = useLocalStorage("role");
    const profile = useProfile().profile;
    const [mortgages, setMortgages] = useState<IMortgageSchema[]>();
    const [mortgage, setMortgage] = useState<IMortgageSchema>();
    const [failedBankflip, setFailedBankflip] = useState(false);

    const [mode, setMode] = useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams();

    const locationTitle = "Mis documentos";
    const breadcrumbs = [
      {
        href: "/client/home",
        title: "Panel principal",
      },
    ];

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "client");

    useEffect(() => {
      getAll();
    }, []);

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    useEffect(() => {
      // this effect is to check is have a mortgage id
      // it is so set the upload manager state
      const mid = searchParams.get("mid");
      if (!mid) {
        mortgage && setMortgage(undefined);
        return;
      }
      const isValidMid = isValidMortgageId(mid);
      if (isValidMid && mortgages && mortgages.length > 0) {
        const mortgage = mortgages.filter((m: any) => mid === m.id);
        if (mortgage.length > 0) {
          setMortgage(mortgage[0]);
        }
      }
    }, [searchParams, mortgages]);

    const getAll = () => {
      getMortgages(
        {
          mode: "custom",
          filters: {
            status__in: [
              "created",
              "active",
              "closed",
              "in_verification_accepted",
              "closed_successfully",
              "expired",
            ],
          },
        },
        1,
        100
      )
        .then(
          (
            response: AxiosResponse<PaginationResWithResult<IMortgageSchema[]>>
          ) => {
            setMortgages(response.data.results);
          }
        )
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
        });
    };

    const isTruelayerSucess =
      searchParams.get("tl_s") === "1" &&
      // due too much those setter going on will make this code run a lot more
      //, so this 3 var if a kind of a dependency
      mortgage &&
      Boolean(mortgage.id) &&
      Boolean(profile);
    if (isTruelayerSucess) {
      const mid = searchParams.get("mid");
      const solicitor = searchParams.get("solicitor_type");
      mid && solicitor && onTrueLayerSuccess({ mid, solicitor });
    }

    return (
      <MainClientLayout>
        <>
          <AlertConfetti success={isTruelayerSucess} />
          <Container>
            <Grid container spacing={2} marginTop={0}>
              <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
                <Breadcrumbs parents={breadcrumbs}>
                  <Typography color="text.gray">{locationTitle}</Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>

            {mortgages && (
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Grid className={styles.InputsBox}>
                    <Typography variant="h5">{locationTitle}</Typography>
                    <Divider component="br" />
                    <MAlert
                      mortgage={mortgage}
                      mortgages={mortgages}
                      mode={mode}
                    />
                    <Divider component="br" />

                    {/* More information section */}
                    {mode === "" && (
                      <FastFAQs
                        header={false}
                        title=""
                        caption=""
                        data={[
                          {
                            title: "Más información",
                            caption: "",
                            content: (
                              <Stack gap={1} >
                                <Typography component='p'>
                                  📑 Nuestra plataforma trabaja únicamente sobre
                                  datos reales con información actualizada a
                                  tiempo real. Por ello necesitamos que aportes
                                  la documentación citada. Puedes hacerlo
                                  mediante clave pin o cargando los documentos
                                  de forma manual, además puedes obtener tu nota
                                  simple.
                                </Typography>
                                <Typography component='p'>
                                  🔐 Sabemos lo importante que es tu privacidad.
                                  Nuestra plataforma dispone de toda la
                                  seguridad e infraestructura testada para
                                  garantizar la privacidad de tu perfil y tu
                                  documentación. Ésta solo será compartida con
                                  la entidad ganador cuando aceptes una de las
                                  pujas. En el caso que no encuentres ninguna
                                  hipoteca interesante para ti, tus datos serán
                                  eliminados cuando tu hipoteca se expire.
                                </Typography>
                                <Typography component='p'>
                                  💭 Si no sabes cómo obtener alguno de los
                                  documentos, consulta las preguntas frecuentes,
                                  te ayudamos a encontrar toda la información
                                  que puedas necesitar.
                                </Typography>
                              </Stack >
                            ),
                          },
                        ]}
                      />
                    )}

                    <Divider component="br" />

                    {/* Bankflip failed process */}
                    {failedBankflip && (
                      <DialogWarning
                        ShowDialog={true}
                        handledClickAgree={() => {}}
                        handledClickDisagree={() => {}}
                        closeIcon={false}
                      >
                        <>
                          <Box sx={{ textAlign: "center" }}>
                            <Lottie
                              options={{
                                loop: false,
                                autoplay: true,
                                animationData: Unlock,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={100}
                              width={100}
                            />

                            <Typography variant="h5" align="center">
                              Falló la obtención de documentos con Clave Pin
                            </Typography>
                            <Typography variant="caption">
                              Ocurrió un error al tratar de obtener los
                              documentos con el método seleccionado, contacta
                              con el equipo de soporte para más información.
                            </Typography>

                            <Divider component="br" />
                            <Button
                              variant="contained"
                              type="button"
                              onClick={() => {
                                setFailedBankflip(false);
                              }}
                              sx={{ marginTop: "2rem" }}
                            >
                              Volver a intentarlo
                            </Button>
                          </Box>
                        </>
                      </DialogWarning>
                    )}

                    {/* Initial status in case that mid param is not set (we ask for the mortgage to upload files)*/}
                    <Grid>
                      {mortgages.length !== 0 ? (
                        <>
                          {!isValidMortgageId(searchParams.get("mid")) && (
                            <>
                              <Divider component="br" />
                              <Typography variant="body2">
                                Por favor selecciona la solicitud en la que
                                quieres cargar tu documentación:{" "}
                              </Typography>
                              <Divider component="br" />
                              <FormControl
                                fullWidth
                                size="small"
                                sx={{ marginBottom: "2rem" }}
                              >
                                <InputLabel id="mortgage_id-select" required>
                                  Solicitud
                                </InputLabel>
                                <Select
                                  labelId="mortgage_id-select"
                                  id="mortgage_id-select"
                                  label="Solicitud *"
                                  value={mortgage}
                                  onChange={({ target }) => {
                                    if (typeof target.value === "object") {
                                      searchParams.set("mid", target.value.id);
                                      setSearchParams(searchParams);
                                      setMortgage(target.value);
                                    }
                                  }}
                                >
                                  {mortgages.map(
                                    (mortgage: IMortgageSchema) => {
                                      let mode = getApplicationType(
                                        mortgage.mortgage_data.mortgage.mode
                                      );
                                      let date = format(
                                        parseISO(mortgage.created),
                                        "dd-MM-yyyy"
                                      );
                                      return (
                                        <MenuItem
                                          value={mortgage as any}
                                          key={mortgage?.id}
                                        >
                                          {mode} | {date}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </>
                          )}

                          {mortgage && (
                            <BidFileSection
                              bidFilesProps={{
                                mortgage: mortgage,
                                allowDownload: false,
                                autoExpand: true,
                              }}
                              uploadManagerProps={{
                                mortgage,
                                mortgageId: mortgage?.id!,
                                // acceptedFiles: acceptedFiles,
                                // onlyAllow: allowFiles,
                                // denyFiles: denyFiles,
                                mode,
                                onChange: () => {},
                                onFinish: () => {
                                  // if (searchParams.get("mid")) {
                                  //   searchParams.delete("mid");
                                  //   setSearchParams(searchParams);
                                  // }
                                  // setMode("");
                                  // setAllowFiles([]);
                                  getAll();
                                },
                              }}
                            />
                          )}
                          {mode !== "bankflip" && (
                            <>
                              <Divider component="br" />{" "}
                              <Divider component="br" />
                              <Divider component="hr" />
                              <DocListFaq />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            fullWidth
                            className={styles.FormButton}
                            onClick={() => navigate("/client/mortgage/create")}
                          >
                            Crear nueva solicitud
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Container>
        </>
      </MainClientLayout>
    );
  };

export default ManageDocuments;

function MAlert({
  mortgage,
  mortgages,
  mode,
}: {
  mortgages: IMortgageSchema[];
  mortgage?: IMortgageSchema;
  mode: string;
}) {
  return (
    <>
      {/* Alert when user don't have application to upload documentation */}
      {mortgages.length === 0 && (
        <Alert severity="warning">
          No hay solicitudes de hipoteca disponible a las que puedas subir
          documentación, por favor crea una nueva solicitud.
        </Alert>
      )}
      {/* Alerts validating document status */}
      {mortgage?.document_status === "pending" && (
        <Alert severity="warning">
          Los bancos o asesores no podrán confirmar su oferta hasta que los
          documentos que necesitan estén cargados en la plataforma.
        </Alert>
      )}
      {mortgage?.document_status === "verification" && (
        <Alert severity="info">
          Muchas gracias por subir la documentación necesaria, ahora nuestro
          equipo estará validando los documentos aportados lo más pronto
          posible, para que puedas continuar con tu proceso.
        </Alert>
      )}
      {/* {mortgage?.document_status === "removed" && (
        <Alert severity="warning">
          Los archivos de esta solicitud han sido eliminados porque la solicitud
          ha expirado.
        </Alert>
      )} */}
      {/* Deprecated */}
      {mode === "certificate" && (
        <>
          <Alert severity="info">
            Al subir su certificado digital autorizará a Colibid para obtener
            los documentos por usted.
          </Alert>
        </>
      )}
    </>
  );
}
