import React, { FunctionComponent, ReactElement } from "react";

import { Breadcrumbs as BreadC } from "@mui/material";
import Link from "@mui/material/Link";

export interface IBreadcrumbsProps {
  parents: {
    href: string;
    title: string;
    onClick?: () => void;
  }[];
  children: JSX.Element;
}

const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({
  parents,
  children,
}): ReactElement<any> => {
  return (
    <BreadC aria-label="breadcrumb">
      {parents.map((e, i) => {
        return (
          <Link underline="hover" color="primary" href={e.href} key={`b${i}`}>
            {e.title}
          </Link>
        );
      })}

      {children}
    </BreadC>
  );
};

export default Breadcrumbs;
