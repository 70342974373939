import { FormControl, InputLabel, Select, OutlinedInput, MenuItem, SelectProps } from "@mui/material";

type Props = {title: string} & SelectProps;
export default function SelectFilter({title, ...selectProps}: Props) {
  return (
    <FormControl sx={{ width: "100%", mb: 2 }} size="small">
      <InputLabel>{ title }</InputLabel>
      <Select
        {...selectProps}
        input={<OutlinedInput label={title}/>}
      />
    </FormControl>
  );
}
