import { CheckboxProps, FormGroup, FormControlLabel, Checkbox, Typography } from "@mui/material";

function ToggleFinanceFee(props: CheckboxProps) {
  return (
    <FormGroup sx={{ padding: "0.9rem" }}>
      <FormControlLabel
        sx={{ fontSize: "0.9rem" }}
        control={<Checkbox {...props} />}
        label={
          <Typography sx={{ marginBottom: 0, fontSize: "0.9rem" }}>
            Deseo financiar los honorarios del asesor financiero en 6 meses al
            0% de interés con Colibid.
          </Typography>
        }
      ></FormControlLabel>
    </FormGroup>
  );
}

export default ToggleFinanceFee