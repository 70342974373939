import { Bid } from "shared/types/Bid";
import axios from "../../utils/axios";
import { BaseApiSuccessRes, MortgageRes } from "shared/types/BaseApiResponse";

const getBid = (bid_id: string) => {
  return axios.get<BaseApiSuccessRes<Bid>>(
    `${process.env.REACT_APP_API_URL}/bids/${bid_id}`
  );
};

const getBids = (filters: string, page: number, limit: number) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/mortgages?format=json&${filters}&wrap=1&p=${page}&ipp=${limit}`
  );
};

const addBid = (mortgageId: string | undefined, schema: any) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/bids/`, {
    ...schema,
    mortgage_id: mortgageId,
  });
};

interface CustomerAcceptBids {
  id: string;
  accept_financed_commission: boolean;
}

const customerAcceptBids = (
  mortgageId: string,
  bidsToAccepted: Array<CustomerAcceptBids>
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bids/accept`,
    {
      bids: bidsToAccepted,
    }
  );
};

const getListOfBidsinMortgageId = (
  mortgageId: string,
  status?: string,
  group?: string
) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bids?status=${status}&group=${group}`
  );
};

const customerAcceptBid = (
  mortgageId: string | undefined,
  bid: string | Array<Bid>,
  bidObj?: string
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bids/${bid}/saccept`,
    bidObj
  );
};

const brokerAcceptBid = (
  mortgageId: string | undefined,
  bid: string | undefined
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bids/${bid}/baccept`
  );
};

const bidderBidSeen = (bid: string | undefined) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/bids/${bid}/seen`);
};

const getTimeLineBid = (bid_id?: string, mortgage_id?: string) => {
  const params = new URLSearchParams("");
  let endpoint = `${process.env.REACT_APP_API_URL}/timeline/`;

  if (bid_id) {
    params.append("bid_id", bid_id);
  }

  if (mortgage_id) {
    params.append("mortgage_id", mortgage_id);
  }

  // Get params
  const query_params = params.toString();

  if (query_params != "") {
    endpoint += `?${query_params}`;
  }

  return axios.get(endpoint);
};

export {
  getBids,
  addBid,
  customerAcceptBid,
  brokerAcceptBid,
  customerAcceptBids,
  getBid,
  getListOfBidsinMortgageId,
  bidderBidSeen,
  getTimeLineBid,
};
