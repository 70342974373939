// reducer.ts

import { Dispatch } from "react";

export interface State {
  disabledButton: boolean;
  selectDisabled: boolean;
  timeMortgage: number;
  modalAllInterest: boolean;
}

export type Action =
  | { type: "SET_BUTTON_DISABLED"; payload: boolean }
  | { type: "SET_SELECT_DISABLED"; payload: boolean }
  | { type: "SET_MODAL_ALL_INTEREST"; payload: boolean }
  | { type: "SET_TIME_MORTGAGE"; payload: number }
  | { type: string; payload: any }; // Add a generic action type with 'any'

export const initialState: State = {
  disabledButton: true,
  selectDisabled: false,
  timeMortgage: 0,
  modalAllInterest: false,
};

export const ModalReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_BUTTON_DISABLED":
      return { ...state, disabledButton: action.payload };
    case "SET_SELECT_DISABLED":
      return { ...state, selectDisabled: action.payload };
    case "SET_TIME_MORTGAGE":
      return { ...state, timeMortgage: action.payload };
    case "SET_MODAL_ALL_INTEREST":
      return { ...state, modalAllInterest: action.payload };
    default:
      return state;
  }
};

// New function to dispatch dynamic action
export const dispatchAction = (
  dispatch: Dispatch<Action>,
  type: string,
  payload: any
) => {
  dispatch({ type, payload });
};
