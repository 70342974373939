import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { constructionTypeOptions } from "../../utils";
import { constructionTypeSchema } from "../../validation/property";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { Divider, Typography } from "@mui/material";
import { RStack } from "shared/components/Custom";
import { getImgBaseUrl } from "utils/links";
import WithDialog from "shared/components/WithDialog";
import { ColibidTypography } from "shared/components";

function PropertyConstructionTypeForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es el tipo de construcción de tu vivienda?"
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            constructionType: constructionTypeSchema,
          }),
        })
      )}
    >
      <FormFields.ListSelector
        name="property.constructionType"
        options={constructionTypeOptions}
      />
      {/* <Divider
        variant="middle"
        sx={{ borderColor: "black", marginInline: "4rem" }}
      /> */}
      {/* <SolarPanelServiceRequest /> */}
    </StepLayout>
  );
}
export default PropertyConstructionTypeForm;

function SolarPanelServiceRequest() {
  return (
    <RStack
      sx={{ marginInline: "5rem", marginBlock: "2rem", mx: "auto" }}
      alignItems="center"
    >
      <img
        src={getImgBaseUrl("wired-outline-450-solar-panel.gif")}
        height={100}
      />
      <WithDialog
        label={
          <FormFields.CheckboxInput
            name="property.service_request.solar_panel.req"
            label={
              <Typography variant="body2">
                ¿Te interesaría instalar{" "}
                <ColibidTypography accentColor inline>
                  placas solares
                </ColibidTypography>
                ?
              </Typography>
            }
            sx={{
              textAlign: "center",
            }}
          />
        }
        position="right"
      >
        <Typography>
          Al hacer clic, un agente de Samara Energy contactará contigo por vía
          telefónica para un asesoramiento energético gratuito, gracias al nuevo
          acuerdo de colaboración entre Colibid y Samara.
        </Typography>
      </WithDialog>
    </RStack>
  );
}
