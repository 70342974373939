import { FunctionComponent, ReactElement, useState, useEffect } from "react";
import { TopBar, LeftBarMenu, Loader } from "../../components";

import subject, { TOGGLE_LOADER } from "../../../utils/subjects";
import AutoBiddingDialog from "shared/components/AutoBiddingDialog";
import { getImgBaseUrl } from "utils/links";
import Swal from "sweetalert2";
import { useProfile } from "shared/hooks";
import { BidderProfile } from "shared/types/Profile";
import { format } from "date-fns";
import useQuery from "shared/hooks/useQuery";
import { SUCCESS_PAYMENT } from "shared/constants/queryParams";
import { FloatingWap } from "shared/components/FabGroup";
import { isBrokerProfile } from "utils/profile";

export interface IMainBrokerLayoutProps {
  children: JSX.Element;
}

const MainBrokerLayout: FunctionComponent<IMainBrokerLayoutProps> = ({
  children,
}): ReactElement<any> => {
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  // should we said profile on a global state? or maybe security issue, always hit the api
  const { profile } = useProfile<BidderProfile>();
  const successPayment = query.get(SUCCESS_PAYMENT);

  useEffect(() => {
    //check is a package active and trigger alert, should this be handle on the useProfile hook?
    // is the hook is called one yes, but that is not a global so some child is calling it

    //check is plan route so dont show the alert
    const isPlanRoute = window.location.pathname === "/broker/planes";
    if (isPlanRoute) return;

    if (
      profile &&
      isBrokerProfile(profile) &&
      profile.require_purchase_package
    ) {
      const {
        remaining_credits,
        trial_initiated,
        trial_expires_on,
        allow_trial,
        credits_expires_on,
      } = profile;
      if (allow_trial && trial_initiated && trial_expires_on) {
        const currDate = format(new Date(), "yyyy-MM-dd");
        const isExpired = currDate > trial_expires_on;
        if (isExpired) {
          showAlert("testPlanFinished");
          return;
        }
      }
      if (credits_expires_on && remaining_credits <= 0) {
        showAlert("quotaReached");
      }
    }
  }, [profile]);

  useEffect(() => {
    if (successPayment) {
      showPaymentAlert(successPayment == "1");
    }
    const toggleLoader = subject
      .listen(TOGGLE_LOADER)
      .subscribe(async ({ value }) => {
        setLoading(value);
      });

    return () => {
      if (toggleLoader) {
        toggleLoader.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      {loading && <Loader />}

      <TopBar allowLeftBarMenu={true} showAuthControls={true} />
      <LeftBarMenu role="broker" profile={profile} />
      <section id="content" className="main-layout bidder">
        {children}
        <AutoBiddingDialog />
      </section>
      <FloatingWap />
    </>
  );
};

export default MainBrokerLayout;

function showAlert(type: "quotaReached" | "testPlanFinished" | "") {
  const swalMixined = Swal.mixin({
    showCancelButton: true,
    reverseButtons: true,
    cancelButtonText: "Más tarde",
    // confirmButtonText: "Ver Planes",
  });
  // const navigateToPlan = () => {
  //   window.location.href = "/broker/planes";
  // };
  if (type === "quotaReached") {
    swalMixined
      .fire({
        imageUrl: getImgBaseUrl("warning-alert.png"),
        title:
          "Parece que has consumido todos tus créditos y no puedes enviar más ofertas.",
        html: '<span style="font-size: 1.8em">Por favor contacta con nuestros asesores.</span>',
      })
      .then((res) => {
        if (res.isConfirmed) {
          //TODO send request
          // navigateToPlan();
        }
      });
    return;
  }
  swalMixined
    .fire({
      imageUrl: getImgBaseUrl("warning-alert.png"),
      title:
        "¡Tu periodo de prueba ha finalizado! ¿Quieres comprar créditos para seguir enviando ofertas?",
      text: "Si no lo haces, las ofertas enviadas anteriormente caducarán y no podrás ganar más clientes. Si ya has ganado un cliente, podrás darle seguimiento sin problema.",
    })
    .then((res) => {
      if (res.isConfirmed) {
        //TODO send request
        // navigateToPlan();
      }
    });
}

function showPaymentAlert(success: boolean) {
  const title = success
    ? "Su pago ha sido procesado correctamente"
    : "Hubo un error con procesando el pago";
  const description = success
    ? "¡Enhorabuena disfruta de tus servicios!"
    : "Por favor, inténtelo más tarde o contáctenos si persiste el problema.";
  Swal.fire({
    title: title,
    text: description,
  });
}
