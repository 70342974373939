import { useMediaQuery, Stack, Typography } from "@mui/material";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import { isMobile } from "react-device-detect";


export default function RotatePhoneInfoMsg() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  if (!isSmallScreen || !isMobile ) return null;
  return (
    <Stack
      gap={2}
      direction="row"
      padding='0.7rem 1rem'
      sx={{
        backgroundColor: "#E5fcfd",
      }}
    >
      <ScreenRotationIcon fontSize="large" />
      <Typography>
        Para una mejor experiencia en móviles, le sugerimos rotar el dispositivo.
      </Typography>
    </Stack>
  );
}