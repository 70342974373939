import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
  Divider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// https://github.com/alexplumb/material-ui-phone-number/issues/111
import MuiPhoneNumber from "material-ui-phone-number-2";

import { toast } from "react-toastify";
import { MainRealEstateLayout, NotAuthLayout } from "../../../shared/layouts";
import {
  formValidation,
  validateTrue,
  emailValidate,
  sendOptCodeEmail,
  phoneNumberValidate,
  agentAddContact,
} from "../../../services/api";
import { useLocalStorage } from "../../../shared/hooks";
import { DialogWarning } from "../../../shared/components";
import { useDialog } from "../../../shared/hooks";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import styles from "./styles.module.scss";
import { NumberBox } from "../Components";
import Swal from "sweetalert2";

const { REACT_APP_RECAPTCHA_SITE_KEY }: any = process.env;
const { REACT_APP_ENV }: any = process.env;
const { REACT_APP_SPECIAL_AGENT }: any = process.env;

const ContactForm: FunctionComponent<{}> = (
  props
): ReactComponentElement<any> => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isShowing, toggle } = useDialog();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstname, setName] = useState("");
  const [lastname, setSurname] = useState("");
  const [selectedHour, setSelectedHour] = useState<Date | string | null>(null);
  const [recaptcha, setreCaptcha] = useState<string | null>("");
  const [msg, setMsg] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [hasError, sethasError] = useState(false); // User to display error message
  const [agentEmail] = useLocalStorage("email");
  const [role] = useLocalStorage("role");
  const [errors, setErrors] = useState({
    email: false,
    firstname: false,
    lastname: false,
    recaptcha: false,
    preferred_time: "",
    phone: false,
  });

  useEffect(() => {
    if (location.search.indexOf("r") !== -1) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params?.r) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
          r: params.r,
        });
      }

      if (params?.error) {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado 😞, intenta iniciar sesión nuevamente y en caso de que sigas teniendo problemas, por favor ponte en contacto con nosotros."
        );
      }
    } else {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, []);

  const validate = () => {
    let short_phone = phone ? phone.split(" ") : [""];
    short_phone.shift();
    errors.email = emailValidate(email);
    errors.firstname = formValidation(firstname, "required");
    errors.phone = phoneNumberValidate(short_phone.join(""));
    errors.lastname = formValidation(lastname, "required");

    if (REACT_APP_ENV !== "development") {
      errors.recaptcha = formValidation(recaptcha, "required");
    }

    let err = validateTrue(errors);
    sethasError(err);
    setErrors({ ...errors });
    if (errors.recaptcha) {
      setMsg("Por favor completa el Captcha");
    } else {
      setMsg("Por favor válida la información ingresada");
    }
    if (!err) {
      register();
      // // sendPinCode();
      // toggle();
    }
  };

  const sendPinCode = () => {
    setPinCode("");

    sendOptCodeEmail(email);
  };

  const register = () => {
    const timeString = selectedHour
      ? selectedHour.toLocaleString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
      : "";

    agentAddContact({
      first_name: firstname,
      last_name: lastname,
      preferred_time: "",
      phone: phone,
      email: email,
    })
      .then(() => {
        Swal.fire({
          title: "¡Tu cliente está en buenas manos!",
          text: "Nos contactaremos vía email y Whatsapp para brindarle la información necesaria. De no obtener respuesta, te avisaremos.",
          customClass: {
            popup: "swal_alert",
          },
          confirmButtonText: "Vale",
        }).then((result: any) => {
          if (result.isConfirmed) {
            navigate("/agent");
          }
        });
      })
      .catch((e: any) => {
        let message = e.message;
        if (e.response && e.response.status === 409) {
          sethasError(true);
          message = "Este correo electrónico ya se encuentra registrado";
          setMsg(message);
        } else if (e.response && e.response.status === 406) {
          message = "Código de validación inválido";
          sethasError(true);
          setMsg(message);
        } else {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
        }
      });
  };

  const handledCLickCloseDialog = () => {
    setPinCode("");
    toggle();
  };

  const handlePinChange = (pinCode: string) => {
    if (pinCode.length >= 0) {
      setPinCode(pinCode);
    }
  };

  const handledAceptDialog = () => {
    toggle();
    register();
  };

  const handleHourChange = (newValue: Date | string | null) => {
    if (newValue !== null) {
      setSelectedHour(newValue);
    }
  };

  return (
    <MainRealEstateLayout>
      <Container className={styles.LoginWrapper}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validate();
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={4} xs={12} sm={12}></Grid>
            <Grid item md={4} xs={12} sm={12}>
              <Typography
                color="primary"
                variant="h5"
                display="block"
                marginBottom="1rem"
              >
                ¿Tienes un cliente que necesita financiación? ¡Nosotros nos
                encargamos!
              </Typography>

              {hasError ? (
                <Box>
                  <Alert severity="error">{msg}</Alert>
                  <Divider component="br" />
                </Box>
              ) : null}

              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Correo electrónico"
                    placeholder="tucorreo@gmail.com"
                    variant="outlined"
                    size="small"
                    error={errors.email}
                    helperText={
                      errors.email
                        ? "Por favor ingresa un correo válido"
                        : false
                    }
                    fullWidth
                    onChange={({ target }) => {
                      setEmail(target.value);
                    }}
                    required
                    type="email"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.firstname}
                    value={firstname}
                    onBlur={(e) => setName(e.target.value.trim())}
                    onChange={({ target }) => {
                      setName(target.value);
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    label="Apellido"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={errors.lastname}
                    value={lastname}
                    onBlur={(e) => setSurname(e.target.value.trim())}
                    onChange={({ target }) => {
                      setSurname(target.value);
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <MuiPhoneNumber
                    defaultCountry={"es"}
                    fullWidth
                    label="Móvil"
                    countryCodeEditable={false}
                    size="small"
                    name="phone"
                    onChange={(data: any) => {
                      setPhone(data);
                    }}
                    error={errors.phone}
                    value={phone}
                    onlyCountries={["es"]}
                    className="phone-selector"
                    type="text"
                    variant="outlined"
                    required
                    helperText={
                      errors.phone
                        ? "Por favor ingresa un número válido de España"
                        : false
                    }
                    autoComplete="country"
                  />
                </Grid>

                {REACT_APP_ENV !== "development" && (
                  <Grid item xs={12} md={12}>
                    <Box className={styles.RecaptchaContainer}>
                      <ReCAPTCHA
                        sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={(token: string | null) => {
                          setreCaptcha(token);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
              >
                <Button variant="contained" fullWidth type="submit">
                  Enviar Datos
                </Button>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="stretch"
              >
                {agentEmail !== REACT_APP_SPECIAL_AGENT ? (
                  <>
                    <Grid item md={4} sm={12}>
                      <NumberBox
                        number="01"
                        colorNumber="#D8D8F6"
                        title="Contacto"
                        paragraph="Le enviaremos sus credenciales vía mail para que pueda finalizar el proceso."
                        style={{ padding: "0px" }}
                      />
                    </Grid>
                    <Grid item md={4} sm={12}>
                      <NumberBox
                        colorNumber="#01807A"
                        number="02"
                        title="Actualización de Panel"
                        paragraph="Quedará actualizado en tu panel “Mis Clientes” para que puedas darle seguimiento."
                        style={{ padding: "0px" }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item md={4} sm={12}>
                    <NumberBox
                      number="01"
                      colorNumber="#D8D8F6"
                      title="Contacto"
                      paragraph="Le enviaremos sus credenciales vía mail para que pueda finalizar el proceso."
                      style={{ padding: "0px" }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>

        <DialogWarning
          ShowDialog={isShowing}
          handledClickAgree={toggle}
          handledClickDisagree={handledCLickCloseDialog}
          closeIcon={true}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginTop: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className={styles.LoginImage}
              >
                <img src="/assets/img/sources/mailbox.svg" alt="" width="40%" />
              </Box>
              <Grid
                sx={{
                  marginTop: 2,
                  fontWeight: "bold",
                  maxWidth: "300px",
                  textAlign: "center",
                }}
                container
              >
                <Box
                  component="div"
                  sx={{
                    mt: 1,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      marginTop: 2,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    component="span"
                    variant="h6"
                  >
                    Verificación de Email
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    component="span"
                    variant="body2"
                  >
                    Se ha enviado un codigo de verificación al correo {email}
                  </Typography>
                </Box>
              </Grid>

              <Box
                component="div"
                sx={{ mt: 1, flexDirection: "column", alignItems: "center" }}
              >
                <Button
                  onClick={handledAceptDialog}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Enviar
                </Button>
                <Grid container>
                  <Grid item>
                    <Typography variant="overline" display="block" gutterBottom>
                      ¿No recibiste el codigo?{" "}
                      <span onClick={sendPinCode} className={styles.links}>
                        Reenviar
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        </DialogWarning>
      </Container>
    </MainRealEstateLayout>
  );
};

export default ContactForm;
