import { Stack, Tab, TableHead, Tabs, Typography } from "@mui/material";
import React, { SyntheticEvent, useState } from "react";
import { MixTypeInterest } from "shared/components/BidDetails";
import BonificationTable from "shared/components/BonificationTable";
import { setTableRowsData, TableRows } from "shared/components/InfoCard";
import TabPanel from "shared/components/TabPanel";
import ToggleFinanceFee from "shared/components/ToggleFinanceFee";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";
import {
  getComissionValue,
  getFirstPeriodMixto,
  isInterestMixto,
} from "utils/bids";
import {
  censureChars,
  formatLocalCurrency,
  getConsultingType,
} from "utils/helpers";
import { getBidderTypeInES } from "utils/translate";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styles from "../BidTable/styles.module.scss";

/**there another component name BidDetails used as a page */
export default function BidDetailsWithTabs({
  bid,
  mortgage,
  isFinanceFeeAccepted,
  onFinanceFeeChange,
  details,
}: {
  bid: Array<Bid>;
  mortgage: MortgageRes;
  isFinanceFeeAccepted?: boolean;
  onFinanceFeeChange?: () => void;
  details?: boolean;
}) {
  const [tabVal, setTabVal] = useState(0);

  const handleTabChange = (e: SyntheticEvent, v: number) => {
    setTabVal(v);
  };

  return (
    <>
      <Tabs variant="fullWidth" value={tabVal} onChange={handleTabChange}>
        {(["Ofertas", "Bonificaciones"] as const).map((t) => (
          <Tab
            key={t}
            label={t}
            sx={{
              width: "50%",
              fontSize:
                "calc(11px + (14 - 11) * ((100vw - 320px) / (1600 - 320)))",
            }}
            disabled={
              t === "Bonificaciones"
                ? //if all is false disable this tab
                  !bid
                    .map((item) => item.conditions_details.some((b) => b.allow))
                    .some((item) => item)
                : false
            }
          />
        ))}
      </Tabs>
      <TabPanel value={tabVal} index={0}>
        {bid.length > 0 && (
          <TableHead>
            {/* <TableRow>
              <TableCell>Oferta</TableCell>
              <TableCell>Entidad</TableCell>
              <TableCell align="center">Tipo de Interés</TableCell>
              <TableCell align="center">Tasa de interés</TableCell>
              <TableCell align="center">Valor de hipoteca</TableCell>
              <TableCell align="center">Pago</TableCell>
              <TableCell align="center">Comisión</TableCell>
            </TableRow> */}
            {/*    <TableRow>
              {" "}
              <TableCell align="center" colSpan={7}>
                Este es el resumen de las ofertas elegidas{" "}
              </TableCell>
            </TableRow> */}
          </TableHead>
        )}

        {bid.map(
          (
            {
              bidder,
              interest_rate,
              general_fees,
              interest_data,
              additional_detail,
            },
            index
          ) => {
            const bidderName = censureChars(bidder.name);
            const bidderType = getBidderTypeInES(bidder.bidder_type);
            const interestRate = isInterestMixto(bid[index])
              ? getFirstPeriodMixto(bid[index])
              : interest_rate;
            const comissionValue =
              getComissionValue(general_fees) > 0
                ? `${formatLocalCurrency(
                    getComissionValue(general_fees)
                  )}`
                : "Sin comisiones";
            const mortgageValue = formatLocalCurrency(
              mortgage.mortgage_data.data.amount
            );
            const paymentMoment = getConsultingType(
              general_fees.consulting_type
            );
            const interestType = interest_data.mode
              ? interest_data.mode.replace(/^\w/, (char) => char.toUpperCase())
              : "";

            const detailsOffert = additional_detail;

            return (
              <React.Fragment>
                {!details ? (
                  <TableRow>
                    <TableCell
                      style={{ color: "#33CCCC", fontWeight: "bold" }}
                      className={styles.customPadding}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell className={styles.customPadding}>
                      <Stack>
                        <span className="capitalize">{bidderType}</span>
                      </Stack>
                    </TableCell>
                    <TableCell className={styles.customPadding}>
                      {interestType}{" "}
                    </TableCell>
                    <TableCell className={styles.customPadding}>
                      {`${interestRate}%`}

                      {interestType === "Variable" && (
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          fontSize="8px"
                        >
                          +Euribor
                        </Typography>
                      )}
                    </TableCell>

                    <TableCell className={styles.customPadding}>
                      {comissionValue}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRows
                    data={{
                      "Banco/bróker": (
                        <Stack>
                          <span className="censure">{bidderName}</span>
                          <span className="capitalize">{bidderType}</span>
                        </Stack>
                      ),
                      "Tasa de interés (TIN)": `${interestRate} %`,
                      "Comision*": comissionValue,
                      "Valor de hipoteca": mortgageValue,
                      "Momento del pago": paymentMoment,
                      "Tipo de interés": interestType,
                      "Detalles de la oferta": detailsOffert,
                    }}
                  />
                )}
              </React.Fragment>
            );
          }
        )}
      </TabPanel>
      <TabPanel value={tabVal} index={1}>
        {bid.map((singleBid, index) => (
          <>
            <TableRow className={styles.customPadding}>
              <TableCell
                style={{
                  color: "#33CCCC",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  marginTop: "1rem",
                }}
              >
                {index + 1}
              </TableCell>
              {!singleBid.conditions_details.some((b) => b.allow) && (
                <TableCell>Sin bonificaciones</TableCell>
              )}
            </TableRow>

            <BonificationTable
              conditionsDetails={singleBid.conditions_details}
            />
          </>
        ))}
      </TabPanel>

      {bid.map((singleBid) => {
        if (singleBid.general_fees.finance_fees && onFinanceFeeChange) {
          return (
            <ToggleFinanceFee
              checked={isFinanceFeeAccepted}
              onChange={onFinanceFeeChange}
            />
          );
        }
        return null;
      })}
    </>
  );
}
