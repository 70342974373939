import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
} from "react";
import { Container, Grid } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { MainBrokerLayout } from "../../../shared/layouts";
import { PageTitle, BidResume, BidFiles } from "../../../shared/components";

import {
  useAuthenticated,
  useLocalStorage,
  useProfile,
} from "../../../shared/hooks";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import styles from "./styles.module.scss";
import { BidFilesSkeleton } from "shared/components/BidFiles/BidFilesSkeleton";
import BidResumeSkeleton from "shared/components/BidResume/BidResumeSkeleton";
import useBidById from "shared/hooks/useBidById";
import useAcceptedBidSeen from "shared/hooks/useAcceptedBidSeen";

const ClientDetails: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const location = useLocation();
  const [role] = useLocalStorage("role");
  const { id } = useParams();
  const navigate = useNavigate();
  const { bid, mortgage } = useBidById(id);
  const profile = useProfile().profile;
  useAcceptedBidSeen(id);

  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["broker", "bank"]
  );

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  return (
    <MainBrokerLayout>
      <Container className={styles.ClientDetailsWrapper}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Detalles de la Puja" />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            {mortgage === undefined && bid === undefined ? (
              <BidResumeSkeleton />
            ) : (
              mortgage &&
              bid && (
                <BidResume
                  bid={bid}
                  data={mortgage}
                  mode="broker"
                  option="customer_information"
                />
              )
            )}
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Documentación" />
            {mortgage ? (
              <BidFiles
                mortgage={mortgage}
                allowDownload={
                  mortgage.status === "closed_successfully" ||
                  mortgage.status === "paid"
                    ? true
                    : false
                }
              />
            ) : (
              <BidFilesSkeleton />
            )}
          </Grid>
        </Grid>
      </Container>
    </MainBrokerLayout>
  );
};

export default ClientDetails;
