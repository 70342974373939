import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CreateIcon from "@mui/icons-material/Create";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  SxProps,
  TextFieldProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import CustomDialog from "shared/components/CustomDialog";
import FormFields from "shared/components/FormFields";
import WithTooltip from "shared/components/WithTooltip";
import { useDialog } from "shared/hooks";

interface MortgageRowProps {
  value?: string | number;
  label: string;
  tooltipText?: string;
  children?: ReactNode;
  /**name of the path in string with dot, it also will get the error if any and display it */
  name?: string;
  textFieldProps?: TextFieldProps;
  iconSx?: SxProps<Theme>;
  editable?: boolean;
  renderInput?: ReactNode;
  helperText?: string;
}

export function MortgageDataRow(props: MortgageRowProps) {
  const { label, tooltipText, children } = props;
  const { isShowing, toggle } = useDialog(true);
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(props?.name || "");
  const hasError = fieldState.error;
  const canClick = Boolean(children);
  const handleClick = () => {
    if (canClick) toggle();
  };
  return (
    <>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            "&>*": {
              flex: 1,
            },
          }}
        >
          <Stack direction="row" alignItems="center">
            <ChevronRightIcon
              fontSize="large"
              color="primary"
              onClick={handleClick}
              sx={{
                ...props.iconSx,
                ...(children && {
                  ...(isShowing && { rotate: "90deg" }),
                  ...(canClick && { cursor: "pointer" }),
                }),
              }}
            />
            {tooltipText ? (
              <WithTooltip title={tooltipText} position="right">
                <Typography variant="body2">{label}</Typography>
              </WithTooltip>
            ) : (
              <Typography variant="body2">{label}</Typography>
            )}
          </Stack>
          {props.renderInput ? props.renderInput : <Amount {...props} />}
        </Stack>
        {props.helperText && (
          <Typography variant="subtitle2" marginLeft="36px" fontSize="0.7rem">
            {props.helperText}
          </Typography>
        )}
        {hasError && (
          <Typography
            color="error"
            variant="subtitle2"
            fontSize="0.7rem"
            textAlign="center"
          >
            {hasError.message}
          </Typography>
        )}
      </Stack>

      {children && <Collapse in={isShowing}>{children}</Collapse>}
    </>
  );
}
export function Amount({
  value,
  name,
  label,
  ...rest
}: Omit<MortgageRowProps, "children" | "renderInput">) {
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(name || "");
  const hasError = fieldState.error;
  const { isShowing, toggle, closeDialog } = useDialog();
  const isEditable = rest.editable;
  const handleClick = () => {
    isEditable && toggle();
  };
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography variant="h6" color={hasError ? "error" : "primary"}>
          {value}
        </Typography>
        <IconButton
          {...(isEditable && { onClick: handleClick })}
          sx={{ visibility: isEditable ? "visible" : "hidden" }}
        >
          <CreateIcon
            sx={{
              color: isEditable ? "#979797" : "white",
            }}
          />
        </IconButton>
      </Stack>
      <CustomDialog
        open={isShowing}
        {...(!hasError && { onClose: closeDialog })}
      >
        <FormFields.CurrencyFieldWithWrapperText
          name={name}
          label={label}
          {...rest.textFieldProps}
          // {...rest}
        />
      </CustomDialog>
    </>
  );
}

export function SubMortgageRow(props: MortgageRowProps) {
  return (
    <Box marginLeft={3}>
      <MortgageDataRow {...props} />
    </Box>
  );
}
