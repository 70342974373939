import { aboveZeroTest, requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";

export const stepFourValidationSchema = yup.object().shape({
  revenue: yup.object().shape({
    incomes_list: yup.array().of(
      yup.object().shape({
        monthly_income: requiredStringSchema().test(aboveZeroTest),
        name: requiredStringSchema(),
        solicitor: requiredStringSchema(),
      })
    ),
    expenses_list: yup.array().of(
      yup.object().shape({
        debt_monthly: requiredStringSchema().test(aboveZeroTest),
        // debt_total: requiredStringSchema().test(aboveZeroTest),
        debt_duration: requiredStringSchema(),
        name: requiredStringSchema(),
        solicitor: requiredStringSchema(),
      })
    ),
    // coli_last_3_file: requiredStringSchema(),
    // coli_emp_contract: requiredStringSchema(),
    // coli_account_state: requiredStringSchema(),
    monthly: requiredStringSchema(),
  }),
});
