import React, {
  useRef,
  useEffect,
  useState,
  FunctionComponent,
  ReactElement,
} from "react";
import { format, parseISO } from "date-fns";
import { ChartData, ChartArea } from "chart.js";
// import { Loader } from "./index";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "chart.js/auto";
import { Box } from "@mui/material";
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

import styles from "./styles.module.scss";
import { censureChars } from "../../../utils/helpers";
import { Bid } from "shared/types/Bid";
import Loader from "../Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

export interface IMortgageChartProps {
  bids: Bid[];
  onClick: (bid: any) => void;
  type?: string;
}
const MortgageChart: FunctionComponent<IMortgageChartProps> = ({
  bids,
  onClick,
  type,
}): ReactElement<any> => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });
  const [showChartOverlay, setShowChartOverlay] = useState(true);
  const [typeChart, setTypeChart] = useState("");
  const color = "rgb(1 133 122)";

  useEffect(() => {
    const chart = chartRef.current;
    if (
      bids.filter(
        (item: any) =>
          item?.interest_data?.mode === "mixed" ||
          item?.interest_data?.mode === "mixto"
      ).length > 0
    ) {
      setTypeChart("bar");
    } else {
      setTypeChart("line");
    }

    if (!chart) {
      return;
    }

    const labels: string[] = [];
    const pujas: any = {
      label: "Pujas",
      data: [],
      fill: true,
      borderColor: color,
      backgroundColor: createGradient(chart.ctx, chart.chartArea),
      pointRadius: [40, 40, 40, 40],
      pointHoverRadius: 20,
      pointHitRadius: 60,
      pointStyle: [],
      dataLabel: [],
      pointBackgroundColor: "#fff",
    };
    const fixed: any = {
      label: "Período Inicial",
      data: [],
      fill: true,
      borderColor: color,
      backgroundColor: "#5DBEA3",

      pointRadius: [40, 40, 40, 40],
      pointHoverRadius: 20,
      pointHitRadius: 60,
      pointStyle: [],
      pointBackgroundColor: "#fff",
    };
    const variable: any = {
      label: "Período Final",
      data: [],
      fill: true,
      borderColor: color,
      backgroundColor: "#3e9f97e0",

      pointRadius: [40, 40, 40, 40],
      pointHoverRadius: 20,
      pointHitRadius: 60,
      pointStyle: [],
      pointBackgroundColor: "#fff",
    };
    if (typeChart === "line") {
      if (bids.length > 0) {
        pujas.data.push(0);
        labels.push("");
        pujas.dataLabel.push({ mode: "", rate: 0 });
      }
      bids?.forEach((b: any) => {
        var logo = new Image();
        if (!b.bid_for?.logo) {
          logo.src = "/assets/img/icons/default_bank.svg";
        }
        logo.width = 50;
        logo.height = 50;
        labels.push(format(parseISO(b.created), "hh:m:s a"));

        pujas.dataLabel.push(b.interest_data);

        pujas.data.push(b.interest_rate);
        pujas.pointStyle.push(logo);
      });

      const chartDatatest = {
        labels,
        datasets: [pujas],
      };
      setChartData(chartDatatest);
    } else if (typeChart === "bar") {
      bids?.forEach((b: any) => {
        var logo = new Image();
        if (!b.bid_for?.logo) {
          logo.src = "/assets/img/icons/default_bank.svg";
        }
        if (
          b.interest_data?.mode === "mixed" ||
          b.interest_data?.mode === "mixto"
        ) {
          b.interest_data?.interest.forEach((interest: any) => {
            const data = interest?.duration ? interest.duration : 0;
            if (interest.name === "initial") {
              fixed.data.push(data);
              fixed.pointStyle.push(interest);
            } else {
              variable.data.push(data);
              variable.pointStyle.push(interest);
            }

            labels.push(format(parseISO(b.created), "hh:m:s a"));
          });
        }
        logo.width = 50;
        logo.height = 50;
      });

      const chartData = {
        labels,
        datasets: [fixed, variable],
      };
      setChartData(chartData);
    }
  }, [bids, typeChart]);

  const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 500);
    gradient.addColorStop(0, "#9dfff9c4");
    gradient.addColorStop(1, "#ffffff8c");

    return gradient;
  };

  const enableChartZoom = (e: any) => {
    if (e.ctrlKey && e.which === 68) {
      setShowChartOverlay(false);
    }
  };

  const addLabelInterest = () => {};

  useEffect(() => {
    document.addEventListener("keydown", enableChartZoom);

    return () => {
      document.removeEventListener("keydown", enableChartZoom);
    };
  }, []);

  return (
    <Box
      onClick={() => {
        setShowChartOverlay(false);
      }}
      height="100%"
      width="100%"
    >
      {typeChart === "line" && (
        <Chart
          ref={chartRef}
          type="line"
          redraw={false}
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            elements: {
              line: {
                fill: true,
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Ofertas fijas o variables",
              },
              tooltip: {
                usePointStyle: false,
                caretSize: 10,
                caretPadding: 0,
                displayColors: false,
                backgroundColor: "#01857A",
                callbacks: {
                  label: (ctx: any) => {
                    if (ctx.dataIndex !== 0) {
                      let data = {
                        interestType: "",
                        rate: "",
                      };

                      if (ctx.dataset?.dataLabel?.length > 0) {
                        data.interestType =
                          ctx.dataset?.dataLabel[ctx.dataIndex].mode;
                        data.rate = ctx.dataset?.dataLabel[ctx.dataIndex].rate;
                      }

                      const html = `Interés: ${data.interestType} ${data.rate}%`;

                      return html;
                    }
                    return ``;
                  },
                },
              },
              zoom: {
                limits: {
                  x: { min: 0, max: 200, minRange: 50 },
                  y: { min: -100, max: 200, minRange: 50 },
                },
                pan: {
                  enabled: false,

                  mode: "xy",
                },
                zoom: {
                  wheel: {
                    enabled: false,
                  },
                  pinch: {
                    enabled: false,
                  },
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
            scales: {
              x: {
                display: true,
              },
              y: {
                display: true,
                min: 0,
              },
            },
          }}
        />
      )}
      {typeChart === "bar" && (
        <Chart
          ref={chartRef}
          type="bar"
          redraw={true}
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: true,

            plugins: {
              title: {
                display: true,
                text: "Ofertas tipo mixto",
              },
              tooltip: {
                callbacks: {
                  label: (ctx: any) => {
                    let dataMixedObject = {
                      typeInterest: "",
                      percentage: "",
                      time: "",
                    };
                    if (ctx.dataset?.pointStyle?.length > 0) {
                      dataMixedObject.typeInterest =
                        ctx.dataset?.pointStyle[ctx.dataIndex].mode;
                      dataMixedObject.percentage =
                        ctx.dataset?.pointStyle[ctx.dataIndex].rate;
                      dataMixedObject.time =
                        ctx.dataset?.pointStyle[ctx.dataIndex].duration;
                    }

                    const html = `Interés: ${dataMixedObject.typeInterest} ${dataMixedObject.percentage}%  por ${dataMixedObject.time} años`;
                    return html;
                  },
                },
              },
            },

            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: "Período de Hipoteca (años)",
                  font: {
                    size: 15,
                  },
                },
              },
            },
          }}
        />
      )}
    </Box>
  );
};

export default MortgageChart;
