import {
  Alert,
  AlertTitle,
  ButtonUnstyled,
  Collapse,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useLocation, useMatch } from "react-router-dom";
import { BROWSER_VER_ALERT } from "shared/constants/localStorage";
import { useDialog, useLocalStorages } from "shared/hooks";
import dataStorageService from "services/api/dataStorage.service";
import { useEffect } from "react";

export default function BrowserVerAlert() {
  const { isShowing, closeDialog, openDialog } = useDialog();
  const location = useLocation();
  const isSigninPage = location.pathname === "/sign-in";
  const [state, setState] = useLocalStorages(BROWSER_VER_ALERT, true);

  useEffect(() => {
    dataStorageService
      .getData(BROWSER_VER_ALERT)
      .then((res) => {
        const isRequired = res.data.results.payload;
        if (!isRequired) {
          openDialog();
        }
      })
      .catch((err) => {
        isLocalStorage();
        console.error({ err });
      });
  }, []);

  const isLocalStorage = () => {
    if (state !== undefined && state == false) {
      closeDialog();
    } else {
      openDialog();
    }
  };

  const handleClose = () => {
    dataStorageService
      .setData({
        key: BROWSER_VER_ALERT,
        payload: true,
      })
      .then((res) => {
        if (res.status === 200) {
          closeDialog();
        }
      })
      .catch((err) => {
      console.error("Error during setData:", err);
      if (err.response && err.response.status === 401) {
        closeDialog();
        setState(false);
      } 
      });
  };

  if (isSigninPage) {
    return null;
  }

  return (
    <Collapse in={isShowing}>
      <Alert severity="info" icon={false} sx={{ justifyContent: "center" }}>
        <Stack direction="row">
          <Stack spacing={1}>
            <BrowserIcons />
            <AlertTitle>
              Te recomendamos utilizar navegadores compatibles para que tu
              experiencia sea la mejor
            </AlertTitle>
            Además recuerda tener siempre tu navegador actualizado para evitar
            errores de compatibilidad.
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ height: 40 }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Alert>
    </Collapse>
  );
}

const BrowserIcons = () => (
  <Stack direction="row" justifyContent="center" spacing={2}>
    <img src="/assets/img/icons/browsers/chrome.svg" height={40} width={40} />
    <img src="/assets/img/icons/browsers/firefox.svg" height={40} width={40} />
    <img src="/assets/img/icons/browsers/safari.svg" height={40} width={40} />
  </Stack>
);
