import { AuthorizeStatementRequest } from "services/types/statement";

/**
 * this function will create a form element append hiddens input, submit the form and del the form after submitting
 * */
export function sendPaymentInFormElement(data: AuthorizeStatementRequest) {
  const form = document.createElement("form");
  form.method = data.method;
  form.action = data.url;
  Object.entries(data.body).forEach(([name, value]) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    form.appendChild(input);
  });
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
