import Confetti from "react-confetti";
import "./Confetti.css";

type Props = {
  /**handle the success from the parent  */
  success?: boolean;
  height?: number
  width?: number
};
export default function AlertConfetti({ success = true, height, width }: Props) {
  if (!success) return null;
  return (
    <Confetti
      className="Confetti"
      width={width || window.innerWidth}
      height={height || window.innerHeight}
      gravity={0.2}
      numberOfPieces={30}
    />
  );
}
