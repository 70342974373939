import { ReactElement } from "react";
import Countdown from "react-countdown";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { statusChecker } from "../../../utils/helpers";
import Swal from "sweetalert2";
import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import { IMortgageCardProps } from "./types";
import {
  bodyTextLinkBy,
  getClosedBodyTextLink,
  getClosedImg,
  imgByStatus,
  shouldUploadDocuments,
} from "./utils";
import GoToManageDocumentsById from "../GoToManageDocumentsById";
import { dateExpired } from "utils";

// Refactor talvez los props, tal vez recibir mortgage y este componente se encargue de la logica de los btn handler
// porq cambiar la redireccion de onviewClick desde afuera se requirio cambiar en todas las partes q llaman este comp
const MortgageCard = ({
  documentStatus,
  status,
  date,
  title,
  onViewClick,
  end,
  bids,
  id,
  onDeleteCard,
  onRetryCard,
  disabledButton,
  mortagage,
}: IMortgageCardProps): ReactElement<any> => {
  const navigate = useNavigate();
  const actionToDelete = () => {
    Swal.fire({
      title: "¿Estás seguro de cancelar tu solicitud?",
      text: "Una vez cancelada, tendrás que crear una nueva solicitud.",
      icon: "warning",
      customClass: {
        title: `title_modal_cancel_mortagage`,
      },
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (onDeleteCard) {
          onDeleteCard();
        }
      }
    });
  };

  const actionToRetry = () => {
    if (onRetryCard) {
      onRetryCard();
    }
  };

  return (
    <div className={styles.MortgageCardWrapper} id="card">
      {/* Badge */}
      {(status === "closed_successfully" || status === "paid") && (
        <Badge
          sx={{ float: "right" }}
          color="primary"
          overlap="circular"
          badgeContent={<DoneIcon sx={{ width: "auto", height: "auto" }} />}
        ></Badge>
      )}

      {/* Header section */}
      <div className={styles.Header}>
        <div className={styles.Top}>
          {status !== "created" &&
          status !== "in_verification" &&
          status !== "discarded" &&
          status !== "draft" ? (
            <Chip
              icon={<CheckCircle className={styles.Icon} />}
              label="Verificada"
              className={styles.Chip}
            />
          ) : (
            <Chip
              icon={<CheckCircle className={styles.Icon} />}
              label="No verificada"
              className={styles.ChipPending}
            />
          )}

          {(status === "active" ||
            status === "waiting_for_response" ||
            status === "in_verification_accepted") && (
            <div className={styles.Live}>
              <div className={styles.Icon}></div>
              EN VIVO
            </div>
          )}
          {(status === "created" ||
            status === "closed_successfully" ||
            status === "paid" ||
            status === "closed" ||
            status === "in_verification" ||
            status === "collapsed") && (
            <div className={styles.LivePending}>
              <div className={styles.Icon}></div>
              EN VIVO
            </div>
          )}
        </div>

        {/* Image section */}
        <div className={styles.Image}>
          <img
            src={`/assets/img/sources/${
              status === "closed" && bids !== undefined
                ? getClosedImg(bids)
                : imgByStatus[status]
            }.svg`}
            alt="Colibid"
          />
        </div>
      </div>

      {/* Body section */}
      <div className={styles.Content}>
        <div className={styles.Left}>
          <span className={styles.Date}>
            <b>Fecha:</b> {date}
          </span>
          <span className={styles.Title}>
            <b>Tipo:</b> {title}
          </span>
          <span className={styles.Status}>
            <b>Estado:</b> {statusChecker(status)}
          </span>
          <BodyText status={status} bidLen={bids} id={id} />
          {/* {Object.keys(bodyTextLinkBy).includes(status) ||
            (status === "closed" && bids === 0 && (
              <BodyText status={status} bidLen={bids} id={id} />
            ))} */}
        </div>
      </div>

      {/* Actions section */}
      <Stack
        id="button-group"
        direction="row"
        spacing={1}
        sx={{
          padding: "0.5rem",
        }}
      >
        {shouldUploadDocuments({ status, documentStatus }) && (
          <GoToManageDocumentsById mortgageId={id} fullWidth />
        )}

        {(status === "active" ||
          status === "created" ||
          status === "in_verification_accepted" ||
          (status === "closed" && bids !== 0)) && (
          <>
            {(status === "active" || status === "created") && (
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => {
                  actionToDelete();
                }}
                name="button_cancel"
                disabled={disabledButton === "button_cancel"}
                sx={{ mr: "4px" }}
              >
                Cancelar
              </Button>
            )}

            {status !== "created" && (
              <Button
                name="button_details"
                variant="outlined"
                fullWidth
                onClick={() => onViewClick()}
              >
                Ver
              </Button>
            )}
          </>
        )}

        {(status === "collapsed" ||
          (status === "closed" && bids === 0) ||
          status === "draft") &&
          mortagage.mortgage_data.mortgage.mode !== 3 &&
          mortagage.mortgage_data.mortgage.mode !== 4 && (
            <Button
              variant="outlined"
              onClick={() => {
                actionToRetry();
              }}
              fullWidth
              name="button_retry"
              disabled={disabledButton === "button_retry"}
              sx={{ mr: "4px", position: "left" }}
            >
              {status === "draft" ? "Continuar Aplicacion" : "Reintentar"}
            </Button>
          )}
        {(status === "closed_successfully" || status === "paid") && (
          <Button variant="contained" fullWidth onClick={() => onViewClick()}>
            Ver
          </Button>
        )}
      </Stack>

      {/* Footer section */}
      <div className={styles.Footer}>
        {status === "active" && (
          <>
            <div className={styles.Left}>
              <span className={styles.Title}>TIEMPO RESTANTE</span>
              <span className={styles.Countdown}>
                <Countdown
                  date={end}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <div>
                      {days}d : {hours}h : {minutes}m : {seconds}s
                    </div>
                  )}
                />
              </span>
            </div>
            {/* <div className={styles.Right}>
              <span className={styles.Title}>ÚLTIMA PUJA</span>
              <span className={styles.Percent}>0%</span>
            </div> */}
          </>
        )}

        {/* Footer section */}
        {status === "closed" && bids !== 0 && bids && (
          <>
            <div className={styles.Left}>
              <span className={styles.Title}>TIEMPO RESTANTE</span>
              <span className={styles.Countdown}>
                <Countdown
                  date={dateExpired(end)}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <div>
                      {days}d : {hours}h : {minutes}m : {seconds}s
                    </div>
                  )}
                />
              </span>
            </div>
            {/* <div className={styles.Right}>
              <span className={styles.Title}>ÚLTIMA PUJA</span>
              <span className={styles.Percent}>0%</span>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

function CustomSkeleton() {
  return (
    <Card>
      <CardContent>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Skeleton width={90} height={25} />
            <Skeleton width={90} height={25} />
          </Stack>
          <Skeleton
            width={"10rem"}
            height={"10rem"}
            variant="circular"
            sx={{ alignSelf: "center" }}
          />
          <Stack>
            <Skeleton width={150} height={25} />
            <Skeleton width={190} height={25} />
            <Skeleton width={110} height={25} />
          </Stack>
          <Stack direction="row" justifyContent="space-around">
            <Skeleton width={90} height={100} />
            <Skeleton width={90} height={100} />
            <Skeleton width={90} height={100} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
MortgageCard.Skeleton = CustomSkeleton;

export default MortgageCard;

const BodyText = ({
  status,
  bidLen,
  id,
}: {
  status: string;
  bidLen?: number;
  id?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div>
      <br />
      <span className={styles.Message}>
        {status === "closed" && getClosedBodyTextLink(bidLen)}
        {status !== "closed" && bodyTextLinkBy[status] && (
          <>
            {bodyTextLinkBy[status].text}
            {bodyTextLinkBy[status].link && (
              <>
                &nbsp;
                {status !== "expired" ? (
                  <Link
                    className={styles.links}
                    underline="none"
                    onClick={() => {
                      if (status === "in_verification_accepted") {
                        navigate(
                          `${bodyTextLinkBy[status].link as string}${id}`
                        );
                      } else {
                        navigate(bodyTextLinkBy[status].link as string);
                      }
                    }}
                  >
                    aquí
                  </Link>
                ) : (
                  <Link
                    className={styles.links}
                    underline="none"
                    href={bodyTextLinkBy[status].link as string}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </Link>
                )}
                &nbsp;
              </>
            )}
            {bodyTextLinkBy[status].endEmoji}
          </>
        )}
      </span>
    </div>
  );
};
