import * as yup from "yup";
import FormFields from "shared/components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import HandleSolicitorView from "../HandleSolicitorView";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";
import { EmploymentSituations } from "../../types";
import { parseISO, sub } from "date-fns";
import { FieldPath, useFormContext } from "react-hook-form";
import { ComponentProps, useEffect } from "react";
import { MortgageFormData } from "shared/types/postMortgageData";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import { Stack } from "@mui/material";
import StepLayout from "../StepLayout";
import { userSchema } from "../../validation/user";

function UserWorkDateForm() {
  const { mortgageFormData, onStepClick, onNext, onBack } =
    useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es la fecha de inicio de su trabajo actual?"
      resolver={yupResolver(
        yup.object({
          user: userSchema.pick([
            "work_date",
            "work_init_month",
            "work_init_year",
          ]),
          user_2: isConjuntaValidate(
            userSchema.pick(["work_date", "work_init_month", "work_init_year"])
          ),
        })
      )}
    >
      <UserWorkDateFormHandler />
    </StepLayout>
  );
}
function UserWorkDateFormHandler() {
  const { getValues } = useFormContext<MortgageFormData>();
  const [userWork] = getValues(["user.work"]);

  const isUndefinedMoreThanOneYear =
    Number(userWork) === EmploymentSituations.UNDEFINED_MORE_THAN_ONE_YEAR;

  const today = new Date();
  const maxDate = isUndefinedMoreThanOneYear ? sub(today, { years: 1 }) : today;
  const shouldDisableYear = (y: Date) => {
    if (isUndefinedMoreThanOneYear) {
      const currYear = y.getFullYear();
      const todayYear = today.getFullYear();
      return currYear === todayYear;
    }
    return false;
  };

  return (
    <WorkDateInput maxDate={maxDate} shouldDisableYear={shouldDisableYear} />
  );
}

function WorkDateInput({
  maxDate,
  shouldDisableYear,
}: {
  maxDate: Date;
  shouldDisableYear: (y: Date) => boolean;
}) {
  const { setValue, watch } = useFormContext();
  const [userWorkDate, user2WorkDate] = watch([
    "user.work_date",
    "user_2.work_date",
  ]);

  const setUserInitMonth = (user: FieldPath<MortgageFormData>, date: Date) => {
    const month = date?.toLocaleDateString("es-ES", {
      month: "2-digit",
    });
    setValue(user, month);
  };
  const setUserInitYear = (user: FieldPath<MortgageFormData>, date: Date) => {
    const year = date
      ?.toLocaleDateString("es-ES", { year: "numeric" })
      .toString();
    setValue(user, year);
  };
  useEffect(() => {
    if (userWorkDate) {
      setUserInitMonth("user.work_init_month", parseISO(userWorkDate));
      setUserInitYear("user.work_init_year", parseISO(userWorkDate));
    }
  }, [userWorkDate]);

  useEffect(() => {
    if (user2WorkDate) {
      setUserInitMonth("user_2.work_init_month", parseISO(user2WorkDate));
      setUserInitYear("user_2.work_init_year", parseISO(user2WorkDate));
    }
  }, [user2WorkDate]);

  return (
    <HandleSolicitorView
      RenderComponent={DatePickerContainer}
      individualProps={{
        name: "user.work_date",
        views: ["year", "month"],
        inputFormat: "MMM / yyyy",
        label: "Mes y Año",
        userWork: {
          userWorkMonth: "user.work_init_month",
          userWorkYear: "user.work_init_year",
        },
        disableFuture: true,
        disableHighlightToday: true,
        shouldDisableYear,
        maxDate,
      }}
      conjuntaProps={{
        name: "user_2.work_date",
        userWork: {
          userWorkMonth: "user_2.work_init_month",
          userWorkYear: "user_2.work_init_year",
        },
      }}
    />
  );
}

function DatePickerContainer(
  props: ComponentProps<typeof FormFields.DatePicker>
) {
  return (
    <Stack justifyContent="center" paddingX={5} height="100%">
      <FormFields.DatePicker {...props} />
    </Stack>
  );
}
export default UserWorkDateForm;
