import { Box, TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import styles from "./styles.module.scss";

export default function TextFieldWithWrapperText({
  text,
  name = "",
  label,
  ...rest
}: TextFieldProps & { text: string }) {
  const { control } = useFormContext();
  const [showBubble, setShowBubble] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <ReactScrollWheelHandler preventScroll={true}>
            <Box className={styles.FieldWrapper}>
              <TextField
                {...rest}
                {...field}
                onFocus={() => {
                  setShowBubble(true);
                }}
                onBlur={() => {
                  setShowBubble(false);
                  field.onBlur();
                }}
                error={!!error}
                required
                variant="outlined"
                size="small"
                fullWidth
                label={label}
                helperText={error?.message ? error.message : ""}
              />
              {showBubble && (
                <Box
                  className={styles.FieldBubble}
                  sx={{ top: Boolean(error) ? "38px" : "" }}
                >
                  {text}
                </Box>
              )}
            </Box>
          </ReactScrollWheelHandler>
        );
      }}
    />
  );
}
