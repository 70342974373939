import { Skeleton, Stack } from "@mui/material";

export default function DataGridClientSkeleton() {
  return (
    <Stack spacing={"2px"} sx={{ marginTop: "5px" }}>
      <Stack direction="row" spacing={"1px"}>
        <Skeleton height={55} width="100%" variant="rectangular" />
        <Skeleton height={55} width="100%" variant="rectangular" />
        <Skeleton height={55} width="100%" variant="rectangular" />
        <Skeleton height={55} width="100%" variant="rectangular" />
        <Skeleton height={55} width="100%" variant="rectangular" />
      </Stack>
      <Skeleton height={500} variant="rectangular" />
    </Stack>
  );
}
