import _ from "lodash";
import { getInitialFormData } from "shared/components/MortgageForm/utils";
import { MortgageRes } from "shared/types/BaseApiResponse";
import {
  MortgageFormData,
  MortgageModeRanges,
  PostMortgageData,
} from "shared/types/postMortgageData";
import { getCountryInfo } from "utils";
import { formatRateInterest, typeSpanishDNI } from "./helpers";

export const mortgageDataToForm = (
  data: MortgageRes["mortgage_data"]
): MortgageFormData => {
  // se vuelve a obtener valores iniciales, just in case, se agregaron campos nuevos al formulario
  const defaultVal = getInitialFormData({
    email: "",
    name: "",
    lastname: "",
  });
  // merge default val with curr val, so it will populate some field missing from curr val
  // hopefully this will stop lil girl sentry from crying 🙃
  // let formData: MortgageFormData = { ...defaultVal, ...data };
  let formData: MortgageFormData = _.merge(defaultVal, data);
  const country = getCountryInfo("ES").name_es;
  formData.user.residence = country;
  formData.user_2.residence = country;

  formData.data.fund_used_for = String(formData.data.fund_used_for);

  formData.data.tax_and_expenses = String(formData.data.tax_and_expenses);

  formData.property.house_situation = String(formData.property.house_situation);

  formData.user.marital_status = String(formData.user.marital_status);
  formData.user.children = String(formData.user.children);
  formData.user.current_house_situation = String(
    formData.user.current_house_situation
  );

  formData.property.type = String(formData.property.type);
  formData.property.value = String(formData.property.value);
  formData.solicitor_type = String(formData.solicitor_type);
  formData.user.work = String(formData.user.work);

  // user_2
  formData.user_2.marital_status = String(formData.user_2.marital_status);
  formData.user_2.children = String(formData.user_2.children);
  formData.user_2.current_house_situation = String(
    formData.user_2.current_house_situation
  );

  formData.user_2.work = String(formData.user_2.work);
  formData.user.salary = String(formData.user.salary);
  formData.user_2.salary = String(formData.user_2.salary);
  formData.user.work_init_year = String(formData.user.work_init_year);
  formData.user_2.work_init_year = String(formData.user_2.work_init_year);

  formData.mortgage_initial = String(formData.mortgage_initial);
  formData.data.period = String(formData.data.period);
  formData.data.amount = String(formData.data.amount);
  formData.data.pending_amount = String(formData.data.pending_amount);
  formData.data.remortgage_laps_years = String(
    formData.data.remortgage_laps_years
  );

  formData.data.mortgage_rate = String(formData.data.mortgage_rate);

  formData.data.interest_rate = Boolean(formData.data.interest_rate)
    ? parseFloat(formData.data.interest_rate as string)
    : "";

  if (formData?.customer_application_situation?.other_active_applications) {
    formData.customer_application_situation.other_active_applications = String(
      formData.customer_application_situation.other_active_applications
    );
  }

  formData.data.debtRatio = Number(formData.data.debtRatio);

  formData.property.hasEndorsement = String(formData.property.hasEndorsement);
  formData.property.constructionType = String(
    formData.property.constructionType
  );

  formData.user.residence_permit = String(formData.user.residence_permit);
  formData.user_2.residence_permit = String(formData.user_2.residence_permit);

  formData.property.area = String(formData.property.area);

  formData.property.project_approved.value = String(
    formData.property.project_approved.value
  );
  return formData;
};

export const postProcessData = (data: MortgageFormData): PostMortgageData => {
  let postdata: PostMortgageData = { mortgage_data: data };
  const mortgageMode = postdata.mortgage_data.mortgage.mode;

  postdata.mortgage_data.user.type_dni = typeSpanishDNI(data.user.dni);
  if (postdata.mortgage_data.user_2.type_dni) {
    postdata.mortgage_data.user_2.type_dni = typeSpanishDNI(data.user_2.dni);
  }
  /*   if (typeof data.user.residence === "object") {
    postdata.mortgage_data.user.residence = data.user.residence
      ? data.user.residence.name_es
      : "";
  }
  if (typeof data.user_2.residence === "object") {
    postdata.mortgage_data.user_2.residence = data.user_2.residence
      ? data.user_2.residence.name_es
      : "";
  } */

  postdata.mortgage_data.data.fund_used_for = Number(
    postdata.mortgage_data.data.fund_used_for
  );

  postdata.mortgage_data.data.tax_and_expenses = Number(
    postdata.mortgage_data.data.tax_and_expenses
  );

  postdata.mortgage_data.property.house_situation = Number(
    postdata.mortgage_data.property.house_situation
  );

  postdata.mortgage_data.user.marital_status = Number(
    postdata.mortgage_data.user.marital_status
  );
  postdata.mortgage_data.user.children = Number(
    postdata.mortgage_data.user.children
  );
  postdata.mortgage_data.user.current_house_situation = Number(
    postdata.mortgage_data.user.current_house_situation
  );

  postdata.mortgage_data.property.type = Number(
    postdata.mortgage_data.property.type
  );
  postdata.mortgage_data.property.value = Number(
    postdata.mortgage_data.property.value
  );
  postdata.mortgage_data.solicitor_type = Number(
    postdata.mortgage_data.solicitor_type
  );
  postdata.mortgage_data.user.work = Number(postdata.mortgage_data.user.work);

  // user_2
  postdata.mortgage_data.user_2.marital_status = Number(
    postdata.mortgage_data.user_2.marital_status
  );
  postdata.mortgage_data.user_2.children = Number(
    postdata.mortgage_data.user_2.children
  );
  postdata.mortgage_data.user_2.current_house_situation = Number(
    postdata.mortgage_data.user_2.current_house_situation
  );

  postdata.mortgage_data.user_2.work = Number(
    postdata.mortgage_data.user_2.work
  );
  postdata.mortgage_data.user.salary = Number(
    postdata.mortgage_data.user.salary
  );
  postdata.mortgage_data.user_2.salary = Number(
    postdata.mortgage_data.user_2.salary
  );
  postdata.mortgage_data.user.work_init_year = Number(
    postdata.mortgage_data.user.work_init_year
  );
  postdata.mortgage_data.user_2.work_init_year = Number(
    postdata.mortgage_data.user_2.work_init_year
  );

  postdata.mortgage_data.mortgage_initial = Number(
    postdata.mortgage_data.mortgage_initial
  );
  postdata.mortgage_data.data.period = Number(
    postdata.mortgage_data.data.period
  );
  postdata.mortgage_data.data.amount = Number(
    postdata.mortgage_data.data.amount
  );
  postdata.mortgage_data.data.pending_amount = Number(
    postdata.mortgage_data.data.pending_amount
  );
  postdata.mortgage_data.data.remortgage_laps_years = Number(
    postdata.mortgage_data.data.remortgage_laps_years
  );

  postdata.mortgage_data.data.mortgage_rate = Number(
    postdata.mortgage_data.data.mortgage_rate
  );

  postdata.mortgage_data.data.interest_rate = parseFloat(
    formatRateInterest(postdata.mortgage_data.data.interest_rate as String)
  );

  postdata.mortgage_data.customer_application_situation.other_active_applications =
    Number(
      postdata.mortgage_data.customer_application_situation
        .other_active_applications
    );

  postdata.mortgage_data.data.debtRatio = Number(
    postdata.mortgage_data.data.debtRatio
  );

  postdata.mortgage_data.revenue.monthly = Number(
    postdata.mortgage_data.revenue.monthly
  );

  postdata.mortgage_data.revenue.incomes_list =
    postdata.mortgage_data.revenue.incomes_list.map((rev) => ({
      ...rev,
      monthly_income: Number(rev.monthly_income),
      solicitor: Number(rev.solicitor),
    }));

  postdata.mortgage_data.revenue.expenses_list =
    postdata.mortgage_data.revenue.expenses_list.map((rev) => ({
      ...rev,
      debt_monthly: Number(rev.debt_monthly),
      debt_total: Number(rev.debt_total),
      solicitor: Number(rev.solicitor),
    }));

  postdata.mortgage_data.property.area = Number(
    postdata.mortgage_data.property.area
  );

  if (mortgageMode === 1) {
    postdata.mortgage_data.property.hasEndorsement = Number(
      postdata.mortgage_data.property.hasEndorsement
    );
  }

  if (mortgageMode === 4) {
    postdata.mortgage_data.property.project_approved.value = Number(
      postdata.mortgage_data.property.project_approved.value
    );
  }

  postdata.mortgage_data.property.constructionType = Number(
    postdata.mortgage_data.property.constructionType
  );
  postdata.mortgage_data.user.residence_permit = Number(
    postdata.mortgage_data.user.residence_permit
  );
  postdata.mortgage_data.user_2.residence_permit = Number(
    postdata.mortgage_data.user_2.residence_permit
  );

  return postdata;
};

export function isMortgageAlive(m: MortgageRes) {
  return getLiveMortgageStatus(m).includes(m.status);
}

export function getLiveMortgageStatus(m: MortgageRes) {
  const live: MortgageRes["status"][] = [
    "created",
    "active",
    "in_verification_accepted",
    "closed_successfully",
  ];
  if (m.bids_count && m.bids_count > 0) {
    live.push("closed");
  }
  return live;
}

export function getDiscartedMortgageStatus(m: MortgageRes) {
  const discarted: MortgageRes["status"][] = ["collapsed", "expired", "paid"];
  if (!m.bids_count) {
    discarted.push("closed");
  }
  return discarted;
}

export function getObjectRanges(
  mortgageMode: number,
  objectRanges: MortgageModeRanges
) {
  if (mortgageMode === 1) {
    return objectRanges.NEW_MORTGAGE;
  }
  if (mortgageMode === 2) {
    return objectRanges.REMORTGAGE;
  }
  if (mortgageMode === 3) {
    return objectRanges.CAPITAL_RAISE;
  }
  if (mortgageMode === 4) {
    return objectRanges.AUTOPROMOTOR;
  }
  return objectRanges.GLOBAL;
}
