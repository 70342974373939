import { Stack } from "@mui/material";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";
import { MortgageData } from "shared/types/postMortgageData";
import SkeletonInfoCard from "../InfoCard/SkeletonInfoCard";
import {
  HipotecaInfoCard,
  InformationGeneralInfoCard,
  PropertyInfoCard,
  FamilyEconomyInfoCard,
  WorkInfoCard,
} from "../InfoCard/mortgageCards";

export default function MortgageDetailCards({
  mortgage,
  acceptedBid,
}: {
  mortgage?: MortgageRes | IMortgageSchema;
  acceptedBid?: Bid;
}) {
  // casting as MortgageData cause mortage has union with outdated soon deprecated ImortgageSchema
  return (
    <Stack
      direction="row"
      gap={2}
      sx={(theme) => ({
        [`@media(max-width:${theme.breakpoints.values.md}px)`]: {
          flexWrap: "wrap",
        },
      })}
    >
      <Stack gap={2} flex={1}>
        {mortgage ? (
          <>
            <HipotecaInfoCard
              data={mortgage?.mortgage_data as MortgageData}
              acceptedBid={acceptedBid}
              showBackgroundColor
            />
            <WorkInfoCard
              data={mortgage?.mortgage_data as MortgageData}
              acceptedBid={acceptedBid}
              showBackgroundColor
            />
          </>
        ) : (
          <>
            <SkeletonInfoCard />
            <SkeletonInfoCard />
          </>
        )}
      </Stack>
      <Stack gap={2} flex={1}>
        {mortgage ? (
          <>
            <FamilyEconomyInfoCard
              data={mortgage?.mortgage_data as MortgageData}
              showBackgroundColor
            />
            <InformationGeneralInfoCard
              data={mortgage?.mortgage_data as MortgageData}
              showBackgroundColor
            />
          </>
        ) : (
          <>
            <SkeletonInfoCard />
            <SkeletonInfoCard />
          </>
        )}
      </Stack>
    </Stack>
  );
}
