import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import Countdown from "react-countdown";
import { sendOptCodeEmail, validateOtpCode } from "services/api/auth.service";
import FormFields from "shared/components/FormFields";
import { Center } from "shared/layouts";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import { toast } from "react-toastify";
import { GENERAL_ERROR_RES } from "shared/constants/general";

export default function CodeVerification() {
  const { onStepClick, mortgageFormData } = useMortgageFormContext();
  const [pinCode, setPinCode] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const { email } = mortgageFormData.user;

  const validateCode = (pin: string, email: string) => {
    validateOtpCode(pin, email)
      .then(() => {
        onStepClick()();

      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          setErrMsg("Código no válido");
        }
        console.log({ e });
      });
  };

  const handlePinChange = (pinCode: string) => {
    if (pinCode.length >= 0) {
      setPinCode(pinCode);
    }
    if (pinCode.length === 4) {
      validateCode(pinCode, email);
    }
  };

  return (
    <FormFields.WizardFormPage
      onNext={() => {}}
      onBack={onStepClick(false)}
      title="Por último, verifica tu email antes de validar la información"
      defaultValues={{ isVerified: false }}
      resolver={yupResolver(
        yup.object({
          isVerified: yup.boolean().isTrue("Código invalido"),
        })
      )}
    >
      <Center>
        <Stack alignItems="center">
          <Typography>
            Completa el código que te hemos enviado vía email
          </Typography>
          <ReactCodeInput
            type="text"
            fields={4}
            onChange={handlePinChange}
            value={pinCode}
            className="containerPinCode"
            name="pinCode"
            inputMode="full-width-latin"
            inputStyle={{
              font: "inherit",
              color: "currentcolor",
              padding: "4px 0px 5px",
              boxSizing: "content-box",
              background: "none",
              height: "2.43em",
              width: "3.51em",
              margin: "8px",
              display: "block",
              boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px 0px",
              animationName: "mui-auto-fill-cancel",
              animationDuration: "10ms",
              border: "1.5px solid #01857A",
              textAlign: "center",
              borderRadius: "6px",
            }}
          />
          <MemoResendCodeBtn email={email} />
          {/* <ResendCodeBtn email={email} /> */}
          {errMsg && <Typography color="error">{errMsg} </Typography>}
        </Stack>
      </Center>
    </FormFields.WizardFormPage>
  );
}

const OTP_CODE_RESEND_TIME = 5;

function ResendCodeBtn({ email }: { email: string }) {
  const [remainingTime, setRemainingTime] = useState(Date.now());

  const setTimer = () => {
    const time = Date.now() + OTP_CODE_RESEND_TIME * 1000;
    setRemainingTime(time);
    localStorage.setItem("resendCodeWaiting", "true");
    localStorage.setItem("resendCodeRemainingTime", time.toString());
  };

  const handleOnClick = () => {
    sendOptCodeEmail(email)
      .then(() => {
        setTimer();
        toast.success(
          "Código enviado exitosamente, por favor revise su correo 💌"
        );
      })
      .catch((err) => {
        toast.error(GENERAL_ERROR_RES + ", code: cvf");
      });
  };

  const handleComplete = () => {
    localStorage.removeItem("resendCodeWaiting");
    localStorage.removeItem("resendCodeRemainingTime");
  };

  useEffect(() => {
    const storedRemainingTime = localStorage.getItem("resendCodeRemainingTime");
    if (storedRemainingTime) {
      setRemainingTime(parseInt(storedRemainingTime));
    }
  }, []);

  return (
    <Countdown
      key={remainingTime}
      date={remainingTime}
      onComplete={handleComplete}
      renderer={({ seconds, completed }) => {
        if (completed) {
          return (
            <Button onClick={handleOnClick}>
              Reenviar codigo de verificación
            </Button>
          );
        } else {
          return <Typography>Espere {seconds} segundos</Typography>;
        }
      }}
    />
  );
}

const MemoResendCodeBtn = React.memo(ResendCodeBtn);
