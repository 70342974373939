import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
} from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// https://github.com/alexplumb/material-ui-phone-number/issues/111

import { toast } from "react-toastify";
import { MainRealEstateLayout } from "../../../../shared/layouts";
import ReactSpeedometer from "react-d3-speedometer";
import styles from "./styles.module.scss";

import Swal from "sweetalert2";
import { MortgageForm } from "shared/components";
import { MortagageRealStateAgencies } from "shared/types/BaseApiResponse";
import {
  agentAplyMortgage,
  agentCalculateScoring,
} from "services/api/agent.service";
import { getInitialFormData } from "shared/components/MortgageForm/utils";
import dataStorageService from "services/api/dataStorage.service";
import {
  MORTGAGE_FORM_STATE,
  MORTGAGE_FORM_VALUE,
} from "shared/constants/localStorage";

import { useLocalStorage } from "shared/hooks";

export interface Scoring {
  failures: Failures;
  calculation: Calculation;
}

export interface Calculation {
  reached_level: string;
  hits: number;
  total_conditions: number;
  reached_percent: number;
  match_percent: number;
}

export interface Failures {
  hight: Hight[];
  "medium-hight": Hight[];
  "medium-slow": Hight[];
  slow: Hight[];
}

export interface Hight {
  title: string;
  field: string;
  expected: number;
  operator: string;
  value: number;
}

export const sendDataToScore = async (
  data: any
): Promise<MortagageRealStateAgencies | null> => {
  try {
    const res = await agentCalculateScoring(data);

    return res.data.results;
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      toast.error("Usuario ya registrado 🧐");
    } else {
      toast.error(
        "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
      );
      console.log(error);
    }

    return null;
  }
};

export const clearMortgageFormValue = () => {
  dataStorageService.delData({ key: MORTGAGE_FORM_VALUE });
};

const Scoring: FunctionComponent<{}> = (props): ReactComponentElement<any> => {
  const { REACT_APP_SPECIAL_AGENT }: any = process.env;
  const navigate = useNavigate();
  const [dataScoring, setDataScoring] =
    useState<MortagageRealStateAgencies | null>(null);
  const [percentage, setPercentage] = useState(0);
  const [lowValue, setLowValue] = useState(false);
  const [calculateMortage, setCalculateMortage] = useState(false);
  const [initialData, setInicialData] = useState(
    getInitialFormData({
      mortgageMode: 1,
    })
  );
  const step = initialData ? 1 : 0;
  const [failures, setFailures] = useState<Failures | null>(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [valuesToFix, setValuesToFix] = useState({
    amount: 0,
  });
  const [email] = useLocalStorage("email");
  // Step is base in mortage base ( mode means ( nueva hipoteca, amoliacion de capital, mejora))

  const calculateScoring = (data: MortagageRealStateAgencies) => {
    const { mortgage } = data;
    let levels = Object.getOwnPropertyNames(
      mortgage.scoring.failures
    ).reverse();
    let value =
      250 *
      (levels.findIndex((item) => {
        return item === mortgage.scoring.calculation.reached_level;
      }) +
        1);
    value = value <= 1050 ? value : 1000;

    let isLowValue =
      mortgage.scoring.calculation.reached_percent < 50 ? true : false;

    setLowValue(isLowValue);
    setDataScoring(data);
    setPercentage(value);

    getFedback(data);
  };

  const applyMortgage = () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¿El cliente ${dataScoring?.first_name}  ${dataScoring?.last_name}  quiere solicitar la subasta?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (dataScoring !== null) {
          agentAplyMortgage(dataScoring.id, dataScoring.mortgage)
            .then(() => {
              toast.success("Solicitud registrada correctamente");
              clearMortgageFormValue();
              navigate("/agent");
            })
            .catch((e: any) => {
              toast.error(
                "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
              );
              console.log(e);
            });
        }
      }
    });
  };

  const aplyMortageWithCorrections = () => {
    let values = Swal.fire({
      title: "¿Estás seguro?",
      text: `¿El cliente ${dataScoring?.first_name}  ${dataScoring?.last_name}  quiere solicitar la subasta?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (dataScoring !== null) {
          agentAplyMortgage(dataScoring.id, dataScoring.mortgage)
            .then(() => {
              toast.success("Solicitud registrada correctamente");
              navigate("/agent");
            })
            .catch((e: any) => {
              toast.error(
                "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
              );
              console.log(e);
            });
        }
      }
    });
  };

  type FailuresKey = keyof Failures;
  const getFedback = (data: MortagageRealStateAgencies) => {
    let failures: Failures = data.mortgage.scoring.failures;
    let feedback: any = {};

    for (let key in failures) {
      if (failures[key as FailuresKey].length > 0) {
        feedback[key as FailuresKey] = failures[key as FailuresKey];
      }
    }

    let hasNotValuesToRecalculate = !valueToRecalculate();
    setFailures(feedback);
    setBtnDisabled(hasNotValuesToRecalculate);

    if (!hasNotValuesToRecalculate) {
      let data = valueToRecalculate();
    }
  };

  const valueToRecalculate = () => {
    const values =
      failures !== null ? getFirstNonEmptyProperty(failures) : undefined;

    if (values) {
      const dataAmount =
        values.filter((item) => item.field === "data.amount").length > 0
          ? true
          : false;

      const result = dataAmount
        ? values
            .filter((item) => item.field === "data.amount")
            .map((item) => {
              return { amount: item.field };
            })
        : false;

      return result;
    }
    return false;
  };

  const Fedbackmessage = () => {
    let res;
    res = (
      <>
        <Typography
          variant="h6"
          style={{ margin: "1rem auto", width: "60%", fontWeight: 400 }}
        >
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcolibid_logo_bird.svg"
            alt=""
            width="40px"
            height="40px"
          />
          Lo sentimos pero con los valores ingresados bancos y brokers no suelen
          enviar muchas ofertas. Puedes volver a intentar modificándolos.
        </Typography>
      </>
    );

    return res;
    /* res = "Si ajustas las siguientes propidades de la vivienda: ";
    const obj =
      failures !== null ? getFirstNonEmptyProperty(failures) : undefined;

    if (obj) {
      res =
        obj.length > 0 ? (
          <div>
            <ul>
              {res}
              {obj.map((item) => {
                let mensaje = "";
                mensaje = item.title + " " + item.expected;
                return (
                  <li className={`${styles.listItem} ${getLevelColor()}`}>
                    {" "}
                    {mensaje}{" "}
                  </li>
                );
              })}
              su probabilidad de obtener la hipoteca aumentará
            </ul>
          </div>
        ) : (
          ""
        );
    }

    return res; */
  };

  const getFirstNonEmptyProperty = (obj: Failures) => {
    if (dataScoring) {
      const isSlow =
        dataScoring.mortgage.scoring.calculation.reached_level === "slow";
      if (isSlow) return obj["medium-hight"];
      const order = ["slow", "medium-slow", "medium-hight", "hight"];
      for (const prop of order) {
        if (
          obj[prop as keyof Failures] &&
          obj[prop as keyof Failures].length > 0
        ) {
          return obj[prop as keyof Failures];
        }
      }
    }

    return null;
  };

  const clearMortgageFormValue = () => {
    dataStorageService.delData({ key: MORTGAGE_FORM_STATE });
  };

  const getLevelColor = () => {
    let color = styles.title;
    if (dataScoring !== null) {
      color = styles[dataScoring.mortgage.scoring.calculation.reached_level];
    }

    return color;
  };

  return (
    <MainRealEstateLayout>
      <Grid container className={styles.fullHeight}>
        {dataScoring === null ||
        dataScoring === undefined ||
        calculateMortage ? (
          <Grid item md={12} sm={12} xs={12}>
            <MortgageForm
              initialFormValue={initialData}
              initialStep={step}
              onSubmitForm={async (data) => {
                let res = await sendDataToScore(data);
                if (res !== null) {
                  setCalculateMortage(false);
                  calculateScoring(res);
                }
              }}
            />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item md={12} xs={12} sm={12}>
              <Typography
                variant="h5"
                display="block"
                marginBottom="1rem"
                textAlign="center"
                className={styles.title}
              >
                Prescoring de : {dataScoring.mortgage.fullname}
              </Typography>
            </Grid>

            <Grid item md={12} xs={12} sm={12}>
              <Typography
                variant="h5"
                display="block"
                marginBottom="2rem"
                textAlign="center"
                className={`${styles.title} ${getLevelColor()}`}
              >
                {dataScoring.mortgage.scoring.calculation.reached_percent}% de
                probabilidad de obtener esta hipoteca!
              </Typography>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              container
              spacing={1}
            >
              <ReactSpeedometer
                maxSegmentLabels={0}
                segments={
                  Object.keys(dataScoring.mortgage.scoring.failures).length
                }
                value={percentage - 50}
                segmentColors={["#F15A24", "#FDCD00", "#A4C626", "#49AF46"]}
                needleColor="#000080"
                currentValueText=" "
                textColor={"black"}
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
              container
              spacing={2}
              marginTop="-9rem"
            >
              <Typography
                variant="body1"
                display="block"
                textAlign="center"
                paragraph={true}
              >
                {lowValue && Fedbackmessage()}
              </Typography>
              <Grid
                item
                xs={8}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                {!lowValue && (
                  <Typography
                    variant="body1"
                    display="block"
                    textAlign="center"
                    paragraph={true}
                  >
                    <img
                      src="https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcolibid_logo_bird.svg"
                      alt=""
                      width="70px"
                      height="70px"
                    />
                    ¡Puedes solicitar una subasta hipotecaria para este usuario
                    con sólo un clic!
                  </Typography>
                )}

                <Button
                  size="medium"
                  onClick={() => {
                    if (!lowValue) {
                      applyMortgage();
                      return;
                    }
                    setCalculateMortage(true);
                  }}
                  variant="contained"
                  sx={{ mb: 2, height: "56px", width: "70%" }}
                >
                  {lowValue ? "Modificar Datos" : "Aplicar hipoteca"}
                </Button>
              </Grid>

              <Grid
                item
                md={5}
                xs={12}
                sm={12}
                container
                spacing="4"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {!lowValue && (
                  <Grid item md={6} xs={6} sm={12}>
                    <Button
                      size="small"
                      onClick={() => {
                        setCalculateMortage(true);
                      }}
                      fullWidth
                      variant="contained"
                    >
                      Modificar Datos
                    </Button>
                  </Grid>
                )}
                <Grid item md={6} xs={6} sm={12}>
                  <Button
                    size="small"
                    onClick={() => {
                      navigate("/agent");
                    }}
                    fullWidth
                    variant="contained"
                  >
                    Ir al home
                  </Button>
                </Grid>
                {lowValue && (
                  <Grid item md={12} xs={12} sm={12}>
                    <Typography
                      variant="body2"
                      mt="2rem"
                      className={styles.typoFont}
                      textAlign="center"
                    >
                      Estas son las principales causas de bajo puntaje crediticio:
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      <ul className={styles.typoFont}>
                        <li>Importe de Hipoteca menos a 80.000€</li>
                        <li>Porcentage de Hipoteca mayor a 80%</li>
                        <li>Periodo solicitado menor de 20 años</li>
                        <li>Ingresos netos totales menores de 2000€</li>
                      </ul>
                    </Typography>

                    {email !== REACT_APP_SPECIAL_AGENT && (
                      <Typography variant="caption" className={styles.captions}>
                        Los datos ingresados de tu cliente quedarán guardados y
                        podrás consultarlo en “Mis Clientes”
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </MainRealEstateLayout>
  );
};

export default Scoring;
