import { Box, BoxProps, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/InfoRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelperText from "../HelperText";

import stepStyles from "./styles.module.scss";

export interface CounterDisplayProps {
  errMsg?: string;
  count: string;
  label: string;
  helperText: string;
}

export default function CountersDisplayCard({
  counterDisplayListProps,
  boxProps,
}: {
  counterDisplayListProps: CounterDisplayProps[];
  boxProps?: BoxProps;
}) {
  return (
    <Box className={stepStyles.CalculationsWrapper} {...boxProps}>
      {counterDisplayListProps.map((e) => {
        return <CounterDisplay {...e} key={e.label} />;
      })}
    </Box>
  );
}

const CounterDisplay = ({
  errMsg,
  count,
  label,
  helperText,
}: CounterDisplayProps) => {
  return (
    <Box className={stepStyles.CalculationsResults}>
      <div className={`${errMsg ? stepStyles.WarningAlert : ""}`}>
        {errMsg ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />}
        <h6>{count}</h6>

        <HelperText text={helperText}>
          <Box className={stepStyles.labelContainer}>
            <small>
              {label} <InfoIcon />
            </small>
            {errMsg && <p>{errMsg}</p>}
          </Box>
        </HelperText>
      </div>
    </Box>
  );
};
