import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Checkbox,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

type Props = { name: string} & Omit<
  FormControlLabelProps,
  "control"
>;

function CheckboxInput({ name, ...formControlLabelProps }: Props) {
  const methods = useFormContext();
  return (
    <FormControl id='checkbox-input-form-control'>
      <FormControlLabel
        control={
          <Controller
            control={methods.control}
            name={name}
            render={({ field, fieldState, formState }) => {
              return <Checkbox {...field} />;
            }}
          />
        }
        {...formControlLabelProps}
      />
    </FormControl>
  );
}

export default CheckboxInput;
