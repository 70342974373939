import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import { IdName, TimestampedRecord } from "shared/types/BaseType";
import { BidderSubscription } from "shared/types/Profile";
import axios from "../../utils/axios";

export type ProductType = "power_bi_report";
export interface Product extends TimestampedRecord, IdName {
  payload: Record<any, any>;
  product_type: ProductType;
}
export interface Subscription extends TimestampedRecord, IdName {
  products: Product[];
}

export const subscribeToDemo = () => {
  return axios.post("/subscriptions/bidders/try");
};

export const reBuySubscription = (id: string) => {
  return axios.post<BaseApiSuccessRes<BidderSubscription>>(
    "/subscriptions/bidders/renew",
    { id },
  );
};
