import {
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FunctionComponent, ReactElement } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { toast } from "react-toastify";
import { addMortgage } from "../../../services/api/mortgages.service";
import { useLocalStorage, useProfile } from "../../hooks";
import {
    MortgageFormData,
    PostMortgageData,
    IncomesList
} from "../../types/postMortgageData";
import StepZero from "./components/StepZero";
import { format } from "date-fns";
import useMortgageForm from "./hooks/useMortgageForm";
import { INITIAL_STEP } from "shared/constants/general";
import { posthogUtils, sendTagEvent } from "utils/analytics";
import { postProcessData } from "utils/mortagageData";
import { prepareTempUploads } from "./utils";
import { Role } from "shared/types/Profile";
import ProgressCard, {
    ProgressGroupNode,
    ProgressSubGroupNode,
} from "./components/ProgressCard";
import { MortgageFormStepBuilder, StepView } from "./stepBuilder";
import MortgageFormProvider from "./context/MortgageFormProvider";
import React from "react";
import { MortgageFormState } from "./hooks/useMortgageFormReducer";
import InfoBanner from "./components/InfoBanner";
import { MortgageDevTool } from "./components";
import styles from "./styles.module.scss";
import { validarObjeto } from "utils/helpers";
import TagManager, { DataLayerArgs } from 'react-gtm-module';

export const M_FORM_GROUPS = {
  form: "Completa el formulario",
  check: "Revisa los datos ingresados",
};
export type MFormGroupKey = keyof typeof M_FORM_GROUPS;

export const M_FORM_SUB_GROUPS = {
  personalData: "Datos personales",
  mortgageData: "Datos de la hipoteca",
  workInfo: "Información laboral",
  revenue: "Ingresos y egresos",
  movilVerification: "Verificación email",
  validateData: "Validar datos",
};
export type MFormSubGroupKey = keyof typeof M_FORM_SUB_GROUPS;

export interface IMortgageFormProps {
  initialStep?: number;
  onSubmitForm?: (data: any) => void;
  onFormReset?: () => void; // Add this line
  initialFormValue?: MortgageFormData;
}

// Note: desde wordpress se recibe que mortgage mode eligio, y si eligio alguno
// debe saltarse el primer paso
const MortgageForm: FunctionComponent<IMortgageFormProps> = ({
  initialStep = 0,
  onSubmitForm,
  onFormReset,
  initialFormValue,
}): ReactElement<any> => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // extract some initial values
  const [role] = useLocalStorage("role");
  const isAgent = role === "realstate";

  const mortgageFormMethods = useMortgageForm(initialStep, initialFormValue);
  const {
    mortgageFormState,
    isUserLogin,
    isUserVerified,
    profile,
    setMortgageFormState,
    clearMortgageFormState,
    setStep,
  } = mortgageFormMethods;
  const { mortgageFormData, mortgageFormStep: activeStep } = mortgageFormState;

  const mortgageMode = mortgageFormData?.mortgage?.mode;

  const onSubmit = () => {
    const data = { ...mortgageFormData };

    if (!validarObjeto(data)) {
      toast.error(
        "Lo sentimos hay algún dato incompleto en el formulario por favor revisar la información ingresada 😔"
      );
      return;
    }

    var postdata: PostMortgageData & Partial<MortgageFormState> =
      postProcessData(data);
    //TODO: add the geocoding location to data and post it
    postdata.mortgage_data.form_init_date = mortgageFormState.formInitDate;
    postdata.map_feature = mortgageFormState.map.selectedFeature;

    try {
      // Register GA4 history
      sendGAEvent({ step: 7, profile, role, location });

      // Register GTM Event with user data
      const userEmailData: DataLayerArgs = {
        dataLayer: {
          event: 'form_success',
          userEmail: data.user.email 
        },
      };
      TagManager.dataLayer(userEmailData);
    } catch (error) {
        console.log("Error on G services: ", error);
    }

    postdata.mortgage_data.temp_uploads = prepareTempUploads(postdata);

    if (onSubmitForm) {
      onSubmitForm(postdata);
      return;
    }

    sendMortgage(postdata, "/client/mortgages/form/success/completed");
  };

  const sendMortgage = (postData: PostMortgageData, link: string) => {
    addMortgage(postData)
      .then((response: any) => {
        // stepClickHandler()(); // se necesita este boobs??
        toast.success("Solicitud registrada correctamente");
        clearMortgageFormState();
        // subject.send(HAS_FORM, false);
        //TODO investigage why localstorage become undefined?, could it be in router?
        navigate(link);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  };

  const onClickResetForm = () => {
    clearMortgageFormState();
  };

  const stepClickHandler =
    (next: boolean = true) =>
    (form?: MortgageFormData) => {
      let step = mortgageFormState.mortgageFormStep;
      if (next) {
        step = step + 1;
      } else {
        step = step - 1;
      }

      if (form) {
        const newFormData = { ...mortgageFormData, ...form };

        // Clean incomes list
        newFormData.revenue.incomes_list = newFormData.revenue.incomes_list.filter((e:IncomesList) => e.solicitor);

        setMortgageFormState({
          ...mortgageFormState,
          mortgageFormData: newFormData,
          mortgageFormStep: step,
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        posthogUtils.captureEvent(`formStep=${step}`, {
          profile: profile,
          role: role,
          location: location,
        });
        sendGAEvent({ step, profile, role, location });
        searchParams.set(INITIAL_STEP, step.toString());
        setSearchParams(searchParams);
      }
    };

  const stepViews = new MortgageFormStepBuilder({
    mortgageMode,
    initialStep: { component: StepZero },
    isUserLogin: Boolean(profile),
    isUserVerified,
    role,
  }).build();

  const progressGroupNodes = getProgressGroupNodes(stepViews, activeStep);

  const isLastStep = activeStep >= stepViews.length - 1;
  const stepView: StepView<MortgageFormData> | undefined =
    // check is activeStep is greater, cause activeStep might be a cache
    // stepViews maybe will not have code verification step
    stepViews?.[
      activeStep > stepViews.length - 1 ? stepViews.length - 1 : activeStep
    ];
  let StepComp = stepView?.component;

  if (isUserLogin && !isAgent && profile && !profile.can_create_mortgage) {
    return (
      <>
        <Box className={styles.SuccessMessageImage}>
          <img
            src="/assets/img/sources/under_construction.svg"
            alt=""
            width="50%"
          />
        </Box>
        <Typography variant="subtitle1" align="center">
          Ya tienes un proceso de solicitud de hipoteca el cuál requiere tu
          atención, por favor dirígete{" "}
          <Link className={styles.links} to="/client/mortgages">
            a mis solicitudes
          </Link>{" "}
          para ver su estado.
        </Typography>
      </>
    );
  }
  return (
    <MortgageFormProvider
      value={{
        mortgageFormData,
        progressGroupNodes,
        activeStep,
        isLastStep,
        stepViews,
        onSubmit,
        onStepClick: stepClickHandler,
        onNext: stepClickHandler(),
        onBack: stepClickHandler(false),
        ...mortgageFormMethods,
      }}
    >
      <Stack
        id="mortgage-form"
        className={styles.mortgageForm}
        // sx={{
        //   height: "100%",
        //   position: "relative",
        //   top: "-1rem",
        // }}
      >
        <InfoBanner activeStep={activeStep} />
        <MortgageDevTool />
        <Stack direction="row" height="100%">
          <Stack id="form-steps" flex="2 0">
            {stepView && React.createElement(stepView?.component)}
          </Stack>
          {Boolean(mortgageFormState.selectedStepView) ||
            (!isLastStep && (
              <Stack
                id="stack-progress-card"
                flex="1 0"
                justifyContent="center"
                alignItems="center"
                sx={{
                  ...(matches && {
                    position: "absolute",
                    right: 0,
                    top: "10rem",
                  }),
                }}
              >
                <ProgressCard
                  stepByGroups={progressGroupNodes}
                  currStep={activeStep}
                  setStep={setStep}
                />
              </Stack>
            ))}
        </Stack>
        {/* {activeStep !== 0 && (
          <Link component="button" onClick={onClickResetForm} variant="body1">
            Reiniciar el Formulario
          </Link>
        )} */}
      </Stack>
    </MortgageFormProvider>
  );
};

export default MortgageForm;

function sendGAEvent({
  step,
  profile,
  role,
  location,
}: {
  step?: number;
  profile: ReturnType<typeof useProfile>["profile"];
  role: Role;
  location: Location;
}) {
  if (profile) {
    switch (step) {
      case 1:
        sendTagEvent({
          event: "app_formulario_registro_step_1",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_step_1",
          page_category: location.search,
          page_location: `${location.pathname}/step_1`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });

        break;
      case 2:
        sendTagEvent({
          event: "app_formulario_registro_step_2",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_step_2",
          page_category: location.search,
          page_location: `${location.pathname}/step_2`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });

        break;
      case 3:
        sendTagEvent({
          event: "app_formulario_registro_step_3",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_step_3",
          page_category: location.search,
          page_location: `${location.pathname}/step_3`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });

        break;
      case 4:
        sendTagEvent({
          event: "app_formulario_registro_step_4",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_step_4",
          page_category: location.search,
          page_location: `${location.pathname}/step_4`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
        break;
      case 5:
        sendTagEvent({
          event: "app_formulario_registro_step_5",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_step_5",
          page_category: location.search,
          page_location: `${location.pathname}/step_5`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
        break;
      case 6:
        sendTagEvent({
          event: "app_formulario_registro_confirmation_step_6",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_completed_step_6",
          page_category: location.search,
          page_location: `${location.pathname}/step_6`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
        break;
      case 7:
        sendTagEvent({
          event: "app_formulario_registro_completed_step_7",
          method: "email",
        });

        sendTagEvent({
          event: "page_view",
          step_name: "app_formulario_registro_step_7",
          page_category: location.search,
          page_location: `${location.pathname}/step_7`, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
        break;

      default:
        sendTagEvent({
          event: "app_formulario_redirecto_to_calculator",
          method: "email",
        });

        break;
    }
  }
}

/**mortgageForm helper func*/
function getProgressGroupNodes(
  stepViews: StepView<MortgageFormData>[],
  activeStep: number
) {
  const formGroups: ProgressSubGroupNode[] = [];
  const checkGroups: ProgressSubGroupNode[] = [];
  stepViews.forEach((s, i) => {
    if (!s.group) return;
    if (!s.subGroup) return;
    const completedStep = i < activeStep;
    if (s.group === "form") {
      const existingSubGroup = formGroups.find((e) => e.group === s.subGroup);
      if (existingSubGroup) {
        existingSubGroup.completedSteps.push(completedStep);
      } else {
        formGroups.push({
          group: s.subGroup,
          completedSteps: [completedStep],
        });
      }
    } else {
      const existingSubGroup = checkGroups.find((e) => e.group === s.subGroup);
      if (existingSubGroup) {
        existingSubGroup.completedSteps.push(completedStep);
      } else {
        checkGroups.push({
          group: s.subGroup,
          completedSteps: [completedStep],
        });
      }
    }
  });

  const progressGroupNodes: ProgressGroupNode[] = [];
  stepViews.forEach((s, i) => {
    if (!s.group) return;
    const existingGroup = progressGroupNodes.find((e) => e.group === s.group);
    if (!existingGroup) {
      progressGroupNodes.push({
        group: s.group,
        subGroups: s.group === "form" ? formGroups : checkGroups,
      });
    }
  });
  return progressGroupNodes;
}
