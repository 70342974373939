import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import {
  Skeleton,
  Stack,
  Step,
  StepButton,
  StepIcon,
  StepIconProps,
  StepLabel,
  Stepper,
  StepperProps,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import React from "react";
import { ReactNode, useRef } from "react";

import { AiOutlineLike } from "react-icons/ai";
import { FaFileSignature } from "react-icons/fa";
import { GiHouseKeys } from "react-icons/gi";
import { GoCloudUpload, GoGraph } from "react-icons/go";
import { GrNotes } from "react-icons/gr";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IconType } from "react-icons/lib";
import { MdOutlinePriceCheck } from "react-icons/md";
import { RiAuctionFill, RiBankLine } from "react-icons/ri";
import { TbDeviceAnalytics } from "react-icons/tb";
import { CrmFields } from "shared/types/Crm";
import { PrincipalPanelState, PrincipalPanelStep } from "../../usePPReducer";

/*
[
  [
    {
        "name": "registered",
        "label": "Registrado"
    },
    {
        "name": "active_auction",
        "label": "Subasta activa"
    },
    {
        "name": "closed_auction",
        "label": "Subasta cerrada"
    },
    {
        "name": "accepted_offer",
        "label": "Oferta aceptada"
    },
    {
        "name": "documents",
        "label": "Documentos"
    },
    {
        "name": "contactado",
        "label": "Contactado"
    },
    {
        "name": "viable",
        "label": "Viable"
    },
    {
        "name": "notaEncargoFirmada",
        "label": "Nota de encargo"
    },
    {
        "name": "aprobacionBancaria",
        "label": "Preaprobación bancaria"
    },
    {
        "name": "tasadoraRealizada",
        "label": "Tasación"
    },
    {
        "name": "FEIN",
        "label": "FEIN"
    },
    {
        "name": "fechaNotaria",
        "label": "Notaría"
    },
    {
        "name": "cerrado",
        "label": "Cerrado"
    }
]
]
*/
export const PPStepperData: {
  [k: CrmFields["label"]]: {
    icon: IconType;
  };
} = {
  registered: {
    icon: GoGraph,
  },

  active_auction: {
    icon: GoGraph,
  },
  closed_auction: {
    icon: RiAuctionFill,
  },
  accepted_offer: {
    icon: AiOutlineLike,
  },
  documents: {
    icon: GoCloudUpload,
  },
  notaEncargoFirmada: {
    icon: GrNotes,
  },
  altaSistema: {
    icon: RiBankLine,
  },
  aprobacionBancaria: {
    icon: RiBankLine,
  },
  tasadoraRealizada: {
    icon: MdOutlinePriceCheck,
  },
  FEIN: {
    icon: IoDocumentTextOutline,
  },
  fechaNotaria: {
    icon: FaFileSignature,
  },
  operacionRiesgos: {
    icon: TbDeviceAnalytics,
  },
  viable: {
    icon: TbDeviceAnalytics,
  },
  cerrado: {
    icon: GiHouseKeys,
  },
  contactado: {
    icon: FaFileSignature,
  },
};

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: "calc(-40% + 20px)",
    right: "calc(60% + 20px)",
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      left: "calc(-30% + 20px)",
    },
  },
}));

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  // all: "unset",
  // cursor: "pointer",
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "transparent",
  zIndex: 1,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(0, 0, 0, 0.26)",
  "& > svg > path": {
    stroke: "currentColor",
  },
  ...(ownerState.active && {
    color: "#debf71",
  }),
  ...(ownerState.completed && {
    color: theme.palette.primary.main,
  }),
}));

export const CustomStepIcon =
  (isCurrStep: boolean, Icon?: IconType) => (props: StepIconProps) => {
    const { active, completed, className } = props;

    // if (props.icon) return props.icon as React.ReactElement;
    const fallbackIcons: { [index: string]: React.ReactElement } = {
      1: <SettingsIcon />,
      2: <GroupAddIcon />,
      3: <VideoLabelIcon />,
    };

    let iconsKey =
      typeof props.icon === "number" ? props.icon % 3 || 3 : props.icon;
    return (
      <StepIconRoot
        ownerState={{ completed, active: active || isCurrStep }}
        className={className}
      >
        {Icon ? <Icon size={24} /> : fallbackIcons[String(iconsKey)]}
      </StepIconRoot>
    );
  };

interface Props extends StepperProps {
  steps: PrincipalPanelState["steps"];
  currStep: number;
  stepCompleted: { [k: number]: boolean };
  BtnOnClick: (idx: number) => void;
}

// recibir mortgage por props para poder utilizar los bid en comp padre
export default function PPSteppers({
  steps,
  currStep,
  stepCompleted,
  BtnOnClick,
  ...rest
}: Props) {
  // elevar este mortgage y el fetch en el useEffect y recibirlo por props, asi el padre puede utilizar los bids si hay
  const stepperRef = useRef<HTMLDivElement>(null);

  const handleClick =
    (idx: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      BtnOnClick(idx);
      const stepperRect = stepperRef.current?.getBoundingClientRect();
      if (!stepperRect) return;
      const iconRect = e.currentTarget.getBoundingClientRect();
      const iconOffset = iconRect.left - stepperRect.left;
      const scrollPos = iconOffset - stepperRect.width / 2 + iconRect.width / 2;
      // tomar la posicion del scroll si es q ya esta scrolleada para ser sumada
      const stepperLeftPos = stepperRef.current?.scrollLeft ?? 0;
      stepperRef.current?.scrollTo({
        left: scrollPos + stepperLeftPos,
        behavior: "smooth",
      });
    };

  return (
    <Stack
      sx={{ width: "100%", marginTop: 2 }}
      spacing={4}
      id="customized-steppers"
    >
      {steps ? (
        <Stepper
          {...rest}
          ref={stepperRef}
          alternativeLabel
          nonLinear
          connector={<Connector />}
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {steps.map(({ step }, idx) => {
            if (step)
              return (
                <Step key={step.label} completed={stepCompleted[idx]}>
                  <StepButton onClick={handleClick(idx)}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon(
                        currStep === idx,
                        PPStepperData[step.name]?.icon
                      )}
                    >
                      {step?.label}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
          })}
        </Stepper>
      ) : (
        <SkeletonPPStepper />
      )}
    </Stack>
  );
}

export function SkeletonPPStepper() {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Stack width="100%">
        <Skeleton height={24} width="100%" variant="rectangular" />
        <Skeleton height={24} width="100%" variant="text" />
      </Stack>
      {[...Array(7)].map((e, i) => (
        <React.Fragment key={i}>
          <Skeleton height={5} width="100%" variant="rectangular" />
          <Stack width="100%">
            <Skeleton height={24} width="100%" variant="rectangular" />
            <Skeleton height={24} width="100%" variant="text" />
          </Stack>
        </React.Fragment>
      ))}
    </Stack>
  );
}
