import React, { useEffect, useState } from "react";

import {
  Typography,
  Collapse,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ExpandMore from "./ExpandMore";

import styles from "../styles.module.scss";
import { defaultFiltersForTypes } from "../helpers";
import { interestTypeOptions } from "shared/components/MortgageForm/components/StepTwo/utils";

type Props = {
  filters: any;
  readOnly: boolean;
  changeValue: (key: string, value: any) => void;
};

// convert this to propertyexistence
export default function InterestFilter({
  readOnly,
  filters,
  changeValue,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [autoBididing, setAutobiding] = useState(false);
  const [options, setOptions] = useState(interestTypeOptions);

  useEffect(() => {
    if (readOnly) {
      setAutobiding(true);
    }
  }, []);

  useEffect(() => {
    if (autoBididing) {
      handleMortgage("todos", true, "mortageinterestType");
      setOptions(options.filter((item) => item.value !== "todos"));
    }
  }, [autoBididing]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;

    changeValue("interestType", value);
  };

  const handleMortgage = (
    value: number | string,
    status: boolean,
    toValue: keyof typeof defaultFiltersForTypes
  ) => {
    let items = filters.mortageinterestType;
    if (value === "todos") {
      if (filters.mortageinterestType.length === 4) {
        changeValue("mortageinterestType", []);
      } else {
        changeValue(
          "mortageinterestType",
          interestTypeOptions.map((item) => item.value)
        );
      }
    } else {
      if (status && !items.includes(value)) {
        items.push(value);
      }
      if (status === false && items.includes(value)) {
        items = items.filter((num: number | string) => num !== value);
      }

      if (toValue === "mortageinterestType") {
        changeValue("mortageinterestType", items);
      }
    }
  };

  return (
    <>
      <Typography variant="h6" className={styles.tittleFilter}>
        <TrendingUpIcon
          sx={{
            color: "#656565",
            marginRight: "1rem",
          }}
        />
        Tipos de interés
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
          style={{
            float: "right",
          }}
        >
          {" "}
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      {!autoBididing && readOnly ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FormControl>
            <RadioGroup value={filters.interestType} onChange={handleOnChange}>
              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Interés fijo"
                value="fijo"
                control={<Radio />}
              />

              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Interés variable"
                value="variable"
                control={<Radio />}
              />

              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Interés mixto"
                value="mixto"
                control={<Radio />}
              />

              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Todos"
                value="todos"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Collapse>
      ) : (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {options.map((p, i) => {
            let elements = filters.mortageinterestType;

            const hasElement = elements ? elements.length > 0 : false;

            const currVal: string[] = elements;

            const isCheck = hasElement ? currVal.includes(p.value) : false;

            return (
              <FormControlLabel
                key={p.id}
                className={styles.LabelCheckbox}
                label={p.label}
                control={
                  <Checkbox
                    checked={isCheck}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let val = currVal;
                      if (currVal.includes(p.value)) {
                        val = currVal.filter((v) => v !== p.value);
                      } else {
                        val.push(p.value);
                      }

                      handleMortgage(
                        p.value,
                        event.target.checked,
                        "mortageinterestType"
                      );
                    }}
                  />
                }
              />
            );
          })}
        </Collapse>
      )}
    </>
  );
}
