import { FunctionComponent, ReactElement, ReactNode } from "react";

export interface ITabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
  style?: React.CSSProperties;
  className?: string
}
const TabPanel: FunctionComponent<ITabPanelProps> = ({
  value,
  index,
  children,
  style,
  className
}): ReactElement<any> => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      className={className}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
