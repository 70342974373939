import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { MainRealEstateLayout } from "../../../shared/layouts";
import { useLocalStorage } from "../../../shared/hooks";

import styles from "./styles.module.scss";

import { NumberBox } from "../Components";
import { clearMortgageFormValue } from "./Scoring";
import dataStorageService from "services/api/dataStorage.service";
import { MORTGAGE_FORM_VALUE } from "shared/constants/localStorage";

const { REACT_APP_SPECIAL_AGENT }: any = process.env;

const Prescoring: FunctionComponent<{}> = (
  props
): ReactComponentElement<any> => {
  const [email] = useLocalStorage("email");
  const navigate = useNavigate();
  return (
    <MainRealEstateLayout>
      <Container className={styles.Wrapper}>
        <Grid container className={styles.fullHeight}>
          <Grid item md={12} xs={12} sm={12}>
            <Typography
              color="primary"
              variant="h5"
              display="block"
              marginBottom="1rem"
              textAlign="center"
              className={styles.title}
            >
              ¿Necesitas saber de forma rápida si un cliente obtendría una
              hipoteca?
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} sm={12} container>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/Prescoring.gif"
                alt=""
                width="140px"
                height="140px"
              />
              <Typography variant="h5" display="block">
                En 4 minutos lo decimos
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            container
            spacing={2}
          >
            <Grid item xs={8}>
              <Button
                size="large"
                onClick={() => {
                  dataStorageService
                    .delData({ key: MORTGAGE_FORM_VALUE })
                    .then(() => {
                      localStorage.removeItem("isEditingForm");
                      navigate("/agent/scoring/result");
                    });
                }}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, height: "56px" }}
              >
                CONSULTAR
              </Button>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} sm={12} container>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src="https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcolibid_logo_bird.svg"
                alt=""
                width="70px"
                height="70px"
              />
              <Typography variant="h5" display="block">
                Nuestro modelo tiene una{" "}
                <span className={styles.typographic}> precisión del 98%.</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} sm={12} container>
            {email !== REACT_APP_SPECIAL_AGENT ? (
              <>
                <Grid item xs={4}>
                  <NumberBox
                    number="01"
                    colorNumber="#D8D8F6"
                    title="Completa
                el formulario"
                    paragraph="Deberás completarlo junto con el cliente. ¡Sólo os llevará 4 minutos!"
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberBox
                    number="02"
                    colorNumber="#E4F086"
                    title="Obtén el 
                Consultar Prescoring"
                    paragraph="Automáticamente sabrás la viabilidad de su hipoteca a través del  prescoring."
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberBox
                    number="03"
                    colorNumber="#01807A"
                    title="Síguelo en 
                tu panel"
                    paragraph={`Quedará registrado en tu panel de "mis clientes" para que puedas hacer su seguimiento.`}
                  />
                </Grid>{" "}
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <NumberBox
                    number="01"
                    colorNumber="#D8D8F6"
                    title="Completa
                el formulario"
                    paragraph="Deberás completarlo junto con el cliente. ¡Sólo os llevará 4 minutos!"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberBox
                    number="02"
                    colorNumber="#E4F086"
                    title="Obtén el 
                Consultar Prescoring"
                    paragraph="Automáticamente sabrás la viabilidad de su hipoteca a través del  prescoring."
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainRealEstateLayout>
  );
};

export default Prescoring;
