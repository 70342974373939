import { useReducer } from "react";
import { MapBoxGeoLocationRes } from "services/api/map.service";
import { MortgageFormData } from "shared/types/postMortgageData";
import { StepView } from "../stepBuilder";

type MapFeature = MapBoxGeoLocationRes["features"][number];
export interface MortgageFormState {
  formInitDate: string;
  /**also is a activeStep*/
  mortgageFormStep: number;
  mortgageFormData: MortgageFormData;
  map: {
    selectedFeature?: MapFeature;
    features: MapFeature[];
  };
  isTermAccepted: boolean;
  selectedStepView?: StepView<MortgageFormData>
}
export type MortgageFormActions =
  | { type: "SET_MORTGAGE_STATE"; payload: MortgageFormState }
  | { type: "SET_MORTGAGE_FORM_DATA"; payload: MortgageFormData}
  | { type: "SET_MORTGAGE_STEP"; payload: number }
  | { type: "SET_FEATURE"; payload?: MapFeature }
  | { type: "SET_FEATURES"; payload: MapFeature[] }
  | { type: "SET_IS_TERM_ACCEPTED"; payload: boolean }
  | { type: "SET_SELECTED_STEPVIEW"; payload: StepView<MortgageFormData> | undefined};

function mortgageFormReducer(
  state: MortgageFormState,
  action: MortgageFormActions
): MortgageFormState {
  switch (action.type) {
    case "SET_MORTGAGE_STATE": {
      return action.payload
    }
    case "SET_MORTGAGE_FORM_DATA": {
      return { ...state, mortgageFormData: action.payload };
    }
    case "SET_MORTGAGE_STEP": {
      return { ...state, mortgageFormStep: action.payload };
    }
    case "SET_FEATURE": {
      return {
        ...state,
        map: {
          ...state.map,
          selectedFeature: action.payload,
        },
      };
    }
    case "SET_FEATURES": {
      return {
        ...state,
        map: {
          ...state.map,
          features: action.payload,
        },
      };
    }
    case "SET_IS_TERM_ACCEPTED": {
      return { ...state, isTermAccepted: action.payload };
    }
    case 'SET_SELECTED_STEPVIEW':{
      return {...state, selectedStepView: action.payload}
    }
    default:
      return state;
  }
}

function useMortgageFormReducer(initialMortgageFormState: MortgageFormState) {
  return useReducer(mortgageFormReducer, initialMortgageFormState);
}
export default useMortgageFormReducer;
