import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  putPaymentMethod,
  getPaymentMethod,
  agentPutPaymentMethod,
  getAgencyPaymentMethods,
} from "services/api";
import useLocalStorage from "./useLocalStorage";
import { BrokerProfile } from "shared/types/Profile";

export default function useBankAccountUser(
  profile?: BrokerProfile | undefined
) {
  const [role] = useLocalStorage("role");
  const [paymentData, setPaymentData] = useState({
    BIC_SWIFT: "",
    IBAN: "",
    account_holder_name: "",
    identification: "",
    user: "",
    DNI_CIE: "",
  });

  useEffect(() => {
    if (role === "realstate") {
      getAgencyPaymentMethods()
        .then((response: any) => {
          setPaymentData(response.data.results);
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (role === "broker" && profile) {
      if (profile.accept_financed_commission) {
        getPaymentMethod()
          .then((response: any) => {
            setPaymentData(response.data);
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    }
  }, [profile]);

  const setPaymentValue = (paymentData: any) => {
    if (role === "broker") {
      let data = {
        user: paymentData.user,
        account_holder_name: paymentData.account_holder_name,
        identification: paymentData.identification,
        IBAN: paymentData.IBAN,
        BIC_SWIFT: paymentData.BIC_SWIFT,
      };
      putPaymentMethod(data)
        .then((response: any) => {
          if (response.data) {
            setPaymentData(response.data);
          }
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    }
    if (role === "realstate") {
      let data = {
        account_holder_name: paymentData.account_holder_name,
        identification: paymentData.identification,
        IBAN: paymentData.IBAN,
        BIC_SWIFT: paymentData.BIC_SWIFT,
        DNI_CIE: paymentData.identification,
      };
      agentPutPaymentMethod(data)
        .then((response: any) => {
          if (response.data) {
            setPaymentData(response.data.results);
          }
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    }
  };

  return { paymentData, setPaymentValue };
}
