import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
  useCallback,
  ChangeEvent,
  FormEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Avatar,
  CardContent,
  Card,
  Box,
  Button,
  OutlinedInput,
  InputAdornment,
  Divider,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  TableContainer,
  Dialog,
  TextField,
  Tabs,
  Tab,
  Stack,
  Alert,
} from "@mui/material";
import { toast } from "react-toastify";
import {
  TabPanel,
  PaginationBar,
  NotResults,
} from "../../../shared/components";

import { DinamycLayout } from "../../../shared/layouts";
import { PageTitle, Breadcrumbs, BannerInfo } from "../../../shared/components";
import * as ibantools from "ibantools";

import { useLocalStorage, useLocalStorages } from "../../../shared/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPaymentMethod,
  putPaymentMethod,
  getPaymentsList,
} from "../../../services/api";

import styles from "./styles.module.scss";
import { formatCurrency, validateSpanishDNI } from "utils/helpers";

const ReferralPay: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role] = useLocalStorage("role");
  const [tab, setTab] = useState(0);
  const [shareURL, setShareURL] = useState("");
  const [rewardData, setRewardData] = useState({
    paid: 0,
    available: 0,
  });
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [shareMessage, setShareMessage] = useState("Registrate en Colibid");
  const [shareiFrame, setShareiFrame] = useState("");
  const [referral, setReferral] = useState({
    created_mortgage: 0,
    name: "",
    referral: "",
    registered: 0,
    successful_mortgage: 0,
    paid_mortgage: 0,
  });
  const [paymentData, setPaymentData] = useState({
    BIC_SWIFT: "",
    IBAN: "",
    account_holder_name: "",
    identification: "",
    user: "",
  });
  const [paymentList, setPaymentList] = useState<any>([]);

  const [referralPaging, setReferralPaging] = useLocalStorages(
    "referral_paging",
    {
      page: 1,
      pages: 0,
      count: 0,
      limit: 25,
    }
  );

  // useAuthenticated((allow) => {
  //   if (!allow) navigate("/sign-in");
  // }, "realestate");

  useEffect(() => {
    getpaymentList();
    getPaymentMethodData();
  }, []);

  const getPaymentMethodData = () => {
    getPaymentMethod()
      .then((response: any) => {
        setPaymentData(response.data);
      })
      .catch((e: any) => {
        toast.info(
          "Aún no cuentas con un método de pago registrado, recuerda que debes registrarlo para poder cobrar tus recompensas"
        );
        setShowBankDetailsModal(true);
        console.log(e);
      });
  };

  const putPaymentMethodData = (data: object) => {
    putPaymentMethod(data)
      .then((response: any) => {
        getPaymentMethodData();
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const getpaymentList = () => {
    getPaymentsList(referralPaging.limit, referralPaging.page)
      .then((response) => {
        if (response.data.results) {
          setPaymentList(response.data.results);
          let paid = 0;
          let available = 0;
          if (response.data.results.length > 0) {
            response.data.results.forEach((e) => {
              if (e.paid) {
                paid += e.amount;
                return;
              }
              available += e.amount;
            });
          }

          setRewardData({ paid, available });
        }

        if (response.data.pagination) {
          referralPaging.page = response.data.pagination?.page;
          referralPaging.pages =
            response.data.pagination?.pages > 0
              ? response.data.pagination?.pages
              : 1;
          referralPaging.limit = response.data.pagination?.items_per_page;
          referralPaging.count = response.data.pagination?.count;

          setReferralPaging({ ...referralPaging });
        }
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  return (
    <DinamycLayout type={role}>
      <Container className={styles.ReferralsWrapper}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
            <Breadcrumbs
              parents={[
                {
                  href: "/referrals",
                  title: "Mis referidos",
                },
              ]}
            >
              <Typography color="text.gray">Cobro y Facturación</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <BannerInfo img="/assets/img/sources/referal_pay.svg">
          <p>
            Aquí podrás llevar el control de todas las recompensas que has
            conseguido. Para cobrarlas solo deberás elegir la forma de pago que
            más te guste dentro de “COBROS Y RECOMPENSAS”.
            <br /> <br /> Además, podrás descargar todas las facturas y
            comprobantes de pago desde la pestaña “FACTURACIÓN"
          </p>
        </BannerInfo>

        <Divider component="br" />

        <Grid container>
          <PageTitle title="Cómo funciona" />
        </Grid>
        <Divider component="br" />
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={tab}
                onChange={(e: any, value) => {
                  setTab(value);
                }}
              >
                <Tab label="Cobros y Recompensas" />
                <Tab label="Facturación" />
              </Tabs>
            </Box>

            <TabPanel value={tab} index={0}>
              <PaymentRewardTab
                rewardData={rewardData}
                setShowBankDetailsModal={setShowBankDetailsModal}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container sx={{ marginTop: "1rem" }} spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  {paymentList.length > 0 ? (
                    <TableContainer sx={{ maxHeight: 650 }} component={Paper}>
                      <Table stickyHeader sx={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Factura</TableCell>
                            <TableCell align="center">Fecha</TableCell>
                            <TableCell align="center">Documento</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paymentList.map((m: any, i: number) => {
                            return (
                              <TableRow hover key={i || 0}>
                                <TableCell align="center">{m.id}</TableCell>
                                <TableCell align="center">{m.date}</TableCell>
                                <TableCell align="center">
                                  {m.files.length > 0 ? (
                                    <Link href={m.files[0].url}>
                                      Descargar pdf
                                    </Link>
                                  ) : (
                                    "---"
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <NotResults
                      title="Sin Resultados"
                      caption="No se han encontrado registros"
                    />
                  )}
                </Grid>

                {paymentList.length !== 0 && (
                  <Grid item md={12} sm={12} xs={12}>
                    <PaginationBar
                      count={referralPaging.count}
                      rowsPerPage={referralPaging.limit}
                      page={referralPaging.page}
                      pages={referralPaging.pages}
                      onChange={(p) => {
                        referralPaging.page = p;

                        setReferralPaging({ ...referralPaging });
                        getpaymentList();
                      }}
                      onChangeLimit={(l) => {
                        referralPaging.page = 1;
                        referralPaging.limit = l;
                        setReferralPaging({ ...referralPaging });
                        getpaymentList();
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>

        <Grid>
          <>
            <Divider component="br" /> <Divider component="br" />
            <Divider component="hr" />
            {/* <FastFAQs
              header={true}
              data={[
                {
                  title: `Más información`,
                  caption: "",
                  content: <p>Esta integración se produce acorde a legislación y con las máximas garantías de seguridad
              y privacidad para el usuario.</p>
                  ,
                },
              ]}
              title="Preguntas frecuentes"
              caption="Encuentre respuestas a las preguntas acerca del proceso de carga de documentos."
            /> */}
          </>
        </Grid>
        <BankDetails
          validateDNI={true}
          show={showBankDetailsModal}
          data={paymentData}
          onSave={(data) => {
            putPaymentMethodData(data);
            setShowBankDetailsModal(false);
            toast.success(
              "Tus datos bancarios se han registrado correctamente."
            );
          }}
          onClose={() => {
            setShowBankDetailsModal(false);
          }}
        />
      </Container>
    </DinamycLayout>
  );
};
interface BoxDescriptionProps {
  title: string;
  description: string;
  imgSrc: string;
  widthImg?: string;
}

const BoxDescription: FunctionComponent<BoxDescriptionProps> = ({
  title,
  description,
  imgSrc,
  widthImg,
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img
          src={imgSrc}
          alt={title}
          style={{ width: widthImg ? widthImg : "60%", height: "60%" }}
        />
        <Box>
          <Typography
            sx={{
              margin: "10px 0 ",
              fontWeight: "bold",
            }}
            color="primary"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textAlign: "justify",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export interface IBankDetailsProps {
  show: boolean;
  data: any;
  onSave: (data: any) => void;
  onClose: () => void;
  nameAccount?: string;
  validateDNI: boolean;
  nameDni?: string;
  saveButtonText?: string;
  showWarningInformation?: boolean;
  warningInformation?: string;
  hideInput?: Array<string> | undefined | ["nombre", "dni", "iban", "bic"];
}
export const BankDetails: FunctionComponent<IBankDetailsProps> = (
  props
): ReactComponentElement<any> => {
  const [localData, setLocalData] = useState(props.data);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    if (name === "IBAN") value = ibantools.extractIBAN(value).iban;
    setLocalData({ ...localData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isValid =
      ibantools.isValidIBAN(localData.IBAN) &&
      validateSpanishDNI(localData.identification);

    if (props.validateDNI === false) {
      isValid = ibantools.isValidIBAN(localData.IBAN);
    }

    if (isValid) {
      props.onSave(localData);
    }
  };

  const elemRef = useCallback((node: any) => {
    if (node !== null) {
      // do stuff here
      for (const node1 of node.childNodes) {
        if (props.hideInput) {
          if (props.hideInput.length > 0) {
            let toHide = props.hideInput;
            toHide.forEach((item) => {
              if (node1.getAttribute("data-name") === item) {
                node1.style.display = "none";
              }
            });
          }
        }

        // Do something with each child as children[i]
        // NOTE: List is live! Adding or removing children will change the list's `length`
      }
    }
  }, []);

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  return (
    <Dialog
      open={props.show}
      onClose={() => props.onClose()}
      maxWidth="sm"
      fullWidth={true}
    >
      <Box className={styles.DialogDetails}>
        <Container>
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: ".7rem" }}>
              <form onSubmit={handleSubmit} ref={elemRef}>
                <Typography variant="h6">Mis Datos Bancarios</Typography>
                <Divider />
                {props.showWarningInformation && (
                  <React.Fragment>
                    <Divider component="br" />
                    <Alert severity="info">
                      <Typography variant="caption">
                        {" "}
                        {props.warningInformation}
                      </Typography>
                    </Alert>
                  </React.Fragment>
                )}
                <Divider component="br" />
                <Divider component="br" />
                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  data-name="nombre"
                  fullWidth
                  required
                  label={
                    props.nameAccount
                      ? props.nameAccount
                      : "Nombre del titular de la cuenta"
                  }
                  value={localData.account_holder_name}
                  name="account_holder_name"
                  onChange={handleChange}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  data-name="dni"
                  label={props.validateDNI ? "DNI/NIE" : "CIF/NIF"}
                  error={Boolean(
                    props.validateDNI &&
                      localData.identification &&
                      !validateSpanishDNI(localData.identification)
                  )}
                  helperText={
                    props.validateDNI &&
                    localData.identification &&
                    !validateSpanishDNI(localData.identification)
                      ? "Ingrese un DNI válido"
                      : null
                  }
                  value={localData.identification}
                  name="identification"
                  onChange={handleChange}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  data-name="iban"
                  label="
                    IBAN"
                  error={Boolean(
                    localData.IBAN && !ibantools.isValidIBAN(localData.IBAN)
                  )}
                  value={ibantools.friendlyFormatIBAN(localData.IBAN)}
                  helperText={
                    localData.IBAN && !ibantools.isValidIBAN(localData.IBAN)
                      ? "Ingrese un IBAN válido"
                      : null
                  }
                  name="IBAN"
                  onChange={handleChange}
                />

                <TextField
                  className={`${styles.InputFields}`}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  data-name="bic"
                  label="BIC/SWIFT"
                  value={localData.BIC_SWIFT}
                  name="BIC_SWIFT"
                  onChange={handleChange}
                />

                <Button
                  type="submit"
                  // disabled={error}
                  variant="contained"
                  fullWidth
                  sx={{ marginTop: ".7rem" }}
                >
                  {props.saveButtonText ? props.saveButtonText : "Guardar"}
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
};

const StatementsList = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Referencia</TableCell>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Descripción</TableCell>
            <TableCell align="center">Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralPay;

const PaymentRewardTab = ({
  rewardData,
  setShowBankDetailsModal,
}: {
  rewardData: { paid: number; available: number };
  setShowBankDetailsModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const [name] = useLocalStorage("name");
  const [email] = useLocalStorage("email");
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "1rem",
      }}
    >
      <Grid item md={5} sm={12} xs={12}>
        <Grid item xs={12} md={12} sx={{ marginTop: "2rem" }}>
          <Box
            className={styles.HeaderBox}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box className={styles.HeaderItem}>
              <Avatar
                src="/assets/img/avatar/a9.svg"
                sx={{ width: 70, height: 70 }}
              />
            </Box>
            <Box className={styles.HeaderItem}>
              <Typography variant="h5" fontWeight="bold">
                {name}
              </Typography>
              <Typography variant="body1">{email}</Typography>
            </Box>
            <Stack direction="row" spacing={3} maxWidth="16rem">
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  Recompensas pagadas
                </Typography>
                <Typography variant="body1" color="lightgray">
                  {formatCurrency(rewardData.paid)}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  Recompensas disponibles
                </Typography>
                <Typography variant="body1" color="lightgray">
                  {formatCurrency(rewardData.available)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid item md={1} sm={12} xs={12}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item md={6} sm={12} xs={12} sx={{ marginTop: "2rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Stack spacing={3}>
            <Stack direction="row" spacing={5} id="IBAN">
              <Typography
                variant="h6"
                display="block"
                gutterBottom
                fontWeight="bold"
              >
                IBAN
              </Typography>
              <Typography variant="subtitle1">
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setShowBankDetailsModal(true);
                  }}
                >
                  Cobrar recompensas por transferencia bancaria{" "}
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
