import { useEffect } from "react";
import { bidderBidSeen } from "services/api";

// Recuerda siempre usar la palabra "use" al principio de cada custom hook
const useAcceptedBidSeen = (id: string | undefined) => {
  useEffect(() => {
    if (id) {
      bidderBidSeen(id).then((res) => {
        console.log(res);
      });
    }
  }, []);
};

export default useAcceptedBidSeen;
