import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import {
  Container,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListSubheader,
  Box,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { BankDetails } from "pages/Referrals/ReferralPay";
import { MainBrokerLayout } from "../../../shared/layouts";
import { PageTitle, DialogWarning } from "../../../shared/components";
import {
  changeStatusSigned,
  getBrokerDocuments,
  getFinancedDocumentColibid,
} from "../../../services/api";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PolicyIcon from "@mui/icons-material/Policy";
import CookieIcon from "@mui/icons-material/Cookie";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {
  useAuthenticated,
  useProfile,
  useLocalStorage,
  useBankAccountUser,
} from "../../../shared/hooks";
import { toast } from "react-toastify";
import { sendTagEvent } from "utils/analytics";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { BrokerProfile } from "shared/types/Profile";
import ProfileAvatarSkeleton from "shared/components/CustomSkeleton/ProfileAvatar";
const ProfileBroker: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const [checked, setChecked] = useState(false);
  const [aceptedTerms, setAceptedTerm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [role] = useLocalStorage("role");
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const { profile, setValueProfile, isFetching } = useProfile<BrokerProfile>();
  const { paymentData, setPaymentValue } = useBankAccountUser(profile);

  const location = useLocation();
  const navigate = useNavigate();

  useAuthenticated((allow) => {
    if (!allow) navigate("/sign-in");
  }, role);

  const logout = () => {
    localStorage.removeItem("access_token");
    navigate("/sign-in");
  };

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  useEffect(() => {
    if (profile && role === "broker") {
      setChecked(
        profile.accept_financed_commission === true &&
          profile.accapt_financed_commission_signed !== null
      );
    }
  }, [profile]);

  useEffect(() => {
    if (showDialog) {
      changeStatus(aceptedTerms);
      setShowDialog(false);
    }

    setShowBankDetailsModal(false);
  }, [paymentData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (profile) {
      if (profile.accapt_financed_commission_signed === null) {
        setShowDialog(true);
      }
      if (profile.accapt_financed_commission_signed) {
        setAceptedTerm(true);
        handledSwitch(event.target.checked);
      }
    }
  };

  const handledSwitch = (value: boolean) => {
    if (aceptedTerms && profile) {
      if (profile.accapt_financed_commission_signed) {
        changeStatus(value);
      } else {
        setShowBankDetailsModal(true);
      }
    } else {
      setHelperText("No has aceptado los terminos y condiciones");
    }
  };
  const handledAgree = () => {
    if (aceptedTerms && profile) {
      if (profile.accapt_financed_commission_signed) {
        changeStatus(profile.accept_financed_commission);
      } else {
        setShowBankDetailsModal(true);
      }
    } else {
      setHelperText("No has aceptado los terminos y condiciones");
    }
  };

  const changeStatus = (status: boolean) => {
    if (profile && paymentData) {
      let data = {
        accept_financed_commission: status,
        account_holder_name: paymentData.account_holder_name,
        identification: paymentData.identification,
        IBAN: paymentData.IBAN,
        BIC_SWIFT: paymentData.BIC_SWIFT,
      };

      changeStatusSigned(JSON.stringify(data)).then((response: any) => {
        if ((response.data.result = "ok")) {
          setChecked(status);

          profile.accept_financed_commission = status;
          setValueProfile({ ...profile });
          setShowDialog(false);
          toast.success("Estatus cambiado correctamente");
        }
      });
    }
  };

  // const handledDisagree = () => {
  //   if (profile) {
  //     changeStatusSigned(JSON.stringify({ accept_financed_commission: false }))
  //       .then((response: any) => {
  //         if ((response.data.result = "ok")) {
  //           profile.accept_financed_commission = false;
  //           setValueProfile({ ...profile });

  //           setShowDialog(false);
  //           toast.success("Estatus cambiado correctamente");
  //         } else {
  //           toast.error(
  //             "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
  //           );
  //         }
  //       })
  //       .catch((err: any) => {
  //         // Handle error
  //         toast.error(
  //           "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
  //         );
  //       });
  //   }
  // };

  const downLoadContractSigned = () => {
    getBrokerDocuments()
      .then((response: any) => {
        if (response.data.length > 0) {
          let financedContract =
            response.data.filter(
              (item: any) =>
                item.file_descriptor === "accept_financed_commission"
            ).length > 0
              ? response.data
                  .filter(
                    (item: any) =>
                      item.file_descriptor === "accept_financed_commission"
                  )
                  .slice(-1)[0]
              : null;
          if (financedContract.file_url) {
            window.open(financedContract.file_url);
          }
        } else {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
        }
      })
      .catch((err: any) => {
        // Handle error
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
      });
  };

  const downLoadContract = () => {
    getFinancedDocumentColibid().then((response: any) => {
      if (response.data) {
        if (response.data.value) {
          window.open(response.data.value);
        }
      }
    });
  };

  const putPaymentMethodData = (data: object) => {
    setPaymentValue(data);
  };

  return (
    <MainBrokerLayout>
      <>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <PageTitle title="Mi Perfil" />
            </Grid>

            <Grid item xs={12} md={12}>
              <Box className={styles.HeaderBox}>
                {isFetching ? (
                  <ProfileAvatarSkeleton />
                ) : (
                  <>
                    <Box
                      className={styles.HeaderItem}
                      sx={{ marginRight: "1rem" }}
                    >
                      <Avatar
                        src="/assets/img/avatar/a9.svg"
                        sx={{ width: 70, height: 70 }}
                      />
                    </Box>
                    <Box className={styles.HeaderItem}>
                      <Typography variant="h6">{profile?.name}</Typography>
                      <Typography variant="caption">{profile?.user}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Divider component="hr" />
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {/* Service isn't longer active */}
              {/* {role === "broker" && profile && (
                <List
                  subheader={
                    <ListSubheader className={styles.SubTitle}>
                      GENERALES
                    </ListSubheader>
                  }
                  className={styles.ListGroup}
                  sx={{ marginTop: "1.5rem" }}
                >
                  <ListItem button className={styles.ListItem}>
                    <ListItemText primary="Activar financiación de la comisión con Colibid" />
                    <Switch checked={checked} onChange={handleChange} />
                  </ListItem>
                  <Divider />
                  {profile.accept_financed_commission === true &&
                    profile.accapt_financed_commission_signed !== null &&
                    checked && (
                      <React.Fragment>
                        <ListItem
                          onClick={() => downLoadContractSigned()}
                          button
                          className={styles.ListItem}
                        >
                          <ListItemText primary="Contrato de financiación y comisión Colibid" />
                          <ArrowCircleDownIcon className={styles.ListIcon} />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            setShowBankDetailsModal(true);
                          }}
                          className={styles.ListItem}
                        >
                          <ListItemText primary="Datos Bancarios" />

                          <AccountBalanceWalletIcon
                            className={styles.ListIcons}
                          />
                        </ListItem>
                      </React.Fragment>
                    )}
                </List>
              )} */}

              <List
                subheader={
                  <ListSubheader className={styles.SubTitle}>
                    ACERCA DE
                  </ListSubheader>
                }
                className={styles.ListGroup}
              >
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/terminos-y-condiciones/"
                  target="n_blank"
                >
                  <ListItemText primary="Términos y Condiciones" />
                  <InfoOutlinedIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/privacy-policy/"
                  target="n_blank"
                >
                  <ListItemText primary="Política de Privacidad" />
                  <PolicyIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/politica-de-cookies/"
                  target="n_blank"
                >
                  <ListItemText primary="Política de Cookies" />
                  <CookieIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  className={styles.ListItem}
                  button
                  component="a"
                  href="https://colibid.com/preguntas-frecuentes/"
                  target="n_blank"
                >
                  <ListItemText primary="Preguntas Frecuentes" />
                  <ContactSupportOutlinedIcon className={styles.ListIcon} />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  button
                  onClick={() => logout()}
                  className={styles.ListItem}
                >
                  <ListItemText primary="Cerrar Sesión" />
                  <LogoutOutlinedIcon className={styles.ListIcon} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
        <DialogWarning
          title="Términos y Condiciones"
          buttonDisagree="Cancelar"
          buttonAgree="Aceptar"
          ShowDialog={showDialog}
          handledClickAgree={handledAgree}
          handledClickDisagree={() => {
            setShowDialog(false);
          }}
        >
          <>
            <Typography component={"span"} variant="body2" gutterBottom>
              Para activar la financiación Colibid, necesitamos que lea y acepte
              nuestros términos y condiciones. Una vez aceptados, podrá elegir
              si ofrece la financiación Colibid o no al realizar cada oferta.
              <br /> Si tiene alguna duda sobre la financiación o sobre el
              contenido del contrato, póngase en contacto con: {}
              <a
                className={styles.link}
                href="mailto:operations@colibid.com"
              >
                operations@colibid.com
              </a>
              .
            </Typography>
            <Typography
              className={styles.link}
              variant="button"
              display="block"
              sx={{ margin: "1rem 0 " }}
              gutterBottom
              onClick={() => {
                downLoadContract();
              }}
            >
              Ver contrato de términos y condiciones
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aceptedTerms}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAceptedTerm(event.target.checked);
                      if (event.target.checked) setHelperText("");
                    }}
                  />
                }
                label="He leído y acepto el contrato de términos y condiciones"
              />
            </FormGroup>
            <FormHelperText className={styles.error}>
              {helperText}
            </FormHelperText>
          </>
        </DialogWarning>
        <BankDetails
          show={showBankDetailsModal}
          data={paymentData}
          validateDNI={false}
          nameAccount="Razon Social"
          warningInformation="Para completar el acuerdo necesitamos estos datos adicionales."
          showWarningInformation={showDialog}
          hideInput={["bic"]}
          onSave={(data) => {
            if (showDialog) {
              putPaymentMethodData(data);
            } else {
              putPaymentMethodData(data);
              setShowBankDetailsModal(false);
            }

            toast.success(
              "Tus datos bancarios se han registrado correctamente."
            );
          }}
          onClose={() => {
            setShowBankDetailsModal(false);
          }}
        />
      </>
    </MainBrokerLayout>
  );
};

export default ProfileBroker;
