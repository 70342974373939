import { DialogContent, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BankFlipPersonType,
  createBankflipSession,
} from "services/api/bankflip.service";
import { CustomDialog, FastFAQs } from "shared/components";
import ImageBtnWithDialog, {
  CustomListItemBtn,
} from "shared/components/ImageButton/ImageBtnWithDialog";
import { useDialog } from "shared/hooks";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { toast } from "react-toastify";
import { DocAssistantProps } from "..";

type Props = {
  mortgage: DocAssistantProps["mortgage"];
};

export default function BankFlipBtn({ mortgage }: Props) {
  const { isShowing, openDialog, closeDialog } = useDialog();
  const isConjunta = mortgage?.mortgage_data?.solicitor_type === 2;
  const [iframe, setIframe] = useState("");

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin.indexOf("bankflip.io") !== -1) {
        const payload = e.data;
        if (payload["name"] === "user_requested_closure") {
          if (isConjunta) {
            window.location.href = `/client/profile/documents?mid=${mortgage?.id}`;
          } else {
            window.location.href = `/client/mortgage/${mortgage?.id}`;
          }
        }
      }
    });

    return () => window.removeEventListener("message", (e) => {});
  }, [mortgage]);

  const handleClick = (person_type: BankFlipPersonType) => {
    createBankflipSession({
      mortgage_id: mortgage.id!,
      person_type,
    })
      .then((response: any) => {
        // window.open(response.data.widget_link, 'bankflipWindow', 'popup')
        setIframe(
          `<iframe src="${response.data.widget_link}" width="100%" height="1000px" scrolling="no" frameborder="0"></iframe>`
        );
        openDialog();
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al conectar con el servicio Clave Pin, por favor inténtalo más tarde 😔"
        );
      });
  };

  return (
    <>
      <ImageBtnWithDialog
        shouldOpenDialog={isConjunta}
        imgBtnOnClick={() => handleClick("main_user")}
        imgSrc="/assets/img/sources/clave_pin.svg"
        btnTitle="Clave Pin"
        listTitle="Seleccione una persona para subir sus archivos 📂"
        alertMsg="La función de clave pin solo puede utilizarse una sola vez por cada usuario (Principal y acompañante)."
      >
        <>
          <CustomListItemBtn
            completed={mortgage.main_user_complete_cpin}
            onClick={() => handleClick("main_user")}
            primary={`${mortgage.mortgage_data.user.name}
                                          ${mortgage.mortgage_data.user.surname}`}
            secondary={"Solicitante"}
          />
          {isConjunta && (
            <CustomListItemBtn
              completed={mortgage.partner_complete_cpin}
              onClick={() => handleClick("partner")}
              primary={`${mortgage.mortgage_data.user_2.name}
                                          ${mortgage.mortgage_data.user_2.surname}`}
              secondary={"Acompañante"}
            />
          )}
        </>
      </ImageBtnWithDialog>
      <CustomDialog open={isShowing} onClose={closeDialog} fullWidth>
        <DialogContent>
          <BankFlipPanel iframe={iframe} />
        </DialogContent>
      </CustomDialog>
    </>
  );
}

export function BankFlipPanel({ iframe }: { iframe: string }) {
  return (
    <Stack gap={1}>
      <Typography variant="h6">Clave Pin</Typography>
      <FastFAQs
        header={false}
        title=""
        caption=""
        data={[
          {
            title: `Más información`,
            caption: "",
            content: (
              <Stack gap={1}>
                <Typography>
                  Nuestra plataforma integra las plataformas de Agencia
                  Tributaria Española, Banco de España y Ministerio de Hacienda,
                  mediante el certificado de Clave pin, toda la documentación
                  necesaria para que tú no tengas que preocuparte por la
                  documentación.
                </Typography>
                <Typography>
                  Esta integración se produce acorde a legislación y con las
                  máximas garantías de seguridad y privacidad para el usuario.
                </Typography>
              </Stack>
            ),
          },
        ]}
      />
      <div dangerouslySetInnerHTML={{ __html: iframe }} />
    </Stack>
  );
}
