import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { COUNTRIES, Country } from "shared/constants/countries";
import HandleSolicitorView from "../HandleSolicitorView";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { useFormContext } from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import { requiredStringSchema } from "utils/yupValidation";

const userSchema = yup.object({
  residence: requiredStringSchema(),
});

const yupSchema = yup.object({
  user: userSchema,
  user_2: isConjuntaValidate(userSchema),
});

function ResidenceForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es su país de residencia?"
      resolver={yupResolver(yupSchema)}
    >
      <Form />
    </StepLayout>
  );
}
function Form() {
  const { onNext } = useMortgageFormContext();
  return (
    <HandleSolicitorView
      RenderComponent={FormFields.ListSelector}
      individualProps={{
        loadMoreAsAutocomplete: true,
        initialOptionsToShow: 1,
        name: "user.residence",
        options: COUNTRIES,
        onClick: onNext,
        getOptionLabel(o: Country | string) {
          if (typeof o === "string") return o;
          return o.name_es;
        },
        getOptionValue(o: Country | string) {
          if (typeof o === "string") return o;
          return o.name_es;
        },
        isOptionEqualToValue(selected: string, o: Country) {
          return selected === o.name_es;
        },
        getValueFromOptions(o: Country) {
          if (o) {
            return o.name_es;
          }
        },
      }}
      conjuntaProps={{ name: "user_2.residence" }}
    />
  );
}
export default ResidenceForm;
