import { useState, useEffect } from "react";
import { getBid } from "services/api";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";

// Recuerda siempre usar la palabra "use" al principio de cada custom hook
const useBidById = (id: string | undefined) => {
  const [bid, setBid] = useState<Bid>();
  const [mortgage, setMortgage] = useState<MortgageRes>();
  useEffect(() => {
    if (id) {
      getBid(id).then((res) => {
        if (res.data.results.mortgage) {
          setBid(res.data.results);
          setMortgage(res.data.results.mortgage);
        }
        console.log(res);
      });
    }
  }, []);

  return {
    bid: bid,
    mortgage: mortgage,
  };
};

export default useBidById;
