import { toast } from "react-toastify";
import { isFileSizeOK } from "utils";
import axios from "utils/axios";

const uploadFileApi = async (file, namespace = "") => {
  toast.info("Subiendo archivo", {
    position: "top-right",
  });

  try {
    isFileSizeOK(file.size);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("namespace", namespace);

    let uploaded = await axios.post(
      `${process.env.REACT_APP_API_URL}/storage/upload`,
      formData
    );

    toast.success("Archivo cargado correctamente", {
      position: "top-right",
    });
    return uploaded.data.data.uploaded.url;
  } catch (e) {
    toast.error(
      "Ocurrió un error inesperado al subir el archivo, por favor inténtalo más tarde",
      {
        position: "top-right",
      }
    );
    throw new Error(e);
  }
};

const uploadBundleFileApi = (bundle) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/storage/bundle`, bundle);
};

const addFileToMortgage = (mortgageId, fileName, schema) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/files/${fileName}`,
    schema
  );
};

export { uploadFileApi, addFileToMortgage, uploadBundleFileApi };
