import { Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function InfoBanner({ activeStep }: {  activeStep: number }) {
  if (activeStep < 2) {
    return (
      <Stack
        padding="1rem"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#33CCCC",
          color: "white",
        }}
      >
        <Stack direction="row" spacing={1}>
          <AccessTimeIcon />
          <Typography>¡Tu subasta hipotecaria está a 3 minutos!</Typography>
        </Stack>
      </Stack>
    );
  }

  return null;
}

export default InfoBanner