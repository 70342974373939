import {
  Box,
  Drawer,
  Fab,
  Paper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CustomProgressBar from "./CustomProgressBar";
import {
  MFormGroupKey,
  MFormSubGroupKey,
  M_FORM_GROUPS,
  M_FORM_SUB_GROUPS,
} from "../..";
import { useDialog } from "shared/hooks";
import HeightIcon from "@mui/icons-material/Height";

export interface ProgressGroupNode {
  group: MFormGroupKey | MFormSubGroupKey;
  subGroups: ProgressSubGroupNode[];
}

/**it's a Linked List Node basically */
export interface ProgressSubGroupNode {
  //TODO turn this to a subgroup and then another group that has a subgroup
  group: MFormSubGroupKey;
  /**if not provided a prevSteps it will calculated on the fly */
  prevSteps?: number;
  /**normally will not required cause it calculate in the component*/
  progress?: number;
  completedSteps: boolean[];
}
type ProgressCardProps = {
  stepByGroups: ProgressGroupNode[];
  currStep: number;
  setStep?: (step: number) => void;
  collapsed?: boolean;
  isLastStep?: boolean;
};

export default function ProgressCardContainer(props: ProgressCardProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  if (isMobile) {
    return <ProgressCardDrawer {...props} />;
  }
  return <ProgressCard {...props} />;
}

export function ProgressCardDrawer(props: ProgressCardProps) {
  const { closeDialog, openDialog, isShowing, toggle } = useDialog();
  return (
    <>
      <Fab
        id="fab-progress"
        sx={{
          width: "",
          height: "",
          borderRadius: "20px",
        }}
        onClick={openDialog}
      >
        <Stack
          id="stack-icon-progress"
          alignItems="center"
          sx={{
            backgroundColor: "#D9F6EFB2",
            borderRadius: "20px",
            padding: "0.5rem",
          }}
        >
          <HeightIcon sx={{ rotate: "90deg" }} fontSize="large" />
          <Typography
            sx={{
              writingMode: "vertical-rl",
              textOrientation: "upright",
            }}
          >
            Progreso
          </Typography>
        </Stack>
      </Fab>
      <Drawer
        id="progress-drawer"
        open={isShowing}
        onClose={closeDialog}
        anchor="right"
      >
        <Stack paddingX="1rem">
          <ProgressCard {...props} />
        </Stack>
      </Drawer>
    </>
  );
}

function ProgressCard({
  stepByGroups,
  currStep,
  setStep,
  collapsed,
  isLastStep,
}: ProgressCardProps) {
  if (isLastStep) {
    return <DummyProgress />;
  }
  return (
    <Stack
      padding={3}
      spacing={2}
      border="solid 1px #D8D7F5"
      borderRadius="8px"
    >
      {stepByGroups.map((e, i) => {
        const showProgressList = !collapsed && e.subGroups.length > 0;
        return (
          <Stack spacing={1} key={e.group}>
            <GroupTitle idx={i} group={e} currStep={currStep} />
            {showProgressList && (
              <Box paddingLeft={4}>
                <ProgressList subGroup={e.subGroups} setStep={setStep} />
              </Box>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}

function DummyProgress() {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  return (
    <Stack
      padding={3}
      spacing={2}
      border="solid 1px #D8D7F5"
      borderRadius="8px"
    >
      <GroupTitle
        idx={0}
        group={{ group: "form", subGroups: [] }}
        backgroundColor="#33CCCC"
      />
      <GroupTitle
        idx={1}
        group={{ group: "check", subGroups: [] }}
        backgroundColor="#D8D8F6"
      />
    </Stack>
  );
}

function GroupTitle({
  group,
  idx,
  currStep,
  backgroundColor,
}: {
  group: ProgressGroupNode;
  idx: number;
  currStep?: number;
  backgroundColor?: string;
}) {
  const isGroupCompleted = group.subGroups.every((s) =>
    s.completedSteps.every((c) => c)
  );
  // isCurrGroup = group.subGroups.redu
  const background = `${
    isGroupCompleted
      ? group.group === "check"
        ? "gray"
        : "#01857A"
      : "darkorchid"
  }`;
  if (group.group == "check" || group.group == "form")
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ textTransform: "capitalize" }}
      >
        <Typography
          sx={{
            background: backgroundColor || background,
            color: "white",
            height: "24px",
            width: "24px",
            borderRadius: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {idx + 1}
        </Typography>
        <Typography>{M_FORM_GROUPS[group.group]}</Typography>
      </Stack>
    );
  return null;
}

function ProgressList({
  subGroup,
  setStep,
}: {
  subGroup: ProgressSubGroupNode[];
  setStep?: ProgressCardProps["setStep"];
}) {
  // watch perfomance is slow should use a memoization
  const completedGroup: MFormSubGroupKey[] = [];
  const notCompletedGroup: MFormSubGroupKey[] = [];
  const stepNodes: Required<ProgressSubGroupNode>[] = [];
  subGroup.forEach((s, i, arr) => {
    const { group, completedSteps } = s;
    const steps = completedSteps.length;
    const stepCompleteds = completedSteps.reduce((acc, curr) => {
      if (curr) return acc + 1;
      return acc;
    }, 0);
    let progress = 100 * (stepCompleteds / steps);
    if (progress >= 100) progress = 100;

    if (progress >= 100) {
      completedGroup.push(group);
    } else {
      notCompletedGroup.push(group);
    }

    //calculate prev steps
    let prevSteps = 0;
    if (stepNodes[i - 1]) {
      prevSteps =
        stepNodes[i - 1].completedSteps.length +
        (stepNodes[i - 1].prevSteps || 0);
    }

    const node = {
      ...s,
      prevSteps,
      progress,
    };
    stepNodes.push(node);
  });
  return (
    <Stack spacing={1}>
      {stepNodes.map(({ group, progress, prevSteps }) => {
        const currGroup =
          notCompletedGroup.length > 0 && notCompletedGroup[0] === group;
        return (
          <CustomProgressBar
            key={group}
            title={M_FORM_SUB_GROUPS[group]}
            progress={progress}
            currGroup={currGroup}
            onClick={() => setStep?.(prevSteps + 1)}
          />
        );
      })}
    </Stack>
  );
}
