import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";

import Swal from "sweetalert2";
import { formatCurrency } from "utils/helpers"; //trimFilesUpload
import styles from "../../styles.module.scss";

import { differenceInYears, sub } from "date-fns";

import { FormProvider, useForm, useWatch } from "react-hook-form";
import FormFields from "shared/components/FormFields";
import { EntitiesType, MortgageFormData } from "shared/types/postMortgageData";

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MAX_CLIENT_AGE_MARKETING_MOVE,
  MAX_DATA_PERIOD_YEARS_INTEREST_FIJO,
  MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
  MAX_MORTGAGE_LAP,
  MIN_DATA_PERIOD_YEARS,
  PROPERTY_VALUE_RANGES,
  WARNING_MORTGAGE_LAP,
} from "shared/constants/mortgageForm";
import useBanks, { OTHER_BROKER } from "shared/hooks/useBanks";
import CountersDisplayCard from "shared/components/CounterDisplayCard";
import { stepTwoValidationSchema } from "./validation.schema";
import logger from "utils/logger";
import {
  calculate,
  calculationsInitialState,
  checkAgeLimit,
  ICalculations,
  fundUsedForOptions,
  interestTypeOptions,
  interestTypeRemortgageOptions,
  propertyExistenceOptions,
} from "./utils";
import { yesNoOption } from "utils/valueLabelOptions";
import { YesNoOption } from "shared/types/Enums";
import { useLocalStorage } from "shared/hooks";

interface Props {
  onNext?: (formData: any) => void;
  onBack?: (formData?: any) => void;
  editMode?: boolean;
  defaultValues: MortgageFormData;
}

export const StepTwo: FunctionComponent<Props> = ({
  onNext,
  onBack,
  defaultValues,
}) => {
  const { banks, addBroker, removeBroker } = useBanks();
  const [calculations, setCalculations] = useState<ICalculations>(
    calculationsInitialState
  );
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [isMortgageInitialLTFee, setIsMortgageInitialLTFee] = useState(false);
  const [role] = useLocalStorage("role");
  const solicitorType = defaultValues?.solicitor_type;
  const mortgageMode = defaultValues?.mortgage.mode;
  const userWork = defaultValues?.user.work;
  const user2Work = defaultValues?.user_2.work;
  const userBirthdate = defaultValues?.user.birthdate;
  // const user2Birthdate = mortgageFormValue?.user_2.birthdate;
  const methods = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(stepTwoValidationSchema(mortgageMode)),
  });
  const { control, setValue, getValues, formState, handleSubmit, trigger } =
    methods;
  const [
    propertyType,
    propertyValue,
    mortgageInitial,
    dataAmount,
    dataPendingAmount,
    dataInterestType,
    dataPeriod,
    dataTaxAndExpenses,
    otherActiveApplications,
    typesEntities,
    financialsEntities,
  ] = useWatch({
    control,
    name: [
      "property.type",
      "property.value",
      "mortgage_initial",
      "data.amount",
      "data.pending_amount",
      "data.interest_type",
      "data.period",
      "data.tax_and_expenses",
      "customer_application_situation.other_active_applications",
      "customer_application_situation.types_entities",
      "customer_application_situation.financials_entities",
    ],
  });

  useEffect(() => {
    //mostrar popup
    if (role !== "realstate") {
      Swal.fire({
        icon: "info",
        title: "#coliconsejo",
        text: "Los bancos suelen financiar hasta el 90% de la vivienda, pero los usuarios que piden hasta el 80% tienen mejores ofertas y un 20% posibilidades de que su hipoteca sea aprobada ",
      });
    }
  }, []);

  useEffect(() => {
    if (otherActiveApplications === YesNoOption.No.toString()) {
      if (typesEntities.length > 0) {
        setValue("customer_application_situation.types_entities", []);
      }
      if (financialsEntities.length > 0) {
        setValue("customer_application_situation.financials_entities", []);
      }
    }
  }, [otherActiveApplications]);

  useEffect(() => {
    const isBroker = typesEntities.includes(String(EntitiesType.BROKERS));
    const isBanks = typesEntities.includes(String(EntitiesType.BANKS));
    const isFinancialHasBroker = financialsEntities.includes(OTHER_BROKER.name);

    if (!isBanks && isBroker && !isFinancialHasBroker) {
      setValue("customer_application_situation.financials_entities", [
        OTHER_BROKER.name,
      ]);
      return;
    }

    if (!isBanks && financialsEntities.length > 1) {
      const newArr = financialsEntities.filter((e) => e === OTHER_BROKER.name);
      setValue("customer_application_situation.financials_entities", newArr);
      return;
    }

    if (isBroker) {
      addBroker();
    } else {
      removeBroker();
    }

    if (isBroker && !isFinancialHasBroker) {
      const newArr = [...financialsEntities];
      newArr.push(OTHER_BROKER.name);
      setValue("customer_application_situation.financials_entities", newArr);
    }

    if (!isBroker && isFinancialHasBroker) {
      const newArr = financialsEntities.filter((e) => e !== OTHER_BROKER.name);
      setValue("customer_application_situation.financials_entities", newArr);
    }
  }, [typesEntities, financialsEntities]);

  useEffect(() => {
    if (propertyType && dataAmount && dataPendingAmount) {
      trigger(["data.amount", "data.pending_amount", "data.mortgage_rate"]);
    }
  }, [propertyType]);

  useEffect(() => {
    if ([2].includes(mortgageMode)) {
      setValue("data.amount", dataPendingAmount, {
        shouldValidate: true,
      });
    }
  }, [dataPendingAmount, mortgageMode]);

  useEffect(() => {
    if (
      mortgageInitial &&
      propertyValue &&
      Number(mortgageInitial) > Number(propertyValue)
    ) {
      setValue("mortgage_initial", propertyValue, {
        shouldValidate: true,
      });
    }
    const calculated = calculate(getValues());
    if ([1, 4].includes(mortgageMode)) {
      setValue("data.amount", calculated.newDataAmount, {
        shouldValidate: true,
      });
    }
    if (propertyValue) {
      setValue("data.mortgage_rate", calculated.mortgage_rate.toString(), {
        shouldValidate: Boolean(mortgageInitial),
      });
    }
    setValue("data.tax_and_expenses", calculated.fees.toFixed(2), {
      shouldValidate: true,
    });
    setCalculations(calculated);
  }, [propertyType, propertyValue, dataAmount, mortgageInitial]);

  useEffect(() => {
    //checkedAge talvez no se usa, ya q si no pasa el primer paso no llega hasta aqui, pero quien sabe ¯\(º_o)/¯
    let checkedAge: boolean = false;
    if (solicitorType === "1") {
      const clientAge = differenceInYears(
        new Date(),
        new Date(userBirthdate ? userBirthdate : sub(new Date(), { years: 18 }))
      );
      checkedAge = checkAgeLimit({ duration: dataPeriod, clientAge });
    }
    setDisableNextBtn(checkedAge);
  }, [solicitorType, userBirthdate, dataPeriod]);

  useEffect(() => {
    if (dataInterestType === "fijo") {
      Swal.fire({
        title: "Importante",
        html: `Debido a la situación actual del mercado, muchas entidades están dejando de ofrecer tipos de interés COMPETITIVOS a tipo fijo. Te recomendamos que valores seleccionar la opción <b>“Todos los tipos”</b> para asegurarte recibir ofertas más variadas y con mejores condiciones.`,
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
    }
  }, [dataInterestType]);

  useEffect(() => {
    setIsMortgageInitialLTFee(
      Number(mortgageInitial) < Number(dataTaxAndExpenses)
    );
  }, [dataTaxAndExpenses, mortgageInitial]);

  const handleOnNext = (data: MortgageFormData) => {
    onNext?.(data);
  };

  const handleOnBack = () => {
    const form = getValues();
    onBack?.(form);
  };
  return (
    <FormProvider {...methods}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(handleOnNext, (a) => {
          logger.info(a);
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Registro</Typography>
            <Divider component="br" />
            <Typography variant="body1">
              Cuéntanos un poco más sobre la hipoteca que buscas 🔍
            </Typography>
            <Box className={styles.StepThreeImage}>
              <img src="/assets/img/sources/houses.svg" alt="" width="50%" />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.RadioInput
              row
              label="¿Vivienda habitual?"
              name="property.type"
              radioOptions={yesNoOption}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Divider component="hr" />
          </Grid>

          {(mortgageMode === 2 || mortgageMode === 3) && (
            <>
              <Grid item xs={12} md={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Hipoteca actual
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormFields.CurrencyFieldWithWrapperText
                  text="Incluya el valor de mercado actual estimado de la propiedad. Este valor deberá estar respaldado por una tasación para finalizar la firma de la hipoteca. Si el valor de la propiedad según la tasadora es inferior al dato aportado, su oferta de hipoteca podría variar, o no ser aceptada por el banco."
                  label="Valor actual de la propiedad"
                  name="property.value"
                  maxInput={PROPERTY_VALUE_RANGES.GLOBAL.MAX}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    // autoComplete: "off",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormFields.CurrencyFieldWithWrapperText
                  label="Importe pendiente de la hipoteca"
                  text="Por favor agregar el importe restante de tu hipoteca actual."
                  name="data.pending_amount"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    // autoComplete: "off",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormFields.TextFieldInput
                  label="Tasa de interés existente"
                  name="data.interest_rate"
                  inputProps={{
                    step: "0.01",
                    min: 0,
                    inputMode: "decimal",
                  }}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TrendingUpIcon />
                      </InputAdornment>
                    ),
                    // autoComplete: "off",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormFields.RadioInput
                  row
                  label="Tipo de Interés Existente"
                  name="data.interest_type_remortgage"
                  radioOptions={interestTypeRemortgageOptions}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormFields.AutoCompleteInput
                  label="Banco actual"
                  name="current_bank"
                  options={banks.map((b: { name: string }) => b.name)}
                  disablePortal
                  id="bank-box"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormFields.TextFieldInput
                  label="Plazo pendiente de hipoteca"
                  name="data.remortgage_laps_years"
                  type="number"
                  inputProps={{
                    min: 0,
                    max: 35,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Años</InputAdornment>
                    ),
                    // autoComplete: "off",
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={12}>
            <Typography variant="caption" display="block" gutterBottom>
              Nueva hipoteca
            </Typography>
          </Grid>

          {[1, 4].includes(mortgageMode) && (
            <Grid item xs={12} md={12}>
              <FormFields.CurrencyFieldWithWrapperText
                text="Incluya el valor de mercado actual estimado de la propiedad. Este valor deberá estar respaldado por una tasación para finalizar la firma de la hipoteca. Si el valor de la propiedad según la tasadora es inferior al dato aportado, su oferta de hipoteca podría variar, o no ser aceptada por el banco."
                name="property.value"
                label="Valor actual de la propiedad"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                  // autoComplete: "off",
                }}
                // disabled={!!!mortgageInitial}
              />
            </Grid>
          )}

          {[1, 4].includes(mortgageMode) && (
            <Grid item xs={12} md={12}>
              <FormFields.CurrencyFieldWithWrapperText
                name={"data.tax_and_expenses"}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Gastos e Impuestos
                    <Tooltip
                      arrow
                      title={
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            fontWeight: 100,
                          }}
                        >
                          Colibid es gratis, pero por favor ten en cuenta que
                          los gastos e impuestos suelen ser del 12% del valor de
                          la propiedad como por ejemplo Notaría, Registro,
                          gestoría, entre otros.
                        </Typography>
                      }
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Box>
                }
                required={false}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                // disabled={mortgageMode === 2}
              />
            </Grid>
          )}

          {[1, 4].includes(mortgageMode) && (
            <Grid item xs={12} md={12}>
              <FormFields.CurrencyFieldWithWrapperText
                text="La cantidad con la que cuentas para solicitar tu hipoteca, (Colibid es gratis, pero toda operación hipotecaria conlleva unos costes que debes tener en cuenta que forman parte del proceso)."
                label="Ahorro aportado"
                name="mortgage_initial"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                  // autoComplete: "off",
                }}
              />
            </Grid>
          )}

          {[1, 4].includes(mortgageMode) && (
            <Grid item xs={12} md={12}>
              {formState.errors.data?.mortgage_rate?.message && (
                <>
                  <Divider component="br" />
                  <Alert severity="warning">
                    <Typography align="justify">
                      {`Lo sentimos pero la cantidad máxima que los bancos pueden financiar esta hipoteca es de: ${formatCurrency(
                        calculations.max_amount
                      )}. Te recomendamos tener unos fondos iniciales mínimos de ${formatCurrency(
                        calculations.min_amount
                      )}.`}
                      <Link
                        component="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setValue("mortgage_initial", calculations.min_amount);
                        }}
                        sx={{
                          marginLeft: "1ch",
                        }}
                      >
                        <Typography>Ajustar.</Typography>
                      </Link>
                    </Typography>
                  </Alert>
                </>
              )}
              {formState.errors.data?.amount?.message && (
                <>
                  <Divider component="br" />
                  <Alert severity="error">
                    <small>
                      Lo sentimos pero los bancos no suelen dar financiación
                      para hipotecas si el importe es inferior a{" "}
                      {PROPERTY_VALUE_RANGES.GLOBAL.MIN}
                      <br />
                    </small>
                  </Alert>
                </>
              )}
            </Grid>
          )}

          {mortgageMode === 3 ? (
            <>
              <Grid item xs={12} md={12}>
                <FormFields.CurrencyFieldWithWrapperText
                  label="Importe de la nueva hipoteca"
                  name="data.amount"
                  text="La cantidad que te gustaría pedir prestada al banco para comprar la propiedad (no puedes pedir dinero extra para renovar la casa o comprar muebles como parte de la hipoteca)"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CountersDisplayCard
                  counterDisplayListProps={[
                    {
                      errMsg: formState.errors.data?.mortgage_rate?.message,
                      count: `${calculations.mortgage_rate} %`,
                      helperText:
                        "Aunque las entidades bancarias estudian cada caso de forma independiente, generalmente el importe máximo a financiar para una vivienda habitual es entre el 80 al 90%",
                      label: "Porcentaje de Hipoteca",
                    },
                  ]}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12}>
              <CountersDisplayCard
                counterDisplayListProps={[
                  {
                    errMsg: formState.errors.data?.amount?.message,
                    count: formatCurrency(dataAmount),
                    helperText:
                      "Es la cantidad que se le solicitará al banco para tu financiación. En este importe no se incluyen los gastos derivados de la operación.",
                    label: "Importe de hipoteca",
                  },
                  {
                    errMsg: formState.errors.data?.mortgage_rate?.message,
                    count: `${calculations.mortgage_rate} %`,
                    helperText:
                      "Aunque las entidades bancarias estudian cada caso de forma independiente, generalmente el importe máximo a financiar para una vivienda habitual es entre el 80 al 90%",
                    label: "Porcentaje de Hipoteca",
                  },
                ]}
              />
            </Grid>
          )}

          {mortgageMode === 3 && (
            <>
              <Grid item xs={12} md={12}>
                <FormFields.SelectInput
                  name="data.fund_used_for"
                  label="¿Cuál es el destino de los fondos de la ampliación?"
                  menuItems={fundUsedForOptions.map((e) => {
                    return {
                      key: e.value,
                      value: e.value,
                      label: e.label,
                    };
                  })}
                ></FormFields.SelectInput>
              </Grid>
            </>
          )}

          {[1, 4].includes(mortgageMode) && (
            <Grid item xs={12} md={12}>
              <FormFields.RadioInput
                row
                label="Tipo de vivienda"
                name="property_existence"
                radioOptions={propertyExistenceOptions}
              />
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <FormFields.RadioInput
              row
              label={"Tipo de Interés que solicitas"}
              name="data.interest_type"
              radioOptions={interestTypeOptions.map((item) => {
                return item;
              })}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.TextFieldWithWrapperText
              text={`La edad máxima que puede tener al final del plazo de la hipoteca es de ${MAX_MORTGAGE_LAP} años. Sin embargo, si la hipoteca termina después de cumplir los ${WARNING_MORTGAGE_LAP} años, recibirá muchas menos ofertas. La mayoría de los bancos prefieren que las hipotecas terminen antes de cumplir los ${MAX_CLIENT_AGE_MARKETING_MOVE} años.`}
              name="data.period"
              type="number"
              label="Plazo de la hipoteca"
              fullWidth
              inputProps={{
                min: MIN_DATA_PERIOD_YEARS,
                max:
                  dataInterestType === "fijo"
                    ? MAX_DATA_PERIOD_YEARS_INTEREST_FIJO
                    : MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Años</InputAdornment>
                ),
                // autoComplete: "off",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.RadioInput
              row
              label="¿Has solicitado esta hipoteca con otra entidad o bróker?"
              name="customer_application_situation.other_active_applications"
              radioOptions={yesNoOption}
            />
          </Grid>
          {otherActiveApplications === YesNoOption.Sí.toString() && (
            <>
              <Grid item xs={12} md={12}>
                <FormFields.MultiCheckboxInput
                  label="¿Con qué entidades estás trabajando?"
                  name="customer_application_situation.types_entities"
                  checkboxOptions={[
                    {
                      value: EntitiesType.BANKS.toString(),
                      label: "Bancos",
                    },
                    {
                      value: EntitiesType.BROKERS.toString(),
                      label: "Brókers",
                    },
                  ]}
                />
              </Grid>
              {typesEntities.includes(EntitiesType.BANKS.toString()) && (
                <Grid item xs={12} md={12}>
                  <FormFields.AutoCompleteInput
                    label="Bancos donde ya he realizado una solicitud de hipoteca"
                    name="customer_application_situation.financials_entities"
                    options={banks.map((b: { name: string }) => b.name)}
                    disablePortal
                    multiple
                    limitTags={3}
                    id="banks-box"
                  />
                </Grid>
              )}
            </>
          )}
          {(onBack || onNext) && (
            <Grid item xs={12} md={12}>
              <Box className={styles.ActionButtons}>
                {onBack && (
                  <Button
                    variant="contained"
                    startIcon={<ArrowBackIosNew />}
                    onClick={handleOnBack}
                  >
                    Regresar
                  </Button>
                )}
                {onNext && (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={disableNextBtn}
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Siguiente
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
      <DevTool control={methods.control} placement="top-right" />
    </FormProvider>
  );
};
