import ImageBtnWithDialog, {
  CustomListItemBtn,
} from "shared/components/ImageButton/ImageBtnWithDialog";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { DocAssistantProps } from "..";
import { getTrueLayerLink } from "utils/links";

type Props = { mortgage: DocAssistantProps['mortgage'];};

export default function TruelayerBtn({ mortgage }: Props) {
  const mid = mortgage.id
  const openTrueLayerLink = (user: 1 | 2, mid: string | null) => {
    if (mid) {
      const link = getTrueLayerLink({
        mid,
        user,
      });
      window.open(link, "_self");
    }
  };
  return (
    <ImageBtnWithDialog
      shouldOpenDialog={mortgage.mortgage_data.solicitor_type === 2}
      imgBtnOnClick={() => {
        openTrueLayerLink(1, mid);
      }}
      imgSrc="/assets/img/sources/banks_movements.svg"
      btnTitle="Obtén tus extractos bancarios aquí"
      listTitle="Seleccione una persona para subir sus archivos 📂"
      alertMsg="La función de extracción bancaria solo puede utilizarse una sola vez por cada usuario (Principal y Acompañante)."
    >
      <>
        <CustomListItemBtn
          completed={mortgage.main_user_complete_truelayer}
          onClick={() => {
            openTrueLayerLink(1, mid);
          }}
          primary={`${mortgage.mortgage_data.user.name}
                                          ${mortgage.mortgage_data.user.surname}`}
          secondary={"Solicitante"}
        />
        {mortgage.mortgage_data.solicitor_type === 2 && (
          <CustomListItemBtn
            completed={mortgage.partner_complete_truelayer}
            onClick={() => {
              openTrueLayerLink(2, mid);
            }}
            primary={`${mortgage.mortgage_data.user_2.name}
                                          ${mortgage.mortgage_data.user_2.surname}`}
            secondary={"Acompañante"}
          />
        )}
      </>
    </ImageBtnWithDialog>
  );
}
