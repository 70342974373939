import {
  WARNING_MORTGAGE_LAP,
  USER_AGE_RANGE,
  MAX_MORTGAGE_LAP,
} from "shared/constants/mortgageForm";
import { MortgageFormData } from "shared/types/postMortgageData";
import Swal from "sweetalert2";
import { FundUsedFor } from "../../types";
import {
  calcDataAmount,
  getMortgageRate, getTaxAndExpenses,
  isFormAFuncionario,
  maxMortgageInitial,
  minMortgageInitial
} from "../../utils";

export interface ICalculations {
  newDataAmount: number;
  mortgage_rate: number;
  deposit?: number;
  fees: number;
  necessary_funds: number;
  required_funds: number;
  allowed: boolean;
  max_amount: number;
  min_amount: number;
}

export const calculationsInitialState = {
  newDataAmount: 0,
  mortgage_rate: 0,
  deposit: 0,
  fees: 0,
  necessary_funds: 0,
  required_funds: 0,
  allowed: false,
  max_amount: 0,
  min_amount: 0,
};

export const calculate = (
  mortgageFormData: MortgageFormData
): ICalculations => {
  const propertyValue = mortgageFormData.property.value;
  const propertyType = mortgageFormData.property.type;
  const mortgageInitial = mortgageFormData.mortgage_initial;
  const dataAmount = mortgageFormData.data.amount;
  const mortgageMode = mortgageFormData.mortgage.mode;
  const propertyValueNum = Number(propertyValue);
  const deposit = propertyValueNum - Number(dataAmount);
  const fees = getTaxAndExpenses(propertyValue, mortgageMode); //b
  const required = deposit + fees;
  const newDataAmount = calcDataAmount({
    propertyValue: propertyValue,
    mortgageInitial,
  }); // a - (c-b)

  const cleanMortgageRate = getMortgageRate({
    propertyValue,
    mortgageMode,
    numerator: dataAmount,
    mortgageInitial,
  });
  const isFuncionario = isFormAFuncionario(mortgageFormData);
  const max_amount = maxMortgageInitial({
    isFuncionario,
    propertyType,
    propertyValue,
  });

  const min_amount = minMortgageInitial(mortgageFormData);

  const math = {
    newDataAmount: Math.ceil(newDataAmount),
    mortgage_rate: Math.ceil(cleanMortgageRate),
    deposit: Math.ceil(deposit),
    fees: Math.ceil(fees),
    necessary_funds: required,
    required_funds: 0,
    // TODO: si mortgage_rate arriba de 90 not allowed
    max_amount: Math.ceil(max_amount),
    min_amount: Math.ceil(min_amount),
    allowed: Number(mortgageInitial) >= Number(Math.abs(required)),
  };
  // clean the math so every number val is round up
  return math;
};

export const checkAgeLimit = ({
  duration,
  clientAge,
}: {
  duration: string | number;
  clientAge: number;
}): boolean => {
  // if (solicitorType !== "1") return;
  const laps = Number(clientAge) + Number(duration);

  if (laps >= WARNING_MORTGAGE_LAP) {
    Swal.fire({
      title: "Importante",
      html: `La edad máxima que puede tener al final del plazo de la hipoteca es de 80 años.<br/> Sin embargo, si la hipoteca termina después de cumplir los ${WARNING_MORTGAGE_LAP} años, recibirá muchas menos ofertas.<br/> La mayoría de los bancos prefieren que las hipotecas terminen antes de cumplir los ${USER_AGE_RANGE.max} años, con la duración que ingresaste la hipoteca <b>terminaría a tus ${laps} años</b> de edad.`,
      icon: "warning",
      confirmButtonText: "Sí, entiendo",
    });
  }

  return laps >= MAX_MORTGAGE_LAP;
};

export const propertyExistenceOptions = [
  { value: 1, label: "Obra Nueva" },
  { value: 2, label: "Existente" },
  // { value: 3, label: "Autopromotor" },
];

export const interestTypeOptions = [
  { id: 1, value: "todos", label: "Todos" },
  { id: 2, value: "fijo", label: "Fijo" },
  { id: 3, value: "variable", label: "Variable" },
  { id: 4, value: "mixto", label: "Mixto" },
];

export const mortgagesType = [
  { id: 1, value: "nuevaHipoteca", label: "Nueva hipoteca" },
  { id: 2, value: "mejoraDeHipoteca", label: "Mejora de hipoteca" },
  // { id: 3, value: "ampliacionDeCapital", label: "Ampliación de capital" },
  // { id: 4, value: "autopromotor", label: "Autopromotor" },
];

export const interestTypeRemortgageOptions = [
  { value: "fijo", label: "Fijo" },
  { value: "variable", label: "Variable" },
];

export const fundUsedForOptions = [
  {
    value: FundUsedFor.UPGRADE_ACTUAL_HOUSE,
    label: "Reforma o mejoras en la vivienda actual",
  },
  {
    value: FundUsedFor.BUY_OTHER_HOUSE,
    label: "Para la compra de otra vivienda",
  },
  { value: FundUsedFor.DEBT_REUNIFICACION, label: "Reunificación de deudas" },
  { value: FundUsedFor.OTHER, label: "Otros" },
];

export const getPropertyExistenceLabel = (value: number): string => {
  if (!value) return "";
  return (
    propertyExistenceOptions.find((o) => o.value === value)?.label ||
    String(value)
  );
};
