import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { MortgageFormData } from "shared/types/postMortgageData";
import { yesNoOption } from "utils/valueLabelOptions";
import { requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";

function PropertyTypeForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Será tu vivienda habitual?"
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            type: requiredStringSchema("Por favor seleccione una opción"),
          }),
        })
      )}
    >
      <FormFields.ListSelector<MortgageFormData>
        name="property.type"
        options={yesNoOption}
        onClick={onNext}
      />
    </StepLayout>
  );
}

export default PropertyTypeForm;
