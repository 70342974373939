import { Cell, ColumnInstance, Row } from "react-table";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { FormControlLabel } from "@mui/material";
import styles from "./styles.module.scss";
import Swal from "sweetalert2";
import { useState } from "react";
import {
  BaseApiSuccessRes,
  MortagageRealStateAgencies,
} from "shared/types/BaseApiResponse";

const checkObject = (obj: any) => {
  const keys = [
    "AltaenSistema",
    "OperacionenRiesgos",
    "NotadeEncargo",
    "Preaprobacionbancaria",
    "Tasacion",
    "FEIN",
    "Notaria",
    "Cerrado",
  ];
  return keys.some((key) => obj[key] !== "");
};

export const scoringColumn = (
  r: Row<{
    [key: string]: string;
  }>,
  c: ColumnInstance<{
    [key: string]: string;
  }>
) => {
  let value = `${r.values[c.id]}`;

  return (
    <span
      className={
        parseInt(value) <= 25
          ? styles.slow
          : parseInt(value) <= 50
          ? styles["medium-slow"]
          : parseInt(value) <= 75
          ? styles["medium-hight"]
          : styles["hight"]
      }
    >
      {value}
      {value !== "" && "%"}
    </span>
  );
};

export const simpleColumns = (
  cell: Cell<
    {
      [key: string]: string;
    },
    any
  >
) => {
  let value = cell.value === "no" ? "" : cell.value;
  return cell.column.Header !== "Column 1" ? (
    <span> {value}</span>
  ) : (
    <span className={styles.cellHiglights}>{value}</span>
  );
};

export const stepColumn = (
  r: Row<{
    [key: string]: string;
  }>,
  c: ColumnInstance<{
    [key: string]: string;
  }>
) => {
  let status = checkObject(r.original);
  let mortageStatus =
    (c.id === "SubastaActiva" && r.values[c.id] === "active") ||
    (c.id === "Subastacerrada" && r.values[c.id] === "closed") ||
    (c.id === "Ofertaaceptada" &&
      r.values[c.id] === "in_verification_accepted") ||
    (c.id === "Documentos" && r.values[c.id] === "closed_successfully");

  let operationStep =
    (r.values[c.id] === "notaEncargoFirmada" && c.id === "NotadeEncargo") ||
    (r.values[c.id] === "aprobacionBancaria" &&
      c.id === "Preaprobacionbancaria") ||
    (r.values[c.id] === "tasadoraRealizada" && c.id === "Tasacion") ||
    (r.values[c.id] === "altaSistema" && c.id === "AltaenSistema") ||
    (r.values[c.id] === "operacionRiesgos" && c.id === "OperacionenRiesgos") ||
    (r.values[c.id] === "fechaNotaria" && c.id === "Notaria") ||
    (r.values[c.id] === "cerrado" && c.id === "Cerrado");

  let step = mortageStatus && !status ? true : operationStep ? true : false;

  let value =
    r.values[c.id] === c.id || step ? (
      <span>
        <img
          width="24"
          height="24"
          src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/user.png"
        ></img>
      </span>
    ) : (
      ""
    );
  return <span>{value}</span>;
};

interface ColumApplyMortgageProps {
  r: Row<{
    [key: string]: string;
  }>;
  c: ColumnInstance<{
    [key: string]: string;
  }>;
  onApplyMortagage?: (data: any) => void;
}

export const ColumApplyMortgage = ({
  r,
  c,
  onApplyMortagage,
}: ColumApplyMortgageProps) => {
  const [checked, setChecked] = useState(false);

  let value = r.values[c.id];
  let isAplicable =
    (r.values[c.id] === "draft" && r.values.Prescoring > 50) ||
    (r.values[c.id] === "draftt" && r.values.Prescoring === "");
  let mortgage = r.original;

  const onClickInRadio = (value: any) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¿El cliente ${value.cliente}   quiere solicitar la subasta?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (onApplyMortagage) {
          onApplyMortagage(value.id);
        }

        return;
      }

      setChecked(false);
    });
  };

  const sendData = (value: string) => {
    // Send data to server
    if (onApplyMortagage) {
      onApplyMortagage(value);
    }
  };

  return isAplicable ? (
    <Radio
      checked={checked}
      onChange={(e) => {
        onClickInRadio(mortgage);
        setChecked(true);
      }}
      style={{ margin: "auto" }}
      value={r.original.id}
    />
  ) : (
    <span></span>
  );
};

export interface DataTable {
  id: string;
  email: string;
  cliente: string;
  Prescoring: string;
  ValorVivienda: string;
  AplicaraHipoteca: string;
  UbicacionVivienda: string;
  SubastaActiva: string;
  Subastacerrada: string;
  Ofertaaceptada: string;
  Documentos: string;
  AltaenSistema: string;
  OperacionenRiesgos: string;
  NotadeEncargo: string;
  Preaprobacionbancaria: string;
  Tasacion: string;
  FEIN: string;
  Notaria: string;
  Cerrado: string;
}

export const modifyData = (
  obj: BaseApiSuccessRes<MortagageRealStateAgencies[]>
) => {
  let res: any = {
    data: [],
    mortageToApply: [],
  };
  let data: Array<DataTable> = [];
  let mortageToApply: any = [];
  if (obj.results.length > 0) {
    data = obj.results.map((item) => {
      let objData: DataTable = {
        id: "",
        email: "",
        cliente: "",
        Prescoring: "", // quemados
        UbicacionVivienda: "",
        ValorVivienda: "", // quemados
        AplicaraHipoteca: "", // quemados
        SubastaActiva: "", // traerl
        Subastacerrada: "",
        Ofertaaceptada: "",
        Documentos: "", // pp default
        AltaenSistema: "", // esto los enpoint field
        OperacionenRiesgos: "",
        NotadeEncargo: "",
        Preaprobacionbancaria: "",
        Tasacion: "",
        FEIN: "",
        Notaria: "",
        Cerrado: "",
      };

      objData.id = item.id;
      objData.cliente = `${item.first_name} ${item.last_name}`;

      objData.email = item.email;
      if (item.mortgage) {
        let step =
          item.mortgage.crm_current_step !== null &&
          item.mortgage.crm_current_step
            ? item.mortgage.crm_current_step
            : "";

        objData.Prescoring = item.mortgage.scoring
          ? item.mortgage.scoring.calculation.reached_percent.toString()
          : "";
        objData["ValorVivienda"] =
          item.mortgage.mortgage_data.property.value.toString();
        objData["UbicacionVivienda"] =
          item.mortgage.mortgage_data.property.address.toString();

        // Status
        objData["AplicaraHipoteca"] =
          item.mortgage.status.toString() === "draft"
            ? item.mortgage.status.toString()
            : ""; // draft

        objData["SubastaActiva"] =
          item.mortgage.status.toString() === "active"
            ? item.mortgage.status.toString()
            : ""; // active
        objData["Subastacerrada"] =
          item.mortgage.status.toString() === "closed"
            ? item.mortgage.status.toString()
            : ""; // closed
        objData["Ofertaaceptada"] =
          item.mortgage.status.toString() === "in_verification_accepted"
            ? item.mortgage.status.toString()
            : ""; // in_verification_accepted
        objData.Documentos =
          item.mortgage.status.toString() === "closed_successfully"
            ? item.mortgage.status.toString()
            : ""; // in_verification_accepted // in_verification_accepted

        // Step
        objData["AltaenSistema"] = step === "altaSistema" ? step : "";
        objData["OperacionenRiesgos"] = step === "operacionRiesgos" ? step : "";
        objData["NotadeEncargo"] = step === "notaEncargoFirmada" ? step : "";
        objData["Preaprobacionbancaria"] =
          step === "aprobacionBancaria" ? step : "";
        objData["Tasacion"] = step === "tasadoraRealizada" ? step : "";
        objData.FEIN = step === "FEIN" ? step : "";
        objData["Notaria"] = step === "fechaNotaria" ? step : "";
        objData["Cerrado"] = step === "cerrado" ? step : "";

        if (item.mortgage.status.toString() === "draft") {
          mortageToApply.push(item);
        }
      } else {
        objData["AplicaraHipoteca"] = "draftt";
        mortageToApply.push(item);
      }

      return objData;
    });
  }

  res.data = data;
  res.mortageToApply = mortageToApply;

  return res;
};
