import {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { toast } from "react-toastify";

import { MainClientLayout } from "../../../shared/layouts";
import {
  PageTitle,
  MortgageCard,
  NotResults,
} from "../../../shared/components";

import { getMortgages } from "../../../services/api";
import { format, parseISO } from "date-fns";
import { sendTagEvent } from "utils/analytics";
import {
  useAuthenticated,
  useLocalStorage,
  useProfile,
} from "../../../shared/hooks";
import { getApplicationType } from "../../../utils/helpers";
import styles from "./styles.module.scss";
import { MortgageRes } from "shared/types/BaseApiResponse";

const CompletedMortgages: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const location = useLocation();
    const navigate = useNavigate();
    const [role] = useLocalStorage("role");
    const [mortgages, setMortgages] = useState([]);
    const profile = useProfile().profile;

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "client");

    useEffect(() => {
      getAll({ mode: "closed_successfully" }, 1, 100);
    }, []);

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    const getAll = (filter: any, page: number, limit: number) => {
      getMortgages(filter, page, limit)
        .then((response: any) => {
          setMortgages(response.data.results);
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    };

    return (
      <MainClientLayout>
        <>
          <Container className={styles.CompletedMortgagesWrapper}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <PageTitle title="Subastas completadas" />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={2}>
                  {mortgages.length === 0 && (
                    <Grid item md={12} sm={12} xs={12}>
                      <NotResults
                        title="Sin Resultados"
                        caption="No se han encontrado registros"
                      />
                    </Grid>
                  )}

                  {mortgages.map((m: MortgageRes, i: number) => {
                    return (
                      <Grid item md={4} sm={12} xs={12} key={i}>
                        {" "}
                        <MortgageCard
                          mortagage={m}
                          documentStatus={m.document_status}
                          status={m.status}
                          date={format(parseISO(m.created), "dd-MM-yyyy")}
                          end={m.ends}
                          id={m.id}
                          title={getApplicationType(
                            m?.mortgage_data.mortgage.mode
                          )}
                          onViewClick={() =>
                            navigate(`/client/mortgage/${m.id}/completed`)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      </MainClientLayout>
    );
  };

export default CompletedMortgages;
