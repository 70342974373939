import { GeneralFees, Bid } from "shared/types/Bid";

export function isInterestMixto(b: Bid) {
  return b.interest_data.mode === "mixed" || b.interest_data.mode === "mixto";
}

export function getFirstPeriodMixto(b: Bid) {
  const val = b.interest_data?.interest?.[0].rate;
  if (typeof val === "string") return Number(val);
  return val;
}

export const getComissionValue = (generalFees: GeneralFees) => {
  return generalFees.values
    ? generalFees.values.consulting_fees
    : generalFees.net_fees + generalFees.colibid_fees;
};

export const getNetFees = (generalFees: GeneralFees) => {
  return generalFees.values
    ? generalFees.values.net_fees
    : generalFees.net_fees;
};
