import { userSchema } from "shared/components/MortgageForm/validation/user";
import * as yup from "yup";
import { SchemaLike } from "yup/lib/types";

const userPickedSchema = userSchema.pick([
  "name",
  "surname",
  "dni",
  "email",
  "birthdate",
  "phone",
]);
export const basicPersonalValidation = yup.object().shape({
  user: userPickedSchema,
  user_2: isConjuntaValidate(userPickedSchema),
});

export function isConjuntaValidate(sucessSchema: SchemaLike) {
  return yup.object().when("solicitor_type", {
    is: (val: any) => {
      return Number(val) === 2;
    },
    then(schema) {
      return sucessSchema;
    },
    otherwise(schema) {
      return schema.nullable();
    },
  });
}
