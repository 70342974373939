import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Chip, IconButton, Link, Typography } from "@mui/material";

import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import { FormState } from "react-hook-form";

import { differenceInYears } from "date-fns";
import { getEmploymentSituation } from "utils/helpers";

import InfoCard, {
  setTableRowsData,
  TableRows,
} from "shared/components/InfoCard";
import { MortgageFormData } from "shared/types/postMortgageData";

import {
  CURRENT_HOUSE_SITUATION_OPTIONS,
  ESTADO_CIVIL_OPTIONS,
  getOptionsLabel,
  HAS_CHILDREN_OPTIONS,
} from "../../MortgageForm/utils";
import { CustomInfoCardProps } from "./types";
import { TabSection } from "../../TabSection";
import EditMortgageModalCard from "./EditMortgageModalCard";
import { StepOne } from "shared/components/MortgageForm/components";
import { getTruthyValuesLength } from "../utils";

export default function InformationGeneralInfoCard({
  hasTitle = true,
  censure = false,
  data,
  editable = false,
  formState,
  modalOnClick,
  onNext,
  stepViews,
  open,
  showBackgroundColor,
  showFieldLens,
}: CustomInfoCardProps<MortgageFormData>) {
  const solicitorType = Number(data?.solicitor_type);

  const errors = formState?.errors;
  const userHasErrors = !!errors?.user && Object.keys(errors?.user).length > 0;
  const user2HasErrors =
    errors?.user_2 && Object.keys(errors?.user_2).length > 0;

  const userTableRowData = getUserData({
    censure,
    user: data?.user,
    userErrors: errors?.user,
  });
  const user2TableRowData = getUserData({
    censure,
    user: data?.user_2,
    userErrors: errors?.user_2,
  });
  const truthyVals = getTruthyValuesLength(userTableRowData)
  return hasTitle ? (
    <InfoCard
      title={
        <Typography component="div">
          Datos personales{" "}
          {showFieldLens && (
            <Chip color="primary" label={`${truthyVals} campos`} />
          )}
        </Typography>
      }
      open={open}
      editable={editable}
      {...(showBackgroundColor && { background: "#01857A1A" })}
    >
      {/* NOTE: theres is a new SolicitorTab check if can swap for that comp */}
      <TabSection
        tabs={[
          {
            muiTabProps: {
              label: "solicitante",
              icon: <PersonIcon />,
              sx: {
                width: `${100 / 2}%`,
                maxWidth: `${100 / 2}%`,
                fontSize: ".8rem",
                backgroundColor: `${userHasErrors ? "pink" : ""}`,
              },
            },
            renderInTabPanel: (
              <TableRows
                data={getUserData({
                  censure,
                  user: data?.user,
                  userErrors: errors?.user,
                })}
              />
            ),
          },
          {
            muiTabProps: {
              disabled: solicitorType === 1,
              label: "acompañante",
              icon: <SupervisedUserCircleIcon />,
              sx: {
                width: `${100 / 2}%`,
                maxWidth: `${100 / 2}%`,
                fontSize: ".8rem",
                backgroundColor: `${user2HasErrors ? "pink" : ""}`,
              },
            },
            renderInTabPanel: (
              <TableRows
                data={getUserData({
                  censure,
                  user: data?.user_2,
                  userErrors: errors?.user_2,
                })}
              />
            ),
          },
        ]}
      />
      {editable && stepViews && (
        <EditMortgageModalCard stepViews={stepViews} onClick={modalOnClick} />
      )}
      {editable && onNext && (
        <EditInfoGeneralCard handleOnNext={onNext} data={data} />
      )}
    </InfoCard>
  ) : (
    <TabSection
      tabs={[
        {
          muiTabProps: {
            label: "solicitante",
            icon: <PersonIcon />,
            sx: {
              width: `${100 / 2}%`,
              maxWidth: `${100 / 2}%`,
              fontSize: ".8rem",
              backgroundColor: `${userHasErrors ? "pink" : ""}`,
            },
          },
          renderInTabPanel: (
            <TableRows
              data={userTableRowData}
            />
          ),
        },
        {
          muiTabProps: {
            disabled: solicitorType === 1,
            label: "acompañante",
            icon: <SupervisedUserCircleIcon />,
            sx: {
              width: `${100 / 2}%`,
              maxWidth: `${100 / 2}%`,
              fontSize: ".8rem",
              backgroundColor: `${user2HasErrors ? "pink" : ""}`,
            },
          },
          renderInTabPanel: (
            <TableRows
              data={user2TableRowData}
            />
          ),
        },
      ]}
    />
  );
}

function getUserData({
  censure,
  user,
  userErrors,
}: {
  censure?: boolean;
  user?:
    | CustomInfoCardProps<MortgageFormData>["data"]["user"]
    | CustomInfoCardProps<MortgageFormData>["data"]["user_2"];
  userErrors:
    | FormState<MortgageFormData>["errors"]["user"]
    | FormState<MortgageFormData>["errors"]["user_2"];
}) {
  const getAge = (
    birthdate?: MortgageFormData["user"]["birthdate"]
  ): string => {
    if (birthdate) {
      return `${differenceInYears(new Date(), new Date(birthdate))} años`;
    }
    return "";
  };

  return {
    Nombre: setTableRowsData(
      `${user?.name} ${user?.surname}`,
      userErrors?.name?.message || userErrors?.surname?.message,
      censure
    ),
    "DNI/NIE": setTableRowsData(user?.dni, userErrors?.dni?.message, censure),
    "Tipo de documento": setTableRowsData(
      user?.type_dni,
      userErrors?.type_dni?.message
    ),
    "Estado Civil": setTableRowsData(
      getOptionsLabel({
        options: ESTADO_CIVIL_OPTIONS,
        value: user?.marital_status,
      }),
      userErrors?.marital_status?.message
    ),
    "Hijos o personas a cargo": setTableRowsData(
      getOptionsLabel({
        options: HAS_CHILDREN_OPTIONS,
        value: user?.children,
      }),
      userErrors?.children?.message
    ),
    "Situación de vivienda actual": setTableRowsData(
      getOptionsLabel({
        options: CURRENT_HOUSE_SITUATION_OPTIONS,
        value: user?.current_house_situation,
      }),
      userErrors?.current_house_situation?.message
    ),
    "País de residencia": setTableRowsData(
      user?.residence,
      userErrors?.residence && "message" in userErrors?.residence
        ? userErrors?.residence.message
        : ""
    ),
    Edad: setTableRowsData(
      getAge(user?.birthdate),
      userErrors?.birthdate?.message
    ),
    "Situación laboral": setTableRowsData(
      getEmploymentSituation(user?.work),
      userErrors?.work?.message
    ),
    Teléfono: setTableRowsData(
      user?.phone,
      userErrors?.phone?.message,
      censure,
      !censure ? (
        <Link
          href={`https://api.whatsapp.com/send?phone=34${user?.phone}`}
          target="_blank"
        >
          <IconButton>
            <WhatsAppIcon color="primary" />
          </IconButton>
        </Link>
      ) : null
    ),
    Email: setTableRowsData(
      user?.email,
      userErrors?.email?.message,
      censure,
      !censure ? (
        <Link href={`mailto:${user?.email}`} target="_blank">
          <IconButton>
            <EmailIcon color="primary" />
          </IconButton>
        </Link>
      ) : null
    ),
  };
}

const EditInfoGeneralCard = ({
  handleOnNext,
  data,
}: {
  handleOnNext: (form: any) => void;
  data: MortgageFormData;
}) => {
  return (
    <InfoCard.Modals title="Editar informacion general">
      {(closeModal) => {
        return (
          <StepOne
            editMode={true}
            onNext={(form) => {
              handleOnNext(form);
              closeModal();
            }}
            defaultValues={data}
          />
        );
      }}
    </InfoCard.Modals>
  );
};
