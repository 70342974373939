import { Box, Typography, Divider } from "@mui/material";

import { ITooltipParams } from "ag-grid-community";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { CRMTableActionsFields } from "shared/types/Crm";
import { formatCurrency } from "utils/helpers";
import { CrmForm } from "../../UploadDialog";

export default (params: ITooltipParams<any, CRMTableActionsFields>) => {
  const { location } = params;
  const isCell = location === "cell";
  const paramsVal = params.value;
  const isObject = typeof paramsVal === "object";
  const value = isObject ? paramsVal.value : paramsVal;
  let payload = params.value?.payload;
  let parsePayload: Omit<CrmForm, "comment"> | undefined = undefined;
  if (typeof payload === "string" && payload.length > 0) {
    try {
      parsePayload = JSON.parse(payload);
    } catch (err) {
      console.log({ err });
    }
  }
  return (
    <Box
      sx={{
        maxWidth: "fit-content",
        border: "1px solid cornflowerblue",
        borderRadius: "5px",
        overflow: "hidden",
        backgroundColor: "#C5F9E4",
        padding: "0.6rem",
      }}
    >
      {isCell ? (
        <>
          <Typography variant="subtitle1" fontWeight="bold">
            {params.data?.Cliente}
          </Typography>
          <Divider />
          {isObject && (
            <Typography variant="body1">
              <b>Fecha:</b>{" "}
              {format(new Date(paramsVal.created), "PPPP", {
                locale: es,
              })}
            </Typography>
          )}

          <Typography variant="body1">
            <b>Comentario:</b> {params.value?.comment || "No hay comentario"}
          </Typography>
          {parsePayload && (
            <>
              {parsePayload.amount && (
                <Typography variant="body1">
                  <b>Importe de hipoteca:</b>{" "}
                  {formatCurrency(parsePayload.amount)}
                </Typography>
              )}
              {parsePayload.interest_rate && (
                <Typography variant="body1">
                  <b>Tasa de Interés:</b> {parsePayload.interest_rate}
                </Typography>
              )}
              {parsePayload.consulting_fees && (
                <Typography variant="body1">
                  <b>Honorarios:</b>{" "}
                  {formatCurrency(parsePayload.consulting_fees)}
                </Typography>
              )}
              {parsePayload.bank && (
                <Typography variant="body1">
                  <b>Banco:</b> {parsePayload.bank}
                </Typography>
              )}
            </>
          )}
        </>
      ) : (
        <Typography variant="body1">{value}</Typography>
      )}
    </Box>
  );
};
