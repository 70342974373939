import { Box, Card, Skeleton, Stack } from "@mui/material";

const SkeletonCard = () => (
  <Card
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Stack
      direction={{ xs: "column", sm: "row" }}
      flexWrap="wrap"
      alignItems="center"
      spacing={2}
      padding="1rem"
      width="100%"
      display="grid"
      gridTemplateColumns={{ xs: "1fr ", sm: "1fr 1.5fr 1fr" }}
    >
      <Stack direction="row" gap={2} justifyContent="space-around">
        <Skeleton variant="text" width={200} height={30} />
        <Skeleton variant="text" width={250} height={30} />
      </Stack>

      <Stack
        direction="row"
        gap={2}
        justifyContent="space-around"
        alignItems="center"
      >
        <Box width={{ xs: "auto", sm: "40%" }}>
          <Skeleton variant="circular" width={50} height={50} />
        </Box>

        <Box width={{ xs: "auto", sm: "100%" }}>
          <Skeleton variant="rectangular" height={20} />
        </Box>
      </Stack>

      <Stack
        direction="row"
        gap={2}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Skeleton variant="rectangular" width={120} height={40} />
      </Stack>
    </Stack>
  </Card>
);

export default SkeletonCard;
