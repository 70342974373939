import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { parseISO, format } from "date-fns";
import { toast } from "react-toastify";

import { MainBrokerLayout } from "../../../shared/layouts";
import {
  PageTitle,
  BidFiles,
  BidsHistory,
  ConfirmAccept,
  BidDetails,
} from "../../../shared/components";

import IMortgageSchema from "../../../shared/schemas/mortgage.schema";
import {
  useAuthenticated,
  useLocalStorage,
  useMortgageBidAccepted,
} from "../../../shared/hooks";

import { getMortgage, brokerAcceptBid } from "../../../services/api";
import { censureChars } from "../../../utils/helpers";

import { sendTagEvent } from "utils/analytics";
import styles from "./styles.module.scss";
import Countdown from "react-countdown";
import CustomAlert from "shared/components/CustomAlert";
import { useMortgage, useProfile } from "shared/hooks/index";
import {
  HipotecaInfoCard,
  InformationGeneralInfoCard,
  PropertyInfoCard,
  FamilyEconomyInfoCard,
  WorkInfoCard,
} from "shared/components/InfoCard/mortgageCards";
import { MortgageData } from "shared/types/postMortgageData";

const ApplicationDetails: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [role] = useLocalStorage("role");
    // const [mortgage, setMortgage] = useState<IMortgageSchema>({});
    const [bid, setBid] = useState<any | null>(null);
    const [showDialogConfirm, setShowDialogConfirm] = useState(false);
    const [tab, setTab] = useState(0);
    const [tabvalidator, setTabValidator] = useState(true);
    const { mortgage, fetchMortgage } = useMortgage(id);
    const profile = useProfile().profile;

    useAuthenticated(
      (allow) => {
        if (!allow) navigate("/sign-in");
      },
      ["broker", "bank"]
    );

    const getDetails = (id: string) => {
      fetchMortgage(id);
      if (mortgage) {
        if (mortgage.mortgage_data.solicitor_type !== 1) {
          setTabValidator(false);
        }
      }
    };

    useEffect(() => {
      getDetails(id!);
    }, [id]);

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    const confirmAction = (bid: any) => {
      setShowDialogConfirm(true);
      setBid(bid);
    };

    const accept = (b: any) => {
      if (mortgage) {
        brokerAcceptBid(mortgage.id, b)
          .then(() => {
            toast.success(
              "Puja confirmada correctamente y enhorabuena por tu nuevo cliente 🥳, te recomendamos ponerte en contacto lo antes posible para finalizar los últimos detalles 😄"
            );
            getDetails(id!);
            navigate(`/broker/clients/${mortgage.id}`);
          })
          .catch((e: any) => {
            toast.error(
              "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
            );
            console.log(e);
          });
      }
    };

    const handleChangeTab = (value: any) => {
      setTab(value);
    };
    return (
      <MainBrokerLayout>
        <Container className={styles.ApplicationDetailsWrapper}>
          {mortgage?.mortgage_data && (
            <Grid container spacing={4}>
              <Grid item md={12} sm={12} xs={12}>
                <Countdown
                  date={parseISO(mortgage?.ends!)}
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    if (completed) return null;
                    if (days === 0 && hours === 0 && minutes <= 59) {
                      return (
                        <CustomAlert
                          alertProps={{ severity: "warning" }}
                          title="¡Date prisa!"
                          subtitle={
                            <Typography>
                              ¡Esta subasta cerrará en menos de{" "}
                              <strong>1 hora</strong>!
                            </Typography>
                          }
                        />
                      );
                    }
                    return null;
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} className="censure">
                <PageTitle
                  title={censureChars(
                    `${mortgage.mortgage_data?.user.name} ${mortgage.mortgage_data?.user.surname}`
                  )}
                />
              </Grid>

              <Grid item md={4} sm={12} xs={12}>
                <HipotecaInfoCard
                  data={mortgage.mortgage_data as MortgageData}
                  open
                />

                <br />

                <WorkInfoCard
                  data={mortgage.mortgage_data as MortgageData}
                  open
                />

              </Grid>

              <Grid item md={4} sm={12} xs={12}>
                <FamilyEconomyInfoCard
                  data={mortgage.mortgage_data as MortgageData}
                  open
                />

                <br />

                <InformationGeneralInfoCard
                  data={mortgage.mortgage_data as MortgageData}
                  censure
                  open
                />
              </Grid>

              <Grid item md={4} sm={12} xs={12}>
                <PageTitle title="Pujas" />
                <br />
                <BidsHistory
                  buttonTitle="Pujar"
                  data={mortgage}
                  onClick={(selected) => setBid(selected)}
                  onUpdate={() => getDetails(id!)}
                  onConfirm={(b) => confirmAction(b)}
                />
              </Grid>

              <Grid item md={4} sm={12} xs={12}></Grid>

              <Grid item md={12} sm={12} xs={12}>
                <PageTitle title="Documentación" />
                <br />
                {mortgage && (
                  <BidFiles
                    mortgage={mortgage}
                    allowDownload={
                      mortgage.status === "closed_successfully" ||
                      mortgage.status === "paid"
                        ? true
                        : false
                    }
                  />
                )}
              </Grid>
            </Grid>
          )}
          {bid && !showDialogConfirm && (
            <BidDetails
              details={bid}
              open={true}
              onClose={() => setBid(null)}
              accept={false}
              onAccept={(b) => {}}
            />
          )}

          {showDialogConfirm && (
            <ConfirmAccept
              bid={bid.id}
              title="¡Enhorabuena tu oferta tiene buena pinta!"
              caption="Al aceptar se dará por finalizada la subasta con la siguiente oferta
            para el cliente"
              bidData={bid}
              mortgageData={mortgage}
              onConfirm={(b) => {
                accept(b);
                setShowDialogConfirm(false);
                setBid(null);
              }}
              onClose={() => {
                setBid(null);
                setShowDialogConfirm(false);
              }}
            />
          )}
        </Container>
      </MainBrokerLayout>
    );
  };

export default ApplicationDetails;
