import React, { useState } from "react";

import {
  Typography,
  Collapse,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ExpandMore from "./ExpandMore";

import styles from "../styles.module.scss";
import FoundationIcon from "@mui/icons-material/Foundation";
import { constructionTypeOptions } from "shared/components/MortgageForm/utils";
import { DefaultFilters } from "../utils";
import FilterRangeSelected from "./FilterRangeSelected";

type Props = {
  filters: DefaultFilters;
  readOnly: boolean;
  changeValue: (key: string, value: any, active?: boolean) => void;
};

// convert this to propertyConstructionType
export default function PropertyConstructionTypeFilters({
  readOnly,
  filters,
  changeValue,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const handleRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;
    changeValue("constructionType", Number(value));
  };

  readOnly = false;

  return (
    <>
      <Typography variant="h6" className={styles.tittleFilter}>
        <FoundationIcon
          sx={{
            color: "#656565",
            marginRight: "1rem",
          }}
        />
        Tipos de contrucción
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
          style={{
            float: "right",
          }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {readOnly ? (
          <FormControl>
            <RadioGroup onChange={handleRadioOnChange}>
              {constructionTypeOptions.map((p, i) => (
                <FormControlLabel
                  key={p.label}
                  className={styles.LabelCheckbox}
                  label={p.label}
                  value={p.value}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : (
          <Stack>
            {constructionTypeOptions.map((p, i) => {
              let elements = filters.constructionType__in;
              const hasElement = elements ? elements.length > 0 : false;
              const pVal = Number(p.value);
              const currVal: number[] = elements;

              const isCheck = hasElement ? currVal.includes(pVal) : false;

              return (
                <FormControlLabel
                  key={p.value}
                  className={styles.LabelCheckbox}
                  label={p.label}
                  control={
                    <Checkbox
                      checked={isCheck}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        let val = currVal;
                        if (currVal.includes(pVal)) {
                          val = currVal.filter((v) => v !== pVal);
                        } else {
                          val.push(pVal);
                        }

                        changeValue("constructionType__in", val);
                      }}
                    />
                  }
                />
              );
            })}

            <FilterRangeSelected
              title="Metros cuadrado de la vivienda"
              name="propertyAreaRange"
              minFilterSelected={30}
              maxFilterSelected={1000}
              stepFilterSelected={10}
              sliderValue={filters.propertyAreaRange}
              inputOneValue={filters.propertyAreaRange[0]}
              inputTwoValue={filters.propertyAreaRange[1]}
              filtersSelected={filters}
              onChangeFilter={(data, active) => {
                changeValue("propertyAreaRange", data, active);
              }}
              labelFilterSelected={(value) => {
                return value;
                // return formatCurrency(value);
              }}
              onChangeInputOne={(data, active) => {
                let value = [data, filters.propertyAreaRange[1]];
                changeValue("propertyAreaRange", value, active);
              }}
              onChangeInputTwo={(data, active) => {
                let value = [filters.propertyAreaRange[0], data];
                changeValue("propertyAreaRange", value, active);
              }}
            />
          </Stack>
        )}
      </Collapse>
    </>
  );
}
