import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import InfoCard from ".";
import TableSkeleton from "../TableSkeleton";

type Props = {};
export default function SkeletonInfoCard({}: Props) {
  return (
    <Stack gap={1}>
      <Skeleton height="3rem" width="12rem" />
      <TableSkeleton headColumns={2} bodyRows={6} bodyColumns={4} />
    </Stack>
  );
}
