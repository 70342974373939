import React, { HTMLAttributes, ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  number: string;
  title: string;
  paragraph: string | ReactNode;
  colorNumber: string;
  style?: React.CSSProperties;
  styleBox?: string;
  styleTitle?: string;
}

const NumberBox: React.FC<Props> = ({
  number,
  title,
  paragraph,
  colorNumber,
  style,
  styleBox,
  styleTitle,
}) => {
  return (
    <div className={`${styles.myBox} ${styleBox}`} style={style}>
      <span style={{ color: `${colorNumber}` }} className={styles.myBoxNumber}>
        {number}
      </span>

      <h2 className={`${styles.myBoxTitle} ${styleTitle}`}>{title}</h2>
      <p className={styles.myBoxParagraph}>{paragraph}</p>
    </div>
  );
};

export default NumberBox;
