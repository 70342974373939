import React, { useRef } from "react";
import { ComponentType, ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import {
  IncomesList,
  ExpensesList,
  MortgageFormData,
} from "shared/types/postMortgageData";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Path } from "react-hook-form";
import { TabSection, TabSectionRef } from "shared/components/TabSection";

type Props<T extends ComponentType<any>> = {
  /**The component to render, fill this field first to get the autosuggestion/autocorrection ide*/
  RenderComponent: T;
  /** the props of the component to render, it will then spread it to the render component
   *  there is autosugestion of the props of the component
   *  if there is no autosuggestion maybe pass the component to RenderComponent first
   */
  individualProps: React.ComponentProps<any>;
  /** Same as propOptions, is a copy of the first propOptions that will be pass to another
   * copy of RenderComponent, used to change some field of propOptions to pass to the second copy element
   *  */
  conjuntaProps?: Partial<React.ComponentProps<T>>;
};
export default function HandleSolicitorView<T extends ComponentType<any>>({
  RenderComponent,
  individualProps,
  conjuntaProps,
}: Props<T>) {
  const { getFieldState, getValues, formState, clearErrors } =
    useFormContext<MortgageFormData>();

  const solicitorType = getValues("solicitor_type");
  const isConjunta = Number(solicitorType) === 2;
  const userComponent = <RenderComponent {...individualProps} />;
  if (!conjuntaProps || !isConjunta) {
    return userComponent;
  }
  const incomesList = getValues("revenue.incomes_list");
  const expensesList = getValues("revenue.expenses_list");
  const incomesListError = getFieldState("revenue.incomes_list").error;
  const expensesListError = getFieldState("revenue.expenses_list").error;
  const revenueListHasError = !!incomesListError || !!expensesListError;

  const userHasError =
    !!getFieldState("user").error ||
    (
      hasRevenueError({
        incomesList,
        expensesList,
        solicitor: 1,
      }));
  const user_2HasError =
    !!getFieldState("user_2").error ||
    (
      hasRevenueError({
        incomesList,
        expensesList,
        solicitor: 2,
      }));
  const user2Component = conjuntaProps
    ? React.createElement(RenderComponent, {
        ...individualProps,
        ...conjuntaProps,
      })
    : null;
  return (
    <SolicitorTabs
      solitante={{ error: userHasError, render: userComponent }}
      conjunta={{ error: user_2HasError, render: user2Component }}
      solicitorType={solicitorType}
      clearErrors={clearErrors}
    />
  );
}

function hasRevenueError({
  incomesList,
  expensesList,
  solicitor,
}: {
  incomesList: IncomesList[];
  expensesList: ExpensesList[];
  solicitor: 1 | 2;
}) {
  const userIncomeHasError = incomesList.filter(
    (f) => Number(f.solicitor) === solicitor,
  );
  const userExpenseHasError = expensesList.filter(
    (f) => Number(f.solicitor) === solicitor,
  );
  const combArr = [...userIncomeHasError, ...userExpenseHasError];
  return combArr.some((r) => {
    for (const k in r) {
      const val = r[k as keyof (IncomesList | ExpensesList)];
      if (val === "") {
        return true;
      }
    }
  });
}

interface solicitorOption {
  render: ReactNode;
  error: boolean;
}

type SolicitorTabsProps = {
  solitante: solicitorOption;
  conjunta: solicitorOption;
  solicitorType: unknown;
  clearErrors?: (path: Path<MortgageFormData>) => void;
};
export function SolicitorTabs({
  conjunta,
  solitante,
  solicitorType,
  clearErrors,
}: SolicitorTabsProps) {
  const hasClearErrorOnInitRef = useRef<boolean>(false);
  const ha2TabVisited = useRef<boolean>(false);
  const tabSectionRef = useRef<TabSectionRef>(null);
  solicitorType = Number(solicitorType);
  const userHasError = solitante.error;
  const user2HasError = conjunta.error;
  const initialTabVal = userHasError ? 0 : user2HasError ? 1 : 0;
  if (tabSectionRef.current) {
    const { tabVal, setTabVal } = tabSectionRef.current;
    // userHasError && setTabVal(0);
    // user2HasError && setTabVal(1);
  }
  return (
    <TabSection
      ref={tabSectionRef}
      stackProps={{
        sx: {
          width: "100%",
          height: "100%",
        },
      }}
      initialTabVal={initialTabVal}
      tabs={[
        {
          muiTabProps: {
            label: "solicitante",
            icon: <PersonIcon />,
            sx: {
              width: `${100 / 2}%`,
              maxWidth: `${100 / 2}%`,
              fontSize: ".8rem",
              backgroundColor: `${solitante.error ? "pink" : ""}`,
            },
          },
          renderInTabPanel: solitante.render,
        },
        {
          muiTabProps: {
            disabled: solicitorType === 1,
            label: "acompañante",
            icon: <SupervisedUserCircleIcon />,
            sx: {
              width: `${100 / 2}%`,
              maxWidth: `${100 / 2}%`,
              fontSize: ".8rem",
              backgroundColor: `${conjunta.error ? "pink" : ""}`,
            },
          },
          renderInTabPanel: conjunta.render,
        },
      ]}
    />
  );
}
