import { useState } from "react";

const useDialog = (state: boolean = false) => {
  const [isShowing, setIsShowing] = useState(state);

  function toggle() {
    setIsShowing((oldV) => !oldV);
  }

  function closeDialog() {
    setIsShowing(false);
  }

  function openDialog() {
    setIsShowing(true);
  }

  return {
    isShowing,
    toggle,
    closeDialog,
    openDialog,
  };
};

export type UseDialogReturn = ReturnType<typeof useDialog>;

export default useDialog;
