import { ROLE } from "shared/constants/localStorage";
import { Role } from "shared/types/Profile";
import useLocalStorage from "./useLocalStorage";

export default function useRole(): {
  role: Role;
  isUserBank: boolean;
  isClient: boolean;
} {
  const [role] = useLocalStorage(ROLE);
  const isUserBank = role === "bank";
  const isClient = role === "client";

  return {
    role,
    isUserBank,
    isClient,
  };
}
