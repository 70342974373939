import {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Container,
  Grid,
  Typography, IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

// import { getDuration } from "../../../utils/helpers";

import { MainBrokerLayout } from "../../../shared/layouts";
import {
  PageTitle,
  SearchBar,
  PaginationBar,
  Timer,
  NotResults,
  ApplicationsFilterModal,
  TableSkeleton,
  MetricPlans,
} from "../../../shared/components";
import { toast } from "react-toastify";
import { useLocalStorages } from "shared/hooks";

import IMortgageSchema from "../../../shared/schemas/mortgage.schema";
import {
  useAuthenticated,
  useLocalStorage,
  useLoading,
} from "../../../shared/hooks";

import FilterAlt from "@mui/icons-material/FilterAlt";
import FilterAltOff from "@mui/icons-material/FilterAltOff";
import { getMortgages } from "../../../services/api";
import {
  getInterestType,
  getApplicationType,
  formatCurrency,
} from "../../../utils/helpers";
import { format } from "date-fns";
import { sendTagEvent } from "utils/analytics";

import {
  getFormatedFiltersForAPI,
  defaultFilters,
} from "../../../shared/components/ApplicationsFilterModal/helpers";
import styles from "./styles.module.scss";
import { useInterestRate, useProfile } from "shared/hooks";
import dataStorageService from "services/api/dataStorage.service";
import { BIDDERS_FILTERS } from "shared/constants/localStorage";
import { deepSameKeys } from "utils";

const Applications: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const initialFilters: any = defaultFilters();
  const { mortgageAllowedCalculator } = useInterestRate();
  const [role] = useLocalStorage("role");
  const location = useLocation();
  const navigate = useNavigate();
  const [mortgages, setMortgages] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [isFiltering, setIsFiltering] = useLocalStorages("isFiltering", false);
  const [applicationsPaging, setApplicationsPaging] = useLocalStorages(
    "application_paging",
    {
      page: 1,
      pages: 0,
      count: 0,
      limit: 25,
    }
  );
  const profile = useProfile().profile;

  const [filterValue, setFilter] = useState(initialFilters);
  const [isFetching, setIsFetching] = useState(true);
  const setFilterValue = (filters: any) => {
    dataStorageService.setData({ key: BIDDERS_FILTERS, payload: filters });
    setFilter(filters);
  };
  const tableContainerRef = useRef<any>(null);
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const isLoading = useLoading();
  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["broker", "bank"]
  );

  useEffect(() => {
    dataStorageService
      .getData(BIDDERS_FILTERS)
      .then((res) => {
        const cachedFilter = res.data.results.payload;
        const isSameFilter = deepSameKeys(initialFilters, cachedFilter);
        if (!isSameFilter) {
          clearFilters();
          return;
        }
        setFilter(cachedFilter);
      })
      .catch((err) => {
        console.log("no filter found", { err });
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    handleMortgagesFetching();
  }, [filterValue, isFetching]);

  useEffect(() => {
    if (!isFetching) {
      // Scroll to the saved position after updating the table
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = savedScrollPosition;
      }
    }
  }, [mortgages]);

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  const handleMortgagesFetching = () => {
    if (!isFetching) {
      const savedScrollPosition = tableContainerRef.current
        ? tableContainerRef.current.scrollTop
        : 0;

      if (_.isEqual(initialFilters, filterValue)) {
        getAll({}, applicationsPaging.page, applicationsPaging.limit);
        setIsFiltering(false);
      } else {
        setIsFiltering(true);
        const data = getFormatedFiltersForAPI(filterValue);
        getAll(
          { mode: "new", sort: "ends", filters: data },
          applicationsPaging.page,
          applicationsPaging.limit
        );
      }
      // Save the scroll position
      setSavedScrollPosition(savedScrollPosition);
    }
  }

  const handleRowsClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    m: { id: string }
  ) => {
    e.preventDefault();
    window.open(`/broker/applications/${m.id}`, "_blank");
    handleMortgagesFetching();
  };

  const getAll = (filter: any, page: number, limit: number) => {
    getMortgages(filter, page, limit)
      .then((response: any) => {
        isLoading.setValue(false);
        setMortgages(response.data.results);

        applicationsPaging.page = response.data.page;
        applicationsPaging.pages = response.data.pages;
        applicationsPaging.limit = response.data.items_per_page;
        applicationsPaging.count = response.data.mortgages;

        setApplicationsPaging({
          page: response.data.page,
          pages: response.data.pages,
          limit: response.data.items_per_page,
          count: response.data.mortgages,
        });

        // Scroll to the saved position after updating the table
        if (tableContainerRef.current) {
          tableContainerRef.current.scrollTop = savedScrollPosition;
        }
      })
      .catch((e: any) => {
        isLoading.setValue(false);
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const clearFilters = () => {
    setFilterValue({ ...initialFilters });
  };

  return (
    <MainBrokerLayout>
      <Container className={styles.ApplicationsWrapper}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Hipotecas en subasta" />{" "}
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <MetricPlans />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <SearchBar
              onChange={function (searchText: string): void {
                throw new Error("Function not implemented.");
              }}
            >
              <>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  Filtros
                </Typography>

                <IconButton
                  onClick={() => setShowFilterModal(true)}
                  color={isFiltering ? "success" : "default"}
                >
                  {isFiltering ? <FilterAlt /> : <FilterAltOff />}
                </IconButton>
              </>
            </SearchBar>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            {(() => {
              if (mortgages.length > 0 && !isLoading.loading) {
                return (
                  <TableContainer
                    sx={{ maxHeight: 650 }}
                    component={Paper}
                    ref={tableContainerRef}
                  >
                    <Table stickyHeader sx={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Tipo de hipoteca</TableCell>
                          <TableCell align="center">Tipo de interés</TableCell>
                          <TableCell align="center">
                            Valor de propiedad
                          </TableCell>
                          <TableCell align="center">
                            Importe de hipoteca
                          </TableCell>
                          <TableCell align="center">
                            Porcentaje de financiación
                          </TableCell>
                          <TableCell align="center">
                            Ratio de endeudamiento
                          </TableCell>
                          <TableCell align="center">
                            Plazo de la hipoteca
                          </TableCell>
                          <TableCell align="center">Pujas recibidas</TableCell>
                          <TableCell align="center">
                            Fin de la subasta
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mortgages.map((m: IMortgageSchema, i: number) => {
                          const { data, revenue } = m.mortgage_data;
                          return (
                            <TableRow
                              hover
                              onClick={(e) => handleRowsClick(e, { id: m.id })}
                              key={i || 0}
                              className={m.seen === true ? styles.IsSeen : ""}
                            >
                              <TableCell align="center">
                                {getApplicationType(
                                  m.mortgage_data.mortgage.mode
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {getInterestType(
                                  m.mortgage_data.data.interest_type
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {formatCurrency(m.mortgage_data.property.value)}
                              </TableCell>
                              <TableCell align="center">
                                {formatCurrency(m.mortgage_data.data.amount)}
                              </TableCell>
                              <TableCell align="center">
                                {m.mortgage_data.data.mortgage_rate} %
                              </TableCell>
                              <TableCell align="center">
                                {data?.debtRatio
                                  ? data.debtRatio
                                  : mortgageAllowedCalculator({
                                      dataAmount: Number(data.amount),
                                      dataPeriod: Number(data.period),
                                      expensesList: revenue.expenses_list,
                                      revenueMonthly: Number(revenue.monthly),
                                      interestType: data.interest_type,
                                    }).debtRatio}{" "}
                                %
                              </TableCell>
                              <TableCell align="center">
                                {m.mortgage_data.data.period} años
                              </TableCell>
                              <TableCell align="center">
                                {m.bids_count}
                              </TableCell>
                              <TableCell align="center">
                                <Timer date={m?.ends} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              } else if (!isLoading.loading && mortgages.length === 0) {
                return (
                  <NotResults
                    title="Sin Resultados"
                    caption="No se han encontrado registros"
                  />
                );
              }
            })()}

            {isLoading.loading && (
              <TableSkeleton headColumns={4} bodyRows={6} bodyColumns={4} />
            )}
          </Grid>

          {mortgages.length !== 0 && applicationsPaging.pages > 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <PaginationBar
                count={applicationsPaging.count}
                rowsPerPage={applicationsPaging.limit}
                page={applicationsPaging.page}
                pages={applicationsPaging.pages}
                onChange={(p) => {
                  applicationsPaging.page = p;

                  setApplicationsPaging({ ...applicationsPaging });
                  setFilterValue({ ...filterValue });
                }}
                onChangeLimit={(l) => {
                  applicationsPaging.page = 1;
                  applicationsPaging.limit = l;
                  setApplicationsPaging({ ...applicationsPaging });
                  setFilterValue({ ...filterValue });
                }}
              />
            </Grid>
          )}
        </Grid>
        <ApplicationsFilterModal
          readOnly={false}
          defaultFilters={filterValue}
          show={showFilterModal}
          onSubmit={(data) => {
            const newFilters = data;

            setApplicationsPaging((prevState: any) => {
              prevState.page = 1;
              return {
                ...prevState,
              };
            });

            setFilterValue({ ...newFilters });
            setShowFilterModal(false);
          }}
          onClose={() => {
            setShowFilterModal(false);
          }}
          onFiltering={(status) => {
            setIsFiltering(status);
          }}
          onClearFilter={() => {
            clearFilters();
            setShowFilterModal(false);
          }}
        />
      </Container>
    </MainBrokerLayout>
  );
};

export default Applications;
