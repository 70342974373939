import { useReducer } from "react";
import { FieldPath } from "react-hook-form";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid } from "shared/types/Bid";
import _ from "lodash";
import { MortgageData } from "shared/types/postMortgageData";
import {
  getComissionValue,
  getFirstPeriodMixto,
  isInterestMixto,
} from "utils/bids";

export interface BidTableState extends Omit<Bid, "mortgage"> {
  totalInterestValue: number;
  totalLoanValue: number;
}

export type BidTableFieldPath = FieldPath<BidTableState>;
export interface BidTableReducerState {
  isSortAsc: boolean;
  sortCol: BidTableFieldPath;
  /**se agregan 2 campos mas por q en backend no esta y se calcula on frontend por ahora
   * para ver los campos nuevo ver BidTableState
   */
  bidsTableState: BidTableState[];
  mortgage: MortgageRes;
}

export type BidTableAction =
  | { type: "SORT_ASC"; payload: BidTableFieldPath }
  | { type: "SORT_DES"; payload: BidTableFieldPath };

function reducer(state: BidTableReducerState, action: BidTableAction) {
  switch (action.type) {
    case "SORT_ASC":
      state.bidsTableState.sort((a, b) => {
        const { aVal, bVal } = getValuesToCompare(a, b, action.payload);
        if (typeof aVal === "string") return bVal.localeCompare(aVal);
        return bVal - aVal;
      });
      return {
        ...state,
        sortCol: action.payload,
        isSortAsc: true,
      };
    case "SORT_DES":
      state.bidsTableState.sort((a, b) => {
        const { aVal, bVal } = getValuesToCompare(a, b, action.payload);
        if (typeof aVal === "string") return aVal.localeCompare(bVal);
        return aVal - bVal;
      });
      return {
        ...state,
        sortCol: action.payload,
        isSortAsc: false,
      };
    default:
      return state;
  }
}

export default function useBidTableReducer({
  bids,
  mortgage,
  sortCol = "calculated.monthly",
}: {
  bids: Bid[];
  mortgage: MortgageRes;
  sortCol?: BidTableFieldPath;
}) {
  const initialState: BidTableReducerState = {
    mortgage,
    isSortAsc: false,
    sortCol,
    bidsTableState: bids
      .map((b) => {
        return {
          ...b,
          ...calcMortgageValues({ b, md: mortgage.mortgage_data }),
        };
      })
      // .sort((a, b) => _.get(b, sortCol) - _.get(a, sortCol))
      // .sort((a, b) => b.accepted.length - a.accepted.length),
  };

  return useReducer(reducer, initialState);
}

function calcMortgageValues({ b, md }: { b: Bid; md: MortgageData }) {
  const totalLoanValue = b.calculated?.monthly * md.data.period * 12;
  const totalInterestValue = totalLoanValue - md.data.amount;
  return { totalLoanValue, totalInterestValue };
}

function getValuesToCompare(a: Bid, b: Bid, sortCol: BidTableFieldPath) {
  if (sortCol === "interest_rate") {
    return {
      aVal: isInterestMixto(a) ? getFirstPeriodMixto(a) : _.get(a, sortCol),
      bVal: isInterestMixto(b) ? getFirstPeriodMixto(b) : _.get(b, sortCol),
    };
  }
  if (sortCol === "conditions_details") {
    return {
      aVal: a.conditions_details.filter((c) => c.allow).length,
      bVal: b.conditions_details.filter((c) => c.allow).length,
    };
  }
  if (sortCol === "bidder.name") {
    return {
      aVal: a.entity,
      bVal: b.entity,
    };
  }
  if (sortCol === "general_fees") {
    return {
      aVal: getComissionValue(a.general_fees),
      bVal: getComissionValue(b.general_fees),
    };
  }
  return {
    aVal: _.get(a, sortCol),
    bVal: _.get(b, sortCol),
  };
}
