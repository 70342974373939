import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { ESTADO_CIVIL_OPTIONS } from "../../utils";
import HandleSolicitorView from "../HandleSolicitorView";
import { isConjuntaValidate } from "./BasicPersonalDataForm/validation.schema";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import { MortgageFormData } from "shared/types/postMortgageData";
import StepLayout from "../StepLayout";
import { useFormContext } from "react-hook-form";
import { userSchema } from "../../validation/user";

const yupSchema = yup.object({
  user: userSchema.pick(["marital_status"]),
  user_2: isConjuntaValidate(userSchema.pick(["marital_status"])),
});

function MaritalStatusForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Cuál es tu estado civil?"
      resolver={yupResolver(yupSchema)}
    >
      <Form/>
    </StepLayout>
  );
}
function Form() {
  const { onNext } = useMortgageFormContext();
  return (
      <HandleSolicitorView
        RenderComponent={FormFields.ListSelector}
        individualProps={{
          name: "user.marital_status",
          options: ESTADO_CIVIL_OPTIONS,
          onClick: onNext,
        }}
        conjuntaProps={{ name: "user_2.marital_status" }}
      />
  );
}

export default MaritalStatusForm;
