import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { FunctionComponent, ReactNode, useEffect, useMemo } from "react";

import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  SpainComunitiesAndProvices,
  spainComunitiesAndProvices,
} from "../../data/spain_cominities_provinces";
import styles from "../../styles.module.scss";

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldPath, FormProvider, useForm, useWatch } from "react-hook-form";
import FormFields from "shared/components/FormFields";
import { TRACE_DATA_LINK } from "shared/constants/links";
import { MortgageFormData } from "shared/types/postMortgageData";
import MortgageCalculatorDialog from "../../components/MortgageCalculatorDialog";
import { HOUSE_SITUATION_OPTIONS } from "../../utils";
import { stepThreeValidationSchema } from "./validation.schema";

interface IStepThreeProps {
  onNext?: (formData: any) => void;
  onBack?: (formData?: any) => void;
  defaultValues: MortgageFormData;
  children?: ReactNode;
}

export const stepThreeFormTriggerArr: FieldPath<MortgageFormData>[] = [
  "property.community",
  "property.province",
  "property.city",
  "property.zipcode",
  "property.address",
  "property.house_situation",
];

export const StepThree: FunctionComponent<IStepThreeProps> = ({
  onNext,
  onBack,
  defaultValues,
  children,
}) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    resolver: yupResolver(
      stepThreeValidationSchema(defaultValues.mortgage.mode)
    ),
  });

  const { control, getValues, setValue, handleSubmit } = methods;

  const [propertyCommunity, propertyHouseSituation] = useWatch({
    control,
    name: ["property.community", "property.house_situation"],
  });
  const mortgageMode = getValues("mortgage.mode");

  useEffect(() => {
    const namesToCheck: FieldPath<MortgageFormData>[] = [
      "property.notasimple",
      "property.reserva",
      "property.arras",
    ];
    const names = getValues(namesToCheck);
    const nameIdx = names.findIndex((e) => Boolean(e));

    if (nameIdx >= 0) {
      setValue(`${namesToCheck[nameIdx]}`, "");
    }
  }, [propertyHouseSituation]);

  const handleOnNext = (data: any) => {
    onNext?.(data);
  };

  const handleOnBack = () => {
    const form = getValues()
    onBack?.(form);
  };

  const comunidades = useMemo(() => {
    return Object.keys(spainComunitiesAndProvices);
  }, []);

  const provincias = useMemo(() => {
    if (!propertyCommunity) {
      return [];
    }
    return spainComunitiesAndProvices[
      propertyCommunity as keyof SpainComunitiesAndProvices
    ];
  }, [propertyCommunity]);


  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(handleOnNext)}>
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12}>
            {Number(propertyHouseSituation) === 1 && (
              <MortgageCalculatorDialog
              />
            )}
            <Grid item xs={12} md={12}>
              <Typography variant="h5">Registro</Typography>
              <Divider component="br" />
            </Grid>

            {[1, 4].includes(mortgageMode) && (
              <>
                <Grid item xs={12} md={12}>
                  <Link href={TRACE_DATA_LINK} target="_blank" underline="none">
                    <Alert severity="info">
                      ¿Necesitas la nota simple? puedes obtenerla fácilmente
                      dando clic aquí
                    </Alert>
                  </Link>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormFields.SelectInput
                    name="property.house_situation"
                    label="¿Cómo va la búsqueda de tu nuevo hogar?"
                    menuItems={HOUSE_SITUATION_OPTIONS.map((e) => {
                      return {
                        key: e.value,
                        value: e.value,
                        label: e.label,
                      };
                    })}
                  ></FormFields.SelectInput>
                </Grid>
                <Divider component="br" />
                <Divider component="br" />
                <Divider component="br" />
              </>
            )}

            {Number(propertyHouseSituation) === 2 && (
              <>
                <Grid item xs={12} md={12}>
                  <FormFields.UploadFileInput
                    acceptedFile=".pdf"
                    regexToAcceptFile={/\.(pdf)$/i}
                    required={false}
                    name={`property.notasimple`}
                    label={`Subir nota simple (Opcional).`}
                  />
                </Grid>
                <Divider component="br" />
                <Divider component="br" />
                <Divider component="br" />
              </>
            )}
            {Number(propertyHouseSituation) === 3 && (
              <>
                <Grid item xs={12} md={12}>
                  <FormFields.UploadFileInput
                    acceptedFile=".pdf"
                    regexToAcceptFile={/\.(pdf)$/i}
                    required={false}
                    name={`property.reserva`}
                    label={`Subir reserva (Opcional).`}
                  />
                </Grid>
                <Divider component="br" />
                <Divider component="br" />
                <Divider component="br" />
              </>
            )}
            {Number(propertyHouseSituation) === 4 && (
              <>
                <Grid item xs={12} md={12}>
                  <FormFields.UploadFileInput
                    acceptedFile=".pdf"
                    regexToAcceptFile={/\.(pdf)$/i}
                    required={false}
                    name={`property.arras`}
                    label={`Subir Arras (Opcional).`}
                  />
                </Grid>
                <Divider component="br" />
                <Divider component="br" />
                <Divider component="br" />
              </>
            )}

            <Divider component="br" />

            <Grid item xs={12} md={12}>
              <Typography variant="body1">
                Por favor dinos donde está la propiedad.
              </Typography>
              <Box className={styles.StepFourImage}>
                <img
                  src="/assets/img/sources/location.svg"
                  alt=""
                  width="35%"
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.AutoCompleteInput
              options={comunidades.map((community) => community)}
              onChange={(e, v) => {
                setValue("property.province", "");
              }}
              name="property.community"
              disablePortal
              id="community-box"
              label="Comunidad"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.AutoCompleteInput
              noOptionsText="Por favor selecciona una comunidad"
              options={provincias.map((province: string) => province)}
              name="property.province"
              disablePortal
              id="province-box"
              label="Provincia"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.TextFieldInput
              id="no-autofill"
              autoComplete="no-autofill"
              label="Ciudad"
              name="property.city"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.TextFieldInput
              id="no-autofill"
              autoComplete="no-autofill"
              label="Código postal"
              name="property.zipcode"
              inputProps={{
                maxLength: 5,
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <FormFields.TextFieldInput
              id="no-autofill"
              autoComplete="no-autofill"
              label="Dirección"
              name="property.address"
            />
          </Grid>
          {children}

          {(onBack || onNext) && (
            <Grid item xs={12} md={12}>
              <Box className={styles.ActionButtons}>
                {onBack && (
                  <Button
                    variant="contained"
                    startIcon={<ArrowBackIosNew />}
                    onClick={handleOnBack}
                  >
                    Regresar
                  </Button>
                )}
                {onNext && (
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Siguiente
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
      <DevTool control={methods.control} placement="top-right" />
    </FormProvider>
  );
};
