import { yupResolver } from "@hookform/resolvers/yup";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  InputAdornment,
  Stack,
  StackProps,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import CountersDisplayCard from "shared/components/CounterDisplayCard";
import FormFields from "shared/components/FormFields";
import {
  INTEREST_RATE_RANGE,
  INTEREST_TYPE_REMORTGAGE_OPTIONS,
  MORTGAGE_INTEREST_RANGES,
  PENDING_AMOUNT_RANGES,
  PROPERTY_VALUE_RANGES,
  CURRENT_ORIGINAL_AMOUNT_RANGE,
  REMORTGAGE_INTEREST_RATE_RANGE,
} from "shared/constants/mortgageForm";
import { useBanks } from "shared/hooks";
import { MortgageFormData } from "shared/types/postMortgageData";
import {
  formatCurrency,
  formatLocalCurrency,
  getApplicationType,
} from "utils/helpers";
import {
  REQ_MSG,
  aboveZeroTest,
  lessThan,
  greaterThan,
  requiredStringSchema,
  isGreaterThan,
  isLessThan,
} from "utils/yupValidation";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import { MortgageMode, TestContextExtended } from "../../types";
import {
  getMortgageInitialRange,
  getMortgageRate,
  getMortgageRatePercentageAllowed,
  isFormAFuncionario,
} from "../../utils";
import { MortgageDataRow } from "../MortgageDataRow";
import StepLayout from "../StepLayout";
import { amountLTPropertyValueTest } from "../StepTwo/validation.schema";
import { getObjectRanges } from "utils/mortagageData";
import { isAfter, parseISO, subYears, isDate, differenceInYears } from "date-fns";
import Swal from "sweetalert2";

/**This form is to get his actual current mortgage */
function CurrentMortgageDataForm() {
  const { mortgageFormData, onNext, onBack } = useMortgageFormContext();
  const mortgageMode = mortgageFormData.mortgage.mode;
  const shouldValidateByMode = [1, 4].includes(Number(mortgageMode));

  //pasar property amount ranges
  const propertyValueRanges = getObjectRanges(
    mortgageMode,
    PROPERTY_VALUE_RANGES
  );

  const isReMortagage = mortgageMode === MortgageMode.REMORTGAGE;
  const minInterestRate = isReMortagage
    ? getObjectRanges(mortgageMode, MORTGAGE_INTEREST_RANGES).MIN
    : INTEREST_RATE_RANGE.min;
  const maxInterestRate = INTEREST_RATE_RANGE.max;

  function isValidNumberWithCommaOrDot(value: any) {
    const regex = /^\d+([.,]\d+)?$/;
    return regex.test(value);
  }

  const curr_original_amount = getObjectRanges(
    mortgageMode,
    CURRENT_ORIGINAL_AMOUNT_RANGE
  );
  return (
    <StepLayout
      title={"Cuéntanos un poco sobre tu hipoteca actual"}
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            value: requiredStringSchema(
              "Ingrese el valor actual de la propiedad"
            )
              .test(lessThan(propertyValueRanges.MIN))
              .test(
                greaterThan(
                  propertyValueRanges.MAX,
                  () =>
                    "El valor ingresado parece muy elevado. Por favor verifica que sea correcto."
                )
              ),
          }),
          mortgage_initial: yup.string().when("mortgage.mode", {
            is() {
              return shouldValidateByMode;
            },
            then(schema) {
              let minMax: ReturnType<typeof getMortgageInitialRange>;
              let min: number;
              return requiredStringSchema("Ingrese un valor")
                .test(aboveZeroTest)
                .test(
                  "lt50k",
                  (val) => {
                    return `El importe de hipoteca mínimo a solicitar es de: ${formatCurrency(
                      // minMax.minAmount
                      min
                    )}.`;
                  },
                  (testVal, Context) => {
                    const { parent } = Context as {
                      parent: MortgageFormData;
                    };
                    const propertyValue = parent.property.value;
                    minMax = getMortgageInitialRange(parent);
                    const { minAmount, maxAmount } = minMax;
                    min = Number(propertyValue) - maxAmount;
                    if (parent.mortgage.mode === MortgageMode.NEW_MORTGAGE) {
                      return (
                        // Number(Context.parent.mortgage_initial) >= minAmount
                        Number(Context.parent.mortgage_initial) >= min
                      );
                    }
                    return true;
                  }
                );
            },
          }),
          data: yup.object().shape({
            interest_rate: requiredStringSchema()
              .test(
                "isValidNumberWithCommaOrDot",
                "El valor debe ser numérico y puede contener un punto o una coma.",
                isValidNumberWithCommaOrDot
              )
              .test(
                "positiveValue",
                "El valor debe ser mayor que 0.",
                isGreaterThan(0)
              )
              .test(
                "greaterThan",
                "El valor ingresado parece muy elevado. Por favor verifica que sea correcto.",
                isLessThan(maxInterestRate)
              )
              .test(
                "lessThan",
                "El valor ingresado parece muy bajo. Por favor verifica que sea correcto.",
                isGreaterThan(minInterestRate)
              ),
            pending_amount: yup.string().when("dummy", {
              is() {
                return !shouldValidateByMode;
              },
              then(schema) {
                const minPendingAmount =
                  mortgageMode === MortgageMode.REMORTGAGE
                    ? PENDING_AMOUNT_RANGES.REMORTGAGE.MIN
                    : PENDING_AMOUNT_RANGES.CAPITAL_RAISE.MIN;
                return schema
                  .required("Ingrese un valor")
                  .nullable()
                  .test(
                    lessThan(minPendingAmount, () => {
                      return `Lamentablemente, los bancos no suelen financiar hipotecas por montos inferiores a ${formatLocalCurrency(
                        minPendingAmount
                      )}`;
                    })
                  )
                  .test(aboveZeroTest)
                  .test(amountLTPropertyValueTest);
              },
            }),
            interest_type_remortgage: yup.string().when("dummy", {
              is() {
                return !shouldValidateByMode;
              },
              then(schema) {
                return schema.required(REQ_MSG).nullable();
              },
            }),
            curr_mortgage_init_date: requiredStringSchema().test(
              "mortage_sign",
              () => {
                return `La firma supera los años estimados`;
              },
              function (val) {
                const currentDate = new Date();
                const maxDateAllowed = subYears(currentDate, 40);
                if (!val) return false;
                const inputDate = parseISO(val);
                return isAfter(inputDate, maxDateAllowed);
              }
            ),
            mortgage_rate: requiredStringSchema().test(
              "allowed",
              () => {
                return `El importe supera el porcentaje máximo permitido`;
              },
              function (val) {
                const { from } = this as yup.TestContext & TestContextExtended;
                const { value } = from[1];
                const isFuncionario = isFormAFuncionario(value);
                if (isFuncionario) {
                  return Number(val) <= 100;
                }
                const mortgageMode = value.mortgage.mode;
                if (mortgageMode === MortgageMode.CAPITAL_RAISE) {
                  return Number(val) <= 100;
                }
                if (mortgageMode === MortgageMode.REMORTGAGE) {
                  return Number(val) <= 90;
                }
                const propertyType = value.property.type;
                return (
                  Number(val) <= getMortgageRatePercentageAllowed(propertyType)
                );
              }
            ),
            curr_original_amount: yup.string().when("mortgage.mode", {
              is() {
                return mortgageMode === 2;
              },
              then(schema) {
                return schema
                  .required("Ingrese un valor")
                  .nullable()
                  .test(
                    greaterThan(
                      curr_original_amount.MAX,
                      () =>
                        "El valor ingresado parece muy elevado. Por favor verifica que sea correcto."
                    )
                  )
                  .test(
                    lessThan(
                      curr_original_amount.MIN,
                      () =>
                        "El valor ingresado parece muy bajo. Por favor verifica que sea correcto."
                    )
                  )
                  .test(
                    "curr_amount",
                    (val) => {
                      return `El importe de la hipoteca original tiene que ser mayor al monto pendiente`;
                    },
                    (testVal, Context) => {
                      const { parent } = Context as {
                        parent: MortgageFormData["data"];
                      };

                      const pendingAmount = parent.pending_amount;

                      if (pendingAmount && testVal) {
                        return (
                          parseFloat(testVal) >
                          parseFloat(pendingAmount.toString())
                        );
                      }
                      return false;
                    }
                  );
              },
            }),
          }),
          current_bank: yup.string().when("mortgage.mode", {
            is: () => {
              return shouldValidateByMode;
              // return Number(val) === 1;
            },
            then(schema) {
              return schema.nullable();
            },
            otherwise(schema) {
              return schema.required(REQ_MSG).nullable();
            },
          }),
        })
      )}
    >
      <CurrMortgageDataFormHandler />
    </StepLayout>
  );
}

function CurrMortgageDataFormHandler() {
  const { formState, getValues, watch, setValue } =
    useFormContext<MortgageFormData>();
  const mortgageMode = getValues("mortgage.mode");
  const [
    dataAmount,
    dataPendingAmount,
    propertyValue,
    mortgageInitial,
    currMortgageInitDate,
  ] = watch([
    "data.amount",
    "data.pending_amount",
    "property.value",
    "mortgage_initial",
    "data.curr_mortgage_init_date",
  ]);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const direction: StackProps["direction"] = isSmallScreen
    ? "column-reverse"
    : "row";
  const mortgageRate = getMortgageRate({
    mortgageMode,
    numerator: dataAmount,
    propertyValue,
    mortgageInitial,
  });
  useEffect(() => {
    if (
      currMortgageInitDate &&
      isDate(new Date(currMortgageInitDate)) &&
      differenceInYears(new Date(), new Date(currMortgageInitDate)) <= 2
    ) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text: "Actualmente, los bancos colaboradores exigen una antigüedad mínima para cambiar o mejorar las condiciones de tu hipoteca superior a 2 años. Si hace menos de 2 años que firmaste o modificaste tu hipoteca, deberás esperar para solicitar una mejora de tus condiciones.",
      });
      //validate is more than 2 year
    }
  }, [currMortgageInitDate]);
  useEffect(() => {
    if ([2, 3].includes(mortgageMode)) {
      setValue("data.amount", dataPendingAmount, {
        shouldValidate: true,
      });
    }
    if (propertyValue) {
      setValue("data.mortgage_rate", mortgageRate, {
        shouldValidate: Boolean(mortgageInitial),
      });
    }
  }, [dataPendingAmount, propertyValue, mortgageRate]);

  return (
    <Stack
      id="curr-mortgage-data-handler"
      direction={direction}
      alignItems="center"
      margin="auto"
    >
      <Form />
      {mortgageMode === 2 && (
        <CountersDisplayCard
          boxProps={{
            sx: { backgroundColor: "transparent", border: "none" },
          }}
          counterDisplayListProps={[
            {
              errMsg: formState.errors.data?.mortgage_rate?.message,
              count: `${mortgageRate} %`,
              helperText:
                "Aunque las entidades bancarias estudian cada caso de forma independiente, generalmente el importe máximo a financiar para una vivienda habitual es entre el 80 al 90%",
              label: "Porcentaje de Hipoteca",
            },
          ]}
        />
      )}
    </Stack>
  );
}

function Form() {
  const { banks } = useBanks();
  const { watch, getValues } = useFormContext<MortgageFormData>();
  const [remortgageInterestType] = watch(["data.interest_type_remortgage"]);
  const isRemortgageInterestFijo = remortgageInterestType === "fijo";
  const mortage_mode = getValues("mortgage.mode");
  const isReMortagage = mortage_mode === MortgageMode.REMORTGAGE;

  return (
    <Stack id="form" spacing={2}>
      <MortgageDataRow
        label="Valor actual de la propiedad"
        renderInput={
          <FormFields.CurrencyFieldWithWrapperText
            text="Incluya el valor de mercado actual estimado de la propiedad. Este valor deberá estar respaldado por una tasación para finalizar la firma de la hipoteca. Si el valor de la propiedad según la tasadora es inferior al dato aportado, su oferta de hipoteca podría variar, o no ser aceptada por el banco."
            name="property.value"
            // maxInput={PROPERTY_VALUE_RANGES.GLOBAL.MAX}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        }
      />
      <MortgageDataRow
        label="Importe pendiente de la hipoteca"
        renderInput={
          <FormFields.CurrencyFieldWithWrapperText
            text="Por favor agregar el importe restante de tu hipoteca actual."
            name="data.pending_amount"
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        }
      />
      {mortage_mode === 2 && (
        <MortgageDataRow
          label="Importe total de la hipoteca original"
          renderInput={
            <FormFields.CurrencyFieldWithWrapperText
              text="Por favor agregar el importe total de la hipoteca actual."
              name="data.curr_original_amount"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          }
        />
      )}
      <MortgageDataRow
        label="Tipo de Interés Existente"
        name="data.interest_type_remortgage"
        renderInput={
          <FormFields.ListSelector<MortgageFormData>
            name="data.interest_type_remortgage"
            options={INTEREST_TYPE_REMORTGAGE_OPTIONS}
            stackProps={{ direction: "row", sx: { "&>*": { flex: 1 } } }}
          />
        }
      />
      <MortgageDataRow
        label="Tasa de interés existente"
        helperText={
          !isRemortgageInterestFijo
            ? "Por favor, ingresa el valor total de la tasa (diferencial + Euribor). Es la tasa que pagas mes a mes"
            : ""
        }
        renderInput={
          <FormFields.TextFieldInput
            name="data.interest_rate"
            inputProps={{
              step: "0.01",
            }}
            type="text"
            placeholder="Ejemplo: 3,4%"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TrendingUpIcon />
                </InputAdornment>
              ),
            }}
          />
        }
      />
      <MortgageDataRow
        label="Plazo pendiente de hipoteca"
        renderInput={
          <FormFields.TextFieldInput
            name="data.remortgage_laps_years"
            type="number"
            inputProps={{
              min: 0,
              max: 35,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Años</InputAdornment>
              ),
            }}
          />
        }
      />
      <MortgageDataRow
        label="Mes y año de firma de hipoteca original o de último cambio de hipoteca"
        renderInput={
          <FormFields.DatePicker
            inputFormat="MMM yyyy"
            views={[ "year", "month"]}
            name="data.curr_mortgage_init_date"
            disableFuture
          />
        }
      />
      <MortgageDataRow
        label="Banco actual"
        renderInput={
          <FormFields.AutoCompleteInput
            name="current_bank"
            options={banks.map((b: { name: string }) => b.name)}
            disablePortal
            id="bank-box"
          />
        }
      />
    </Stack>
  );
}

export default CurrentMortgageDataForm;
