export interface NavItems {
  label: string;
  path: string | ((id: string) => string);
  iconUrl: string;
  can_create_mortgage?: boolean;
}

/**
 * A collection of navigation items for the app.
 *
 * @description This object represents a collection of navigation items that appear in the app's menu or navigation bar.
 * Each item includes a label, path, and icon URL.
 */
export const navItems = {
  myDocument: {
    label: "Mis documentos",
    path: "/client/profile/documents",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fdocument-unscreen.gif",
  },
  principalPanel: {
    label: "Panel principal",
    path: "/client/home",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fpresentation-rm-bg.gif",
  },
  /**new mortgage */
  newApplication: {
    can_create_mortgage: true,
    label: "Nueva solicitud",
    path: "/client/mortgage/create?step=0",
    iconUrl:
      "https://colibid.com/wp-content/uploads/2023/03/49-plus-circle-outline-1.gif",
  },
  myProfile: {
    label: "Mi perfil",
    path: "/client/profile",
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fuser-unscreen.gif",
  },
  /**list of mortgages */
  myApplications: {
    label: "Mis solicitudes",
    path: "/client/mortgages",
    iconUrl:
      "https://colibid.com/wp-content/uploads/2023/03/187-suitcase-outline.gif",
    // sistema-solar
  },
  myMortgage: 
  {
    can_create_mortgage: false,
    label: "Mi hipoteca",
    path: (id: string) => `/client/mortgage/${id}`,
    iconUrl:
      "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fhome-unscreen.gif",
  },
};
