import React, { FunctionComponent, ReactComponentElement } from "react";

import MainClientLayout from "./MainClientLayout";
import MainBrokerLayout from "./MainBrokerLayout";
import NotAuthLayout from "./NotAuthLayout";
import MainRealEstateLayout from "./MainRealEstateLayout";
import MainInfluencerLayout from "./MainInfluencerLayout";
import Center from './Center'

type IComponents = {
  [key: string]: React.FunctionComponent<any>;
};

const components: IComponents = {
  client: MainClientLayout,
  broker: MainBrokerLayout,
  auth: NotAuthLayout,
  realstate: MainRealEstateLayout,
  influencer: MainInfluencerLayout,
};

export interface IDinamycLayoutProps {
  type: string;
}
const DinamycLayout: FunctionComponent<IDinamycLayoutProps> = (
  props
): ReactComponentElement<any> => {
  const Layout = components[props.type];
  return <Layout {...props} />;
};

export {
  MainClientLayout,
  NotAuthLayout,
  MainBrokerLayout,
  MainRealEstateLayout,
  MainInfluencerLayout,
  DinamycLayout,
  Center
};
