import { Scoring } from "pages/RealState/Prescoring/Scoring";
import {
  BaseApiSuccessRes,
  MortagageRealStateAgencies,
  MortgageRes,
} from "shared/types/BaseApiResponse";
import {
  MortgageData,
  PostMortgageData,
  User,
} from "shared/types/postMortgageData";
import axios from "../../utils/axios";

export interface addContact {
  first_name: string;
  last_name: string;
  preferred_time: string;
  phone: string;
  email: string;
}
export const agentAddContact = (user: addContact) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/agencies/agents/contact/create`,
    user
  );
};

export const agentCalculateScoring = (data: PostMortgageData) => {
  return axios.post<BaseApiSuccessRes<MortagageRealStateAgencies>>(
    `${process.env.REACT_APP_API_URL}/agencies/scoring`,
    data
  );
};

export const agentAplyMortgage = (id: string, data: PostMortgageData) => {
  return axios.post<BaseApiSuccessRes<MortagageRealStateAgencies>>(
    `${process.env.REACT_APP_API_URL}/agencies/scoring/${id}/activate`,
    data
  );
};
interface GetListClientParams {
  itemsPerPage: number;
  page: number;
}
export const getListClient = (params: GetListClientParams) => {
  const { itemsPerPage, page } = params;
  return axios.get<BaseApiSuccessRes<Array<MortagageRealStateAgencies>>>(
    `${process.env.REACT_APP_API_URL}/agencies/agents/contact/list`,
    {
      params: {
        ipp: itemsPerPage,
        p: page,
      },
    }
  );
};

export const agentPutPaymentMethod = (data: object | undefined) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/agencies/agents/payments/method`,
    data
  );
};

interface contract {
  business_name: string;
  nif: string;
}
export const agentSignContract = (data: contract) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/agencies/agents/contract`,
    data
  );
};

export const getAgentDocuments = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/agencies/agents/documents`
  );
};

export const getAgencyPaymentMethods = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/agencies/agents/payments/method`
  );
};

export const getAgentContractColibid = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/settings/REAL_STATES_AGENTS_CONTRACT`
  );
};
