import { AxiosResponse } from "axios";
import { DocType, DocTypesRes } from "shared/types/DocType";
import axios from "../../utils/axios";
import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";

type DocTypeMortgageMode = number | undefined;
interface DocTypesParams {
  mortgage_mode?: number;
}
// should we install another lib to handle fetch cache?
// or add a global context combine with the useDocument hook level 🤔
const docTypeCached: {
  doctypes?: Promise<AxiosResponse<DocTypesRes, any>>;
  expired?: any;
} = {};

const getDoctypes = (mortgage_mode: DocTypeMortgageMode = undefined) => {
  const params: DocTypesParams = {};
  if (mortgage_mode) {
    params.mortgage_mode = mortgage_mode;
  }
  if (
    !docTypeCached.expired ||
    !docTypeCached.doctypes ||
    docTypeCached?.expired <= new Date()
  ) {
    docTypeCached.doctypes = axios.get<DocTypesRes>(
      `${process.env.REACT_APP_API_URL}/doctypes`,
      {
        params,
      }
    );
    const today = new Date();
    docTypeCached.expired = new Date().setDate(today.getDate() + 1);
    return docTypeCached.doctypes;
  }
  if (docTypeCached.doctypes) return docTypeCached.doctypes;
  return axios.get<DocTypesRes>(`${process.env.REACT_APP_API_URL}/doctypes`, {
    params,
  });
};

export interface Tasadora {
  id: string;
  name: string;
  logo: string;
  is_active: boolean;
  created_at: string;
  deleted_at: any;
  updated_at: any;
}

const getActiveAppraisers = (bid_id: string) => {
  return axios.get<BaseApiSuccessRes<Array<Tasadora>>>(
    `${process.env.REACT_APP_API_URL}/appraisers/`,
    {
      params: {
        mortgage_id: bid_id,
      },
    }
  );
};

export interface AppraiserStatus {
  id: string;
  status: string;
  observations: string;
  mortgage: string;
  appraiser: Appraiser;
}

export interface Appraiser {
  id: string;
  name: string;
  logo: string;
  is_active: boolean;
  created_at: string;
  deleted_at: string;
  updated_at: string;
}
/* 
          pending: enviado la tasacion
          processing: enviado la tasacion pero en espera
          succeeded: ya listo
          failed: "falla en el proceso"
          payment_declined: "error en el pago"
         */

const getStatusAppraisers = (mortgageId: string) => {
  return axios.get<BaseApiSuccessRes<AppraiserStatus>>(
    `${process.env.REACT_APP_API_URL}/appraisers/process/active`,
    {
      params: {
        mortgage_id: mortgageId,
      },
    }
  );
};

interface createAppraiser {
  mortgage_id: string;
  appraiser_id: string;
}

const createAppraiserProcess = (data: createAppraiser) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/appraisers/create`, {
    mortgage_id: data.mortgage_id,
    appraiser_id: data.appraiser_id,
  });
};
//Get Files Appraiser Process
export interface file {
  file_descriptor: string;
  file_url: string;
  created: string;
  uploader: string;
  downloadable: boolean;
  cansee: boolean;
}

const getFileAppraiser = (id: string) => {
  return axios.get<BaseApiSuccessRes<file>>(
    `${process.env.REACT_APP_API_URL}/appraisers/process/${id}/files`
  );
};

export {
  getDoctypes,
  getActiveAppraisers,
  createAppraiserProcess,
  getStatusAppraisers,
  getFileAppraiser,
};
