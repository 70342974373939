import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Avatar,
  CardContent,
  Card,
  Box,
  Button,
  OutlinedInput,
  InputAdornment,
  Divider,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  TableContainer,
  Dialog,
  TextField,
} from "@mui/material";
import QRCode from "react-qr-code";
import { MobileView } from "react-device-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CodeIcon from "@mui/icons-material/Code";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import DownloadIcon from "@mui/icons-material/Download";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";

import { DinamycLayout } from "../../../shared/layouts";
import { PageTitle, Breadcrumbs, BannerInfo } from "../../../shared/components";
import { isMobile } from "react-device-detect";
import { useAuthenticated, useProfile } from "../../../shared/hooks";
import { useLocalStorage } from "../../../shared/hooks";
import { useLocation } from "react-router-dom";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import {
  getReferrals,
  getPaymentMethod,
  putPaymentMethod,
} from "../../../services/api";

import styles from "./styles.module.scss";
import { BankDetails } from "../ReferralPay";

const ReferralLink: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const location = useLocation();
  const [role] = useLocalStorage("role");
  const profile = useProfile().profile;
  const [shareURL, setShareURL] = useState("");
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [shareMessage, setShareMessage] = useState("Registrate en Colibid");
  const [shareiFrame, setShareiFrame] = useState("");
  const [referral, setReferral] = useState({
    created_mortgage: 0,
    name: "",
    referral: "",
    registered: 0,
    successful_mortgage: 0,
    paid_mortgage: 0,
  });
  const [paymentData, setPaymentData] = useState({
    BIC_SWIFT: "",
    IBAN: "",
    account_holder_name: "",
    identification: "",
    user: "",
  });

  // useAuthenticated((allow) => {
  //   if (!allow) navigate("/sign-in");
  // }, "realestate");

  useEffect(() => {
    getReferralData();
  }, []);

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  useEffect(() => {
    setShareURL(`${process.env.REACT_APP_WEB_URL}?r=${referral.referral}`);
    setShareiFrame(
      `<iframe src="${process.env.REACT_APP_API_URL}/embed?r=${
        referral.referral
      }" scrolling="no" width="100%" height="${
        isMobile ? "75" : "130"
      }" frameborder="0"></iframe>`
    );
  }, [referral.referral]);

  const getReferralData = () => {
    getReferrals()
      .then((response: any) => {
        setReferral(response.data);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const getPaymentMethodData = () => {
    getPaymentMethod()
      .then((response: any) => {
        setPaymentData(response.data);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const putPaymentMethodData = (data: object) => {
    putPaymentMethod(data)
      .then((response: any) => {
        getPaymentMethodData();
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const onDownloadQR = () => {
    const svg = document.getElementById("QRCode") as HTMLImageElement;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <DinamycLayout type={role}>
      <Container className={styles.ReferralsWrapper}>
        <Grid container spacing={2}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
              <Breadcrumbs
                parents={[
                  {
                    href: "/referrals",
                    title: "Mis referidos",
                  },
                ]}
              >
                <Typography color="text.gray">Compartir enlace</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <BannerInfo img="/assets/img/sources/referal_share_link.svg">
            <p>
              Para recomendar Colibid a tus amigos y conocidos, solo necesitas
              compartir cualquiera de estos enlaces. Todos ellos son únicos para
              ti y ¡no existen dos iguales!
              <br />
              <br /> ¡Pero recuerda! Es importante que tus conocidos se
              registren a través de tus enlaces. Si no, no podremos contabilizar
              tus recompensas.
            </p>
          </BannerInfo>

          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <PageTitle
                title="Elige como recomendar Colibid:"
                textAlign={isMobile ? "center" : "left"}
              />
            </Grid>
          </Grid>
          <Divider component="br" />

          <Grid
            container
            spacing={2}
            sx={{ marginBottom: { md: "4rem" }, marginTop: "1rem" }}
          >
            <Grid item md={5} sm={12} xs={12}>
              <Box className={styles.ShareBox}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: ".5rem" }}
                >
                  Compartir en Redes Sociales
                </Typography>

                <Fab
                  size="medium"
                  className={styles.WhatsappButton}
                  target="_blank"
                  component="a"
                  href={`https://api.whatsapp.com/send/?phone&text=${shareURL}`}
                >
                  <WhatsAppIcon />
                </Fab>

                <Fab
                  size="medium"
                  className={styles.FacebookButton}
                  target="_blank"
                  component="a"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}&t=${shareMessage}`}
                >
                  <FacebookIcon />
                </Fab>

                <Fab
                  size="medium"
                  className={styles.TwitterButton}
                  target="_blank"
                  component="a"
                  href={`https://twitter.com/intent/tweet?url=${shareURL}&text=${shareMessage}`}
                >
                  <TwitterIcon />
                </Fab>

                <MobileView style={{ display: "inline-block" }}>
                  <Fab
                    size="medium"
                    className={styles.LinkedinButton}
                    target="_blank"
                    component="a"
                    href={`https://www.linkedin.com/shareArticle?url=${shareURL}&title=${shareMessage}`}
                  >
                    <LinkedInIcon />
                  </Fab>
                </MobileView>

                <Fab
                  size="medium"
                  className={styles.TelegramButton}
                  target="_blank"
                  component="a"
                  href={`https://t.me/share/url?url=${shareURL}&text=${shareMessage}`}
                >
                  <TelegramIcon />
                </Fab>

                {/* <Fab
                  size="medium"
                  className={styles.Instagram}
                  target="_blank"
                  component="a"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}&t=${shareMessage}}`}
                >
                  <InstagramIcon />
                </Fab> */}

                <Fab
                  size="medium"
                  className={styles.MailButton}
                  target="_blank"
                  component="a"
                  href={`mailto:?body=${shareURL}&subject=${shareMessage}`}
                >
                  <EmailIcon />
                </Fab>
              </Box>
            </Grid>

            <Grid item md={1} sm={12} xs={12}>
              <Divider orientation="vertical" />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Box className={styles.ShareTools}>
                <Box className={styles.CodeBox}>
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: ".5rem" }}
                    align="center"
                  >
                    Comparte este enlace de referencia con tus amigos y
                    seguidores
                  </Typography>

                  <FormControl fullWidth disabled variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={shareURL}
                      endAdornment={
                        <InputAdornment position="end">
                          <CopyToClipboard
                            text={shareURL}
                            onCopy={() => {
                              toast.success("Enlace copiado correctamente");
                            }}
                          >
                            <Button variant="text">COPIAR</Button>
                          </CopyToClipboard>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={5} sm={12} xs={12}>
              <Box className={styles.ShareBox}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: ".5rem" }}
                >
                  Descarga o Escanea
                </Typography>
                <QRCode value={shareURL} id="QRCode" />
                <Fab
                  id="Qrcodereferaldownload"
                  size="medium"
                  className={styles.DownloadButton}
                  onClick={() => onDownloadQR()}
                >
                  <DownloadIcon />
                </Fab>
              </Box>
              <Divider orientation="vertical" flexItem />
            </Grid>

            <Grid item md={1} sm={12} xs={12}>
              <Divider orientation="vertical" />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Box className={styles.ShareBox}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: ".5rem" }}
                >
                  Integrar en tu Página Web
                </Typography>

                <Box className={styles.iFrameBox}>
                  <CopyToClipboard
                    text={shareiFrame}
                    onCopy={() => {
                      toast.success("Código copiado correctamente");
                    }}
                  >
                    <Fab
                      id="shareCodeColibid"
                      size="medium"
                      className={styles.WhiteButton}
                    >
                      <CodeIcon />
                    </Fab>
                  </CopyToClipboard>

                  <Box dangerouslySetInnerHTML={{ __html: shareiFrame }}></Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <BankDetails
          validateDNI={true}
          show={showBankDetailsModal}
          data={paymentData}
          onSave={(data) => {
            putPaymentMethodData(data);
            setShowBankDetailsModal(false);
            toast.success(
              "Tus datos bancarios se han registrado correctamente."
            );
          }}
          onClose={() => {
            setShowBankDetailsModal(false);
          }}
        />
      </Container>
    </DinamycLayout>
  );
};

/* export interface IBankDetailsProps {
  show: boolean;
  data: any;
  onSave: (data: any) => void;
  onClose: () => void;
}
const BankDetails: FunctionComponent<IBankDetailsProps> = (
  props
): ReactComponentElement<any> => {
  const [localData, setLocalData] = useState(props.data);

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  return (
    <Dialog
      open={props.show}
      onClose={() => props.onClose()}
      maxWidth="sm"
      fullWidth={true}
    >
      <Box className={styles.DialogDetails}>
        <Container
          sx={{
            padding: {
              sm: ".8rem",
            },
          }}
        >
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: ".7rem" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.onSave(localData);
                }}
              >
                <Typography variant="h6">Mis Datos Bancarios</Typography>
                <Divider />
                <Divider component="br" />
                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="
                    Nombre del titular de la cuenta"
                  value={localData.account_holder_name}
                  onChange={(e: any) => {
                    localData.account_holder_name = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="
                    DNI"
                  value={localData.identification}
                  onChange={(e: any) => {
                    localData.identification = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="
                  IBAN"
                  value={localData.IBAN}
                  onChange={(e: any) => {
                    localData.IBAN = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="BIC/SWIFT"
                  value={localData.BIC_SWIFT}
                  onChange={(e: any) => {
                    localData.BIC_SWIFT = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ marginTop: ".7rem" }}
                >
                  Guardar
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
}; */

const StatementsList = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Referencia</TableCell>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Descripción</TableCell>
            <TableCell align="center">Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralLink;
