import {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Button,
  Container,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate, useLocation } from "react-router-dom";

import { Center, MainBrokerLayout } from "../../../shared/layouts";
import { PageTitle, PaginationBar } from "../../../shared/components";
import { toast } from "react-toastify";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";
import { getCrmData, getCrmOptionsStates } from "../../../services/api";
import {
  useAuthenticated,
  useLocalStorage,
  useProfile,
  useLocalStorages,
} from "../../../shared/hooks";
import styles from "./styles.module.scss";
import DataGridTabs from "shared/components/DataGridTable";
import {
  BidderMetrics,
  CRMTable,
  CrmFields,
  BidderEvaluationOption,
  CRMFlashyCells,
  CrmTabState,
  CrmFunnel,
} from "shared/types/Crm";
import SearchInput from "shared/components/SearchInput";
import {
  crmPaginationFactory,
  getModeByTab,
  PAGINATION_INITIAL_VALUE,
  getAlertDataCells,
} from "./utils";
import {
  CRM_CLIENT_PAID_PAGINATION,
  CRM_COLLAPSE_PAGINATION,
  CRM_NOT_COLLAPSE_PAGINATION,
  CRM_TAB_STATE,
  ROLE,
} from "shared/constants/localStorage";
import {
  getCrmFunnels,
  getCrmHeaderFields,
  getCrmMetrics,
} from "services/api/crm.service";
import logger from "utils/logger";
import MemoBidderMetricCardList from "./components/BidderMetricCardList";
import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import { exportCrm } from "services/api/fileDownload.services";

const { COLLAPSE, NO_COLLAPSE, PAID } = CrmTabState;

// when i start writting this code only me and god know it work
// when i finish only god know how it work 🙈
const Clients: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();
  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["broker", "bank"]
  );

  const [role] = useLocalStorage(ROLE);
  const location = useLocation();
  const profile = useProfile().profile;
  const [notCollapsedPagination, setNotCollapsedPagination] = useLocalStorages(
    CRM_NOT_COLLAPSE_PAGINATION,
    PAGINATION_INITIAL_VALUE
  );
  const [collapsedPagination, setCollapsedPagination] = useLocalStorages(
    CRM_COLLAPSE_PAGINATION,
    PAGINATION_INITIAL_VALUE
  );
  const [clientPaidPagination, setClientPaidPagination] = useLocalStorages(
    CRM_CLIENT_PAID_PAGINATION,
    PAGINATION_INITIAL_VALUE
  );
  // const tabState = useState<CrmTabState>(0);
  const tabState = useLocalStorages(CRM_TAB_STATE, 0);

  const [headerFields, setHeaderFields] = useState<CrmFields[]>();

  const [notCollapsedData, setNotCollapsedData] = useState<CRMTable[]>();
  const [collapsedData, setCollapsedData] = useState<CRMTable[]>();
  const [clientPaidData, setClientPaidData] = useState<CRMTable[]>();
  const [flashyCellsData, setFlashyCellsData] = useState<CRMFlashyCells>();

  const [bidderMetrics, setBidderMetrics] = useState<
    BidderMetrics | undefined
  >();
  const [funnels, setFunnels] = useState<CrmFunnel>();

  const [search, setSearch] = useState("");
  const [optionsValue, setOptionsValue] = useState<BidderEvaluationOption[]>();
  const [isFetchingData, setIsFetchingData] = useState(true);

  const getPaginationByTab = (v: number) => {
    if (v === NO_COLLAPSE) {
      return notCollapsedPagination;
    }
    if (v === COLLAPSE) {
      return collapsedPagination;
    }
    if (v === PAID) {
      return clientPaidPagination;
    }
  };

  const crmDataHandler = ({
    mode,
    data,
  }: {
    mode?: "dead" | "paid";
    data?: BaseApiSuccessRes<CRMTable[]>;
  }) => {
    if (data) {
      const pagination = crmPaginationFactory(data.pagination);
      // parse data from back so it has the structure needed for the aggrid table
      // plus get the flashy cell in the same loop
      const flashyCells = getAlertDataCells({
        data: data.results,
        shouldGetFlashyData: !Boolean(mode),
        headerFields: headerFields || [],
      });
      if (mode === "dead") {
        setCollapsedData(data.results);
        setCollapsedPagination(pagination);
        return;
      }
      if (mode === "paid") {
        setClientPaidData(data.results);
        setClientPaidPagination(pagination);
        return;
      }
      setFlashyCellsData(flashyCells);
      setNotCollapsedData(data.results);
      setNotCollapsedPagination(pagination);
    } else {
      if (mode === "dead") {
        setCollapsedData([]);
        return;
      }
      if (mode == "paid") {
        setClientPaidData([]);
        return;
      }
      setNotCollapsedData([]);
    }
  };

  useEffect(() => {
    // this effect is to get the data when mounted
    if (!optionsValue && !bidderMetrics && !funnels && !headerFields) {
      setIsFetchingData(true);
      Promise.all([
        getCrmOptionsStates(),
        getCrmHeaderFields(),
        getCrmMetrics(),
        getCrmFunnels(getModeByTab(tabState[0])),
      ])
        .then(([optionsState, headers, metric, funnels]) => {
          setOptionsValue(optionsState.data.results);
          setBidderMetrics(metric.data.results);
          setHeaderFields(headers.data.results);
          setFunnels(funnels.data.results);
        })
        .catch((e) => {
          logger.error(e);
        });
    }
    if (headerFields) {
      const pagination = getPaginationByTab(tabState[0]);
      getCrm({ pagination });
    }
  }, [headerFields]);

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  const changeTabsValue = useCallback((e, v) => {
    tabState[1](v);
    clearSearchInput();
    const pagination = getPaginationByTab(v);
    getCrm({ m: v, pagination });
    getCrmFunnels(getModeByTab(v)).then((funnels) => {
      setFunnels(funnels.data.results);
    });
  }, []);

  const getCrm = ({
    m,
    pagination,
    q,
  }: {
    m?: number;
    pagination: any;
    q?: string;
  }) => {
    const mode = getModeByTab(m === undefined ? tabState[0] : m);
    setIsFetchingData(true);
    getCrmData({
      mode,
      p: pagination.page,
      ipp: pagination.limit,
      q,
    })
      .then((res) => {
        crmDataHandler({ mode, data: res.data });
      })
      .catch((e: any) => {
        crmDataHandler({ mode });
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const onSave = () => {
    const pagination = getPaginationByTab(tabState[0]);
    getCrm({ pagination, q: search });
    getCrmMetrics().then((res) => {
      setBidderMetrics(res.data.results);
    });
    getCrmFunnels(getModeByTab(tabState[0])).then((funnels) => {
      setFunnels(funnels.data.results);
    });
  };

  const onSearchFilter = (q: string) => {
    const pagination = getPaginationByTab(tabState[0]);
    getCrm({ pagination, q });
  };

  const clearSearchInput = (fetch?: boolean) => {
    setSearch("");
    if (fetch) {
      onSearchFilter("");
    }
  };

  const paginationOnChange = (p: number) => {
    const pagination = getPaginationByTab(tabState[0]);
    pagination.page = p;
    getCrm({ pagination });
  };

  const paginationOnChangeLimit = (l: string) => {
    const pagination = getPaginationByTab(tabState[0]);
    pagination.limit = l;
    pagination.page = 1;
    // 0 no collapse, 1 - is collapse
    getCrm({ pagination });
  };

  return (
    <MainBrokerLayout>
      <Container maxWidth="lg" className={styles.ClientsWrapper}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <PageTitle title="Clientes adquiridos" />
          </Grid>

          <MemoBidderMetricCardList bidderMetrics={bidderMetrics} />

          <Grid container item md={12} sm={12} xs={12} spacing={2}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              container
              spacing={2}
              justifyContent={"space-between"}
            >
              <Grid item md={12} sm={12} xs={8}>
                <SearchInput
                  searchState={[search, setSearch]}
                  onSearchCb={onSearchFilter}
                  onClearInputCb={clearSearchInput}
                  disabled={isFetchingData}
                />
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12} spacing={2}>
              {tabState[0] === NO_COLLAPSE && notCollapsedData?.length! > 0 && (
                <>
                  {notCollapsedData?.length! > 0 ? (
                    <Tooltip title="Descargar información del cliente">
                      <Button
                        variant="contained"
                        className={styles.buttonDownload}
                        size="small"
                        onClick={() => {
                          const mode = getModeByTab(tabState[0]);
                          exportCrm({ mode }).catch(console.log);
                        }}
                      >
                        <FileDownloadIcon className={styles.iconDownload} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <>
                      <Skeleton variant="rectangular" width={40} height={40} />
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <DataGridTabs
                onSave={onSave}
                isFetchingData={isFetchingData}
                funnels={funnels}
                headerFields={headerFields}
                optionsValue={optionsValue}
                collapsedDataState={[collapsedData, setCollapsedData]}
                notCollapseDataState={[notCollapsedData, setNotCollapsedData]}
                clientPaidDataState={[clientPaidData, setClientPaidData]}
                flashyCellsDataState={[flashyCellsData, setFlashyCellsData]}
                tabValue={tabState[0]}
                changeTabsValue={changeTabsValue}
              />
              {notCollapsedData?.length! > 0 &&
                tabState[0] === NO_COLLAPSE &&
                notCollapsedPagination.pages > 0 && (
                  <PaginationBar
                    count={notCollapsedPagination["count"]}
                    rowsPerPage={notCollapsedPagination["limit"]}
                    page={notCollapsedPagination["page"]}
                    pages={notCollapsedPagination["pages"]}
                    onChange={paginationOnChange}
                    onChangeLimit={paginationOnChangeLimit}
                  />
                )}
              {collapsedData?.length! > 0 &&
                tabState[0] === COLLAPSE &&
                collapsedPagination.pages > 0 && (
                  <PaginationBar
                    count={collapsedPagination["count"]}
                    rowsPerPage={collapsedPagination["limit"]}
                    page={collapsedPagination["page"]}
                    pages={collapsedPagination["pages"]}
                    onChange={paginationOnChange}
                    onChangeLimit={paginationOnChangeLimit}
                  />
                )}
              {clientPaidData?.length! > 0 &&
                tabState[0] === PAID &&
                clientPaidPagination.pages > 0 && (
                  <PaginationBar
                    count={clientPaidPagination["count"]}
                    rowsPerPage={clientPaidPagination["limit"]}
                    page={clientPaidPagination["page"]}
                    pages={clientPaidPagination["pages"]}
                    onChange={paginationOnChange}
                    onChangeLimit={paginationOnChangeLimit}
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainBrokerLayout>
  );
};

export default Clients;
