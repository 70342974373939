import React, {
  memo,
  forwardRef,
  useRef,
  useState,
  SyntheticEvent,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react";

import {
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";

import { ICellEditorParams } from "ag-grid-community";

import {
  CRMTableActionsFields,
  BidderEvaluationOption,
} from "shared/types/Crm";

export default memo(
  forwardRef(
    (
      props: ICellEditorParams<any, CRMTableActionsFields> & {
        optionsValue: BidderEvaluationOption[];
        closeSuccessFullies: string[];
        fileCols: (string | number)[];
        dateCols: (string | number)[];
      },
      ref
    ) => {
      const { optionsValue } = props;
      const { is_frozen } = props.value;
      const colId = props.column.getColId();

      const selectRef = useRef<HTMLSelectElement>(null);
      const [value, setValue] = useState<any>(props.value || "");
      const [open, setOpen] = React.useState(true);
      const changeTrackerRef = useRef<boolean>(false);

      // const isFileCol = fileCols.includes(colId);

      const handleClose = (e: SyntheticEvent<Element, Event>) => {
        if (!changeTrackerRef.current) {
          props.stopEditing();
        }
      };

      const handleOpen = () => {
        setOpen(true);
      };

      useImperativeHandle(ref, () => {
        return {
          getValue() {
            return value;
          },
        };
      });

      const onChange = useCallback((event: SelectChangeEvent) => {
        changeTrackerRef.current = true;
        // send post request
        setValue(event.target.value);
      }, []);

      useEffect(() => {
        if (props.value !== value) {
          props.stopEditing();
        }
      }, [value]);
      return (
        <FormControl disabled={value.is_frozen}>
          <Select
            sx={{ width: 300, paddingTop: 0, paddingBottom: 0 }}
            fullWidth
            ref={selectRef}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={value}
            onChange={onChange}
          >
            {optionsValue
              .filter((e) => {
                if (is_frozen) {
                  return e.closes_process;
                }
                //NOTE: this if will be controlled from the back in the future maybe...
                if (colId === "cerrado") {
                  return e.state !== "pending";
                }
                return true;
              })
              .map((e) => {
                const {
                  label,
                  color: backgroundColor,
                  code: val,
                  closes_process,
                  emojis,
                } = e;
                return (
                  <MenuItem
                    key={val}
                    value={val}
                    sx={{
                      display: "flex",
                      gap: "2rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack direction="row" gap="0.5rem" alignItems="center">
                      <SquareIcon
                        sx={{ color: backgroundColor, fontSize: 10 }}
                      />
                      {`${emojis} ${label}`}
                    </Stack>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      );
    }
  )
);
