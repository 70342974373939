import React, { memo, ReactNode } from "react";
import { Stepper, Step, StepLabel, StepperProps } from "@mui/material";

interface Props extends StepperProps {
  steps: string[];
}

function StepperForm({ activeStep, steps, ...rest }: Props) {
  return (
    <Stepper {...rest} activeStep={activeStep}>
      {steps.map((label, idx) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: ReactNode } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default memo(StepperForm, (prevP, nextP) => {
  return prevP.activeStep === nextP.activeStep;
});
