import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { rangeValidation, requiredStringSchema } from "utils/yupValidation";
import { Center } from "shared/layouts";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { PROPERTY_AREA_RANGE } from "shared/constants/mortgageForm";

function PropertyAreaForm() {
  const { mortgageFormData, onStepClick } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onStepClick()}
      onBack={onStepClick(false)}
      title="¿Cuántos metros cuadrados totales tiene la vivienda?"
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            area: requiredStringSchema("Por favor ingrese un numero").test(
              rangeValidation(PROPERTY_AREA_RANGE.min, PROPERTY_AREA_RANGE.max)
            ),
          }),
        })
      )}
    >
      <Center>
        <FormFields.CounterInput
          name="property.area"
          label={
            <>
              m<sup>2</sup>
            </>
          }
          min={PROPERTY_AREA_RANGE.min}
          max={PROPERTY_AREA_RANGE.max}
        />
      </Center>
    </StepLayout>
  );
}

export default PropertyAreaForm;
