import React, { FunctionComponent, ReactComponentElement } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../shared/hooks";

import { MainRealEstateLayout, NotAuthLayout } from "../../../shared/layouts";

import styles from "./styles.module.scss";

import { NumberBox } from "../Components";

const { REACT_APP_SPECIAL_AGENT }: any = process.env;

const HowWorks: FunctionComponent<{}> = (props): ReactComponentElement<any> => {
  const [email] = useLocalStorage("email");
  const navigate = useNavigate();
  return (
    <MainRealEstateLayout>
      <Container className={styles.Wrapper}>
        <Grid container className={styles.fullHeight}>
          <Grid item md={12} xs={12} sm={12}>
            <Typography
              color="primary"
              variant="h5"
              display="block"
              marginBottom="1rem"
              textAlign="center"
              className={styles.title}
            >
              Cómo funciona el programa
            </Typography>
          </Grid>

          <Grid item md={12} xs={12} sm={12} container>
            {email !== REACT_APP_SPECIAL_AGENT ? (
              <>
                <Grid item xs={12} md={6}>
                  <NumberBox
                    number="01"
                    colorNumber="#D8D8F6"
                    title="Registra al cliente"
                    paragraph="Ingresa los datos del cliente que está buscando una hipoteca de la forma que más te convenga (encontrarás todo en el panel principal)."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberBox
                    number="02"
                    colorNumber="#E4F086"
                    title="Seguimiento de Colibid"
                    paragraph="Nos pondremos en contacto con el cliente vía mail o Whatsapp, ¡asegúrate que los datos proporcionados son correctos! Si no logramos contactarle, te avisaremos."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberBox
                    number="03"
                    colorNumber="#01807A"
                    title="Seguimiento"
                    paragraph="Podrás seguir los avances en el proceso de tus clientes desde la sección “Mis Clientes”."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberBox
                    number="04"
                    colorNumber="#33CCCC"
                    title="Celebra"
                    paragraph={`¡Asegúrate que tus datos bancarios ingresados sean los correctos para el cobro de tu premio! Puedes hacerlo en "Mi Perfil".`}
                  />
                </Grid>{" "}
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <NumberBox
                    number="01"
                    colorNumber="#D8D8F6"
                    title="Registra al cliente"
                    paragraph="Ingresa los datos del cliente que está buscando una hipoteca de la forma que más te convenga (encontrarás todo en el panel principal)."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberBox
                    number="02"
                    colorNumber="#E4F086"
                    title="Seguimiento de Colibid"
                    paragraph="Nos pondremos en contacto con el cliente vía mail o Whatsapp, ¡asegúrate que los datos proporcionados son correctos! Si no logramos contactarle, te avisaremos."
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <NumberBox
                    number="03"
                    colorNumber="#33CCCC"
                    title="Celebra"
                    paragraph={`¡Una vez la solicitud se haya aprobado exitosamente!`}
                  />
                </Grid>{" "}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainRealEstateLayout>
  );
};

export default HowWorks;
