import { PMT } from "@formulajs/formulajs";
import { useEffect, useState } from "react";
import { getInterestTypeService } from "services/api";
import { InterestTypeValue } from "services/api/general.service";
import { NumericString } from "shared/types/misc";
import { ExpensesList, InterestType } from "shared/types/postMortgageData";
import logger from "utils/logger";
import maxMortgageDebtRatio from "utils/maxMortgageDebtRatio";

type ExcludedInterestType<T> = Exclude<InterestType, T>;

export type InterestTypeObjExcluding<T> = {
  [x in ExcludedInterestType<T>]: InterestTypeValue;
};

export default function useInterestRate() {
  const [interestRates, setInterestRates] = useState<InterestTypeValue>();

  useEffect(() => {
    getInterestTypeService().then((res) => {
      setInterestRates(res.data);
    });
  }, []);

  const mortgageAllowedCalculator = ({
    dataPeriod,
    expensesList,
    revenueMonthly,
    dataAmount,
    interestType,
  }: {
    dataPeriod: NumericString;
    expensesList: ExpensesList[];
    revenueMonthly: NumericString;
    dataAmount: NumericString;
    interestType: InterestType;
  }) => {
    //check if total debt plus mortgage debt, cannot surpase 35% of the net salary minus month debt
    let totalMonthlyDebts = expensesList.reduce(
      (a: number, v: ExpensesList) => a + Number(v.debt_monthly),
      0
    );

    // interestRate viene de back, param a considerar enviar a back
    // tipo de interes, dataAmount(cuanto el bank te va a finaciar) and dataPeriod
    const interestRate = interestRates
      ? interestRates[interestType === "variable" ? "variable" : "fijo"].value
      : 0;
    const rate = (interestRate + 1) / 100;
    const pmtSum = Math.abs(
      PMT(
        rate / 12,
        Number(dataPeriod) * 12,
        Number(dataAmount),
        0,
        0
      ) as number
    );

    const totalDebtPlusMortgage = pmtSum + totalMonthlyDebts;
    const debtRatio = Math.ceil((totalDebtPlusMortgage / Number(revenueMonthly || 0)) * 100);
    const maxDebtRatio = maxMortgageDebtRatio(Number(revenueMonthly || 0));

    logger.info({
      revenueMonthly,
      totalMonthlyDebts,
      rate,
      dataPeriod,
      dataAmount,
      pmtSum,
      totalDebtPlusMortgage,
      debtRatio,
      maxDebtRatio,
    });

    return {
      isAllowed: maxDebtRatio >= debtRatio,
      debtRatio: isFinite(debtRatio) ? debtRatio : 0,
    };
  };

  return { interestRate: interestRates, mortgageAllowedCalculator };
}
