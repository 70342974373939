import React, { FunctionComponent, ReactElement } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import styles from "./styles.module.scss";

const NotFound: FunctionComponent<{}> = (): ReactElement<any> => {
  return (
    <Grid container spacing={2} style={{ textAlign: "center" }}>
      <Grid item xs={12} style={{ marginTop: "40px" }}>
        <Box className={styles.SuccessMessageImage}>
          <img src="/assets/img/sources/404.svg" alt="" width="50%" />
        </Box>
        <Divider style={{ margin: "2rem" }} light />
        <Typography variant="subtitle1" align="center">
          Lo sentímos pero no hemos encontrado lo que buscabas :(
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
