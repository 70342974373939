import { BaseApiSuccessRes, MortgageRes } from "shared/types/BaseApiResponse";
import { BidFile } from "shared/types/DocType";
import { PostMortgageData } from "../../shared/types/postMortgageData";
import axios from "../../utils/axios";

const getMortgages = (values: any, page: number, limit: number) => {
  // Check if values.sort exists before including it in params
  const params: any = {
    format: "json",
    ipp: limit,
    p: page,
    mode: values.mode,
    filters: values.filters,
    wrap: 1,
  };

  if (values.sort) {
    params.sort = values.sort;
  }

  return axios.get(`${process.env.REACT_APP_API_URL}/mortgages`, {
    params,
  });
};

const getMortgage = (id: string) => {
  return axios.get<BaseApiSuccessRes<MortgageRes>>(
    `${process.env.REACT_APP_API_URL}/mortgages/${id}?format=json`
  );
};

const getLastMortgage = () => {
  return axios.get<BaseApiSuccessRes<MortgageRes>>(
    `${process.env.REACT_APP_API_URL}/mortgages/last`
  );
};

const addMortgage = (data: PostMortgageData) => {
  return axios.post<MortgageRes>(
    `${process.env.REACT_APP_API_URL}/mortgages`,
    data
  );
};

const updateMortgage = (data: PostMortgageData, mortgageId: string) => {
  return axios.put<MortgageRes>(
    `${process.env.REACT_APP_API_URL}/mortgage/${mortgageId}/activate`,
    data
  );
};

const getMortgageFilesById = (
  mid: MortgageRes["id"],
  only: "required" | "all" | 'importants_files' = "all"
) => {
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/mortgages/${mid}/files`
  );
  url.searchParams.append("only", only);
  return axios.get<BaseApiSuccessRes<BidFile[]>>(url.toString());
};

const getMortgageAcceptedBid = (mortgageId: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}/bid/accepted`
  );
};

const deleteMortgage = (mortgageId: string) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/mortgages/${mortgageId}`
  );
};

export {
  getMortgages,
  getMortgage,
  getMortgageFilesById,
  getLastMortgage,
  addMortgage,
  getMortgageAcceptedBid,
  deleteMortgage,
  updateMortgage,
};
