import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import FormFields from "shared/components/FormFields";
import { MortgageFormData } from "shared/types/postMortgageData";
import { requiredStringSchema } from "utils/yupValidation";
import { solicitorTypeOptions } from "../../utils";
import MortgageCalculatorDialog from "../MortgageCalculatorDialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Center } from "shared/layouts";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { useLocalStorage } from "shared/hooks";
import { useFormContext } from "react-hook-form";

function SolicitorTypeForm() {
  const { mortgageFormData, onNext, onBack } = useMortgageFormContext();

  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onBack={onBack}
      onNext={onNext}
      title="¿Qué tipo de solicitud quieres realizar?"
      resolver={yupResolver(
        yup.object({
          solicitor_type: requiredStringSchema(
            "Por favor seleccione una opción"
          ),
        })
      )}
    >
      <Form />
    </StepLayout>
  );
}

function Form() {
  const { mortgageFormData, onNext } = useMortgageFormContext();
  const { getValues } = useFormContext<MortgageFormData>();
  const [role] = useLocalStorage("role");
  const mortgageMode = mortgageFormData.mortgage?.mode;
  const handleNextClick = () => {
    onNext(getValues());
  };
  return (
    <>
      {[1].includes(mortgageMode) && role !== "realstate" && (
        <MortgageCalculatorDialog />
      )}
      <FormFields.ListSelector<MortgageFormData>
        name="solicitor_type"
        options={solicitorTypeOptions}
        onClick={handleNextClick}
      />
    </>
  );
}

export default SolicitorTypeForm;
