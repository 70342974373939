import React, { ReactElement, FunctionComponent } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogWarningProps = {
  title?: String;
  children: JSX.Element | JSX.Element[] | string | string[];
  buttonDisagree?: String;
  buttonAgree?: String;
  ShowDialog: boolean;
  closeIcon?: boolean;
  handledClickAgree: () => void;
  handledClickDisagree?: () => void;
  closeOnOutside?: (event: any, reason: any) => void;
  otherPropsTitle?: DialogTitleProps;
  otherPropsButtonAccept?: ButtonProps;
  otherPropsButtonCancel?: ButtonProps;
};

const DialogWarning: FunctionComponent<DialogWarningProps> = ({
  title,
  children,
  buttonAgree,
  buttonDisagree,
  ShowDialog,
  handledClickAgree,
  handledClickDisagree,
  closeIcon,
  closeOnOutside,
  otherPropsButtonAccept,
  otherPropsButtonCancel,
  otherPropsTitle,
}): ReactElement<any> => {
  const handleClose = (event?: any, reason?: any) => {
    if (closeOnOutside) {
      closeOnOutside(event, reason);
    }
  };
  return (
    <div>
      <Dialog
        open={ShowDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        onClose={handleClose}
      >
        {title && <DialogTitle {...otherPropsTitle}>{title}</DialogTitle>}
        {closeIcon && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent>
          <DialogContentText
            component={"div"}
            id="alert-dialog-slide-description"
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttonDisagree && (
            <Button {...otherPropsButtonCancel} onClick={handledClickDisagree}>
              {buttonDisagree}
            </Button>
          )}
          {buttonAgree && (
            <Button {...otherPropsButtonAccept} onClick={handledClickAgree}>
              {buttonAgree}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogWarning;
