import { Fab, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

import TagManager from "react-gtm-module";

export default function CreateMortgageFab() {
  const navigate = useNavigate();
  return (
    <Fab
      color="primary"
      aria-label="add"
      size={"small"}
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: "app_mis_solicitudes_fab_crear_nueva_solicitud",
          },
        });
        navigate("/client/mortgage/create");
      }}
    >
      <AddIcon />
    </Fab>
  );
}
