import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";
import { NavItems } from "shared/constants/navigations";

export interface IPageTitleProps {
  title: string;
  textAlign?: "right" | "left" | "inherit" | "center" | "justify" | undefined;
}
const PageTitle  = ({
  title,
  textAlign,
}: IPageTitleProps): ReactElement<any> => {
  return (
    <Typography
      variant="h6"
      align={textAlign !== undefined ? textAlign : "left"}
    >
      {title}
    </Typography>
  );
};

function WithIcon({ iconUrl, label }: Pick<NavItems, 'iconUrl' | 'label'> ) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <img src={iconUrl} width="70px" />
      <Typography
        variant="h5"
        sx={{
          backgroundColor: "#D9F6EF",
          fontWeight: 500,
          paddingInline: "1rem",
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
}

PageTitle.WithIcon = WithIcon;

export default PageTitle;
