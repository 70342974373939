import { MortgageRes } from "./BaseApiResponse";

export interface CRMTableActionsFields {
  created: string;
  value: string;
  is_frozen: boolean;
  is_editable: boolean;
  comment: string;
  payload: unknown;
}

export interface CRMTableBase {
  id: number;
  mortgage: string;
  seen: boolean;
  bid_status?: string;
  Cliente: string;
  created: string;
  diasDesdeAdquisicionCliente: number;
  mortgage_data: MortgageRes["mortgage_data"];
}
export interface CRMTableActions {
  [x: string]: CRMTableActionsFields;
}

export type CRMTable = CRMTableActions & CRMTableBase;

export interface BidderEvaluationOption {
  id?: string;
  label: string;
  /**this color is referer to background color */
  color: string;
  code: string;
  emojis: string;
  closes_process: boolean;
  closes_successfully: boolean;
  state: "pending" | "fulfilled" | "rejected";
}

export interface PostCrmInfoFields {
  id: string;
  field: keyof CRMTable;
  value: string;
}

export interface BidderMetrics {
  total: number;
  closed_deals: number;
  collapsed: number;
  expired: number;
  avg_time: number;
  conversion_rate: number;
}
export interface CrmFunnel {
  [x: keyof CRMTableActions]: number;
}

export interface CrmFields {
  accepted_type: "date" | "string" | "file" | "number";
  label: string;
  name: string;
  require_file: boolean | string[];
  tags: string[];
  weight: number;
  description: string;
  /**coli consejo o tips */
  description2: string;
  max_days: number;
  state_change_if_closed_successfully: "paid" | string;
}

export enum CrmTabState {
  NO_COLLAPSE,
  COLLAPSE,
  PAID,
}

export type FileCol  =  "FEIN" | "tasadoraRealizada" | "notaEncargoFirmada"

export type CRMFlashyCells = Record<keyof CRMTableActions, number[]>;
