import axios from "../../utils/axios";

const getBroker = (username: string) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/brokers/${username}`);
};

const getBrokerDocuments = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/bidders/documents`);
}

const changeStatusSigned = (status: string) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/bidders/accept`, status);
}

const getFinancedDocumentColibid = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/settings/FINANCED_CONDITIONS`);
}

export { getBroker, changeStatusSigned, getBrokerDocuments, getFinancedDocumentColibid };
