import {
  FormControl,
  FormHelperText, TextField,
  TextFieldProps
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ES from "react-phone-number-input/locale/es.json";
import { forwardRef } from "react";

export default function PhoneNumberInput({
  name = "",
  label,
  ...rest
}: TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl error={Boolean(error)}>
            <PhoneInput
              {...field}
              international
              name={name}
              labels={ES}
              inputComponent={phoneInput}
              defaultCountry="ES"
              countryOptionsOrder={["ES"]}
            />
            {Boolean(error) && error?.message && (
              <FormHelperText error>{error.message}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
const phoneInput = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      id="phone-input"
      inputRef={ref}
      fullWidth
      label={"Móvil"}
      size="small"
      type="text"
      variant="outlined"
      autoComplete="off"
      required
    />
  );
});
