import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { isMobile } from "react-device-detect";

export interface CustomAlertProps {
  title: string;
  subtitle?: ReactNode;
  acceptCb?: () => void;
  acceptBtnTitle?: string;
  cancelCb?: () => void;
  cancelBtnTitle?: string;
  alertProps?: AlertProps;
}

export default function CustomAlert({
  title,
  subtitle,
  acceptCb,
  acceptBtnTitle,
  cancelCb,
  cancelBtnTitle,
  alertProps,
}: CustomAlertProps) {
  const alertStyle: SxProps<Theme> = {
    margin: "1rem ",
  };

  if (isMobile) {
    alertStyle.display = "flex";
    alertStyle.flexDirection = "column";
  }

  const onClickCancel = () => {
    cancelCb?.();
  };

  const onClickAccept = () => {
    acceptCb?.();
  };

  return (
    <Alert
      {...alertProps}
      sx={alertProps?.sx || alertStyle}
      icon={isMobile || null}
      action={
        alertProps?.action || (
          <>
            {cancelCb && (
              <Button onClick={onClickCancel} size="small">
                {cancelBtnTitle || "Cancelar"}
              </Button>
            )}
            {acceptCb && (
              <Button onClick={onClickAccept} size="small">
                {acceptBtnTitle || "Reanudar"}
              </Button>
            )}
          </>
        )
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {subtitle}
    </Alert>
  );
}
