import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ExtraErrorData, CaptureConsole } from "@sentry/integrations";

const isDevelopment = process.env.REACT_APP_ENV === "development";

export const ErrorMonitorInit = () => {
  if (isDevelopment) return;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new ExtraErrorData(),
      new CaptureConsole({
        levels: ["error"],
      }),
      new Sentry.Replay(),
    ],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: isDevelopment ? 1 : 0.2,
    replaysOnErrorSampleRate: 1,
  });
};

export const setUserForErrorMonitor = (user: Sentry.User) => {
  if (isDevelopment) return;
  Sentry.configureScope((scope) => {
    scope.setUser(user)
  })
};
