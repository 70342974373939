import {
  Skeleton, Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import { FunctionComponent, ReactElement } from "react";

export interface TableSkeleton {
  headColumns: number;
  bodyRows: number;
  bodyColumns: number;
}

const TableSkeleton: FunctionComponent<TableSkeleton> = ({
  headColumns,
  bodyRows,
  bodyColumns,
}): ReactElement<any> => {
  const columnsHead = () => {
    let list = [];
    for (let index = 0; index < headColumns; index++) {
      list.push(index);
    }
    return list.map((item) => (
      <TableCell align="center" key={item}>
        <Skeleton variant="rectangular" width="100%" />
      </TableCell>
    ));
  };

  const rowsBody = () => {
    let list = [];

    for (let index = 0; index < bodyRows; index++) {
      list.push(index);
    }

    return list.map((item) => <TableRow key={item}>{columnsHead()}</TableRow>);
  };

  return (
    <TableContainer sx={{ maxHeight: 650 }} component={Paper}>
      <Table stickyHeader sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>{columnsHead()}</TableRow>
        </TableHead>
        <TableBody>{rowsBody()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
