import { requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";

export const stepThreeValidationSchema = (mode: number) => yup.object().shape({
  property: yup.object().shape({
    province: requiredStringSchema(),
    community: requiredStringSchema(),
    city: requiredStringSchema(),
    zipcode: requiredStringSchema().matches(
      /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
      "Por favor introduce un código postal válido"
    ),
    address: requiredStringSchema("Por favor introduzca una dirección"),
    // notasimple: requiredStringSchema(),
    // arras: requiredStringSchema(),
    // reserva: requiredStringSchema(),
    house_situation: yup.string().when("dummy", {
      is(val: any) {
        return [1,4].includes(mode);
      },
      then(schema) {
        return schema
          .required("Ingrese un valor")
          .nullable()
          .test(
            "noHouseIdentify",
            "Lo sentimos hasta que no tengas un lugar identificado no podemos activar el proceso de subasta.",
            (testVal) => testVal !== "1"
          );
      },
    }),
  }),
});
