import { Stack, Button } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoCard from "shared/components/InfoCard";
import { StepView } from "../../MortgageForm/stepBuilder";
import { MortgageFormData } from "shared/types/postMortgageData";

type Props = {
  onClick?: (s: StepView<MortgageFormData>) => void;
  stepViews: StepView<MortgageFormData>[];
};
function EditMortgageModalCard({ stepViews, onClick }: Props) {
  const handleClick =
    (closeModal: () => void, s: StepView<MortgageFormData>) => () => {
      closeModal();
      onClick?.(s);
    };
  return (
    <InfoCard.Modals>
      {(closeModal) => {
        return (
          <Stack alignItems="flex-start">
            {stepViews.flat().map((s) => {
              if (s.accesor) {
                if (Array.isArray(s.accesor)) {
                  return s.accesor.map((a) => {
                    return (
                      <Button
                        key={a.label}
                        startIcon={<RadioButtonUncheckedIcon />}
                        onClick={handleClick(closeModal, s)}
                      >
                        {a.label}
                      </Button>
                    );
                  });
                }
                return (
                  <Button
                    key={s.component.name}
                    startIcon={<RadioButtonUncheckedIcon />}
                    onClick={handleClick(closeModal, s)}
                  >
                    {s.accesor.label}
                  </Button>
                );
              }
              return null;
            })}
          </Stack>
        );
      }}
    </InfoCard.Modals>
  );
}

export default EditMortgageModalCard;
