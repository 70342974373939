import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import { requiredStringSchema } from "utils/yupValidation";
import { propertyExistenceOptions } from "../../utils";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { Typography } from "@mui/material";
import { MortgageFormData } from "shared/types/postMortgageData";
import { useState } from "react";

function PropertyExistenceForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  const [showMsg, setShowMsg] = useState(false);
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿De qué tipo de vivienda se trata?"
      resolver={yupResolver(
        yup.object({
          //anteriormente comparaba el modo, si estos pasa al builder, el builder decide q input tener
          property_existence: requiredStringSchema(),
        })
      )}
    >
      {({ getValues }) => {
        const handleClick = (form?: MortgageFormData) => {
          const val = getValues("property_existence");
          if (val === "1") {
            // show message
            setShowMsg(true)
          } else {
            setShowMsg(false)
            onNext(form)
          }
        };
        return (
          <>
            <FormFields.ListSelector
              name="property_existence"
              options={propertyExistenceOptions}
              onClick={handleClick}
            />
            {showMsg && (
              <Typography>
                Por favor ten en cuenta que el inicio de la solicitud de
                hipoteca debe hacerse máximo 2 meses antes de tu fecha prevista
                de firma de escritura de la vivienda. Si tu fecha estimada es
                mayor, debes esperar para iniciar el proceso.
              </Typography>
            )}
          </>
        );
      }}
    </StepLayout>
  );
}

export default PropertyExistenceForm;
