export enum YesNoOption {
  Sí = 1,
  No,
}

export enum RequireBiddingsActivation {
  REQUIRED = "required",
  POSTPONED = "postponed",
}

export enum ModalAgencyAcepted {
  ACCEPTED = "",
  DECLINE = "postponed",
}
