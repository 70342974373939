import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, TooltipProps, Stack, SxProps, Theme } from "@mui/material";
import { FlexboxProps } from "@mui/system";

/**
 *  add a tooltip next to the children node
 * @example 
 *        <WithTooltip
                title="when over you will se this text"
                position="right"
              >
                <Typography>Manual</Typography>
              </WithTooltip>
 */
export default function WithTooltip({
  children,
  position = "left",
  justifyContent = "center",
  icon,
  styleStackProps,
  ...restTooltipPros
}: {
  /**where the icon should be place next to the child node you want to wrap */
  position?: "left" | "right";
  justifyContent?: FlexboxProps["justifyContent"];
  icon?: TooltipProps["children"];
  styleStackProps?: SxProps<Theme>;
} & TooltipProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent={justifyContent}
      spacing={1}
      direction={position === "left" ? "row" : "row-reverse"}
      sx={styleStackProps}
    >
      <Tooltip
        {...restTooltipPros}
        enterTouchDelay={0}
        sx={{ ...restTooltipPros.sx, fontSize: "medium" }}
      >
        {icon ? (
          icon
        ) : (
          <InfoOutlined color="primary" sx={{ width: 24, height: 24 }} />
        )}
      </Tooltip>
      {children}
    </Stack>
  );
}
