import React, { ReactNode } from "react";
import styles from "./styles.module.scss";

interface CenterProps {
  children: ReactNode;
}

function Center(props: CenterProps) {
  return <div className={styles.center}>{props.children}</div>;
}

export default Center;
