import {
  PROPERTY_AREA_RANGE,
  USER_AGE_RANGE,
} from "shared/constants/mortgageForm";
import {
  MORTGAGE_AMOUNT_RANGES,
  PROPERTY_VALUE_RANGES,
  MIN_DATA_PERIOD_YEARS,
  MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
} from "shared/constants/mortgageForm";

const calculateAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export function valoresEnRango(rango, yValores) {
  const [xMin, xMax] = rango;

  if (yValores.length !== 2) {
    throw new Error("El arreglo debe contener exactamente 2 elementos");
  }

  return yValores.every((y) => {
    let numeroY = typeof y === "string" ? parseFloat(y) : y;

    return (
      typeof numeroY === "number" &&
      numeroY >= 0 &&
      numeroY >= xMin &&
      numeroY <= xMax
    );
  });
}

export const defaultFilters = () => {
  return {
    mode: 0,
    interestType: "",
    homeValue: [
      PROPERTY_VALUE_RANGES.GLOBAL.MIN,
      PROPERTY_VALUE_RANGES.GLOBAL.MAX,
    ],
    mortgageValue: [
      MORTGAGE_AMOUNT_RANGES.GLOBAL.MIN,
      MORTGAGE_AMOUNT_RANGES.GLOBAL.MAX,
    ],
    mortgagesProvinces: [],
    mortgagesCommunities: [],
    province: "",
    community: "",

    debtRatio: [0, 40],
    monthlyIncome: [0, 45000],
    monthlyExpenses: [0, 15000],
    monthlyDebts: 0,
    mortgageAmount: 0,
    mortgageBidsAmount: [0, 15],
    userAge: [USER_AGE_RANGE.min, USER_AGE_RANGE.max],
    userWork: [],
    user2Work: [],
    user2_Age: [18, 65],
    typeDocUser1: "",
    typeDocUser2: "",
    user2Profession: "",
    userProfession: "",
    "user.has_last_year_tax": 0,
    "user.residence_permit": 0,
    "user_2.residence_permit": 0,
    "user.residence": "",
    "user_2.residence": "",
    "data.curr_mortgage_init_date__lte":"",
    // "data.curr_mortgage_init_date_range":["", ""],
    mortgagePeriod: [
      MIN_DATA_PERIOD_YEARS,
      MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
    ],
    mortgageRate: [0, 100],
    requestType: "",
    mortgageRemaining: [0, 10],
    mortgageFiles: [],
    financialsEntities: [],
    propertyExistence: [],
    mortagagePropertyExistence: "",
    constructionType__in: [],
    constructionType: "",
    fundUsedFor: [],
    propertyType: "",
    propertyAreaRange: [30, 1000],
    "property.hasEndorsement": 0,
    allowFilterHomeValue: false,
    allowFilterMortgageValue: false,
    allowFilterMonthlyIncome: false,
    allowFilterMonthlyExpenses: false,
    allowFiltersMortgagePeriod: false,
    allowFiltersMortgageRate: false,
    allowFiltersMortageDebtRatio: false,
    allowFiltersUserAge: false,
    allowFiltersUserAgeTwo: false,
    allowFiltersMortgageBidsAmount: false,
    allowFiltersMortgageRemaining: false,
    allowPropertyType: false,
    allowFilterPropertyArea: false,
    allowCurrMortgageInitDate: false,
    mortgageType: [],
    mortageinterestType: [],

    newMortgage: false,
    changeMortgage: false,
    capitalRaise: false,
    autopromotor: false,

    fixedInterest: false,
    variableInterest: false,
    mixedInterest: false,
    totalInterest: false,

    laboralLife: false,
    sScontribution: false,
    rent: false,
    CIRBE: false,
    AEATDebts: false,
    sSSituation: false,
    corporateTax: false,

    d347: false,
    d111: false,
    d130: false,
    d190: false,
    d303: false,
    d390: false,
    d200: false,
    DNIa: false,
    DNIr: false,
    DNIaa: false,
    DNIra: false,
    NS: false,
    U3: false,
  };
};

// this var is only to extract the types to use in typescript
// defaultFilters is a fn that return a obj, maybe is that way so
// it create a new Reference to the new obj, ask manuel why, i asume it for that
// keeping inmutability which is great!!
// now you have read all those gibberish above, here its your reward a potato 🥔
// maybe turn this file to typescript (no time to do it without fearing to break it, i dare you to do it 🤺)
export const defaultFiltersForTypes = defaultFilters();

/**is used to parse default filters so the api can work with it,
 * its more like writhing django query api and then eval it on django
 * the data in res should be parse back to defaultFilters using decodeFiltersApi
 */
export const getFormatedFiltersForAPI = (filterValue) => {
  const data = {};

  // Mortgage amount
  //  1. mortgageValue
  if (
    (Number(filterValue.mortgageValue[0]) >=
      MORTGAGE_AMOUNT_RANGES.GLOBAL.MIN) &
    filterValue.allowFilterMortgageValue
  ) {
    data["data.amount__gte"] = Number(filterValue.mortgageValue[0]);
  }

  if (
    (Number(filterValue.mortgageValue[1]) <=
      MORTGAGE_AMOUNT_RANGES.GLOBAL.MAX) &
    filterValue.allowFilterMortgageValue
  ) {
    data["data.amount__lte"] = Number(filterValue.mortgageValue[1]);
  }

  // 2. Property price
  if (
    (Number(filterValue.homeValue[0]) > -1) &
    filterValue.allowFilterHomeValue
  ) {
    data["property.value__gte"] = Number(filterValue.homeValue[0]);
  }

  if (
    (Number(filterValue.homeValue[1]) <= PROPERTY_VALUE_RANGES.GLOBAL.MAX) &
    filterValue.allowFilterHomeValue
  ) {
    data["property.value__lte"] = Number(filterValue.homeValue[1]);
  }

  //3. Mortgage period
  if (
    (Number(filterValue.mortgagePeriod[0]) >= 15) &
    filterValue.allowFiltersMortgagePeriod
  ) {
    data["data.period__gte"] = Number(filterValue.mortgagePeriod[0]);
  }

  if (
    (Number(filterValue.mortgagePeriod[1]) <= 35) &
    filterValue.allowFiltersMortgagePeriod
  ) {
    data["data.period__lte"] = Number(filterValue.mortgagePeriod[1]);
  }

  //4. Mortgage Rate %
  if (
    (Number(filterValue.mortgageRate[0]) >= 0) &
    filterValue.allowFiltersMortgageRate
  ) {
    data["data.mortgage_rate__gte"] = Number(filterValue.mortgageRate[0]);
  }

  //5.new propertyType
  if (filterValue.propertyType !== "" && filterValue.allowPropertyType) {
    data["property.type"] = Number(filterValue.propertyType);
  }

  if (
    (Number(filterValue.mortgageRate[1]) <= 100) &
    filterValue.allowFiltersMortgageRate
  ) {
    data["data.mortgage_rate__lte"] = Number(filterValue.mortgageRate[1]);
  }

  // 5. debtRatio
  if (
    (Number(filterValue.debtRatio[0]) > 0) &
    filterValue.allowFiltersMortageDebtRatio
  ) {
    data["data.debtRatio__gte"] = Number(filterValue.debtRatio[0]);
  }
  if (
    (Number(filterValue.debtRatio[1]) <= 15000) &
    filterValue.allowFiltersMortageDebtRatio
  ) {
    data["data.debtRatio__lte"] = Number(filterValue.debtRatio[1]);
  }

  // 6.  Monthly income amount
  if (
    Number(filterValue.monthlyIncome[0]) > 0 &&
    filterValue.allowFilterMonthlyIncome
  ) {
    data["revenue.monthly__gte"] = Number(filterValue.monthlyIncome[0]);
  }

  if (
    Number(filterValue.monthlyIncome[1]) <= 45000 &&
    filterValue.allowFilterMonthlyIncome
  ) {
    data["revenue.monthly__lte"] = Number(filterValue.monthlyIncome[1]);
  }

  //7. Monthly expenses amount
  if (
    (Number(filterValue.monthlyExpenses[0]) > 0) &
    filterValue.allowFilterMonthlyExpenses
  ) {
    data["revenue.general.monthly_expenses__gte"] = Number(
      filterValue.monthlyExpenses[0]
    );
  }

  if (
    Number(filterValue.monthlyExpenses[1]) > 1 &&
    filterValue.allowFilterMonthlyExpenses
  ) {
    data["revenue.general.monthly_expenses__lte"] = Number(
      filterValue.monthlyExpenses[1]
    );
  }

  // 8. User age
  if (
    Number(filterValue.userAge[0]) >= USER_AGE_RANGE.min &&
    filterValue.allowFiltersUserAge
  ) {
    // {"user.birthdate__lte": "2002-01-01", "user.birthdate__gte": "1992-01-01"}
    const date = new Date();
    date.setFullYear(date.getFullYear() - filterValue.userAge[0]);
    const userBirthDay = `${date.getFullYear()}-01-01`;

    data["user.birthdate__lte"] = userBirthDay;
  }

  if (
    Number(filterValue.userAge[1]) <= USER_AGE_RANGE.max &&
    filterValue.allowFiltersUserAge
  ) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - filterValue.userAge[1]);
    const userBirthDay = `${date.getFullYear()}-01-01`;

    data["user.birthdate__gte"] = userBirthDay;
  }

  //9. bids
  if (
    (Number(filterValue.mortgageBidsAmount[1]) < 15) &
    filterValue.allowFiltersMortgageBidsAmount
  ) {
    data["data.bids__lte"] = filterValue.mortgageBidsAmount[1];
  }

  if (
    (Number(filterValue.mortgageBidsAmount[0]) >= 0) &
    filterValue.allowFiltersMortgageBidsAmount
  ) {
    data["data.bids__gte"] = filterValue.mortgageBidsAmount[0];
  }

  //10
  // mortgageRemaining
  if (
    Number(filterValue.mortgageRemaining[0]) >= 0 &&
    filterValue.allowFiltersMortgageRemaining
  ) {
    data["remaining__gte"] = Number(filterValue.mortgageRemaining[0]);
  }

  if (
    Number(filterValue.mortgageRemaining[1]) <= 10 &&
    filterValue.allowFiltersMortgageRemaining
  ) {
    data["remaining__lte"] = Number(filterValue.mortgageRemaining[1]);
  }

  // Mode
  if (filterValue.mode > 0) {
    data["mortgage.mode"] = filterValue.mode;
  }

  if (filterValue.interestType !== "") {
    data["data.interest_type"] = filterValue.interestType;
  }

  // User DNI
  if (filterValue.typeDocUser1 !== "" && filterValue.typeDocUser1) {
    data["user.type_dni"] = filterValue.typeDocUser1;
  }

  // Partner DNI
  if (filterValue.typeDocUser2 !== "" && filterValue.typeDocUser2) {
    data["user_2.type_dni"] = filterValue.typeDocUser2;
  }

  //Mortgage period
  /*   if (Number(filterValue.userAge[0]) > 18) {
    // {"user.birthdate__lte": "2002-01-01", "user.birthdate__gte": "1992-01-01"}
    const date = new Date();
    date.setFullYear(date.getFullYear() - filterValue.userAge[0]);
    const userBirthDay = `${date.getFullYear()}-01-01`;

    data["user.birthdate__lte"] = userBirthDay;
  }

  if (Number(filterValue.userAge[1]) <= 65) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - filterValue.userAge[1]);
    const userBirthDay = `${date.getFullYear()}-01-01`;

    data["user.birthdate__gte"] = userBirthDay;
  } */

  // bids
  if (Number(filterValue.bidsAmount > 0)) {
    data["bids"] = Number(filterValue.bidsAmount);
  }
  // laboral situation
  if (filterValue.userWork.length > 0) {
    let work = filterValue.userWork.map((opt) => parseInt(opt.id));
    data["user.work__in"] = work;
  }

  //laboral situation user 2
  if (Number(filterValue.user2Work.length > 0)) {
    let work = filterValue.user2Work.map((opt) => parseInt(opt.id));
    data["user_2.work__in"] = work;
  }

  //user professsion
  if (filterValue.userProfession !== "") {
    data["user.profession"] = filterValue.userProfession;
  }
  //Use2 profession
  if (filterValue.user2Profession !== "") {
    data["user_2.profession"] = filterValue.user2Profession;
  }

  //User has tax
  if (filterValue["user.has_last_year_tax"]) {
    data["user.has_last_year_tax"] = filterValue["user.has_last_year_tax"];
  }
  // user residence permit
  if (filterValue["user.residence_permit"]) {
    data["user.residence_permit"] = filterValue["user.residence_permit"];
  }
  // user_2 residence permit
  if (filterValue["user_2.residence_permit"]) {
    data["user_2.residence_permit"] = filterValue["user_2.residence_permit"];
  }
  // user residence
  if (filterValue["user.residence"]) {
    data["user.residence"] = filterValue["user.residence"];
  }
  // user2 residence
  if (filterValue["user_2.residence"]) {
    data["user_2.residence"] = filterValue["user_2.residence"];
  }

  if (filterValue["property.hasEndorsement"]) {
    data["property.hasEndorsement"] = filterValue["property.hasEndorsement"];
  }

  // Request type
  if (Number(filterValue.requestType !== "")) {
    data["solicitor_type"] = Number(filterValue.requestType);
  }

  //Mortgage period
  if (
    (Number(filterValue.user2_Age[0]) >= 18) &
    filterValue.allowFiltersUserAgeTwo
  ) {
    // {"user.birthdate__lte": "2002-01-01", "user.birthdate__gte": "1992-01-01"}
    const date = new Date();
    date.setFullYear(date.getFullYear() - filterValue.user2_Age[0]);
    const userBirthDay = `${date.getFullYear()}-01-01`;

    data["user_2.birthdate__lte"] = userBirthDay;
  }

  if (
    (Number(filterValue.user2_Age[1]) <= 65) &
    filterValue.allowFiltersUserAgeTwo
  ) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - filterValue.user2_Age[1]);
    const userBirthDay = `${date.getFullYear()}-01-01`;

    data["user_2.birthdate__gte"] = userBirthDay;
  }

  // Doc Files
  // {"uploads": ["coli_notasimple" ]}
  if (Number(filterValue.mortgageFiles.length > 0)) {
    let files = filterValue.mortgageFiles.map((opt) => opt.code);
    data["uploads"] = files;
  }

  // Financials Institutions
  // {"customer_application_situation.financials_entities.lnin": ["Abanca", "BBVA" ]}
  if (Number(filterValue.financialsEntities.length > 0)) {
    let entities = filterValue.financialsEntities.map((opt) => opt.name);
    data["customer_application_situation.financials_entities.nin"] = entities;
  }

  // Community
  if (Number(filterValue.mortgagesCommunities.length) > 0) {
    data["property.community__in"] = filterValue.mortgagesCommunities;
  }

  // Province
  if (Number(filterValue.mortgagesProvinces.length) > 0) {
    data["property.province__in"] = filterValue.mortgagesProvinces;
  }

  // Monthly debts !!! WARNING add logic in API!!!
  if (filterValue.monthlyDebts > 0) {
    // data["revenue.monthly_debts"] = filterValue.monthlyDebts;
  }
  // mortgage type
  if (filterValue.mortgageType.length > 0) {
    data["mortgage.mode__in"] = filterValue.mortgageType;
  }

  // Fixed interest
  if (filterValue.mortageinterestType.length > 0) {
    data["data.interest_type__in"] = filterValue.mortageinterestType;
  }

  // Docs
  if (filterValue.laboralLife === true) {
    data["mortgage.laboralLife"] = "Doc_laboral";
  }

  if (filterValue.sScontribution === true) {
    data["mortgage.laboralLife"] = "Doc_cotizacion";
  }

  // property existence
  if (filterValue.mortagagePropertyExistence != "") {
    data["property_existence"] = Number(filterValue.mortagagePropertyExistence);
  }

  if (filterValue.propertyExistence.length > 0) {
    data["property_existence__in"] = filterValue.propertyExistence;
  }

  if (filterValue.constructionType__in.length > 0) {
    data["property.constructionType__in"] = filterValue.constructionType__in;
  }

  // fund used for
  if (filterValue.fundUsedFor > 0) {
    data["data.fund_used_for__in"] = filterValue.fundUsedFor;
  }

  if (filterValue.allowCurrMortgageInitDate){
    data["data.curr_mortgage_init_date__lte"] = filterValue["data.curr_mortgage_init_date__lte"];
    // data["data.curr_mortgage_init_date__range"] = filterValue["data.curr_mortgage_init_date_range"];
  }

  // property.area
  if (
    (Number(filterValue.propertyAreaRange[0]) >= PROPERTY_AREA_RANGE.min) &
    filterValue.allowFilterPropertyArea
  ) {
    data["property.area__gte"] = Number(filterValue.propertyAreaRange[0]);
  }

  if (
    (Number(filterValue.propertyAreaRange[1]) <= PROPERTY_AREA_RANGE.max) &
    filterValue.allowFilterPropertyArea
  ) {
    data["property.area__lte"] = Number(filterValue.propertyAreaRange[1]);
  }

  return data;
};

/**
 *  is used to decoded the data from back been send as a return of getFormatedFiltersForApi fn
 *  here we parse it back so the front filter can understand the defaultFilters fn
 * @param {object} data from getFormatedFiltersFor api
 * @returns object defaultFilters return types
 */
export const decodeFiltersAPI = (data) => {
  const dF = defaultFilters();
  return {
    mode: data["mortgage.mode"] || dF["mode"],
    interestType: data["data.interest_type"] || dF["interestType"],
    typeDocUser1: data["user.type_dni"] || dF["typeDocUser1"],
    typeDocUser2: data["user_2.type_dni"] || dF["typeDocUser2"],
    allowFilterHomeValue:
      data["property.value__gte"] || data["property.value__lte"] ? true : false,

    homeValue: [
      data["property.value__gte"] || dF["homeValue"][0],
      data["property.value__lte"] || dF["homeValue"][1],
    ],
    allowFilterMortgageValue:
      data["data.amount__gte"] || data["data.amount__lte"] ? true : false,
    mortgageValue: [
      data["data.amount__gte"] || dF["mortgageValue"][0],
      data["data.amount__lte"] || dF["mortgageValue"][1],
    ],
    mortgageType: data["mortgage.mode__in"] || dF["mortgageType"],
    mortageinterestType:
      data["data.interest_type__in"] || dF["mortageinterestType"],
    mortgagesProvinces:
      data["property.province__in"] || dF["mortgagesProvinces"],
    mortgagesCommunities:
      data["property.community__in"] || dF["mortgagesCommunities"],
    province: "",
    community: "",
    allowFiltersMortageDebtRatio:
      (data["data.debtRatio__gte"] ||
        false ||
        data["data.debtRatio__lte"] ||
        false) === false
        ? false
        : true,
    debtRatio: [
      data["data.debtRatio__gte"] || dF["debtRatio"][0],
      data["data.debtRatio__lte"] || dF["debtRatio"][1],
    ],
    monthlyIncome: [
      data["revenue.monthly__gte"] || dF["monthlyIncome"][0],
      data["revenue.monthly__lte"] || dF["monthlyIncome"][1],
    ],
    monthlyExpenses: [
      data["revenue.general.monthly_expenses__gte"] || dF["monthlyExpenses"][0],
      data["revenue.general.monthly_expenses__lte"] || dF["monthlyExpenses"][1],
    ],
    monthlyDebts: data["revenue.monthly_debts"] || dF["monthlyDebts"],
    mortgageAmount: data["data.amount"] || dF["mortgageAmount"],
    allowFiltersMortgageBidsAmount:
      data["data.bids__gte"] || data["data.bids__lte"] ? true : false,
    mortgageBidsAmount: [
      data["data.bids__gte"] || dF["mortgageBidsAmount"][0],
      data["data.bids__lte"] || dF["mortgageBidsAmount"][1],
    ],
    userAge: [
      data["user.birthdate__gte"]
        ? calculateAge(data["user.birthdate__lte"])
        : dF["userAge"][0],
      data["user.birthdate__lte"]
        ? calculateAge(data["user.birthdate__gte"])
        : dF["userAge"][1],
    ],

    user2_Age: [
      data["user_2.birthdate__gte"]
        ? calculateAge(data["user_2.birthdate__lte"])
        : dF["user2_Age"][0],
      data["user_2.birthdate__lte"]
        ? calculateAge(data["user_2.birthdate__gte"])
        : dF["user2_Age"][1],
    ],
    userWork: data["user.work__in"] || dF["userWork"],
    user2Work: data["user_2.work__in"] || dF["user2Work"],
    allowFiltersUserAge:
      data["user.birthdate__gte"] || data["user.birthdate__lte"] ? true : false,
    allowFiltersUserAgeTwo:
      data["user_2.birthdate__gte"] || data["user_2.birthdate__lte"]
        ? true
        : false,

    propertyType: data["property.type"] || dF["propertyType"],
    allowPropertyType: data["property.type"] ? true : false,

    user2Profession: data["user_2.profession"] || dF["user2Profession"],
    userProfession: data["user.profession"] || dF["userProfession"],
    "user.has_last_year_tax":
      data["user.has_last_year_tax"] || dF["user.has_last_year_tax"],
    "user.residence_permit":
      data["user.residence_permit"] || dF["user.residence_permit"],
    "user_2.residence_permit":
      data["user_2.residence_permit"] || dF["user_2.residence_permit"],
    "user.residence": data["user.residence"] || dF["user.residence"],
    "user_2.residence": data["user_2.residence"] || dF["user_2.residence"],

    "property.hasEndorsement":
      data["property.hasEndorsement"] || dF["property.hasEndorsement"],
    allowFiltersMortgagePeriod:
      data["data.period__gte"] || data["data.period__lte"] ? true : false,
    mortgagePeriod: [
      data["data.period__gte"] || dF["mortgagePeriod"][0],
      data["data.period__lte"] || dF["mortgagePeriod"][1],
    ],
    allowFiltersMortgageRate:
      data["data.mortgage_rate__gte"] ||
      data["data.mortgage_rate__gte"] === 0 ||
      data["data.mortgage_rate__lte"]
        ? true
        : false,
    mortgageRate: [
      data["data.mortgage_rate__gte"] || dF["mortgageRate"][0],
      data["data.mortgage_rate__lte"] || dF["mortgageRate"][1],
    ],
    requestType: data["solicitor_type"] || dF["requestType"],
    allowFiltersMortgageRemaining:
      data["remaining__gte"] || data["remaining__lte"] ? true : false,
    mortgageRemaining: [
      data["remaining__gte"] || dF["mortgageRemaining"][0],
      data["remaining__lte"] || dF["mortgageRemaining"][1],
    ],
    mortgageFiles: data["uploads"] || dF["mortgageFiles"],
    financialsEntities:
      data["customer_application_situation.financials_entities.nin"] ||
      dF["financialsEntities"],
    propertyExistence:
      data["property_existence__in"] || dF["propertyExistence"],
    mortagagePropertyExistence:
      data["property_existence"] || dF["mortagagePropertyExistence"],

    allowFilterPropertyArea: Boolean(
      data["property.area__gte"] || data["property.area__lte"]
    ),
    propertyAreaRange: [
      data["property.area__gte"] || dF["propertyAreaRange"][0],
      data["property.area__lte"] || dF["propertyAreaRange"][1],
    ],

    constructionType__in:
      data["property.constructionType__in"] || dF["constructionType__in"],
    constructionType: data["constructionType"] || dF["constructionType"],

    allowCurrMortgageInitDate: Boolean(data["data.curr_mortgage_init_date__lte"]),
    "data.curr_mortgage_init_date__lte": data["data.curr_mortgage_init_date__lte"],
    // "data.curr_mortgage_init_date_range": data["data.curr_mortgage_init_date__range"],
    
    allowFilterMonthlyIncome:
      (data["revenue.monthly__gte"] ||
        false ||
        data["revenue.monthly__lte"] ||
        false) === false
        ? false
        : true,
    allowFilterMonthlyExpenses:
      (data["revenue.general.monthly_expenses__gte"] ||
        false ||
        data["revenue.general.monthly_expenses__lte"] ||
        false) === false
        ? false
        : true,

    newMortgage: (data["mortgage.mode"] || 0) === 1,
    changeMortgage: (data["mortgage.mode"] || 0) === 2,
    capitalRaise: (data["mortgage.mode"] || 0) === 3,
    autopromotor: (data["mortgage.mode"] || 0) === 4,

    fixedInterest: (data["data.interest_type"] || "") === "fijo",
    variableInterest: (data["data.interest_type"] || "") === "variable",
    mixedInterest: (data["data.interest_type"] || "") === "mixto",
    totalInterest: (data["data.interest_type"] || "") === "total",

    laboralLife: (data["mortgage.laboralLife"] || "") === "Doc_laboral",
    sScontribution:
      (data["mortgage.sScontribution"] || "") === "Doc_cotizacion",
    rent: dF["rent"],
    CIRBE: dF["CIRBE"],
    AEATDebts: dF["AEATDebts"],
    sSSituation: dF["sSSituation"],
    corporateTax: dF["corporateTax"],

    d347: dF["d347"],
    d111: dF["d111"],
    d130: dF["d130"],
    d190: dF["d190"],
    d303: dF["d303"],
    d390: dF["d390"],
    d200: dF["d200"],
    DNIa: dF["DNIa"],
    DNIr: dF["DNIr"],
    DNIaa: dF["DNIaa"],
    DNIra: dF["DNIra"],
    NS: dF["NS"],
    U3: dF["U3"],
  };
};
