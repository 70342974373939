export const spainComunitiesAndProvices = {
  Andalucía: [
    "Almería",
    "Cádiz",
    "Córdoba",
    "Granada",
    "Huelva",
    "Jaén",
    "Málaga",
    "Sevilla",
  ],
  Aragón: ["Huesca", "Teruel", "Zaragoza"],
  "Principado de Asturias": ["Asturias"],
  "Illes Balears": ["Balears, Illes"],
  Canarias: ["Palmas, Las", "Santa Cruz de Tenerife"],
  Cantabria: ["Cantabria"],
  "Castilla y León": [
    "Ávila",
    "Burgos",
    "León",
    "Palencia",
    "Salamanca",
    "Segovia",
    "Soria",
    "Valladolid",
    "Zamora",
  ],
  "Castilla - La Mancha": [
    "Albacete",
    "Ciudad Real",
    "Cuenca",
    "Guadalajara",
    "Toledo",
  ],
  Cataluña: ["Barcelona", "Girona", "Lleida", "Tarragona"],
  "Comunitat Valenciana": [
    "Valencia/València",
    "Alicante/Alacant",
    "Castellón/Castelló",
  ],
  Extremadura: ["Badajoz", "Cáceres"],
  Galicia: ["Coruña, A", "Lugo", "Ourense", "Pontevedra"],
  "Comunidad de Madrid": ["Madrid"],
  "Región de Murcia": ["Murcia"],
  "Comunidad Foral de Navarra": ["Navarra"],
  "País Vasco": ["Araba/Álava", "Bizkaia", "Gipuzkoa"],
  "La Rioja": ["Rioja, La"],
  Ceuta: ["Ceuta"],
  Melilla: ["Melilla"],
};

export type SpainComunitiesAndProvices = {
  [C in keyof typeof spainComunitiesAndProvices]: typeof spainComunitiesAndProvices[C];
};
