import {
  MAX_DATA_PERIOD_YEARS_INTEREST_FIJO,
  MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
  MAX_MORTGAGE_LAP,
  MIN_DATA_PERIOD_YEARS,
  PROPERTY_VALUE_RANGES,
} from "shared/constants/mortgageForm";
import { YesNoOption } from "shared/types/Enums";
import { EntitiesType } from "shared/types/postMortgageData";
import { getAgeByBirth } from "utils";
import { formatCurrency } from "utils/helpers";
import {
  aboveZeroTest,
  lessThan,
  requiredStringSchema,
  REQ_MSG,
} from "utils/yupValidation";
import * as yup from "yup";
import { MortgageMode, TestContextExtended } from "../../types";
import { getMortgageRatePercentageAllowed, isFuncionario } from "../../utils";

export const amountLTPropertyValueTest: yup.TestConfig<any, any> = {
  name: "amountLTPropertyValue",
  message: "El importe no debe superar el valor actual de la propiedad",
  test: function (val) {
    const { from } = this as yup.TestContext & TestContextExtended;
    const { value } = from[1];

    const mortgageMode = value.mortgage.mode;
    if ([2, 3].includes(mortgageMode)) {
      const propertyValue = Number(value.property.value);

      const solicitorType = value.solicitor_type;
      const userWork = value.user.work;
      const user2Work = value.user_2.work;
      if (mortgageMode === 2) {
        return propertyValue >= Number(val);
      }

      const Funcionario = isFuncionario(solicitorType, userWork, user2Work);
      if (Funcionario) {
        return propertyValue >= Number(val);
      }

      const propertyType = value.property.type;
      const notFuncionarioVal =
        (propertyValue * getMortgageRatePercentageAllowed(propertyType)) / 100;
      if (notFuncionarioVal >= Number(val)) {
        return true;
      }
      return this.createError({
        message: `El importe no debe superar los ${formatCurrency(
          notFuncionarioVal
        )}`,
      });
    }

    return true;
  },
};

export const periodSchema = requiredStringSchema()
  .when("interest_type", {
    is: (val: string) => val === "fijo",
    then: (schema) =>
      schema.test(
        `max${MAX_DATA_PERIOD_YEARS_INTEREST_FIJO}Fijo`,
        `La duración máxima permítida es de ${MAX_DATA_PERIOD_YEARS_INTEREST_FIJO} años para un interés fijo`,
        function (testVal) {
          return Number(testVal) <= MAX_DATA_PERIOD_YEARS_INTEREST_FIJO;
        }
      ),
    otherwise: (schema) =>
      schema.test({
        name: `max${MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO}`,
        message: `La duración máxima permítida es de ${MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO} años`,
        test: (testVal) => {
          return Number(testVal) <= MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO;
        },
      }),
  })
  .test(
    `min${MIN_DATA_PERIOD_YEARS}`,
    `La duración mínima permítida por los bancos es de ${MIN_DATA_PERIOD_YEARS} años.`,
    (testVal) => Number(testVal) >= MIN_DATA_PERIOD_YEARS
  )
  .test(
    "agePlusMortgagePeriod",
    `Su edad sumado al plazo de la hipoteca supera los ${MAX_MORTGAGE_LAP} años.`,
    function (val) {
      const { from } = this as yup.TestContext & TestContextExtended;
      const { value } = from[1];

      const clientAge = getAgeByBirth(value.user.birthdate);
      return Number(val) + clientAge < MAX_MORTGAGE_LAP;
    }
  );

export const stepTwoValidationSchema = (mode: number) => {
  const shouldValidateByMode = [1, 4].includes(Number(mode));
  return yup.object().shape({
    property: yup.object().shape({
      type: requiredStringSchema(),
      value: requiredStringSchema(
        "Ingrese el valor actual de la propiedad"
      ).test(lessThan(PROPERTY_VALUE_RANGES.GLOBAL.MIN)),
      // notasimple: requiredStringSchema(),
      // arras: requiredStringSchema(),
      // reserva: requiredStringSchema(),
    }),
    mortgage_initial: yup.string().when("mortgage.mode", {
      is() {
        return shouldValidateByMode;
      },
      then(schema) {
        return requiredStringSchema("Ingrese un valor")
          .test(aboveZeroTest)
          .test(
            "lt50k",
            (val) =>
              `El importe de hipoteca mínimo a solicitar es de: ${formatCurrency(
                75000
              )}.`,
            (testVal, Context) => {
              const { parent } = Context;
              if (parent.mortgage.mode === 1 && !parent.property.value) {
                return Number(Context.parent.data?.amount) >= 50000;
              }
              return true;
            }
          );
      },
    }),
    property_existence: yup.string().when("mortgage.mode", {
      is() {
        return shouldValidateByMode;
      },
      then() {
        return requiredStringSchema("Seleccione una opción");
      },
    }),
    current_bank: yup.string().when("mortgage.mode", {
      is: () => {
        return shouldValidateByMode;
        // return Number(val) === 1;
      },
      then(schema) {
        return schema.nullable();
      },
      otherwise(schema) {
        return schema.required(REQ_MSG).nullable();
      },
    }),
    data: yup.object().shape({
      interest_type: requiredStringSchema(),
      amount: requiredStringSchema("Ingrese un valor")
        .test(lessThan(PROPERTY_VALUE_RANGES.GLOBAL.MIN))
        .test(amountLTPropertyValueTest),
      pending_amount: yup.string().when("dummy", {
        is() {
          return !shouldValidateByMode;
        },
        then(schema) {
          return schema
            .required("Ingrese un valor")
            .nullable()
            .test(lessThan())
            .test(aboveZeroTest)
            .test(lessThan())
            .test(amountLTPropertyValueTest);
        },
      }),
      period: periodSchema,
      interest_type_remortgage: yup.string().when("dummy", {
        is() {
          return !shouldValidateByMode;
        },
        then(schema) {
          return schema.required(REQ_MSG).nullable();
        },
      }),

      interest_rate: yup
        .string()
        .nullable()
        .when("dummy", {
          is() {
            return !shouldValidateByMode;
          },
          then(schema) {
            return schema
              .required("Ingrese un valor")
              .nullable()
              .test(aboveZeroTest);
          },
        }),
      // interest_rate: requiredStringSchema().test(aboveZeroTest),
      remortgage_laps_years: yup.string().when("dummy", {
        is() {
          return !shouldValidateByMode;
        },
        then(schema) {
          return schema
            .required("Ingrese un valor")
            .nullable()
            .test({
              name: "max35",
              message: "La duración máxima es de 35 años",
              test: (testVal) => Number(testVal) <= 35,
            });
        },
      }),
      mortgage_rate: requiredStringSchema().test(
        "allowed",
        "El importe supera el porcentaje máximo permitido",
        function (val) {
          const { from } = this as yup.TestContext & TestContextExtended;
          const { value } = from[1];

          const mortgageMode = value.mortgage.mode;
          if (mortgageMode === 2) {
            return Number(val) <= 100;
          }
          const solicitorType = value.solicitor_type;
          const userWork = value.user.work;
          const user2Work = value.user_2.work;
          const Funcionario = isFuncionario(solicitorType, userWork, user2Work);
          if (Funcionario) {
            return Number(val) <= 100;
          }

          const propertyType = value.property.type;
          return Number(val) <= getMortgageRatePercentageAllowed(propertyType);
        }
      ),
      fund_used_for: yup
        .string()
        .nullable()
        .when("mortgage.mode", {
          is() {
            return mode === MortgageMode.CAPITAL_RAISE;
          },
          then(schema) {
            return requiredStringSchema();
          },
        }),
    }),
    customer_application_situation: yup.object().shape({
      other_active_applications: requiredStringSchema("Seleccione una opción"),
      types_entities: yup.array().when("other_active_applications", {
        is(val: any) {
          return val === YesNoOption.Sí.toString();
        },
        then(schema) {
          return schema.min(1, "Por favor selecciona una opcion");
        },
      }),
      financials_entities: yup.array().when("types_entities", {
        is(val: string[]) {
          return val && val.includes(EntitiesType.BANKS.toString());
        },
        then(schema) {
          return schema.when("types_entities", {
            is(val: any) {
              return (
                val.includes(EntitiesType.BANKS.toString()) &&
                val.includes(EntitiesType.BROKERS.toString())
              );
            },
            then(schema) {
              return schema.min(
                2,
                "Por favor selecciona otra entidad con la que estés trabajando"
              );
            },
            otherwise(schema) {
              return schema.min(1, "Por favor selecciona una opción");
            },
          });
        },
      }),
    }),
  });
};
