import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";
import { axiosWithoutResInterceptor as axios } from "utils/axios";

interface RedisBaseApi<T> {
  key: string;
  payload: T;
}

interface DataStorageWithDayExpire<T> extends RedisBaseApi<T> {
  days_expire?: number;
}

const getData = <T>(k: string, signal?: AbortSignal) => {
  return axios.get<BaseApiSuccessRes<RedisBaseApi<T>>>(
    `${process.env.REACT_APP_API_URL}/datastore/get`,
    {
      signal,
      params: {
        k,
      },
    }
  );
  // .then((res) => {

  //   //before v1 was a string of object so it need to parse it
  //   //if v2 is stable this promise then can be removed without fear 🙃

  //   const payload = res.data.results.payload;
  //   res.data.results.payload =
  //     typeof payload === "string" ? JSON.parse(payload) : payload;
  //   return res;
  // });
};

const setData = <T>(
  data: DataStorageWithDayExpire<T>,
  signal?: AbortSignal
) => {
  return axios.put<BaseApiSuccessRes<RedisBaseApi<T>>>(
    `${process.env.REACT_APP_API_URL}/datastore/set`,
    data,
    { signal }
  );
};
const delData = (data: { key: string }) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/datastore/del`, {
    data,
  });
};

export default {
  getData,
  setData,
  delData,
};
