import * as yup from "yup";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import FormFields from "shared/components/FormFields";
import { useBanks } from "shared/hooks";
import { OTHER_BROKER } from "shared/hooks/useBanks";
import { YesNoOption } from "shared/types/Enums";
import { EntitiesType, MortgageFormData } from "shared/types/postMortgageData";
import { yesNoOption } from "utils/valueLabelOptions";
import { yupResolver } from "@hookform/resolvers/yup";
import { requiredStringSchema } from "utils/yupValidation";
import useMortgageFormContext from "shared/components/MortgageForm/hooks/useMortgageFormContext";
import StepLayout from "../../StepLayout";

function CustomerAppSituationForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  const mortgageMode = mortgageFormData.mortgage.mode;
  const title = [1].includes(mortgageMode)
    ? "¿Has solicitado ya la hipoteca con otros bancos o hay bancos de los que no deseas recibir ofertas?"
    : "¿Has solicitado ya la nueva hipoteca con otras entidades?";

  return (
    <StepLayout
      title={title}
      resolver={yupResolver(
        yup.object({
          customer_application_situation: yup.object({
            other_active_applications: requiredStringSchema(
              "Por favor seleccione una opción"
            ),
            types_entities: yup.array().when("other_active_applications", {
              is(val: any) {
                return val === YesNoOption.Sí.toString();
              },
              then(schema) {
                return schema.min(1, "Por favor selecciona una opcion");
              },
            }),
            financials_entities: yup.array().when("types_entities", {
              is(val: string[]) {
                return val && val.includes(EntitiesType.BANKS.toString());
              },
              then(schema) {
                return schema.when("types_entities", {
                  is(val: any) {
                    return (
                      val.includes(EntitiesType.BANKS.toString()) &&
                      val.includes(EntitiesType.BROKERS.toString())
                    );
                  },
                  then(schema) {
                    return schema.min(
                      2,
                      "Por favor selecciona otra entidad con la que estés trabajando"
                    );
                  },
                  otherwise(schema) {
                    return schema.min(1, "Por favor selecciona una opción");
                  },
                });
              },
            }),
          }),
        })
      )}
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
    >
      <CustomerAppSituationFormHandler />
    </StepLayout>
  );
}

function CustomerAppSituationFormHandler() {
  const { onNext } = useMortgageFormContext();
  const { banks, addBroker, removeBroker } = useBanks();
  const { watch, setValue, getValues } = useFormContext();
  const [otherActiveApplications, typesEntities, financialsEntities] = watch([
    "customer_application_situation.other_active_applications",
    "customer_application_situation.types_entities",
    "customer_application_situation.financials_entities",
  ]);

  const handleClick = (form?: MortgageFormData) => {
    const val = getValues(
      "customer_application_situation.other_active_applications"
    );
    if (val === YesNoOption.No.toString()) {
      if (typesEntities.length > 0) {
        setValue("customer_application_situation.types_entities", [], {
          shouldValidate: true,
        });
      }
      if (financialsEntities.length > 0) {
        setValue("customer_application_situation.financials_entities", [], {
          shouldValidate: true,
        });
      }
      onNext(form);
    }
  };

  useEffect(() => {
    const isBroker = typesEntities.includes(String(EntitiesType.BROKERS));
    const isBanks = typesEntities.includes(String(EntitiesType.BANKS));
    const isFinancialHasBroker = financialsEntities.includes(OTHER_BROKER.name);

    if (!isBanks && isBroker && !isFinancialHasBroker) {
      setValue(
        "customer_application_situation.financials_entities",
        [OTHER_BROKER.name],
        { shouldValidate: true }
      );
      return;
    }

    if (!isBanks && financialsEntities.length > 1) {
      const newArr = financialsEntities.filter(
        (e: string) => e === OTHER_BROKER.name
      );
      setValue("customer_application_situation.financials_entities", newArr, {
        shouldValidate: true,
      });
      return;
    }

    if (isBroker) {
      addBroker();
    } else {
      removeBroker();
    }

    if (isBroker && !isFinancialHasBroker) {
      const newArr = [...financialsEntities];
      newArr.push(OTHER_BROKER.name);
      setValue("customer_application_situation.financials_entities", newArr, {
        shouldValidate: true,
      });
    }

    if (!isBroker && isFinancialHasBroker) {
      const newArr = financialsEntities.filter(
        (e: string) => e !== OTHER_BROKER.name
      );
      setValue("customer_application_situation.financials_entities", newArr, {
        shouldValidate: true,
      });
    }
  }, [typesEntities, financialsEntities]);
  return (
    <>
      <FormFields.ListSelector
        name="customer_application_situation.other_active_applications"
        options={yesNoOption}
        onClick={handleClick}
      />
      {otherActiveApplications === YesNoOption.Sí.toString() && (
        <Stack height="100%" alignItems="center" gap="1rem">
          <FormFields.MultiCheckboxInput
            formControlLabelProps={{
              sx: {
                paddingRight: "1rem",
                borderRadius: "4px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                boxSizing: "border-box",
                transition:
                  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              },
            }}
            formGroupProps={{
              sx: { justifyContent: "space-between" },
            }}
            label="¿Con qué entidades estás trabajando?"
            name="customer_application_situation.types_entities"
            checkboxOptions={[
              {
                value: EntitiesType.BANKS.toString(),
                label: "Bancos",
              },
              {
                value: EntitiesType.BROKERS.toString(),
                label: "Brókers",
              },
            ]}
          />
          {typesEntities.includes(EntitiesType.BANKS.toString()) && (
            <Stack gap="1rem">
              <Typography>
                Entidades donde ya has realizado una solicitud de hipoteca
              </Typography>
              <FormFields.AutoCompleteInput
                // label="Bancos donde ya he realizado una solicitud de hipoteca"
                name="customer_application_situation.financials_entities"
                options={banks.map((b: { name: string }) => b.name)}
                disablePortal
                multiple
                limitTags={3}
                id="banks-box"
              />
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
}

export default CustomerAppSituationForm;
