import React, { ReactNode } from "react";

import SaveAsIcon from "@mui/icons-material/SaveAs";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Typography } from "@mui/material";

interface StepTimeline {
  label: string | ReactNode | undefined;
  caption: string | ReactNode;
  icon?: ReactNode;
  alertTitle?: string | ReactNode;
}
const defaultSteps: StepTimeline[] = [
  {
    label: "Subasta activa",
    caption: (
      <span>
        Durante <ColibidText>un día natural</ColibidText> verás en tu panel
        principal cómo bancos y brókers autorizados te envían las mejores
        ofertas. <mark>¡Son ofertas reales!</mark> no somos un comparador. Te
        recordamos que <BoldText> la subasta es ciega</BoldText>: no podrás ver
        quién te hace la oferta ni ellos tus datos personales, sólo los
        financieros.
      </span>
    ),
    icon: <SaveAsIcon />,
  },
  {
    label: "Subasta cerrada",
    caption: (
      <span>
        Al finalizar el día de subasta, ya no recibirás más ofertas. Luego
        tendrás otros <ColibidText>3 días naturales</ColibidText> para aceptar
        la oferta que más te cuadre.
      </span>
    ),
    icon: <SavedSearchIcon />,
  },
  {
    label: "Oferta aceptada",
    caption: (
      <span>
        ¡Has elegido la oferta ideal para ti! Ahora tendrás{" "}
        <ColibidText>4 días naturales</ColibidText>{" "}
        <mark>para cargar la documentación requerida.</mark> ¡Apúrate para que
        el mercado no haga cambiar las tasas!
      </span>
    ),
    icon: <LockOpenIcon />,
  },
  {
    label: "Carga y verificación de Documentos",
    caption: (
      <span>
        Tras cargar la documentación requerida, nuestro equipo experto validará
        que concuerde con los datos de la solicitud. Si todo está correcto, el{" "}
        <BoldText>
          nombre del ofertante elegido aparecerá en la plataforma.
        </BoldText>{" "}
        Este <mark>se pondrá en contacto contigo en los siguientes días.</mark>{" "}
        Si hay alguna discrepancia, te contactaremos para resolverlo.
      </span>
    ),
    icon: <LockIcon />,
  },
];

const defaultStepsCompleted: StepTimeline[] = [
  {
    label: "Contactado",
    caption:
      "Llamada telefónica para confirmar los datos y explicar las condiciones de la oferta realizada. En esta llamada es donde se puedes detallar tu situación con mayor detalle si hay algo que no pudieras reflejar en la solicitud. ",
    icon: <div></div>,
  },
  {
    label: "Viable",
    caption: "La documentación es correcta y se fijan las condiciones",
    icon: <div></div>,
  },
  {
    label: "Nota de encargo",
    caption:
      "Tras la llamada de contacto y con las condiciones acordadas, el asesor expide un documento que deberás firmar, donde se compromete a conseguir las condiciones ofertadas, e incluirá sus honorarios (si hubiera) y en qué momento los cobrará. ",
    icon: <div></div>,
  },
  {
    label: "Operación preaprobada",
    caption:
      "El banco ha recibido todos los datos (perfil financiero y datos de la vivienda), y después de analizar el caso, da el visto bueno, a falta de la confirmación del valor de la propiedad por parte de la tasadora.",
    icon: <div></div>,
  },
  {
    label: "Tasación ",
    caption:
      "Una vez solicitada la tasación, un tasador irá a la vivienda y emitirá un informe, que servirá para que el banco confirme la operación y prepare la FEIN. ",
    icon: <div></div>,
  },
  {
    label: "FEIN",
    caption:
      "Documento que emite el banco, donde se recoge toda la información relevante del préstamo hipotecario. Este documento no tiene carácter vinculante para el cliente, pero sí para el banco. Deberás devolverlo firmado. ",
    icon: <div></div>,
  },
  {
    label: "Notaría",
    caption:
      "Es la última etapa del proceso, tendrás que acudir físicamente a tu notaría para la lectura de la FEIN y posterior firma. ",
    icon: <div></div>,
  },
  {
    label: "Cerrado",
    caption: "Ahora sí, ¡Tu casa es tuya! ¡y tu hipoteca, también! Felicidades",
    icon: <div></div>,
  },
  // { label: "Viable", caption: "", icon: <div></div> },
  // { label: "Cerrado", caption: "", icon: <div></div> },
];
const road = [
  "",
  "created",
  "in_verification",
  "active",
  "closed",
  "in_verification_accepted",
  "closed_successfully",
];

const getStepLines = (data: Array<any>) => {
  var resSteps;
  var lastStepResult = "";
  if (data.length > 0) {
    resSteps = data.map((item: any, index: number) => {
      let res = {
        label: item?.step?.label,
        caption: item?.step.description ? item?.step.description : "",
        icon: <div></div>,
        alertTitle: item.step.description,
      };

      if (item.value !== "" && item.value !== undefined) {
        lastStepResult = res.label;
      }

      return res;
    });
  } else {
    resSteps = defaultStepsCompleted;
  }

  return { resSteps, lastStepResult };
};

export { defaultSteps, road, defaultStepsCompleted, getStepLines };
export type { StepTimeline };

function BoldText({ children }: { children: ReactNode }) {
  return (
    <Typography component="span" fontWeight={700} fontSize="0.8rem">
      {children}
    </Typography>
  );
}
function ColibidText({ children }: { children: ReactNode }) {
  return (
    <Typography component="span" fontSize="0.8rem" color="primary">
      {children}
    </Typography>
  );
}
