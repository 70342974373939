import {
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
  FormHelperText,
} from "@mui/material";
import UploadFile from "@mui/icons-material/UploadFile";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { uploadFileApi } from "services/api/api.service";
import styles from "./styles.module.scss";
import { useEffect, useRef } from "react";
import { getFilenameFromUrl } from "utils";
import subject, { TOGGLE_LOADER } from "utils/subjects";

type Props = {
  name: string;
  label: string;
  acceptedFile?: string;
  regexToAcceptFile?: RegExp;
  required?: boolean;
};

export default function UploadFileInput({
  name,
  label,
  acceptedFile = ".png, .jpg, .jpeg, .heic, .heif, .pdf",
  regexToAcceptFile = /\.(jpe?g|png|gif|bmp|heic|heif|pdf)$/i,
  required = true,
}: Props) {
  const { control, setValue, getValues, setError, clearErrors } =
    useFormContext();
  const Name = useWatch({ control, name });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const urlNameRef = useRef<string>("");

  useEffect(() => {
    if (Name) {
      urlNameRef.current = Name;
    }
    if (!Name && urlNameRef.current) {
      setValue(name, urlNameRef.current);
    }
  }, [Name]);

  const onClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  let inputLabel: string = Name ? Name : urlNameRef.current || label;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl
            variant="outlined"
            fullWidth
            error={Boolean(error)}
            sx={{
              cursor: "pointer",
              "& input:disabled": {
                cursor: "pointer",
              },
            }}
          >
            <OutlinedInput
              sx={{ cursor: "pointer" }}
              onClick={onClick}
              error={Boolean(error)}
              className={Boolean(error) ? "bodered_error" : ""}
              startAdornment={
                <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                  <InputLabel
                    sx={{ cursor: "pointer" }}
                    htmlFor="outlined-adornment-password"
                    required={required}
                    className={styles.InputLabelTrim}
                  >
                    {getFilenameFromUrl(inputLabel)}
                  </InputLabel>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <input
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      id={name}
                      multiple
                      type="file"
                      accept={acceptedFile}
                      onChange={async (e) => {
                        if (e.target.files && e.target.files[0]) {
                          try {
                            const regexTest = regexToAcceptFile.test(
                              e.target.files[0].name
                            );
                            if (!regexTest) {
                              const e = new Error(
                                `Por favor introduzca archivos con extensiones de tipo ${acceptedFile.replace(
                                  /\./g,
                                  "*."
                                )}`
                              );
                              e.name = "FileType";
                              throw e;
                            }

                            subject.send(TOGGLE_LOADER, true);
                            let url: string = await uploadFileApi(
                              e.target.files[0]
                            );
                            subject.send(TOGGLE_LOADER, false);
                            if (url) {
                              clearErrors(name);
                              field.onChange(url);
                              urlNameRef.current = url;
                            }
                          } catch (err) {
                            subject.send(TOGGLE_LOADER, false);
                            let type = "network-error";
                            let message =
                              "Hay un error en la red, inténtalo mas tarde";

                            if (err instanceof Error) {
                              if (err.name === "FileType") {
                                type = err.message;
                                message = err.message;
                              }
                              setError(name, {
                                type,
                                message,
                              });
                            }
                            field.onChange("");
                          }
                        }
                      }}
                    />
                    <UploadFile />
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
              disabled
            />
            {Boolean(error) && (
              <FormHelperText>{error?.message}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
