import { useCallback } from "react";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";

import { format } from "date-fns";
import { es } from "date-fns/locale";

import { formatCurrency, getBalance, getMonthlyExpense } from "utils/helpers";

import NotResults from "shared/components/NotResults";

import InfoCard, {
  setTableRowsData,
  TableRows,
  TableRowsData,
} from "shared/components/InfoCard";
import { CustomInfoCardProps } from "./types";

import {
  DeepPartialSkipArrayKey,
  FormState,
  UnpackNestedValue,
} from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import { dateFormat } from "utils";
import EditMortgageModalCard from "./EditMortgageModalCard";
import { StepFour } from "shared/components/MortgageForm/components";
import { getTruthyValuesLength } from "../utils";

export default function FamilyEconomyInfoCard({
  data,
  hasTitle = true,
  editable = false,
  formState,
  modalOnClick,
  onNext,
  stepViews,
  showBackgroundColor,
  showFieldLens,
  open,
}: CustomInfoCardProps<MortgageFormData>) {
  const errors = formState?.errors;
  const tableRowsData: TableRowsData = getTableRowData(data, errors);
  const truthyValsLen = getTruthyValuesLength(tableRowsData);
  return hasTitle ? (
    <InfoCard
      title={
        <Typography component={"div"}>
          Ingresos y gastos{" "}
          {showFieldLens && (
            <Chip color="primary" label={`${truthyValsLen} campos`} />
          )}
        </Typography>
      }
      editable={editable}
      open={open}
      {...(showBackgroundColor && { background: "#E5F28733" })}
    >
      <EconomyBreakdown
        data={data}
        formState={formState}
        editable={editable}
        modalOnClick={modalOnClick}
      />
      {editable && stepViews && (
        <EditMortgageModalCard stepViews={stepViews} onClick={modalOnClick} />
      )}
      {editable && onNext && (
        <InfoCard.Modals title="Editar economia familiar">
          {(closeModal) => {
            return (
              <StepFour
                onNext={(form) => {
                  onNext(form);
                  closeModal();
                }}
                defaultValues={data}
              />
            );
          }}
        </InfoCard.Modals>
      )}
    </InfoCard>
  ) : (
    <EconomyBreakdown
      data={data}
      formState={formState}
      editable={editable}
      modalOnClick={modalOnClick}
    />
  );
}

const EconomyBreakdown = ({
  data,
  formState,
  modalOnClick: handleOnNext,
}: CustomInfoCardProps<MortgageFormData>) => {
  const errors = formState?.errors;
  const tableRowsData: TableRowsData = getTableRowData(data, errors);
  return (
    <Stack gap={1}>
      <TableRows
        title="Resumen"
        data={tableRowsData}
        bolds={[
          "Ingresos netos mensuales",
          "Deudas mensuales totales",
          "Balance",
        ]}
      />
      <IncomeBreakdown data={data} formState={formState} />
      <DebtBreakdown data={data} formState={formState} />
    </Stack>
  );
};

const IncomeBreakdown = ({
  data,
  formState,
}: CustomInfoCardProps<MortgageFormData>) => {
  if (data.revenue.incomes_list.length === 0) return null;
  const errors = formState?.errors;
  return (
    <>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        Desglose de ingresos
      </Typography>
      {data.revenue?.incomes_list?.length === 0 && (
        <NotResults
          title="Sin Resultados"
          caption="No se han registrado ingresos"
        />
      )}
      {data.revenue?.incomes_list &&
        data?.revenue?.incomes_list?.length > 0 &&
        data?.revenue?.incomes_list.map((d: any, i: number) => {
          const Solicitante =
            Number(d.solicitor) === 1 ? "Principal" : "Acompañante";
          const incomesListErrors = errors?.revenue?.incomes_list;
          let monthlyIncomeError;
          if (
            incomesListErrors &&
            incomesListErrors[i] &&
            incomesListErrors.length > 0
          ) {
            monthlyIncomeError = incomesListErrors[i].monthly_income?.message;
          }
          return (
            <Box marginLeft={2}>
              <TableRows
                collapseAble
                key={d?.name + Solicitante + i}
                title={d.name || "Título Ingreso"}
                data={{
                  Solicitante,
                  Ingresos: setTableRowsData(
                    formatCurrency(d.monthly_income),
                    monthlyIncomeError
                  ),
                }}
              />
            </Box>
          );
        })}
    </>
  );
};

const DebtBreakdown = ({
  data,
  formState,
}: CustomInfoCardProps<MortgageFormData>) => {
  if (data.revenue.expenses_list.length === 0) return null;
  const errors = formState?.errors;
  return (
    <>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        Desglose de deudas
      </Typography>
      <Divider component="hr" sx={{ margin: "1rem 0" }} />
      {data.revenue?.expenses_list?.length === 0 && (
        <NotResults
          title="Sin Resultados"
          caption="No se han registrado gastos"
        />
      )}
      {data.revenue?.expenses_list &&
        data?.revenue?.expenses_list?.length > 0 &&
        data.revenue.expenses_list.map((d: any, i: number) => {
          const expensesListErrors = errors?.revenue?.expenses_list;
          let debtTotalError;
          let debtMonthlyError;
          let debtDurationError;
          if (
            expensesListErrors &&
            expensesListErrors[i] &&
            expensesListErrors.length > 0
          ) {
            debtTotalError = expensesListErrors[i].debt_total?.message;
            debtMonthlyError = expensesListErrors[i].debt_monthly?.message;
            debtDurationError = expensesListErrors[i].debt_duration?.message;
          }
          return (
            <Box marginLeft={2}>
              <TableRows
                collapseAble
                key={d?.name && d?.debt_monthly && d?.name + d?.debt_monthly}
                title={d.name || "Título Deuda"}
                data={{
                  Solicitante:
                    Number(d.solicitor) === 1 ? "Principal" : "Acompañante",
                  "Deuda total": setTableRowsData(
                    formatCurrency(d.debt_total),
                    debtTotalError
                  ),
                  "Cuota mensual": setTableRowsData(
                    formatCurrency(d.debt_monthly),
                    debtMonthlyError
                  ),
                  "Finalización de deuda": setTableRowsData(
                    format(new Date(d.debt_duration as string), dateFormat, {
                      locale: es,
                    }),
                    debtDurationError
                  ),
                }}
              />
            </Box>
          );
        })}
    </>
  );
};

function getTableRowData(
  data: MortgageFormData,
  errors?: FormState<MortgageFormData>["errors"]
) {
  const solicitoryType = Number(data.solicitor_type);
  const getMonthlyExpenseBySolicitor = ({
    expenses,
    person,
    type,
  }: {
    expenses?: UnpackNestedValue<
      DeepPartialSkipArrayKey<MortgageFormData["revenue"]["expenses_list"]>
    >;
    type?: "monthly" | "total";
    person: 1 | 2;
  }): number => {
    if (!expenses) return 0;
    return expenses
      .filter((e) => {
        const solicitor = Number(e.solicitor);
        return solicitor === person;
      })
      .reduce((a, v) => {
        let total =
          type === "monthly"
            ? v.debt_monthly
              ? v.debt_monthly
              : 0
            : v.debt_total
            ? v.debt_total
            : 0;
        return a + (v.debt_total ? Number(total) : 0);
      }, 0);
  };
  return {
    "Ratio de endeudamiento": data.data?.debtRatio
      ? setTableRowsData(
          String(data.data?.debtRatio) + "%",
          errors?.data?.debtRatio?.message
        )
      : "",
    "Ingreso del solicitante": setTableRowsData(
      formatCurrency(data.user?.salary),
      errors?.user?.salary?.message
    ),
    "Ingreso del acompañante":
      solicitoryType === 2
        ? setTableRowsData(
            formatCurrency(data.user_2?.salary),
            errors?.user_2?.salary?.message
          )
        : "",
    "Ingresos netos mensuales": setTableRowsData(
      formatCurrency(data.revenue?.monthly),
      errors?.revenue?.monthly?.message
    ),
    "Deuda del solicitante": formatCurrency(
      getMonthlyExpenseBySolicitor({
        expenses: data.revenue?.expenses_list,
        person: 1,
        type: "monthly",
      })
    ),
    "Deuda del acompañate":
      solicitoryType === 2
        ? formatCurrency(
            getMonthlyExpenseBySolicitor({
              expenses: data.revenue?.expenses_list,
              person: 2,
              type: "monthly",
            })
          )
        : "",
    "Deudas mensuales totales": formatCurrency(
      getMonthlyExpenseBySolicitor({
        expenses: data.revenue?.expenses_list,
        person: 1,
        type: "monthly",
      }) +
        getMonthlyExpenseBySolicitor({
          expenses: data.revenue?.expenses_list,
          person: 2,
          type: "monthly",
        })
    ),
    Balance: formatCurrency(
      getBalance(
        Number(data.revenue?.monthly),
        getMonthlyExpense(data.revenue?.expenses_list)
      )
    ),
  };
}
