import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Alert, Link } from "@mui/material";
import FormFields from "shared/components/FormFields";
import { TRACE_DATA_LINK } from "shared/constants/links";
import { MortgageFormData } from "shared/types/postMortgageData";
import { HOUSE_SITUATION_UPLOADS, HOUSE_SITUATION_OPTIONS } from "../../utils";
import MortgageCalculatorDialog from "../MortgageCalculatorDialog";
import { useFormContext } from "react-hook-form";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
function HouseSituationForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  const mortgageMode = mortgageFormData.mortgage.mode;
  return (
    <StepLayout
      title="¿Cómo va la búsqueda de tu nuevo hogar?"
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            house_situation: yup.string().when("dummy", {
              is(val: any) {
                return [1, 4].includes(mortgageMode);
              },
              then(schema) {
                return schema
                  .required("Ingrese un valor")
                  .nullable()
                  .test(
                    "noHouseIdentify",
                    "Lo sentimos hasta que no tengas un lugar identificado no podemos activar el proceso de subasta.",
                    (testVal) => testVal !== "1"
                  );
              },
            }),
          }),
        })
      )}
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
    >
      <HouseSituationFormHandler />
    </StepLayout>
  );
}

function HouseSituationFormHandler() {
  const { watch, getValues } = useFormContext<MortgageFormData>();
  const mortgageMode = getValues("mortgage.mode");
  const propertyHouseSituation = watch("property.house_situation");
  const uploadOption = HOUSE_SITUATION_UPLOADS[propertyHouseSituation];
  return (
    <Stack spacing={1} height="100%">
      <FormFields.ListSelector<MortgageFormData>
        name="property.house_situation"
        options={HOUSE_SITUATION_OPTIONS}
      />
      {Number(propertyHouseSituation) === 1 && <MortgageCalculatorDialog />}
      {propertyHouseSituation in HOUSE_SITUATION_UPLOADS && uploadOption && (
        <FormFields.UploadFileInput
          acceptedFile=".pdf"
          regexToAcceptFile={/\.(pdf)$/i}
          required={false}
          key={uploadOption.name}
          name={uploadOption.name}
          label={uploadOption.label}
        />
      )}
      {[1, 4].includes(mortgageMode) && (
        <Link href={TRACE_DATA_LINK} target="_blank" underline="none">
          <Alert severity="info">
            ¿Necesitas la nota simple? puedes obtenerla fácilmente dando clic
            aquí
          </Alert>
        </Link>
      )}
    </Stack>
  );
}

export default HouseSituationForm;
