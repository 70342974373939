import { Box, Button, ButtonProps, Stack, StackProps } from "@mui/material";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import {
  FieldValues,
  Path,
  PathValue,
  useForm,
  useFormContext,
} from "react-hook-form";
import { InputOption, NumericString } from "shared/types/misc";
import FormFields from "..";

type Props<TForm> = {
  stackProps?: StackProps;
  /**
   *  passing a click handler will call handle submit inside 
   * @param form 
   * @returns 
   */
  onClick?: (form?: TForm) => void;
  name: Path<TForm>;
  /**
   *  by default options should be a list of InputOption, but if you pass another
   *  structure, you should set your own getOptionLabel, getOptionValue, isOptionEqualToValue
   */
  options: ReadonlyArray<any | InputOption>;
  /**
   * how many element needed to show the cargar mas button and also affect the layout of the list
   * @default 6
   */
  maxLen?: number;
  /**
   * how many element to show initial
   * @default 6
   */
  initialOptionsToShow?: number;
  loadMoreLabel?: string;
  /**
   * is true will show autocomplete input when the load_more_btn is clicked
   * if any option selected will sort the options and put the selected element at first
   *  @default false
   */
  loadMoreAsAutocomplete?: boolean;
  /**
   *  is a function to get the label of the unkwon structure
   *  eg. if the struc is {a: 1, b:'test'} and b field hold the label
   *  should return option.b
   * @param option each element you pass in the options props
   */
  getOptionLabel?: (option: any) => string;
  /**
   * same as getOptionLabel example but looking for the value
   */
  getOptionValue?: (option: any) => any;
  /**
   *  should pass your fn to check is the selected options is the one that match with the options
   * @param selected is the selected value, is there is, it always the value of the option, not the object itsel, its a primitive!
   * @param value is each element of options props
   * @returns
   */
  isOptionEqualToValue?: (
    selected: PathValue<TForm, Path<TForm>>,
    value: any
  ) => boolean;
  /**
   *  Calls a defined callback function on each element of an array, and returns an array that contains the results.
   *  default will render a list of buttons
   * @param o each element of options
   * @param i index of the element
   * @returns ReactNode
   */

  getValueFromOptions?: (option: any | null) => string;
  renderList?: (props: {
    isSelected: boolean;
    option: any;
    label: string;
    value: any;
    setSelectedOption: () => void;
    selected: PathValue<TForm, Path<TForm>>;
  }) => ReactNode;
};

function ListSelector<TForm extends FieldValues>({
  name,
  options,
  maxLen = 6,
  initialOptionsToShow = 6,
  isOptionEqualToValue,
  getOptionLabel,
  getOptionValue,
  renderList,
  getValueFromOptions,
  onClick,
  loadMoreLabel,
  loadMoreAsAutocomplete,
  stackProps,
  children,
}: PropsWithChildren<Props<TForm>>) {
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [optionLenToShow, setOptionLenToShow] = useState(initialOptionsToShow);
  const [optionsToShow, setOptionsToShow] = useState<any[] | InputOption[]>([
    ...options,
  ]);
  const { setValue, watch, register, formState, getValues, handleSubmit } =
    useFormContext<TForm>();
  const selected = watch(name);
  const isValBig = optionLenToShow > maxLen;

  const selectOption = (value: any) => {
    setValue(name, value, { shouldDirty: true, shouldValidate: true });
  };

  const loadMore = () => {
    if (loadMoreAsAutocomplete) {
      setShowAutocomplete(true);
    } else {
      setOptionLenToShow((prevLen) => prevLen + maxLen);
    }
  };

  const shouldShowMore = options.length > optionLenToShow;
  const _getOptionLabel =
    getOptionLabel ||
    ((o: InputOption) => {
      // mui autocomplete bug when working with options of object
      if (typeof o === "string") {
        return options.find((op) => op.value === o)?.label || "";
      }
      return o.label;
    });
  const _getOptionValue = getOptionValue || ((o: InputOption) => o.value);
  const _isOptionEqualToValue =
    isOptionEqualToValue ||
    ((w: NumericString, o: InputOption) => {
      return w.toString() === o.value;
    });

  const _renderList = (o: any) => {
    let isSelected: boolean = _isOptionEqualToValue(selected, o);
    let label: string = _getOptionLabel(o);
    let value: any = _getOptionValue(o);
    const handleClick = () => {
      selectOption(value);
      const newForm = getValues();
      handleSubmit(
        (form) => {
          onClick?.(form as TForm);
        },
        (err) => {
          console.log("list selector handlesubmit err", err);
        }
      )();
    };

    if (renderList)
      return renderList({
        option: o,
        isSelected,
        label,
        value,
        setSelectedOption: handleClick,
        selected,
      });
    return (
      <Btn
        key={label}
        isSelected={isSelected}
        label={label}
        onClick={handleClick}
      />
    );
  };

  const sortOptionsBySelected = () => {
    const sorted = [...options]
      .sort((a, b) => {
        if ("value" in a) {
          if (a.value === selected) return -1;
          if (b === selected) return 1;
          return 0;
        }
        if (_getOptionValue(a) === selected) return -1;
        if (_getOptionValue(b) === selected) return 1;
        return 0;
      })
      .slice(0);
    setOptionsToShow(sorted);
  };
  useEffect(() => {
    sortOptionsBySelected();
  }, []);

  useEffect(() => {
    register(name);
    loadMoreAsAutocomplete && sortOptionsBySelected();
  }, [name, options, selected]);

  return (
    <Stack
      key={name}
      id={`list-selector-stack-${name}`}
      margin="auto"
      {...(!isValBig && { height: "100%" })}
      width={isValBig ? "50vw" : "50%"}
      direction={isValBig ? "row" : "column"}
      flexWrap={isValBig ? "wrap" : "nowrap"}
      alignItems={isValBig ? "center" : "stretch"}
      paddingTop={isValBig ? "1rem" : 0}
      justifyContent="center"
      gap={1}
      {...stackProps}
    >
      {optionsToShow.slice(0, optionLenToShow).map(_renderList)}
      {shouldShowMore && !showAutocomplete && (
        <Button variant="text" fullWidth onClick={loadMore}>
          {loadMoreLabel || "Cargar mas..."}
        </Button>
      )}
      {loadMoreAsAutocomplete && showAutocomplete && (
        <FormFields.AutoCompleteInput
          options={options}
          name={name}
          label="elige una opción"
          isOptionEqualToValue={_isOptionEqualToValue}
          getOptionLabel={_getOptionLabel}
          getValueFromOptions={getValueFromOptions}
        />
      )}
      {children}
    </Stack>
  );
}

function Btn({
  label,
  onClick,
  isSelected,
}: {
  label: string;
  onClick: () => void;
  isSelected: boolean;
}) {
  return (
    <Button
      key={label}
      variant="contained"
      onClick={onClick}
      // fullWidth
      sx={{
        backgroundColor: isSelected ? "#33CCCC" : "#FFFFFF",
        color: isSelected ? "white" : "black",
        "&:hover": {
          backgroundColor: "#cbcbcb",
          color: "black",
        },
        boxSizing: "border-box",
        fontSize: "calc(10px + (14 - 10) * ((100vw - 320px) / (1600 - 320)))",
      }}
    >
      {label}
    </Button>
  );
}

ListSelector.Btn = Btn;

export default ListSelector;
