import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Autocomplete, Grid, InputAdornment, TextField } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { CurrencyField } from "shared/components";
import { useBanks } from "shared/hooks";
import { ValidationError } from "yup";
import { CrmForm } from ".";
import { COMMNET_MAX_CHAR, UploadableCol } from "../..";

export default function CrmUploadForm({
  form,
  setForm,
  formError,
  setFormError,
  validateForm,
  colId,
  isUserBank
}: {
  form: CrmForm;
  setForm: Dispatch<SetStateAction<CrmForm>>;
  formError: CrmForm;
  setFormError: Dispatch<SetStateAction<CrmForm>>;
  // validateForm: ReturnType<typeof formValidation>;
  validateForm: any;
  colId?: UploadableCol;
  isUserBank?: boolean
}) {

  const { banks } = useBanks();
  const onBlurHandle = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const { name } = e.target;
    validateForm
      .validateAt(name, form)
      .then((res: any) => {
        setFormError((prev) => ({
          ...prev,
          [name]: "",
        }));
      })
      .catch((err: unknown) => {
        if (err instanceof ValidationError) {
          const { message, path } = err;
          setFormError((prev) => ({
            ...prev,
            [name]: message,
          }));
        }
      });
  };
  return (
    <>
      <Grid item xs={12}>
        <TextField
          label="Comentario"
          placeholder={`opcional...`}
          inputProps={{
            maxLength: COMMNET_MAX_CHAR,
          }}
          name="comment"
          value={form.comment}
          helperText={`${form.comment.length}/${COMMNET_MAX_CHAR}`}
          onChange={(e) => {
            const { name, value } = e.target;
            setForm((prev) => ({
              ...prev,
              [name]: value,
            }));
          }}
          fullWidth
          multiline
          rows={3}
        />
      </Grid>
      {colId !== "tasadoraRealizada" && (
        <>
          <Grid item xs={12}>
            <CurrencyField
              label="Importe de hipoteca"
              value={form.amount}
              name="amount"
              onChange={(math: any) => {
                setForm((prev) => ({
                  ...prev,
                  amount: math.value,
                }));
              }}
              onBlur={onBlurHandle}
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              error={Boolean(formError.amount)}
              helperText={formError.amount}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyField
              label="Tasa de Interés"
              value={form.interest_rate}
              name={"interest_rate"}
              onChange={(math: any) => {
                setForm((prev) => ({
                  ...prev,
                  interest_rate: math.value,
                }));
              }}
              onBlur={onBlurHandle}
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              error={Boolean(formError.interest_rate)}
              helperText={formError.interest_rate}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TrendingUpIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {colId === 'notaEncargoFirmada' ? (
              <CurrencyField
                label="Honorarios"
                value={form.consulting_fees}
                name={"consulting_fees"}
                onChange={(math: any) => {
                  setForm((prev) => ({
                    ...prev,
                    consulting_fees: math.value,
                  }));
                }}
                onBlur={onBlurHandle}
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                error={Boolean(formError.consulting_fees)}
                helperText={formError.consulting_fees}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Autocomplete
                options={banks.map((e) => e.name)}
                disabled={isUserBank}
                value={form.bank}
                onChange={(_, data: any) => {
                  setForm((prev) => ({
                    ...prev,
                    bank: data,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Banco"
                    name="bank"
                    size="small"
                    onBlur={onBlurHandle}
                    error={Boolean(formError.bank)}
                    helperText={formError.bank}
                  />
                )}
              />
            )}
          </Grid>
        </>
      )}
    </>
  );
}
