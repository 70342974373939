import {
  TextField,
  FormControl,
  Autocomplete,
  AutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import "./styles.scss";

export default function AutoCompleteInput({
  id = "",
  name = "",
  label,
  renderInput,
  required,
  getValueFromOptions,
  ...rest
}: Omit<AutocompleteProps<any, any, any, any>, "renderInput"> & {
  name?: string;
  label?: string;
  id?: string;
  required?: boolean;
  renderInput?: AutocompleteProps<any, any, any, any>["renderInput"];
  /**
   * when options is a list of object instead of a list of primitive
   * use this method, to extract the value, instead of saving the entire object
   */
  getValueFromOptions?: (option: any | null) => string;
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const fieldWithoutRef = { ...field, ref: undefined };
        const hasError = Boolean(error);
        return (
          <FormControl fullWidth size="small" error={hasError}>
            <Autocomplete
              {...rest}
              {...fieldWithoutRef}
              blurOnSelect
              autoHighlight
              onChange={(e, data: any, r, d) => {
                if(!data) return
                rest.onChange?.(e, data, r, d);
                if (getValueFromOptions) {
                  const gettedVal = getValueFromOptions(data);
                  field.onChange(gettedVal);
                } else {
                  field.onChange(data);
                }
              }}
              renderInput={
                renderInput
                  ? renderInput
                  : (params) => {
                      return (
                        <TextField
                          {...params}
                          required={required}
                          inputRef={field.ref}
                          label={label}
                          size="small"
                          error={hasError}
                          helperText={hasError && error?.message}
                        />
                      );
                    }
              }
            />
          </FormControl>
        );
      }}
    />
  );
}
