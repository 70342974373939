import {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";
import { TopBar, LeftBarMenu, Loader } from "../../components";

import subject, { TOGGLE_LOADER } from "../../../utils/subjects";
import { FloatingWap } from "shared/components/FabGroup";

export interface IMainInfluencerLayoutProps {
  children: JSX.Element;
}

const MainInfluencerLayout: FunctionComponent<IMainInfluencerLayoutProps> = ({
  children,
}): ReactElement<any> => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const toggleLoader = subject
      .listen(TOGGLE_LOADER)
      .subscribe(async ({ value }) => {
        setLoading(value);
      });

    return () => {
      if (toggleLoader) {
        toggleLoader.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      {loading && <Loader />}

      <TopBar allowLeftBarMenu={true} showAuthControls={true} />
      <LeftBarMenu role="influencer" />
      <section id="content" className="main-layout">
        {children}
      </section>
      <FloatingWap />
    </>
  );
};

export default MainInfluencerLayout;
