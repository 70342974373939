const formValidation = (value: any, validations: string): boolean => {
  let cases = validations.split("|");

  let len = cases.length;
  for (let i = 0; i < len; i++) {
    switch (cases[i]) {
      case "required":
        return (
          value === "" || value === undefined || !value || value.length === 0
        );
      case "string":
        return typeof value === "string" || value instanceof String;
      case "number":
        return !isNaN(value);
      default:
        return false;
    }
  }
  return false;
};

const validateTrue = (objectItem: any): boolean => {
  let rs = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, valueItem] of Object.entries(objectItem)) {
    if (typeof valueItem === "object") {
      let value = validateTrue(valueItem);
      if (value && !rs) {
        rs = true;
      }
    } else {
      if (!rs && valueItem) {
        rs = true;
      }
    }
  }

  return rs;
};

const emailValidate = (value: any) => {
  // eslint-disable-next-line no-control-regex
  const emailRegex =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (!emailRegex.test(value)) return true;
  return false;
};

const phoneNumberValidate = (value: any) => {
  var str = value.toString().replace(/\s/g, "");
  let validation = str.length === 9 && /^[679]{1}[0-9]{8}$/.test(str);
  return !validation;
};

const validateDebts = (value: Array<any>) => {
  let sentinel = false;
  value.forEach(function (arrayItem) {
    for (const [key, value] of Object.entries(arrayItem)) {
      if (value === "") {
        sentinel = true;
      }
    }
  });
  return sentinel;
};

export {
  formValidation,
  validateTrue,
  emailValidate,
  phoneNumberValidate,
  validateDebts,
};
