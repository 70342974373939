import { memo, ReactNode } from "react";

import { Grid } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import HandshakeIcon from "@mui/icons-material/Handshake";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import { BidderMetrics } from "shared/types/Crm";
import MetricCard from "../../../../../shared/components/MetricCard";

function BidderMetricCardList({
  bidderMetrics,
}: {
  bidderMetrics: BidderMetrics | undefined;
}) {
  return (
    <Grid
      container
      item
      md={12}
      sm={12}
      xs={12}
      spacing={2}
      columns={{ xs: 6, md: 12 }}
    >
      <Grid container item xs={3} md={3}>
        <MetricCard
          title="Clientes totales"
          value={bidderMetrics?.total}
          icon={<GavelIcon fontSize="large" color="primary" />}
        />
      </Grid>
      <Grid container item xs={3} md={3}>
        <MetricCard
          title="Clientes cerrados"
          value={bidderMetrics?.closed_deals}
          icon={<HandshakeIcon fontSize="large" color="primary" />}
        />
      </Grid>
      <Grid container item xs={3} md={3}>
        <MetricCard
          title="Tiempo medio"
          value={bidderMetrics?.avg_time && `${bidderMetrics?.avg_time} días`}
          icon={<WorkHistoryIcon fontSize="large" color="primary" />}
        />
      </Grid>
      <Grid container item xs={3} md={3}>
        <MetricCard
          title="Tasa de conversión"
          value={
            bidderMetrics?.conversion_rate &&
            `${bidderMetrics?.conversion_rate}%`
          }
          icon={<TrendingUpIcon fontSize="large" color="primary" />}
        />
      </Grid>
    </Grid>
  );
}

const MemoBidderMetricCardList = memo(BidderMetricCardList);

export default MemoBidderMetricCardList;
