import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import FormFields from "shared/components/FormFields";
import { MortgageFormData } from "shared/types/postMortgageData";
import { useFormContext } from "react-hook-form";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { requiredStringSchema } from "utils/yupValidation";
import { yesNoOption } from "utils/valueLabelOptions";
import { YesNoOption } from "shared/types/Enums";
import {
  PROJECT_APPROVED_OPTIONS,
  PROJECT_APPROVED_UPLOADS
} from "../../utils";
function ProjectApproved() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      title="¿Tienes tu proyecto aprobado?"
      resolver={yupResolver(
        yup.object({
          property: yup.object({
            project_approved: yup.object({
              value: requiredStringSchema(),
            }),
          }),
        })
      )}
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
    >
      <ProjectApprovedFormHandler />
    </StepLayout>
  );
}

function ProjectApprovedFormHandler() {
  const { watch, getValues } = useFormContext<MortgageFormData>();
  const [projectApprovedVal, projectApprovedSelected] = watch([
    "property.project_approved.value",
    "property.project_approved.selected_doc_type",
  ]);
  const uploadOption = PROJECT_APPROVED_UPLOADS[projectApprovedSelected];
  return (
    <Stack spacing={1} height="100%">
      <FormFields.ListSelector<MortgageFormData>
        name="property.project_approved.value"
        options={yesNoOption}
      />
      {projectApprovedVal === YesNoOption.Sí.toString() && (
        <Stack gap={2} alignItems="center" paddingBottom={1}>
          <FormFields.ListSelector<MortgageFormData>
            maxLen={2}
            name="property.project_approved.selected_doc_type"
            options={PROJECT_APPROVED_OPTIONS}
          />
          {projectApprovedVal in PROJECT_APPROVED_UPLOADS && uploadOption && (
            <Stack alignItems="center">
              <FormFields.UploadFileInput
                acceptedFile=".pdf"
                regexToAcceptFile={/\.(pdf)$/i}
                required={false}
                key={uploadOption.name}
                name={uploadOption.name}
                label={uploadOption.label}
              />
              <Typography>
                Ejemplo: Nota simple de la parcela o Proyecto de obra visado{" "}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default ProjectApproved;
