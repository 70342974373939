import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import { usePostHog } from "posthog-js/react";

import "./index.scss";
import App from "./App";

import { ThemeProvider } from "@mui/material/styles";
import { mui } from "./themes";
import { PostHogProvider } from "posthog-js/react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieBot from "react-cookiebot";
import { ErrorMonitorInit } from "errors/errorMonitor";

ErrorMonitorInit();

function ErrorHandler(e: any) {
  if (process.env.REACT_APP_ENV === "development") return null;

  window.localStorage.clear();
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  if (process.env.REACT_APP_ENV === "production") {
    window.location.href = "https://app.colibid.com/?error=true";
  } else {
    window.location.href = "https://app.stage.colibid.com/?error=true";
  }
  return null;
}
const { REACT_APP_COOKIE_BOT_ID }: any = process.env;

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  opt_in_site_apps: true, // <--- Add this line
};

ReactDOM.render(
  <React.StrictMode>
    {/* <ErrorBoundary FallbackComponent={ErrorHandler}> */}
    <ThemeProvider theme={mui}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <App />
      </PostHogProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CookieBot domainGroupId={REACT_APP_COOKIE_BOT_ID} />
    </ThemeProvider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>,

  document.getElementById("root")
);
