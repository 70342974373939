export const incomeCardOptions = [
  "Salario neto mensual",
  "Rentas inmobiliarias",
  "Dividendos de acciones",
  "Negocios",
  "Pensión",
  "Otros",
];

export const expensiveCardOptions = [
  "Hipoteca existente",
  "Prestamos personales",
  "Financiaciones",
  "Renta de alquiler",
  "Manutención",
  "Pensión compensatoria",
  "Tarjetas de crédito",
  "Otros",
];
