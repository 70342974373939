import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Avatar,
  CardContent,
  Card,
  Box,
  Button,
  OutlinedInput,
  InputAdornment,
  Divider,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  TableContainer,
  Dialog,
  TextField,
} from "@mui/material";
import QRCode from "react-qr-code";
import { MobileView } from "react-device-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CodeIcon from "@mui/icons-material/Code";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import {
  MortgageStepTimeline,
  ImageButton,
  FastFAQs,
} from "../../../shared/components";

import { DinamycLayout } from "../../../shared/layouts";
import { PageTitle, Breadcrumbs, BannerInfo } from "../../../shared/components";

import { useAuthenticated, useProfile } from "../../../shared/hooks";
import { useLocalStorage } from "../../../shared/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import {
  getReferrals,
  getPaymentMethod,
  putPaymentMethod,
} from "../../../services/api";

import styles from "./styles.module.scss";
import { ImageSearchTwoTone } from "@mui/icons-material";
import { BankDetails } from "../ReferralPay";

const ReferralsHowItWorks: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const navigate = useNavigate();
    const location = useLocation();
    const [role] = useLocalStorage("role");
    const profile = useProfile().profile;
    const [shareURL, setShareURL] = useState("");
    const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
    const [shareMessage, setShareMessage] = useState("Registrate en Colibid");
    const [shareiFrame, setShareiFrame] = useState("");
    const [referral, setReferral] = useState({
      created_mortgage: 0,
      name: "",
      referral: "",
      registered: 0,
      successful_mortgage: 0,
      paid_mortgage: 0,
    });
    const [paymentData, setPaymentData] = useState({
      BIC_SWIFT: "",
      IBAN: "",
      account_holder_name: "",
      identification: "",
      user: "",
    });

    // useAuthenticated((allow) => {
    //   if (!allow) navigate("/sign-in");
    // }, "realestate");

    useEffect(() => {}, []);

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    useEffect(() => {
      setShareURL(`${process.env.REACT_APP_WEB_URL}?r=${referral.referral}`);
      setShareiFrame(
        `<iframe src="${process.env.REACT_APP_API_URL}/embed?r=${referral.referral}" scrolling="no" width="100%" height="130" frameborder="0"></iframe>`
      );
    }, [referral.referral]);

    const getReferralData = () => {
      getReferrals()
        .then((response: any) => {
          setReferral(response.data);
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    };

    const getPaymentMethodData = () => {
      getPaymentMethod()
        .then((response: any) => {
          setPaymentData(response.data);
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    };

    const putPaymentMethodData = (data: object) => {
      putPaymentMethod(data)
        .then((response: any) => {
          getPaymentMethodData();
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
          console.log(e);
        });
    };

    const onDownloadQR = () => {
      const svg = document.getElementById("QRCode") as HTMLImageElement;
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas") as HTMLCanvasElement;
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };

      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    return (
      <DinamycLayout type={role}>
        <Container className={styles.ReferralsWrapper}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
              <Breadcrumbs
                parents={[
                  {
                    href: "/referrals",
                    title: "Mis referidos",
                  },
                ]}
              >
                <Typography color="text.gray">Cómo Funciona</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <BannerInfo img="/assets/img/sources/referal_how_it_works.svg">
            <p>
              Recomiéndanos a tus amigos y conocidos, y cada vez que uno de
              ellos obtenga su hipoteca con Colibid (registrándose a través de
              uno de tus enlaces) ¡acumularás 100€ de recompensa!.
              <br /> <br /> ¡Así de sencillo!
            </p>
          </BannerInfo>

          <Divider component="br" />

          <Grid container>
            <PageTitle title="Cómo funciona" />
          </Grid>
          <Divider component="br" />
          <Grid container spacing={2}>
            <Grid item md={3} sm={12} xs={12}>
              <BoxDescription
                title="Paso 1"
                description="Si conoces a gente que esté buscando hipoteca, o que quiera mejorar la que ya tiene, solo tienes que pedirles que se registren a través de cualquiera de las formas que encontrarás en la sección “Compartir tu enlace."
                imgSrc="/assets/img/sources/referral_step_one.svg"
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <BoxDescription
                title="Paso 2"
                description="Podrás llevar y ver en todo momento cuantas personas se han registrado gracias a tu recomendación, como va el proceso de cierre de hipoteca de tus referidos, y cuantas recompensas has acumulado."
                imgSrc="/assets/img/sources/referral_step_two.svg"
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <BoxDescription
                title="Paso 3"
                widthImg="50%"
                description="Elige el método con el que quieres cobrar tus recompensas, y te ingresaremos el dinero al final de cada mes. Además, podrás acceder a todos los documentos de facturación y descargarlos."
                imgSrc="/assets/img/sources/referral_step_three.svg"
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <BoxDescription
                title="Paso 4"
                description="Ser un buen colega ¡nunca estuvo mejor pagado!"
                imgSrc="/assets/img/sources/referral_step_four.svg"
              />
            </Grid>
          </Grid>

          <Grid>
            <>
              <Divider component="br" /> <Divider component="br" />
              <Divider component="hr" />
              {/* <FastFAQs
                header={true}
                data={[
                  {
                    title: `Más información`,
                    caption: "",
                    content: <p>Esta integración se produce acorde a legislación y con las máximas garantías de seguridad
            y privacidad para el usuario.</p>
                ,
                  },
                ]}
                title="Preguntas frecuentes"
                caption="Encuentre respuestas a las preguntas acerca del proceso de carga de documentos."
              /> */}
            </>
          </Grid>
          <BankDetails
            validateDNI={true}
            show={showBankDetailsModal}
            data={paymentData}
            onSave={(data) => {
              putPaymentMethodData(data);
              setShowBankDetailsModal(false);
              toast.success(
                "Tus datos bancarios se han registrado correctamente."
              );
            }}
            onClose={() => {
              setShowBankDetailsModal(false);
            }}
          />
        </Container>
      </DinamycLayout>
    );
  };

const StatementsList = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Referencia</TableCell>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Descripción</TableCell>
            <TableCell align="center">Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralsHowItWorks;

interface boxDescription {
  title: string;
  description: string;
  imgSrc: string;
  widthImg?: string;
}

const BoxDescription: FunctionComponent<boxDescription> = ({
  title,
  description,
  imgSrc,
  widthImg,
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "1rem",
          boxSizing: "border-box",
        }}
      >
        <img
          src={imgSrc}
          alt={title}
          style={{ width: widthImg ? widthImg : "60%", height: "60%" }}
        />
        <Box>
          <Typography
            sx={{
              margin: "10px 0 ",
              fontWeight: "bold",
            }}
            color="primary"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textAlign: "justify",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
