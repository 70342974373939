import {
  IconButton,
  IconButtonProps,
  InputBase,
  InputBaseProps,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ReactNode } from "react";
import { removeNonNumeric, addSeparator } from "utils/string";

type Props = { name: string; label?: ReactNode; min?: number; max?: number };

//TODO
export default function CounterInput({ name, label, min, max }: Props) {
  const { setValue, control, getFieldState } = useFormContext();
  const watched = useWatch({ control, name });
  const fieldState = getFieldState(name);
  const increment = () => {
    let val = watched ? Number(watched) + 1 : min || 1;
    if (max && val > max) val = max;
    setValue(name, val, {
      shouldValidate: true,
    });
  };
  const decrement = () => {
    let val = Number(watched) - 1;
    if (min && val < min) val = min;
    setValue(name, val, {
      shouldValidate: true,
    });
  };

  const handleChange: InputBaseProps["onChange"] = (e) => {
    let formattedVal = "";
    if (e.target.value === "0" || e.target.value === "") {
      formattedVal = "";
    } else {
      formattedVal = e.target.value;
    }
    setValue(name, Number(removeNonNumeric(formattedVal)), { shouldValidate: true });
  };

  return (
    <Stack alignItems="center">
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <PaperBtn onClick={decrement}>
          <RemoveIcon />
        </PaperBtn>
        <Paper>
          <InputBase
            value={addSeparator(watched)}
            onChange={handleChange}
            inputProps={{ style: { textAlign: "center" } }}
          />
        </Paper>
        <PaperBtn onClick={increment}>
          <AddIcon />
        </PaperBtn>
      </Stack>
      {label && <Typography>{label}</Typography>}
      {fieldState.error && (
        <Typography color="error">{fieldState.error.message}</Typography>
      )}
    </Stack>
  );
}
function PaperBtn({ children, ...props }: IconButtonProps) {
  return (
    <Paper
      component={IconButton}
      elevation={4}
      sx={{ borderRadius: "50%", height: "40px" }}
      {...props}
    >
      {children}
    </Paper>
  );
}
