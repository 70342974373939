export default function Icon({ src }: { src: string }) {
  return (
    <img
      src={src}
      style={{
        width: "calc(30px * 0.4 + 8vw)",
        maxWidth: "60px",
      }}
    />
  );
}
