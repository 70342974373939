import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Modal,
  Paper,
} from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { MainRealEstateLayout, NotAuthLayout } from "../../../shared/layouts";

import styles from "./styles.module.scss";
import { useDialog } from "shared/hooks";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const YouTubeModal: React.FC<Props> = ({ open, closeModal }) => {
  const handleClose = () => {
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
          backgroundColor: "none",
        }}
      >
        <iframe
          width="400px"
          height="500px"
          src="https://www.youtube.com/embed/eIIx00IZW80"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Paper>
    </Modal>
  );
};

const HowWorksColibid: FunctionComponent<{}> = (
  props
): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const { isShowing, closeDialog, openDialog } = useDialog();
  return (
    <MainRealEstateLayout>
      <Container className={styles.Wrapper}>
        <Grid
          container
          className={styles.fullHeight}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={12} xs={12} sm={12}>
            <Typography
              variant="h5"
              display="block"
              textAlign="center"
              className={styles.title}
            >
              Así opera Colibid
            </Typography>
          </Grid>

          <Grid item md={6} xs={12} sm={12}>
            <Grid item xs={12} md={12} className={styles.paragraph}>
              <Typography variant="body1" gutterBottom paragraph={true}>
                No somos un bróker ni un comparador, ¡somos una plataforma de
                <span className={styles.highlight}> subasta hipotecaria</span>!
              </Typography>

              <Typography variant="body1" gutterBottom paragraph={true}>
                ¿Qué significa? que tus clientes al ingresar a nuestra
                plataforma (tras completar el formulario a través vuestro o por
                sus propios medios) estarán participando de la subasta.
              </Typography>

              <Typography variant="body1" gutterBottom paragraph={true}>
                102 partners (Bancos y brókers de toda España autorizados)
                estarán{" "}
                <span className={styles.highlight}> enviando ofertas</span> a
                través de nuestra plataforma,
                <span className={styles.highlight}> compitiendo</span> entre
                ellos, durante 3 días para ganar a tu cliente.
              </Typography>

              <Typography variant="body1" gutterBottom paragraph={true}>
                Luego tu cliente tendrá 3 días para elegir una oferta ganadora y
                otros 4 días para cargar la documentación requerida para avanzar
                en el proceso. El curso a partir de aquí es el ya conocido de
                las hipotecas.
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                color="primary"
                variant="body1"
                gutterBottom
                textAlign="center"
              >
                ¿Aún tienes dudas? En este video te explicamos con más detalle
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} mt="2rem" textAlign="center">
              <Button
                variant="contained"
                onClick={() => {
                  openDialog();
                }}
              >
                Video Explicativo
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <YouTubeModal
          open={isShowing}
          closeModal={() => {
            closeDialog();
          }}
        />
      </Container>
    </MainRealEstateLayout>
  );
};

export default HowWorksColibid;
