import axios from "axios";

interface GeocodingParams {
  country?: string;
  proximity?: string;
  language?: string;
  fuzzyMatch?: boolean;
}

export interface MapBoxGeoLocationRes {
  type: string
  query: string[]
  features: Feature[]
  attribution: string
}

export interface Feature {
  id: string
  type: string
  place_type: string[]
  relevance: number
  properties: Properties
  text_es: string
  place_name_es: string
  text: string
  place_name: string
  center: number[]
  geometry: Geometry
  context: Context[]
}

export interface Properties {
  accuracy?: string
  mapbox_id?: string
  foursquare?: string
  landmark?: boolean
  category?: string
}

export interface Geometry {
  type: string
  coordinates: number[]
}

export interface Context {
  id: string
  mapbox_id: string
  text_es: string
  text: string
  language_es?: string
  language?: string
  wikidata?: string
  short_code?: string
}

const mapBoxKey = process.env.REACT_APP_MAP_BOX_KEY;

const getMapGeocoding = ({
  location,
  params = { country: "es", language: "es", fuzzyMatch: true, proximity: "ip" },
}: {
  location: string;
  params?: GeocodingParams;
}) => {
  const baseUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
  const encodedLocation = encodeURIComponent(location);
  const url = new URL(encodedLocation + ".json", baseUrl);
  if (mapBoxKey) {
    url.searchParams.set("access_token", mapBoxKey);
  }
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key as keyof GeocodingParams];
      value && url.searchParams.set(key, value.toString());
    }
  }
  return axios.get<MapBoxGeoLocationRes>(url.toString());
};

export { getMapGeocoding };
