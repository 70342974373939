import { Stack, StackProps, Theme, useMediaQuery } from "@mui/material";

/**responsive stack
 * - is use flex
 * - flex direction for mobile will be column else row
 */
const RStack = (props: StackProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return <Stack id="RStack" {...props} direction={
    isMobile ? 'column' : 'row'
  }/>;
}

export default RStack