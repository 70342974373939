import { useState, useEffect } from "react";

const useLocalStorage = (key: string, initialValue?: string) => {
  const [currentValue] = useState<string | null | any>(() => {
    try {
      return localStorage.getItem(key)
        ? localStorage.getItem(key)
        : initialValue;
    } catch (error) {
      console.log("error local Storage", error);
      return initialValue
    }
  });

  useEffect(() => {
    if (currentValue) {
      localStorage.setItem(key, currentValue);
    }
  }, [key, currentValue]);

  return [currentValue] as const;
};

export default useLocalStorage;
