import { useRef } from "react";
import { IconButton, InputBase, InputBaseProps, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

interface Props extends InputBaseProps {
  searchState: [string, React.Dispatch<React.SetStateAction<string>>];
  onSearchCb: (query: string) => void;
  onClearInputCb?: (fetch?: boolean) => void;
}

export default function SearchInput({
  onSearchCb,
  onClearInputCb,
  searchState,
  ...inputBaseProps
}: Props) {
  const [search, setSearch] = searchState;

  const timerRef = useRef<number>();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
    const timer = window.setTimeout(() => {
      onSearchCb(e.target.value.toLowerCase());
    }, 500);
    timerRef.current = timer;
  };

  const onClickClearInput = () => {
    setSearch("");
    if (onClearInputCb) {
      onClearInputCb(true);
      return;
    }
  };

  return (
    <Paper
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <InputBase
        {...inputBaseProps}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Buscar Cliente"
        inputProps={{ "aria-label": "buscar cliente" }}
        value={search}
        onChange={onChange}
      />
      {search ? (
        <IconButton
          sx={{ p: "10px" }}
          aria-label="limpiar buscador"
          onClick={onClickClearInput}
        >
          <ClearIcon />
        </IconButton>
      ) : (
        <SearchIcon sx={{ p: "10px" }} />
      )}
    </Paper>
  );
}
