import React, { FunctionComponent, ReactElement } from "react";
import { TablePagination, Paper } from "@mui/material";

export interface IPaginationBarProps {
  count: number;
  rowsPerPage: number;
  page: number;
  pages: number;
  onChange: (p: number) => any;
  onChangeLimit: (l: string) => any;
}
const PaginationBar: FunctionComponent<IPaginationBarProps> = ({
  count,
  rowsPerPage,
  page,
  pages,
  onChange,
  onChangeLimit,
}): ReactElement<any> => {
  return (
    <Paper sx={{ width: "100%" }}>
      <TablePagination
        rowsPerPageOptions={[1, 5, 10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={Number(rowsPerPage)}
        page={page}
        onPageChange={(e, p) => {
          onChange(p);
        }}
        onRowsPerPageChange={({ target }) => {
          onChangeLimit(target.value);
        }}
        labelRowsPerPage={"Elementos por página"}
        labelDisplayedRows={() => {
          return `Página ${page} de ${pages}`;
        }}
        backIconButtonProps={{
          disabled: page <= 1 ? true : false,
        }}
        nextIconButtonProps={{
          disabled: pages > page ? false : true,
        }}
        SelectProps={{
          native: true,
        }}
      />
    </Paper>
  );
};

export default PaginationBar;
