const logStyle = 'background:#222 ; color: yellow'

const info = (...params: any) => {
  if (process.env.REACT_APP_ENV === "development") {
    console.log("%c logger solo aparece en dev ----------------- si ves esto en prod reportalo! 🫠", logStyle);
    console.log(...params)
    console.log("%c -----------------", logStyle);
  }
};

const error = (...params: any) => {
  if (process.env.REACT_APP_ENV === "development") {
    console.error("logger: ", ...params);
    // throw new Error("Form state error - For you Amber ❤️");
  }
};

export default { info, error };
