// export { default as SelectInput } from "./SelectInput";
import SelectInput from "./components/SelectInput";
import TextFieldInput from "./components/TextFieldInput";
import AutoCompleteInput from "./components/AutoCompleteInput";
import DatePicker from "./components/DatePicker";
import PhoneNumberInput from "./components/PhoneNumberInput";
import UploadFileInput from "./components/uploadFileInput";
import {
  TextFieldWithWrapperText,
  CurrencyFieldWithWrapperText,
} from "./components/WithWraperText";
import RadioInput from "./components/RadioInput";
import MultiCheckboxInput from "./components/MultiCheckboxInput";
import ListSelector from "./components/ListSelector";
import WizardFormPage from "./components/WizardFormPage";
import CounterInput from "./components/CounterInput";
import CircularSlider from "./components/CircularSlider";
import CheckboxInput from "./components/CheckboxInput";

const FormFields = {
  SelectInput,
  CheckboxInput,
  CircularSlider,
  CounterInput,
  TextFieldInput,
  AutoCompleteInput,
  DatePicker,
  PhoneNumberInput,
  UploadFileInput,
  TextFieldWithWrapperText,
  CurrencyFieldWithWrapperText,
  RadioInput,
  MultiCheckboxInput,
  ListSelector,
  WizardFormPage,
};

export default FormFields;
