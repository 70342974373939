import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Fab,
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
} from "@mui/material";
import styles from "./styles.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

interface statusInt {
  status_type: "success" | "fail" | null | string;
  status_title: string | null;
  status_message: string | null; // arrow function
  status_code?: string | null;
}

const StatusPage: FunctionComponent<{}> = (): ReactElement<any> => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState<statusInt>({
    status_type: "",
    status_title: "",
    status_message: "",
    status_code: "",
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    status.status_type =
      searchParams.get("type") !== null ? searchParams.get("type") : "";
    status.status_title =
      searchParams.get("title") !== null ? searchParams.get("title") : "";
    status.status_message =
      searchParams.get("message") !== null ? searchParams.get("message") : "";
    status.status_code =
      searchParams.get("code") !== null ? searchParams.get("code") : "";

    setStatus({ ...status });

    localStorage.clear();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      style={{ textAlign: "center" }}
      className={styles.Container}
    >
      <Grid item xs={12} md={6} style={{ textAlign: "left", margin: "0 3%" }}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity={status.status_type === "success" ? "success" : "error"}
            sx={{ marginTop: "1rem" }}
          >
            <AlertTitle>
              {status.status_type === "success" ? "Success" : "Error"}
            </AlertTitle>
            Estado de solicitud: {status.status_code}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={12}>
        <img
          src="/assets/img/logo.svg"
          alt="colibid logo"
          className={styles.logo}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Box className={styles.containerImage}>
          <img
            src="/assets/img/sources/status.svg"
            alt="colibid maintenance"
            width="60%"
          />
        </Box>

        <Divider style={{ margin: "1rem" }} light />
        <Box style={{ padding: "10px" }}>
          <Typography variant="subtitle1" gutterBottom align="center">
            <div className={styles.titleMaintain}>{status.status_title}</div>
            <div
              className={`
                ${styles.titleMessage} 
                ${
                  status.status_type === "success"
                    ? styles.success
                    : styles.failed
                }
              `}
            >
              ¡{status.status_message}!
            </div>
          </Typography>
          <Box
            className={
              status.status_type === "success" ? styles.success : styles.failed
            }
            style={{ marginTop: "10px" }}
          ></Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StatusPage;
