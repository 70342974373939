import FormFields from "shared/components/FormFields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MAX_DATA_PERIOD_YEARS_INTEREST_FIJO,
  MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO,
  MIN_DATA_PERIOD_YEARS,
} from "shared/constants/mortgageForm";
import { Center } from "shared/layouts";
import { useFormContext } from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { periodSchema } from "../StepTwo/validation.schema";
import { Stack, Typography } from "@mui/material";

const MODE: Record<string, string> = {
  1: "hipoteca",
  2: "nueva hipoteca",
  3: "ampliación",
  4: "nueva hipoteca",
};

function DataPeriodForm() {
  const { mortgageFormData, onStepClick } = useMortgageFormContext();
  const mortgageMode = mortgageFormData.mortgage.mode;
  const title = `¿A cuántos años deseas solicitar tu ${
    MODE[mortgageMode.toString()]
  }?`;

  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onStepClick()}
      onBack={onStepClick(false)}
      title={title}
      resolver={yupResolver(
        yup.object({
          data: yup.object({
            period: periodSchema,
          }),
        })
      )}
    >
      <DataPeriodFormHandler />
    </StepLayout>
  );
}

function DataPeriodFormHandler() {
  const { getValues, watch } = useFormContext<MortgageFormData>();
  const DataPeriod = watch("data.period");
  const msg =
    "Por favor ten en cuenta que al solicitar un periodo mayor a 30 años de hipoteca, no recibirás ofertas de tipo fijo.";
  const shouldShowMsg =
    Number(DataPeriod) > MAX_DATA_PERIOD_YEARS_INTEREST_FIJO;
  const dataInterestType = getValues("data.interest_type");

  return (
    <Center>
      <Stack paddingX={3} spacing={2}>
        <FormFields.CounterInput
          name="data.period"
          max={
            dataInterestType === "fijo"
              ? MAX_DATA_PERIOD_YEARS_INTEREST_FIJO
              : MAX_DATA_PERIOD_YEARS_INTEREST_NOT_FIJO
          }
          min={MIN_DATA_PERIOD_YEARS}
          label="Años"
        />
        {shouldShowMsg && <Typography variant="body2">{msg}</Typography>}
      </Stack>
    </Center>
  );
}

export default DataPeriodForm;
