import CS, { CircularSliderProps } from "@fseehawer/react-circular-slider";
import { Button, TextFieldProps, Typography } from "@mui/material";
import { CSSProperties, ReactNode, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import CustomDialog from "shared/components/CustomDialog";
import { useDialog } from "shared/hooks";
import useDebounce from "shared/hooks/useDebounce";
import FormFields from "..";
import "animate.css";

type LabelProps = {
  labelColor?: string;
  labelBottom?: boolean;
  labelFontSize?: string;
  valueFontSize?: string;
  appendToValue?: string;
  prependToValue?: string;
  verticalOffset?: string;
  hideLabelValue?: boolean;
  hideLabel?: boolean;
  renderValue?: ReactNode;
  name: string;
  label: string;
  value: string;
  textFieldProps?: TextFieldProps ;
 maxInput?: number 
};
type Props = {
  name: string;
  labelProps?: LabelProps;
} & CircularSliderProps;

function CircularSlider({ name, labelProps, ...csProps }: Props) {
  const { setValue, watch, register } = useFormContext();
  const debounce = useDebounce(100);

  const handleChange = (val: number) => {
    // const valWithK = val * 1000;
    debounce(() => {
       if (csProps.onChange) {
        // it need to set value separely so circular slider and editablelabel work separetly
        // like setValue(name, val, { shouldValidate: true }) in the parent calling this change event;
        csProps.onChange?.(val);
      } else {
        setValue(name, val, { shouldValidate: true });
      }
     });
  };

  useEffect(() => {
    register(name);
  }, []);
  return (
    <CS
      {...csProps}
      // {...(labelProps && { renderLabelValue: <Label {...labelProps} /> })}
      onChange={handleChange}
    />
  );
}

const EditableLabel = ({
  labelColor,
  labelBottom,
  labelFontSize,
  valueFontSize,
  appendToValue,
  prependToValue,
  verticalOffset,
  hideLabelValue,
  hideLabel,
  name,
  label,
  value,
  textFieldProps,
   maxInput
 }: LabelProps) => {
  const { closeDialog, isShowing, openDialog } = useDialog();
  const styles: Record<string, CSSProperties> = {
    labels: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: `${labelColor}`,
      userSelect: "none",
      zIndex: 2,
    },

    value: {
      fontSize: `${valueFontSize}`,
      position: "relative",
    },

    bottomMargin: {
      marginBottom: `calc(${verticalOffset})`,
    },

    appended: {
      position: "absolute",
      right: "0",
      top: "0",
      transform: "translate(100%, 0)",
    },

    prepended: {
      position: "absolute",
      left: "0",
      top: "0",
      transform: "translate(-100%, 0)",
    },

    hide: {
      display: "none",
    },
  };

  return (
    <>
       <div
        className="animate__animated animate__flash animate__repeat-3"
        style={{ ...styles.labels, ...(hideLabelValue && styles.hide) }}
      >
         {labelBottom ||
          (!Boolean(hideLabel) && (
            <div style={{ fontSize: labelFontSize }}>{label}</div>
          ))}
        <div
          style={{ ...styles.value, ...(!labelBottom && styles.bottomMargin) }}
        >
          <code>
            <span style={styles.prepended}>{prependToValue}</span>
            <Button variant="text" test-id="property.amount" onClick={openDialog}>
              <Typography variant="h3" sx={{ ...styles.value }}>
                {value}
              </Typography>
            </Button>
            <span style={styles.appended}>{appendToValue}</span>
          </code>
        </div>
        {labelBottom && <div style={{ fontSize: labelFontSize }}>{label}</div>}
      </div>
      <CustomDialog onClose={closeDialog} open={isShowing}>
        <FormFields.CurrencyFieldWithWrapperText
          name={name}
          label={label}
           maxInput={maxInput}
          {...textFieldProps}
        />
      </CustomDialog>
    </>
  );
};

CircularSlider.EditableLabel = EditableLabel;

export default CircularSlider;
