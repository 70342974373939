import { YesNoOption } from "shared/types/Enums";
import { NumericString } from "shared/types/misc";

export const yesNoOption = [
  { value: YesNoOption.Sí.toString(), label: "Sí" },
  { value: YesNoOption.No.toString(), label: "No" },
];

export function getYesNoLabel(id?: NumericString) {
  return yesNoOption.find((o) => o.value === id?.toString())?.label;
}

export function isYes(value?: NumericString) {
  return Number(value) === 1;
}
