import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import {
  DeepPartialSkipArrayKey,
  FormState,
  UnpackNestedValue,
} from "react-hook-form";
import InfoCard, {
  TableRows,
  TableRowsData,
  setTableRowsData,
} from "shared/components/InfoCard";
import {
  fundUsedForOptions,
  getPropertyExistenceLabel,
} from "shared/components/MortgageForm/components/StepTwo/utils";
import { Bid } from "shared/types/Bid";
import { MortgageFormData } from "shared/types/postMortgageData";
import {
  formatCurrency,
  getApplicationType,
  getInterestType,
  getLabelFromOptions,
} from "utils/helpers";
import { CAPITAL_RAISE, REMORTGAGE } from "../../MortgageForm/types";
import EditMortgageModalCard from "./EditMortgageModalCard";
import { CustomInfoCardProps } from "./types";
import { getTruthyValuesLength } from "../utils";
import {
  HOUSE_SITUATION_OPTIONS,
  constructionTypeOptions,
  getOptionsLabel,
} from "shared/components/MortgageForm/utils";
import { getYesNoLabel } from "utils/valueLabelOptions";
import { format, parseISO } from "date-fns";

export default function HipotecaInfoCard({
  data,
  hasTitle = true,
  editable = false,
  formState,
  modalOnClick,
  onNext,
  acceptedBid,
  stepViews,
  open,
  showBackgroundColor,
  showFieldLens,
}: CustomInfoCardProps<MortgageFormData>) {
  const headerTableRowsData: TableRowsData = {
    "Tipo de hipoteca": setTableRowsData(
      getApplicationType(data.mortgage?.mode),
      formState?.errors?.mortgage?.mode?.message
    ),
  };
  const oldTableRowsData: TableRowsData = getOldMortgageTableRowsData({
    data,
    formState,
  });
  const newTableRowsData: TableRowsData = getNewMortgageTableRowsData({
    data,
    formState,
    acceptedBid,
  });

  const propertyTableRowsData: TableRowsData = getPropertyDataRows({
    data,
    formState,
  });

  const truthyValsLen = getTruthyValuesLength({
    ...headerTableRowsData,
    ...oldTableRowsData,
    ...newTableRowsData,
    ...propertyTableRowsData,
  });

  return hasTitle ? (
    <InfoCard
      title={
        <Typography component={"div"}>
          Datos de la hipoteca{" "}
          {showFieldLens && (
            <Chip color="primary" label={`${truthyValsLen} campos`} />
          )}
        </Typography>
      }
      editable={editable}
      open={open}
      {...(showBackgroundColor && { background: "#D9F6EF33" })}
    >
      <RenderTableRows
        data={data}
        headerTableRowsData={headerTableRowsData}
        oldTableRowsData={oldTableRowsData}
        newTableRowsData={newTableRowsData}
        propertyTableRowsData={propertyTableRowsData}
        formState={formState}
        acceptedBid={acceptedBid}
      />
      {editable && stepViews && (
        <EditMortgageModalCard stepViews={stepViews} onClick={modalOnClick} />
      )}
    </InfoCard>
  ) : (
    <RenderTableRows
      data={data}
      headerTableRowsData={headerTableRowsData}
      oldTableRowsData={oldTableRowsData}
      newTableRowsData={newTableRowsData}
      propertyTableRowsData={propertyTableRowsData}
      formState={formState}
    />
  );
}

// Utils de Componente y funciones
const RenderTableRows = ({
  data,
  headerTableRowsData,
  oldTableRowsData,
  newTableRowsData,
  propertyTableRowsData,
}: {
  data: UnpackNestedValue<DeepPartialSkipArrayKey<MortgageFormData>>;
  headerTableRowsData: TableRowsData;
  oldTableRowsData: TableRowsData;
  newTableRowsData: TableRowsData;
  propertyTableRowsData: TableRowsData;
  formState?: FormState<MortgageFormData>;
  acceptedBid?: Bid;
}) => {
  const mortgageMode = data.mortgage?.mode;

  if (mortgageMode && [2, 3].includes(mortgageMode)) {
    return (
      <Stack gap={1}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Resumen
        </Typography>
        <Box marginLeft={2}>
          <OldMortgageInfoCard
            tableRowsData={{ ...headerTableRowsData, ...oldTableRowsData }}
          />
          <Divider component="br" />
          <NewMortgageInfoCard
            tableRowsData={newTableRowsData}
            additionalInformation={data}
          />
        </Box>
        <TableRows title={"Propiedad"} data={propertyTableRowsData} />
      </Stack>
    );
  }

  return (
    <Stack gap={1}>
      <TableRows
        title="Resumen"
        data={{
          ...headerTableRowsData,
          ...oldTableRowsData,
          ...newTableRowsData,
        }}
      />
      <TableRows title={"Propiedad"} data={propertyTableRowsData} />
    </Stack>
  );
};

const getOldMortgageTableRowsData = ({
  data,
  formState,
}: {
  data: UnpackNestedValue<DeepPartialSkipArrayKey<MortgageFormData>>;
  formState?: FormState<MortgageFormData>;
}) => {
  const errors = formState?.errors;
  const mortgageMode = data.mortgage?.mode;
  const propertyValLabel =
    mortgageMode === 4
      ? "Presupuesto total del proyecto"
      : mortgageMode === 2
      ? "Valor actual de la propiedad"
      : "Valor de la propiedad";
  const dataAmountLabel =
    mortgageMode === 2
      ? "Importe de la hipoteca firmada"
      : "Importe de la hipoteca";

  const amountProperty =
    mortgageMode === 2
      ? data.data?.curr_original_amount === ""
        ? ""
        : formatCurrency(data.data?.curr_original_amount)
      : data.data?.amount === ""
      ? ""
      : formatCurrency(data.data?.amount);

  const mortgageSignedDate = mortgageMode === 2 ? data.data?.curr_mortgage_init_date
    ? format(parseISO(data.data.curr_mortgage_init_date), "MM/dd/yyyy")
    : "" : "";

  return {
    [propertyValLabel]: data.property?.value
      ? setTableRowsData(
          formatCurrency(data.property?.value),
          errors?.property?.value?.message
        )
      : "",
    [dataAmountLabel]: amountProperty,

    "Mes y año de firma de hipoteca": mortgageSignedDate,
    "Fondos iniciales disponibles": data.mortgage_initial
      ? setTableRowsData(
          formatCurrency(data.mortgage_initial),
          errors?.mortgage_initial?.message
        )
      : "",
    "Plazo de la hipoteca":
      data.mortgage?.mode !== REMORTGAGE && data.data?.period
        ? setTableRowsData(
            `${data.data?.period} años`,
            errors?.data?.period?.message
          )
        : "",
    "Importe pendiente de la hipoteca": data.data?.pending_amount
      ? setTableRowsData(
          formatCurrency(Number(data.data?.pending_amount)),
          errors?.data?.pending_amount?.message
        )
      : "",
    "Banco actual": setTableRowsData(
      data.current_bank,
      errors?.current_bank?.message
    ),
    "Tipo de interés existente": setTableRowsData(
      getInterestType(data.data?.interest_type_remortgage),
      errors?.data?.interest_type_remortgage?.message
    ),
    "Tasa de interés existente": data.data?.interest_rate
      ? setTableRowsData(
          `${data.data?.interest_rate}%`,
          errors?.data?.interest_rate?.message
        )
      : "",

    "Plazo pendiente de hipoteca": data.data?.remortgage_laps_years
      ? setTableRowsData(
          Number(data.data?.remortgage_laps_years) === 0
            ? ""
            : `${data.data?.remortgage_laps_years} Años`,
          errors?.data?.remortgage_laps_years?.message
        )
      : "",
  };
};

const getNewMortgageTableRowsData = ({
  data,
  formState,
  acceptedBid,
}: {
  data: UnpackNestedValue<DeepPartialSkipArrayKey<MortgageFormData>>;
  formState?: FormState<MortgageFormData>;
  acceptedBid?: Bid;
}) => {
  const errors = formState?.errors;
  return {
    "Tipo de interés solicitado": setTableRowsData(
      getInterestType(data.data?.interest_type),
      errors?.data?.interest_type?.message
    ),
    "Tipo de interés condedido":
      acceptedBid && data.data?.interest_type === "todos"
        ? getInterestType(acceptedBid?.interest_data?.mode)
        : "",
    "Plazo de nueva hipoteca":
      (data.mortgage?.mode === REMORTGAGE ||
        data.mortgage?.mode === CAPITAL_RAISE) &&
      data.data?.period
        ? setTableRowsData(
            `${data.data?.period} años`,
            errors?.data?.period?.message
          )
        : "",
    "Porcentaje de hipoteca":
      data.mortgage?.mode !== 3 && data.data?.mortgage_rate
        ? setTableRowsData(
            `${data.data?.mortgage_rate}%`,
            errors?.data?.mortgage_rate?.message
          )
        : "",
    "Hipoteca solicitada con otras entidades": getYesNoLabel(
      data.customer_application_situation?.other_active_applications
    ),
    "Hipoteca ya solicitada con":
      data.customer_application_situation?.financials_entities?.join(", "),
    "Fondos destinados a":
      data.mortgage?.mode === CAPITAL_RAISE && data.data?.fund_used_for
        ? setTableRowsData(
            `${getLabelFromOptions(
              Number(data.data.fund_used_for),
              fundUsedForOptions
            )}`,
            errors?.data?.fund_used_for?.message
          )
        : "",
  };
};

type MortgageInfoCardRows = { tableRowsData: { [x: string]: any } };
const OldMortgageInfoCard = ({ tableRowsData }: MortgageInfoCardRows) => {
  return <TableRows title="Existente" data={tableRowsData} />;
};
interface MortgageInfoCardProps {
  tableRowsData: { [x: string]: any };
  additionalInformation?: UnpackNestedValue<
    DeepPartialSkipArrayKey<MortgageFormData>
  >;
}

const NewMortgageInfoCard: React.FC<MortgageInfoCardProps> = ({
  tableRowsData,
  additionalInformation,
}) => {
  let title = "Solicitante";
  if (additionalInformation?.mortgage?.mode) {
    title = additionalInformation?.mortgage?.mode === 2 ? "" : "Solicitante";
  }

  return <TableRows title={title} data={tableRowsData} />;
};

const getPropertyDataRows = ({
  data,
  formState,
}: {
  data: UnpackNestedValue<DeepPartialSkipArrayKey<MortgageFormData>>;
  formState?: FormState<MortgageFormData>;
}) => {
  const errors = formState?.errors;
  const tableRowsData: TableRowsData = {
    "Tipo de vivienda": setTableRowsData(
      getPropertyExistenceLabel(Number(data.property_existence)),
      errors?.property_existence?.message
    ),
    "Vivienda habitual": setTableRowsData(
      getYesNoLabel(data.property?.type),
      errors?.property?.type?.message
    ),
    Situación: setTableRowsData(
      getOptionsLabel({
        options: HOUSE_SITUATION_OPTIONS,
        value: data.property?.house_situation,
      }),
      errors?.property?.house_situation?.message
    ),
    Comunidad: setTableRowsData(
      data.property?.community,
      errors?.property?.community?.message
    ),
    Provincia: setTableRowsData(
      data.property?.province,
      errors?.property?.province?.message
    ),
    Ciudad: setTableRowsData(
      data.property?.city,
      errors?.property?.city?.message
    ),
    "Código postal": setTableRowsData(
      String(data.property?.zipcode),
      errors?.property?.zipcode?.message
    ),
    Dirección: setTableRowsData(
      data.property?.address,
      errors?.property?.address?.message
    ),
    "Tipo de construcción": setTableRowsData(
      getOptionsLabel({
        options: constructionTypeOptions,
        value: data.property?.constructionType,
      }),
      errors?.property?.constructionType?.message
    ),
    "Area de la propiedad": setTableRowsData(
      data.property?.area && `${data.property.area} m2`,
      errors?.property?.area?.message
    ),
    "Proyecto aprobado": setTableRowsData(
      getYesNoLabel(data.property?.project_approved?.value),
      errors?.property?.project_approved?.value?.message
    ),
  };
  return tableRowsData;
};
