import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Alert,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { NotAuthLayout } from "../../shared/layouts";
import { userHomeURL } from "../../utils/helpers";
import { useLocalStorage } from "../../shared/hooks";
import {
  // resetPassword,
  clientMagicLink,
  emailValidate,
  validateTrue,
} from "../../services/api";

import styles from "./styles.module.scss";

const ResetPassword: FunctionComponent<{}> = (
  props
): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const [bToken] = useLocalStorage("access_token");
  const [role] = useLocalStorage("role");
  const [email, setEmail] = useState("");
  const [hasError, sethasError] = useState(false); // User to display error message
  const [errors, setErrors] = useState({
    email: false,
  });

  useEffect(() => {
    if (bToken !== "") {
      navigate(userHomeURL(role));
    }
  }, [bToken, navigate, role]);

  const validate = () => {
    errors.email = emailValidate(email);

    let err = validateTrue(errors);
    sethasError(err);
    setErrors({ ...errors });
    if (!err) {
      getMagicLink();
      // reset();
    }
  };
// Temporal disable reset password service
  // const reset = () => {
  //   resetPassword({ email })
  //     .then((response) => {
  //       toast.success(
  //         "Se ha solicitado el cambio correctamente, te hemos enviado un correo con las instrucciones"
  //       );
  //       navigate("/sign-in");
  //     })
  //     .catch((e) => {
  //       if (e?.response?.status === 404) {
  //         toast.warning("Este correo electrónico no se encuentra registrado");
  //       } else {
  //         toast.error(
  //           "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
  //         );
  //       }

  //       console.error(e);
  //     });
  // };

  const getMagicLink = () => {
    clientMagicLink({ email })
      .then(() => {
        toast.success(
          "Link de acceso generado exitosamente, te hemos enviado un correo con las instrucciones"
        );
        navigate("/sign-in");
      })
      .catch((e: any) => {
        // Specify the type as AxiosError
        if (e?.response?.status === 404) {
          toast.warning("Este correo electrónico no se encuentra registrado");
        } else {
          toast.error(
            "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔"
          );
        }

        console.error(e);
      });
  };

  return (
    <NotAuthLayout>
      <Container className={styles.LoginWrapper}>
        <Grid container spacing={2} marginTop={2}>
          <Grid item md={4} xs={12} sm={12}></Grid>
          <Grid item md={4} xs={12} sm={12}>
            <Box className={styles.LoginImage}>
              <img
                src="/assets/img/sources/resetpassword.svg"
                alt=""
                width="70%"
              />
            </Box>

            {hasError ? (
              <Box>
                <Alert severity="error">
                  Por favor valida la información ingresada
                </Alert>
                <Divider component="br" />
              </Box>
            ) : null}

            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Correo electrónico"
                  placeholder="tucorreo@gmail.com"
                  variant="outlined"
                  size="small"
                  error={errors.email}
                  helperText={
                    errors.email ? "Por favor ingresa un correo válido" : false
                  }
                  fullWidth
                  onChange={({ target }) => {
                    setEmail(target.value);
                  }}
                  required
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              {/* <Button variant="contained" fullWidth onClick={() => validate()}>
                Recuperar contraseña
              </Button> */}
              <Button variant="contained" fullWidth onClick={() => validate()}>
                Obtener enlace mágico
              </Button>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="caption" align="center">
                ¿Ya tienes cuenta?
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Button variant="text" onClick={() => navigate("/sign-in")}>
                Iniciar sesión
              </Button>
            </Grid>

            <Divider component="br" />
            <Grid item xs={12} md={12}>
              <Link
                className={styles.Recovery}
                underline="none"
                onClick={() => navigate("/sign-up")}
              >
                <Typography variant="caption" align="center">
                  ¿Aún no tienes cuenta?
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </NotAuthLayout>
  );
};

export default ResetPassword;
