import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAuthorizeStatement } from "services/api/statements.services";
import useQuery from "shared/hooks/useQuery";
import { sendPaymentInFormElement } from "utils/payment";

export default function Rpay() {
  const queryP = useQuery();
  const navigate = useNavigate();
  const statementId = queryP.get("statementId");
  useEffect(() => {
    if (statementId) {
      getAuthorizeStatement(statementId)
        .then((res) => {
          sendPaymentInFormElement(res.data.results.request);
        })
        .catch((err) => {
          toast.error(
            "Lo sentimos no hemos podido completar la solicitud en estos momentos, por favor inténtalo más tarde 😔, rpay: " +
              statementId,
          );
        })
        .finally(() => {
          navigate("/");
        });
    }
  }, [statementId]);
  return null;
}
