import {
  Button,
  List,
  ListItem,
  ListItemText,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AlertConfetti } from "shared/components";
import { getImgBaseUrl } from "utils/links";
import { MainBrokerLayout } from "shared/layouts";
import styles from "./styles.module.scss";

const { REACT_APP_COLIBID_IBAN }: any = process.env;

function DirectPayment() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/broker/applications");
  };
  const height = window.innerHeight - 64;

  const listItemsInstructions = [
    `Debes hacer una transferencia bancaria a nuestra cuenta ${REACT_APP_COLIBID_IBAN} con el monto del valor del paquete elegido`,
    "Una vez recibamos la confirmación de la transacción se activarán tus créditos.",
  ];

  return (
    <MainBrokerLayout>
      <Container
        sx={{
          height,
        }}
      >
        <AlertConfetti height={height} />
        <Stack gap={4} alignItems="center">
          <img src={getImgBaseUrl("undraw_winners.svg")} height={320} />
          <Typography variant="h5" color="primary" textAlign="center">
            ¡Solicitaste créditos!
          </Typography>
          <Typography variant="h6" textAlign="center">
            Siguientes pasos
          </Typography>
          <List component="nav" aria-label="Instructions">
            {listItemsInstructions.map((item, index) => (
              <ListItem key={item}>
                <ListItemText
                  primary={
                    <div className={styles.listItemText}>
                      <span className={styles.listItemNumber}>
                        {index + 1}.
                      </span>
                      {item}
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Typography textAlign="center">¡A por esos clientes!</Typography>
          <Button variant="contained" onClick={handleClick}>
            ¡Vamos!
          </Button>
        </Stack>
      </Container>
    </MainBrokerLayout>
  );
}
export default DirectPayment;
