import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { NumberBox } from "pages/RealState/Components";
import React, { useEffect, useState } from "react";
import { CgFileDocument } from "react-icons/cg";
import { toast } from "react-toastify";
import { getActiveAppraisers } from "services/api";
import {
  Tasadora,
  createAppraiserProcess,
  getFileAppraiser,
  getStatusAppraisers,
} from "services/api/doctypes.service";
import { DialogWarning } from "shared/components";
import { WSAPI } from "shared/constants/links";
import { useDialog } from "shared/hooks";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { getTasadoraDescription } from "utils/helpers";
import { EvalWithHighligthText } from "../EvalSection";
import styles from "./styles.module.scss";

interface TasadoraComponentProps {
  // Aquí puedes definir las propiedades que necesite tu componente
  // por ejemplo:
  step: number;
  mortgage: MortgageRes | undefined;
  url?: string;
  variant: "doc" | string;
}

interface InfoInterface {
  tasadaraActives: Tasadora[];
  onClickTasadora: (tasadoraId: string) => void; // arrow function
  variant: string;
}

const generalInfo = () => (
  <EvalWithHighligthText
    head="En este paso es que se confirma mediante un tasador el valor anunciado de la vivienda."
    showIcon
    iconProps={{ color: "#E5F287" }}
  />
);
const WaNumber = process.env.REACT_APP_WHATSAPP_NUMBER_CUSTOMERS;
const InfoSteper = () => {
  return (
    <Grid container marginTop="2.5rem" padding="0 2rem">
      <Grid item md={12} sm={12}>
        <Typography
          className={` ${styles.fontFamily}`}
          sx={{ fontSize: "1.5rem", textAlign: "left" }}
        >
          ¿Cómo funciona? Es sencillo:
        </Typography>
      </Grid>
      <Grid item md={3} sm={12} display="flex" flexDirection="row">
        <NumberBox
          number="01"
          colorNumber="#D8D8F6"
          title="Presupuesto"
          paragraph="Según la tasadora y las características de la vivienda el presupuesto te será brindado online o te contactará un asesor para compartírtelo."
          style={{ padding: "0px" }}
          styleBox={`${styles.fontFamily} ${styles.numberText}`}
          styleTitle={styles.fontFamilyTitleNumber}
        />
      </Grid>
      <Grid item md={3} sm={12}>
        <NumberBox
          number="02"
          colorNumber="#E4F086"
          title="Aceptación y pago"
          paragraph="Si el presupuesto te cuadra, debes abonarlo en la plataforma de la tasadora según el proceso que te indiquen."
          style={{ padding: "0px" }}
          styleBox={`${styles.fontFamily} ${styles.numberText}`}
          styleTitle={styles.fontFamilyTitleNumber}
        />
      </Grid>
      <Grid item md={3} sm={12}>
        <NumberBox
          number="03"
          colorNumber="#01807A"
          title="Tasación"
          paragraph="El tasador experto estará visitando la vivienda según acuerde contigo en un plazo máximo de 3 días."
          style={{ padding: "0px" }}
          styleBox={`${styles.fontFamily} ${styles.numberText}`}
          styleTitle={styles.fontFamilyTitleNumber}
        />
      </Grid>
      <Grid item md={3} sm={12}>
        <NumberBox
          number="04"
          colorNumber="#33CCCC"
          title="Informe"
          paragraph="En máximo 4 días estarás recibiendo vía email y en esta plataforma tu informe de tasación. No te preocupes, nosotros nos encargamos de compartírselo a la entidad para continuar el proceso."
          style={{ padding: "0px" }}
          styleBox={`${styles.fontFamily} ${styles.numberText}`}
          styleTitle={styles.fontFamilyTitleNumber}
        />
      </Grid>

      <Grid item md={12} sm={12}>
        <Typography variant="caption" display="block" gutterBottom>
          ¿Aún tienes dudas? Nos puedes escribir a través del ChatBot o{" "}
          <Link
            className={styles.links}
            href={`${WSAPI}${WaNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Whatsapp
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
interface Title {
  title: string;
  paragraph?: string;
}
const TitleInfo = ({ title, paragraph }: Title) => (
  <Grid
    container
    item
    md={12}
    direction="row"
    display="flex"
    textAlign="center"
    justifyContent="center"
    flexDirection="row"
    alignItems="center"
    marginBottom="1rem"
    marginTop="2rem"
  >
    {title && (
      <Grid item md={12}>
        <Typography
          className={`${styles.hightlight} ${styles.fontFamily}`}
          sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
        >
          {title}
        </Typography>
      </Grid>
    )}

    {paragraph && (
      <>
        <Grid
          item
          md={12}
          className={`${styles.fontFamily} ${styles.paragraph}`}
        >
          <Typography variant="body2">{paragraph}</Typography>
        </Grid>
      </>
    )}
  </Grid>
);

const InfoComponent = ({
  tasadaraActives,
  onClickTasadora,
  variant,
}: InfoInterface) => (
  <>
    {/* TODO: @diegobeltran97 Este componente no carga bien con el skeleton mostrandose a usuarios que no debería por unos breves segundos, si tienen problemas de conexión pueden quedar en una pantalla que no deben ver */}
    <Grid container justifyContent="center" textAlign="center">
      {variant === "doc" ? (
        <TitleInfo
          title="  ¿Aún no tienes la tasación? ¡Te ayudamos a conseguirla!"
          paragraph=" En este punto del proceso aún no es obligatoria, podrías hacerlo más
          adelante si lo deseas. Pero hacerla ahora, te ayudará a acelerar el
          proceso ;)"
        />
      ) : (
        <EvalWithHighligthText
          head="En este paso es que se confirma mediante un tasador el valor anunciado de la vivienda."
          showIcon
          iconProps={{ color: "#E5F287" }}
        />
      )}
    </Grid>
    <Box className={styles.boxStyle}>
      <Grid container spacing={2} padding="1rem" className={styles.fontFamily}>
        <Grid item xs={8}>
          <Typography>Te damos la oportunidad de hacerlo simple.</Typography>
          <Typography className={styles.hightlight}>
            ¡Colibid al rescate!
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid
          item
          xs={12}
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} paddingLeft="1rem">
            {variant === "doc" ? (
              "Puedes adquirir desde aquí una tasación para acelerar el proceso*"
            ) : (
              <>
                Puedes acceder desde aquí a la{" "}
                <span className={styles.bold}>
                  tasadora admitida por tu banco*
                </span>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                margin: "0 0 2rem 0",
                width: "100%",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/point.png"
                  alt=""
                  width="40px"
                />
              </Grid>
              {tasadaraActives.length > 0 && (
                <>
                  {tasadaraActives.map((item: Tasadora) => (
                    <Grid item xs={6} key={item.id}>
                      <Button
                        size="small"
                        variant="contained"
                        // onClick={() => activeTasadoraDialog(item.id)}
                        onClick={() => onClickTasadora(item.id)}
                      >
                        <div>
                          Solicitar Tasación <br></br> a {item.name}
                        </div>
                      </Button>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
            {/* <Grid item xs={4}>
              {tasadaraActives.length > 0 && (
                <>
                  {tasadaraActives.map((item: Tasadora) => (
                    <Grid item xs={10} key={item.id}>
                      <Button
                        size="small"
                        variant="contained"
                        // onClick={() => activeTasadoraDialog(item.id)}
                        onClick={() => onClickTasadora(item.id)}
                      >
                        Solicitar Tasación a {item.name}
                      </Button>
                    </Grid>
                  ))}
                </>
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
    <Typography variant="caption" display="block" gutterBottom>
      *Si bien legalmente los bancos deben aceptar cualquier tasación inscripta
      en el Banco de España, nos aseguramos explícitamente que no vayas a tener
      problemas por la tasadora elegida
    </Typography>
  </>
);

// Component for loading state
const LoadingComponent = () => (
  <>
    <Skeleton
      variant="rectangular"
      sx={{ fontSize: "1rem", marginBottom: "1rem" }}
    />
    <Skeleton variant="rectangular" width={310} height={60} />
  </>
);

const onWaitingComponent = (variant: string) => (
  <>
    <Grid container>
      <TitleInfo title="¡Tasación en curso!" />
    </Grid>
    <Box className={styles.boxStyle}>
      <Grid container spacing={2} padding="2rem">
        <Grid
          item
          xs={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            ¡Parece que tienes tu tasación en curso! En cuanto esté emitido el
            informe, podrás encontrarlo tanto en tu email como aquí en la
            plataforma
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/wait.png"
            width="25%"
            alt="waiting"
          />
        </Grid>
      </Grid>
    </Box>
  </>
);

const resultComponent = (url: any, tasadoraId: string) => {
  const downloadDocument = () => {
    if (tasadoraId) {
      getFileAppraiser(tasadoraId)
        .then((result) => {
          let url = result.data.results.file_url;

          window.open(url, "_blank"); // Open the URL in a new tab
        })
        .catch((e: any) => {
          toast.error("Lo sentimos ha ocurrido un error", e);
          console.log("erroe cliente", e);
        });
    }
  };

  return url ? (
    <Grid container justifyContent="center">
      <TitleInfo title="¡Ya tienes tu tasación disponible!" />
      <EvalWithHighligthText
        head="Puedes consultar tu"
        mid={<Link href={url}>tasación aquí</Link>}
        showIcon
        iconProps={{ color: "#E5F287" }}
      />
    </Grid>
  ) : (
    <>
      <Grid container>
        <TitleInfo title="¡Ya tienes tu tasación disponible!" />
      </Grid>
      <Box
        sx={{
          marginTop: "2rem",
          fontFamily: "Roboto",
          fontWeight: 400,
        }}
      >
        <Grid container padding="2rem">
          <Grid item xs={10} textAlign="center">
            <Typography>
              Puedes consultar tu{" "}
              <span
                className={styles.hightlightOpen}
                onClick={downloadDocument}
              >
                tasación
              </span>{" "}
              aquí
            </Typography>
            <Typography>
              ¡También te debería haber llegado a la casilla de mail!
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CgFileDocument
              size="40px"
              color="#E5F287"
              style={{ minWidth: "fit-content" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

// Constants for steps for better readability
const STEP_CHOOSE_APPRAISER = 1;
const STEP_WAITING = 2;
const STEP_RESULT = 3;
const STEP_RESULT_2 = 6;
const STEP_INFO = 5;

//How it work the process on Tasasdora
// Fist we check if we check if have the document Doc_tasadora
// if we have the document we render the url file to download (STEP_RESULT_2)
// then we check if we have an status waiting in process or in pending we show step waiting or we have to get the aprraisers
// finaly we request the list of apraisers by mortgagge id , and render the list

const TasadoraComponent: React.FC<TasadoraComponentProps> = ({
  step,
  mortgage,
  url,
  variant,
}) => {
  const [actualStep, setActualStep] = useState(step);
  const [helperText, setHelperText] = useState("");
  const [aceptedTerms, setAceptedTerms] = useState(false);
  const { isShowing, toggle } = useDialog();
  const [tasadaraActives, setTasadoraActives] = useState<Array<Tasadora>>([]);
  const [tasadoraId, setTasadoraId] = useState("");
  const [loading, setLoading] = useState(true);
  const [urlFile, setUrlFile] = useState(url);

  useEffect(() => {
    if (mortgage) {
      const isDocumentAlreadyUp = mortgage.files.find(
        (item) => item.file_descriptor === "Doc_tasadora"
      );
      setUrlFile(
        isDocumentAlreadyUp ? isDocumentAlreadyUp.file_url : undefined
      );

      if (urlFile && variant !== "doc") {
        setActualStep(6);
        setLoading(false);
        return;
      }

      if (isDocumentAlreadyUp && variant === "doc") {
        setActualStep(5);
        setLoading(false);
        return;
      }

      const acceptedBid =
        mortgage.bids_broker || mortgage.bids_bank
          ? [...mortgage.bids_broker, ...mortgage.bids_bank].length > 0
            ? [...mortgage.bids_broker, ...mortgage.bids_bank].filter(
                (item) => item.is_accepted
              )
            : []
          : [...mortgage.bids].length > 0
          ? [...mortgage.bids].filter((item) => item.accepted.length > 0)
          : [];

      const accepteddId = acceptedBid.length > 0 ? acceptedBid[0].id : "";

      getStatusAppraisers(mortgage.id)
        .then((res) => {
          let apraiser = res.data.results;

          setTasadoraId(apraiser.id);
          if (
            apraiser.status === "pending" ||
            apraiser.status === "processing"
          ) {
            setActualStep(2);
            getActivePraisers(accepteddId);
          } else if (apraiser.status === "succeeded") {
            setActualStep(3);
            setLoading(false);
          } else {
            getActivePraisers(accepteddId);
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 404) {
            getActivePraisers(accepteddId);
          }
          setLoading(false);
        });
    }
  }, [mortgage]);

  const getActivePraisers = (offertId: string) => {
    if (mortgage) {
      getActiveAppraisers(mortgage.id)
        .then((result) => {
          let data = result.data.results;

          if (data.length === 0 && url === undefined) {
            setActualStep(5);
          }

          if (data.length > 0) {
            setTasadoraActives(data);
          }
          setLoading(false);
        })
        .catch((e: any) => {
          toast.error("Lo sentimos ha ocurrido un error", e);
          setLoading(false);
          console.log("error cliente", e);
        });
    }
  };

  const getLinkTasadora = (tasadoraId: string) => {
    if (mortgage) {
      createAppraiserProcess({
        mortgage_id: mortgage.id,
        appraiser_id: tasadoraId,
      })
        .then((result) => {
          const url = result.data.results.redirect_url;

          if (url) {
            window.open(url, "_blank")?.focus();
            setActualStep(2);
            toggle();
          }
        })
        .catch((e: any) => {
          toast.error("Lo sentimos ha ocurrido un error", e);
          console.log("erroe cliente", e);
        });
    }
  };

  const activeTasadoraDialog = (tasadoraId: string) => {
    setTasadoraId(tasadoraId);
    toggle();
  };

  const handledAgree = () => {
    if (aceptedTerms) {
      getLinkTasadora(tasadoraId);
      return;
    }

    setHelperText("No has aceptado los terminos y condiciones");
  };

  // Separate the render logic based on the step into its own function.
  const renderStepContent = () => {
    if (urlFile && variant !== "doc") {
      return resultComponent(urlFile, tasadoraId);
    } else {
      switch (actualStep) {
        case STEP_CHOOSE_APPRAISER:
          if (tasadaraActives.length > 0 && !loading) {
            return (
              <InfoComponent
                tasadaraActives={tasadaraActives}
                onClickTasadora={activeTasadoraDialog}
                variant={variant}
              />
            );
          }
          break;
        case STEP_WAITING:
          return !loading && onWaitingComponent(variant);
        case STEP_RESULT:
        case STEP_RESULT_2:
          if (!loading && variant !== "doc") {
            return resultComponent(url, tasadoraId);
          }
          break;
        case STEP_INFO:
          if (!loading && variant !== "doc") {
            return generalInfo();
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div>
      {renderStepContent()}

      {loading && <LoadingComponent />}

      <DialogWarning
        title="Estás por continuar el proceso de tasación"
        buttonDisagree="Cancelar"
        buttonAgree="Continuar"
        ShowDialog={isShowing && tasadaraActives.length > 0}
        handledClickAgree={handledAgree}
        handledClickDisagree={() => {
          toggle();
        }}
        otherPropsTitle={{
          sx: {
            color: "#01857A",
            textAlign: "center",
          },
        }}
      >
        <>
          {tasadaraActives &&
            tasadoraId !== "" &&
            tasadaraActives.length > 0 && (
              <>
                <Typography component={"span"} variant="body2" gutterBottom>
                  ¡Buena elección!{" "}
                  {tasadaraActives.find(
                    (tasadora: Tasadora) => tasadora.id === tasadoraId
                  )?.name || ""}{" "}
                  {getTasadoraDescription(
                    tasadaraActives.find(
                      (tasadora: Tasadora) => tasadora.id === tasadoraId
                    )?.name || ""
                  )}
                  <br /> A partir de aquí, continuarás tu proceso directamente
                  con ellos. Una vez tengas la tasación, lo verás actualizado en
                  tu panel principal en la plataforma de{" "}
                  <span className={styles.hightlight}>Colibid</span> como
                  siempre.
                </Typography>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={aceptedTerms}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setAceptedTerms(event.target.checked);
                          if (event.target.checked) setHelperText("");
                        }}
                      />
                    }
                    sx={{
                      fontSize: "20px",
                    }}
                    label={
                      <>
                        <br />
                        <Typography variant="body2" gutterBottom>
                          Acepto compartir mis datos con la tasadora elegida
                          según{" "}
                          <a
                            href="https://colibid.com/terminos-y-condiciones/"
                            target="_blank"
                            className={styles.links}
                          >
                            {" "}
                            Términos y Condiciones
                          </a>{" "}
                        </Typography>
                      </>
                    }
                  />
                </FormGroup>
                <FormHelperText className={styles.error}>
                  {helperText}
                </FormHelperText>
              </>
            )}
        </>
      </DialogWarning>

      {![5, 3, 6].includes(actualStep) &&
        !loading &&
        tasadaraActives.length > 0 && <InfoSteper />}
      {actualStep === 5 && !loading && variant !== "doc" && (
        <>
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="center"
            mt="2rem"
          >
            <img
              src="https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2Fcolibid_logo_bird.svg"
              width={40}
              height={40}
            />
            <Typography>
              ¡Ten activas las notificaciones así no te pierdes los avances!
            </Typography>
          </Stack>
        </>
      )}
    </div>
  );
};

export default TasadoraComponent;
