import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDoctypes } from "services/api";


export default function useDocuments() {
    const [documents, setDocuments] = useState<any>([]);

    useEffect(() => {
        getDoctypes()
            .then((response: any) => {
                let files = response.data.name_docs.filter(
                    (file: any) => file.user_upload && file.can_see === "both"
                );
                setDocuments(files);
            })
            .catch((e: any) => {
                toast.error(
                    "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
                );
                console.log(e);
            });

    }, []);

    const setValueDocumets = (value: any) => {
        setDocuments(value);
    };

    return { documents, setValueDocumets };
}
