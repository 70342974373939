import { Chip, Typography } from "@mui/material";

import InfoCard, {
  setTableRowsData,
  TableRows,
  TableRowsData,
} from "shared/components/InfoCard";
import { CustomInfoCardProps } from "./types";
import {
  constructionTypeOptions,
  getOptionsLabel,
  HOUSE_SITUATION_OPTIONS,
} from "../../MortgageForm/utils";
import { MortgageFormData } from "shared/types/postMortgageData";
import { StepThree } from "shared/components/MortgageForm/components";
import { getPropertyExistenceLabel } from "shared/components/MortgageForm/components/StepTwo/utils";
import { getTruthyValuesLength } from "../utils";

export default function PropertyInfoCard({
  hasTitle = true,
  data,
  editable = false,
  formState,
  onNext,
  showBackgroundColor,
  showFieldLens,
  open,
}: CustomInfoCardProps<MortgageFormData>) {
  const errors = formState?.errors;
  const tableRowsData: TableRowsData = {
    "Tipo de vivienda": setTableRowsData(
      getPropertyExistenceLabel(Number(data.property_existence)),
      errors?.property_existence?.message
    ),
    "Vivienda habitual": setTableRowsData(
      Number(data.property?.type) === 1 ? "Sí" : "No",
      errors?.property?.type?.message
    ),
    Situación: setTableRowsData(
      getOptionsLabel({
        options: HOUSE_SITUATION_OPTIONS,
        value: data.property?.house_situation,
      }),
      errors?.property?.house_situation?.message
    ),
    Comunidad: setTableRowsData(
      data.property?.community,
      errors?.property?.community?.message
    ),
    Provincia: setTableRowsData(
      data.property?.province,
      errors?.property?.province?.message
    ),
    Ciudad: setTableRowsData(
      data.property?.city,
      errors?.property?.city?.message
    ),
    "Código postal": setTableRowsData(
      String(data.property?.zipcode),
      errors?.property?.zipcode?.message
    ),
    Dirección: setTableRowsData(
      data.property?.address,
      errors?.property?.address?.message
    ),
    "Tipo de construcción": setTableRowsData(
      getOptionsLabel({
        options: constructionTypeOptions,
        value: data.property?.constructionType,
      }),
      errors?.property?.constructionType?.message
    ),
    "Area de la propiedad": setTableRowsData(
      data.property?.area && `${data.property.area} m2`,
      errors?.property?.area?.message
    ),
  };

  const truthyValsLen = getTruthyValuesLength(tableRowsData);
  return hasTitle ? (
    <InfoCard
      title={
        <Typography component="div">
          Propiedad{" "}
          {showFieldLens && (
            <Chip color="primary" label={`${truthyValsLen} campos`} />
          )}
        </Typography>
      }
      editable={editable}
      open={open}
    >
      <TableRows data={tableRowsData} />
      {editable && onNext && (
        <EditPropertyInfoCard handleOnNext={onNext} data={data} />
      )}
    </InfoCard>
  ) : (
    <TableRows data={tableRowsData} />
  );
}

const EditPropertyInfoCard = ({
  handleOnNext,
  data,
}: {
  handleOnNext: (form: any) => void;
  data: MortgageFormData;
}) => {
  return (
    <InfoCard.Modals title="Editar propiedad">
      {(closeModal) => {
        return (
          <>
            <StepThree
              onNext={(form) => {
                handleOnNext(form);
                closeModal();
              }}
              defaultValues={data}
            />
          </>
        );
      }}
    </InfoCard.Modals>
  );
};
