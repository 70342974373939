import { useEffect, useMemo } from "react";
import {
  Grid,
  Button,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from "@mui/material";
import { Article, Delete } from "@mui/icons-material";

import { useWatch, useFormContext, UseFieldArrayRemove } from "react-hook-form";
import { format, differenceInMonths } from "date-fns";

import FormFields from "shared/components/FormFields";
import { InputOption } from "shared/types/misc";
import { expensiveCardOptions } from "./utils";

type Props = { index: number; fieldId: string; remove: UseFieldArrayRemove };

export default function ExpenseCard({ index, fieldId, remove }: Props) {
  //   const [expanded, setExpanded] = useState(false);
  const { control, setValue } = useFormContext();
  const [debtMonthly, debtDuration, debtTotal, solicitorType] = useWatch({
    control,
    name: [
      `revenue.expenses_list[${index}].debt_monthly`,
      `revenue.expenses_list[${index}].debt_duration`,
      `revenue.expenses_list[${index}].debt_total`,
      "solicitor_type",
    ],
  });

  useEffect(() => {
    if (solicitorType === "1") {
      setValue(`revenue.expenses_list[${index}].solicitor`, 1);
    }
  }, []);

  useEffect(() => {
    if (Number(debtMonthly) > 0 && debtDuration) {
      const now = new Date();
      const endDate = new Date(debtDuration);
      const initDate = new Date(
        `${format(now, "yyyy-MM")}-${format(endDate, "dd")}`
      );

      const laps = differenceInMonths(endDate, initDate);
      const debt_total = Number(laps || 1) * Number(debtMonthly);
      if (debtTotal !== debt_total) {
        setValue(`revenue.expenses_list[${index}].debt_total`, debt_total);
      }
    }
  }, [debtMonthly, debtDuration]);

  const radioOptions = useMemo(() => {
    const options: InputOption[] = [{ value: 1, label: "Solicitante" }];
    if (solicitorType === "2") {
      options.push({ value: 2, label: "Acompañante" });
    }
    return options;
  }, [solicitorType]);

  return (
    <Grid item xs={12} md={12} key={fieldId} data-cy={`expense-card-${index}`}>
      <Accordion expanded={true}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ color: "#707676" }}
        >
          <Article style={{ marginRight: ".7rem", paddingTop: "6px" }} />{" "}
          <Grid item xs={12} md={12}>
            <FormFields.AutoCompleteInput
              options={expensiveCardOptions}
              name={`revenue.expenses_list[${index}].name`}
              label="Nombre de la deuda"
              placeholder="Coche, préstamo, vacaciones..."
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormFields.RadioInput
                row
                label="Titular"
                name={`revenue.expenses_list[${index}].solicitor`}
                radioOptions={radioOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFields.CurrencyFieldWithWrapperText
                name={`revenue.expenses_list[${index}].debt_monthly`}
                label="Cuota de pago mensual"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormFields.DatePicker
                label=" Fecha prevista de liquidación de la deuda *"
                name={`revenue.expenses_list[${index}].debt_duration`}
                disablePast={true}
              />
            </Grid>

            <Grid item xs={12}>
              <FormFields.CurrencyFieldWithWrapperText
                name={`revenue.expenses_list[${index}].debt_total`}
                label="Deuda total"
                required={false}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  remove(index);
                }}
                startIcon={<Delete />}
              >
                Eliminar
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
