import { Collapse, Stack, Switch, Typography } from "@mui/material";
import { ReactNode } from "react";
import { allowFilterValuesKeys } from "../utils";
import { HStack } from "shared/components/Custom";
import WithTooltip from "shared/components/WithTooltip";

export default function AllowSwitchFilter ({
  title,
  name,
  filters,
  tooltipText,
  onChange,
  children
}: {
  title: ReactNode;
  children: ReactNode;
  name: keyof typeof allowFilterValuesKeys;
  tooltipText: string;
  filters: Record<string, any>;
  onChange: (key: string, value: any, active?: boolean) => void;
}) {
  const allowName = allowFilterValuesKeys[name];
  const showFilter = filters[allowName];
  return (
    <Stack gap={1}>
      <HStack justifyContent="space-between" alignItems="center">
        <WithTooltip
          title={tooltipText}
          position="right"
          justifyContent="flex-end"
        >
          <Typography>{title}</Typography>
        </WithTooltip>
        <Switch
          checked={showFilter}
          onChange={(e, checked) => {
            onChange(allowName, checked);
          }}
        />
      </HStack>
      <Collapse in={showFilter}>
          {children}
      </Collapse>
    </Stack>
  );
}