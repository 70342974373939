import jwt_decode from "jwt-decode";
import ReactGA from "react-ga";
import { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useWatch, useFormContext, Path } from "react-hook-form";
import { getUserFormLabel, getUserFormName } from "../../../utils";
import { MortgageFormData } from "shared/types/postMortgageData";
import { getAgeByBirth, getCookie } from "utils";
import {
  USER_AGE_RANGE,
  WARNING_CLIENT_AGE,
  MIN_DATA_PERIOD_YEARS
} from "shared/constants/mortgageForm";
import FormFields from "shared/components/FormFields";
import {
  Checkbox,
  CheckboxProps,
  Link,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import HandleSolicitorView from "../../HandleSolicitorView";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicPersonalValidation } from "./validation.schema";
import useMortgageFormContext from "shared/components/MortgageForm/hooks/useMortgageFormContext";
import { useLocalStorage, useLocalStorages, useProfile } from "shared/hooks";
import { format } from "date-fns";
import TagManager from "react-gtm-module";
import { toast } from "react-toastify";
import { signUp, sendOptCodeEmail } from "services/api";
import { SignUpAdditional, acceptOptIn, checkEmail } from "services/api/auth.service";
import { sendTagEvent } from "utils/analytics";
import { setTokenSSID } from "utils/axios";
import { useSearchParams } from "react-router-dom";
import StepLayout from "../../StepLayout";
import { typeSpanishDNI } from "utils/helpers";
import { ClientProfile } from "shared/types/Profile";

function BasicPersonalDataForm() {
  const [searchParams] = useSearchParams();
  const { onStepClick, mortgageFormState } = useMortgageFormContext();
  const { mortgageFormData, isTermAccepted } = mortgageFormState;
  // error tal vez manejarse desde yup?, requiere refactor steplayout o usar wizardformpage, termcondition pasar como children comp
  const [termErrorMsg, setTermErrorMsg] = useState("");
  const { profile, isFetching } = useProfile<ClientProfile>(false);
  const [role] = useLocalStorage("role");
  const [isEditingForm] = useLocalStorages("isEditingForm");
  const isAgent = role === "realstate";
  const isUserLogin = Boolean(profile);
  const stepLayoutRef = useRef<ElementRef<typeof StepLayout>>(null);
  const hasOptIn = profile && profile.opt_in;
  const shouldShowTermAndCondt: boolean =
    !isAgent && (!isUserLogin || !hasOptIn);
  // (!isAgent && !hasOptIn) || (!isAgent && !isUserLogin);
  const setErrorImperative = ({message, fieldName}: {message: string, fieldName: Path<MortgageFormData>}) => {
    if (stepLayoutRef.current) {
      stepLayoutRef.current.formMethod.setError(
        "user.email",
        {
          type: "server",
          message,
        },
        { shouldFocus: true }
      );
    }
  };

  const register = (form: MortgageFormData) => {
    const cRef = searchParams.get("r");
    let referrerId = "";
    let refId = localStorage.getItem("ref_id") || "";
    if (cRef !== "" && cRef) {
      refId = cRef;
      referrerId = cRef;
      // setReferrerId(cRef);
      localStorage.setItem("ref_id", cRef || "");
    }
    if (refId !== "") {
      referrerId = refId;
      // setReferrerId(refId);
    }

    ReactGA.event({
      category: "Mortgage Form",
      action: "(Step 0) Entered the registration page",
      value: 0,
    });
    const additional: SignUpAdditional = {
      referral: referrerId,
    };
    const searchParamsFromCookie = new URLSearchParams(getCookie("qp"));
    searchParamsFromCookie.forEach((v, k) => {
      additional[k] = v;
    });

    const email = form.user.email.toLowerCase();
    signUp({
      email,
      firstname: form.user.name.trim(),
      lastname: form.user.surname.trim(),
      password: "",
      phone: form.user.phone,
      additional,
      otp_code: "",
      should_login: true,
    })
      .then((res) => {
        //remove cookie to end tracking
        const { results } = res.data;
        document.cookie = "qp=";

        var username = email;
        if (window.location.search.indexOf("r") !== -1) {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());

          if (params?.r) {
            sendTagEvent({
              event: "page_view",
              page_category: window.location.search,
              page_location: window.location.pathname, // URL
              user_type: role,
              user_registration_date: format(new Date(), "MM/dd/yyyy"),
              user_login_status: "active",
              r: params.r,
            });
          }
        }

        TagManager.dataLayer({
          dataLayer: {
            event: "app_sign_up_ok",
            method: "email",
          },
        });

        if ("access_token" in results) {
          const { access_token } = results;
          let role = "client";
          let home = "/client/mortgage/create?step=0";
          const payload: any = jwt_decode(results.access_token);

          if (payload.resource_access?.bidder) {
            role = "broker";
            home = "/broker/applications";
          }
          setTokenSSID(access_token);

          localStorage.setItem("access_token", access_token);
          localStorage.setItem("role", role);
          localStorage.setItem("name", payload.name);
          localStorage.setItem("email", payload.email);
          localStorage.setItem("username", payload.preferred_username);
          localStorage.setItem("email_verified", payload.email_verified);
        }

        onStepClick()(form);
        sendOptCodeEmail(email);
        toast.success("Registro exitoso. ¡Bienvenido a Colibid! 😄");
        // signIn({ username, password: "" }).then((response: any) => {});
      })
      .catch((e: any) => {
        let message = e?.message;
        if (e.response && e.response?.status === 409) {
          message = "Este correo electrónico ya se encuentra registrado";
          setErrorImperative({ message, fieldName: "user.email" });
        } else if (e.response && e.response?.status === 406) {
          message = "Código de validación inválido";
        } else {
          message =
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔";
        }
        toast.error(message + " " + e.message);
        TagManager.dataLayer({
          dataLayer: {
            event: "app_sign_up_error",
            error_message: message,
          },
        });
      });
  };

  const handleOptIn = (form: MortgageFormData) => {
    acceptOptIn()
      .then((res) => {
        onStepClick()(form);
      })
      .catch((e) => {
        console.error(e);
        toast(e?.message);
      });
  };
  const handleOnNext = (form?: MortgageFormData) => {
    let newForm = form;
    if (form && newForm) {
      newForm.user.type_dni = typeSpanishDNI(form.user.dni);
      newForm.user_2.type_dni = typeSpanishDNI(form.user_2.dni);
    }
    if (shouldShowTermAndCondt && !isTermAccepted) {
      setTermErrorMsg("Por favor lee y acepta los términos y condiciones, así como la política de privacidad");
      return;
    }
    if (!isUserLogin && newForm && isTermAccepted) {
      register(newForm);
      return;
    }
    if (isAgent && newForm && !Boolean(isEditingForm)) {
      // validar si existe correo
      checkEmail(newForm.user.email).then(() => {
        // si existe correo, set error
        setErrorImperative({message: 'Usuario ya existe', fieldName:'user.email'})
      }).catch(() => {
        // no existe correo, pasar a siguiente paso 
        onStepClick()(newForm)
      })
      return;
    }

    if (!isAgent && !hasOptIn && newForm) {
      handleOptIn(newForm);
      return;
    }

    onStepClick()(newForm);
  };
  return (
    <StepLayout
      ref={stepLayoutRef}
      onNext={handleOnNext}
      onBack={onStepClick(false)}
      defaultValues={mortgageFormData}
      title="Empecemos por el principio"
      resolver={yupResolver(basicPersonalValidation)}
      isLogin={!isAgent && !isUserLogin}
    >
      {isFetching ? (
        <FormSkeleton />
      ) : (
        <BasicPersonalDataFormHandler
          shouldShowTermAndCondt={shouldShowTermAndCondt}
          errorMsg={termErrorMsg}
        />
      )}
    </StepLayout>
  );
}

function BasicPersonalDataFormHandler({
  shouldShowTermAndCondt,
  errorMsg,
}: {
  shouldShowTermAndCondt: boolean;
  errorMsg: string;
}) {
  const methods = useFormContext<MortgageFormData>();
  const { control, getValues } = methods;
  const [solicitorType, userWork, user2Work, userBirthDate, userBirthDate2] =
    useWatch({
      control,
      name: [
        "solicitor_type",
        "user.work",
        "user_2.work",
        "user.birthdate",
        "user_2.birthdate",
      ],
    });

  const [disableNext, setDisableNext] = useState(false);
  const [hasUnemployConjuntoMsgShow, setHasUnemployConjuntoMsgShow] =
    useState(false);
  const [clientAges, setClientAges] = useState({
    clientAge: 0,
    clientAge2: 0,
  });

  useEffect(() => {
    if (userBirthDate) {
      const clientAge = getAgeByBirth(userBirthDate);
      setClientAges((prevAges) => ({ ...prevAges, clientAge }));
    }
    if (userBirthDate2) {
      const clientAge2 = getAgeByBirth(userBirthDate2);
      setClientAges((prevAges) => ({ ...prevAges, clientAge2 }));
    }
  }, [userBirthDate, userBirthDate2]);

  const currentYear = new Date().getFullYear();

  const MaxClientAgeAlert = useCallback((age: number, dataPeriod: number) => {
    dataPeriod = MIN_DATA_PERIOD_YEARS;
    Swal.fire({
      title: "Importante",
      html: `La edad máxima que puede tener al final del plazo de la hipoteca es de 80 años.<br/> Sin embargo, si la hipoteca termina después de cumplir los 75 años, recibirá muchas menos ofertas.<br/> La mayoría de los bancos prefieren que las hipotecas terminen antes de cumplir los 70 años, con la duración de hipoteca mínima (${dataPeriod} años) <b>terminaría a tus ${
        age + dataPeriod
      } años</b> de edad.`,
      icon: "warning",
      confirmButtonText: "Aceptar",
    });
  }, []);

  useEffect(() => {
    if (clientAges.clientAge >= WARNING_CLIENT_AGE) {
      MaxClientAgeAlert(clientAges.clientAge, Number(getValues("data.period")));
    }
    if (clientAges.clientAge >= USER_AGE_RANGE.max) {
      if (solicitorType === "1") {
        setDisableNext(true);
        return;
      }
      if (solicitorType === "2" && clientAges.clientAge >= USER_AGE_RANGE.max) {
        setDisableNext(true);
      }
      return;
    }
    setDisableNext(false);
  }, [clientAges.clientAge]);

  useEffect(() => {
    if (clientAges.clientAge2 >= WARNING_CLIENT_AGE) {
      MaxClientAgeAlert(
        clientAges.clientAge2,
        Number(getValues("data.period"))
      );
      if (clientAges.clientAge >= USER_AGE_RANGE.max) {
        setDisableNext(true);
        return;
      }
    }
    setDisableNext(false);
  }, [clientAges.clientAge2]);

  useEffect(() => {
    if (
      (solicitorType === "1" && userWork === "3") ||
      (solicitorType === "2" && userWork === "3" && user2Work === "3")
    ) {
      setDisableNext(true);
      Swal.fire({
        title: "Importante",
        html: `Lamentablemente los bancos necesitan cierta estabilidad laboral para poder hacer ofertas hipotecarias. <br> Desde Colibid, te recomendamos que apliques nuevamente con tu solicitud de hipoteca cuando tu situación laboral cambie. Sin duda recibirás más y mejores ofertas.`,
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return;
    }
    if (
      solicitorType === "2" &&
      !hasUnemployConjuntoMsgShow &&
      (userWork === "3" || user2Work === "3")
    ) {
      setDisableNext(false);
      setHasUnemployConjuntoMsgShow(true);
      Swal.fire({
        title: "Importante",
        html: `Ten en cuenta en donde usted como su acompañante tienen un trabajo o ingreso fijo, esto aumenta mas la posibilidad de que un banco analice mas su perfil`,
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return;
    }
    setHasUnemployConjuntoMsgShow(false);
    setDisableNext(false);
  }, [solicitorType, userWork, user2Work]);

  // tal vez pasar a children to handleSolicior view
  return (
    <HandleSolicitorView
      RenderComponent={Form}
      individualProps={{ isUser2: false, shouldShowTermAndCondt, errorMsg }}
      conjuntaProps={{ isUser2: true }}
    />
  );
}

type FormProps = {
  isUser2?: boolean;
  shouldShowTermAndCondt: boolean;
  errorMsg: string;
};
function Form({
  isUser2 = false,
  shouldShowTermAndCondt,
  errorMsg,
}: FormProps) {
  const methods = useFormContext<MortgageFormData>();
  return (
    <Stack
      id="basic-personal-form-inputs"
      height="inherit"
      spacing={2}
      justifyContent="center"
      margin="auto"
      maxWidth="350px"
      sx={{
        transform: "scale(0.8)",
      }}
    >
      <FormFields.TextFieldInput
        label={getUserFormLabel(isUser2, "Nombre")}
        name={getUserFormName({ isUser2, name: "user.name" })}
      />
      <FormFields.TextFieldInput
        label={getUserFormLabel(isUser2, "Apellido")}
        name={getUserFormName({ isUser2, name: "user.surname" })}
      />
      <FormFields.TextFieldInput
        label={getUserFormLabel(isUser2, "Email")}
        name={getUserFormName({ isUser2, name: "user.email" })}
        lowercase
      />
      <FormFields.DatePicker
        label={getUserFormLabel(isUser2, "Fecha de Nacimiento")}
        name={getUserFormName({ isUser2, name: "user.birthdate" })}
        disableFuture
      />
      <FormFields.TextFieldInput
        label={getUserFormLabel(isUser2, "DNI ó NIE")}
        name={getUserFormName({ isUser2, name: "user.dni" })}
        onBlur={e => {
          // this block of code is used to trigger validation on other input
          const dniPath =   getUserFormName({ isUser2: !isUser2, name: "user.dni" })
          const [userDni, solicitorType] = methods.getValues([dniPath, 'solicitor_type'])
          if(Number(solicitorType) === 2){
            userDni && methods.trigger()
          }
        }}
        placeholder="X12345678A"
      />
      <FormFields.PhoneNumberInput
        label={getUserFormLabel(isUser2, "Móvil")}
        name={getUserFormName({ isUser2, name: "user.phone" })}
      />
      {shouldShowTermAndCondt && <TermAndCondition errorMsg={errorMsg} />}
    </Stack>
  );
}

export default BasicPersonalDataForm;

function FormSkeleton() {
  return (
    <Stack
      id="basic-personal-form-inputs-skeleton"
      height="inherit"
      gap={4}
      justifyContent="center"
      margin="auto"
      width="100%"
      maxWidth="350px"
      sx={{
        transform: "scale(0.8)",
      }}
    >
      {[...Array(5)].map((e, i) => {
        return (
          <Skeleton key={i} height={40} width="100%" variant="rectangular" />
        );
      })}
    </Stack>
  );
}

function TermAndCondition({ errorMsg }: { errorMsg: string }) {
  const { mortgageFormState, mortgageFormDispatch } = useMortgageFormContext();

  const checked = mortgageFormState.isTermAccepted;
  const handleChange: CheckboxProps["onChange"] = (e) => {
    mortgageFormDispatch({
      type: "SET_IS_TERM_ACCEPTED",
      payload: e.target.checked,
    });
  };
  return (
    <Stack alignItems="center">
      <Stack direction="row" alignItems="center">
        <Checkbox checked={checked} onChange={handleChange} />
        <Typography>
          Acepto los{" "}
          <Link
            href="https://colibid.com/terminos-y-condiciones/"
            target="_blank"
            rel="noopener"
          >
            Términos y Condiciones
          </Link>{" "}
          y las{" "}
          <Link
            href="https://colibid.com/privacy-policy/"
            target="_blank"
            rel="noopener"
          >
            Políticas de Privacidad
          </Link>
        </Typography>
      </Stack>
      {errorMsg && !checked && (
        <Typography textAlign="center" color="error" variant="body2">
          {errorMsg}
        </Typography>
      )}
    </Stack>
  );
}
