import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { NumberBox } from "pages/RealState/Components";
import React, { useState } from "react";
import styles from "./styles.module.scss";

const InfoOfferts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleTooltipClick = () => {
    setIsModalVisible(true);
  };
  return (
    <React.Fragment>
      <Grid container marginTop="2.5rem" padding="0 2rem">
        <Grid item md={12} sm={12} marginBottom="1rem">
          <Typography
            className={` ${styles.fontFamily}`}
            sx={{ fontSize: "1.5rem", textAlign: "left" }}
          >
            ¿Aún quedan dudas? Te contamos el proceso
          </Typography>
        </Grid>
        <Grid item md={3} sm={12} display="flex" flexDirection="row">
          <NumberBox
            number="01"
            colorNumber="#D8D8F6"
            title="Analiza las ofertas"
            paragraph={
              <p>
                {" "}
                Durante el <span className={styles.hightlight}>día</span> de
                subasta te llegarán distintas ofertas de bancos y brókers.
                Podrás priorizarlas según te convenga (cuota mensual,
                bonificaciones, comisión, etc).
              </p>
            }
            style={{ padding: "0px" }}
            styleBox={`${styles.fontFamily} ${styles.numberText}`}
            styleTitle={styles.fontFamilyTitleNumber}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <NumberBox
            number="02"
            colorNumber="#E4F086"
            title="Elige las ofertas"
            paragraph={
              <div>
                <span className={styles.hightlight}>¡Elige las ofertas </span>
                con las que deseas avanzar! Si ves ofertas agrupadas por color,
                es porque pertenecen a la misma entidad o banco, y podrás elegir
                <span className={styles.hightlight}> sólo 1 por grupo. </span>
                Una vez elijas las ofertas con las que deseas continuar, las
                demás se descartarán automáticamente.
                <p
                  className={styles.links}
                  style={{ textDecoration: "underline" }}
                  onClick={handleTooltipClick}
                >
                  Quiero saber más
                </p>
              </div>
            }
            style={{ padding: "0px" }}
            styleBox={`${styles.fontFamily} ${styles.numberText}`}
            styleTitle={styles.fontFamilyTitleNumber}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <NumberBox
            number="03"
            colorNumber="#01807A"
            title="Carga la documentación"
            paragraph={
              <div>
                <p>
                  <span className={styles.hightlight}>
                    Tras cargar tu documentación, verificaremos
                  </span>{" "}
                  que todos los datos sean correctos. Una vez finalizado este
                  proceso, las entidades seleccionadas se pondrán en contacto
                  contigo para continuar el proceso.Te facilitaremos un panel
                  para que puedas hacer seguimiento a cada una.{" "}
                </p>
              </div>
            }
            style={{ padding: "0px" }}
            styleBox={`${styles.fontFamily} ${styles.numberText}`}
            styleTitle={styles.fontFamilyTitleNumber}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <NumberBox
            number="04"
            colorNumber="#33CCCC"
            title="Sigue el proceso"
            paragraph="Podrás continuar en proceso paralelo con todas las ofertas elegidas, hasta que escojas la que más te cuadra. Podrás dar de baja la oferta que quieras, en el momento que quieras."
            style={{ padding: "0px" }}
            styleBox={`${styles.fontFamily} ${styles.numberText}`}
            styleTitle={styles.fontFamilyTitleNumber}
          />
        </Grid>
      </Grid>
      <Dialog
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            <p>
              Colibid permite a bancos y brókers enviar múltiples ofertas. Dado
              que{" "}
              <span className={styles.higlight}>
                operamos a nivel nacional y las oficinas bancarias son
                independientes{" "}
              </span>
              , es posible que diferentes oficinas o brókers de un mismo banco
              envíen ofertas distintas a los mismos perfiles.
            </p>

            <p>
              Es por ello, que puedes recibir varias ofertas de la misma
              entidad.{" "}
              <span className={styles.higlight}>
                No obstante, no es posible continuar el proceso con varias
                ofertas en paralelo dentro del mismo banco, ¡pero sí con otros!
              </span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button variant="contained" onClick={() => setIsModalVisible(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InfoOfferts;
