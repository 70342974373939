import React, {
  FunctionComponent,
  ReactComponentElement,
  useState,
  useEffect,
} from "react";
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Avatar,
  CardContent,
  Card,
  Box,
  Button,
  OutlinedInput,
  InputAdornment,
  Divider,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
  TableBody,
  TableContainer,
  Dialog,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import QRCode from "react-qr-code";
import { MobileView } from "react-device-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CodeIcon from "@mui/icons-material/Code";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import HomeIcon from "@mui/icons-material/Home";
import PaidIcon from "@mui/icons-material/Paid";
import {
  MortgageStepTimeline,
  ImageButton,
  FastFAQs,
  MetricCard,
} from "../../shared/components";

import { DinamycLayout } from "../../shared/layouts";
import { PageTitle } from "../../shared/components";

import { useAuthenticated, useProfile } from "../../shared/hooks";
import { useLocalStorage } from "../../shared/hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";
import { StepTimeline } from "shared/components/MortgageStepTimeline/utils";
import {
  getReferrals,
  getPaymentMethod,
  putPaymentMethod,
} from "../../services/api";

import styles from "./styles.module.scss";
import { MetricCardProps } from "shared/components/MetricCard";

const Referrals: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role] = useLocalStorage("role");
  const profile = useProfile().profile;
  const [shareURL, setShareURL] = useState("");
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [shareMessage, setShareMessage] = useState("Registrate en Colibid");
  const [shareiFrame, setShareiFrame] = useState("");
  const [referral, setReferral] = useState({
    created_mortgage: 0,
    name: "",
    referral: "",
    registered: 0,
    successful_mortgage: 0,
    paid_mortgage: 0,
  });
  const [paymentData, setPaymentData] = useState({
    BIC_SWIFT: "",
    IBAN: "",
    account_holder_name: "",
    identification: "",
    user: "",
  });
  const [age, setAge] = React.useState("");
  const [defaultSteps, setDefaultSteps] = useState<MetricCardProps[]>([]);

  useAuthenticated(
    (allow) => {
      if (!allow) navigate("/sign-in");
    },
    ["client", "influencer"]
  );

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  useEffect(() => {
    setShareURL(`${process.env.REACT_APP_WEB_URL}?r=${referral.referral}`);
    setShareiFrame(
      `<iframe src="${process.env.REACT_APP_API_URL}/embed?r=${referral.referral}" scrolling="no" width="100%" height="130" frameborder="0"></iframe>`
    );
  }, [referral.referral]);

  useEffect(() => {
    getReferralData();
  }, []);

  const referalData = (data: any) => {
    const result = data;
    const referralResult: MetricCardProps[] = [
      {
        title: "Referidos",
        value: result.registered,
        icon: <AddReactionIcon color="primary" />,
      },
      {
        title: "Hipotecas registradas",
        value: result.created_mortgage,
        icon: <OtherHousesIcon color="primary" />,
      },
      {
        title: "Hipotecas completadas",
        value: result.successful_mortgage,
        icon: <HomeIcon color="primary" />,
      },
      {
        title: "Hipotecas pagadas",
        value: result.paid_mortgage,
        icon: <PaidIcon color="primary" />,
      },
    ];
    return referralResult;
  };

  const getReferralData = () => {
    getReferrals()
      .then((response: any) => {
        setReferral(response.data);
        const resStep = referalData(response.data);
        setDefaultSteps(resStep);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const getPaymentMethodData = () => {
    getPaymentMethod()
      .then((response: any) => {
        setPaymentData(response.data);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  const putPaymentMethodData = (data: object) => {
    putPaymentMethod(data)
      .then((response: any) => {
        getPaymentMethodData();
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });
  };

  return role ? (
    <DinamycLayout type={role}>
      <Container className={styles.ReferralsWrapper}>
        <Grid item md={12} sm={12} xs={12}>
          <PageTitle title="Mis recomendaciones" />
          <Divider component="br" />
        </Grid>
        <Grid item md={12} sm={12} xs={12} container spacing={2}>
          <Divider component="br" />

          {defaultSteps.length > 0 &&
            defaultSteps.map((step) => (
              <Grid item md={3} sm={12} xs={12}>
                <MetricCard
                  title={step.title}
                  value={step.value}
                  icon={step.icon}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Divider></Divider>
          </Grid>
        </Grid>
        <Divider component="br" />
        <Divider component="br" />
        <Grid container>
          <PageTitle title="Cómo recomendar:" />
        </Grid>
        <Divider component="br" />
        <Grid
          container
          spacing={2}
          sx={{
            borderBottom: "1px solid #dddddd",
            paddingBottom: {
              xs: "1rem",
              sm: "2rem",
            },
            marginTop: {
              sm: "3rem",
              xs: "1rem",
            },
          }}
        >
          <Grid item md={4} sm={12} xs={12}>
            <ImageButton
              title="Compartir Enlace"
              src="/assets/img/sources/referal_share_link.svg"
              onClick={() => {
                navigate("/referrals/link");
              }}
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <ImageButton
              title="Cobro y facturación"
              src="/assets/img/sources/referal_pay.svg"
              onClick={() => {
                navigate("/referrals/pay");
              }}
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <ImageButton
              title="Cómo funciona"
              src="/assets/img/sources/referal_how_it_works.svg"
              onClick={() => {
                navigate("/referrals/info");
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </DinamycLayout>
  ) : (
    <></>
  );
};

export interface IBankDetailsProps {
  show: boolean;
  data: any;
  onSave: (data: any) => void;
  onClose: () => void;
}
const BankDetails: FunctionComponent<IBankDetailsProps> = (
  props
): ReactComponentElement<any> => {
  const [localData, setLocalData] = useState(props.data);

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  return (
    <Dialog
      open={props.show}
      onClose={() => props.onClose()}
      maxWidth="sm"
      fullWidth={true}
    >
      <Box className={styles.DialogDetails}>
        <Container>
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: ".7rem" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.onSave(localData);
                }}
              >
                <Typography variant="h6">Mis Datos Bancarios</Typography>
                <Divider />
                <Divider component="br" />
                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="
                  Nombre del titular de la cuenta"
                  value={localData.account_holder_name}
                  onChange={(e: any) => {
                    localData.account_holder_name = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="
                  DNI"
                  value={localData.identification}
                  onChange={(e: any) => {
                    localData.identification = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="
                IBAN"
                  value={localData.IBAN}
                  onChange={(e: any) => {
                    localData.IBAN = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <TextField
                  className={styles.InputFields}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="BIC/SWIFT"
                  value={localData.BIC_SWIFT}
                  onChange={(e: any) => {
                    localData.BIC_SWIFT = e.target.value;
                    setLocalData({ ...localData });
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ marginTop: ".7rem" }}
                >
                  Guardar
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
};

const StatementsList = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Referencia</TableCell>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Descripción</TableCell>
            <TableCell align="center">Monto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
};

export default Referrals;
