import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Chip } from "@mui/material";
import { Typography } from "@mui/material";
import InfoCard, { TableRows, TableRowsData, setTableRowsData } from "..";
import { CustomInfoCardProps } from "./types";
import { MortgageFormData } from "shared/types/postMortgageData";
import { TabSection } from "../../TabSection";
import {
  DeepPartialSkipArrayKey,
  FormState,
  UnpackNestedValue,
} from "react-hook-form";
import { getYearMonth, getUserProfessions } from "utils/helpers";
import { NumericString } from "shared/types/misc";
import { getYesNoLabel } from "utils/valueLabelOptions";
import { EditMortgageModalCard } from ".";
import { getTruthyValuesLength } from "../utils";

export default function WorkInfoCard({
  data,
  editable = false,
  formState,
  modalOnClick,
  censure,
  stepViews,
  open,
  showBackgroundColor,
  showFieldLens,
}: CustomInfoCardProps<MortgageFormData>) {
  const solicitorType = Number(data?.solicitor_type);
  const errors = formState?.errors;
  const userHasErrors = !!errors?.user && Object.keys(errors?.user).length > 0;
  const user2HasErrors =
    errors?.user_2 && Object.keys(errors?.user_2).length > 0;

  const userTableRowData = getWorkInfoData({
    censure,
    user: data?.user,
    userErrors: errors?.user,
  });
  const user2TableRowData = getWorkInfoData({
    censure,
    user: data?.user_2,
    userErrors: errors?.user_2,
  });

  const truthyValsLen = getTruthyValuesLength(userTableRowData);
  return (
    <InfoCard
      title={
        <Typography component="div">
          Información laboral{" "}
          {showFieldLens && (
            <Chip color="primary" label={`${truthyValsLen} campos`} />
          )}
        </Typography>
      }
      editable={editable}
      open={open}
      {...(showBackgroundColor && { background: "#D8D7F533" })}
    >
      <TabSection
        tabs={[
          {
            muiTabProps: {
              label: "solicitante",
              icon: <PersonIcon />,
              sx: {
                width: `${100 / 2}%`,
                maxWidth: `${100 / 2}%`,
                fontSize: ".8rem",
                backgroundColor: `${userHasErrors ? "pink" : ""}`,
              },
            },
            renderInTabPanel: <TableRows data={userTableRowData} />,
          },
          {
            muiTabProps: {
              disabled: solicitorType === 1,
              label: "acompañante",
              icon: <SupervisedUserCircleIcon />,
              sx: {
                width: `${100 / 2}%`,
                maxWidth: `${100 / 2}%`,
                fontSize: ".8rem",
                backgroundColor: `${user2HasErrors ? "pink" : ""}`,
              },
            },
            renderInTabPanel: <TableRows data={user2TableRowData} />,
          },
        ]}
      />
      {editable && stepViews && (
        <EditMortgageModalCard stepViews={stepViews} onClick={modalOnClick} />
      )}
    </InfoCard>
  );
}

function getWorkInfoData({
  user,
  userErrors,
}: {
  censure?: boolean;
  user?:
    | CustomInfoCardProps<MortgageFormData>["data"]["user"]
    | CustomInfoCardProps<MortgageFormData>["data"]["user_2"];
  userErrors:
    | FormState<MortgageFormData>["errors"]["user"]
    | FormState<MortgageFormData>["errors"]["user_2"];
}): Record<string, TableRowsData> {
  const getEmployFrom = (
    user?: UnpackNestedValue<DeepPartialSkipArrayKey<MortgageFormData["user"]>>,
  ): string => {
    if (user && [3, 6].includes(Number(user.work))) return "";
    if (!user?.work_init_month || !user?.work_init_year) return "";
    return `${getYearMonth(user?.work_init_month || "--")} del ${
      user?.work_init_year || "--"
    }`;
  };

  return {
    "Empleado desde": setTableRowsData(
      getEmployFrom(user),
      userErrors?.work_init_month?.message ||
        userErrors?.work_init_year?.message,
    ),
    Profesión: setTableRowsData(
      getUserProfessionsById(user?.profession),
      userErrors?.profession?.message,
    ),
    "Declaración de Renta": setTableRowsData(
      getYesNoLabel(user?.has_last_year_tax),
      userErrors?.has_last_year_tax?.message,
    ),
  };
}

function getUserProfessionsById(id?: NumericString) {
  return getUserProfessions().find((p) => p.id === id?.toString())?.description;
}
