import {
  CountryNameEs,
  ExcludeCountryNameEs,
} from "shared/constants/countries";

export interface DefaultFilters {
  mode: number;
  interestType: string;
  homeValue: number[];
  mortgageValue: number[];
  mortgagesProvinces: any[];
  mortgagesCommunities: any[];
  province: string;
  community: string;
  debtRatio: number[];
  monthlyIncome: number[];
  monthlyExpenses: number[];
  monthlyDebts: number;
  mortgageAmount: number;
  mortgageBidsAmount: number[];
  userAge: number[];
  userWork: any[];
  user2Work: any[];
  user2_Age: number[];
  typeDocUser1: string;
  typeDocUser2: string;
  user2Profession: string;
  userProfession: string;
  /**
   * is type number cause the options is a yes: 1, no: 2, so is true: 1 else false
   */
  "user.residence_permit": number;
  'user_2.residence_permit': number;
  "user.has_last_year_tax": number;
  /**
   * user.residence in the mortgage form is a CountryNameEs, in front and back
   * the added exclude string is for the backend to process it and exclude it from the query
   */
  "user.residence": CountryNameEs | ExcludeCountryNameEs;
  "user_2.residence": CountryNameEs | ExcludeCountryNameEs;
  "property.hasEndorsement": number;
  mortgagePeriod: number[];
  mortgageRate: number[];
  requestType: string | number;
  mortgageRemaining: number[];
  mortgageFiles: any[];
  financialsEntities: any[];
  constructionType__in: number[];
  constructionType: number;
  mortagagePropertyExistence: string;
  fundUsedFor: any[];
  propertyType: string;
  propertyAreaRange: [number, number];
  allowFilterHomeValue: boolean;
  allowFilterMortgageValue: boolean;
  allowFilterMonthlyIncome: boolean;
  allowFilterMonthlyExpenses: boolean;
  allowFiltersMortgagePeriod: boolean;
  allowFiltersMortgageRate: boolean;
  allowFiltersMortageDebtRatio: boolean;
  allowFiltersUserAge: boolean;
  allowFiltersUserAgeTwo: boolean;
  allowFiltersMortgageBidsAmount: boolean;
  allowFiltersMortgageRemaining: boolean;
  allowPropertyType: boolean;
  allowFilterPropertyArea: boolean;
  mortgageType: any[];
  mortageinterestType: any[];
  newMortgage: boolean;
  changeMortgage: boolean;
  capitalRaise: boolean;
  autopromotor: boolean;
  fixedInterest: boolean;
  variableInterest: boolean;
  mixedInterest: boolean;
  totalInterest: boolean;
  laboralLife: boolean;
  sScontribution: boolean;
  rent: boolean;
  CIRBE: boolean;
  AEATDebts: boolean;
  sSSituation: boolean;
  corporateTax: boolean;
  d347: boolean;
  d111: boolean;
  d130: boolean;
  d190: boolean;
  d303: boolean;
  d390: boolean;
  d200: boolean;
  DNIa: boolean;
  DNIr: boolean;
  DNIaa: boolean;
  DNIra: boolean;
  NS: boolean;
  U3: boolean;
}

export type MortgageDefaultFilterKeys = keyof DefaultFilters;
export interface AllowFilterValuesKeys {
  homeValue: string;
  mortgageValue: string;
  monthlyIncome: string;
  monthlyExpenses: string;
  mortgagePeriod: string;
  mortgageRate: string;
  debtRatio: string;
  userAge: string;
  user2_Age: string;
  mortgageBidsAmount: string;
  mortgageRemaining: string;
  propertyType: string;
  propertyAreaRange: string;
  /** this will be a string of number, it should be, in the backedn we parse it to a int */
  "data.curr_mortgage_init_date__lte": string
}

export const allowFilterValuesKeys: AllowFilterValuesKeys = {
  homeValue: "allowFilterHomeValue",
  mortgageValue: "allowFilterMortgageValue",
  monthlyIncome: "allowFilterMonthlyIncome",
  monthlyExpenses: "allowFilterMonthlyExpenses",
  mortgagePeriod: "allowFiltersMortgagePeriod",
  mortgageRate: "allowFiltersMortgageRate",
  debtRatio: "allowFiltersMortageDebtRatio",
  userAge: "allowFiltersUserAge",
  user2_Age: "allowFiltersUserAgeTwo",
  mortgageBidsAmount: "allowFiltersMortgageBidsAmount",
  mortgageRemaining: "allowFiltersMortgageRemaining",
  propertyType: "allowPropertyType",
  propertyAreaRange: "allowFilterPropertyArea",
  "data.curr_mortgage_init_date__lte": "allowCurrMortgageInitDate",
};
