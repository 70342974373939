import { useReducer } from "react";
import {
  MortgageEvaluation,
  MortgageRes,
  TimeLineStep,
} from "shared/types/BaseApiResponse";
import { Bid, PhaseTimeLine } from "shared/types/Bid";
import { isFileAlreadyUpdated } from "utils";

export const defaultPPSteps: PrincipalPanelStep[] = [
  {
    step: {
      description: "lorem ipsum",
      description2: "",
      label: "Subasta activa",
      name: "active",
    },
  },
  {
    step: {
      description:
        "Tu subasta ha finalizado, por lo que no recibirás más ofertas. ¡Tienes 3 días para elegir una de ellas!",
      description2: "",
      label: "Subasta cerrada",
      name: "closed",
    },
  },
  {
    step: {
      description:
        "¡Has elegido una oferta y estás listo para cargar la documentación!",
      description2:
        "si ya cargas la documentación, podemos verificarla antes y ganas tiempo",
      label: "Oferta aceptada",
      name: "in_verification_accepted", //accepted but document missing
    },
  },
  {
    step: {
      description:
        "Una vez cargues toda la documentación, nuestro equipo experto la verificará en menos de 48hs para asegurar que vaya todo bien. Luego de verificada el banco/bróker se pondrá en contacto contigo para avanzar",
      description2: "",
      label: "Documentos",
      name: "in_verification_accepted",
    },
  },
];

export type PrincipalPanelStep = Partial<MortgageEvaluation>;
export interface PrincipalPanelState {
  mortgage?: MortgageRes;
  activeStep: number;
  currStep: number;
  step: PhaseTimeLine;
  bids: Bid[];
  /**with activeStep or currStep can access this object to see it is completed or not */
  completedStep: { [k: number]: boolean };
  steps?: PrincipalPanelStep[];
}

export type PPAction =
  | {
      type: "SET_MORTGAGE";
      payload: MortgageRes;
    }
  | { type: "SET_ACTIVE_STEP"; payload: number }
  | { type: "SET_CURR_STEP"; payload: number }
  | {
      type: "SET_COMPLETED_STEP";
      payload: PrincipalPanelState["completedStep"];
    }
  | {
      type: "PUSH_STEPS";
      payload: PrincipalPanelStep[];
    }
  | {
      type: "SET_BIDS";
      payload: Bid[];
    }
  | {
      type: "SET_STATE_STEPS";
      payload: {
        steps: TimeLineStep[];
        mortgage: MortgageRes;
        id?: string | undefined;
        currentStep?: number | undefined;
      };
    };

/* 
    steps
    lista steps 
    current step
    status step -> completed or procesed
    */

function reducer(
  state: PrincipalPanelState,
  action: PPAction
): PrincipalPanelState {
  switch (action.type) {
    case "SET_MORTGAGE": {
      const m = action.payload;

      return {
        ...state,
        mortgage: { ...m },
      };
    }
    case "SET_ACTIVE_STEP":
      return { ...state, activeStep: action.payload };
    case "SET_CURR_STEP":
      return { ...state, currStep: action.payload };
    case "SET_COMPLETED_STEP":
      return { ...state, completedStep: action.payload };
    case "PUSH_STEPS": {
      const namesToFilter = ["registered", "contactado", "viable"];
      const newSteps = filterStepsByNames(action.payload, namesToFilter);
      return {
        ...state,
        steps: newSteps,
      };
    }
    case "SET_BIDS": {
      const namesToFilter = ["registered", "contactado", "viable"];

      const test = action.payload.map((b) => {
        b.timeline.phases = filterStepsState(b.timeline.phases, namesToFilter);
        return b;
      });

      return { ...state, bids: test };
    }
    case "SET_STATE_STEPS": {
      const namesToFilter = ["registered", "contactado", "viable"];
      const steps = action.payload.steps;
      const mortgage = action.payload.mortgage;
      const id = action.payload.id;
      const stepCurrentPayload = action.payload.currentStep;

      const newSteps = filterStepsState(steps, namesToFilter);
      const { currentStep, stepCompleted, activeStep, timeLine } =
        getStepTimeLine(newSteps, mortgage, id);

      return {
        ...state,
        activeStep: stepCurrentPayload ? stepCurrentPayload : currentStep,
        completedStep: stepCompleted,
        currStep: activeStep,
        step: timeLine,
      };
    }

    default:
      return state;
  }
}

function usePPReducer() {
  const initialState: PrincipalPanelState = {
    activeStep: 0,
    currStep: 0,
    completedStep: {},
    steps: undefined,
    bids: [],
    step: {
      id: "",
      label: "",
      action: "",
      description: null,
      status: "",
      event: null,
      created_at: "2023-11-06T15:30:25.627Z",
      updated_at: null,
      deleted_at: null,
    },
  };

  return useReducer(reducer, initialState);
}
export default usePPReducer;

function getStepTimeLine(
  stepsTimeLine: TimeLineStep[],
  mortagage: MortgageRes,
  id: string | undefined
) {
  const isTasadoraLoaded = isFileAlreadyUpdated(mortagage, "Doc_tasadora");

  let currentStep: number = -1;
  const stepCompleted: { [key: number]: boolean } = {};
  let count = true;

  stepsTimeLine.forEach((step, index) => {
    if (
      step.status === "completed" ||
      (step.action === "tasadoraRealizada" &&
        isTasadoraLoaded &&
        stepsTimeLine[index - 1].status === "completed")
    ) {
      currentStep = index; // Guardamos el índice de la última tarea completada
      stepCompleted[index] = true;
    }
  });

  // check if is not closed yet then change the status, this could be changed on backend to improve the option

  const isNotClosedAuction =
    stepsTimeLine[currentStep] &&
    stepsTimeLine[currentStep].action === "active_auction" &&
    mortagage.status !== "closed";

  if (stepsTimeLine[currentStep]) {
    stepsTimeLine[currentStep].status = isNotClosedAuction
      ? "pending"
      : "completed";
  }

  // check if is crm and check document and skiped steped
  // is step to omit
  if (id) {
    const validStates = ["documents", "contactado", "viable"];
    const bidId = mortagage.bids.filter((b) => b.id === id)[0];
    const omitStep = validStates.includes(bidId.timeline.current.action);

    if (omitStep) {
      const isCompletedLastSteps =
        bidId.timeline.current.action !== "contacto" &&
        bidId.timeline.current.action !== "viable" &&
        bidId.timeline.current.action !== "documents";

      count = isCompletedLastSteps;
    }
  }

  const activeStep =
    (stepsTimeLine[currentStep]?.status === "completed" ||
      (isTasadoraLoaded &&
        stepsTimeLine[currentStep]?.action === "tasadoraRealizada")) &&
    count
      ? currentStep + 1
      : currentStep;

  const step = stepsTimeLine[activeStep];

  return {
    currentStep: activeStep,
    stepCompleted,
    activeStep,
    timeLine: step,
  };
}

function filterStepsByNames(
  stepsArray: PrincipalPanelStep[],
  namesToFilterBy: Array<string>
) {
  return stepsArray.filter(
    (stepObject) =>
      stepObject.step && !namesToFilterBy.includes(stepObject.step.name)
  );
}

function filterStepsState(
  stepsArray: TimeLineStep[],
  namesToFilterBy: Array<string>
) {
  return stepsArray.filter(
    (stepObject) => !namesToFilterBy.includes(stepObject.action)
  );
}
