import { useState, useEffect } from "react";
import { Fab, useTheme } from "@mui/material";
import ArrowToTopIcon from "@mui/icons-material/ExpandLess";

type Props = { showBelow?: number };

export default function ScrollToTop({ showBelow = 250 }: Props) {
  const theme = useTheme();

  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <Fab
          onClick={handleClick}
          color="primary"
          size={"small"}
          aria-label="to top"
          component="span"
        >
          <ArrowToTopIcon />
        </Fab>
      )}
    </div>
  );
}
