import { FunctionComponent, ReactElement } from "react";

import { Box, Typography, Divider } from "@mui/material";

import styles from "./styles.module.scss";

export interface INotResultsProps {
  title: string;
  caption?: string;
  captionHTML?: any;
  url?: string;
  resize?: boolean;
}
const NotResults: FunctionComponent<INotResultsProps> = ({
  title,
  caption,
  captionHTML,
  url,
  resize,
}): ReactElement<any> => {
  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.BoxImage}>
        {resize === true ? (
          <img
            src={url}
            alt="Colibid"
            style={{
              height: 150,
              width: 250,
            }}
          />
        ) : (
          <img src={url} alt="Colibid" />
        )}
      </Box>

      <Typography variant="h6" align="center">
        {title}
      </Typography>

      {caption && (
        <Typography variant="subtitle1" align="center">
          {caption}
        </Typography>
      )}

      {captionHTML && (
        <Typography
          variant="subtitle1"
          align="center"
          dangerouslySetInnerHTML={{ __html: captionHTML }}
        />
      )}
      <Divider component="br"></Divider>
      <Divider component="br"></Divider>
    </Box>
  );
};

NotResults.defaultProps = {
  url: "/assets/img/sources/not_found.svg",
  resize: false,
};

export default NotResults;
