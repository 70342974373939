import { useMemo, useState, memo, Dispatch, SetStateAction } from "react";

import {
  Radio,
  RadioGroup,
  RadioGroupProps,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";

import { Controller, UseFormReturn, useFormContext } from "react-hook-form";
import { InputOption } from "shared/types/misc";

const MemoRadioInput = memo(
  ({
    methods,
    radioOptions,
    name = "",
    label,
    disabled,
    ...rest
  }: RadioGroupProps & {
    methods: UseFormReturn;
    radioOptions: InputOption[];
    label: string;
    disabled?: boolean;
  }) => {
    return (
      <Controller
        control={methods.control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          const fieldWithoutRef = { ...field, ref: undefined };
          return (
            <FormControl sx={{ ml: 1 }} error={Boolean(error?.message)}>
              <FormLabel filled>{label}</FormLabel>
              <RadioGroup
                {...rest}
                {...fieldWithoutRef}
                aria-labelledby={`${label}`}
              >
                {radioOptions.map((o) => {
                  return (
                    <FormControlLabel
                      key={o.label}
                      value={o.value}
                      control={
                        <Radio
                          size="small"
                          disabled={disabled}
                          inputRef={field.ref}
                        />
                      }
                      label={o.label}
                    />
                  );
                })}
              </RadioGroup>
              {error?.message && (
                <FormHelperText margin="dense">{error.message}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.methods.formState.isDirty === nextProps.methods.formState.isDirty
);

const RadioInput = ({
  radioOptions,
  name = "",
  label,
  disabled,
  ...rest
}: RadioGroupProps & {
  radioOptions: InputOption[];
  label: string;
  disabled?: boolean;
}) => {
  const methods = useFormContext();
  return (
    <MemoRadioInput
      methods={methods}
      disabled={disabled}
      radioOptions={radioOptions}
      name={name}
      label={label}
      {...rest}
    />
  );
};

export default RadioInput;
