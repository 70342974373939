import React, { useEffect, useState } from "react";

import {
  Typography,
  Collapse,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import ExpandMore from "./ExpandMore";

import styles from "../styles.module.scss";
import { defaultFiltersForTypes } from "../helpers";
import { mortgagesType } from "shared/components/MortgageForm/components/StepTwo/utils";

type Props = {
  filters: any;
  readOnly: boolean;
  changeValue: (key: string, value: any) => void;
};

export default function MortgageTypeFilter({
  readOnly,
  filters,
  changeValue,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [autoBididing, setAutobiding] = useState(false);

  useEffect(() => {
    if (readOnly) {
      setAutobiding(true);
    }
  }, []);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;

    // changeValue("mortgageType", value);
  };

  const handleMortgage = (
    value: number | string,
    status: boolean,
    toValue: keyof typeof defaultFiltersForTypes
  ) => {
    const items = [...filters.mortgageType];

    if (status && !items.includes(value)) {
      items.push(value);
    }
    if (status === false && items.includes(value)) {
      const index = items.indexOf(value);
      items.splice(index, 1);
    }

    if (toValue === "mortgageType") {
      changeValue("mortgageType", items);
    }
  };

  return (
    <>
      <Typography variant="h6" className={styles.tittleFilter}>
        <HolidayVillageIcon
          sx={{
            color: "#656565",
            marginRight: "1rem",
          }}
        />
        Tipos de hipoteca
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
          style={{
            float: "right",
          }}
        >
          {" "}
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      {!autoBididing && readOnly ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FormControl>
            <RadioGroup value={filters.mode} onChange={handleOnChange}>
              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Nueva hipoteca"
                value={1}
                control={<Radio />}
              />
              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Mejora de hipoteca"
                value={2}
                control={<Radio />}
              />
              {/* <FormControlLabel
                className={styles.LabelCheckbox}
                label="Ampliación de capital"
                value={3}
                control={<Radio />}
              />
              <FormControlLabel
                className={styles.LabelCheckbox}
                label="Autopromotor"
                value={4}
                control={<Radio />}
              /> */}
            </RadioGroup>
          </FormControl>
        </Collapse>
      ) : (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {mortgagesType.map((p, i) => {
            let elements = filters.mortgageType;

            const hasElement = elements ? elements.length > 0 : false;

            const currVal: number[] = elements;

            const isCheck = hasElement ? currVal.includes(p.id) : false;

            return (
              <FormControlLabel
                key={p.id}
                className={styles.LabelCheckbox}
                label={p.label}
                control={
                  <Checkbox
                    checked={isCheck}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let val = currVal;
                      if (currVal.includes(p.id)) {
                        val = currVal.filter((v) => v !== p.id);
                      } else {
                        val.push(p.id);
                      }

                      handleMortgage(
                        p.id,
                        event.target.checked,
                        "mortgageType"
                      );
                    }}
                  />
                }
              />
            );
          })}
        </Collapse>
      )}
    </>
  );
}
