import { Container, Grid, Stack, Typography, Divider } from "@mui/material";
import { format } from "date-fns";
import {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BidSection, TableSkeleton } from "shared/components";
import { useAuthenticated, useLocalStorage, useProfile } from "shared/hooks";
import { MainClientLayout } from "shared/layouts";
import { sendTagEvent } from "../../../utils/analytics";

import styles from "./styles.module.scss";
import PPSteppers from "../Home/components/PrincipalPanel/components/PPSteppers";

import { getBid, getTimeLineBid } from "services/api";
import usePPReducer from "../Home/components/PrincipalPanel/usePPReducer";
import { AcceptedBid, Bid } from "shared/types/Bid";
import { OnBidSelectedDialog } from "../Home/components/PrincipalPanel";
import { TimeLineStep } from "shared/types/BaseApiResponse";
import EvalSection from "../Home/components/PrincipalPanel/components/EvalSection";
import PPBidFileSection from "../Home/components/PrincipalPanel/components/PPBidFileSection";
import { censureValue } from "utils";
import TasadoraComponent from "../Home/components/PrincipalPanel/components/TasadoraComponent";

const defaultSteps = [
  {
    step: {
      description: "lorem ipsum",
      description2: "",
      label: "Subasta activa",
      name: "active",
    },
  },
  {
    step: {
      description:
        "Tu subasta ha finalizado, por lo que no recibirás más ofertas. ¡Tienes 3 días para elegir una de ellas!",
      description2: "",
      label: "Subasta cerrada",
      name: "closed",
    },
  },
  {
    step: {
      description:
        "¡Has elegido una oferta y estás listo para cargar la documentación!",
      description2:
        "si ya cargas la documentación, podemos verificarla antes y ganas tiempo",
      label: "Oferta aceptada",
      name: "in_verification_accepted",
    },
  },
  {
    step: {
      description:
        "Una vez cargues toda la documentación, nuestro equipo experto la verificará en menos de 48hs para asegurar que vaya todo bien. Luego de verificada el banco/bróker se pondrá en contacto contigo para avanzar",
      description2: "",
      label: "Documentos",
      name: "in_verification_accepted",
    },
  },
  {
    step: {
      name: "altaSistema",
      label: "Alta en el sistema",
      description2: "",
      accepted_type: "string",
      description: "Se han introducido los datos del cliente en el sistema.",
    },
  },
  {
    step: {
      name: "notaEncargoFirmada",
      label: "Nota de encargo",
      accepted_type: "file",
      description2: "",
      description:
        "Tras la llamada de contacto y con las condiciones acordadas, el asesor expide un\r\ndocumento que deberás firmar, donde se compromete a conseguir las condiciones\r\nofertadas, e incluirá sus honorarios (si hubiera) y en qué momento los cobrará.",
    },
  },
  {
    step: {
      name: "aprobacionBancaria",
      label: "Preaprobación bancaria",
      accepted_type: "string",
      require_file: false,
      description2: "",
      description:
        "El banco ha recibido todos los datos (perfil financiero y datos de la vivienda), y\r\ndespués de analizar el caso, da el visto bueno, a falta de la confirmación del valor\r\nde la propiedad por parte de la tasadora.",

      max_days: 0,
    },
  },
  {
    step: {
      name: "tasadoraRealizada",
      label: "Tasación",
      accepted_type: "file",
      description2: "",
      description:
        "Una vez solicitada la tasación, un tasador irá a la vivienda y emitirá un informe, que\r\nservirá para que el banco confirme la operación y prepare la FEIN.",
    },
  },
  {
    step: {
      name: "operacionRiesgos",
      label: "Operacion en Riesgos",
      accepted_type: "string",
      require_file: false,
      description2: "",
      description:
        "Cuando se envían los datos de la operación (con la tasación) al departamento de riesgos para su confirmacón definitiva.",
    },
  },
  {
    step: {
      name: "FEIN",
      label: "FEIN",
      accepted_type: "file",
      description2: "",
      description:
        "Documento que emite el banco, donde se recoge toda la información relevante del\r\npréstamo hipotecario. Este documento no tiene carácter vinculante para el cliente,\r\npero sí para el banco. Deberás devolverlo firmado.",

      max_days: 0,
    },
  },
  {
    step: {
      name: "fechaNotaria",
      label: "Notaría",
      accepted_type: "date",
      description2: "",
      description:
        "Es la última etapa del proceso, tendrás que acudir físicamente a tu notaría para la\r\nlectura de la FEIN y posterior firma.",

      max_days: 0,
    },
  },
  {
    step: {
      name: "cerrado",
      label: "Cerrado",
      accepted_type: "string",
      description2: "",
      description:
        "Ahora sí, ¡Tu casa es tuya! ¡Y tu hipoteca, también! Felicidades. Desde Colibid queremos agradecerte que hayas confiado en nosotros para ayudarte a conseguir las mejores condiciones. Pero no hemos acabado aún, queremos seguir haciendo cosas por tí. Te regalamos 100€ por recomentarnos a quién tú quieras, solo tienes que entrar a tu panel de referidos.",
      state_change_if_closed_successfully: "paid",
      max_days: 0,
    },
  },
];

const BidDetailsPrincipalPanel: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [role] = useLocalStorage("role");
    const profile = useProfile().profile;
    const [ppState, ppDispatch] = usePPReducer();
    const { mortgage, activeStep, currStep, completedStep, steps, bids } =
      ppState;
    const [actualStep, setActualStep] = useState("");
    const [bid, setBid] = useState<Bid | null>(null);

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "client");

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    useEffect(() => {
      if (id) {
        getOffertData(id);
      }
    }, [id]);

    const getOffertData = (offertId: string, actualStep?: string) => {
      getBid(offertId)
        .then((res) => {
          const m = res.data.results.mortgage;
          if (m) {
            const accepted: AcceptedBid = {
              solicitor_accepted: new Date(res.data.results.created),
              bidder_accepted: res.data.results.created,
              close_at: res.data.results.created,
              accept_financed_commission: false,
            };
            res.data.results.accepted = [accepted];
            m.bids = [res.data.results];

            setBid(res.data.results);
            ppDispatch({ type: "SET_MORTGAGE", payload: m });

            getTimeLineBid(id).then((res) => {
              if (res.data.results.length > 0) {
                const filterDefault = res.data.results;

                const stps = filterDefault.map((stp: TimeLineStep) => {
                  let stepp = {
                    step: {
                      description: stp.description ? stp.description : "",
                      description2: stp.description ? stp.description : "",
                      label: stp.label,
                      name: stp.action,
                    },
                  };
                  return stepp;
                });

                ppDispatch({
                  type: "PUSH_STEPS",
                  payload: stps,
                });

                const number = filterDefault
                  .filter(
                    (item: TimeLineStep) =>
                      item.action !== "contacto" &&
                      item.action !== "viable" &&
                      item.action !== "registered"
                  )
                  .findIndex((object: TimeLineStep) => {
                    return object.action === actualStep;
                  });

                ppDispatch({
                  type: "SET_STATE_STEPS",
                  payload: {
                    steps: filterDefault,
                    mortgage: m,
                    id: id,
                    currentStep: actualStep ? number : undefined,
                  },
                });
              }
            });
          }
        })
        .catch((e) => {
          console.log("err", e);
        });
    };

    useEffect(() => {
      if (steps) {
        getStep();
      }
    }, [activeStep]);

    const getStep = () => {
      if (steps && steps[activeStep].step?.name !== undefined) {
        const name = steps[activeStep].step?.name;

        if (name) {
          setActualStep(name);
        }
      }
    };

    return (
      <MainClientLayout>
        <>
          <Container className={styles.MortgageDetailsWrapper}>
            <Stack spacing={3} id="principal-panel">
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  margin: "0.5rem 0",
                }}
              >
                <Grid item md={12}>
                  <Typography className={`${styles.entityTitle}`}>
                    Oferta de{" "}
                    {mortgage &&
                      (mortgage.status !== "closed_successfully" ? (
                        <span className="censure">
                          {" "}
                          {bid?.bidder?.bidder_type === "broker"
                            ? bid?.bidder.name
                            : bid?.bid_for.name}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {bid?.bidder?.bidder_type === "broker"
                            ? bid?.bidder.name
                            : bid?.bid_for.name}
                        </span>
                      ))}
                  </Typography>
                </Grid>
              </Grid>
              <PPSteppers
                id="pp-steppers"
                activeStep={activeStep}
                currStep={currStep}
                alternativeLabel
                nonLinear
                steps={steps}
                stepCompleted={completedStep}
                BtnOnClick={(idx: number) => {
                  // revisar si se puede cambiar vista o solo popup

                  if (idx <= currStep) {
                    ppDispatch({ type: "SET_ACTIVE_STEP", payload: idx });
                  }
                }}
              />

              {!mortgage && (
                <TableSkeleton headColumns={5} bodyRows={5} bodyColumns={0} />
              )}

              {mortgage &&
                [
                  "registered",
                  "active_auction",
                  "closed_auction",
                  "accepted_offer",
                ].includes(actualStep) && (
                  <BidSection
                    mortgage={mortgage}
                    onSubmit={() => console.log("hey")}
                  />
                )}

              {mortgage && actualStep === "documents" && (
                <>
                  <OnBidSelectedDialog />
                  <PPBidFileSection
                    mortgage={mortgage}
                    onFinishFiles={() => {
                      if (id) {
                        getOffertData(id, "documents");
                      }
                    }}
                  />
                  <Divider variant="middle" sx={{ margin: "1rem 0" }} />
                  <div style={{ marginTop: "2.5rem" }}></div>
                  <TasadoraComponent
                    mortgage={mortgage}
                    step={1}
                    variant="doc"
                  />
                </>
              )}

              {activeStep >= 4 &&
                mortgage &&
                steps &&
                activeStep &&
                completedStep &&
                (mortgage.status === "closed_successfully" ||
                  mortgage.status === "paid") && (
                  <EvalSection
                    steps={steps}
                    activeStep={activeStep}
                    completedStep={completedStep}
                    mortgage={mortgage}
                    id={id}
                  />
                )}
              {/*
              
              {steps && steps[stepDescRef.current] && (
                <CustomDialog
                  id="description dialog"
                  open={isShowing}
                  onClose={closeDialog}
                  title={steps[stepDescRef.current].step?.label}
                >
                  <Typography>
                    {steps[stepDescRef.current].step?.description}
                  </Typography>
                </CustomDialog>
              )} */}
            </Stack>
          </Container>
        </>
      </MainClientLayout>
    );
  };

export default BidDetailsPrincipalPanel;
