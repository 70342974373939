import {
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HighlightText from "shared/components/HighlightText";
import { MFormSubGroupKey } from "shared/components/MortgageForm";
import { StepView } from "shared/components/MortgageForm/stepBuilder";
import { MortgageFormData } from "shared/types/postMortgageData";
import ProgressCard from "../../ProgressCard";
import React from "react";
import {
  HipotecaInfoCard,
  WorkInfoCard,
  FamilyEconomyInfoCard,
  InformationGeneralInfoCard,
} from "shared/components/InfoCard/mortgageCards";
import useMortgageFormContext from "shared/components/MortgageForm/hooks/useMortgageFormContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function VerificationForm() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const {
    mortgageFormData,
    stepViews,
    mortgageFormState,
    mortgageFormDispatch,
  } = useMortgageFormContext();
  const { selectedStepView } = mortgageFormState;
  const cards = stepViews.reduce<
    Partial<Record<MFormSubGroupKey, StepView<MortgageFormData>[]>>
  >((acc, e) => {
    if (e.subGroup) {
      if (!acc[e.subGroup]) {
        acc[e.subGroup] = [e];
      } else {
        acc[e.subGroup]?.push(e);
      }
    }
    return acc;
  }, {});

  const handleModalOnClick = (s: StepView<MortgageFormData>) => {
    mortgageFormDispatch({ type: "SET_SELECTED_STEPVIEW", payload: s });
  };
  return (
    <Stack height="100%" id="verificationForm">
      <ProgressStack />
      {/* por alguna extraña razon en redis regresa selectedStepViw con el objeto stepview pero sin component */}
      {selectedStepView && selectedStepView.component ? (
        React.createElement(selectedStepView.component)
      ) : (
        <Stack padding="2rem 2rem 0" flexGrow={1} spacing={1} height="100%">
          <TitleAndDesc />
          <ApplyMortgageBtn />
          <Stack
            spacing={2}
            flexGrow={1}
            direction={isMobile ? "column" : "row"}
            id="card-resumes"
            justifyContent={isMobile ? "flex-start" : "center"}
            sx={{
              ">*": {
                flex: isMobile ? "unset" : 1,
              },
            }}
          >
            <Stack spacing={1}>
              <HipotecaInfoCard
                data={mortgageFormData}
                editable
                stepViews={cards.mortgageData}
                modalOnClick={handleModalOnClick}
                showBackgroundColor
                showFieldLens
              />
              <WorkInfoCard
                data={mortgageFormData}
                editable
                stepViews={cards.workInfo}
                modalOnClick={handleModalOnClick}
                showBackgroundColor
                showFieldLens
              />
            </Stack>
            <Stack spacing={1}>
              <FamilyEconomyInfoCard
                data={mortgageFormData}
                editable
                stepViews={cards.revenue}
                modalOnClick={handleModalOnClick}
                showBackgroundColor
                showFieldLens
              />
              <InformationGeneralInfoCard
                data={mortgageFormData}
                editable
                stepViews={cards.personalData}
                modalOnClick={handleModalOnClick}
                showBackgroundColor
                showFieldLens
              />
            </Stack>
          </Stack>
          <ApplyMortgageBtn />
        </Stack>
      )}
    </Stack>
  );
}

function ApplyMortgageBtn() {
  const { onSubmit } = useMortgageFormContext();
  const handleSubmit = () => {
    onSubmit();
  };
  return (
    <Stack
      id="btn-row"
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingBottom={2}
    >
      <Button onClick={handleSubmit} variant="contained">
        Aplicar a Subasta
      </Button>
    </Stack>
  );
}

export default VerificationForm;

function ProgressStack() {
  const { progressGroupNodes, activeStep, isLastStep } =
    useMortgageFormContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  if (isMobile) return null;
  return (
    <Stack
      id="progress-stuff"
      spacing={1}
      sx={{
        position: "absolute",
        right: 0,
        top: 0,
        transform: "scale(0.7)",
      }}
    >
      <Stack direction="row" spacing={2}>
        <CircularProgressWithLabel value={90} />
        <Typography>Progreso de tu aplicación</Typography>
      </Stack>
      <ProgressCard
        stepByGroups={progressGroupNodes}
        currStep={activeStep}
        collapsed
        isLastStep
      />
    </Stack>
  );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        {...props}
        variant="determinate"
        sx={{ color: "#33CCCC" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          maxWidth={40}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function TitleAndDesc() {
  return (
    <Stack id="title-and-desc" alignItems="center">
      <Typography variant="h5">¡Estás a un clic!</Typography>
      <Typography maxWidth={300}>
        Una <HighlightText>revisión a los datos</HighlightText> que ingresaste,
        ¡y que comience la{" "}
        <Typography component="span" color="primary">
          subasta hipotecaria
        </Typography>
        !
      </Typography>
    </Stack>
  );
}
