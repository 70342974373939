import { Skeleton } from "@mui/material";

export const BidFilesSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={48}
      sx={{ marginTop: "1rem" }}
    />
  );
};
