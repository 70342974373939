import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  genReportByAccountId,
  getTruelayerAccounts,
  TruelayerAccount,
} from "services/api/truelayer.service";
import { MainClientLayout } from "shared/layouts";
import Swal from "sweetalert2";
import logger from "utils/logger";
import { AlertConfetti } from "shared/components";

// the route that call this component will be call from the api, example:
// http://localhost:3000/callback/truelayer?mid=739c3715-ea09-4975-813e-4bb2229e40b3&solicitor_type=1

export default function TrueLayer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [accountIdSelected, setAccountIdSelected] = useState<string>();
  const [accounts, setAccouts] = useState<TruelayerAccount[]>([]);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const mid = searchParams.get("mid");
    const solicitor = searchParams.get("solicitor_type");
    if (mid && solicitor) {
      getTruelayerAccounts({ mid, solicitor })
        .then((res: AxiosResponse<{ results: TruelayerAccount[] }>) => {
          setAccouts(res.data.results);
        })
        .catch((e: any) => {
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
          );
        });
    }
  }, []);

  const submitAccount = () => {
    const mid = searchParams.get("mid");
    const solicitor = searchParams.get("solicitor_type");
    if (accountIdSelected && mid && solicitor) {
      toast.success("La generación del documento esta en processo. 📑", {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: "doc generation",
      });
      genReportByAccountId({ accountId: accountIdSelected, mid, solicitor })
        .then((res) => {
          setSuccess(true);
          onTrueLayerSuccess({ mid, solicitor });
        })
        .catch((e) => {
          logger.error(e);
          toast.error(
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: "doc generation",
            }
          );
          navigate(
            `/client/profile/documents?mid=${mid}&solicitor_type=${solicitor}&error_true_layer=tryagain`
          );
        });
    }
  };

  return (
    <MainClientLayout>
      <>
        <AlertConfetti success={success} />
        {accounts && accounts.length > 0 && (
          <Container maxWidth="xs" sx={{ padding: "1rem" }}>
            <Card
              id="successGenReport"
              sx={{
                boxShadow: "rgba(0, 0 , 0, 0.35) 0px 5px 55px",
              }}
            >
              <CardHeader title="Seleccione una cuenta" />
              <CardContent>
                <List>
                  {accounts.map((a) => {
                    return (
                      <ListItem
                        key={a.account_id}
                        secondaryAction={
                          accountIdSelected === a.account_id ? (
                            <CheckCircleOutlineIcon color={"primary"} />
                          ) : null
                        }
                        onClick={() => {
                          setAccountIdSelected(a.account_id);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar src={a.provider.logo_uri} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={a.display_name}
                          secondary={a.account_type}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </CardContent>
              <CardActions sx={{ padding: "16px" }}>
                <Button
                  variant="contained"
                  onClick={submitAccount}
                  disabled={!Boolean(accountIdSelected)}
                >
                  enviar
                </Button>
              </CardActions>
            </Card>
          </Container>
        )}
      </>
    </MainClientLayout>
  );
}

export function onTrueLayerSuccess({
  mid,
  solicitor,
}: {
  mid: string;
  solicitor: string;
}) {
  const url = `/client/profile/documents?mid=${mid}&solicitor_type=${solicitor}`;
  Swal.fire({
    title: "¡Enhorabuena!",
    text: "¡Tu extracción bancaria fue generada correctamente!, está página se redirigirá en 5 segundos o puedes dar clic en aceptar para seguir cargando tu otra documentación.",
    icon: "success",
    confirmButtonText: "Aceptar",
  }).then((res) => {
    if (res.isConfirmed) {
      window.location.replace(url);
    }
  });
  setTimeout(() => {
    Swal.close();
    window.location.replace(url);
  }, 5000);
}
