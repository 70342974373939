import { FunctionComponent, ReactElement, useState } from "react";
import {
  Card,
  CardContent, Grid,
  Box
} from "@mui/material";

import styles from "./styles.module.scss";

export interface ISearchBarProps {
  children: JSX.Element;
  onChange: (searchText: string) => void;
}
const SearchBar: FunctionComponent<ISearchBarProps> = ({
  children,
  onChange,
}): ReactElement<any> => {
  const [searchText, setSearchText] = useState("");

  return (
    <Card sx={{ marginTop: ".7rem" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12} xs={12}>
            {/* <TextField
              placeholder="Buscar"
              size="small"
              fullWidth
              value={searchText}
              onChange={({ target }) => {
                setSearchText(target.value);
                onChange(target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>

          <Grid item md={8} sm={12} xs={12}>
            <Box className={styles.ChildWrapper}>{children}</Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SearchBar;
