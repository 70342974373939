import { TextField, TextFieldProps } from "@mui/material";
import { memo, ReactNode } from "react";
import {
  Controller,
  FieldValues,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

type CustomTexFieldProps = TextFieldProps & {
    methods: UseFormReturn;
    name: string;
    label?: ReactNode;
    lowercase?: boolean
  }

const MemoTextFieldInput = memo(
  ({
    methods,
    name,
    label,
    lowercase,
    ...rest
  }: CustomTexFieldProps) => {
    return (
      <Controller
        control={methods.control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          const fieldWithoutRef = { ...field, ref: undefined };
          return (
            <ReactScrollWheelHandler preventScroll={true}>
              <TextField
                {...fieldWithoutRef}
                {...rest}
                autoComplete="off"
                inputRef={field.ref}
                onBlur={(e) => {
                  let newVal = e.target.value.trim()
                  if(lowercase){
                    newVal = newVal.toLowerCase()
                  } 
                  field.onChange(newVal);
                  rest.onBlur?.(e)
                  field.onBlur();
                }}
                onChange={(e) => {
                  if(rest.onChange){
                    rest.onChange(e)
                  }
                  field.onChange(e)
                }}
                error={!!error}
                required
                variant="outlined"
                size="small"
                fullWidth
                label={label}
                helperText={error?.message ? error.message : ""}
              />
            </ReactScrollWheelHandler>
          );
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.methods.formState.isDirty === nextProps.methods.formState.isDirty
  // && prevProps.methods.formState.errors !== nextProps.methods.formState.errors
);

export default function TextFieldInput({
  name = "",
  label,
  ...rest
}: Omit<CustomTexFieldProps, 'methods'>) {
  const methods = useFormContext();

  return (
    <MemoTextFieldInput name={name} label={label} methods={methods} {...rest} />
  );
}
