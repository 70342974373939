import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectProps,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface MenuItemOption {
  value: string | number;
  label: string;
}

interface SelectInputProps extends SelectProps {
  menuItems?: MenuItemOption[];
}

export default function SelectInput({
  name = "",
  label,
  menuItems,
  children,
  ...rest
}: SelectInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const fieldWithoutRef = { ...field, ref: undefined };
        return (
          <FormControl fullWidth size="small" error={Boolean(error)}>
            <InputLabel>{label}</InputLabel>
            <Select
              {...rest}
              {...fieldWithoutRef}
              inputRef={field.ref}
              error={Boolean(error)}
              label={label}
            >
              {!menuItems && children}
              {menuItems &&
                menuItems.length > 0 &&
                menuItems.map((m) => (
                  <MenuItem key={m.value} value={m.value}>
                    {m.label}
                  </MenuItem>
                ))}
            </Select>
            {Boolean(error) && (
              <FormHelperText>{error?.message}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
