import { ComponentType } from "react";
import { MortgageStepBaseDirector, IMFormStepBuilder, StepView } from ".";
import {
  UserHasLastYearTaxForm,
  UserProfesionForm,
  UserWorkDateForm,
} from "../components/formSteps";
import { MortgageFormData } from "shared/types/postMortgageData";

export class WorkInfoDataBuilder implements IMFormStepBuilder {
  private initialStepView: Pick<
    StepView<MortgageFormData>,
    "group" | "subGroup"
  > = {
    group: "form",
    subGroup: "workInfo",
  };
  _stepViews: StepView<MortgageFormData>[] = [];

  addStep(
    Component: ComponentType<any>,
    accesor?: StepView<MortgageFormData>["accesor"]
  ) {
    this._stepViews.push({
      ...this.initialStepView,
      component: Component,
      accesor,
    });
    return this;
  }
  addUserProfession() {
    this.addStep(UserProfesionForm, {
      path: "user.profession",
      label: "Profesión",
    });
    return this;
  }
  addUserWorkDate() {
    this.addStep(UserWorkDateForm, {
      path: "user.work_date",
      label: "Fecha de inicio de su trabajo actual",
    });
    return this;
  }
  addUserhas_last_year_tax() {
    this.addStep(UserHasLastYearTaxForm, {
      path: "user.has_last_year_tax",
      label: "Declaración de Renta",
    });
    return this;
  }

  build(): StepView<MortgageFormData>[] {
    return this._stepViews;
  }
}

export class WorkInfoDataDirector implements MortgageStepBaseDirector {
  private builder: WorkInfoDataBuilder;

  constructor(builder: WorkInfoDataBuilder) {
    this.builder = builder;
  }

  buildNewMortgage() {
    // this.builder.addUserProfession();
    // this.builder.addUserWorkDate();
    this.builder.addUserhas_last_year_tax();
  }
  buildImproveMortgage() {
    this.buildNewMortgage();
  }

  buildRaiseCapital() {
    this.buildNewMortgage();
  }
  buildAutopromotor() {
    this.buildNewMortgage();
  }
}
