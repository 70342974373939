import { useRef, useEffect } from "react";

export default function useDebounce(time: number = 500) {

  const timerRef = useRef<number>();
  useEffect(() => {
    return () => {
      window.clearTimeout(timerRef.current);
    }
  },[])

 return function (cb: () => void) {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
    const timer = window.setTimeout(() => {
      cb()
      // setValue(name, valWithK.toString());
    }, time);
    timerRef.current = timer;
 }
}