import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";
import { FastFAQs } from "shared/components";
import styles from "styles.module.scss";

export default function DocListFaq() {
  return (
    <FastFAQs
      header={true}
      data={[
        {
          title: "¿Cómo obtener los documentos para mi hipoteca? 📑 ",
          caption: "Lista de documentos",
          content: (
            <Box className="container-table">
              <TableContainer sx={{ maxHeight: 850 }}>
                <Table stickyHeader className="table-documents">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Documento</TableCell>
                      <TableCell align="center">¿Quién lo necesita?</TableCell>
                      <TableCell align="center">Institución</TableCell>
                      <TableCell align="center">Detalle</TableCell>
                      <TableCell align="center">¿Dónde descargarlos?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableRow>
                    <TableCell align="center">Nota Simple</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">
                      Registro de la propiedad
                    </TableCell>
                    <TableCell>
                      La principal función de la nota simple es informar a los
                      interesados sobre la titularidad de un inmueble,
                      así como sus cargas y gravámenes. Se trata de un resumen
                      conciso de la información que aparece en el Registro de la
                      Propiedad sobre una determinada finca.
                    </TableCell>
                    <TableCell>
                      Puedes pedir la nota simple de forma presencial en el
                      Registro de la Propiedad al que corresponda el inmueble o
                      bien a través de la tramitación online, en la web del
                      Colegio de Registradores. Si solicitas la nota simple de
                      manera online, la recibirás automáticamente al correo
                      electrónico que indicaste en la solicitud.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Vida Laboral</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">Seguridad Social</TableCell>
                    <TableCell>
                      El informe de vida laboral es oficial. Se trata de
                      un informe emitido por la Tesorería General de la
                      Seguridad Social, entidad gestora de la Seguridad Social
                      que gestiona todo lo relacionado con la afiliación al
                      Sistema (altas, bajas y variación de datos) La Vida
                      laboral es el tiempo acumulado por un trabajador durante
                      la vida activa que haya trabajado en una o varias empresas
                      u organismos oficiales
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.seg-social.gob.es/wps/portal/sede/sede/Inicio"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Cotizacion SS</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">Seguridad Social</TableCell>
                    <TableCell>
                      El informe de bases de cotización es el
                      documento que engloba la información relativa a la
                      retribución mensual bruta y los porcentajes de retención
                      aplicados a todo trabajador durante los períodos en
                      los que ha estado de alta en los regímenes de la Seguridad
                      Social. La cotización a la Seguridad Social es la cuota
                      que tanto empleados como empleadores deben de abonar al
                      Estado como aportación
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.seg-social.gob.es/wps/portal/sede/sede/Inicio"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Situacion SS</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">Seguridad Social</TableCell>
                    <TableCell>
                      El certificado de estar al corriente de pago con
                      la Seguridad Social es un documento acreditativo para las
                      personas físicas y jurídicas sobre el estado de deudas con
                      la Tesorería General de la Seguridad Social
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.seg-social.gob.es/wps/portal/sede/sede/Inicio"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Renta M 100I</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El modelo 100 permite hacer la devolución o ingreso del
                      IRPF, es decir, es el modelo al que comúnmente nos
                      referimos como «Declaración de la Renta«. Normalmente el
                      modelo 100 se presenta entre los meses de mayo y junio,
                      aunque algunos días pueden variar ligeramente en función
                      del calendario.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">CIRBE</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">Banco de España</TableCell>
                    <TableCell>
                      El CIRBE o Central de Información de Riesgos del Banco de
                      España es, tal y como indica su propio nombre, una central
                      de información en la que aparecen los préstamos, letras,
                      pagarés y obligaciones en general que su deudor haya
                      aceptado que tiene pendiente con otro particular o empresa
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://www.bde.es/bde/es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Deudas AEAT</TableCell>
                    <TableCell align="center">Empleado y Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      Consulta de Deudas con la Agencia Estatal de
                      Administracion Tributaria
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M111 - IRPF</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El modelo 111 de Hacienda es el que contiene la
                      declaración trimestral de las retenciones e ingresos a
                      cuenta del IRPF ejercidas a trabajadores, profesionales o
                      empresarios. Una empresa tiene que declarar, mediante
                      el modelo 111, las retenciones e ingresos a cuenta
                      del IRPF ejercidas a los trabajadores o profesionales.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M130 - IRPF</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El Modelo 130 es una declaración trimestral del impuesto
                      de la renta de las personas físicas (IRPF) en el que se
                      liquida el pago fraccionado del impuesto. Por ello, estos
                      contribuyentes están obligados a efectuar cuatro pagos
                      fraccionados trimestralmente a cuenta de la futura
                      declaración de la renta anual
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M190 - IRPF</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El modelo 190 es el resumen anual de las retenciones
                      practicadas en el IRPF a trabajadores vía nómina, a
                      empresarios o profesionales autónomos mediante factura.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M200</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El modelo 200 sirve para realizar la declaración o
                      liquidación anual del Impuesto de Sociedades. Todos los
                      contribuyentes del impuesto de sociedades deberán
                      presentar este modelo y deberá ir firmado por él o los
                      representantes de la empresa con la potestad para hacerlo.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M347</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El Modelo 347 de la AEAT es la declaración anual de
                      operaciones con terceras personas que presentan personas
                      físicas (autónomos) y jurídicas (sociedades), públicas y
                      privadas, que en el desarrollo de su actividad empresarial
                      o profesional facturan operaciones por una cifra superior
                      a los 3.005,06 € anuales.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M303 - IVA</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El modelo 303 es el formulario de declaración trimestral
                      del IVA (Impuesto sobre el Valor Añadido). Se trata del
                      pago fraccionado del IVA que los autónomos y empresas
                      deben presentar a Hacienda de forma trimestral a través de
                      las facturas emitidas.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">M390 - IVA</TableCell>
                    <TableCell align="center">Autónomo</TableCell>
                    <TableCell align="center">Agencia Tributaria</TableCell>
                    <TableCell>
                      El modelo 390 es una declaración informativa de resumen
                      anual de las operaciones relativas a la liquidación
                      del IVA. Se trata pues del equivalente anual
                      del modelo 303 pero extendido, que se se presenta con el
                      cierre contable de cada ejercicio.
                    </TableCell>
                    <TableCell align="center" className="link-container">
                      <Link
                        className="links"
                        href="https://sede.agenciatributaria.gob.es/"
                        target="_blank"
                      >
                        Ver más detalles 🔍
                      </Link>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Box>
          ),
        },
      ]}
      title="Preguntas frecuentes"
      caption="Encuentre respuestas a las preguntas acerca del proceso de carga de documentos."
    />
  );
}
