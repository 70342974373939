import React, { FunctionComponent, useEffect, useState } from "react";

import {
  Typography,
  Slider,
  TextField,
  InputAdornment,
  Grid,
  Tooltip,
  Switch,
  Collapse,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoRounded";

import styles from "../styles.module.scss";
import { allowFilterValuesKeys } from "../utils";

type Abc = keyof typeof allowFilterValuesKeys;

export interface filterRangeSelected {
  title: string;
  name: string;
  minFilterSelected: number;
  maxFilterSelected: number;
  stepFilterSelected?: number;
  labelFilterSelected?: (data: any) => any;
  filtersSelected: any;
  onChangeFilter: (data: any, active: boolean) => void;
  onChangeInputOne: (data: any, active: boolean) => void;
  onChangeInputTwo: (data: any, active: boolean) => void;
  labelIconInputOne?: string;
  labelIconInputTwo?: string;
  sliderValue: any;
  inputOneValue: any;
  inputTwoValue: any;
  titleTooltip?: string;
  showInputs?: boolean;
  showTooltip?: boolean;
}
const FilterRangeSelected: FunctionComponent<filterRangeSelected> = ({
  title,
  name,
  minFilterSelected,
  maxFilterSelected,
  stepFilterSelected,
  filtersSelected,
  labelFilterSelected,
  onChangeFilter,
  onChangeInputOne,
  onChangeInputTwo,
  labelIconInputOne,
  labelIconInputTwo,
  sliderValue,
  inputOneValue,
  inputTwoValue,
  titleTooltip,
  showInputs,
  showTooltip,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (filtersSelected) {
      setShowFilter(filtersSelected[allowFilterValuesKeys[name as Abc]]);
    }
  });

  const equalsCheck = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  useEffect(() => {
    Object.keys(allowFilterValuesKeys).forEach((key) => {
      if (key === name) {
        setShowFilter(filtersSelected[allowFilterValuesKeys[key as Abc]]);
      }
    });
  }, [filtersSelected]);

  useEffect(() => {
    if (
      showFilter &&
      equalsCheck([minFilterSelected, maxFilterSelected], sliderValue)
    ) {
      onChangeFilter([minFilterSelected, maxFilterSelected], showFilter);
    }
  }, [showFilter]);

  return (
    <React.Fragment>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        {showTooltip && (
          <Tooltip title={titleTooltip || ""} placement="right-end">
            <InfoIcon sx={{ width: "1rem", color: "#656565" }} />
          </Tooltip>
        )}
        <Switch
          checked={showFilter}
          onChange={() => {
            setShowFilter((prevState: any) => {
              let val = !prevState;
              if (val === false) {
                onChangeFilter([minFilterSelected, maxFilterSelected], false);
              }
              return val;
            });
          }}
        />
      </Typography>

      <Collapse in={showFilter}>
        <Slider
          valueLabelDisplay="auto"
          size="small"
          sx={{ marginBottom: "1rem" }}
          min={minFilterSelected}
          max={maxFilterSelected}
          step={stepFilterSelected}
          value={sliderValue}
          valueLabelFormat={(c: any) => {
            return labelFilterSelected ? labelFilterSelected(c) : c;
          }}
          // getAriaValueText={(valuetext) => {
          //   return `${valuetext.toFixed(2)} €`;
          // }}
          onChange={(event, newValue) => {
            onChangeFilter(newValue, showFilter);
          }}
        />
        {
          <Grid
            className={showInputs === false ? styles.hideInputsFilters : ""}
            container
            columnSpacing={1}
            rowSpacing={1}
          >
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                fullWidth
                id="outlined-helperText"
                defaultValue={inputOneValue}
                label="Desde"
                value={inputOneValue}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {labelIconInputOne}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode:'numeric',
                  min: minFilterSelected,
                  max: maxFilterSelected,
                }}
                onChange={(value) => {
                  onChangeInputOne(value.target.value, showFilter);
                }}
                error={Number(inputOneValue) < minFilterSelected}
                helperText={
                  Number(inputOneValue) < minFilterSelected
                    ? `Valor mínimo ${minFilterSelected}`
                    : ""
                }
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                fullWidth
                id="outlined-helperText"
                defaultValue={inputTwoValue}
                label="Hasta"
                type="number"
                value={inputTwoValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {labelIconInputTwo}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  inputMode:'numeric',
                  min: minFilterSelected,
                  max: maxFilterSelected,
                }}
                onChange={(value) => {
                  onChangeInputTwo(value.target.value, showFilter);
                }}
                error={Number(inputTwoValue) > maxFilterSelected}
                helperText={
                  Number(inputTwoValue) > maxFilterSelected
                    ? `Valor máximo ${maxFilterSelected}`
                    : ""
                }
              />
            </Grid>
          </Grid>
        }
      </Collapse>
    </React.Fragment>
  );
};

export default FilterRangeSelected;
