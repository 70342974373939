import { Profile, Role } from "shared/types/Profile";
import axios, { axiosWithoutResInterceptor } from "../../utils/axios";
import { BaseApiSuccessRes } from "shared/types/BaseApiResponse";

export interface ISignInModel {
  username: string;
  password: string;
}

export interface SignInRes {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  "not-before-policy": number;
  session_state: string;
  scope: string;
  role: Role;
  onesignal: Onesignal;
}

export interface Onesignal {
  onesignal_unique_id: string;
  onesignal_hash_id: string;
}

const signIn = (data: ISignInModel) => {
  const user: ISignInModel = {
    username: data.username,
    password: data.password,
  };
  return axios.post<BaseApiSuccessRes<SignInRes>>(
    `${process.env.REACT_APP_API_URL}/users/login`,
    user
  );
};

export interface SignUpAdditional {
  [k: string]: string;
}
export interface ISignUpModel {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  phone: string;
  additional: SignUpAdditional;
  otp_code: string;
  /**
   * this tell the endpoint to also login and get the accesstoken
   */
  should_login?: boolean;
}

export interface SignUpRes {
  id: string;
  user: string;
  created: string;
  firstname: string;
  lastname: string;
  phone: string;
  last_access: any;
  additional: SignUpAdditional;
  files: any[];
  has_mortgages: boolean;
}

const signUp = (user: ISignUpModel) => {
  return axios.post<BaseApiSuccessRes<SignUpRes | SignInRes>>(
    `${process.env.REACT_APP_API_URL}/users/register`,
    user
  );
};

const sendOptCodeEmail = (email: string) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/users/otp_mail?email=${email}`
  );
};
const validateOtpCode = (code: string, email: string) => {
  return axios.post<BaseApiSuccessRes<{}>>(
    `${process.env.REACT_APP_API_URL}/users/otp_code`,
    {
      code,
      email,
    }
  );
};

export interface IresetPasswordModel {
  email: string;
}
const resetPassword = (data: IresetPasswordModel) => {
  const user = {
    email: data.email,
  };

  // return axios.get(`${process.env.REACT_APP_API_URL}/users/reset_pw_request`, user)
  return axios.get<BaseApiSuccessRes<{}>>(
    `${process.env.REACT_APP_API_URL}/users/reset_pw_request?username=${user.email}`
  );
};

export interface IclientMagicLinkModel {
  email: string;
}
const clientMagicLink = (data: IclientMagicLinkModel) => {
  const user = {
    email: data.email,
  };

  // return axios.get(`${process.env.REACT_APP_API_URL}/users/reset_pw_request`, user)
  return axios.get<BaseApiSuccessRes<{}>>(
    `${process.env.REACT_APP_API_URL}/users/login_magic_link?username=${user.email}`
  );
};

export interface IchangePasswordModel {
  newpassword: string;
}
const changePassword = (data: IchangePasswordModel) => {
  return axios.post<BaseApiSuccessRes<{}>>(
    `${process.env.REACT_APP_API_URL}/users/reset_pw`,
    {
      new_password: data.newpassword,
    }
  );
};

//Todo make this signal required
const userProfile = (signal?: AbortSignal) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/profile`, { signal });
};

const acceptOptIn = () => {
  return axios.patch<BaseApiSuccessRes<string>>(
    `${process.env.REACT_APP_API_URL}/users/opt_in`
  );
};

const getProfileWithOutInterceptor = (signal?: AbortSignal) => {
  return axiosWithoutResInterceptor.get(
    `${process.env.REACT_APP_API_URL}/profile`,
    { signal }
  );
};
const userPostProfile = (data: Profile) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/profile/`, data);
};

const checkEmail = (email: string) => {
  return axios.get<BaseApiSuccessRes<{}>>(
    `${process.env.REACT_APP_API_URL}/users/check_email`,
    {
      params: { email },
    }
  );
};

const oauthSignin = (idp: "google") => {
  return axios.get<BaseApiSuccessRes<{ authorization_url: string }>>(
    `${process.env.REACT_APP_API_URL}/users/oauth/redirect`,
    {
      params: { idp },
    }
  );
};

export {
  signIn,
  signUp,
  resetPassword,
  changePassword,
  clientMagicLink,
  userProfile,
  acceptOptIn,
  getProfileWithOutInterceptor,
  sendOptCodeEmail,
  validateOtpCode,
  userPostProfile,
  checkEmail,
  oauthSignin,
};
