import React, { FunctionComponent, ReactElement, ReactNode, useEffect } from "react";
import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import styles from "./styles.module.scss";

export interface ItemFAQ {
  title: string;
  caption: string;
  content: ReactNode;
}

export interface IFastFAQsProps {
  data: ItemFAQ[];
  title: string;
  caption: string;
  header: boolean;
}
const FastFAQs: FunctionComponent<IFastFAQsProps> = ({
  data,
  title,
  caption,
  header = true,
}): ReactElement<any> => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {header && (
        <>
          <Divider component="br" />
          <LiveHelpOutlinedIcon className={styles.Icon} color="primary" />
          <Typography variant="h6" className={styles.Title}>
            {title}
          </Typography>
          <Typography variant="body2" className={styles.Caption}>
            {caption}
          </Typography>
          <Divider component="br" />
        </>
      )}
      {data.map((d: ItemFAQ, i: number) => {
        return (
          <Accordion
            key={i}
            expanded={expanded === i}
            onChange={handleChange(i)}
            className={styles.AccordionWrapper}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ArrowRightIcon className={styles.AccordionIconTitle} />
              <Typography className={styles.AccordionTitle}>
                {d.title}
              </Typography>
              <Typography className={styles.AccordionCaption}>
                {d.caption}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            {d.content}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default FastFAQs;
