import { Box, TextField, InputAdornment, useMediaQuery } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  MobileDatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers";

import { Controller, useFormContext, Control } from "react-hook-form";
import { sub } from "date-fns";
import { dateFormat } from "utils";
import { useState } from "react";
import { isoToDate } from "utils/date";
interface RenderDateProps {
  control?: Control;
  name: string;
  label?: string;
  adultAge?: Date;
  minDate?: Date;
  userWork?: {
    userWorkMonth: "user.work_init_month" | "user_2.work_init_month";
    userWorkYear: "user.work_init_year" | "user_2.work_init_year";
  };
}

export default function DatePicker({
  name = "",
  label,
  userWork,
  ...rest
}: RenderDateProps & Partial<DatePickerProps<Date, Date>>) {
  const { control, getValues } = useFormContext();
  const [value, setValue] = useState<string>(getValues(name));
  const date = new Date();
  // const adultAge = sub(date, { years: 18 });
  const minDate = sub(date, { years: 80 });

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          const noIsoValue = isoToDate(value);
          if (isSmallScreen) {
            return (
              <TextField
                {...field}
                type="date"
                InputLabelProps={{ shrink: true }}
                value={value?.length > 10 ? noIsoValue : value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onBlur={(e) => {
                  if (e.target.value) {
                    const date = new Date(e.target.value);
                    date.setDate(date.getDate() + 1);
                    field.onChange(date.toISOString());
                  }
                  field.onBlur();
                }}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                size="small"
                fullWidth
                label={label}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon />
                    </InputAdornment>
                  ),
                }}
              />
            );
          }
          const fieldWithoutRef = { ...field, ref: undefined };
          return (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <MobileDatePicker
                {...fieldWithoutRef}
                {...rest}
                inputRef={field.ref}
                onChange={(val) => {
                  field.onChange(val?.toISOString());
                }}
                showDaysOutsideCurrentMonth
                onAccept={() => {
                  field.onBlur();
                }}
                onClose={() => {
                  field.onBlur();
                }}
                label={label}
                inputFormat={rest.inputFormat || dateFormat}
                minDate={minDate}
                reduceAnimations={true}
                showToolbar={false}
                renderInput={(params) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TextField
                      {...params}
                      onBlur={() => {
                        field.onBlur();
                      }}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      size="small"
                      fullWidth
                      label={label}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
              />
            </LocalizationProvider>
          );
        }}
      />
    </LocalizationProvider>
  );
}

