import React, { useState } from "react";

import {
  Typography,
  Collapse,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Divider,
} from "@mui/material";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ExpandMore from "./ExpandMore";

import styles from "../styles.module.scss";
import { defaultFiltersForTypes } from "../helpers";
import { propertyExistenceOptions } from "shared/components/MortgageForm/utils";

type Props = {
  filters: any;
  readOnly: boolean;
  changeValue: (key: string, value: any) => void;
};

// convert this to propertyexistence
export default function PropertyExistenceFilters({
  readOnly,
  filters,
  changeValue,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;
    changeValue("mortagagePropertyExistence", value);
  };

  readOnly = false;

  return (
    <>
      <Typography variant="h6" className={styles.tittleFilter}>
        <MapsHomeWorkIcon
          sx={{
            color: "#656565",
            marginRight: "1rem",
          }}
        />
        Tipos de vivienda
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
          style={{
            float: "right",
          }}
        >
          {" "}
          <ExpandMoreIcon />
        </ExpandMore>
      </Typography>

      {readOnly ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FormControl>
            <RadioGroup
              value={filters.mortagagePropertyExistence}
              onChange={handleOnChange}
            >
              {propertyExistenceOptions.map((p, i) => (
                <FormControlLabel
                  key={p.label}
                  className={styles.LabelCheckbox}
                  label={p.label}
                  value={p.value}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Collapse>
      ) : (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {propertyExistenceOptions.map((p, i) => {
            let elements = Array.isArray(filters.propertyExistence)
              ? filters.propertyExistence
              : filters.propertyExistence
                  .split(",")
                  .map(function (item: string) {
                    return parseInt(item, 10);
                  });
            const hasElement = elements ? elements.length > 0 : false;
            const pVal = Number(p.value)
            const currVal: number[] = elements;

            const isCheck = hasElement ? currVal.includes(pVal) : false;

            return (
              <FormControlLabel
                key={p.value}
                className={styles.LabelCheckbox}
                label={p.label}
                control={
                  <Checkbox
                    checked={isCheck}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let val = currVal;
                      if (currVal.includes(pVal)) {
                        val = currVal.filter((v) => v !== pVal);
                      } else {
                        val.push(pVal);
                      }

                      changeValue("propertyExistence", val);
                    }}
                  />
                }
              />
            );
          })}
        </Collapse>
      )}
    </>
  );
}
