import { memo } from "react";

import { Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { ICellRendererParams } from "ag-grid-community";

export default (colId: string, closeSuccessFullies: string[]) =>
  memo(
    (props: ICellRendererParams) => {
      const cellValue = props.valueFormatted
        ? props.valueFormatted
        : props.value;

      const buttonClicked = (e: any) => {
        props.api.stopEditing();
        // trigger the upload dialog
        props.node.setDataValue(colId, props.value.value);
      };
      if (closeSuccessFullies.includes(props.value.value)) {
        return (
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              marginBottom: "0.6rem",
            }}
          >
            <span>{cellValue}</span>
            {props.value.is_frozen && (
              <Chip label="Pendiente de firma" color="warning" />
            )}
            {/* {!props.value.is_frozen && props.value.is_editable && (
              <Chip
                icon={<EditIcon />}
                onClick={buttonClicked}
                label="Editar"
                color="info"
              />
            )} */}
          </span>
        );
      }
      return cellValue;
    },
    (prevP: ICellRendererParams, nextP: ICellRendererParams) => {
      return prevP.value === nextP.value;
    }
  );
