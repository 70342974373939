import { useState, useEffect } from "react";
import { getMortgage } from "services/api";

// Recuerda siempre usar la palabra "use" al principio de cada custom hook
const useMortgageBidAccepted = (id: string | undefined) => {
  const [bid, setBidAccepted] = useState<any>(null);
  useEffect(() => {
    if (id) {
      getMortgage(id).then((response: any) => {
        const dataBids = response.data.results.bids;

        dataBids.asforEach((b: any) => {
          if (b?.accepted[0]?.solicitor_accepted) {
            setBidAccepted(b);
          }
        });
      });
    }
  }, []);

  return bid;
};

export default useMortgageBidAccepted;
