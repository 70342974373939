import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  useRef,
} from "react";

import { TextField, TextFieldProps } from "@mui/material";

import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import { formatCurrency, formatNumberToCurrency } from "../../../utils/helpers";

export interface CurrencyFieldProps {
  value: string,
  onChange:(math: {
    value: number;
    formattedAmount: any;
    formattedAmountWithSymbol: string;
}) => void; 
}

const CurrencyField: FunctionComponent<TextFieldProps & CurrencyFieldProps> = (props): ReactElement<any> => {
  const [data, setData] = useState({
    value: 0,
    formattedAmount: "",
    formattedAmountWithSymbol: "",
  });

  const format = (amount: string) => {
    const cleanValue = String(amount).replace(/[^\d.]/g, "");

    const math = {
      value: Number(Number(cleanValue).toFixed(2)),
      formattedAmount: formatNumberToCurrency(cleanValue),
      formattedAmountWithSymbol: formatCurrency(cleanValue),
    };

    return math;
  };

  useEffect(() => {
    const newData = format(props.value);
    setData({ ...newData });
  }, [props.value]);

  return (
    <ReactScrollWheelHandler preventScroll={true}>
      <TextField
        {...props}
        value={data.formattedAmount || ""}
        onChange={({ target }) => {
          const math = format(target.value);
          setData({ ...math });
          props.onChange(math);
        }}
      />
    </ReactScrollWheelHandler>
  );
};

export default CurrencyField;
