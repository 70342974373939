import { MainBrokerLayout } from "shared/layouts";
import Welcome from "./components/Welcome";
//import { useProfile } from "shared/hooks";
//import ViewDashboard from "./components/ViewDashboard";
//import { BidderProfile } from "shared/types/Profile";
import { Stack, Typography } from "@mui/material";
import { LinkText } from "shared/components";

function ColibidData() {
  //const { profile } = useProfile<BidderProfile>();
  //const hasPBISub = Boolean(profile?.subscriptions.length);
  return (
    <MainBrokerLayout>
      <Stack height="100%" justifyContent="space-between" gap={2}>
        <Welcome />
        {/* {profile && hasPBISub ? (
          <ViewDashboard profile={profile} />
        ) : (
          <Welcome />
        )} */}
        <Footer />
      </Stack>
    </MainBrokerLayout>
  );
}
export default ColibidData;

function Footer() {
  return (
    <Typography variant="body2" textAlign="center" paddingBottom={2}>
      ¿Tienes dudas? Contactanos a <LinkText email/> o al <LinkText whatsapp/> 
    </Typography>
  );
}

/**This helper fn will always get the last subs that is type power bi, just in case there is more
 * subs of pbi, the last one if the recent one (depend on the sorting from the backend)
 */
// function getLastPBISubscription(products?: BidderSubscription[] ) {
//   if (products) {
//     const pbs = products.filter((s) => s.product_type === "power_bi_report").slice(-1);
//     if (pbs.length > 0) return pbs[0];
//   }
//   return undefined;
// }
