import {
  IMG_BASE_URL,
  TRUE_LAYER,
} from "shared/constants/links";

export const getImgBaseUrl = (name: string) => {
  return `${IMG_BASE_URL}${name}`;
};

export const getTrueLayerLink = ({
  mid,
  user,
}: {
  mid: string;
  user: 1 | 2;
}) => {
  // "https://auth.truelayer-sandbox.com/?response_type=code&client_id=colibid-f1d0ba&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=http://localhost/api/v1/services/truelayer/session&providers=uk-cs-mock%20uk-ob-all%20uk-oauth-all&state=0c991551-2f69-4a4e-acc9-d5924c9ac9b3,1"

  return `https://auth.${TRUE_LAYER.auth}.com/?response_type=code&client_id=${process.env.REACT_APP_TRUE_LAYER_CLIENT_ID}&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=${TRUE_LAYER.callBack}&providers=${TRUE_LAYER.providers}&state=${mid},${user}`;
};
