import { Typography, Button, Stack, Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ColibidTypography,
  HStack,
  MortgageFullTimeline,
} from "shared/components";
import { MainClientLayout } from "shared/layouts";
import { LinkText } from "shared/components/";
import { getImgBaseUrl } from "utils/links";
import { useEffect } from "react";
import Swal from "sweetalert2";

// https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/customer-service.svg
const FormSuccessMessage = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate("/client/home");
  return (
    <MainClientLayout>
      <Container>
        <Stack alignItems="center" padding="1rem 0" gap={1}>
          <Title />
          <Typography>¡Tu subasta ya está activa! </Typography>
          <Button variant="outlined" onClick={handleClick}>
            Panel principal
          </Button>
          <Typography>Te contamos los puntos clave del proceso:</Typography>
          <MortgageFullTimeline status={"created"} />
          <Typography
            textAlign="center"
            variant="caption"
            fontSize="0.8rem"
            color="#4B4B4B"
            maxWidth={700}
            lineHeight={2.5}
          >
            Seguirás el proceso con la entidad elegida. Podrás ver todas las
            novedades en el panel principal de la plataforma de Colibid. ¿Aún
            tienes dudas? Puedes consultar con nuestro ChatBot o escribirnos por{" "}
            <LinkText whatsapp /> ¡No olvides revisar las notificaciones que te
            llegarán a tu correo y a tu móvil!
          </Typography>
          <HStack gap={2} maxWidth={450} marginTop={3}>
            <Typography
              component="footer"
              color="primary"
              variant="h6"
              textAlign="center"
            >
              BIENVENIDO A LA REVOLUCIÓN HIPOTECARIA
            </Typography>
            <img
              src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/colibid_logo_bird.svg"
              height={50}
            />
          </HStack>
          <LinkLeadSections />
        </Stack>
      </Container>
    </MainClientLayout>
  );
};

export default FormSuccessMessage;

function Title() {
  return (
    <HStack
      id="title"
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{
        backgroundColor: "#E2E2F8",
        position: "relative",
        paddingRight: "60px",
      }}
    >
      <Typography variant="h5" color="primary" align="center">
        ¡Bienvenido a Colibid!{" "}
      </Typography>
      <img
        height={40}
        src="https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/house.png"
        style={{ position: "absolute", right: "5px" }}
      />
    </HStack>
  );
}

const linkBtns = [
  {
    id:'_kreditiweb',
    href: "https://link.colibid.com/kreditiweb",
    imgSrc: getImgBaseUrl("link_leads/800px-Logo_Kreditiweb.png"),
  },
  {
    id:'_softcredit',
    href: "https://link.colibid.com/softcredit",
    imgSrc: getImgBaseUrl("link_leads/SofKredit-logo-prestamos-online.webp"),
  },
  {
    id:'_fintonic',
    href: "https://link.colibid.com/fintonic",
    imgSrc: getImgBaseUrl("link_leads/Mosca-2.png"),
  },
];

function LinkLeadSections() {
  useEffect(() => {
    const waitTimerId = setTimeout(() => {
      Swal.fire({
        imageUrl: getImgBaseUrl("customer-service.svg"),
        imageHeight: 100,
        html: "<p>¡Ahora en Colibid también vas a poder encontrar muchos otros servicios que necesitarás para tu nuevo hogar! </p><p>¿Quieres echar un ojo?</p>",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "VAMOS",
        cancelButtonText: "MÁS TARDE",
      }).then((res) => {
        if (res.isConfirmed) {
          const parentScroll = document.getElementById("content");
          if (parentScroll) {
            parentScroll.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }
        }
      });
    }, 300);
    return () => {
      waitTimerId && clearTimeout(waitTimerId);
    };
  }, []);
  return (
    <>
      <Stack
        alignItems="center"
        gap={3}
        width="100vw"
        paddingY={3}
        sx={{
          backgroundColor: "#F8F8F8",
        }}
      >
        <Stack gap={1} alignItems="center">
          <ColibidTypography fontWeight="bold" variant="h5">
            Desde Colibid te acercamos todo lo que necesites para tu hipoteca,
            en un sólo lugar
          </ColibidTypography>
          <Typography>
            Haciendo clic en cada botón podrás acceder a cada servicio. ¡Buena
            suerte!
          </Typography>
        </Stack>
        <Stack gap={1} alignItems="center">
          <Typography component="mark" className="bg-purple" paddingX={3}>
            Préstamos personales
          </Typography>
          <Typography>
            ¿Necesitas dinero para reformas, muebles o equipamiento?
          </Typography>
        </Stack>
        <HStack gap={2}>
          {linkBtns.map((l) => {
            return (
              <Button
                id={l.id}
                component="a"
                LinkComponent={Paper}
                target="_blank"
                href={l.href}
                sx={{
                  flex: "1",
                  backgroundColor: "white",
                  padding: 2,
                }}
              >
                <img
                  src={l.imgSrc}
                  style={{
                    maxWidth: "100%",
                    height: 150,
                  }}
                />
              </Button>
            );
          })}
        </HStack>
      </Stack>
    </>
  );
}
