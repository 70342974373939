import { Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AlertConfetti } from "shared/components";
import { getImgBaseUrl } from "utils/links";
import { MainBrokerLayout } from "shared/layouts";

function GoCardLess() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/broker/applications')
  }
  const height = window.innerHeight - 64;
  return (
    <MainBrokerLayout>
      <Container
        sx={{
          height,
        }}
      >
        <AlertConfetti height={height} />
        <Stack gap={4} alignItems='center'>
          <img src={getImgBaseUrl("undraw_winners.svg")} height={320} />
          <Typography variant="h5" color="primary" textAlign="center">
            ¡Adquiriste créditos!
          </Typography>
          <Typography textAlign="center">
            La factura por tu compra te llegará vía email. Los créditos tardarán
            máximo 10 minutos en reflejarse. Si no los ves, por favor refresca
            la página.
          </Typography>
          <Typography textAlign="center">¡A por esos clientes!</Typography>
          <Button variant='contained' onClick={handleClick}>¡Vamos!</Button>
        </Stack>
      </Container>
    </MainBrokerLayout>
  );
}
export default GoCardLess;
