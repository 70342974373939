import {
  DialogProps,
  ButtonProps,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ReactNode } from "react";
import CustomAlert, { CustomAlertProps } from "../CustomAlert";

type Props = DialogProps & {
  btnList?: ButtonProps[];
  children?: ReactNode;
  customAlertProps?: CustomAlertProps;
};

export default function CustomDialog({
  btnList,
  children,
  customAlertProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog {...dialogProps}>
      {dialogProps.title && (
        <DialogTitle id="alert-dialog-title">{dialogProps.title}</DialogTitle>
      )}
      {children && (
        <DialogContent>
          {customAlertProps && <CustomAlert {...customAlertProps} />}
          {children}
        </DialogContent>
      )}
      {btnList && btnList.length > 0 && (
        <DialogActions>
          {btnList.map((btn) => (
            <Button key={btn.title} {...btn}>
              {btn.title}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
}
