import { useState, memo, useEffect } from "react";

import { Box, InputAdornment, TextField, TextFieldProps } from "@mui/material";

import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import {
  Controller,
  UseFormReturn,
  useFormContext,
  useWatch,
} from "react-hook-form";

import styles from "./styles.module.scss";
import { MAX_CURRENCY_NUMBER_VALUES } from "shared/constants/mortgageForm";
import { addSeparator, removeNonNumeric } from "utils/string";

const MemoCurrencyFieldWithWrappedText = memo(
  ({
    methods,
    name = "",
    label,
    text,
    maxInput = MAX_CURRENCY_NUMBER_VALUES,
    ...rest
  }: TextFieldProps & {
    methods: UseFormReturn;
    text?: string;
    maxInput?: number;
  }) => {
    const watchVal = useWatch({ control: methods.control, name });
    const [showBubble, setShowBubble] = useState(false);
    const [value, setValue] = useState("");
    useEffect(() => {
      if (watchVal && !Number.isNaN(watchVal)) {
        setValue(addSeparator(Math.ceil(Number(watchVal)).toString()));
      }
    }, [watchVal]);

    return (
      <Controller
        control={methods.control}
        name={name}
        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
          <ReactScrollWheelHandler
            preventScroll={true}
            style={{ width: "100%" }}
          >
            <Box className={styles.FieldWrapper}>
              <TextField
                {...rest}
                label={label}
                onFocus={() => {
                  text && setShowBubble(true);
                }}
                onBlur={(e) => {
                  text && setShowBubble(false);

                  if (rest.onBlur) {
                    rest.onBlur(e);
                  }

                  if (e.target.value.length > 0) {
                    field.onChange(value.replace(/ /g, ""));
                  }
                  if (e.target.value === "0" || e.target.value === "") {
                    field.onChange("");
                  }

                  field.onBlur();
                }}
                // value={formattedWatchVal}
                value={value}
                onChange={(e) => {
                  if (rest.onChange) {
                    rest.onChange(e);
                  }
                  if (e.target.value === "0" || e.target.value === "") {
                    setValue("");
                  } else {
                    let newV = removeNonNumeric(e.target.value);
                    if (Number(newV) > maxInput) {
                      newV = `${maxInput}`;
                    }
                    newV = addSeparator(newV);
                    setValue(newV);
                  }
                }}
                variant="outlined"
                size="small"
                fullWidth
                required={rest.required}
                error={Boolean(error)}
                helperText={Boolean(error) ? error?.message : ""}
                inputProps={{
                  inputMode: "numeric",
                }}
                InputProps={
                  rest.InputProps || {
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    autoComplete: "off",
                  }
                }
              />
              {text && showBubble && (
                <Box
                  className={styles.FieldBubble}
                  sx={{ top: Boolean(error) ? "38px" : "" }}
                >
                  {text}
                </Box>
              )}
            </Box>
          </ReactScrollWheelHandler>
        )}
      />
    );
  },
  (prevProps, nextProps) => {
    const isDirty =
      prevProps.methods.formState.isDirty ===
      nextProps.methods.formState.isDirty;
    // const isError = prevProps.formState.errors !== nextProps.formState.errors;
    const shouldRerender: boolean = isDirty;
    return shouldRerender;
  }
);

const CurrencyFieldWithWrappedText = ({
  text,
  name = "",
  label,
  maxInput,
  ...rest
}: TextFieldProps & { text?: string; maxInput?: number }) => {
  const methods = useFormContext();
  return (
    <MemoCurrencyFieldWithWrappedText
      text={text}
      name={name}
      label={label}
      methods={methods}
      maxInput={maxInput}
      {...rest}
    />
  );
};

export default CurrencyFieldWithWrappedText;
