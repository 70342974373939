import { useMemo, useState, memo, Dispatch, SetStateAction } from "react";

import {
  Radio,
  RadioGroup,
  RadioGroupProps,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormGroupProps,
  CheckboxProps,
  FormControlLabelProps,
} from "@mui/material";

import { Controller, UseFormReturn, useFormContext } from "react-hook-form";
import { InputOption } from "shared/types/misc";

type CheckboxInputProps = {
  name: string;
  methods: UseFormReturn;
  checkboxOptions: InputOption[];
  label: string;
  disabled?: boolean;
  formGroupProps?: FormGroupProps;
  checkBoxProps?: CheckboxProps;
  formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'> 
};
const MemoCheckboxInput = memo(
  ({
    methods,
    checkboxOptions,
    name = "",
    label,
    disabled,
    formGroupProps,
    checkBoxProps,
    formControlLabelProps
  }: CheckboxInputProps) => {
    const { setValue, getValues, watch } = methods;
    const [state, setState] = useState(() => {
      const multiCheckboxVal: any[] = getValues(name);
      const dic: any = {};
      checkboxOptions.forEach((e) => {
        dic[e.label] = multiCheckboxVal.includes(e.value);
      });
      return dic;
    });
    const formGroupValues: any[] = getValues(name);
    return (
      <Controller
        control={methods.control}
        name={name}
        render={({ field, fieldState: { error } }) => {
          const fieldWithoutRef = { ...field, ref: undefined };
          return (
            <FormControl
              sx={{ ml: 1, gap: "1rem" }}
              error={Boolean(error?.message)}
            >
              <FormLabel filled sx={{ color: "black" }}>
                {label}
              </FormLabel>
              <FormGroup {...formGroupProps} row>
                {checkboxOptions.map((e, i) => {
                  return (
                    <FormControlLabel
                      {...formControlLabelProps}
                      key={e.label}
                      control={
                        <Checkbox
                        id={`checkbox-${i}`}
                          {...checkBoxProps}
                          {...fieldWithoutRef}
                          name={e.label}
                          value={e.value}
                          inputRef={field.ref}
                          onChange={(e) => {
                            const targetVal = e.target.value;
                            const vals = getValues(name);

                            if (vals && vals.includes(targetVal)) {
                              const newVals = vals.filter(
                                (e: string) => e !== targetVal
                              );
                              setValue(name, newVals);
                              setState((prevS: any) => ({
                                ...prevS,
                                [e.target.name]: e.target.checked,
                              }));
                              return;
                            }
                            const set = new Set(vals);
                            set.add(targetVal);
                            setValue(name, Array.from(set));
                            setState((prevS: any) => ({
                              ...prevS,
                              [e.target.name]: e.target.checked,
                            }));
                          }}
                          checked={state[e.label]}
                        />
                      }
                      label={e.label}
                    />
                  );
                })}
              </FormGroup>
              {error?.message && (
                <FormHelperText margin="dense">{error.message}</FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.methods.formState.isDirty === nextProps.methods.formState.isDirty
);

const CheckboxInput = (props: Omit<CheckboxInputProps, "methods">) => {
  const methods = useFormContext();

  return <MemoCheckboxInput methods={methods} {...props} />;
};

export default CheckboxInput;
