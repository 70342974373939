import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
  PropsWithChildren,
  useRef,
} from "react";
import { TopBar, Loader } from "../../components";

import subject, { TOGGLE_LOADER } from "../../../utils/subjects";

export interface INotAuthLayoutProps {}

const NotAuthLayout: FunctionComponent<
  PropsWithChildren<INotAuthLayoutProps>
> = ({ children }): ReactElement<any> => {
  const [loading, setLoading] = useState(false);

  const sectionEleRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const toggleLoader = subject
      .listen(TOGGLE_LOADER)
      .subscribe(async ({ value }) => {
        setLoading(value);
      });

    const setHeight = () => {
      const currHeight = window.innerHeight - 64;
      if (sectionEleRef.current) {
        sectionEleRef.current.style.height = `${currHeight}px`;
      }
    };
    setHeight();
    window.addEventListener("resize", setHeight);
    return () => {
      window.removeEventListener("resize", setHeight);
      if (toggleLoader) {
        toggleLoader.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <TopBar allowLeftBarMenu={false} showAuthControls={false} />
      <section id="content" className="not-auth-layout" ref={sectionEleRef}>
        {children}
      </section>
    </>
  );
};

export default NotAuthLayout;
