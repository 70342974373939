import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getBanks } from "services/api";
import { Bank } from "services/api/banks.service";

export const OTHER_BROKER: Bank = {
  id: 10000,
  name: "Otros Brókers",
  logo: null,
};

export default function useBanks() {
  const [banks, setBanks] = useState<Bank[]>([]);
  const [brokerAdded, setBrokerAdded] = useState(false);

  useEffect(() => {
    getBanks()
      .then((response) => {

        setBanks(response.data);
      })
      .catch((e: any) => {
        toast.error(
          "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔"
        );
        console.log(e);
      });

  }, []);

  const addBroker = () => {
    if (!brokerAdded) {
      setBanks((prevB) => {
        const newArr = [...prevB];
        newArr.unshift(OTHER_BROKER);
        return newArr;
      });
      setBrokerAdded(true);
    }
  };

  const removeBroker = () => {
    if (brokerAdded) {
      setBanks((prevB) => {
        const newArr = [...prevB];
        newArr.shift();
        return newArr;
      });
      setBrokerAdded(false);
    }
  };

  return { banks, addBroker, removeBroker };
}
