import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Button,
  Divider,
  IconButton, Card,
  CardHeader,
  Typography,
  TextField,
  CardContent,
  Box,
  Stack
} from "@mui/material";
import Dropzone, { Accept, DropzoneOptions } from "react-dropzone";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Close";

import IFilesSchema from "../../schemas/files.schema";
import IMortgageSchema from "../../schemas/mortgage.schema";
import { HelperText, CertificateDisclaimerModal, HStack } from "../";
import Autocomplete from "@mui/material/Autocomplete";
import {
  addFileToMortgage,
  uploadBundleFileApi,
  uploadFileApi,
} from "../../../services/api/api.service";

import { DocType } from "shared/types/DocType";

import styles from "./styles.module.scss";
import { sendTagEvent } from "utils/analytics";
import { getMortgageFilesById } from "services/api/mortgages.service";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export interface IUploadManagerProps {
  mortgage: IMortgageSchema;
  onlyAllow: string[];
  onChange?: (key: string, value: any) => void;
  /** when file is uploaded, the caller should mostly refresh the mortgage object */
  onFinish: () => void;
  mode: string;
  denyFiles: string[];
  acceptedFiles: Accept;
  mortgageId: string;
  /** doc type code only, if it come from a type bidfile pass the code only */
  defaultDocTypeCode?: DocType["code"];
  qty_file_required?: DocType["qty_file_required"];
}
const UploadManager: FunctionComponent<IUploadManagerProps> = ({
  onChange,
  mortgage,
  onFinish,
  onlyAllow,
  mode,
  denyFiles,
  acceptedFiles,
  mortgageId,
  defaultDocTypeCode,
  qty_file_required = 1,
}) => {
  const duplicatedElements = useRef<string[]>([]);
  const bundleFiles = useRef<
    {
      bundle_name: string;
      files: { url: string }[];
    }[]
  >([]);

  const [files, setFiles] = useState<IFilesSchema[]>([]);
  const [documents, setDocuments] = useState<DocType[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [showCertificateDisclaimer, setShowCertificateDisclaimer] =
    useState<boolean>(false);

  const handleOnDrop: DropzoneOptions['onDrop'] = (afiles) => {
    if(files.length >= qty_file_required) return;
    setUploading(false);
    const slotLeft = qty_file_required - files.length 
    const newFile = afiles.slice(0, slotLeft).map((f: any) => {
      const details: IFilesSchema = {
        name: f.name,
        type: defaultDocTypeCode || "",
        file: f,
        status: "await",
        error: false,
        error_message: "",
      };
      /* Commented bc is allowing to upload the files without type */
      /* if (onlyAllow.length !== 0) {
        details.type = "coli_certificate";
      } */
      return details;
    });
    setFiles((old) => [...old, ...newFile]);
  };

  const uploadFile = (f: IFilesSchema, i: number, filesArr: IFilesSchema[]) => {
    return new Promise((resolve, reject) => {
      if (filesArr[i]["status"] === "loaded") {
        resolve(null);
        return;
      }

      filesArr[i]["status"] = "loading";
      setFiles([...filesArr]);
      uploadFileApi(f.file)
        .then((url) => {
          if (duplicatedElements.current.includes(f.type)) {
            const idx = bundleFiles.current.findIndex(
              (e) => e.bundle_name === f.type
            );
            if (idx !== -1) {
              bundleFiles.current[idx].files.push({ url });
            } else {
              bundleFiles.current.push({
                bundle_name: f.type,
                files: [{ url }],
              });
            }
            if (filesArr[i]) {
              if (filesArr[i]) filesArr[i]["status"] = "loaded";
              setFiles([...filesArr]);
            }
            resolve(null);
          } else {
            addFileToMortgage(mortgageId, f.type, { url, uploader: "user" })
              .then((response: any) => {
                if (filesArr[i]) {
                  filesArr[i]["status"] = "loaded";
                  setFiles([...filesArr]);
                }
                resolve(null);
              })
              .catch((e: any) => {
                console.log({ e }, "(AFM)");
                filesArr[i]["status"] = "error";
                filesArr[i]["error"] = true;
                filesArr[i]["error_message"] =
                  "Si sigues experimentando problemas para cargar tu documentación por favor enviala a soporteoperaciones@colibid.com 📫 ";
                setFiles([...filesArr]);
                reject();
              });
          }
        })
        .catch((e) => {
          console.log({ e }, "(UA)");
          filesArr[i]["status"] = "error";
          filesArr[i]["error"] = true;
          filesArr[i]["error_message"] =
            "Si sigues experimentando problemas para cargar tu documentación por favor enviala a soporteoperaciones@colibid.com 📫 ";
          setFiles((old) => [...filesArr]);
          reject();
        });
    });
  };
  const uploadBundle = (bundle: {
    bundle_name: string;
    files: { url: string }[];
  }) => {
    const setErrorToFile = (files: IFilesSchema[], errMsg: string) => {
      files.forEach((f) => {
        if (f.type === bundle.bundle_name) {
          f.status = "error";
          f.error = true;
          f.error_message = errMsg;
        }
      });
      setFiles((old) => [...files]);
    };
    return new Promise(async (resolve, reject) => {
      uploadBundleFileApi(bundle)
        .then((bundleRes: any) => {
          addFileToMortgage(mortgageId, bundle.bundle_name, {
            url: bundleRes.data.bundle_url,
            uploader: "user",
          })
            .then((response: any) => {
              resolve(null);
            })
            .catch((e: any) => {
              setErrorToFile(files, e.message);
              reject();
            });
        })
        .catch((e: any) => {
          setErrorToFile(files, e.message);
          reject();
        });
    });
  };

  const finish = () => {
    bundleFiles.current = [];
    onFinish();
  };

  const uploadFiles = async () => {
    setUploading(true);

    // Check type doc errors
    files.forEach((file, i: number) => {
      files[i].error_message = "";
      if (files[i].type === "") {
        files[i].status = "error";
        files[i].error = true;
        files[i].error_message = "Debe seleccionar un tipo de archivo";
        setUploading(false);
      } else {
        if (files[i].status !== "loaded") {
          files[i].status = "await";
          files[i].error = false;
          files[i].error_message = "";
        }
      }

      // Check duplicates
      if (!files[i].error) {
        const repeats = files.filter((f) => f.type === file.type);

        if (repeats.length > 1) {
          duplicatedElements.current.push(files[i].type);
        }
      }

      setFiles((old) => [...files]);
    });

    const failed = files.findIndex((f) => f.error === true);

    if (failed !== -1) {
      setUploading(false);
      return;
    }

    const uploaded = files.reduce(
      async (prevFile: any, f: IFilesSchema, i: number, fileArr) => {
        await prevFile;
        return uploadFile(f, i, fileArr);
      },
      Promise.resolve()
    );

    // Run only if is successfully

    uploaded
      .then((e: any) => {
        bundleFiles.current
          .reduce(async (a, c, i) => {
            await a;
            return uploadBundle(c) as any;
          }, Promise.resolve())
          .then((e) => {
            sendTagEvent({
              event: "app_mis_documentos_subir_documentos",
            });
            finish();
          })
          .catch((e) => {
            console.log(e);
            setUploading(false);
          });
        finish();
      })
      .catch((e: any) => {
        console.log(e);
        setUploading(false);
      });
  };

  const confirmUpload = () => {
    if (mode === "certificate") {
      setShowCertificateDisclaimer(true);
    } else {
      uploadFiles();
    }
  };

  useEffect(() => {
    // getAllDocs();
    getMortgageFilesById(mortgage.id)
      .then((res) =>
        setDocuments(res.data.results.filter((d) => d.user_upload))
      )
      .catch((e) => console.error({ e }));
  }, []);

  const dropboxes = Array(qty_file_required).fill(null);
  const disabledUploadBtn = uploading || files.length < qty_file_required;
  return (
    <Stack gap={1}>
      <Typography variant="body2">
        Haga clic o arrastre uno o el conjunto de archivos de una vez si es mas de uno.
        Deben ser formato pdf, jpg, png, bmf o tiff
      </Typography>
      <HStack gap={1}>
        {dropboxes.map((e, i) => {
          const hasFile = Boolean(files[i]);
          return (
            <Dropzone
              key={i}
              onDrop={handleOnDrop}
              accept={acceptedFiles}
              maxFiles={qty_file_required}
              disabled={hasFile}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <button
                  className={styles.UploadFilesZone}
                  {...getRootProps()}
                  disabled={hasFile}
                >
                  <input {...getInputProps()} />
                  <div className={styles.imgs}>
                    {hasFile && (
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ fontSize: "4rem", zIndex: 1 }}
                      />
                    )}
                    <img
                      src="/assets/img/sources/add_files.svg"
                      width={100}
                      alt=""
                    />
                  </div>
                </button>
              )}
            </Dropzone>
          );
        })}
      </HStack>
      {files.map((e: any, i: number) => {
        return (
          <Card sx={{ width: "100%" }}>
            <CardHeader
              avatar={
                <Box>
                  {e.status === "loading" && <CircularProgress size={20} />}
                  {e.status === "loaded" && (
                    <HelperText text="Archivo enviado correctamente, se encuentra disponible en los Documentos de la Hipoteca">
                      <CheckCircleIcon color="primary" />
                    </HelperText>
                  )}
                  {e.status === "await" && (
                    <HelperText text="Documento listo para cargar. Seleccione el tipo de archivo correspondiente y presione el boton para subir los archivos">
                      <HistoryToggleOffIcon color="primary" />
                    </HelperText>
                  )}
                  {e.status === "error" && (
                    <HelperText text={e.error_message}>
                      <ErrorIcon color="error" />
                    </HelperText>
                  )}
                </Box>
              }
              action={
                <IconButton
                  onClick={() => {
                    files.splice(i, 1);
                    setFiles((old) => [...files]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
              title={e.name}
              subheader={
                <>
                  <Typography variant="caption" color="error">
                    {e.error_message || ""}
                  </Typography>
                </>
              }
            />
            <CardContent>
              <Autocomplete
                disabled={Boolean(defaultDocTypeCode)}
                size="small"
                id="combo-box-demo"
                options={documents}
                sx={{ width: "100%" }}
                noOptionsText="Sin resultados"
                onChange={(d, val) => {
                  files[i]["type"] = val ? val.code : "";
                  files[i]["status"] = "await";
                  files[i]["error_message"] = "";

                  setFiles([...files]);
                }}
                renderInput={(params) => {
                  return <TextField {...params} label="Tipo" />;
                }}
                {...(defaultDocTypeCode && {
                  value: documents.find((d) => d.code === defaultDocTypeCode),
                })}
              />
            </CardContent>
          </Card>
        );
      })}

      <Divider component="br" />
      <Button
        variant="contained"
        fullWidth
        disabled={disabledUploadBtn}
        onClick={() => {
          confirmUpload();
        }}
      >
        Subir archivos
      </Button>

      <CertificateDisclaimerModal
        onClose={() => {
          setShowCertificateDisclaimer(false);
        }}
        onContinue={() => {
          setShowCertificateDisclaimer(false);
          uploadFiles();
        }}
        show={showCertificateDisclaimer}
      />
    </Stack>
  );
};
export default UploadManager;
