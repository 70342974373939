import {
  Stack,
  Box,
  Paper,
  Typography,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import { ReactNode } from "react";
import { BidFiles, HStack } from "shared/components";
import CustomAlert from "shared/components/CustomAlert";
import { OMITS_STEPS } from "shared/constants/general";
import { WSAPI } from "shared/constants/links";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { getImgBaseUrl } from "utils/links";
import styles from "./styles.module.scss";

const WaNumber = process.env.REACT_APP_WHATSAPP_NUMBER_CUSTOMERS;

export default function PPBidFileSection({
  mortgage,
  onFinishFiles,
}: {
  mortgage: MortgageRes;
  onFinishFiles?: () => void;
}) {
  //Variables to check current CRM last step to avoid magic variables, the variables are explicit
  let partOfOmittedSteps = OMITS_STEPS.some((item) =>
    item.includes(String(mortgage.crm_current_step))
  );
  let lastCRMStepIsNull = mortgage.crm_current_step === null;

  let mortagageDocuments = mortgage.bids.some(
    (item) => item.accepted && item.timeline.current.action === "documents"
  );

  // Conditional rendering for alert
  let showAlert = false;
  if (mortgage.status === "closed_successfully") {
    showAlert = true;
  }
  return (
    <Stack gap={2}>
      {showAlert && (
        <CustomAlert
          alertProps={{ severity: "info" }}
          title="Tu solicitud ha sido validada por Colibid. El bróker/banco elegido se pondrá en contacto contigo próximamente."
        />
      )}
      <HStack
        id="PPBidFileSection"
        gap="1rem"
        sx={(theme) => ({
          [`@media(max-width:${theme.breakpoints.values.sm}px)`]: {
            flexWrap: "wrap",
          },
        })}
        alignItems="flex-start"
      >
        <Box
          flex="3 1 10%"
          sx={{
            overflowX: "auto",
          }}
        >
          <BidFiles
            mortgage={mortgage}
            onFinish={() => {
              if (onFinishFiles) {
                onFinishFiles();
              }
            }}
            autoExpand
          />
        </Box>
        <Paper
          sx={(theme) => ({
            backgroundColor: "#F5F5F5B2",
            padding: "2rem",
            flex: "1 0",
            position: "sticky",
            top: "1rem",
          })}
        >
          <Typography textAlign="center">
            Tienes 4 días para cargar la documentación requerida. Caso
            contrario, caducarán todas las ofertas elegidas. <br /> Una vez
            cargues toda la documentación,{" "}
            <Typography color="primary" component="span">
              nuestro equipo la verificará en menos de 48hs laborales.
            </Typography>
            <br />
            Luego de esto, cada entidad se pondrá en contacto contigo para
            avanzar con cada oferta elegida.
            <Typography color="primary" component="span">
              La documentación será válida para todas las ofertas que hayas
              aceptado.
            </Typography>
          </Typography>
        </Paper>
      </HStack>
      <Stack gap={2} paddingX={5}>
        <Typography variant="h6">
          Te contamos qué esperar de esta parte del proceso:
        </Typography>
        <BidFileCardProcessInfo />
        <ImportantText />
        <Typography>
          ¿Aún tienes dudas? Nos puedes escribir a través del ChatBot o{" "}
          <Link
            className={styles.links}
            href={`${WSAPI}${WaNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Whatsapp
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}

function BidFileCardProcessInfo() {
  return (
    <Card>
      <CardContent>
        <HStack justifyContent="center" gap={2} flexWrap="wrap">
          <ProcessInfoSection
            imgUrl={getImgBaseUrl("undraw_upload.svg")}
            numberTxt="1"
            numberColor="#33CCCC"
          >
            Debes{" "}
            <span className="colibid-color">
              cargar la documentación requerida
            </span>{" "}
            de forma manual o automática. Esto esnecesario para garantizar el
            futuro <strong>éxito</strong> de la operación.
          </ProcessInfoSection>
          <ProcessInfoSection
            imgUrl={getImgBaseUrl("undraw_chore_list.svg")}
            numberTxt="2"
            numberColor="#D8D8F6"
          >
            <span className="colibid-color">
              Nuestro equipo experto validará{" "}
            </span>
            que la documentación concuerde con los datos de la solicitud.
            Tardaremos máximo 48hs hábiles. Si algo no coincide o falta algún
            documento, ¡te contactaremos!
          </ProcessInfoSection>
          <ProcessInfoSection
            imgUrl={getImgBaseUrl("undraw_contact_us.svg")}
            numberTxt="3"
            numberColor="#E4F086"
          >
            Tras validar la documentación,{" "}
            <span className="colibid-color">
              la entidad elegida se pondrá en contacto contigo.{" "}
            </span>
            Según el caso, puede ser inmediato o demorar unos días.
          </ProcessInfoSection>
        </HStack>
      </CardContent>
    </Card>
  );
}

function ProcessInfoSection({
  children,
  imgUrl,
  numberTxt,
  numberColor,
}: {
  children: ReactNode;
  imgUrl: string;
  numberTxt: string;
  numberColor: string;
}) {
  return (
    <Stack maxWidth={300} gap={3}>
      <HStack justifyContent="space-evenly" alignItems="center">
        <Typography color={numberColor} fontWeight={800} fontSize={90}>
          {numberTxt}
        </Typography>
        <img
          src={imgUrl}
          height={150}
          width={200}
          style={{ objectFit: "contain" }}
        />
      </HStack>
      <Typography>{children}</Typography>
    </Stack>
  );
}

function ImportantText() {
  return (
    <Stack gap={1}>
      <HStack gap={2} alignItems="center">
        <Typography variant="h4" color="primary" fontWeight={500}>
          ¡Importante!
        </Typography>
        <img src={getImgBaseUrl("blind-man.png")} height={60} />
      </HStack>
      <Typography>
        Hasta que tu documentación no sea validada por nosotros,{" "}
        <strong>
          tú no podrás ver quién es la entidad elegida ni ellos podrán ver tus
          datos personales
        </strong>
        . ¿Por qué? Nos tomamos la confidencialidad muy a pecho, no
        compartiremos tu información hasta que no sea seguro que puedas
        continuar con la operación. Además, tras verificar nosotros la
        documentación nos aseguramos que el{" "}
        <span className="colibid-accent-color ">proceso sea más rápido</span> 😉
      </Typography>
    </Stack>
  );
}
