import { Box } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import CreateMortgageFab from "./CreateMortgageFab";

import ScrollToTop from "./ScrollToTop";
import { isMobile } from "react-device-detect";
import FloatingWhatsApp from "react-floating-whatsapp";

type Props = { showBelow: number };

export default function FabGroup({ showBelow }: Props) {
  const location = useLocation();
  const showCreateBtn = location.pathname === "/client/mortgages";

  if (isMobile && showCreateBtn) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        position: "fixed",
        bottom: "5rem",
        right: "2rem",
        zIndex: "10",
      }}
    >
      <ScrollToTop showBelow={showBelow} />
      {showCreateBtn && <CreateMortgageFab />}
    </Box>
  );
}

const { REACT_APP_WHATSAPP_NUMBER_BIDDERS }: any = process.env;
const { REACT_APP_WHATSAPP_ACCOUNT_NAME }: any = process.env;
const { REACT_APP_WHATSAPP_STATUS_MESSAGE }: any = process.env;
const { REACT_APP_WHATSAPP_CHAT_MESSAGE }: any = process.env;
export function FloatingWap() {
  return (
    <FloatingWhatsApp
      phoneNumber={REACT_APP_WHATSAPP_NUMBER_BIDDERS}
      accountName={REACT_APP_WHATSAPP_ACCOUNT_NAME}
      avatar="/assets/img/avatar/colibid_whatsapp.svg"
      statusMessage={REACT_APP_WHATSAPP_STATUS_MESSAGE}
      chatMessage={REACT_APP_WHATSAPP_CHAT_MESSAGE}
      allowClickAway
      className="height-unset"
    />
  );
}
