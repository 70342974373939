import {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
} from "react";
import {
  useLocation,
  useNavigate, useSearchParams
} from "react-router-dom";

import { MainClientLayout } from "../../../shared/layouts";
import { MortgageForm } from "../../../shared/components";

import {
  useAuthenticated,
  useLocalStorage,
  useProfile,
} from "../../../shared/hooks";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import { INITIAL_STEP } from "shared/constants/general";

const CreateMortgage: FunctionComponent<{}> =
  (): ReactComponentElement<any> => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const initialStep = searchParams.get(INITIAL_STEP);
    const [role] = useLocalStorage("role");
    const profile = useProfile().profile;

    useAuthenticated((allow) => {
      if (!allow) navigate("/sign-in");
    }, "client");

    useEffect(() => {
      if (profile) {
        sendTagEvent({
          event: "page_view",
          page_category: location.search,
          page_location: location.pathname, // URL
          user_type: role,
          user_id: profile.id,
          user_registration_date: format(new Date(), "MM/dd/yyyy"),
          user_login_status: "active",
        });
      }
    }, [profile]);

    return (
      <MainClientLayout>
        <MortgageForm
          initialStep={initialStep ? Number(initialStep) : 0}
        />
      </MainClientLayout>
    );
  };

export default CreateMortgage;
