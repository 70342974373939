import { requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";

export const provinceSchema = requiredStringSchema();
export const communitySchema = requiredStringSchema();
export const citySchema = requiredStringSchema();
export const zipcodeSchema = requiredStringSchema().matches(
  /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
  "Por favor introduce un código postal válido"
);
export const addressSchema = requiredStringSchema(
  "Por favor introduzca una dirección"
);

export const constructionTypeSchema = requiredStringSchema()

export const propertyLocationSchema = yup.object({
  property: yup.object({
    province: provinceSchema,
    community: communitySchema,
    city: citySchema,
    zipcode: zipcodeSchema,
    address: addressSchema,
  }),
});
