import React, { FunctionComponent, useEffect, useState } from "react";

import {
  Typography, Switch,
  Collapse,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";

import { allowFilterValuesKeys } from "../utils";

type Abc = keyof typeof allowFilterValuesKeys;

export interface filterRadio {
  title: string;
  name: string;
  filters: any;
  filterValue: string;
  onChangeValue: (data: any, active: boolean) => void;
}
const FilterRadio: FunctionComponent<filterRadio> = ({
  title,
  name,
  filterValue,
  onChangeValue,
  filters,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;

    onChangeValue(value, showFilter);
  };

  useEffect(() => {
    if (filterValue) {
      setShowFilter(filters[allowFilterValuesKeys[name as Abc]]);
    }
  }, []);

  useEffect(() => {
    Object.keys(allowFilterValuesKeys).forEach((key) => {
      if (key === name) {
        setShowFilter(filters[allowFilterValuesKeys[key as Abc]]);
      }
    });
  }, [filters]);

  return (
    <React.Fragment>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}

        <Switch
          checked={showFilter}
          onChange={() => {
            setShowFilter((prevState: any) => {
              let val = !prevState;

              onChangeValue(filterValue, val);

              return val;
            });
          }}
        />
      </Typography>

      <Collapse in={showFilter}>
        <RadioGroup value={filterValue} onChange={handleOnChange}>
          <FormControlLabel value="1" control={<Radio />} label="Si" />
          <FormControlLabel value="2" control={<Radio />} label="No" />
        </RadioGroup>
      </Collapse>
    </React.Fragment>
  );
};

export default FilterRadio;
