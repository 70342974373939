import { useState, useEffect } from 'react'

// Recuerda siempre usar la palabra "use" al principio de cada custom hook
const useLoading = () => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, [])

    const setValue = (value: boolean) => {
        setLoading(value);
    };

    return { loading, setValue }
}

export default useLoading