export function calculateDaysLeft(trialExpiresOn?: string | null): number {
  if (!trialExpiresOn) return -1;
  // Convert the trialExpiresOn string to a Date object
  const trialExpiresDate = new Date(trialExpiresOn);

  // Get the current date
  const currentDate = new Date();

  // Set the trialExpiresDate to the end of the day (just before midnight)
  trialExpiresDate.setHours(23, 59, 59, 999);

  // Calculate the difference in milliseconds
  const timeDifference = trialExpiresDate.getTime() - currentDate.getTime();

  // Calculate the number of days left (inclusive)
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
}

export function isoToDate(date: string) {
  if(!date) return ''
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - 1);

  return newDate.toISOString().slice(0, 10);
}