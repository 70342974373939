import { styled } from "@mui/material/styles";
import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  Button,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type Props = {
  title: string;
  progress: number;
  currGroup: boolean;
  onClick?: () => void;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  // borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#33CCCC80",
  },
  [`& .${linearProgressClasses.bar}`]: {
    // borderRadius: 5,
    backgroundColor: "#33CCCC",
  },
}));

export default function CustomProgressBar({
  title,
  progress,
  currGroup,
  onClick,
}: Props) {
  const isDisabled = progress === 0;
  const icon =
    progress === 100 ? (
      <CheckCircleIcon color="primary" />
    ) : !currGroup ? (
      <RadioButtonUncheckedIcon color="disabled" />
    ) : (
      <RadioButtonCheckedIcon color={currGroup ? "inherit" : "disabled"} />
    );
  const handleClick = () => {
    onClick?.();
  };


  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ textTransform: "capitalize" }}
      >
        {icon}{" "}
        <Typography
          component={Button}
          sx={{ textTransform: "none" }}
          disabled={isDisabled}
          color={isDisabled ? "black " : "gray"}
          onClick={handleClick}
        >
          {title}
        </Typography>
      </Stack>
      {currGroup && (
        <Box paddingLeft={4}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </Stack>
  );
}
