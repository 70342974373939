import { yupResolver } from "@hookform/resolvers/yup";
import {
  InputAdornment,
  Stack,
  StackProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import CountersDisplayCard from "shared/components/CounterDisplayCard";
import FormFields from "shared/components/FormFields";
import {HStack} from "shared/components";
import { MAX_MORTGAGE_RATE, PROPERTY_IMPORT_MIN_VALUE } from "shared/constants/mortgageForm";
import { MortgageFormData } from "shared/types/postMortgageData";
import { formatLocalCurrency } from "utils/helpers";
import { lessThan, requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import { MortgageDataRow } from "../MortgageDataRow";
import StepLayout from "../StepLayout";
import { fundUsedForOptions } from "../StepTwo/utils";
import { amountLTPropertyValueTest } from "../StepTwo/validation.schema";

function ExtendedMortgageDataForm() {
  const { mortgageFormData, onNext, onBack } = useMortgageFormContext();
  const mortgageMode = mortgageFormData.mortgage.mode;
  return (
    <StepLayout
      title="Y ahora sobre la ampliación..."
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      resolver={yupResolver(
        yup.object({
          data: yup.object().shape({
            amount: requiredStringSchema("Ingrese un valor")
              .test(lessThan(PROPERTY_IMPORT_MIN_VALUE))
              .test(amountLTPropertyValueTest),
            fund_used_for: requiredStringSchema(),
            mortgage_rate: requiredStringSchema().test({
              name: `gt${MAX_MORTGAGE_RATE}`,
              message: () =>
                `El porcentaje de hipoteca no puede superar el 80%`,
              test: (testVal) => {
                return Number(testVal) <= MAX_MORTGAGE_RATE;
              },
            }),
          }),
        })
      )}
    >
      <ExtendedMortgageHandler />
    </StepLayout>
  );
}
function ExtendedMortgageHandler() {
  const { formState, getValues, watch, setValue } =
    useFormContext<MortgageFormData>();
  const mortgageMode = getValues("mortgage.mode");
  const [dataAmount, dataPendingAmount, propertyValue, mortgageInitial] = watch(
    ["data.amount", "data.pending_amount", "property.value", "mortgage_initial"]
  );
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const direction: StackProps["direction"] = isSmallScreen
    ? "column-reverse"
    : "row";

  const mortgageRate = Math.round(
    (Number(dataPendingAmount) / Number(propertyValue)) *
      100
  );

  useEffect(() => {
    setValue("data.mortgage_rate", mortgageRate, { shouldValidate: true });
  }, [dataAmount]);

  return (
    <Stack
      id="extended-mortgage-data-handler"
      direction={direction}
      alignItems="center"
      height="100%"
      justifyContent={isSmallScreen ? "flex-end" : "center"}
    >
      <Form />
      <CountersDisplayCard
        boxProps={{
          sx: { backgroundColor: "transparent", border: "none" },
        }}
        counterDisplayListProps={[
          {
            errMsg: formState.errors.data?.mortgage_rate?.message,
            count: `${mortgageRate} %`,
            helperText:
              "Aunque las entidades bancarias estudian cada caso de forma independiente, generalmente el importe máximo a financiar para una vivienda habitual es entre el 80 al 90%",
            label: "Porcentaje de Hipoteca",
          },
        ]}
      />
    </Stack>
  );
}

function Form() {
  const { formState, getValues, watch, setValue } =
    useFormContext<MortgageFormData>();
  const propertyValue = getValues("property.value");
  return (
    <Stack id="form" spacing={10} marginTop={3} maxWidth='340px'>
      <HStack justifyContent="space-between" paddingLeft="35px">
        <Typography>Valor actual de la propiedad</Typography>
        <Typography>{formatLocalCurrency(propertyValue)}</Typography>
      </HStack>
      <Stack spacing={2}>
        <MortgageDataRow
          label="Importe de la nueva hipoteca"
          renderInput={
            <FormFields.CurrencyFieldWithWrapperText
              text="La cantidad que te gustaría pedir prestada al banco para comprar la propiedad (no puedes pedir dinero extra para renovar la casa o comprar muebles como parte de la hipoteca)"
              name="data.amount"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          }
        />
        <MortgageDataRow
          label="¿Cuál es el destino de los fondos de la ampliación?"
          renderInput={
            <FormFields.SelectInput
              name="data.fund_used_for"
              menuItems={fundUsedForOptions.map((e) => {
                return {
                  key: e.value,
                  value: e.value,
                  label: e.label,
                };
              })}
            />
          }
        />
      </Stack>
    </Stack>
  );
}

export default ExtendedMortgageDataForm;
