import { differenceInYears, isPast } from "date-fns";
import { toast } from "react-toastify";
import { getInitialFormData } from "shared/components/MortgageForm/utils";
import { COUNTRIES, CountriesCode } from "shared/constants/countries";
import { MORTGAGE_FORM_STATE } from "shared/constants/localStorage";
import jwt_decode from "jwt-decode";
import { MortgageData } from "shared/types/postMortgageData";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { censureChars } from "./helpers";

export function revokeObjectURL(url?: string): void {
  if (url) {
    URL.revokeObjectURL(url);
  }
}

export function isSameFile(filename: string | File, encondeUrl: string) {
  const encodeName = encondeUrl.split("/").pop();
  if (encodeName) {
    const decode = decodeURI(encodeName);
    return decode === filename;
  }
  return false;
}

export function getAgeByBirth(userBirthDate: Date | string | null | undefined) {
  return differenceInYears(new Date(), new Date(userBirthDate as Date));
}

export const dateFormat: string = "dd / MMM / yyyy";

export const getFilenameFromUrl = (url: string) =>
  decodeURIComponent(url.split("/").pop() || "");

export const isValidMortgageId = (id: string | null) => {
  if (!id) return false;
  return id.split("-").length === 5;
};

export const isFileSizeOK = (size: string) => {
  if (parseInt(size) <= 0) {
    toast.error("El tamaño minimo del archivo es de 1 byte ", {
      position: "top-right",
    });
    throw new Error("File size too small, maybe corrupted ");
  }
  // Check max 60mb
  if (parseInt(size) / 1000000 > 60) {
    toast.error("El tamaño máximo del archivo es de 60MB", {
      position: "top-right",
    });
    throw new Error("File size exceeded");
  }
};

export const isAppleDamnImgFormat = (filename?: string) => {
  // https://shkspr.mobi/blog/2020/12/coping-with-heic-in-the-browser/
  // https://itnext.io/tackling-iphone-or-ipad-images-support-in-browser-8e3e64e9aaa1
  if (filename) return /\.(heic|heif)$/i.test(filename);
  return false;
};

export const isPdf = (filename?: string) => {
  if (filename) return /\.(pdf)$/i.test(filename);
  return false;
};

export const isStrADate = (value: string) => {
  return !isNaN(Date.parse(value));
};

export const deepSameKeys = (o1: any, o2: any) => {
  // Both nulls = same
  if (o1 === null && o2 === null) {
    return true;
  }

  // Both Array
  if (Array.isArray(o1) && Array.isArray(o2)) {
    return true;
  }
  // Get the keys of each object
  const o1keys = o1 === null ? new Set() : new Set(Object.keys(o1));
  const o2keys = o2 === null ? new Set() : new Set(Object.keys(o2));
  if (o1keys.size !== o2keys.size) {
    // Different number of own properties = not the same
    return false;
  }

  // Look for differences, recursing as necessary
  for (const key of o1keys as any) {
    if (!o2keys.has(key)) {
      // Different keys
      return false;
    }

    // Get the values and their types
    const v1 = o1[key];
    const v2 = o2[key];
    const t1 = typeof v1;
    const t2 = typeof v2;
    if (t1 === "object") {
      if (t2 === "object" && !deepSameKeys(v1, v2)) {
        return false;
      }
    } else if (t2 === "object") {
      // We know `v1` isn't an object
      return false;
    }
  }

  // No differences found
  return true;
};

export const testMortgageFormStructure = () => {
  let item = window.localStorage.getItem(MORTGAGE_FORM_STATE);
  if (item) {
    const formData = JSON.parse(item).mortgageFormValue;
    const formToCompare: any = getInitialFormData({
      name: "",
      lastname: "",
      email: "",
    });
    return deepSameKeys(formToCompare, formData);
  }
  return false;
};

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getCountryInfo(countryCode: CountriesCode) {
  const c = COUNTRIES.find((c) => c.code === countryCode);
  if (c) {
    return c;
  }
  return {
    name_en: "Spain",
    name_es: "España",
    dial_code: "+34",
    code: "ES",
  } as (typeof COUNTRIES)[number];
}

export const dateExpired = (dateEx: string): Date => {
  const input = dateEx;
  const [year, month, day] = input.split("-");
  const dayFormat = day.slice(0, 2);
  const futureDay = new Date(+year, +month - 1, +dayFormat);
  futureDay.setDate(futureDay.getDate() + 3);
  return futureDay;
};

/**
 * this function get the index element of a array of number
 * it used binary search algo so the array you passing it needed to be sorted
 * if the lenght if not big you can use the array method findIndex it perform a
 * linear search no problem for small array len
 * @param {number[]} arr must be sorted arr
 * @param {number} val the index element to find based on his value
 */
export function getIndex(arr: number[], val: number) {
  let leftP = 0;
  let rightP = arr.length - 1;

  while (leftP <= rightP) {
    const mid = Math.floor((leftP + rightP) / 2);
    const midVal = arr[mid];

    if (midVal === val) {
      return mid;
    }
    if (midVal < val) {
      leftP = mid + 1;
    } else {
      rightP = mid - 1;
    }
  }

  return -1;
}

export const isAccessTokenExpired = () => {
  const token = window.localStorage.getItem("access_token");
  const decoded = jwt_decode(token || "");
  if (tokenHasExpiration(decoded)) {
    const expirationDate = new Date(decoded.exp * 1000);
    return isPast(expirationDate);
  }
};

function tokenHasExpiration(token: unknown): token is { exp: number } {
  return typeof token === "object" && token !== null && "exp" in token;
}

export function getUrlImg(name: string) {
  return (
    "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/" +
    name
  );
}
export function censureValue(validate: boolean, valueToCensure: any) {
  const valueCensurate = validate
    ? censureChars(valueToCensure)
    : valueToCensure;

  return valueCensurate;
}

// Tipos para los errores
type ErrorType =
  | "kindergarten_limit"
  | "dont_have_credits"
  | "banking_entity_rejected_by_client"
  | "unexpected_error";

// Interface para los mensajes de error
interface ErrorMessages {
  [key: string]: string;
}

// Mensajes de error
const errorMessages: ErrorMessages = {
  kindergarten_limit:
    "Lo sentimos no podemos crear esta oferta, porque has superado la cantidad máxima de ofertas por ahora 🤔",
  dont_have_credits:
    "Lo sentimos no dispones de créditos para pujar debes de adquirir un nuevo paquete 📦",
  banking_entity_rejected_by_client:
    "Lo sentimos el cliente ha indicado, que ya se encuentra trabajando con esta entidad bancaria",
  unexpected_error:
    "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔",
};

// Interface para la respuesta del error
interface ErrorResponse {
  data?: {
    error_type?: ErrorType;
  };
  status?: number;
}

// Función para manejar errores
export function handleErrorBidModal(e: { response?: ErrorResponse }) {
  if (e.response) {
    const errorType = e.response.data?.error_type;
    const status = e.response.status;

    if (errorType && errorMessages[errorType]) {
      toast.warning(errorMessages[errorType]);
    } else if (status === 409) {
      toast.error(errorMessages["unexpected_error"]);
    }
  }
}

export const isFileAlreadyUpdated = (
  mortgage: MortgageRes,
  document: string
) => {
  return mortgage.files.find((item) => item.file_descriptor === document);
};
