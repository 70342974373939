import { Skeleton, Card, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

export interface MetricCardProps {
  title: string;
  value: string | number | undefined;
  icon: ReactNode;
}

export default function MetricCard({ title, value, icon }: MetricCardProps) {
  if (value === undefined) {
    return <Skeleton height={104} width="100%" variant="rectangular" />;
  }
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        padding="1rem"
        width="100%"
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          flex="1 1 30%"
          sx={{
            height: "60px",
            backgroundColor: "#C5F9E4",
            borderRadius: "5px",
          }}
        >
          {icon}
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          flex="1 1 70%"
          spacing={1}
        >
          <Typography variant="h6" color="#01857a" textAlign="center">
            {title}
          </Typography>
          <Typography variant="h5">{value}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
