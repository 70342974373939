import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useReducer,
  useState,
} from "react";

import {
  Box,
  Dialog,
  Grid,
  Container,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Button,
  InputAdornment,
  RadioGroup,
  Radio,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Alert,
  FormHelperText,
  Stack,
  Tooltip,
  TextFieldProps,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import { SelectChangeEvent } from "@mui/material/Select";
import IMortgageSchema from "../../../shared/schemas/mortgage.schema";
import { addBid } from "../../../services/api";
import {
  formatToRate,
  formatLocalCurrency,
  getApplicationType,
  getInterestType,
  getTotalComissionDisscount,
} from "../../../utils/helpers";
import { useDialog, useLocalStorage, useProfile } from "../../../shared/hooks";
import {
  AlertInfo,
  CurrencyField,
  WithTooltip,
  WithDialog,
} from "../../../shared/components";
import Bindings from "./components/Bindings";
import MixedInterest from "./components/MixedInterest";
import { useBanks } from "shared/hooks";
import { BidOffer } from "./utils";
import { interestTypeOptions } from "../MortgageForm/components/StepTwo/utils";
import { BidderProfile } from "shared/types/Profile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { initialState, ModalReducer } from "./reducer";
import { bidValidationSchema, MAX_CHAR } from "./validations";
import { ValidationError } from "yup";
import { isBrokerProfile } from "utils/profile";
import { handleErrorBidModal } from "utils";

const initialErrorState = {
  additional_detail: "",
};
export interface IBidModalProps {
  mortgage: any;
  mode: string; // auto - manual
  readOnly: boolean;
  defaultValue: any;
  onSave: (bid: any) => void;
  onClose: () => void;
}
const BidModal: FunctionComponent<IBidModalProps> = (
  props
): ReactElement<any> => {
  const allBanks = useBanks().banks;
  const { profile } = useProfile<BidderProfile>();
  const [banks, setBanks] = useState<any>([]);
  const [bank, setBank] = useState(0);
  const [mortgagetype, setMortgageType] = useState<any>("");
  const [interesttype, setInterestType] = useState<any>("");
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [mixInterestType1, setmixInterestType1] = useState("");
  const [mixInterestType2, setmixInterestType2] = useState("");
  const [showComissionFinances, setShowComissionFinances] = useState(true);
  const [showFinancedRow, setShowFinancedRow] = useState(false);
  const [mortgage, setMortgage] = useState<Partial<IMortgageSchema>>({});
  const [role] = useLocalStorage("role");
  const { isShowing, openDialog } = useDialog();
  const [schema, setSchema] = useState(
    props.defaultValue ||
      ({
        bid_for: {
          id: "",
          logo: "",
          name: "",
          hidden: true,
        },
        general_fees: {
          consulting_fees: 0,
          consulting_type: 0,
          colibid_fees: 0,
          net_fees: 0,
          alert_limit: false,
          colibid_percent: 0,
          net_fees_financed: 0,
          finance_fees: false,
          new_format: true,
          values: {
            consulting_fees: 0,
            net_fees: 0,
            colibid_fees: 0,
          },
        },
        interest_rate: "",
        conditions: {},
        interest_data: {},
        conditions_details: [
          {
            code: "b01",
            bonificationName: "Nómina",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b02",
            bonificationName: "Tarjetas",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b03",
            bonificationName: "Seguro Hogar",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b04",
            bonificationName: "Seguro Vida",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b05",
            bonificationName: "Seguro Protección Pagos",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b06",
            bonificationName: "Seguro Auto",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b07",
            bonificationName: "Seguro Salud",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b08",
            bonificationName: "Fondo de Inversión",
            interest_rate: "0.5",
            allow: false,
          },
          {
            code: "b09",
            bonificationName: "Plan de Pensión",
            interest_rate: "0.5",
            allow: false,
          },
        ],
        additional_detail: "",
      } as BidOffer)
  );
  const [state, dispatch] = useReducer(ModalReducer, initialState);
  // Access state values
  const { selectDisabled, disabledButton, timeMortgage, modalAllInterest } =
    state;
  const [error, setError] =
    useState<Pick<BidOffer, "additional_detail">>(initialErrorState);

  useEffect(() => {
    if (props.defaultValue !== null) {
      setBank(
        allBanks.findIndex(
          (b: any) => b.name === props.defaultValue.bid_for.name
        )
      );

      setInterestType(props.defaultValue.interest_data.mode);

      if (
        props.defaultValue.interest_data.mode === "mixto" &&
        props.defaultValue.interest_data?.interest?.length > 0
      ) {
        setmixInterestType1(props.defaultValue.interest_data.interest[0].mode);
        setmixInterestType2(props.defaultValue.interest_data.interest[1].mode);
      }
    }
  }, [props.defaultValue, allBanks]);

  useEffect(() => {
    setMortgage(props.mortgage);
    setMortgageType(props.mortgage.mortgage_data.mortgage.mode);

    dispatch({
      type: "SET_TIME_MORTGAGE",
      payload: props.mortgage.mortgage_data.data.period,
    });

    if (Array.isArray(props.mortgage.mortgage_data.data.interest_type)) {
      setInterestType("todos");
      dispatch({
        type: "SET_MODAL_ALL_INTEREST",
        payload: true,
      });
    } else {
      setInterestType(props.mortgage.mortgage_data.data.interest_type);
      if (props.mortgage.mortgage_data.data.interest_type === "todos") {
        dispatch({
          type: "SET_MODAL_ALL_INTEREST",
          payload: true,
        });
      }
    }
  }, [props.mortgage]);

  useEffect(() => {
  if (profile && (props.mortgage) && allBanks) {
    // Calculate fees
    const mortgageData = props.mortgage?.mortgage_data?.data || mortgage;
    const amount = mortgageData?.amount || 0;
    const fees = (amount * (profile.percentage || 0)) / 100;

    // Check if the profile is a broker
    const isBroker = isBrokerProfile(profile);

    // Set general fees
    const general = {
      ...schema.general_fees,
      colibid_fees: isBroker && profile.require_purchase_package ? 0 : fees,
      colibid_percent: profile.percentage,
    };

    // FEATURE DEACTIVATE UNTIL NEW CONFIRMATION: Check if the profile can accept financed commission
    // if (profile.accept_financed_commission === true) {
    //   general.finance_fees = true;
    //   general.net_fees_financed = finaced_colibid_fee();
    //   changedFinancedStatus();
    // }

    // Find user bids for the current profile
    const userBids = (mortgage?.bids || []).filter((bid) => bid.bidder.user === profile.user);

    // Check if there are user bids
    if (userBids.length > 0 && role !== "bank") {
      const defaultBid = userBids[0]; // Use the first user bid
      const defaultBank = defaultBid.bid_for;

      setBank(allBanks.findIndex((b) => b.name === defaultBank.name));

      schema.bid_for.id = defaultBank.id;
      schema.bid_for.name = defaultBank.name;
      schema.bid_for.logo = defaultBank.logo;

      dispatch({ type: "SET_SELECT_DISABLED", payload: true });
    } else {
      // No user bids, set select enabled
      dispatch({ type: "SET_SELECT_DISABLED", payload: false });
    }

    // Update the schema
    setSchema({
      ...schema,
      general_fees: general,
    });
  }
}, [profile, props.mortgage, allBanks, role]);


  useEffect(() => {
    if (interesttype === "mixto") {
      setMixInterestType();
    }

    if (interesttype === "fijo" || interesttype === "variable") {
      if (props.defaultValue === null) {
        setInterestData("");
      }
    }
  }, [interesttype]);

  useEffect(() => {
    let ab = allBanks;
    if (allBanks && profile) {
      if (role === "bank") {
        ab = allBanks.filter((b) => b.id === profile.bank_name?.id);
      }

      if (props.defaultValue !== null && role === "bank") {
        setBank(
          ab.findIndex((b: any) => b.name === props.defaultValue.bid_for.name)
        );
      }

      setBanks(() => [...ab]);
    }
  }, [role, allBanks, profile, props.defaultValue]);

  useEffect(() => {
    if (props.defaultValue === null) {
      consultingFeeValidations("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortgage, props.defaultValue]);

  useEffect(() => {
    const { interest_data = {}, interest_rate } = schema;
    if (interest_data.mode !== undefined) {
      if (interest_data.mode !== "mixto") {
        const value =
          interest_rate >= 10 ||
          interest_rate === "" ||
          isNaN(interest_rate) ||
          Object.keys(interest_data).length === 0;

        dispatch({ type: "SET_BUTTON_DISABLED", payload: value });
      } else {
        dispatch({
          type: "SET_BUTTON_DISABLED",
          payload: checkValuesMixedInterest(),
        });
      }
    }
  }, [schema]);

  const changeInterestType = (value: string) => {
    if (value === "fijo" || value === "variable") {
      setInterestData("");
      setmixInterestType1("");
      setmixInterestType2("");
    }

    if (value === "mixto") {
      setMixInterestType();
    }

    setInterestType(value);
  };

  /*  useEffect(() => {
    const { interest_data = {}, interest_rate } = schema;
    if (interest_data.mode !== undefined) {
      if (interest_data.mode !== "mixto") {
        setButtonDisabled(
          interest_rate >= 10 ||
            interest_rate === "" ||
            isNaN(interest_rate) ||
            Object.keys(interest_data).length === 0
        );
      } else {
        setButtonDisabled(checkValuesMixedInterest());
      }
    }
  }, [schema]); */

  const consultingFeeValidations = (value: any) => {
    if (
      schema.general_fees.consulting_fees === "" ||
      (schema.general_fees.consulting_fees === 0 &&
        checked1 === false &&
        checked2 === false &&
        checked3 === false)
    ) {
      setChecked1(true);
      schema.general_fees.consulting_type = 1;
    }
    schema.general_fees.consulting_fees = value;

    schema.general_fees.net_fees =
      schema.general_fees.consulting_fees - schema.general_fees.colibid_fees > 0
        ? schema.general_fees.consulting_fees - schema.general_fees.colibid_fees
        : 0;

    schema.general_fees.alert_limit =
      Number(schema.general_fees.consulting_fees) <
        schema.general_fees.colibid_fees ||
      Number(schema.general_fees.consulting_fees) === 0;

    if (Number(schema.general_fees.consulting_fees) === 0) {
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      schema.general_fees.consulting_type = "";
    }

    schema.general_fees.net_fees_financed = showFinancedRow
      ? finaced_colibid_fee()
      : 0;

    setSchema({ ...schema });
  };

  const submit = () => {
    schema["interest_rate"] = Number(schema["interest_rate"]);
    schema["general_fees"]["consulting_fees"] = Number(
      schema["general_fees"]["consulting_fees"]
    );

    if (
      role === "bank" ||
      schema.bid_for.id === "" ||
      schema.bid_for.id === null
    ) {
      schema.bid_for.id = banks[0].id;
      schema.bid_for.name = banks[0].name;
      schema.bid_for.logo = banks[0].logo;
    }

    schema.bid_for.hidden = true;
    const newValue: BidOffer["general_fees"]["values"] = {
      net_fees: role === "bank" ? 0 : schema.general_fees.consulting_fees,
      consulting_fees:
        role === "bank"
          ? 0
          : schema.general_fees.consulting_fees +
            schema.general_fees.colibid_fees,
      colibid_fees: schema.general_fees.colibid_fees,
    };
    schema.general_fees = {
      ...schema.general_fees,
      values: newValue,
    };

    if (!props.readOnly) {
      addBid(mortgage.id, schema)
        .then((response: any) => {
          props.onSave(response.data);
          // Las siguientes líneas quedan desactivadas por solicitud de los Stakeholders para evitar que los brókers se confundan con sus créditos para usuarios alcanzados y el pool de hipotecas que era el remaining_bid_counter no es necesario distinguir por tipo de usuario.
          // let remaining_bid_counter = response.data.remainig;
          //  te quedan disponibles: ${remaining_bid_counter} hipotecas en las que participar.
          toast.success("Puja registrada correctamente");
        })
        .catch((e: any) => {
          handleErrorBidModal(e)
          console.log(e);
        });
    } else {
      props.onSave(schema);
    }
  };

  const confirmAction = async () => {
    try {
      const validation = await bidValidationSchema.validate(schema);
      setError(initialErrorState);
    } catch (error) {
      if (error instanceof ValidationError) {
        const { message, path } = error;
        if (path) {
          setError((prevS) => ({
            ...prevS,
            [path]: message,
          }));
        }
      }
      console.log({ error });
      return;
    }

    if (
      (interesttype === "fijo" || interesttype === "variable") &&
      schema.interest_rate === ""
    ) {
      Swal.fire({
        title: "Se requieren llenar los datos",
        text: "Datos incorrectos",
        icon: "warning",
      });
      return;

      // setSchema((prevState: any) => {
      //   delete prevState.interest_data.interest;
      //   submit();
      //   return prevState;
      // });
    }

    // Evaluate type mixt
    if (interesttype === "mixto" && checkValuesMixedInterest()) {
      Swal.fire({
        title: "Se requieren llenar los datos",
        text: "Por favor llenar los datos del tipo de interés mixto correctamente",
        icon: "warning",
      });
      return;
    }

    if (interesttype === "todos") {
      Swal.fire({
        title: "",
        html: `<p style="font-size: 1.6rem">Seleccione un tipo de Interés.</p>`,
        icon: "warning",
      });

      return;
    }

    if (
      schema.conditions_details.filter(
        (item: any) => item.interest_rate === "" && item.allow === true
      ).length > 0
    ) {
      Swal.fire({
        title: "Se requieren llenar los datos",
        text: "Datos incorrectos",
        icon: "warning",
      });
      return;
    }

    if (
      schema.general_fees.alert_limit &&
      role !== "bank" &&
      props.mode !== "auto"
    ) {
      if (schema.general_fees.consulting_fees <= 0) {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "Los honorarios ingresados son 0.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then((result: any) => {
          if (result.isConfirmed) {
            submit();
          }
        });
        return
      }
      submit();
      return;
    }

    submit();
  };

  interface DOMEvent<T extends EventTarget> extends Event {
    readonly target: T;
  }
  const setMixInterest = (
    event: DOMEvent<HTMLInputElement> | SelectChangeEvent<string>,
    type: string
  ) => {
    const optiontoSelect = [
      {
        value: "fijo",
        label: "Fijo",
      },
      {
        value: "variable",
        label: "Variable",
      },
    ];

    if (schema.interest_data.interest) {
      schema.interest_data.interest = [...schema.interest_data.interest];
    } else {
      schema.interest_data.interest = [];
    }

    switch (type) {
      case "initial_step_interest":
        const options = optiontoSelect.filter(
          (interest) => interest.value !== event.target.value
        );
        const option = options.length > 0 ? options[0].value : "";

        if (schema.interest_data.interest) {
          schema.interest_data.interest[0] = {
            ...schema.interest_data.interest[0],
            mode: event.target.value,
          };
          schema.interest_data.interest[1] = {
            ...schema.interest_data.interest[1],
            mode: options[0].value,
          };
        }

        setmixInterestType1(event.target.value);
        setmixInterestType2(getInterestType(option));
        setSchema({ ...schema });

        break;
      case "last_step_interest":
        if (schema.interest_data.interest) {
          schema.interest_data.interest[1] = {
            ...schema.interest_data.interest[1],
            mode: event.target.value,
          };
        }
        setmixInterestType2(event.target.value);
        setSchema({ ...schema });

        break;
      case "initial_interest":
        schema.interest_data.interest[0].rate = event;
        setSchema({ ...schema });
        break;
      case "final_interest":
        schema.interest_data.interest[1].rate = event;
        setSchema({ ...schema });
        break;

      default:
        break;
    }
  };

  const checkValuesMixedInterest = () => {
    const data = schema.interest_data.interest.filter((item: any) => {
      return (
        item.mode === "" ||
        item.to_year === 0 ||
        item.rate === 0 ||
        item.rate === ""
      );
    });

    if (data.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const setInterestData = (value: any) => {
    switch (interesttype) {
      case "fijo": {
        schema.interest_data.mode = "fijo";
        schema.interest_data.rate = formatToRate(value);
        schema.interest_rate = formatToRate(value);
        delete schema.interest_data.interest;
        break;
      }
      case "variable": {
        schema.interest_data.mode = "variable";
        schema.interest_data.rate = formatToRate(value);
        schema.interest_rate = formatToRate(value);
        delete schema.interest_data.interest;
        break;
      }
    }
    setSchema({ ...schema });
  };

  const setMixInterestType = () => {
    if (props.defaultValue?.interest_data?.mode !== "mixto") {
      schema.interest_rate = "";
      schema.interest_data.mode = "mixto";
      schema.interest_data.interest = [
        {
          name: "initial",
          mode: "",
          from_year: 0,
          to_year: 0,
          rate: 1.0,
          duration: 0,
        },
        {
          name: "final",
          mode: "",
          from_year: 0,
          to_year: 0,
          rate: 1.0,
          duration: 0,
        },
      ];
      setSchema({ ...schema });
    }
  };

  const finaced_colibid_fee = () => {
    let net_fees_financed = "";
    if (profile) {
      net_fees_financed = getTotalComissionDisscount(
        schema.general_fees.consulting_fees,
        schema.general_fees.net_fees,
        profile.financed_commission_percentage
      );
    }

    return net_fees_financed;
  };

  const changedFinancedStatus = () => {
    setShowFinancedRow((prevCheck) => {
      schema.general_fees.finance_fees = !prevCheck;
      schema.general_fees.net_fees_financed = !prevCheck
        ? finaced_colibid_fee()
        : 0;

      return !prevCheck;
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => props.onClose()}
      maxWidth="md"
      fullWidth={true}
    >
      <Box className={styles.DialogDetails}>
        <Container>
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="h5">Oferta</Typography>
              <Divider />
            </Grid>
            <Grid item md={6} sm={12} xs={12} sx={{ marginBottom: "1rem" }}>
              {props.mortgage.mortgage_data.data.interest_type === "todos" ? (
                <Alert severity="info">
                  <small>
                    Estás apunto de hacer una puja para una solicitud de{" "}
                    <b>{getApplicationType(mortgagetype)}</b> que esta abierta a
                    cualquier tipo de <b>oferta</b>.
                  </small>
                </Alert>
              ) : mortgagetype === 1 || mortgagetype === 3 ? (
                <Alert severity="info">
                  <small>
                    Estás apunto de hacer una puja para una solicitud de{" "}
                    <b>{getApplicationType(mortgagetype)}</b> que desea un
                    interés de tipo <b> {getInterestType(interesttype)}</b>.
                  </small>
                </Alert>
              ) : (
                <Alert severity="info">
                  <small>
                    Estás apunto de hacer una puja para una solicitud de{" "}
                    <b>{getApplicationType(mortgagetype)}</b> que desea cambiar
                    a un interés <b>{getInterestType(interesttype)}</b>.
                  </small>
                </Alert>
              )}
              <Divider component="br" />

              <WithTooltip
                title="Por usuario se pueden enviar todas las ofertas que desees, pero de un mismo banco. Con la primera oferta de cada usuario podrás elegir el banco que desees. Con la segunda oferta al mismo usuario, sólo podrás enviar del banco antes seleccionado."
                position="right"
                justifyContent="left"
                styleStackProps={{
                  marginBottom: "0.5rem",
                }}
              >
                <Typography sx={{ fontSize: "0.9rem" }}>Banco</Typography>
              </WithTooltip>
              <FormControl fullWidth size="small">
                <Select
                  data-id="banks"
                  value={bank}
                  onChange={({ target }) => {
                    schema.bid_for.id = banks[Number(target.value)].id;
                    schema.bid_for.name = banks[Number(target.value)].name;
                    schema.bid_for.logo = banks[Number(target.value)].logo;
                    setBank(Number(target.value));

                    setSchema({ ...schema });
                  }}
                  disabled={selectDisabled}
                >
                  {banks.map((bank: any, i: number) => {
                    return (
                      <MenuItem value={i} key={i}>
                        {bank.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Divider component="br" />

              {modalAllInterest && (
                <React.Fragment>
                  <Divider component="br" />
                  <Typography variant="h6" className={styles.Title}>
                    Tipo de interés
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    error={interesttype === "todos" && isShowing}
                  >
                    <Select
                      data-id="tipo-interes"
                      value={interesttype}
                      onChange={({ target }) => {
                        changeInterestType(target.value);
                      }}
                    >
                      {interestTypeOptions
                        .filter((value) => value.value !== "todos")
                        .map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            className={styles.SelectItem}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                    </Select>
                    {interesttype === "todos" && isShowing && (
                      <FormHelperText>
                        Por favor selecione un tipo de interés
                      </FormHelperText>
                    )}
                  </FormControl>
                </React.Fragment>
              )}

              {interesttype !== "mixto" && (
                <>
                  <Divider component="br" />
                  <Divider component="br" />
                  <Typography variant="h6" className={styles.Title}>
                    Tasa de Interés
                  </Typography>
                  <ReactScrollWheelHandler preventScroll={true}>
                    <FormControl fullWidth size="small">
                      <TextField
                        data-id="tasa-interes"
                        type="text"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={
                          schema.interest_rate === "" ||
                          isNaN(schema.interest_rate) ||
                          parseFloat(schema.interest_rate) >= 10
                            ? true
                            : false
                        }
                        value={schema.interest_rate}
                        onChange={({ target }) => {
                          // schema.interest_rate = formatToRate(target.value);
                          // setSchema({ ...schema });
                          setInterestData(target.value);
                        }}
                        onFocus={() => {
                          if (interesttype === "todos") {
                            openDialog();
                          }
                        }}
                        onBlur={({ target }) => {
                          if (parseFloat(target.value) === 0) {
                            schema.interest_rate = "";
                            setSchema({ ...schema });
                          }
                        }}
                        inputProps={{
                          step: "0.01",
                          min: 0,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {""}
                              <TrendingUpIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {(schema.interest_rate === "" ||
                        parseFloat(schema.interest_rate) >= 10) && (
                        <FormHelperText error={true}>
                          {schema.interest_rate === "" &&
                            "Debe ingresar el valor del interés"}
                          {parseFloat(schema.interest_rate) >= 10 &&
                            "El valor máximo para el tipo de interés es 10%"}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </ReactScrollWheelHandler>
                  <Divider component="br" />
                </>
              )}

              {interesttype === "mixto" &&
                schema.interest_data?.interest !== undefined && (
                  <MixedInterest
                    schema={schema}
                    timeMortgage={timeMortgage}
                    selectValueOne={mixInterestType1}
                    selectValueTwo={mixInterestType2}
                    isAutoBidding={props.readOnly}
                    onChangeMixedInput={(data: any, input: string) => {
                      setMixInterest(data, input);
                      setSchema({ ...schema });
                    }}
                    onChangeSelectInput={(data: any, input: string) => {
                      setMixInterest(data, input);
                      setSchema({ ...schema });
                    }}
                    onChangeSlideInput={(data) => {
                      setSchema({ ...data });
                    }}
                  />
                )}

              {role === "bank" && (
                <AditionalDetailInput
                  name="additional_detail"
                  value={schema.additional_detail}
                  error={Boolean(error.additional_detail)}
                  helperText={error.additional_detail}
                  multiline
                  onChange={({ target }) => {
                    setSchema((prevS: BidOffer) => ({
                      ...prevS,
                      additional_detail: target.value,
                    }));
                  }}
                  onBlur={({ target }) => {
                    bidValidationSchema
                      .validate(schema)
                      .then((val) => {
                        if (error.additional_detail) {
                          setError((prevS) => ({
                            ...prevS,
                            [target.name]: "",
                          }));
                        }
                      })
                      .catch((err) => {
                        setError((prevS) => ({
                          ...prevS,
                          [target.name]: err.message,
                        }));
                      });
                  }}
                />
              )}
              {role === "bank" && (
                <>
                  <Table sx={{ width: "100%" }} className={styles.TableBalance}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" className={styles.Title}>
                          Comisiones Colibid
                        </TableCell>
                        <TableCell align="right" className={styles.Content}>
                          {props.mode === "auto" &&
                            `${schema.general_fees.colibid_percent || 0}%`}
                          {props.mode === "manual" && (
                            <span>
                              {formatLocalCurrency(
                                schema.general_fees.colibid_fees
                              )}{" "}
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              )}

              {role !== "bank" && profile && (
                <Box>
                  {showComissionFinances &&
                    props.mode !== "auto" &&
                    profile.accept_financed_commission === true &&
                    profile.accapt_financed_commission_signed !== null && (
                      <>
                        {/*
                        ## financiar 
                        disabled until further notice
 
                        <FormGroup>
                          <FormControlLabel
                            className={styles.Title}
                            sx={{ fontSize: "0.9rem" }}
                            control={
                              <Checkbox
                                checked={showFinancedRow}
                                onChange={() => {
                                  changedFinancedStatus();
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{ marginBottom: 0, fontSize: "0.9rem" }}
                              >
                                Financiar honorarios con Colibid
                              </Typography>
                            }
                          ></FormControlLabel>
                        </FormGroup> */}
                      </>
                    )}
                  {props.mode !== "auto" && (
                    <React.Fragment>
                      <Table
                        sx={{ width: "100%" }}
                        className={styles.TableBalance}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" className={styles.Title}>
                              Honorarios Netos
                            </TableCell>
                            <TableCell align="right" className={styles.Content}>
                              <CurrencyField
                                data-id="honorarios-netos"
                                value={schema.general_fees.consulting_fees}
                                onChange={(data: any) => {
                                  consultingFeeValidations(data.value);
                                }}
                                type="text"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      €
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          {"require_purchase_package" in profile &&
                            !profile.require_purchase_package && (
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className={styles.Title}
                                >
                                  Comisiones Colibid
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className={styles.Content}
                                >
                                  {props.mode === "auto" &&
                                    `${
                                      schema.general_fees.colibid_percent || 0
                                    }%`}
                                  {props.mode === "manual" && (
                                    <span>
                                      {formatLocalCurrency(
                                        schema.general_fees.colibid_fees
                                      )}{" "}
                                    </span>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}

                          {showFinancedRow && profile && (
                            <TableRow>
                              <TableCell align="left" className={styles.Title}>
                                Descuento por financiación
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontWeight: "500" }}
                              >
                                {formatLocalCurrency(
                                  schema.general_fees.consulting_fees *
                                    profile.financed_commission_percentage
                                )}{" "}
                              </TableCell>
                            </TableRow>
                          )}

                          {showFinancedRow && (
                            <TableRow>
                              <TableCell align="left" className={styles.Title}>
                                Honorarios netos
                              </TableCell>
                              <TableCell
                                align="right"
                                className={styles.Content}
                              >
                                <span
                                  className={`${styles.Hightlight} ${
                                    schema.general_fees.alert_limit ||
                                    parseFloat(
                                      schema.general_fees.net_fees_financed
                                    ) <= 0
                                      ? styles.WarningAlert
                                      : ""
                                  }`}
                                >
                                  {formatLocalCurrency(
                                    schema.general_fees.net_fees_financed
                                  )}
                                </span>
                              </TableCell>
                            </TableRow>
                          )}

                          {!showFinancedRow && (
                            <TableRow>
                              <TableCell align="left" className={styles.Title}>
                                Honorarios Totales
                              </TableCell>
                              <TableCell
                                align="right"
                                className={styles.Content}
                              >
                                <span
                                  className={`${styles.Hightlight} ${
                                    schema.general_fees.alert_limit
                                      ? styles.WarningAlert
                                      : ""
                                  }`}
                                >
                                  {formatLocalCurrency(
                                    schema.general_fees.consulting_fees +
                                      schema.general_fees.colibid_fees
                                  )}{" "}
                                </span>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      {/* Validate is a broker and doesn't have the broker package */}
                      {isBrokerProfile(profile) &&
                        !profile.require_purchase_package && (
                          <>
                            <AlertInfo features={{ severity: "info" }}>
                              <small>
                                Lo que debes ingresar como valor es lo que tú
                                quieres ganar, sin tener en cuenta la comisión
                                de Colibid. Al cliente, le mostraremos la suma
                                de los dos importes (los honorarios totales)
                              </small>
                            </AlertInfo>
                            <AlertInfo features={{ severity: "warning" }}>
                              <small>
                                Recuerda que la comisión de Colibid es el{" "}
                                <b>
                                  {schema.general_fees.colibid_percent || 0}
                                </b>
                                % del valor de la hipoteca. Asegúrate de que tus
                                honorarios cubran esta cantidad.
                              </small>
                            </AlertInfo>
                          </>
                        )}
                    </React.Fragment>
                  )}

                  {props.mode === "auto" && (
                    <>
                      <Box sx={{ padding: "1.5rem" }}>
                        <Stack spacing={2}>
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "bold" }}
                          >
                            Honorarios Configurados:
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            Margen sobre el importe de la hipoteca:{" "}
                            {profile.autobidding_percentage}%
                            <Tooltip title="Este es el porcentaje que deseas ganar.">
                              <InfoOutlinedIcon className={styles.toolTip} />
                            </Tooltip>
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            Honorarios mínimos a percibir:{" "}
                            {profile.autobidding_min_margin}€
                            <Tooltip title="Este es el valor mínimo que quieres ganar. De no alcanzar el mínimo con el porcentaje, se aplicará este mínimo.">
                              <InfoOutlinedIcon className={styles.toolTip} />
                            </Tooltip>
                          </Typography>
                        </Stack>
                      </Box>
                      <AlertInfo features={{ severity: "warning" }}>
                        <small>
                          {
                            '"Estos son tus honorarios configurados. Si deseas modificarlos, debes hacerlo desde Auto-pujas > Configuraciones. Podrás ver el detalle de los honorarios enviados por puja en la sección “Mis Pujas”.'
                          }
                        </small>
                      </AlertInfo>
                    </>
                  )}
                  {/* Validate isn't autobid and if is a broker and doesn't have the broker package */}
                  {schema.general_fees.alert_limit &&
                    props.mode !== "auto" &&
                    isBrokerProfile(profile) &&
                    !profile.require_purchase_package && (
                      <>
                        <Alert severity="error">
                          <small>
                            Los honorarios netos son inferiores a las comisiones
                            de <b>Colibid</b>
                          </small>
                        </Alert>
                      </>
                    )}
                </Box>
              )}

              <Divider component="br" />
              <Divider component="br" />
              {role !== "bank" && (
                <FormControl component="fieldset">
                  <Typography variant="h6" className={styles.Title}>
                    Tipos de Honorarios
                  </Typography>
                  <RadioGroup
                    aria-label="Tipo de honorarios"
                    name="radio-buttons-group"
                    value={schema.general_fees.consulting_type}
                    onChange={({ target }) => {
                      schema.general_fees.consulting_type = Number(
                        target.value
                      );
                      setSchema({ ...schema });
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Por firma ante notaria"
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Por firma de FEIN"
                    />
                    {/* <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Pago por adelantado"
                    /> */}
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Pago incluido en la hipoteca"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>

            <Bindings
              conditions_details={schema.conditions_details}
              onChangeInput={(data: any, n: number) => {
                schema.conditions_details[n] = data;
                setSchema({ ...schema });
              }}
            />
          </Grid>

          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ marginBottom: "1rem", textAlign: "right" }}
          >
            <Divider /> <Divider component="br" />
            <Button
              variant="text"
              sx={{ marginRight: "1rem" }}
              onClick={() => props.onClose()}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={disabledButton}
              onClick={() => confirmAction()}
            >
              Enviar Oferta
            </Button>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
};

export default BidModal;

function AditionalDetailInput(props: TextFieldProps) {
  return (
    <Stack>
      <WithDialog
        position="right"
        label={
          <Typography
            variant="h6"
            className={styles.Title}
            style={{ margin: "unset" }}
          >
            Detalle adicional (opcional)
          </Typography>
        }
      >
        {({ closeDialog }) => (
          <Stack gap={3} alignItems="center" padding={2}>
            <Typography>
              Tienes {MAX_CHAR} caracteres libres para añadir un detalle
              adicional a tu oferta.
            </Typography>
            <Typography
              component="ul"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              Algunos ejemplos comunes:
              <Typography component="li">
                Tipo Variable, los primeros 12 meses con un fijo al 4,15%
              </Typography>
              <Typography component="li">
                Comisión de apertura de 0.5%
              </Typography>
            </Typography>
            <Typography textAlign="center">
              Es importante que el detalle sea lo suficiente claro y limpio para
              el cliente, ya que se lo mostraremos tal como lo ingreses.
            </Typography>
            <Button
              variant="contained"
              onClick={closeDialog}
              sx={{ minWidth: 100 }}
            >
              OK
            </Button>
          </Stack>
        )}
      </WithDialog>
      <TextField size="small" {...props} />
    </Stack>
  );
}
