import React, {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BankDetails } from "pages/Referrals/ReferralPay";
import {
  agentSignContract,
  getAgentContractColibid,
} from "../../../../services/api";
import { DialogWarning } from "../../../../shared/components";
import {
  useLocalStorage,
  useBankAccountUser,
  useDialog,
} from "../../../../shared/hooks";
import { useProfile } from "../../../../shared/hooks";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import dataStorageService from "services/api/dataStorage.service";
import { AGENCY_FIRST_INITAL_SESSION } from "shared/constants/general";
import { Profile, RealStateProfile } from "shared/types/Profile";

interface InFoDialogInterface {
  profile: Profile;
}
const InfoDialog: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const { isShowing, closeDialog, openDialog } = useDialog();
  const [isAgency, setIsAgency] = useState(false);
  const [checked, setChecked] = useState(false);
  const [aceptedTerms, setAceptedTerm] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [role] = useLocalStorage("role");
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const { profile } = useProfile<RealStateProfile>();
  const { paymentData, setPaymentValue } = useBankAccountUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      if (
        !profile.signed_contract &&
        !(profile.tags !== "" && profile.tags !== null)
      ) {
        openDialog();
      } else {
        if (profile.tags !== "" && profile.tags !== null) {
          dataStorageService
            .getData(AGENCY_FIRST_INITAL_SESSION)
            .then((res) => {
              const isRequired = res.data.results.payload;

              if (isRequired) {
                closeDialog();
              }
            })
            .catch((err) => {
              console.log("err", err);
              console.error({ err });
            });
        }
      }
    }
  }, [profile]);

  useEffect(() => {
    // Identify what modal to display
    if (profile && role === "realstate") {
      setIsAgency(profile.tags !== "" && profile.tags !== null);
    }
  }, [profile]);

  useEffect(() => {
    if (isShowing) {
      closeDialog();
    }

    setShowBankDetailsModal(false);
  }, [paymentData]);

  const handledAgree = () => {
    if (isAgency) {
      saveOption();

      return;
    } else {
      if (aceptedTerms && profile) {
        setShowBankDetailsModal(true);
      } else {
        setHelperText("No has aceptado los terminos y condiciones");
      }
    }
  };

  const changeStatus = (payload: any) => {
    if (profile && paymentData) {
      let data = {
        business_name: payload.account_holder_name,
        nif: payload.identification,
      };

      agentSignContract(data).then(() => {
        setShowBankDetailsModal(false);
      });
    }
  };

  const downLoadContract = () => {
    getAgentContractColibid().then((response: any) => {
      if (response.data) {
        if (response.data.value) {
          window.open(response.data.value);
        }
      }
    });
  };

  const putPaymentMethodData = (data: object) => {
    setPaymentValue(data);
    changeStatus(data);
    saveOption();
  };

  const messageWelcome = () => {
    return (
      <Typography component={"span"} variant="body2" gutterBottom>
        Aquí cambiamos el orden del proceso de solicitar una hipoteca, haciendo
        que sean{" "}
        <span className={styles.hightlight}>
          los bancos quienes realizan ofertas a los usuarios
        </span>
        , para que así ellos elijan con qué banco se quedan.No suena mal,
        ¿verdad? ¡Te contamos más! .
      </Typography>
    );
  };

  const saveOption = () => {
    if (isAgency) {
      dataStorageService
        .setData({
          key: AGENCY_FIRST_INITAL_SESSION,
          payload: true,
          days_expire: 30,
        })
        .then((res) => {
          if (res.status === 200) {
            closeDialog();
            if (isAgency) navigate("/agent/howworks/colibid");
          }
        })
        .catch((err) => console.log({ err }));
    }
  };

  return (
    <>
      <DialogWarning
        title="¡BIENVENIDO A COLIBID!"
        buttonDisagree={isAgency ? "Más tarde" : "Cancelar"}
        buttonAgree={isAgency ? "Vamos" : "Aceptar"}
        ShowDialog={isShowing}
        handledClickAgree={handledAgree}
        handledClickDisagree={() => {
          if (isAgency) {
            closeDialog();
            return;
          }
          window.open("https://colibid.com/afiliados-inmobiliaria/");
        }}
        otherPropsButtonAccept={{ variant: "contained" }}
        otherPropsButtonCancel={{
          variant: "contained",
          sx: { marginRight: "1rem", backgroundColor: "#CCCCCC" },
        }}
      >
        <>
          {isAgency ? (
            messageWelcome()
          ) : (
            <>
              <Typography component={"span"} variant="body2" gutterBottom>
                Para comenzar a trabajar con nosotros, necesitamos que por favor
                lea y firme el contrato comercial.
              </Typography>
              <Typography
                className={styles.link}
                variant="button"
                display="block"
                sx={{ margin: "1rem 0 " }}
                gutterBottom
                onClick={() => {
                  downLoadContract();
                }}
              >
                Ver contrato Comercial
              </Typography>
              <Typography variant="body2" gutterBottom mb={"2rem"}>
                Tras leer el documento, si das click a "Aceptar" te pediremos
                los datos para completar el contrato y se autocompletará. Fácil,
                ¿no?
              </Typography>

              <Typography variant="body2" gutterBottom>
                Si tienes dudas sobre el proceso, puedes contactar a:{" "}
                <a
                  className={styles.link}
                  href="mailto:inmobiliarias@colibid.com"
                >
                  inmobiliarias@colibid.com
                </a>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={aceptedTerms}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setAceptedTerm(event.target.checked);
                        if (event.target.checked) setHelperText("");
                      }}
                    />
                  }
                  label="He leído y acepto el contrato comercial"
                />
              </FormGroup>
              <FormHelperText className={styles.error}>
                {helperText}
              </FormHelperText>
            </>
          )}
        </>
      </DialogWarning>
      <BankDetails
        validateDNI={false}
        show={showBankDetailsModal}
        data={paymentData}
        nameAccount="Razón social/nombre autónomo"
        nameDni="CIF/NIF"
        warningInformation="Para completar el acuerdo necesitamos estos datos adicionales."
        saveButtonText="Guardar y Firmar"
        hideInput={["bic"]}
        showWarningInformation={isShowing}
        onSave={(data) => {
          if (isShowing) {
            putPaymentMethodData(data);
          } else {
            putPaymentMethodData(data);
          }

          toast.success("Tus datos bancarios se han registrado correctamente.");
        }}
        onClose={() => {
          setShowBankDetailsModal(false);
        }}
      />
    </>
  );
};

export default InfoDialog;
