export const TRACE_DATA_LINK = process.env.REACT_APP_TRACE_DATA_URL;

export const TRUE_LAYER = {
  auth:
    process.env.REACT_APP_ENV === "development"
      ? "truelayer-sandbox"
      : "truelayer",
  providers:
    process.env.REACT_APP_ENV === "development"
      ? "uk-cs-mock%20uk-ob-all%20uk-oauth-all"
      : "es-xs2a-all%20es-interactive-all&disable_providers=uk-ob-all%20uk-oauth-all",
    callBack: `${process.env.REACT_APP_API_URL}/services/truelayer/session`
};

export const WSAPI = "https://api.whatsapp.com/send?phone=";

export const IMG_BASE_URL =
  "https://colibid.fra1.digitaloceanspaces.com/colibid/public/assets/img/";

/**this link is to create a booking in the microsof calendar*/
export const COLIBID_DATA_BOOKING_LINK = 'https://link.colibid.com/sales'
