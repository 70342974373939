import {
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationProps,
  Skeleton,
  Stack,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getMortgages } from "services/api";
import { NavItems, navItems } from "shared/constants/navigations";
import { useProfile } from "shared/hooks";
import useRole from "shared/hooks/useRole";
import { BaseApiSuccessRes, MortgageRes } from "shared/types/BaseApiResponse";
import {
  getDiscartedMortgageStatus,
  isMortgageAlive,
} from "utils/mortagageData";
import styles from "./styles.module.scss";
import { ClientProfile } from "shared/types/Profile";

export interface NavBtnOption extends NavItems {
  can_create_mortgage?: boolean;
}

// change this to defaultIconoptions cause is using in everywhereç
// and it will maybe be a object, but in nav it need to be a ordered list
export const defaultNavBtnOptions: NavBtnOption[] = [
  navItems.myDocument,
  navItems.newApplication,
  navItems.myMortgage,
  navItems.principalPanel,
  navItems.myProfile,
  navItems.myApplications,
];

export default function BottomNav({
  navBtnOptions = defaultNavBtnOptions,
  bottomNavSx,
}: {
  bottomNavSx?: BottomNavigationProps["sx"];
  navBtnOptions?: NavBtnOption[];
}) {
  const navigate = useNavigate();
  const [activeMortId, setActiveMortId] = useState<MortgageRes["id"]>();
  const [isFetching, setIsFetching] = useState<boolean>();
  const { profile } = useProfile<ClientProfile>();
  // revisar si es cliente por q el layout client y bidder puede q se este utilzando donde no es
  // cambiar los layout de como se renderiza para no usar este hook
  const { isClient, role } = useRole();

  const getAllMortgages = (filter: any, page: number, limit: number) => {
    setIsFetching(true);
    // se necesita esta logica aqui para obtener un hipoteca activa
    // que se usaria su id poder redirigir
    // ya q profile.can_create_mortgage solo es booleano y no contiene el id de la hipoteca activa
    // tal vez utilizar el nuevo endpoint last mortgage?
    getMortgages(filter, page, limit)
      .then((response: AxiosResponse<BaseApiSuccessRes<MortgageRes[]>>) => {
        // take last mortgage is the only one care
        // const lastMortgage = response.data.results.at(-1);
        const res = response.data.results;
        const activeMortgage = res.find(isMortgageAlive);
        if (!activeMortgage) {
          return;
        }
        const mStatus = activeMortgage.status;
        if (!getDiscartedMortgageStatus(activeMortgage).includes(mStatus)) {
          setActiveMortId(activeMortgage.id);
        }
      })
      .catch((e: any) => {
        toast.info(
          "Actualmente estamos presentando intermitencia en nuestros servidores, es posible que algunas funcionalidades no funcionen correctamente, ya ha sido reportado el problema y estamos trabajando para solucionarlo lo más pronto posible, disculpas las molestias.🚧🏗️"
        );
        console.log(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    // fetch mortgages whne is client and cannot create mortgage, meaning has a active one
    // fetch mortgages to get the active one
    if (isClient && profile && !profile.can_create_mortgage) {
      getAllMortgages({ mode: "" }, 1, 100);
    }
  }, [profile]);

  const handleClick = (b: (typeof navBtnOptions)[number]) => () => {
    if (typeof b.path === "string") {
      navigate(b.path);
      return;
    }
    if (activeMortId) {
      const url = b.path(activeMortId);
      navigate(url);
    }
  };

  if (role === "bank" || role === "broker") return null;
  return (
    <nav className={styles.bottomNav}>
      <BottomNavigation showLabels sx={{ ...bottomNavSx, padding: "0.5rem" }}>
        {navBtnOptions
          .filter((e) => {
            if ("can_create_mortgage" in e) {
              //if have active mortgage it cannot create
              return e.can_create_mortgage === profile?.can_create_mortgage;
            }
            return true;
          })
          .map((b, i) => {
            let icon: ReactNode = isFetching ? (
              <Stack alignItems="center" gap={1}>
                <Skeleton variant="rectangular" width={40} height={40} />
                <Skeleton variant="text" width={50} />
              </Stack>
            ) : (
              <img
                src={b.iconUrl}
                height={40}
                width={40}
                {...(i === 2 && { style: { backgroundColor: "#D9F6EF" } })}
              />
            );
            return (
              <BottomNavigationAction
                key={b.label}
                label={isFetching ? undefined : b.label}
                disabled={isFetching}
                icon={icon}
                onClick={handleClick(b)}
                // sx={{ backgroundColor: "#D9F6EF" }}
              />
            );
          })}
      </BottomNavigation>
    </nav>
  );
}
