import {
  Box,
  CircularProgress,
  LinearProgress,
  LinearProgressProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import styles from "./styles.module.scss";

const BidProgress = (props: LinearProgressProps & { value: number }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const value = Math.round(props.value);
  return (
    <Box
      className={
        isMobile ? styles.circularProgressBox : styles.linearProgressBox
      }
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        {isMobile ? (
          <CircularProgress variant="determinate" value={value} />
        ) : (
          <LinearProgress color="primary" variant="determinate" {...props} />
        )}
      </Box>
      <Box
        className={
          isMobile
            ? styles.circularProgressBoxContainer
            : styles.linearProgressBoxContainer
        }
      >
        <Typography variant="body2" color="text.secondary">
          {value}%
        </Typography>
      </Box>
    </Box>
  );
};

export default BidProgress;
