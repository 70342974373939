import { TableRowsData } from ".";

export function getTruthyValuesLength(data: TableRowsData): number {
  const values = Object.values(data);
  const truthyValues = values.filter((value) => {
    if (typeof value === 'object' && value !== null && 'value' in value) {
      return Boolean(value.value);
    }
    return Boolean(value);
  });
  return truthyValues.length;
}
