import { Box, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { ColibidTypography, HStack } from "shared/components";
import Icon from "./Icon";
import { getImgBaseUrl } from "utils/links";

export default function MediaDescPanel({
  imgSrc,
  imgW = 260,
  description,
  imgOnLeft,
  iconSrc,
  label,
}: {
  imgSrc: string;
  imgW?: number;
  imgOnLeft?: boolean;
  description: ReactNode;
  iconSrc: string;
  label: string;
}) {
  return (
    <HStack
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      gap={2}
      {...(imgOnLeft && { flexDirection: "row-reverse" })}
    >
      <img src={imgSrc} width={imgW} style={{ flex: "1.5 0 260px" }} />
      <Box flex="1 0 120px">
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <Stack alignItems="center">
            <Icon src={iconSrc} />
            <Typography color="primary">{label}</Typography>
          </Stack>
          {description}
        </Stack>
      </Box>
    </HStack>
  );
}

export function ClientInfoPanel() {
  return (
    <MediaDescPanel
      imgOnLeft
      imgSrc={getImgBaseUrl("saas_colibid_data/1_colibid_data.svg")}
      label="Clientes"
      iconSrc={getImgBaseUrl("group.svg")}
      description={
        <Typography textAlign="center">
          En esta sección encontrarás información sobre los distintos perfiles de
          usuarios, y cuán requeridos son. Podrás ver la situación laboral, el
          salario, ratios de endeudamiento, edad, residencia y situación
          laboral. Además, podrás filtrar por fecha, valor de hipoteca
          solicitado, tipo de hipoteca solicitada y comunidad.{" "}
          <ColibidTypography accentColor inline>
            Te ayudamos a detectar oportunidades 😉
          </ColibidTypography>
        </Typography>
      }
    />
  );
}
export function MortgageInfoPanel() {
  return (
    <MediaDescPanel
      imgSrc={getImgBaseUrl("saas_colibid_data/2_colibid_data.svg")}
      label="Hipotecas"
      iconSrc={getImgBaseUrl("mortgage.svg")}
      description={
        <Typography textAlign="center">
          En la sección hipotecas podrás analizar{" "}
          <ColibidTypography accentColor inline>
            qué hipotecas están pidiendo los usuarios y dónde compiten los bancos.
          </ColibidTypography>
          Aquí podrás ver: tipo e importe de hipoteca, de interés, de solicitud y periodo solicitado. También podrás filtrarlo 
          tanto por fecha como por Comunidad Autónoma.
        </Typography>
      }
    />
  );
}
export function GeoInfoPanel() {
  return (
    <MediaDescPanel
      imgOnLeft
      imgSrc={getImgBaseUrl("saas_colibid_data/3_colibid_data.svg")}
      label="Geografía"
      iconSrc={getImgBaseUrl("map-location.svg")}
      description={
        <Typography textAlign="center">
          <ColibidTypography accentColor inline>
            ¿Quieres saber cómo opera cada comunidad autónoma?
          </ColibidTypography>
          Lo tenemos. Podrás analizar por cada comunidad qué importe promedio de
          hipoteca se solicita, cuántos perfiles no reciben ofertas, el promedio
          de ofertas por usuario, y una comparativa de tasas.
        </Typography>
      }
    />
  );
}
export function OffersInfoPanel() {
  return (
    <MediaDescPanel
      imgSrc={getImgBaseUrl("saas_colibid_data/4_colibid_data.svg")}
      label="Ofertas"
      iconSrc={getImgBaseUrl("auction.svg")}
      description={
        <Typography textAlign="center">
          Esto se pone cada vez más interesante:{" "}
          <ColibidTypography accentColor inline>
            ¡Te decimos cómo te encontrás frente al mercado!
          </ColibidTypography>
          Te reflejamos tus tasas ofrecidas vs las del mercado por día para cada
          tipo de interés, y qué bonificaciones suele ofrecer la competencia.
          Mola, ¿no?
        </Typography>
      }
    />
  );
}
export function ConvertionInfoPanel() {
  return (
    <MediaDescPanel
      imgOnLeft
      imgSrc={getImgBaseUrl("saas_colibid_data/4_colibid_data.svg")}
      label="Conversión"
      iconSrc={getImgBaseUrl("auction.svg")}
      description={
        <Typography textAlign="center">
          Y claro, te ayudamos a entender{" "}
          <ColibidTypography inline accentColor>
            cómo estás posicionado
          </ColibidTypography>
          . Cuántas ofertas envías, a cuántos usuarios y cuántos clientes
          aceptan tus ofertas. Y para completar, cuánto representa esto del
          mercado.
        </Typography>
      }
    />
  );
}
