import React, { FunctionComponent, ReactElement } from "react";

import styles from "./styles.module.scss";

export interface ItemFAQ {
  title: string;
}

export interface IImageButtonProps {
  title: string;
  src: string;
  onClick: () => void;
}
const ImageButton: FunctionComponent<IImageButtonProps> = ({
  title,
  src,
  onClick,
}): ReactElement<any> => {
  return (
    <>
      <div className={styles.Wrapper} onClick={() => onClick()}>
        <figure>
          <img src={src} alt={title} />
        </figure>
        <h4>{title}</h4>
      </div>
    </>
  );
};

export default ImageButton;
