import { yupResolver } from "@hookform/resolvers/yup";
import FormFields from "shared/components/FormFields";
import * as yup from "yup";
import { yesNoOption } from "utils/valueLabelOptions";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import StepLayout from "../StepLayout";
import { useFormContext } from "react-hook-form";
import { MortgageFormData } from "shared/types/postMortgageData";
import { YesNoOption } from "shared/types/Enums";
import Swal from "sweetalert2";
import { requiredStringSchema } from "utils/yupValidation";

const yupSchema = yup.object({
  user: yup.object({
    has_last_year_tax: requiredStringSchema(),
  }),
});

function UserHasLastYearTaxForm() {
  const { mortgageFormData, onBack, onNext } = useMortgageFormContext();
  return (
    <StepLayout
      defaultValues={mortgageFormData}
      onNext={onNext}
      onBack={onBack}
      title="¿Has realizado la declaración de Renta en España del último año?"
      resolver={yupResolver(yupSchema)}
    >
      <Form />
    </StepLayout>
  );
}

function Form() {
  const { onNext } = useMortgageFormContext();
  const { getValues } = useFormContext<MortgageFormData>();

  const handleClick = () => {
    const form = getValues();
    yupSchema
      .validate(form)
      .then((res) => {
        const val = getValues("user.has_last_year_tax");
        if (Number(val) === YesNoOption.No) {
          Swal.fire({
            icon: "warning",
            title: "Importante",
            text: "Podemos intentarlo, pero ten en cuenta que los bancos la necesitarán más adelante en el proceso",
          }).then((res) => {
            if (res.isConfirmed) {
              onNext(form);
            }
          });
        } else {
          onNext(form);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <FormFields.ListSelector
      name="user.has_last_year_tax"
      options={yesNoOption}
      onClick={handleClick}
    />
  );
}
export default UserHasLastYearTaxForm;
