import { useEffect, useMemo } from "react";
import {
  Grid,
  Button,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from "@mui/material";
import { Article, Delete } from "@mui/icons-material";

import { useWatch, useFormContext, UseFieldArrayRemove } from "react-hook-form";

import FormFields from "shared/components/FormFields";
import { InputOption } from "shared/types/misc";
import { IncomesList, MortgageFormData } from "shared/types/postMortgageData";
import { incomeCardOptions } from "./utils";

type Props = { index: number; fieldId: string; remove: UseFieldArrayRemove };

export default function IncomeCard({ index, fieldId, remove }: Props) {
  const MONTHLY_INCOME_STRING = useMemo(
    () => `revenue.incomes_list[${index}].monthly_income`,
    []
  );
  const SOLICITOR_INCOME_TYPE_STRING = useMemo(
    () => `revenue.incomes_list[${index}].solicitor`,
    []
  );

  const { control, setValue, getValues } = useFormContext();
  const [monthlyIncome, solicitorType, solicitorIncomeType] = useWatch({
    control,
    name: [
      MONTHLY_INCOME_STRING,
      "solicitor_type",
      SOLICITOR_INCOME_TYPE_STRING,
    ],
  });

  useEffect(() => {
    if (solicitorType === "1") {
      setValue(`revenue.incomes_list[${index}].solicitor`, 1);
    }
  }, []);

  useEffect(() => {
    const revenueIncome = getValues("revenue.incomes_list");
    let userTotalIncome = 0;
    let user2TotalIncome = 0;
    revenueIncome.forEach((i: IncomesList) => {
      if (Number(i.solicitor) === 1) {
        userTotalIncome += Number(i.monthly_income);
        return;
      }
      user2TotalIncome += Number(i.monthly_income);
    });
    setValue("user.salary", userTotalIncome || "");
    if (solicitorType === "2") {
      setValue("user_2.salary", user2TotalIncome || "");
    }
  }, [monthlyIncome, solicitorIncomeType]);

  const handleRemoveOnClick = () => {
    remove(index);

    if (solicitorIncomeType === "2") {
      const user2Salary = getValues("user_2.salary");
      const sum = user2Salary - Number(monthlyIncome);
      setValue("user_2.salary", sum || "");
      return;
    }
    const userSalary = getValues("user.salary");
    const sum = userSalary - Number(monthlyIncome);
    setValue("user.salary", sum || "");
  };

  const radioOptions = useMemo(() => {
    const options: InputOption[] = [{ value: 1, label: "Solicitante" }];
    if (solicitorType === "2") {
      options.push({ value: 2, label: "Acompañante" });
    }
    return options;
  }, [solicitorType]);

  return (
    <Grid item xs={12} md={12} key={fieldId} data-cy={`income-card-${index}`}>
      <Accordion expanded={true}>
        <AccordionSummary style={{ color: "#707676" }}>
          <Article style={{ marginRight: ".7rem", paddingTop: "6px" }} />{" "}
          <Grid item xs={12} md={12}>
            <FormFields.AutoCompleteInput
              options={incomeCardOptions}
              name={`revenue.incomes_list[${index}].name`}
              label="Nombre del Ingreso Mensual "
              placeholder="Salario, Negocio, Renta..."
            />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormFields.RadioInput
                row
                label="Titular"
                name={`revenue.incomes_list[${index}].solicitor`}
                radioOptions={radioOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <FormFields.CurrencyFieldWithWrapperText
                name={MONTHLY_INCOME_STRING}
                label="Ingreso Mensual"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                variant="text"
                color="error"
                onClick={handleRemoveOnClick}
                startIcon={<Delete />}
              >
                Eliminar
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
