import { useState } from "react";
import logger from "utils/logger";

export default function useLocalStorages(key: string, initialValue?: any) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key) !== null ? window.localStorage.getItem(key) : undefined;
      const data = item ? JSON.parse(item) : initialValue;
      logger.info({key, item, data})
      return data;
    } catch (error) {
      console.error(error);
      return initialValue
    }
  });

  const clearData = () => {
    setStoredValue(undefined);
    window.localStorage.removeItem(key);
  };

  const setValue = (value: string) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue, clearData];
}
